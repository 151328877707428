import axios from "axios";
import qs from "qs";
// import $env from "@/enviroment";
import store from "../store/index.js";

function l() {
  let l = null;
  return (
    (l = Object.assign
      ? Object.assign.bind()
      : function (e) {
          for (var t = 1; t < arguments.length; t++) {
            var i = arguments[t];
            for (var a in i)
              Object.prototype.hasOwnProperty.call(i, a) && (e[a] = i[a]);
          }
          return e;
        }),
    l.apply(this, arguments)
  );
}

const c = 3e4;

class _http {
  constructor() {
    this.interceptorDisabled = !1;
    this.unauthorizedDisabled = !1;
    this.offlineCount = 0;
    this.offlineLockMode = !0;
    this.offlineScheduler = null;
    this._client = axios.create({
      // baseURL: $env.CRISP_URL_API,
      baseURL: "http://localhost:8090/api",
      timeout: c,
      // withCredentials: !0,
    });
    this._transformResponses();
  }
  delete(e, t = {}, i = {}, a = {}) {
    return this._client.delete(
      this._prepareRestUrl(e, t),
      l(a, {
        data: i,
      }),
    );
  }
  get(e, t = {}, i = {}) {
    return this._client.get(this._prepareRestUrl(e, t), i);
  }
  head(e, t = {}, i = {}) {
    return this._client.head(this._prepareRestUrl(e, t), i);
  }
  patch(e, t = {}, i = {}, a = {}) {
    return this._client.patch(this._prepareRestUrl(e, t), i, a);
  }
  post(e, t = {}, i = {}, a = {}) {
    return this._client.post(this._prepareRestUrl(e, t), i, a);
  }
  put(e, t = {}, i = {}, a = {}) {
    return this._client.put(this._prepareRestUrl(e, t), i, a);
  }
  addInterceptor(e, t) {
    return this._client.interceptors.response.use(e, t);
  }
  deleteHeader(e) {
    delete this._client.defaults.headers.common[e];
  }
  setHeader(e, t) {
    this._client.defaults.headers.common[e] = t;
  }
  one(e, t) {
    return new httpFactory(this, t ? [e, t].join("/") : e);
  }
  _prepareRestUrl(e = [], t = {}) {
    let i = "/v1/";
    return (
      Array.isArray(e) ? (i += e.join("/")) : "string" === typeof e && (i += e),
      Object.keys(t).length > 0 && (i += "?" + qs.stringify(t)),
      i
    );
  }
  _transformResponses() {
    this._client.interceptors.response.use(
      (e) => (
        e.headers &&
          e.headers["x-safe-mode"] &&
          store.commit("common/setSafeModeEnabled", {
            value: !0,
          }),
        e.data && "object" === typeof e.data.data
          ? ((e.data.data.__status = e.status),
            (e.data.data.__url = (e.request || {}).responseURL || ""),
            Promise.resolve(e.data.data))
          : Promise.resolve(e)
      ),
      (e) =>
        e.response && "object" === typeof e.response.data
          ? ((e.response.data.status = e.response.status),
            (e.response.data.headers = e.response.headers),
            (e.response.data.silent = !0 === (e.response.config || {}).silent),
            Promise.reject(e.response.data))
          : Promise.reject(e.response),
    );
  }
}

class httpFactory {
  constructor(e, t) {
    return (this._httpFactory = e), (this._relativePath = t), this;
  }
  one(e, t) {
    return new httpFactory(
      this._httpFactory,
      t
        ? [this._relativePath, e, t].join("/")
        : [this._relativePath, e].join("/"),
    );
  }
  delete(e = {}, t = {}, i = {}) {
    return this._httpFactory.delete(this._relativePath, t, e, i);
  }
  get(e = {}, t = {}) {
    return this._httpFactory.get(this._relativePath, e, t);
  }
  head(e = {}, t = {}) {
    return this._httpFactory.head(this._relativePath, e, t);
  }
  patch(e = {}, t = {}, i = {}) {
    return this._httpFactory.patch(this._relativePath, t, e, i);
  }
  post(e = {}, t = {}, i = {}) {
    return this._httpFactory.post(this._relativePath, t, e, i);
  }
  put(e = {}, t = {}, i = {}) {
    return this._httpFactory.put(this._relativePath, t, e, i);
  }
}

export default new _http();
