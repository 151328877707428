const n = 1e3;
const r = 50;
import a from "@/classes/platformBridgeDock.js";

class _titleController {
  constructor() {
    this.restoreTimeout = null;
    this.updateTimeout = null;
    this.lastTitle = "Talqui";
    this.unread = 0;
  }
  restore() {
    clearTimeout(this.restoreTimeout),
      clearTimeout(this.updateTimeout),
      (this.restoreTimeout = null),
      (this.updateTimeout = null),
      (this.restoreTimeout = setTimeout(() => {
        this.makeTitleWithCount("Talqui");
      }, n));
  }
  update(e) {
    if (!e) return this.restore();
    clearTimeout(this.restoreTimeout),
      clearTimeout(this.updateTimeout),
      (this.restoreTimeout = null),
      (this.updateTimeout = null),
      (this.updateTimeout = setTimeout(() => {
        this.makeTitleWithCount(e + " | Talqui");
      }, r));
  }
  makeTitleWithCount(e = null) {
    null === e ? (e = this.lastTitle) : (this.lastTitle = e),
      this.unread > 0
        ? (document.title = `💬${this.unread} - ${e}`)
        : (document.title = e);
  }
  setUnreadCounter(e = 0) {
    (this.unread = e), this.makeTitleWithCount(), a.setUnreadCounter(e);
  }
}

export default new _titleController();
