<template>
  <div
    :class="[
      'c-base-card-provider',
      'c-base-card-provider--' + provider,
      'c-base-card-provider--' + size,
    ]"
  ></div>
</template>
<script>
export default {
  props: {
    provider: {
      type: String,
      default: "default",
    },
    size: {
      type: String,
      default: "medium",
    },
  },
};
</script>
<style lang="scss">
.c-base-card-provider {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI3NTAiIGhlaWdodD0iNDcxIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxyZWN0IHdpZHRoPSI3NTAiIGhlaWdodD0iNDcxIiBmaWxsPSIjMDAwYzlkIiBmaWxsLXJ1bGU9Im5vbnplcm8iIHJ4PSI0MCIvPjxyZWN0IHdpZHRoPSIxMTAuMzI0IiBoZWlnaHQ9Ijk5LjY3OSIgeD0iNDguNzEiIHk9IjE1My41NzYiIGZpbGw9IiM5ZDk0MDAiIGZpbGwtcnVsZT0ibm9uemVybyIgcng9IjIwLjMyMyIvPjxnIGZvbnQtZmFtaWx5PSJDb3VyaWVyIj48dGV4dCBmaWxsPSIjZmZmIiBmb250LXNpemU9IjYxIiBsZXR0ZXItc3BhY2luZz0iLTEuNSI+PHRzcGFuIHg9IjU0IiB5PSIzMzMiPjQzMjEgNDMyMSA0MzIxIDQzMjE8L3RzcGFuPjwvdGV4dD48dGV4dCBmaWxsPSIjYzhjYWU5IiBmb250LXNpemU9IjQ2IiBsZXR0ZXItc3BhY2luZz0iLTEuMTMxIj48dHNwYW4geD0iNTUiIHk9IjQxOSI+MTEyMzU4IDEzMjEgMzQ1NTwvdHNwYW4+PC90ZXh0PjwvZz48L2c+PC9zdmc+");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%;
}
.c-base-card-provider--amex {
  background-image: url("@/assets/images/components/base/BaseCardProvider/amex.svg");
}
.c-base-card-provider--dinersclub {
  background-image: url("@/assets/images/components/base/BaseCardProvider/dinersclub.svg");
}
.c-base-card-provider--discover {
  background-image: url("@/assets/images/components/base/BaseCardProvider/discover.svg");
}
.c-base-card-provider--jcb {
  background-image: url("@/assets/images/components/base/BaseCardProvider/jcb.svg");
}
.c-base-card-provider--maestro {
  background-image: url("@/assets/images/components/base/BaseCardProvider/maestro.svg");
}
.c-base-card-provider--mastercard {
  background-image: url("@/assets/images/components/base/BaseCardProvider/mastercard.svg");
}
.c-base-card-provider--paypal {
  background-image: url("@/assets/images/components/base/BaseCardProvider/paypal.svg");
}
.c-base-card-provider--unionpay {
  background-image: url("@/assets/images/components/base/BaseCardProvider/unionpay.svg");
}
.c-base-card-provider--visa {
  background-image: url("@/assets/images/components/base/BaseCardProvider/visa.svg");
}
.c-base-card-provider--wire {
  background-image: url("@/assets/images/components/base/BaseCardProvider/wire.svg");
}
.c-base-card-provider--tiny {
  width: 27px;
  height: 17px;
  border-radius: 2px;
}
.c-base-card-provider--small {
  width: 43px;
  height: 27px;
  border-radius: 4px;
}
.c-base-card-provider--medium {
  width: 72px;
  height: 46px;
  border-radius: 6px;
}
.c-base-card-provider--big {
  width: 88px;
  height: 56px;
  border-radius: 6px;
}
.c-base-card-provider--huge {
  width: 146px;
  height: 94px;
  border-radius: 8px;
}
</style>
