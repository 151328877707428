import organization from "./organization";
import tenant from "@/classes/talqui/tenant";

class Operator {
  constructor() {}

  operatorBase() {
    return organization.organizationOne().one("operators");
  }
  operatorList() {
    return organization.organizationOne().one("operators");
  }
  operatorRecover() {
    return organization.organizationOne().one("operators", "recover");
  }
  operatorLogin() {
    return organization.organizationOne().one("operators", "login");
  }
  operatorMe() {
    return organization.organizationOne().one("operators", "me");
  }
  operatorAvailability({ tenantID, operatorID }) {
    return tenant
      .tenantOne(tenantID)
      .one("operators", operatorID)
      .one("availability");
  }
  operatorNotifications({ tenantID, operatorID }) {
    return tenant
      .tenantOne(tenantID)
      .one("operators", operatorID)
      .one("notifications");
  }
}
export default new Operator();
