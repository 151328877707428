<template>
  <div
    :class="[
      'c-field-checkbox',
      'c-field-checkbox--' + size,
      {
        'c-field-checkbox--block': block,
      },
    ]"
  >
    <input
      class="c-field-checkbox__input"
      v-on:change="(...e) => onInputChange(...e)"
      v-on:click="(...e) => onInputClick(...e)"
      :checked="modelValue"
      :value="modelValue"
      :disabled="disabled"
      :id="uuid"
      :name="name"
      type="checkbox"
    />
    <div
      v-if="label || description || this.$slots.default"
      class="c-field-checkbox__information"
    >
      <label
        v-if="label || this.$slots.default"
        class="c-field-checkbox__label"
        :direction="direction"
        :for="uuid"
      >
        <div v-if="label">{{ label }}</div>
        <slot></slot>
      </label>

      <p v-if="description" class="c-field-checkbox__description">
        {{ description }}
      </p>
    </div>
  </div>
</template>
<script>
import { v4 } from "uuid";
export default {
  props: {
    block: {
      type: Boolean,
      default: !0,
    },
    description: {
      type: String,
      default: "",
    },
    direction: {
      type: String,
      default: "vertical",
      validator(e) {
        return -1 !== ["horizontal", "vertical"].indexOf(e);
      },
    },
    label: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "default",
    },
    modelValue: {
      type: Boolean,
      default: !1,
    },
    disabled: {
      type: Boolean,
      default: !1,
    },
  },
  emits: ["update:modelValue", "click", "change"],
  data() {
    return {
      uuid: null,
    };
  },
  created() {
    this.uuid = v4();
  },
  methods: {
    onInputChange(e) {
      this.$emit("change", e.target.checked, this.name || this.uuid, e);
      this.$emit("update:modelValue", e.target.checked);
    },
    onInputClick(e) {
      this.$emit("click", e);
    },
  },
};
</script>
<style lang="scss">
.c-field-checkbox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: left;
  .c-field-checkbox__input {
    position: relative;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    margin-bottom: 0;
    outline: 0;
    border: none;
    border-radius: 2px;
    cursor: pointer;
    -webkit-appearance: none;
    -webkit-transition: all 50ms linear;
    transition: all 50ms linear;
    &:after {
      -webkit-transition: all 50ms linear;
      transition: all 50ms linear;
      position: absolute;
      display: inline-block;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      border: 2px solid #fff;
      border-top: 0;
      border-left: 0;
      content: "";
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
    }
    &:before {
      -webkit-transition: all 50ms linear;
      transition: all 50ms linear;
      position: absolute;
      display: inline-block;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 1px solid #a8c6df;
      border-radius: 3px;
      background-color: #fff;
      content: "";
    }
    &:hover {
      &:not(:checked) {
        &:before {
          border-color: #75869c;
        }
      }
    }
    &:checked {
      &:before {
        border-color: #0093ee;
        background: #0093ee;
        -webkit-animation-name: none;
        animation-name: none;
      }
      &:after {
        display: block;
        border-color: #fff;
      }
    }
  }
  .c-field-checkbox__information {
    .c-field-checkbox__label {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      color: #3f536e;
      cursor: pointer;
    }
    .c-field-checkbox__description {
      margin: 4px 0 0;
      color: #3f536e;
    }
  }
  &:last-of-type {
    margin-bottom: 0;
  }
}
.c-field-checkbox--mini {
  .c-field-checkbox__input {
    margin-right: 6px;
    width: 12px;
    height: 12px;
    &:after {
      top: 2px;
      left: 4px;
      width: 4px;
      height: 6px;
    }
  }
  .c-field-checkbox__information {
    .c-field-checkbox__label {
      font-size: 12px;
      line-height: 1.1;
    }
    .c-field-checkbox__description {
      font-size: 11px;
      line-height: 1.1;
    }
    margin-top: 1px;
  }
}
.c-field-checkbox--small {
  .c-field-checkbox__input {
    margin-right: 7px;
    width: 14px;
    height: 14px;
    &:after {
      top: 3px;
      left: 5px;
      width: 4px;
      height: 7px;
    }
  }
  .c-field-checkbox__information {
    .c-field-checkbox__label {
      font-size: 13px;
      line-height: 1.1;
    }
    .c-field-checkbox__description {
      font-size: 12px;
      line-height: 1.1;
    }
  }
}
.c-field-checkbox--default {
  .c-field-checkbox__input {
    margin-right: 8px;
    width: 16px;
    height: 16px;
    &:after {
      top: 3px;
      left: 6px;
      width: 4px;
      height: 9px;
    }
  }
  .c-field-checkbox__information {
    .c-field-checkbox__label {
      font-size: 14px;
      line-height: 1.1;
    }
    .c-field-checkbox__description {
      font-size: 13px;
      line-height: 1.1;
    }
    margin-top: -1px;
  }
}
.c-field-checkbox--medium {
  .c-field-checkbox__input {
    margin-right: 9px;
    width: 18px;
    height: 18px;
    &:after {
      top: 3px;
      left: 6px;
      width: 6px;
      height: 10px;
    }
  }
  .c-field-checkbox__information {
    .c-field-checkbox__label {
      font-size: 15px;
      line-height: 1.1;
    }
    .c-field-checkbox__description {
      font-size: 14px;
      line-height: 1.1;
    }
  }
}
.c-field-checkbox--large {
  .c-field-checkbox__input {
    margin-right: 10px;
    width: 20px;
    height: 20px;
    &:after {
      top: 3px;
      left: 7px;
      width: 6px;
      height: 12px;
    }
  }
  .c-field-checkbox__information {
    .c-field-checkbox__label {
      font-size: 16px;
      line-height: 1.1;
    }
    .c-field-checkbox__description {
      font-size: 15px;
      line-height: 1.1;
    }
  }
}
.c-field-checkbox--block {
  width: 100%;
}
</style>
