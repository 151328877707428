import { localize } from "@vee-validate/i18n";
import { configure } from "vee-validate";
import store from "@/store/index.js";
import o from "./i18n.js";
import n from "@/enviroment";
import s from "./date.js";

class _language {
  constructor() {
    this.loadedLanguages = [];
    this.localeOverride = !1;
    this.localeLocaled = !1;
  }

  loadLanguageAsync(e) {
    return this.localeLocaled && o.getInstance().locale === e
      ? Promise.resolve(e)
      : ((this.localeLocaled = !0),
        -1 === this.loadedLanguages.indexOf(e)
          ? require("@/localize/locales-language")("./" + e)
              .then(
                (t) => (
                  o.getInstance().setLocaleMessage(e, t.default),
                  this.loadedLanguages.push(e),
                  this.setI18nLanguage(e)
                ),
              )
              // eslint-disable-next-line no-unused-vars
              .then((e) => {
                let t = window.LOCALE;
                let VEE_VALIDATE_LOCALE = n.CRISP_VALIDATOR_LOCALE_OVERRIDES[t];
                if (VEE_VALIDATE_LOCALE) {
                  t = VEE_VALIDATE_LOCALE;
                }
                s.updateDateInstances();
                require("@/localize/locales-veevalidate")("./" + t).then(
                  (imported) => {
                    configure({
                      generateMessage: localize(t, imported.default),
                    });
                  },
                );
                return Promise.resolve();
              })
          : Promise.resolve(this.setI18nLanguage(e)));
  }

  setI18nLanguage(e) {
    o.getInstance().locale = e;
    store.commit("common/setLocaleLoaded", {
      value: !0,
    });
    document.querySelector("html").setAttribute("lang", e);
    return e;
  }

  updateLanguage(e) {
    this.loadLanguageAsync(e);
    localStorage && localStorage.setItem("locale_override", e);
    window.LOCALE = e;
  }
}

export default new _language();
