<template>
  <label
    :class="[
      'c-field-label',
      'c-field-label--' + direction,
      'c-field-label--' + size,
      {
        'c-field-label--dark': dark,
        'c-field-label--uppercase': uppercase,
      },
    ]"
    :for="forField"
  >
    <slot></slot>
    <span v-if="required" class="c-field-label__required u-has-tooltip">
      *
      <BaseTooltip direction="bottom">
        {{ this.$t("TOOLTIPS.FIELD_LABEL_REQUIRED") }}
      </BaseTooltip>
    </span>
  </label>
</template>
<script>
export default {
  props: {
    dark: {
      type: Boolean,
      default: !1,
    },
    direction: {
      type: String,
      default: "vertical",
      validator(e) {
        return -1 !== ["horizontal", "horizontal-small", "vertical"].indexOf(e);
      },
    },
    forField: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: !1,
    },
    size: {
      type: String,
      default: "default",
      validator(e) {
        return (
          -1 !== ["mini", "small", "default", "medium", "large"].indexOf(e)
        );
      },
    },
    uppercase: {
      type: Boolean,
      default: !1,
    },
  },
  emits: [],
};
</script>
<style lang="scss">
.c-field-label {
  display: inline-block;
  color: #1c293b;
  font-weight: 700;
  cursor: pointer;
  .c-field-label__required {
    margin-left: 4px;
    color: #e0102b;
    cursor: help;
  }
}
.c-field-label--vertical {
  margin-bottom: 6px;
}
.c-field-label--mini {
  font-size: 12px;
}
.c-field-label--small {
  font-size: 13px;
}
.c-field-label--default {
  font-size: 14px;
}
.c-field-label--medium {
  font-size: 15px;
}
.c-field-label--large {
  font-size: 16px;
}
.c-field-label--dark {
  color: #fff;
}
.c-field-label--uppercase {
  text-transform: uppercase;
}
</style>
