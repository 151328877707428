<template>
  <span
    v-if="browser"
    :class="[
      'c-base-browser',
      'c-base-browser--' + browserIdentifier,
      'c-base-browser--' + size,
    ]"
  >
  </span>
</template>
<script>
const a = /mobile/g;
export default {
  props: {
    browser: {
      type: String,
      required: !0,
      validator(e) {
        return (
          -1 !==
          [
            "android",
            "chrome",
            "chromium",
            "edge",
            "facebook",
            "firefox",
            "internet",
            "mobile safari",
            "opera",
            "safari",
          ].indexOf(e)
        );
      },
    },
    size: {
      type: String,
      default: "default",
      validator(e) {
        return -1 !== ["small", "default"].indexOf(e);
      },
    },
  },
  computed: {
    browserIdentifier() {
      return this.browser.replace(a, "").trim().toLowerCase();
    },
  },
};
</script>
<style lang="scss">
.c-base-browser {
  border-radius: 100%;
  background-color: #eff3f6;
  background-image: url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjU0MCIgd2lkdGg9IjU0MCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0wIDBoNTQwdjU0MEgweiIgZmlsbD0iI2VmZjNmNiIvPjxwYXRoIGQ9Ik0zMDAgMzQ3LjYyNXY1MS43NWMwIDIuMy0uODYzIDQuMzEzLTIuNTg3IDYuMDM3UzI5My42NzUgNDA4IDI5MS4zNzUgNDA4aC01MS43NWMtMi4zIDAtNC4zMTMtLjg2My02LjAzNy0yLjU4N1MyMzEgNDAxLjY3NSAyMzEgMzk5LjM3NXYtNTEuNzVjMC0yLjMuODYzLTQuMzEzIDIuNTg3LTYuMDM3czMuNzM4LTIuNTg4IDYuMDM4LTIuNTg4aDUxLjc1YzIuMyAwIDQuMzEzLjg2MyA2LjAzNyAyLjU4N3MyLjU4OCAzLjczOCAyLjU4OCA2LjAzOHptNjktMTMwLjAxOGMwIDcuNzk1LTEuMTE4IDE1LjA4NC0zLjM1NSAyMS44NjgtMi4yMzYgNi43ODUtNC43NjEgMTIuMzA2LTcuNTc1IDE2LjU2NC0yLjgxMyA0LjI1OC02Ljc4MSA4LjU1My0xMS45MDMgMTIuODgzLTUuMTIyIDQuMzMtOS4yNyA3LjQ3LTEyLjQ0NSA5LjQxOC0zLjE3NCAxLjk1LTcuNTc1IDQuNTExLTEzLjIwMiA3LjY4Ny01LjkxNiAzLjMyLTEwLjg1NyA4LjAxLTE0LjgyNSAxNC4wNzNzLTUuOTUyIDEwLjg5OS01Ljk1MiAxNC41MDdjMCAyLjQ1NC0uODY2IDQuOC0yLjU5NyA3LjAzNy0xLjczMSAyLjIzNy0zLjc1MSAzLjM1Ni02LjA2IDMuMzU2aC01MS45NDNjLTIuMTY0IDAtNC4wMDQtMS4zMzUtNS41MTktNC4wMDYtMS41MTUtMi42Ny0yLjI3Mi01LjM3Ni0yLjI3Mi04LjExOXYtOS43NDNjMC0xMS45ODEgNC42OS0yMy4yNzYgMTQuMDY4LTMzLjg4NXMxOS42OTUtMTguNDQgMzAuOTQ5LTIzLjQ5M2M4LjUxMy0zLjg5NyAxNC41NzMtNy45MzkgMTguMTgtMTIuMTI1IDMuNjA3LTQuMTg2IDUuNDEtOS42NyA1LjQxLTE2LjQ1NSAwLTYuMDYyLTMuMzU0LTExLjQwMy0xMC4wNjMtMTYuMDIycy0xNC40NjUtNi45MjktMjMuMjY2LTYuOTI5Yy05LjM3OSAwLTE3LjE3IDIuMDkzLTIzLjM3NSA2LjI4LTUuMDUgMy42MDgtMTIuNzY5IDExLjkwOC0yMy4xNTcgMjQuODk5LTEuODc2IDIuMzEtNC4xMTMgMy40NjQtNi43MSAzLjQ2NC0xLjczMSAwLTMuNTM1LS41NzctNS40MS0xLjczMmwtMzUuNDk1LTI3LjA2NWMtMS44NzUtMS40NDMtMi45OTMtMy4yNDgtMy4zNTQtNS40MTMtLjM2LTIuMTY1LjAzNi00LjE4NiAxLjE5LTYuMDYyQzE5My40MDUgMTUwLjE5OCAyMjYuODggMTMxIDI3MC43NDIgMTMxYzExLjU0MyAwIDIzLjE1OCAyLjIzNyAzNC44NDUgNi43MTIgMTEuNjg3IDQuNDc1IDIyLjIyIDEwLjQ2NSAzMS41OTggMTcuOTcxIDkuMzc5IDcuNTA2IDE3LjAyNiAxNi43MDggMjIuOTQxIDI3LjYwNlMzNjkgMjA1LjYyNiAzNjkgMjE3LjYwN3oiIGZpbGw9IiM0YjU4NmUiLz48L2c+PC9zdmc+");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%;
  display: inline-block;
}
.c-base-browser--android {
  background-image: url("@/assets/images/common/browsers/android.png");
}
.c-base-browser--chrome {
  background-image: url("@/assets/images/common/browsers/chrome.png");
}
.c-base-browser--chromium {
  background-image: url("@/assets/images/common/browsers/chromium.png");
}
.c-base-browser--edge {
  background-image: url("@/assets/images/common/browsers/edge.png");
}
.c-base-browser--facebook {
  background-image: url("@/assets/images/common/browsers/facebook.png");
}
.c-base-browser--firefox {
  background-image: url("@/assets/images/common/browsers/firefox.png");
}
.c-base-browser--internet-explorer {
  background-image: url("@/assets/images/common/browsers/internet-explorer.png");
}
.c-base-browser--opera {
  background-image: url("@/assets/images/common/browsers/opera.png");
}
.c-base-browser--safari {
  background-image: url("@/assets/images/common/browsers/safari.png");
}
.c-base-browser--small {
  width: 14px;
  height: 14px;
}
.c-base-browser--default {
  width: 20px;
  height: 20px;
}
</style>
