import a from "@/router/index.js";
import o from "@/classes/platform.js";

const r = /^crisp:\/\/crisp-app-desktop\/([^?&#]+)(?:\?([^#]+))?(?:#(.+))?$/;

class _linkHelper {
  openExternalOrInternal(e, t = !0) {
    !0 === r.test(e) ? this.openInternal(e) : this.openExternal(e, t);
  }
  openExternal(e, t = !0) {
    if (o.isPlatform()) window.open(e);
    else if (!0 === t) {
      let t = window.open();
      (t.opener = null), (t.location = e);
    } else window.location = e;
  }
  openInternal(e) {
    let t = e.match(r);
    t &&
      t[1] &&
      a.default
        .push({
          name: t[1],
          query: this.__parseUriParameters(t[2] || ""),
          params: this.__parseUriParameters(t[3] || ""),
        })
        .catch(() => {});
  }
  __parseUriParameters(e) {
    let t = {},
      i = e.split("&");
    for (let a = 0; a < i.length; a++) {
      let e = i[a].split("=");
      if (e[0]) {
        let i = this.__normalizeUriParametersKey(decodeURIComponent(e[0]));
        t[i] = decodeURIComponent(e[1] || "");
      }
    }
    return t;
  }
  __normalizeUriParametersKey(e) {
    return e
      .split("_")
      .map(
        (e, t) => (
          e &&
            (e =
              0 === t
                ? e.toLowerCase()
                : e.substr(0, 1).toUpperCase() +
                  "" +
                  e.substr(1).toLowerCase()),
          e
        ),
      )
      .join("");
  }
}

export default new _linkHelper();
