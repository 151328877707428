class _crispPlaceholder {
  init(e) {
    let t = this;
    e.directive("crispPlaceholder", {
      beforeMount(e, i) {
        return t.bind(e, i);
      },
    });
  }
  bind(e, t) {
    var i = () => {
      setTimeout(() => {
        let i = t.instance.$refs.textarea || {};
        "" === i.innerText || "\n" === i.innerText
          ? e.setAttribute("empty", !0)
          : e.removeAttribute("empty");
      }, 1);
    };
    i(),
      e.addEventListener("keydown", () => {
        i();
      });
  }
}
export default new _crispPlaceholder();
