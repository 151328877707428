const validate = {
  code: "pt_BR",
  messages: {
    default: "{field} não está correto",
    _default: "{field} não está correto",
    alpha: "O campo {field} deve conter somente letras",
    alpha_dash: "O campo {field} deve conter letras, números e traços",
    alpha_num: "O campo {field} deve conter somente letras e números",
    alpha_spaces:
      "O campo {field} só pode conter caracteres alfabéticos e espaços",
    between: "O campo {field} deve estar entre 0:{min} e 1:{max}",
    confirmed: "A confirmação do campo {field} deve ser igual",
    digits:
      "O campo {field} deve ser numérico e ter exatamente 0:{length} dígitos",
    dimensions:
      "O campo {field} deve ter 0:{width} pixels de largura por 1:{height} pixels de altura",
    email: "O campo {field} deve ser um email válido",
    excluded: "O campo {field} deve ser um valor válido",
    ext: "O campo {field} deve ser um arquivo válido",
    image: "O campo {field} deve ser uma imagem",
    integer: "O campo {field} deve ser um número inteiro",
    is: "O valor inserido no campo {field} não é válido",
    one_of: "O campo {field} deve ter um valor válido",
    length: "O tamanho do campo {field} deve ser 0:{length}",
    max: "O campo {field} não deve ter mais que 0:{length} caracteres",
    max_value: "O campo {field} precisa ser 0:{max} ou menor",
    mimes: "O campo {field} deve ser um tipo de arquivo válido",
    min: "O campo {field} deve conter pelo menos 0:{length} caracteres",
    min_value: "O campo {field} precisa ser 0:{min} ou maior",
    numeric: "O campo {field} deve conter apenas números",
    regex: "O campo {field} possui um formato inválido",
    required: "O campo {field} é obrigatório",
    required_if: "O campo {field} é obrigatório",
    size: "O campo {field} deve ser menor que 0:{size}KB",
    url: "O campo {field} deve ser uma URL válida",
  },
};

export default validate;
