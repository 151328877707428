<template>
  <ValidationProvider
    :modelValue="modelValue"
    v-on:update:modelValue="(e) => (modelValue = e)"
    :name="name || uuid"
    :label="validationLabel"
    :rules="rules"
    :validate-on-input="!0"
    :value="currentValue"
    :class="[
      'c-field-input',
      'c-field-input--' + direction,
      'c-field-input--' + size,
      'c-field-input--' + type,
      'c-field-input--' + status,
      {
        'c-field-input--block': block,
        'c-field-input--clear': clear,
        'c-field-input--bordered': hasBorders,
        'c-field-input--dark': dark,
        'c-field-input--disabled': disabled,
        'c-field-input--error': hasError,
        'c-field-input--focused': focused && !noFocusStyle,
        'c-field-input--loading': loading,
        'c-field-input--rounded': rounded,
        'c-field-input--padding': padding,
        'c-field-input--autocompletion': autocompletion,
        'c-field-input--with-icon': leftIcon || rightIcon,
        'c-field-input--with-tags': hasTags && tags.length > 0,
      },
    ]"
    as="div"
    v-slot="{ field, meta, errorMessage }"
  >
    <div v-if="label" class="c-field-input__header">
      <FieldLabel
        class="c-field-input__label"
        :dark="dark"
        :direction="direction"
        :for-field="uuid"
        :required="rules && -1 !== rules.indexOf('required')"
        :size="size"
      >
        {{ label }}
      </FieldLabel>
    </div>

    <div class="c-field-input__wrapper">
      <div
        :class="[
          'c-field-input__container',
          {
            'c-field-input__container--error':
              errorMessage && meta.dirty && !focused,
            'c-field-input__container--suffix-border': suffixBorder,
          },
        ]"
        v-on:click="(...e) => onContainerClick(...e)"
      >
        <BaseIcon
          v-if="computedLeftIcon"
          class="c-field-input__icon c-field-input__icon--left"
          :name="computedLeftIcon"
          :size="computedIconSize"
        ></BaseIcon>
        <input
          v-bind="field"
          v-on:blur="(...e) => onInputBlur(...e)"
          v-on:focus="(...e) => onInputFocus(...e)"
          v-on:input="(...e) => onInput(...e)"
          v-on:keyup="(...e) => onInputKeyup(...e)"
          v-on:keydown="(...e) => onInputKeydown(...e)"
          :autocomplete="autocomplete"
          :data-lpignore="
            'off' === autocomplete || 'new-password' === autocomplete || null
          "
          :autocorrect="autocorrect"
          :autocapitalize="autocapitalize"
          :disabled="disabled"
          :id="uuid"
          :min="min"
          :max="max"
          :name="name"
          :pattern="pattern"
          :placeholder="placeholder"
          :readonly="readonly"
          :step="step"
          :type="type"
          :value="currentValue"
          :class="[
            'c-field-input__field',
            'js-field-input-field',
            {
              'u-semibold': currentValue && emphasis,
            },
          ]"
          :style="{
            textAlign: textAlign,
          }"
          ref="input"
          v-maska
          :data-maska="mask"
        />
        <slot></slot>
        <BaseSpinner
          v-if="'loading' === computedRightIcon"
          class="c-field-input__icon c-field-input__icon--right c-field-input__icon--loading"
          border-width="2px"
          color="#a8c6df"
          key="loading"
          size="14px"
        ></BaseSpinner>
        <BaseIcon
          v-else-if="computedRightIcon"
          class="c-field-input__icon c-field-input__icon--right"
          v-on:click="onRightIconClick"
          :cursor="clear ? 'pointer' : 'default'"
          :name="computedRightIcon"
          :size="clear ? '16px' : '20px'"
          key="rightIcon"
        ></BaseIcon>
        <div v-if="hasTags && tags.length > 0" class="c-field-input__tags">
          <BaseTag
            v-for="item in tags"
            class="c-field-input__tag"
            v-on:remove="onRemoveTag"
            :id="item"
            :key="item"
            :label="item"
            palette="segment"
            has-remove=""
          ></BaseTag>
        </div>
      </div>
      <div
        v-if="suffix"
        :class="[
          'c-field-input__suffix',
          {
            'c-field-input__suffix--border': suffixBorder,
          },
        ]"
      >
        {{ suffix }}
      </div>
    </div>

    <FieldDescription
      v-if="description"
      class="c-field-input__description"
      :description="description"
      :size="size"
    ></FieldDescription>

    <div
      v-if="errorMessage && meta.dirty && !focused"
      class="c-field-input__error"
      v-on:click="(...e) => focusField(...e)"
    >
      {{ errorMessage }}
    </div>

    <div
      v-if="autocompletion && focused && computedSuggestions.length > 0"
      class="c-field-input__suggestions"
    >
      <div
        v-for="item in computedSuggestions"
        :key="item"
        class="c-field-input__suggestion"
        v-on:mouseDown="(e) => onSelectSuggestion(item, e)"
        :innerHTML="this.$filters.format.emphasizeWithQuery(item, currentValue)"
      ></div>
    </div>
  </ValidationProvider>
</template>
<script>
import { v4 } from "uuid";
import inputMixin from "@/mixins/inputMixin.js";
export default {
  mixins: [inputMixin],
  props: {
    autocomplete: {
      type: String,
      default: "off",
    },
    autocompletion: {
      type: Boolean,
      default: !1,
    },
    autocorrect: {
      type: String,
      default: "off",
    },
    autocapitalize: {
      type: String,
      default: "off",
    },
    autofocus: {
      type: Boolean,
      default: !1,
    },
    emphasis: {
      type: Boolean,
      default: !0,
    },
    block: {
      type: Boolean,
      default: !0,
    },
    clear: {
      type: Boolean,
      default: !1,
    },
    dark: {
      type: Boolean,
      default: !1,
    },
    description: {
      type: String,
      default: null,
    },
    direction: {
      type: String,
      default: "vertical",
      validator(e) {
        return -1 !== ["horizontal", "vertical"].indexOf(e);
      },
    },
    disabled: {
      type: Boolean,
      default: !1,
    },
    hasBorders: {
      type: Boolean,
      default: !0,
    },
    hasError: {
      type: Boolean,
      default: !1,
    },
    hasTags: {
      type: Boolean,
      default: !1,
    },
    label: {
      type: String,
      default: null,
    },
    leftIcon: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: !1,
    },
    min: {
      type: Number,
      default: null,
    },
    max: {
      type: Number,
      default: null,
    },
    modelValue: {
      type: [String, Number],
      default: null,
    },
    name: {
      type: String,
      default: "",
    },
    noFocusStyle: {
      type: Boolean,
      default: !1,
    },
    onBlur: {
      type: Function,
      default: null,
    },
    padding: {
      type: Boolean,
      default: !0,
    },
    pattern: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: !1,
    },
    rightIcon: {
      type: String,
      default: null,
    },
    rounded: {
      type: Boolean,
      default: !1,
    },
    rules: {
      type: [Object, String],
      default: null,
    },
    size: {
      type: String,
      default: "default",
    },
    status: {
      type: String,
      default: "normal",
    },
    step: {
      type: Number,
      default: null,
    },
    suffix: {
      type: String,
      default: null,
    },
    suffixBorder: {
      type: Boolean,
      default: !1,
    },
    suggestionType: {
      type: String,
      default: "segment",
      validator(e) {
        return -1 !== ["data", "event", "segment"].indexOf(e);
      },
    },
    suggestions: {
      type: Array,
      default() {
        return [];
      },
    },
    textAlign: {
      type: String,
      default: "left",
    },
    trim: {
      type: Boolean,
      default: !0,
    },
    type: {
      type: String,
      default: "text",
      validator(e) {
        return -1 !== ["email", "number", "password", "text", "tel"].indexOf(e);
      },
    },
    mask: {
      type: [Array, String],
      default: null,
    },
  },
  emits: [
    "update:modelValue",
    "input",
    "blur",
    "click",
    "keydown",
    "keyup",
    "clear",
    "focus",
  ],
  data() {
    return {
      currentValue: "",
      focused: !1,
      tags: [],
      uuid: null,
    };
  },
  computed: {
    computedIconSize() {
      switch (this.size) {
        case "mini":
          return "14px";
        case "small":
          return "16px";
        case "default":
          return "20px";
        case "medium":
          return "22px";
        case "large":
          return "24px";
        default:
          return;
      }
    },
    computedLeftIcon() {
      return this.leftIcon;
    },
    computedRightIcon() {
      if (this.disabled) return null;
      if (this.loading) return "loading";
      if (this.clear && this.currentValue && this.currentValue.length > 0)
        return "cancel";
      switch (this.status) {
        case "error":
          return "close";
        case "success":
          return "check";
        case "warning":
          return "warning";
      }
      return this.rightIcon;
    },
    confirmable() {
      return !(!this.$props || !this.$props.onBlur);
    },
    computedSuggestions() {
      let e = this.currentValue;
      return this.suggestions
        .map((e) => {
          switch (this.suggestionType) {
            case "data":
              return e.key;
            case "event":
              return e.text;
            case "segment":
              return e.segment;
            default:
              return e.segment;
          }
        })
        .filter(
          (t) =>
            (!this.hasTags || -1 === this.tags.indexOf(t)) &&
            t.toLowerCase().startsWith(e.toLowerCase()) &&
            t.toLowerCase() !== e.toLowerCase(),
        );
    },
  },
  watch: {
    modelValue: {
      immediate: !0,
      handler(e) {
        this.currentValue = e;
      },
    },
  },
  created() {
    this.uuid = v4();
  },
  mounted() {
    this.hasTags &&
      (this.tags = this.modelValue
        .split(",")
        .map((e) => e.trim())
        .filter((e) => e && !0)),
      this.autofocus && this.focusField();
  },
  methods: {
    addTag() {
      const e = this.getInputValue();
      e &&
        ((this.currentValue = ""),
        -1 === this.tags.indexOf(e) &&
          (this.tags.push(e),
          this.$emit(
            "update:modelValue",
            this.tags.join(","),
            this.name || this.uuid,
            event,
          ),
          this.$emit(
            "input",
            this.tags.join(","),
            this.name || this.uuid,
            event,
          )));
    },
    clearField() {
      (this.currentValue = ""),
        this.hasTags ||
          (this.$emit("update:modelValue", "", this.name || this.uuid, event),
          this.$emit("keyup", this.name || this.uuid, "", event),
          this.$emit("clear", this.name || this.uuid, event));
    },
    focusField() {
      this.$refs.input.focus();
    },
    getInputValue(e = !0) {
      let t = (this.$refs.input || {}).value || "";
      return (
        !0 === e && t && !0 === this.trim && (t = t.trim()),
        t && "number" === this.type && (t = Number(t)),
        t
      );
    },
    onContainerClick(e) {
      this.focusField(),
        this.$emit("click", this.name || this.uuid, this.getInputValue(), e);
    },
    onInput(e) {
      this.currentValue = this.getInputValue(!1);
      let t = this.getInputValue();
      this.hasTags ||
        (this.$emit("update:modelValue", t, this.name || this.uuid, e),
        this.$emit("input", t, this.name || this.uuid, e));
    },
    onInputBlur(e) {
      (this.focused = !1),
        this.hasTags && this.addTag(),
        this.$emit("blur", this.name || this.uuid, this.getInputValue(), e);
    },
    onInputKeyup(e) {
      if ("Escape" === e.code && this.clear && this.currentValue)
        this.clearField();
      else {
        if ("Enter" === e.code) {
          if (this.hasTags) return void this.addTag();
          if (this.confirmable)
            return (
              e.preventDefault(), e.stopPropagation(), void e.target.blur()
            );
        }
        this.$emit("keyup", this.name || this.uuid, this.getInputValue(), e);
      }
    },
    onInputKeydown(e) {
      this.$emit("keydown", this.name || this.uuid, e);
    },
    onInputFocus(e) {
      (this.focused = !0),
        this.$emit("focus", this.name || this.uuid, this.getInputValue(), e);
    },
    onSelectSuggestion(e, t) {
      (this.$refs.input.value = e),
        (this.currentValue = e),
        this.hasTags ? this.addTag() : this.hasTags || this.onInput(t);
    },
    onRemoveTag(e) {
      const t = this.tags.findIndex((t) => t === e);
      -1 !== t &&
        (this.tags.splice(t, 1),
        this.$emit(
          "update:modelValue",
          this.tags.join(","),
          this.name || this.uuid,
          event,
        ),
        this.$emit(
          "input",
          this.tags.join(","),
          this.name || this.uuid,
          event,
        ));
    },
    onRightIconClick() {
      this.clear && this.clearField();
    },
  },
};
</script>
<style lang="scss">
.c-field-input {
  color: #2c405a;
  position: relative;
  text-align: left;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  .c-field-input__header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    .c-field-input__label {
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
    }
  }
  .c-field-input__wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .c-field-input__container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background-color: transparent;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    &:hover {
      cursor: text;
    }
    .c-field-input__icon {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      -webkit-transition: all 0.15s linear;
      transition: all 0.15s linear;
      -webkit-transition-property: fill, opacity;
      transition-property: fill, opacity;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }
    .c-field-input__provider {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      -webkit-transition: all 0.15s linear;
      transition: all 0.15s linear;
      -webkit-transition-property: fill, opacity;
      transition-property: fill, opacity;
      margin-right: -4px;
      margin-left: 14px;
    }
    .c-field-input__icon--left {
      margin-right: 8px;
      margin-left: 14px;
      fill: #a8c6df;
    }
    .c-field-input__provider--left {
      margin-right: 8px;
      margin-left: 14px;
      fill: #a8c6df;
    }
    .c-field-input__icon--right {
      margin-right: 14px;
      margin-left: 8px;
    }
    .c-field-input__provider--right {
      margin-right: 14px;
      margin-left: 8px;
    }
    .c-field-input__icon--loading {
      margin-left: 9px;
    }
    .c-field-input__provider--loading {
      margin-left: 9px;
    }
    .c-field-input__field {
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
      padding: 0;
      height: 100%;
      border: none;
      background-color: transparent;
      width: 100%;
      -webkit-box-shadow: none;
      box-shadow: none;
      &::-webkit-input-placeholder {
        color: #75869c;
      }
      &::-moz-placeholder {
        color: #75869c;
      }
      &::-ms-input-placeholder {
        color: #75869c;
      }
      &::placeholder {
        color: #75869c;
      }
      &:disabled {
        background: transparent;
        color: rgba(168, 198, 223, 0.8);
        cursor: not-allowed;
      }
      &:-moz-read-only {
        color: rgba(44, 64, 90, 0.8);
        background: rgba(168, 198, 223, 0.05);
      }
      &:read-only {
        color: rgba(44, 64, 90, 0.8);
        background: rgba(168, 198, 223, 0.05);
      }
      &:focus {
        outline: none;
      }
    }
    .c-field-input__tags {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      padding: 2px 8px 6px;
      .c-field-input__tag {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        margin: 3px;
        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }
  .c-field-input__container--error {
    border-color: #e0102b !important;
  }
  .c-field-input__container--suffix-border {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .c-field-input__suffix {
    color: #75869c;
    padding-left: 12px;
    font-size: 14px;
    font-weight: 700;
  }
  .c-field-input__error {
    z-index: 10;
    position: absolute;
    min-width: 110px;
    line-height: 18px;
    background-color: #e0102b;
    color: #fff;
    font-weight: 700;
    font-size: 13.5px;
    cursor: text;
    padding: 2px 6px;
    border-radius: 2px;
  }
  .c-field-input__suggestions {
    position: absolute;
    right: 0;
    left: 0;
    top: calc(100% - 2px);
    z-index: 22;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 200px;
    margin-bottom: 10px;
    background-color: #fff;
    color: #1c293b;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
    border: 1px solid #e3e8ef;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    .c-field-input__suggestion {
      position: relative;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      cursor: pointer;
      padding: 6px 10px;
      font-size: 14px;
      line-height: 22px;
      border-bottom: 1px solid #e3e8ef;
      &:last-child {
        border-bottom: none;
      }
      &:hover {
        background-color: #0093ee;
        color: #fff;
      }
    }
  }
  &:hover {
    .c-field-input__error {
      visibility: hidden;
    }
  }
}
.c-field-input--horizontal {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  .c-field-input__header {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 140px;
  }
  .c-field-input__container {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .c-field-input__suffix {
    line-height: 44px;
  }
  .c-field-input__error {
    left: 150px;
  }
  .c-field-input__suggestions {
    left: 140px;
  }
}
.c-field-input--horizontal.c-field-input--number {
  .c-field-input__header {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .c-field-input__container {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
}
.c-field-input--vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  .c-field-input__error {
    left: 10px;
  }
}
.c-field-input--vertical.c-field-input--mini {
  .c-field-input__suffix {
    line-height: 32px !important;
  }
}
.c-field-input--vertical.c-field-input--small {
  .c-field-input__suffix {
    line-height: 36px !important;
  }
}
.c-field-input--vertical.c-field-input--default {
  .c-field-input__suffix {
    line-height: 40px !important;
  }
}
.c-field-input--vertical.c-field-input--medium {
  .c-field-input__suffix {
    line-height: 44px !important;
  }
}
.c-field-input--vertical.c-field-input--large {
  .c-field-input__suffix {
    line-height: 48px !important;
  }
}
.c-field-input--mini.c-field-input--horizontal {
  line-height: 32px;
}
.c-field-input--mini {
  .c-field-input__container {
    height: 32px;
    border-radius: 2px;
    .c-field-input__icon {
      font-size: 16px !important;
    }
    .c-field-input__field {
      font-size: 12px;
    }
  }
  .c-field-input__error {
    bottom: 5px;
  }
}
.c-field-input--small.c-field-input--horizontal {
  line-height: 36px;
}
.c-field-input--small {
  .c-field-input__container {
    height: 36px;
    border-radius: 3px;
    .c-field-input__icon {
      font-size: 17px !important;
    }
    .c-field-input__field {
      font-size: 13px;
    }
  }
  .c-field-input__error {
    bottom: 7px;
  }
}
.c-field-input--default.c-field-input--horizontal {
  line-height: 40px;
}
.c-field-input--default {
  .c-field-input__container {
    height: 40px;
    border-radius: 4px;
    .c-field-input__icon {
      font-size: 18px !important;
    }
    .c-field-input__field {
      font-size: 14px;
    }
  }
  .c-field-input__error {
    bottom: 9px;
  }
}
.c-field-input--medium.c-field-input--horizontal {
  line-height: 44px;
}
.c-field-input--medium {
  .c-field-input__container {
    height: 44px;
    border-radius: 5px;
    .c-field-input__icon {
      font-size: 19px !important;
    }
    .c-field-input__field {
      font-size: 15px;
    }
  }
  .c-field-input__error {
    bottom: 11px;
  }
}
.c-field-input--large.c-field-input--horizontal {
  line-height: 48px;
}
.c-field-input--large {
  .c-field-input__container {
    height: 48px;
    border-radius: 6px;
    .c-field-input__icon {
      font-size: 20px !important;
    }
    .c-field-input__field {
      font-size: 16px;
    }
  }
  .c-field-input__error {
    bottom: 13px;
  }
}
.c-field-input--error {
  .c-field-input__container {
    color: #e0102b;
    border-color: #e0102b !important;
    border-color: #e0102b;
    .c-field-input__icon {
      fill: #e0102b;
    }
    .c-field-input__field {
      &::-webkit-input-placeholder {
        color: #e0102b;
      }
      &::-moz-placeholder {
        color: #e0102b;
      }
      &::-ms-input-placeholder {
        color: #e0102b;
      }
      &::placeholder {
        color: #e0102b;
      }
    }
  }
}
.c-field-input--normal {
  .c-field-input__container {
    border-color: #a8c6df;
    color: #2c405a;
  }
}
.c-field-input--success {
  .c-field-input__container {
    color: #40b630;
    border-color: #40b630;
    .c-field-input__icon {
      fill: #40b630;
    }
    .c-field-input__field {
      &::-webkit-input-placeholder {
        color: #40b630;
      }
      &::-moz-placeholder {
        color: #40b630;
      }
      &::-ms-input-placeholder {
        color: #40b630;
      }
      &::placeholder {
        color: #40b630;
      }
    }
  }
}
.c-field-input--warning {
  .c-field-input__container {
    color: #fd7b1f;
    border-color: #fd7b1f;
    .c-field-input__icon {
      fill: #fd7b1f;
    }
    .c-field-input__field {
      &::-webkit-input-placeholder {
        color: #fd7b1f;
      }
      &::-moz-placeholder {
        color: #fd7b1f;
      }
      &::-ms-input-placeholder {
        color: #fd7b1f;
      }
      &::placeholder {
        color: #fd7b1f;
      }
    }
  }
}
.c-field-input--block {
  width: 100%;
}
.c-field-input--bordered {
  .c-field-input__container {
    border-width: 1px;
    border-style: solid;
    border-radius: 3px;
    background-color: #fff;
    -webkit-transition: border 0.2s linear;
    transition: border 0.2s linear;
  }
}
.c-field-input--clear {
  .c-field-input__container {
    .c-field-input__icon--right {
      fill: #a8c6df;
    }
  }
}
.c-field-input--dark {
  .c-field-input__container {
    border-color: #3f536e;
    background-color: #2c405a;
    .c-field-input__field {
      color: #fff;
    }
    &:hover {
      border-color: #526b8e;
    }
  }
  .c-field-input__suffix--border {
    border: 1px solid #3f536e;
    border-left: none;
    background-color: #273950;
    color: #fff;
    border-radius: 0 4px 4px 0;
    padding-right: 12px;
    height: 36px;
    text-align: center;
  }
  .c-field-input__suggestions {
    border-color: #3f536e;
    background-color: #2c405a;
    color: #fff;
    .c-field-input__suggestion {
      border-color: #3f536e;
      &:hover {
        border-color: #526b8e;
      }
    }
  }
}
.c-field-input--disabled {
  .c-field-input__container {
    background-color: rgba(168, 198, 223, 0.1);
  }
}
.c-field-input--autocompletion {
  overflow: initial;
}
.c-field-input--focused {
  .c-field-input__container {
    border-color: #2c405a;
    color: #2c405a;
    .c-field-input__icon {
      opacity: 0.5;
    }
    .c-field-input__field {
      &::-webkit-input-placeholder {
        color: #75869c;
      }
      &::-moz-placeholder {
        color: #75869c;
      }
      &::-ms-input-placeholder {
        color: #75869c;
      }
      &::placeholder {
        color: #75869c;
      }
    }
  }
}
.c-field-input--focused.c-field-input--dark {
  .c-field-input__container {
    border-color: #526b8e;
  }
}
.c-field-input--rounded {
  .c-field-input__container {
    border-radius: 40px;
  }
}
.c-field-input--padding {
  .c-field-input__container {
    .c-field-input__field {
      padding: 0 15px;
    }
  }
}
.c-field-input--with-icon {
  .c-field-input__container {
    .c-field-input__field {
      padding: 0;
    }
  }
}
.c-field-input--with-tags {
  .c-field-input__container {
    display: block;
    height: auto;
    .c-field-input__field {
      display: block;
      height: 32px;
    }
  }
}
</style>
