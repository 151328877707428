import http from "./http";

class _bucket {
  constructor() {}

  bucketBase() {
    return http.one("bucket");
  }

  bucketUrl() {
    return this.bucketBase().one("url");
  }
}
export default new _bucket();
