// import classOperators from "@/classes/talqui/operator";
import classHttp from "@/classes/talqui/http";
import store from "@/store";
import $env from "@/enviroment";

const INTERVAL_ATTEMP_CALL_API = 60000; //1minute
const DISABLE_API_CALL_LOWER_THAN = 240000; //4minutes
const INTERVAL_NOTIFICATED_API = 300;
const AUTHORIZATION_HOURS = 12;

class _auth {
  constructor() {
    this.heartbeatDisabled = false;
    this.__heartbeatScheduler = null;
  }

  decodeToken(token) {
    try {
      var base64Url = token.split(".")[1];
      var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      var payload = decodeURIComponent(
        window
          .atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join(""),
      );
      return JSON.parse(payload);
    } catch (err) {
      console.error(err);
      return null;
    }
  }

  authenticateApi({ token }) {
    // console.log("@class:auth:authenticateApi", token);
    classHttp.setHeader("Authorization", "Bearer " + token);
  }

  autoAuthenticateNetwork() {
    // console.log("@class:auth:autoAuthenticateNetwork");
    this.autoAuthenticateApi();
  }

  isTokenExpired() {
    return new Promise((resolve) => {
      let __token = store.getters["operatorsTalqui/getToken"];
      if (typeof __token === "string") {
        var $decoded = this.decodeToken(__token);
        if (($decoded || {}).iat) {
          let _now = Math.floor(new Date().getTime() / 1000);
          let _iat = Math.floor(new Date($decoded.iat * 1000).getTime() / 1000);
          if ((_now - _iat) / 3600 < AUTHORIZATION_HOURS) {
            return resolve(false);
          }
        }
      }
      return resolve(true);
    });
  }

  autoAuthenticateApi() {
    // console.log("@class:auth:autoAuthenticateApi");
    let __token = store.getters["operatorsTalqui/getToken"];
    if (typeof __token === "string") {
      this.authenticateApi({ token: __token });
      this.startAvailabilityHeartbeat();
    }
  }

  startAvailabilityHeartbeat() {
    if (localStorage) {
      localStorage.removeItem($env.TALQUI_HEARTBEAT_KEY);
    }

    // this.doHeartbeat(true);
    this.stopAvailabilityHeartbeat();

    /**
     * Evaluate if should call API notification
     * that i'm online each minute (60000 milliseconds)
     */
    this.__heartbeatScheduler = setInterval(() => {
      // s.default.getters["common/isConnected"] &&
      //   !s.default.getters["common/isSafeModeEnabled"] &&
      this.doHeartbeat();
    }, INTERVAL_ATTEMP_CALL_API);
  }

  stopAvailabilityHeartbeat() {
    if (this.__heartbeatScheduler) {
      clearInterval(this.__heartbeatScheduler);
      this.__heartbeatScheduler = null;
    }
  }

  async doHeartbeat(forceHeartbeat = false) {
    return Promise.resolve().then(async () => {
      if (true !== this.heartbeatDisabled) {
        let e = Date.now();
        this.heartbeatLast = e;
        let shouldCallAPI = true;

        if (forceHeartbeat === false) {
          let lastHeartbeat = parseInt(
            (localStorage && localStorage.getItem($env.TALQUI_HEARTBEAT_KEY)) ||
              "0",
            10,
          );

          /**
           * Disabling api call if interval since lastHeartbeat
           * is lower than 4 minutes (240000milliseconds)
           */
          if (e > 0 && e - lastHeartbeat < DISABLE_API_CALL_LOWER_THAN) {
            shouldCallAPI = false;
          }
        }

        if (shouldCallAPI === true) {
          if (localStorage) {
            localStorage.setItem($env.TALQUI_HEARTBEAT_KEY, "" + e);
          }
          let __desiredStatus =
            store.getters["operatorsTalqui/getDesiredAvailability"];
          await this.changeAvailability(
            __desiredStatus,
            INTERVAL_NOTIFICATED_API,
          );
        }
      }
      return Promise.resolve();
    });
  }

  async changeAvailability(type, time = 0) {
    let requestBody = {
      status: type,
    };
    if (time && time > 0) {
      requestBody.time = {
        for: time,
      };
    }
    await store.dispatch("operatorsTalqui/operatorAvailability", requestBody);
  }
}

export default new _auth();
