<template>
  <div class="c-base-commands">
    <span class="c-base-commands__command">
      <BaseIcon
        class="c-base-commands__icon"
        name="unfold_more"
        size="14px"
      ></BaseIcon>
      <span class="c-base-commands__text">{{
        this.$t("BASES.COMMANDS.SELECTOR_KEYS_NAVIGATE")
      }}</span>
    </span>

    <span class="c-base-commands__command">
      <BaseIcon
        class="c-base-commands__icon"
        name="keyboard_return"
        size="14px"
      ></BaseIcon>
      <span class="c-base-commands__text">{{
        this.$t("BASES.COMMANDS.SELECTOR_KEYS_SELECT")
      }}</span>
    </span>

    <span class="c-base-commands__command">
      <span class="c-base-commands__control">esc</span>
      <span class="c-base-commands__text">{{
        this.$t("BASES.COMMANDS.SELECTOR_KEYS_EXIT")
      }}</span>
    </span>
  </div>
</template>
<style lang="css" scoped>
.c-base-commands {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  color: #3f536e;
  font-size: 11px;
}

.c-base-commands .c-base-commands__command {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 15px;
}

.c-base-commands .c-base-commands__command:last-of-type {
  margin-right: 0;
}

.c-base-commands .c-base-commands__command > * {
  margin-right: 3px;
}

.c-base-commands .c-base-commands__command > :last-of-type {
  margin-right: 0;
}

.c-base-commands .c-base-commands__command .c-base-commands__icon {
  fill: #3f536e;
}

.c-base-commands .c-base-commands__command .c-base-commands__control {
  font-weight: 700;
}

.c-base-commands .c-base-commands__command .c-base-commands__text {
  margin-left: 2px;
  color: #75869c;
}
</style>
