// import a from "thenby";
import thenby from "thenby";
import assign from "lodash.assign";
import session from "@/classes/talqui/session";
import adapterMessages from "@/adapters/messages";
import $env from "@/enviroment";
import route from "@/router";

const SESSION_PRONOUMS = [
  "Entusiasta",
  "Explorador",
  "Aventureiro",
  "Viajante",
  "Descobridor",
  "Desbravador",
  "Curioso",
  "Pioneiro",
  "Desbravador",
  "Inovador",
  "Destemido",
  "Corajoso",
  "Admirador",
];

export default {
  namespaced: !0,
  state: {
    sessions: [],
    sessionsMap: {},
    currentSessionID: null,
  },
  getters: {
    getList: (state) => (tenantID) => state.sessions[tenantID],
    getSession: (state) => (tenantID, sessionID) =>
      state.sessionsMap[tenantID][sessionID],
    getSessionIndex: (e) => (tenantID, sessionID) =>
      (e.sessions[tenantID] || []).findIndex((e) => e.sessionID === sessionID),
    getMessageFingerprints: () => (session) =>
      (session.messages || []).map((message) => message.messageFingerprint),
    getSessionReference: (state) => (tenantID, sessionID) =>
      state.sessionsMap[tenantID][sessionID],
    getCurrentSessionID: (state) => state.currentSessionID,
    getCurrentSession: (state) => (tenantID) =>
      state.sessionsMap[tenantID][state.currentSessionID],
  },
  actions: {
    acquireSessions(
      { getters, commit },
      {
        tenantID: _tenantID,
        page: a = 1,
        perPage: _perPage = 100,
        filterTab: _tab = "auto",
        sessionKind = "both",
        filterAssigned = false,
        filterUnassigned = false,
        searchFilters = null,
      },
    ) {
      let $sessions = [];
      let _query = {
        page: a,
        perPage: _perPage,
        sessionKind,
      };

      if (_tab != "auto") {
        _query.tab = _tab;
      }
      if (filterAssigned) {
        _query.filter_assigned = filterAssigned;
      }
      if (true === filterUnassigned) {
        _query.filter_unassigned = "1";
      }
      if (null !== searchFilters) {
        _query.search_type = "filter";
        _query.search_query = JSON.stringify(searchFilters.filter);
        _query.search_operator = searchFilters.operator || "and";
      }

      return Promise.resolve()
        .then(() => session.sessionList(_tenantID).get(_query))
        .then((_sessions) => {
          $sessions = _sessions || [];
          commit("assertTenantSessions", {
            tenantID: _tenantID,
          });
          Promise.resolve();
        })
        .then(() => {
          if ($sessions.length > 0) {
            $sessions.forEach((_session) => {
              commit("storeSession", {
                tenantID: _tenantID,
                sessionID: _session.sessionID,
                session: _session,
              });
            });
          }
        })
        .then(() => {
          let _sessionList = getters.getList(_tenantID);
          return Promise.resolve({
            hasNext: $sessions.length === _perPage,
            list: _sessionList || [],
          });
        });
    },

    acquireFullSession({ commit, getters }, { tenantID, sessionID }) {
      let $session = getters.getSession(tenantID, sessionID);
      if ($session._$.isFullLoaded !== true) {
        return session
          .sessionOne(tenantID, sessionID)
          .get()
          .then((res) => {
            $session._$.isFullLoaded = true;
            $session.contact = res.session.contact;

            if ($session.contact.contactFirstname === "") {
              var __inc = 0;
              for (let a = 0; a < $session.sessionID.length; a++) {
                __inc += $session.sessionID.charCodeAt(a);
              }
              $session.contact.contactFirstname = `Visitante #${
                SESSION_PRONOUMS[__inc % SESSION_PRONOUMS.length]
              }`;
            }

            commit("storeSession", {
              tenantID: tenantID,
              sessionID: sessionID,
              session: assign($session, res.session),
            });
            return Promise.resolve($session);
          });
      } else {
        return Promise.resolve($session);
      }
    },

    acquireMessages(
      { getters, commit },
      { tenantID, sessionID, firstMessageTimestamp = Date.now },
    ) {
      this.commit("sessionsTalqui/assertSession", {
        tenantID,
        sessionID,
      });
      let $session = getters.getSession(tenantID, sessionID);
      return session
        .sessionMessages(tenantID, sessionID, firstMessageTimestamp)
        .then((messages) => {
          commit("upsertSessionMessages", {
            session: $session,
            messages: messages,
          });
          commit("markMessagesPaging", {
            session: $session,
            messages: messages,
          });
          return Promise.resolve(messages);
        });
    },

    setSessionOpen({ commit, getters }, { tenantID, sessionID }) {
      commit("setCurrentSessionID", { value: sessionID });
      let $session = getters.getSession(tenantID, sessionID);
      $session._$.hasUnreadMessages = false;
      return session.sessionOne(tenantID, sessionID).one("open").patch();
    },

    // eslint-disable-next-line no-unused-vars
    deleteMessage({ commit }, { tenantID, sessionID, messageFingerprint }) {
      return session
        .sessionOne(tenantID, sessionID)
        .one("messages", messageFingerprint)
        .delete();
    },

    sessionSetRouting({ commit }, { tenantID, sessionID, operatorID }) {
      return session
        .sessionOne(tenantID, sessionID)
        .one("routing")
        .patch({
          operatorID: operatorID,
        })
        .then((res) => {
          commit("storeSession", {
            tenantID: tenantID,
            sessionID: sessionID,
            session: res.session,
          });

          /**
           * Forcing sessions tab change to where this conversation goes
           */
          if (
            res.session.sessionType === "manual" &&
            res.session.operatorID ===
              this.getters["operatorsTalqui/getMe"].operatorID
          ) {
            this.commit("inboxTalqui/setActiveFilter", {
              value: "mine",
            });
          } else {
            this.commit("inboxTalqui/setActiveFilter", {
              value: res.session.sessionType,
            });
          }
        });
    },

    sessionSetClosed(
      { commit },
      { tenantID, sessionID, closeMotive, closeMotiveDescription = null },
    ) {
      return session
        .sessionOne(tenantID, sessionID)
        .one("close")
        .patch({
          closeMotive: closeMotive,
          closeMotiveDescription: closeMotiveDescription,
        })
        .then((res) => {
          commit("storeSession", {
            tenantID: tenantID,
            sessionID: sessionID,
            session: res.session,
          });
        });
    },

    sessionPushTag({ commit }, { tenantID, sessionID, tag }) {
      return session
        .sessionOne(tenantID, sessionID)
        .one("tags")
        .post({
          tag: tag,
        })
        .then(() => {
          commit("addSessionTag", {
            tenantID: tenantID,
            sessionID: sessionID,
            tag: tag,
          });
          return Promise.resolve();
        });
    },

    sessionDeleteTag({ commit }, { tenantID, sessionID, tag }) {
      return session
        .sessionOne(tenantID, sessionID)
        .one("tags")
        .delete({
          tag: tag,
        })
        .then(() => {
          commit("removeSessionTag", {
            tenantID: tenantID,
            sessionID: sessionID,
            tag: tag,
          });
          return Promise.resolve();
        });
    },

    sessionStart(
      { commit },
      {
        tenantID,
        channel,
        contactID = null,
        contactExternalID = null,
        contactFullName = "",
        messageKey = "text",
        messageValue,
      },
    ) {
      return session
        .sessionBase(tenantID)
        .post({
          ...(contactID ? { contactID: contactID } : {}),
          ...(contactExternalID
            ? { contactExternalID: contactExternalID }
            : {}),
          ...(contactFullName !== ""
            ? {
                contactFullName: contactFullName,
              }
            : {}),
          channel,
          messageKey,
          messageValue,
        })
        .then((res) => {
          commit("storeSession", {
            tenantID: tenantID,
            sessionID: res.data.session.sessionID,
            session: {
              ...res.data.session,
              contact: res.data.contact,
            },
          });

          return Promise.resolve(res.data);
        });
    },
  },
  mutations: {
    assertTenantSessions(state, { tenantID }) {
      if ("object" !== typeof state.sessions[tenantID]) {
        state.sessions[tenantID] = [];
      }
      if ("object" !== typeof state.sessionsMap[tenantID]) {
        state.sessionsMap[tenantID] = {};
      }
    },
    assertSession(state, { tenantID, sessionID }) {
      if ("object" !== typeof state.sessionsMap[tenantID][sessionID]) {
        state.sessionsMap[tenantID][sessionID] = {
          tenantID: tenantID,
          sessionID: sessionID,
          contactID: null,
          createdAt: -1,
          sessionActive: true,
          sessionStatus: 0,
          sessionChannel: "telegram",
          sessionLastMessageID: null,
          sessionMeta: {},
          sessionRating: {
            comment: null,
            stars: 0,
          },
          sessionTags: [],
          sessionType: "auto",
          updatedAt: -1,
          contact: {},
          lastMessage: null,
          messages: [],
        };
      }

      state.sessionsMap[tenantID][sessionID]._$ =
        state.sessionsMap[tenantID][sessionID]._$ || {};
      state.sessionsMap[tenantID][sessionID]._$.operators =
        state.sessionsMap[tenantID][sessionID]._$.operators || {};
      state.sessionsMap[tenantID][sessionID]._$.firstMessageTimestamp =
        state.sessionsMap[tenantID][sessionID]._$.firstMessageTimestamp || -1;
      state.sessionsMap[tenantID][sessionID]._$.boxFieldStatus =
        state.sessionsMap[tenantID][sessionID]._$.boxFieldStatus || "reply";
      state.sessionsMap[tenantID][sessionID]._$.hasUnreadMessages =
        state.sessionsMap[tenantID][sessionID]._$.hasUnreadMessages || false;
      state.sessionsMap[tenantID][sessionID]._$.uploading =
        state.sessionsMap[tenantID][sessionID]._$.uploading || false;
    },
    storeSession(state, { tenantID, sessionID, session }) {
      this.commit("sessionsTalqui/assertSession", {
        tenantID,
        sessionID,
      });

      let $session = this.getters["sessionsTalqui/getSession"](
        tenantID,
        sessionID,
      );

      Object.assign($session, {
        ...session,
        contact: {
          ...$session.contact,
          ...session.contact,
        },
      });

      if ($session.contact.contactFirstname === "") {
        var __inc = 0;
        for (let a = 0; a < $session.sessionID.length; a++) {
          __inc += $session.sessionID.charCodeAt(a);
        }
        $session.contact.contactFirstname = `Visitante #${
          SESSION_PRONOUMS[__inc % SESSION_PRONOUMS.length]
        }`;
      }

      this.commit("sessionsTalqui/postStoreSession", {
        tenantID,
        sessionID,
        session: $session,
      });
    },
    postStoreSession(state, { tenantID, sessionID, session }) {
      let _sessionIndex = this.getters["sessionsTalqui/getSessionIndex"](
        tenantID,
        sessionID,
      );
      if (_sessionIndex < 0) {
        state.sessions[tenantID].push(session);
      } else {
        state.sessions[tenantID][_sessionIndex] = session;
      }

      this.commit("sessionsTalqui/orderSessions", {
        tenantID,
      });
    },
    orderSessions(state, { tenantID }) {
      if (state.sessions[tenantID]) {
        // const currentSessionsTab =
        //   this.getters["inboxTalqui/getActiveFilter"] || null;

        // if (currentSessionsTab === "queued") {
        //   state.sessions[tenantID].sort(thenby.firstBy("queuedAt", 1));
        // } else if (["manual", "mine"].includes(currentSessionsTab)) {
        //   state.sessions[tenantID].sort(thenby.firstBy("manualAt", 1));
        // } else {
        state.sessions[tenantID].sort(thenby.firstBy("updatedAt", -1));
        // }
      }
    },

    setCurrentSessionID(state, { value }) {
      state.currentSessionID = value;
    },

    upsertSessionMessages(state, { session, messages }) {
      session.messages = session.messages || [];
      for (let a = 0; a < messages.length; a++) {
        this.commit("sessionsTalqui/storeSessionMessage", {
          session: session,
          message: messages[a],
        });
      }
    },

    addMessage(state, { message }) {
      let $session = this.getters["sessionsTalqui/getSession"](
        message.tenantID,
        message.sessionID,
      );
      this.commit("sessionsTalqui/storeSessionMessage", {
        session: $session,
        message: message,
        isNew: true,
      });
    },

    removeMessage(state, { tenantID, sessionID, messageFingerprint }) {
      let sessionRef = this.getters["sessionsTalqui/getSessionReference"](
        tenantID,
        sessionID,
      );
      let messageIndex = sessionRef.messages.findIndex(
        (message) => message.messageFingerprint === messageFingerprint,
      );
      if (messageIndex !== -1) {
        sessionRef.messages.splice(messageIndex, 1);
      }

      let $fingerprints =
        this.getters["sessionsTalqui/getMessageFingerprints"](session);
      let messageFingerprintIndex = $fingerprints.indexOf(messageFingerprint);
      if (messageFingerprintIndex !== -1) {
        $fingerprints.splice(messageFingerprintIndex, 1);
      }
    },

    storeSessionMessage(state, { session, message, isNew = false }) {
      let $fingerprints =
        this.getters["sessionsTalqui/getMessageFingerprints"](session);
      if (
        isNew &&
        message.messageDirection === "outbound" &&
        typeof message.operatorID === "string"
      ) {
        message["operator"] =
          this.getters["operatorsTalqui/getOperator"](
            message.tenantID,
            message.operatorID,
          ) || null;
      }

      message._$ = message._$ || {};
      message._$.isNew = isNew;

      /**
       * Push or upsert message in state
       */
      const messageIndex = $fingerprints.indexOf(message.messageFingerprint);
      if (messageIndex === -1) {
        session.messages[isNew ? "push" : "unshift"](message);
      } else {
        let messageInStore = session.messages[messageIndex];
        Object.assign(messageInStore, message);
      }

      if (isNew) {
        session._$.hasUnreadMessages =
          route.currentRoute.value.params.sessionId !== session.sessionID;

        session.lastMessage =
          adapterMessages.messageToSessionLastMessage(message);

        session.sessionStatus = message.messageDirection === "inbound" ? 0 : 1;

        this.commit("sessionsTalqui/orderSessions", {
          tenantID: session.tenantID,
        });
      }
    },

    markMessagesPaging(state, { session, messages }) {
      session._$.firstMessageTimestamp =
        206 === messages.__status && messages.length > 0
          ? new Date(messages[messages.length - 1].createdAt).getTime() || -1
          : -1;
    },

    addSessionTag(state, { tenantID, sessionID, tag }) {
      let sessionRef = this.getters["sessionsTalqui/getSessionReference"](
        tenantID,
        sessionID,
      );
      if (!sessionRef.sessionTags.includes(tag)) {
        sessionRef.sessionTags.push(tag);
      }
    },

    removeSessionTag(state, { tenantID, sessionID, tag }) {
      let sessionRef = this.getters["sessionsTalqui/getSessionReference"](
        tenantID,
        sessionID,
      );
      sessionRef.sessionTag = sessionRef.sessionTags.filter((item) => {
        return item !== tag;
      });
    },

    setReadMessage(state, { tenantID, sessionID, messageFingerprint }) {
      let sessionRef = this.getters["sessionsTalqui/getSessionReference"](
        tenantID,
        sessionID,
      );
      let $stateMessage = (sessionRef?.messages || []).find(
        (message) => message.messageFingerprint === messageFingerprint,
      );
      if ($stateMessage) {
        $stateMessage["read"] = {
          fingerprint: new Date().getTime(),
          medium: $stateMessage.messageChannel,
        };
        $stateMessage["messageStatus"] = $env.TALQUI.MESSAGES.STATUSES.READ;
      }
    },

    setDeliveredMessage(state, { tenantID, sessionID, messageFingerprint }) {
      let sessionRef = this.getters["sessionsTalqui/getSessionReference"](
        tenantID,
        sessionID,
      );
      let $stateMessage = (sessionRef?.messages || []).find(
        (message) => message.messageFingerprint === messageFingerprint,
      );
      if ($stateMessage) {
        $stateMessage["delivered"] = {
          fingerprint: new Date().getTime(),
          medium: $stateMessage.messageChannel,
        };
        $stateMessage["messageStatus"] =
          $env.TALQUI.MESSAGES.STATUSES.DELIVERED;
      }
    },

    setMessageTranscription(
      state,
      { tenantID, sessionID, messageFingerprint, transcription },
    ) {
      let sessionRef = this.getters["sessionsTalqui/getSessionReference"](
        tenantID,
        sessionID,
      );
      let $stateMessage = (sessionRef?.messages || []).find(
        (message) => message.messageFingerprint === messageFingerprint,
      );
      if ($stateMessage) {
        $stateMessage.messageValue["transcription"] = transcription;
      }
    },

    updateSessionBoxFieldStatus(state, { tenantID, sessionID, status }) {
      state.sessionsMap[tenantID][sessionID]._$.boxFieldStatus = status;
    },

    updateSessionUploading(state, { tenantID, sessionID, value }) {
      state.sessionsMap[tenantID][sessionID]._$.uploading = value;
    },
  },
};
