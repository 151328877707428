<template>
  <div
    :class="[
      'base-heading',
      'base-heading--' + computedColor,
      'base-heading--' + computedFontWeight,
      'base-heading--' + type,
    ]"
  >
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: "black",
      validator(e) {
        return -1 !== ["black", "blue", "green", "grey", "red"].indexOf(e);
      },
    },
    fontWeight: {
      type: String,
      default: null,
      validator(e) {
        return (
          -1 !==
          ["thin", "light", "regular", "medium", "bold", "extrabold"].indexOf(e)
        );
      },
    },
    type: {
      type: String,
      required: !0,
      validator(e) {
        return -1 !== ["h1", "h2", "h3", "p", "small", "tiny"].indexOf(e);
      },
    },
  },
  computed: {
    computedColor() {
      if (this.color) return this.color;
      switch (this.type) {
        case "h3":
          return "blue";
        default:
          return "black";
      }
    },
    computedFontWeight() {
      if (this.fontWeight) return this.fontWeight;
      switch (this.type) {
        case "h1":
          return "bold";
        case "h2":
          return "medium";
        case "h3":
          return "medium";
        default:
          return "regular";
      }
    },
  },
};
</script>
<style lang="scss">
.base-heading--black {
  color: #1c293b;
}
.base-heading--blue {
  color: #0093ee;
}
.base-heading--green {
  color: #40b630;
}
.base-heading--grey {
  color: #75869c;
}
.base-heading--red {
  color: #e0102b;
}
.base-heading--thin {
  font-weight: 100;
}
.base-heading--light {
  font-weight: 300;
}
.base-heading--regular {
  font-weight: 400;
}
.base-heading--medium {
  font-weight: 500;
}
.base-heading--bold {
  font-weight: 700;
}
.base-heading--extrabold {
  font-weight: 800;
}
.base-heading--h1 {
  font-size: 20px;
  line-height: 30px;
}
.base-heading--h2 {
  font-size: 18px;
  line-height: 28px;
}
.base-heading--h3 {
  font-size: 16px;
  line-height: 26px;
}
.base-heading--p {
  font-size: 15px;
  line-height: 20px;
}
.base-heading--small {
  font-size: 14px;
  line-height: 22px;
}
.base-heading--tiny {
  font-size: 13.5px;
  line-height: 16px;
}
</style>
