<template>
  <div class="c-base-tabs">
    <router-link
      v-for="item in tabs"
      :key="item.id"
      class="c-base-tabs__tab"
      v-on:click="(t) => onTabClick(item.id)"
      :to="item.link"
    >
      <BaseIcon
        class="c-base-tabs__icon"
        :name="item.icon"
        size="16px"
      ></BaseIcon>
      <span class="c-base-tabs__label">{{ item.label }}</span>
    </router-link>
  </div>
</template>
<script>
export default {
  props: {
    tabs: {
      type: Array,
      required: !0,
    },
  },
  emits: ["click"],
  methods: {
    onTabClick(e) {
      this.$emit("click", e);
    },
  },
};
</script>
<style lang="scss">
.c-base-tabs {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  .c-base-tabs__tab {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 1px;
    padding: 0 12px;
    cursor: pointer;
    .c-base-tabs__icon {
      margin-top: -2px;
      margin-right: 6px;
      fill: #75869c;
      -webkit-transition: fill 0.15s linear;
      transition: fill 0.15s linear;
    }
    .c-base-tabs__label {
      margin-top: -2px;
      color: #75869c;
      font-weight: 700;
      font-size: 15px;
      -webkit-transition: color 0.15s linear;
      transition: color 0.15s linear;
    }
    &:after {
      position: absolute;
      right: 0;
      bottom: -1px;
      left: 0;
      height: 0;
      background-color: #0093ee;
      content: "";
      -webkit-transition: height 0.1s linear;
      transition: height 0.1s linear;
    }
    &:hover {
      .c-base-tabs__icon {
        fill: #2c405a;
      }
      .c-base-tabs__label {
        color: #2c405a;
      }
    }
    &:active {
      .c-base-tabs__icon {
        fill: #1c293b;
      }
      .c-base-tabs__label {
        color: #1c293b;
      }
    }
  }
  .c-base-tabs__tab.is-exact-active {
    .c-base-tabs__icon {
      fill: #2c405a;
    }
    .c-base-tabs__label {
      color: #2c405a;
    }
    &:after {
      height: 3px;
    }
  }
}
</style>
