<template>
  <div
    :class="[
      'c-field-toggle',
      {
        'c-field-toggle--checked': currentValue,
        'c-field-toggle--disabled': disabled,
        'c-field-toggle--full-width': fullWidth,
      },
    ]"
  >
    <div class="c-field-toggle__container">
      <div
        class="c-field-toggle__field"
        v-on:click="(...e) => onFieldClick(...e)"
      >
        <span class="c-field-toggle__background"></span
        ><span class="c-field-toggle__handle"></span>
      </div>
      <FieldLabel
        v-if="label"
        class="c-field-toggle__label"
        v-on:click="onFieldClick"
        :for-field="uuid"
        :uppercase="!1"
      >
        {{ label }}
      </FieldLabel>
    </div>
    <FieldDescription
      v-if="description"
      :description="description"
    ></FieldDescription>
  </div>
</template>
<script>
import { v4 } from "uuid";
export default {
  props: {
    description: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: !1,
    },
    fullWidth: {
      type: Boolean,
      default: !0,
    },
    label: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: !1,
    },
    modelValue: {
      type: Boolean,
      default: !1,
    },
  },
  emits: ["update:modelValue", "change"],
  data() {
    return {
      uuid: "",
      currentValue: null,
    };
  },
  watch: {
    modelValue: {
      immediate: !0,
      handler(e) {
        this.currentValue = e;
      },
    },
  },
  mounted() {
    this.uuid = v4();
  },
  methods: {
    onFieldClick() {
      this.currentValue = !this.currentValue;
      this.$emit("update:modelValue", this.currentValue);
      this.$emit("change", this.currentValue, this.name || this.uuid, event);
    },
  },
};
</script>
<style lang="scss">
.c-field-toggle {
  display: block;
  text-align: left;
  .c-field-toggle__container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    .c-field-toggle__field {
      position: relative;
      margin-bottom: 0;
      width: 42px;
      height: 24px;
      outline: 0;
      border: none;
      border-radius: 100px;
      cursor: pointer;
      -webkit-appearance: none;
      .c-field-toggle__background {
        position: absolute;
        display: inline-block;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border: none;
        -webkit-transition: all 0.1s linear;
        transition: all 0.1s linear;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 20px;
        background-color: rgba(117, 134, 156, 0.4);
        content: "";
      }
      .c-field-toggle__handle {
        position: absolute;
        display: inline-block;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border: none;
        -webkit-transition: all 0.1s linear;
        transition: all 0.1s linear;
        top: 4px;
        margin-left: 7px;
        width: 16px;
        height: 16px;
        border: none;
        border-radius: 100%;
        background: #fff;
        content: "";
        -webkit-transform: translateX(-2px);
        transform: translateX(-2px);
      }
    }
    .c-field-toggle__label {
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
      margin-bottom: 0;
      color: #1c293b;
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      -webkit-transition: color 0.1s linear;
      transition: color 0.1s linear;
    }
  }
  &:not(.c-field-toggle--checked) {
    .c-field-toggle__container {
      .c-field-toggle__label {
        color: #75869c;
      }
    }
  }
  &:hover {
    .c-field-toggle__container {
      .c-field-toggle__field {
        .c-field-toggle__background {
          background-color: rgba(117, 134, 156, 0.5);
        }
      }
    }
  }
  &:active {
    .c-field-toggle__container {
      .c-field-toggle__field {
        .c-field-toggle__background {
          background-color: rgba(117, 134, 156, 0.6);
        }
      }
    }
  }
}
.c-field-toggle--checked {
  .c-field-toggle__container {
    .c-field-toggle__field {
      .c-field-toggle__background {
        background-color: #40b630;
      }
      .c-field-toggle__handle {
        -webkit-transform: translateX(14px);
        transform: translateX(14px);
      }
    }
  }
  &:hover {
    .c-field-toggle__container {
      .c-field-toggle__field {
        .c-field-toggle__background {
          background-color: #3aa62c !important;
        }
      }
    }
  }
  &:active {
    .c-field-toggle__container {
      .c-field-toggle__field {
        .c-field-toggle__background {
          background-color: #359627 !important;
        }
      }
    }
  }
}
.c-field-toggle--disabled {
  cursor: not-allowed;
  .c-field-toggle__container {
    pointer-events: none;
    .c-field-toggle__field {
      opacity: 0.35;
    }
  }
}
.c-field-toggle--full-width {
  width: 100%;
  .c-field-toggle__container {
    .c-field-toggle__field {
      margin-left: 8px;
    }
  }
}
</style>
