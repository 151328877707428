import a from "../store/index.js";
import o from "socket.io-client";

import isEqual from "lodash.isequal";

import * as r from "@/enviroment.js";
import s from "./platform.js";
import l from "./focus.js";
import c from "./userAuth.js";
import d from "./websites.js";

const M = 1e3,
  g = 2e3,
  p = 1e4,
  b = 4e4,
  m = 15e3,
  N = 3e5,
  I = 2,
  f = 12e4,
  x = /^app\.(.+)$/;

class _auth {
  constructor() {
    this.__countReconnect = 0;
    this.__timeoutReconnect = null;
    this.__timeoutIdleApply = null;
    this.__onAuthenticate = null;
    this.__listeners = {};
    this.available = !1;
    this.prepared = !1;
    this.isIdle = !1;
    this.lastConnected = 0;
    this.tokenIdentifier = null;
    this.tokenKey = null;
    this.events = r.default.CRISP_SOCKET_EVENTS.COMMON;
    this.rooms = null;
    this.routing = null;
    this.affinity = null;
    this.__socket = null;
    this.__listeners = {};
    this.__affinityURLs = {};
    this.__affinityURLDefault = new URL(r.default.CRISP_URL_RELAY_APP);
    this.__listen();
  }
  authenticate(e, t) {
    this.tokenIdentifier = e;
    this.tokenKey = t;
    null !== this.__onAuthenticate &&
      (this.__onAuthenticate(e, t), (this.__onAuthenticate = null));
  }
  on(e, t) {
    (this.__listeners[e] = this.__listeners[e] || []),
      this.__listeners[e].push(t),
      null !== this.__socket && this.__socket.on(e, t);
  }
  emit(e, t) {
    if (null === this.__socket)
      throw new Error(`Cannot emit [${e}]: socket is not yet bound`);
    this.__socket.emit(e, t);
  }
  bind(e, t = null) {
    let i = e.match(x);
    if (i && i[1]) {
      let e = i[1].toUpperCase().split("."),
        o = [],
        s = null,
        l = null,
        c = r.default.CRISP_SOCKET_EVENTS,
        d = null,
        u = 0;
      while (c)
        c.COMMON && (o = o.concat(c.COMMON)),
          (d = e[u++]),
          d
            ? ((c = c[d]),
              c && c instanceof Array && ((o = o.concat(c)), (c = null)))
            : (c = null);
      if (
        ((o = o.filter((e, t) => o.indexOf(e) >= t)),
        t && (l = [t]),
        (s = a.default.getters["inbox/getActiveUserRouting"]
          ? [a.default.getters["inbox/getActiveUserRouting"]]
          : null === a.default.getters["inbox/getActiveUserRouting"]
          ? null
          : []),
        !isEqual(this.events, o) ||
          !isEqual(this.rooms, l) ||
          !isEqual(this.routing, s) ||
          this.affinity !== t ||
          null === this.__socket)
      ) {
        let e = null === this.__socket || this.affinity !== t;
        (this.events = o),
          (this.rooms = l),
          (this.routing = s),
          (this.affinity = t),
          !0 === e
            ? (this.__disconnect(!0),
              this.tokenIdentifier && this.tokenKey
                ? this.__connect()
                : (this.__onAuthenticate = this.__connect))
            : this.__onAvailable(!0);
      }
    }
  }
  mayReload(e) {
    return Date.now() - this.lastConnected >= e;
  }
  onViewFocus() {
    this.__delayIdleApply(!1);
  }
  onViewBlur() {
    this.__delayIdleApply(!0);
  }
  addListener(e, t) {
    this.__listeners[e] || (this.__listeners[e] = []),
      this.__listeners[e].push(t);
  }
  removeListener(e, t) {
    if (this.__listeners[e])
      for (let i in this.__listeners[e])
        if (this.__listeners[e][i] === t) {
          delete this.__listeners[e][i];
          break;
        }
  }
  __connect() {
    if (!this.tokenIdentifier || !this.tokenKey)
      throw new Error("Cannot connect socket at this point: token not set!");
    Promise.resolve()
      .then(() =>
        null === this.__socket
          ? this.__prepareSocket(this.affinity)
          : Promise.resolve(this.__socket),
      )
      .then((e) => {
        e.connect();
      });
  }
  __disconnect(e = !1) {
    null !== this.__socket &&
      (!0 === e && (this.__socket.removeAllListeners(), (this.prepared = !1)),
      this.__socket.disconnect(),
      !0 === e && (this.__socket = null));
  }
  __listen() {
    this.__listenSocket(),
      this.__listenWindow(),
      this.__scheduleMaintainReconnect();
  }
  __listenSocket() {
    this.on("authenticated", () => {
      (this.available = !0), this.__onAvailable();
    }),
      this.on("disconnect", () => {
        (this.available = !1), this.__disconnected();
      }),
      this.on("connect", () => {
        (this.__countReconnect = 0),
          a.default.commit("common/setConnected", {
            value: !0,
          }),
          setTimeout(() => {
            this.lastConnected = Date.now();
          }, M),
          !0 === a.default.getters["common/isReconnecting"] &&
            this.__reconnected(),
          this.tokenIdentifier &&
            this.tokenKey &&
            this.emit("authentication", {
              username: this.tokenIdentifier,
              password: this.tokenKey,
              events: this.events,
              rooms: this.rooms,
              routing: this.routing,
            });
      });
  }
  __listenWindow() {
    window.addEventListener("offline", () => {
      this.__disconnect();
    }),
      window.addEventListener("online", () => {
        this.__reconnectIfNeeded(!0);
      });
  }
  __prepareSocket(e = null) {
    return Promise.resolve()
      .then(() =>
        null !== e
          ? this.__affinityURLs[e]
            ? Promise.resolve(this.__affinityURLs[e])
            : this.__pullAffinityURL(e)
          : Promise.resolve(this.__affinityURLDefault),
      )
      .then(
        (t) => (null !== e && (this.__affinityURLs[e] = t), Promise.resolve(t)),
      )
      .then((e) => {
        (this.__socket = new o(e.origin, {
          path: e.pathname,
          transports: ["websocket"],
          reconnection: !1,
          autoConnect: !1,
        })),
          (this.prepared = !0);
        for (let t in this.__listeners) {
          let e = this.__listeners[t] || [];
          for (let i = 0; i < e.length; i++) this.__socket.on(t, e[i]);
        }
        return Promise.resolve(this.__socket);
      });
  }
  __scheduleMaintainReconnect() {
    setInterval(() => {
      this.__reconnectIfNeeded();
    }, m);
  }
  __onAvailable(e = !1) {
    if (!0 === this.available && this.events && !0 === e) {
      let e = {
        events: this.events,
      };
      null !== this.rooms && (e.rooms = this.rooms),
        (e.routing = this.routing),
        this.emit("socket:bind", e);
    }
  }
  __onIdle() {
    if (!0 === this.available && this.events) {
      let e = [];
      if (
        (this.events.forEach((t) => {
          -1 !== r.default.CRISP_SOCKET_IDLE.indexOf(t) && e.push(t);
        }),
        e.length > 0)
      ) {
        let t = !0 === this.isIdle ? "pop" : "push";
        this.emit("socket:bind:events:" + t, {
          events: e,
        });
      }
    }
  }
  __delayIdleApply(e) {
    null !== this.__timeoutIdleApply &&
      (clearTimeout(this.__timeoutIdleApply), (this.__timeoutIdleApply = null)),
      (this.__timeoutIdleApply = setTimeout(
        () => {
          (this.__timeoutIdleApply = null),
            this.isIdle !== e && ((this.isIdle = e), this.__onIdle());
        },
        !0 === e ? f : 0,
      ));
  }
  __reconnectIfNeeded(e = !1) {
    !0 === this.prepared &&
      c.default.isLogged() &&
      !a.default.getters["common/isConnected"] &&
      this.__reconnect(e);
  }
  __reconnect(e = !1) {
    if (
      (this.__lastReconnect &&
        Date.now() - this.__lastReconnect > N &&
        ((e = !0), (this.__countReconnect = 0)),
      !0 === e &&
        (clearTimeout(this.__timeoutReconnect),
        (this.__timeoutReconnect = null)),
      null === this.__timeoutReconnect)
    ) {
      !1 !== window.navigator.onLine && this.__countReconnect++;
      let t = g + Math.ceil(Math.random() * p),
        i = ((this.__countReconnect || 1) - 1) * I;
      (t += Math.ceil(Math.random() * Math.min(p * i, b))),
        !0 === e && (t = g),
        a.default.getters["common/isConnected"] ||
          a.default.commit("common/setReconnecting", {
            value: !0,
          }),
        this.__disconnect(),
        (this.__timeoutReconnect = setTimeout(() => {
          (this.__lastReconnect = Date.now()),
            (this.__timeoutReconnect = null),
            this.__connect();
        }, t));
    }
  }
  __reconnected() {
    l.default.purgeFocusActions("reconnect"),
      l.default.runInFocus(
        "reconnect",
        () => {
          this.__triggerListener("reconnect");
        },
        s.default.isPlatform(),
      ),
      a.default.commit("common/setReconnecting", {
        value: !1,
      });
  }
  __disconnected() {
    l.default.purgeFocusActions("focus_reconnect"),
      l.default.runInFocus("focus_reconnect", () => {
        this.__reconnectIfNeeded();
      }),
      a.default.commit("common/setConnected", {
        value: !1,
      });
  }
  __triggerListener(e, t = {}) {
    if (this.__listeners[e])
      for (let i in this.__listeners[e]) this.__listeners[e][i](t);
  }
  __pullAffinityURL(e) {
    return d.default
      .connectEndpoints(e)
      .get()
      .then((e) =>
        e.socket && e.socket.app
          ? Promise.resolve(new URL(e.socket.app))
          : Promise.reject(),
      )
      .catch(() => Promise.resolve(this.__affinityURLDefault));
  }
}
export default new _auth();
