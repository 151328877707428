<template>
  <ValidationProvider
    :modelValue="modelValue"
    v-on:update:modelValue="(e) => (modelValue = e)"
    :name="name || uuid"
    :label="validationLabel"
    :rules="rules"
    :validate-on-input="!0"
    :value="currentValue"
    :class="[
      'c-field-textarea',
      'c-field-textarea--' + direction,
      'c-field-textarea--' + size,
      {
        'c-field-textarea--block': block,
        'c-field-textarea--bordered': hasBorders,
        'c-field-textarea--resizable': resizable,
        'c-field-textarea--focused': focused,
      },
    ]"
    as="div"
    v-slot="{ field, meta, errorMessage }"
  >
    <div v-if="label" class="c-field-textarea__header">
      <FieldLabel
        class="c-field-textarea__label"
        :direction="direction"
        :for-field="uuid"
        :required="rules && -1 !== rules.indexOf('required')"
        :size="size"
      >
        {{ label }}
      </FieldLabel>
    </div>
    <div class="c-field-textarea__wrapper">
      <div
        v-on:click="(...e) => onContainerClick(...e)"
        :class="[
          'c-field-textarea__container',
          {
            'c-field-textarea__container--error':
              errorMessage && meta.dirty && !focused,
          },
        ]"
      >
        <textarea
          class="c-field-textarea__field js-field-textarea-field"
          v-bind="field"
          v-on:blur="(...e) => onTextareaBlur(...e)"
          v-on:focus="(...e) => onTextareaFocus(...e)"
          v-on:input="(...e) => onTextareaInput(...e)"
          v-on:keyup="(...e) => onTextareaKeyup(...e)"
          v-on:keydown="(...e) => onTextareaKeydown(...e)"
          :id="uuid"
          :name="name"
          :placeholder="placeholder"
          :readonly="readonly"
          :value="currentValue"
          :disabled="disabled"
          :minlength="minLength"
          :maxlength="maxLength"
          ref="textarea"
        ></textarea>
      </div>
    </div>
    <div
      v-if="errorMessage && meta.dirty && !focused"
      v-on:click="(...e) => focusField(...e)"
      class="c-field-textarea__error"
    >
      {{ errorMessage }}
    </div>
  </ValidationProvider>
</template>
<script>
import { v4 } from "uuid";
import inputMixin from "@/mixins/inputMixin.js";
export default {
  mixins: [inputMixin],
  props: {
    autofocus: {
      type: Boolean,
      default: !1,
    },
    block: {
      type: Boolean,
      default: !0,
    },
    disabled: {
      type: Boolean,
      default: !1,
    },
    direction: {
      type: String,
      default: "vertical",
      validator(e) {
        return -1 !== ["horizontal", "vertical"].indexOf(e);
      },
    },
    hasBorders: {
      type: Boolean,
      default: !0,
    },
    label: {
      type: String,
      default: null,
    },
    modelValue: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: !1,
    },
    resizable: {
      type: Boolean,
      default: !0,
    },
    rules: {
      type: [Object, String],
      default: null,
    },
    size: {
      type: String,
      default: "default",
    },
    trim: {
      type: Boolean,
      default: !0,
    },
  },
  emits: [
    "update:modelValue",
    "input",
    "click",
    "blur",
    "keyup",
    "keydown",
    "focus",
  ],
  data() {
    return {
      currentValue: "",
      focused: !1,
      uuid: null,
    };
  },
  watch: {
    modelValue: {
      immediate: !0,
      handler(e) {
        this.ignoreModelSync(e) || (this.currentValue = e);
      },
    },
  },
  created() {
    this.uuid = v4();
  },
  mounted() {
    this.autofocus && this.focusField();
  },
  methods: {
    focusField() {
      this.$refs.textarea.focus();
    },
    getInputValue(e = !0) {
      let t = this.$refs.textarea.value || "";
      return !0 === e && t && !0 === this.trim && (t = t.trim()), t;
    },
    onContainerClick(e) {
      this.focusField(),
        this.$emit("click", this.name || this.uuid, this.getInputValue(), e);
    },
    onTextareaInput(e) {
      (this.currentValue = this.getInputValue(!1)),
        this.$emit(
          "update:modelValue",
          this.getInputValue(),
          this.name || this.uuid,
          e,
        ),
        this.$emit("input", this.getInputValue(), this.name || this.uuid, e);
    },
    onTextareaBlur(e) {
      (this.focused = !1),
        this.$emit("blur", this.name, this.getInputValue(), e);
    },
    onTextareaKeyup(e) {
      this.$emit("keyup", this.name, this.getInputValue(), e);
    },
    onTextareaKeydown(e) {
      this.$emit("keydown", this.name, this.getInputValue(), e);
    },
    onTextareaFocus(e) {
      (this.focused = !0),
        this.$emit("focus", this.name, this.getInputValue(), e);
    },
  },
};
</script>
<style lang="scss">
.c-field-textarea {
  color: #2c405a;
  position: relative;
  text-align: left;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  .c-field-textarea__header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    .c-field-textarea__label {
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
    }
  }
  .c-field-textarea__wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .c-field-textarea__container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    overflow: hidden;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-color: #a8c6df;
    background-color: transparent;
    color: #a8c6df;
    &:hover {
      cursor: text;
    }
    .c-field-textarea__field {
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
      padding: 0 15px;
      width: 100%;
      height: 100%;
      border: none;
      background-color: transparent;
      resize: none;
      &::-webkit-input-placeholder {
        color: #75869c;
      }
      &::-moz-placeholder {
        color: #75869c;
      }
      &::-ms-input-placeholder {
        color: #75869c;
      }
      &::placeholder {
        color: #75869c;
      }
      &:disabled {
        background: rgba(168, 198, 223, 0.1);
        color: rgba(168, 198, 223, 0.8);
        cursor: not-allowed;
      }
      &:-moz-read-only {
        color: rgba(44, 64, 90, 0.8);
        background: rgba(168, 198, 223, 0.05);
      }
      &:read-only {
        color: rgba(44, 64, 90, 0.8);
        background: rgba(168, 198, 223, 0.05);
      }
      &:focus {
        outline: none;
      }
    }
  }
  .c-field-textarea__container--error {
    border-color: #e0102b !important;
  }
  .c-field-textarea__error {
    z-index: 10;
    position: absolute;
    min-width: 110px;
    line-height: 18px;
    background-color: #e0102b;
    color: #fff;
    font-weight: 700;
    font-size: 13.5px;
    cursor: text;
    padding: 2px 6px;
    border-radius: 2px;
  }
  &:hover {
    .c-field-textarea__error {
      visibility: hidden;
    }
  }
}
.c-field-textarea--mini {
  .c-field-textarea__container {
    border-radius: 2px;
    .c-field-textarea__field {
      padding: 10px;
      min-height: 80px;
      font-size: 12px;
    }
  }
  .c-field-textarea__error {
    top: 30px;
  }
}
.c-field-textarea--small {
  .c-field-textarea__container {
    border-radius: 3px;
    .c-field-textarea__field {
      padding: 11px;
      min-height: 84px;
      font-size: 13px;
    }
  }
  .c-field-textarea__error {
    top: 32px;
  }
}
.c-field-textarea--default {
  .c-field-textarea__container {
    border-radius: 4px;
    .c-field-textarea__field {
      padding: 12px;
      min-height: 88px;
      font-size: 14px;
    }
  }
  .c-field-textarea__error {
    top: 34px;
  }
}
.c-field-textarea--medium {
  .c-field-textarea__container {
    border-radius: 5px;
    .c-field-textarea__field {
      padding: 13px;
      min-height: 92px;
      font-size: 15px;
    }
  }
  .c-field-textarea__error {
    top: 36px;
  }
}
.c-field-textarea--large {
  .c-field-textarea__container {
    border-radius: 6px;
    .c-field-textarea__field {
      padding: 14px;
      min-height: 96px;
      font-size: 16px;
    }
  }
  .c-field-textarea__error {
    top: 38px;
  }
}
.c-field-textarea--horizontal {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  .c-field-textarea__header {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 140px;
  }
  .c-field-textarea__container {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .c-field-textarea__error {
    left: 150px;
  }
}
.c-field-textarea--vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  .c-field-textarea__error {
    left: 10px;
  }
}
.c-field-textarea--block {
  width: 100%;
}
.c-field-textarea--bordered {
  .c-field-textarea__container {
    border-width: 1px;
    border-style: solid;
    border-radius: 3px;
    background-color: #fff;
    -webkit-transition: border 0.2s linear;
    transition: border 0.2s linear;
  }
}
.c-field-textarea--error {
  .c-field-textarea__container {
    border-color: #e0102b;
  }
}
.c-field-textarea--resizable {
  .c-field-textarea__container {
    .c-field-textarea__field {
      resize: vertical;
    }
  }
}
.c-field-textarea--focused {
  .c-field-textarea__container {
    border-color: #2c405a;
    color: #2c405a;
    .c-field-textarea__field {
      &::-webkit-input-placeholder {
        color: #75869c;
      }
      &::-moz-placeholder {
        color: #75869c;
      }
      &::-ms-input-placeholder {
        color: #75869c;
      }
      &::placeholder {
        color: #75869c;
      }
    }
  }
}
</style>
