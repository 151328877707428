import organization from "@/classes/talqui/organization";
import store from "@/store/index";

export default {
  namespaced: !0,
  state: {
    organization: {},
  },
  getters: {
    getOrganization: (state) => state.organization,
  },
  actions: {
    organizationCreate({ commit }, { organizationName, organizationWebsite }) {
      return organization
        .organizationBase()
        .post({
          organizationName,
          organizationWebsite,
        })
        .then((res) => {
          store.dispatch("operatorsTalqui/refreshToken", {
            token: res.token,
          });
          commit("setOrganization", res.organization);
        });
    },
    organizationTenantsCreate(
      // eslint-disable-next-line no-empty-pattern
      {},
      { tenantName, tenantWebsite, tenantKind = "OTHERS" },
    ) {
      return organization
        .organizationBase()
        .one("tenants")
        .post({
          tenantName,
          tenantWebsite,
          tenantKind,
        })
        .then(async (res) => {
          await store.commit("tenantsTalqui/setCurrentTenant", res.tenant);
          return res;
        });
    },
  },
  mutations: {
    reset(state) {
      state.organization = {};
    },
    setOrganization(state, value) {
      state.organization = value;
    },
  },
};
