export default {
  SIDEBAR: {
    INBOX: "Inbox",
    VISITORS: "Visitors",
    ANALYTICS: "Analytics",
    PLUGINS: "Plugins",
    SETTINGS: "Settings",
    UPGRADE: "Upgrade",
    SETUP: "Setup",
    SWITCHER: {
      MENU_SUPPORT: "Get help using Crisp",
      MENU_GO_OFFLINE: "Enable invisible mode",
      MENU_GO_ONLINE: "Disable invisible mode",
      MENU_LOGOUT: "Logout from app",
      MENU_CREATE_WEBSITE: "Create a new website",
      MENU_ALERTS: "View alerts",
      MENU_MANAGE_ACCOUNT: "Manage account",
      MENU_INVITE_OPERATOR: "Invite an operator",
    },
    CAMPAIGNS: "Campaigns",
    HELPDESK: "Helpdesk",
    CONTACTS: "Contacts",
    SEARCH: "Search",
  },
  TITLES: {
    INBOX: "Inbox",
    VISITORS: "Visitors",
    ANALYTICS: "Analytics",
    PLUGINS: "Plugins",
    SETTINGS: "Settings",
    CAMPAIGNS: "Campaigns",
    HELPDESK: "Helpdesk",
    CONTACTS: "Contacts",
  },
  TOASTS: {
    INVALID_DATA:
      "The data you sent is invalid. Please correct it and try again.",
    NOT_ALLOWED: "You are not allowed to access this resource.",
    INVALID_SESSION:
      "Your session is invalid. You cannot access this resource.",
    AUTHENTICATED_ROUTE:
      "This resource is available for authenticated users only.",
    RATE_LIMITED:
      "Oops. Looks like you are rate limited for this resource. Try again later.",
    TIMED_OUT: "Access to the resource timed out. Try again later.",
    SERVICE_DEGRADED:
      "This action is temporarily unavailable, as Crisp is currently unable to honor your request. Try again in a few seconds.",
    PLAN_SUBSCRIPTION_REQUIRED:
      "A plan subscription is required to perform this action. Please subscribe to a plan.",
    SUBSCRIPTION_UPGRADE_REQUIRED:
      "This feature is not available in your current subscription. Please upgrade your plan.",
    TOKEN_SCOPE_FORBIDDEN:
      "Your authentication token lacks the required scope to perform this action.",
    TOKEN_FORCED_MEMBER_WEBSITES:
      "Two Factor Authentication cannot be disabled, as at least one of your websites has forced it in its options.",
    WEBSITE_MEMBER_TOKEN_MISSING:
      "At least a member of the website does not have Two Factor Authentication enabled. All operators should configure it before enabling the force option.",
    WEBSITE_VERIFICATION_REQUIRED:
      "Your website has not yet been verified. Please contact our support and request to be verified to be able to continue.",
    CONVERSATION_NOT_FOUND: "The conversation could not be found.",
    SESSION_NOT_FOUND: "The conversation session could not be found.",
    PEOPLE_EMAIL_EXISTS:
      "Another profile with that email exists. Change the email.",
    DOMAIN_TAKEN: "The domain is already taken. Try another one.",
    DOMAIN_REUSED:
      "You cannot reuse a domain already configured for another Crisp service (eg. email, helpdesk or status). Try another subdomain.",
    CUSTOM_DOMAIN_IP_REQUIRED:
      "A dedicated email IP is active. Please remove it before you can disable your custom domain.",
    CAMPAIGN_RECIPIENTS_NONE:
      "You have set no recipient to send the campaign to, or we are unable to check how many of them there are. Check your campaign configuration.",
    CAMPAIGN_RECIPIENTS_UNFILTERABLE:
      "Your campaign recipient filter is too complex for our systems to process in due time, as you have a lot of contacts. Try to simplify it.",
    CAMPAIGN_RECIPIENTS_LIMITED:
      "There are too many recipients. Upgrade your plan, check you\\'re not using a trial or get a dedicated email IP address to send to more recipients.",
    CAMPAIGN_SLOT_UNAVAILABLE:
      "The campaign cannot be sent at the moment, as another campaign is still being sent.",
    CAMPAIGN_LOCKED:
      "This campaign has been sent. It cannot be edited anymore.",
    CAMPAIGN_NAME_EXISTS:
      "This campaign name is already used on another campaign. Please enter a different name.",
    CAMPAIGN_FORMAT_INCOMPATIBLE:
      "The format of your campaign is incompatible with its delivery channel (eg. HTML does not work on chatbox).",
    CAMPAIGN_HTML_INVALID_CODE:
      "Your HTML campaign has an invalid code. It must contain an HTML tag and a body tag.",
    CAMPAIGN_HTML_NO_UNSUBSCRIBE:
      "Your HTML campaign has no unsubscribe URL. Please add one (this is a legal requirement).",
    TEMPLATE_EXISTS: "A template with this name already exists.",
    TEMPLATE_NAME_EXISTS:
      "This template name is already used on another template. Please enter a different name.",
    TEMPLATE_NOT_WRITABLE:
      "Templates provided by Crisp cannot be edited or removed. Only your own templates can be altered.",
    TEMPLATE_HTML_INVALID_CODE:
      "Your HTML template has an invalid code. It must contain an HTML tag and a body tag.",
    TEMPLATE_HTML_NO_UNSUBSCRIBE:
      "Your HTML template has no unsubscribe URL. Please add one (this is a legal requirement).",
    SHORTCUT_EXISTS:
      "The shortcut you want to create already exists. Change its bang.",
    ROOT_DOMAIN_CONFLICT:
      "Please use a sub-domain of your domain (eg. emails.acme.com and not acme.com).",
    WEBSITE_MEMBERSHIP_LEFT:
      "You are still a member of a website. Please leave all your websites.",
    PHONE_REQUIRED:
      "A non-empty phone number is required for Two Factor Authentication. Please set one.",
    BILLING_CARD_LEFT:
      "You still have registered payment cards. Please remove them.",
    BILLING_OWNER:
      "This operator pays for the website. Please link the payment method to another operator account and try again.",
    NO_PAYMENT_CARD:
      "You have no saved payment method. As this is a paid feature, you should add a payment method in your billing settings.",
    QUOTA_LIMIT_EXCEEDED:
      "Your quota has been exceeded for this action, either for the website or for your account. Try again later on, this quota may reset shortly.",
    REMOVAL_NOT_PERMITTED:
      "You do not have the permission to remove this, as a non-owner operator.",
    ORIGINAL_VANISHED:
      "The original you are looking for has expired, as it was too old. We automatically remove originals after some time.",
    PASSWORD_UNVERIFIED:
      "The password you entered could not be verified. Please try again.",
    INBOX_CONVERSATION_CREATED: "The conversation has been created.",
    INBOX_CONVERSATION_REMOVED: "The conversation has been removed.",
    INBOX_CUSTOM_FILTER_SAVED: "The custom filter has been saved.",
    INBOX_CUSTOM_FILTER_REMOVED: "The custom filter has been removed.",
    INBOX_CONVERSATION_NICKNAME_SAVED:
      "The conversation nickname has been saved.",
    INBOX_CONVERSATION_EMAIL_SAVED: "The conversation email has been saved.",
    INBOX_CONVERSATION_PHONE_SAVED: "The conversation phone has been saved.",
    INBOX_CONVERSATION_SEGMENT_ADDED: "The segment has been added.",
    INBOX_CONVERSATION_SEGMENT_REMOVED: "The segment has been removed.",
    INBOX_CONVERSATION_PARTICIPANT_ADDED:
      "The participant has been added to conversation. They will start receiving conversation messages.",
    INBOX_CONVERSATION_PARTICIPANT_REMOVED:
      "The participant has been removed from conversation. They will not receive messages anymore.",
    INBOX_CONVERSATION_PARTICIPANT_ADD_FAIL_UPGRADE:
      "Participant could not be added, as there are already too many participants in the conversation. You may upgrade your plan to add more.",
    INBOX_CONVERSATION_TRANSCRIPT_EMAILED: "The transcript has been emailed.",
    INBOX_CONVERSATION_CHATBOX_CLEAR_SESSION_REQUESTED:
      "The visitor chatbox session has been cleared.",
    INBOX_CONVERSATION_SHORTCUT_CREATED: "The shortcut has been created.",
    SETTINGS_ACCOUNT_SAVED: "Account settings have been saved.",
    SETTINGS_ACCOUNT_TOKEN_ENABLE_FAIL:
      "Two Factor Authentication failed to be enabled. Check the token you provided and make sure a phone number is set up.",
    SETTINGS_ACCOUNT_TOKEN_ENABLED:
      "Two Factor Authentication has been enabled.",
    SETTINGS_ACCOUNT_TOKEN_DISABLED:
      "Two Factor Authentication has been disabled.",
    SETTINGS_ACCOUNT_TOKEN_SECRET_COPIED:
      "The private key has been copied in your clipboard.",
    SETTINGS_ACCOUNT_REMOVED:
      "Your Crisp account has been deleted. You will be disconnected in a few moments…",
    SETTINGS_ACCOUNT_EMAIL_DONT_MATCH:
      "The account email does not match. Please enter it again.",
    SETTINGS_ACCOUNT_SESSION_CLOSED:
      "Selected session has been closed. The connected app does not have access to your account anymore.",
    SETTINGS_AVAILABILITY_SAVED: "Availability settings have been saved.",
    SETTINGS_BILLING_DEFAULT_METHOD_REMOVED:
      "The payment method has been removed.",
    SETTINGS_NOTIFICATIONS_SAVED: "Notification settings have been saved.",
    SETTINGS_PLANS_SUBSCRIBED: "The website has been subscribed to plan.",
    SETTINGS_COUPON_NOT_APPLIED:
      "The coupon could not be applied. Is it still valid?",
    SETTINGS_COUPON_APPLIED: "The coupon has been applied.",
    SETTINGS_SHORTCUTS_ADDED: "The shortcut has been added.",
    SETTINGS_SHORTCUTS_DELETED: "The shortcut has been deleted.",
    SETTINGS_SHORTCUTS_UPDATED: "The shortcut has been updated.",
    SETTINGS_WEBSITES_WEBSITE_CREATED: "The website has been created.",
    SETTINGS_WEBSITE_SETUP_IDENTIFIER_COPIED:
      "The website identifier has been copied in your clipboard.",
    SETTINGS_WEBSITE_OPERATOR_ROLE_CHANGED:
      "The operator role has been changed.",
    SETTINGS_WEBSITE_OPERATOR_EDITED: "The operator has been edited.",
    SETTINGS_WEBSITE_OPERATOR_REMOVED: "The operator has been removed.",
    SETTINGS_WEBSITE_OPERATOR_LIST_EMPTIED:
      "The list of last operators has been emptied.",
    SETTINGS_WEBSITE_VISITORS_BLOCKED_CLEARED:
      "All blocked visitor rules have been cleared.",
    SETTINGS_WEBSITE_INVITE_SENT:
      "The invite has been sent to the operator email address.",
    SETTINGS_WEBSITE_PAYMENT_METHOD_LINKED:
      "The payment method has been linked.",
    SETTINGS_WEBSITE_PAYMENT_METHOD_UNLINKED:
      "The payment method has been unlinked.",
    SETTINGS_WEBSITE_REMOVED: "The website has been removed.",
    SETTINGS_WEBSITE_DOMAIN_DONT_MATCH:
      "The website domain does not match. Please enter it again.",
    SETTINGS_WEBSITE_SAVED: "Website settings have been saved.",
    POPUPS_ADD_CARD_ADDED: "The payment method has been added.",
    POPUPS_ADD_CARD_INVALID:
      "The payment method could not be validated by the card network, check for any incorrect data (eg. invalid CVV).",
    APP_INVISIBLE_MODE_ENABLED:
      "Invisible mode has been enabled. You are now seen as offline, until you disable invisible mode.",
    APP_INVISIBLE_MODE_DISABLED:
      "Invisible mode has been disabled. Your regular availability schedule is now used.",
    RUNTIME_UNEXPECTED_ERROR:
      "An unexpected error occurred. Are you connected to a network?",
    FACTORY_UPLOAD_TOO_LARGE:
      "File too large. Please reduce its size and try again.",
    FACTORY_UPLOAD_POLICY:
      "File extension is not allowed for security reasons. The list of allowed file formats is available [here](https://help.crisp.chat/en/article/ubuxcu/).",
    FACTORY_UPLOAD_ERROR:
      "An error occurred while uploading the file. Is your file too large?",
    FACTORY_DATATABLE_ITEM_DELETED: "The selected items have been deleted.",
    FACTORY_DATATABLE_ITEM_NOT_DELETED:
      "The selected items could not have been deleted. Please try again.",
    FACTORY_DATATABLE_ITEM_DUPLICATED:
      "The selected items have been duplicated",
    PLUGINS_DEFAULT_INSTALLED:
      "The plugin has been installed. It is now enabled on the website.",
    PLUGINS_DEFAULT_UNINSTALLED:
      "The plugin has been uninstalled. It is now disabled from the website.",
    PLUGINS_SETTINGS_SAVED: "The plugin settings have been saved.",
    INBOX_DEFAULT_SETUP_HIDDEN:
      "The setup instructions have been hidden forever.",
    INBOX_CONVERSATION_DATA_ADDED: "The data point has been added.",
    INBOX_CONVERSATION_DATA_REMOVED: "The data point has been removed.",
    INBOX_CONVERSATION_DATA_FORGET_SUGGEST_FAIL:
      "Failed to forget data key suggestion. Try again.",
    INBOX_CONVERSATION_NOTEPAD_SAVED: "The notepad has been saved.",
    WEBSITE_MEMBER_ROLE_NOT_ALLOWED:
      "Your website membership role is too low for this resource. Maybe you are not website owner?",
    SETTINGS_BILL_PERIOD_UPDATED: "The bill period has been updated.",
    SETTINGS_BILL_PERIOD_MISMATCH_ERROR: "Your answer is incorrect",
    INBOX_ORIGINAL_VIEWER_LOAD_FAILED:
      "The original you attempted to view could not be loaded. It may have expired.",
    INBOX_ORIGINAL_VIEWER_FORMAT_UNSUPPORTED:
      "The format of this original is currently not supported, thus it could not be opened.",
    INBOX_CONVERSATION_BATCH_RESOLVED: "All conversations have been resolved.",
    INBOX_CONVERSATION_BATCH_READ:
      "All conversations have been marked as read.",
    INBOX_CONVERSATION_BLOCKED:
      "The user from this conversation has been blocked.",
    INBOX_CONVERSATION_UNBLOCKED:
      "The user from this conversation has been unblocked.",
    SETTINGS_WEBSITE_CUSTOM_EMAIL_DOMAIN_CHECKING:
      "Domain DNS records are being checked. This will take a few seconds.",
    SETTINGS_WEBSITE_CUSTOM_EMAIL_DOMAIN_CHANGED:
      "Email domain has been changed. Emails will now be sent from this domain.",
    SETTINGS_WEBSITE_CUSTOM_EMAIL_DOMAIN_NOT_CHANGED:
      "Email domain could not be updated. Shown DNS records are those missing. Update them and wait a few hours for DNS propagation.",
    SETTINGS_WEBSITE_EMAIL_SMTP_CHECKING:
      "Custom SMTP credentials are being checked. This will take a few seconds.",
    SETTINGS_WEBSITE_EMAIL_SMTP_LOCKED:
      "Custom SMTP settings can only be configured if you have a Custom Email Domain active.",
    SETTINGS_WEBSITE_EMAIL_SMTP_CHANGED:
      "Custom SMTP settings have been successfully changed.",
    SETTINGS_WEBSITE_EMAIL_SMTP_NOT_CHANGED:
      "Custom SMTP settings could not be updated. Check that provided credentials are valid.",
    SETTINGS_WEBSITE_VERIFY_KEY_ROLLING:
      "Your verify secret key is being rolled. This will take a few seconds.",
    SETTINGS_WEBSITE_VERIFY_KEY_ROLLED:
      "Your verify secret key has been rolled. Make sure to update your backend with this new key.",
    SETTINGS_WEBSITE_VERIFY_KEY_NOT_ROLLED:
      "Your verify secret key could not been rolled yet. It has been queued for processing.",
    SETTINGS_WEBSITE_HOOK_ADDED: "The hook has been added.",
    SETTINGS_WEBSITE_HOOK_EDITED: "The hook has been edited.",
    SETTINGS_WEBSITE_HOOK_DELETED: "The hook has been removed.",
    SETTINGS_WEBSITE_CONTRACT_AGREED: "Contract has been agreed and saved.",
    SETTINGS_WEBSITE_CONTRACT_CANCELED: "Contract has been canceled.",
    SETTINGS_WEBSITE_EMAIL_IPS_ASSIGNED:
      "An email IP have been successfully assigned to your website.",
    SETTINGS_WEBSITE_EMAIL_IPS_UNASSIGNED:
      "Your email IP has been successfully unassigned from your website.",
    CONTACTS_GENERIC_CONTACT_CREATED: "The contact has been created.",
    CONTACTS_INDEX_PROFILES_EXPORTING:
      "Your contact profiles are being exported.",
    CONTACTS_INDEX_SEARCH_DESTROYED:
      "The contacts matching search have been removed.",
    CONTACTS_PROFILE_SAVED: "The profile has been saved.",
    CONTACTS_PROFILE_SEGMENT_ADDED: "The segment has been added.",
    CONTACTS_PROFILE_SEGMENT_REMOVED: "The segment has been removed.",
    CONTACTS_PROFILE_SEGMENT_FORGET_SUGGEST_FAIL:
      "Failed to forget segment suggestion. Try again.",
    CONTACTS_PROFILE_DATA_ADDED: "The data point has been added.",
    CONTACTS_PROFILE_DATA_REMOVED: "The data point has been removed.",
    CONTACTS_PROFILE_DATA_FORGET_SUGGEST_FAIL:
      "Failed to forget data key suggestion. Try again.",
    CONTACTS_PROFILE_NOTEPAD_SAVED: "The notepad has been saved.",
    CONTACTS_PROFILE_SUBSCRIPTION_EMAIL_ENABLED:
      "Email subscription has been enabled.",
    CONTACTS_PROFILE_SUBSCRIPTION_EMAIL_DISABLED:
      "Email subscription has been disabled.",
    CAMPAIGNS_CREATED: "The campaign has been created.",
    CAMPAIGNS_PAUSED:
      "The campaign has been paused. You can resume it anytime.",
    CAMPAIGNS_DEACTIVATED: "The campaign has been deactivated.",
    CAMPAIGNS_RESUMED: "The campaign has been resumed.",
    CAMPAIGNS_ACTIVATED: "The campaign has been activated.",
    CAMPAIGNS_SAVED: "The campaign has been saved.",
    CAMPAIGNS_TESTED: "A campaign test email has been sent to your inbox.",
    CAMPAIGNS_DISPATCHED:
      "The campaign has been dispatched and will start running soon.",
    CAMPAIGNS_TEMPLATES_CREATED: "The campaign template has been created.",
    CAMPAIGNS_TEMPLATES_CREATE_DONE: "The campaign template has been saved.",
    CAMPAIGNS_TEMPLATES_CREATE_NO_CONTENT:
      "The campaign template has no content to be saved. Please go back to the editor.",
    CAMPAIGNS_TEMPLATES_INSERT_DONE:
      "The campaign template has been inserted in your current campaign.",
    CAMPAIGNS_TEMPLATES_UPDATE_DONE: "The campaign template has been updated.",
    CAMPAIGNS_TEMPLATES_UPDATE_NO_CONTENT:
      "The campaign template has no content to be updated. Please go back to the editor.",
    POPUPS_IMPORT_CONTACTS_BUSY_INGESTING:
      "Another contact import is still ongoing at the moment. Try again once the ongoing import is finished.",
    POPUPS_IMPORT_CONTACTS_QUOTA_LIMIT:
      "Your contact import quota has been exceeded for this website. Please wait at least a week before trying again.",
    POPUPS_IMPORT_CONTACTS_ERROR:
      "An error occurred while importing contacts, please try again. Are you trying to import too many contacts?",
    POPUPS_IMPORT_CONTACTS_INVALID_MAPPING:
      "Please map at least an email and an user fullname to proceed import.",
    POPUPS_IMPORT_CONTACTS_INVALID_SEPARATOR:
      "Please specify a column separator to proceed import.",
    POPUPS_IMPORT_CONTACTS_INVALID_DATA_KEY:
      "Your custom data key is invalid. Please avoid special characters and spaces.",
    POPUPS_IMPORT_CONTACTS_DATA_KEY_ADDED:
      "Your custom data key was added and can now be assigned.",
    POPUPS_IMPORT_HELPDESK_IS_IMPORTING:
      "Your helpdesk is already being imported.",
    POPUPS_IMPORT_HELPDESK_QUOTA_LIMIT:
      "Your helpdesk import quota has been exceeded for this website. Please wait at least a week before trying again.",
    POPUPS_IMPORT_HELPDESK_ERROR:
      "An error occurred while importing helpdesk, please try again.",
    INBOX_CONVERSATION_MESSAGE_EDIT_NONE:
      "There is no message to be edited. Last message is probably being sent, or was sent a while ago. User might as well be offline.",
    INBOX_CONVERSATION_MESSAGE_EDITED: "The message has been edited.",
    INBOX_CONVERSATION_REMINDER_INVALID_DATE:
      "The entered date format is invalid. Please use the correct format.",
    INBOX_CONVERSATION_REMINDER_NO_NOTE:
      "No note is written for reminder. Please write a note following the date.",
    INBOX_CONVERSATION_REMINDER_SCHEDULED:
      "Reminder has been scheduled. You will be reminded on date.",
    INBOX_CONVERSATION_TRANSLATE_LIMIT:
      "Could not translate this message. It may be too long for LiveTranslate.",
    SETTINGS_HELPDESK_DOMAIN_CUSTOM_CHECKING:
      "Domain DNS records are being checked. This will take a few seconds.",
    SETTINGS_HELPDESK_DOMAIN_CUSTOM_CHANGED:
      "Helpdesk domain has been changed. Allow a few minutes for your SSL certificate to be deployed online.",
    SETTINGS_HELPDESK_DOMAIN_CUSTOM_NOT_CHANGED:
      "Helpdesk domain could not be updated. Shown DNS records are those missing. Update them and wait a few hours for DNS propagation.",
    SETTINGS_HELPDESK_DOMAIN_SAVED:
      "Helpdesk domain changes have been saved. Allow a few minutes for your SSL certificate to be deployed online.",
    SETTINGS_HELPDESK_SETTINGS_SAVED:
      "Helpdesk settings have been saved. Allow a few minutes for them to be available online.",
    SETTINGS_HELPDESK_INITIALIZED:
      "Your helpdesk has been initialized and is now available online.",
    SETTINGS_STATUS_DOMAIN_CUSTOM_CHECKING:
      "Domain DNS records are being checked. This will take a few seconds.",
    SETTINGS_STATUS_DOMAIN_CUSTOM_CHANGED:
      "Status Page domain has been changed. Allow a few minutes for your SSL certificate to be deployed online.",
    SETTINGS_STATUS_DOMAIN_CUSTOM_NOT_CHANGED:
      "Status Page domain could not be updated. Shown DNS records are those missing. Update them and wait a few hours for DNS propagation.",
    SETTINGS_STATUS_DOMAIN_SAVED:
      "Status Page domain changes have been saved. Allow a few minutes for your SSL certificate to be deployed online.",
    SETTINGS_STATUS_SETTINGS_SAVED:
      "Status Page settings have been saved. Allow a few minutes for them to be available online.",
    SETTINGS_STATUS_INITIALIZED:
      "Your Status Page has been initialized and is now available online.",
    SETTINGS_STATUS_SERVICE_ADDED: "The service has been added.",
    SETTINGS_STATUS_SERVICE_EDITED: "The service has been edited.",
    SETTINGS_STATUS_SERVICE_REMOVED: "The service has been removed.",
    SETTINGS_STATUS_SERVICE_NODE_ADDED:
      "The node has been added. You can now configure it.",
    SETTINGS_STATUS_SERVICE_NODE_EDITED: "The node has been edited.",
    SETTINGS_STATUS_SERVICE_NODE_REMOVED: "The node has been removed.",
    SETTINGS_STATUS_SERVICE_NODE_REPLICAS_PURGED:
      "The node replicas have been purged.",
    SETTINGS_STATUS_SERVICE_NODE_REPLICA_TCP_PORT_MISSING:
      'One or more TCP replica URLs have no port set (format should be: "{"pattern"}").',
    SETTINGS_STATUS_SERVICE_NODE_REPLICA_INVALID_FORMAT:
      "One or more replica URLs are invalid (provide HTTP, TCP or ICMP URLs).",
    SETTINGS_STATUS_SERVICE_NODE_HTTP_INCOMPLETE:
      "HTTP settings are incomplete. Provide both an above & below HTTP status, or none.",
    SETTINGS_STATUS_ANNOUNCEMENT_EXPIRE_INVALID:
      "The expire date set is invalid.",
    SETTINGS_STATUS_ANNOUNCEMENT_SAVED:
      "The Status Page announcement has been saved. It is now visible on your Status Page.",
    SETTINGS_STATUS_ANNOUNCEMENT_REMOVED:
      "The Status Page announcement has been removed.",
    SETTINGS_STATUS_REPORTER_TOKEN_ROLLED:
      "Your reporter token key has been rolled. Make sure to update your nodes with this new key.",
    HELPDESK_LOCALE_ADDED: "The language has been added to your helpdesk.",
    HELPDESK_LOCALE_DESTROYED:
      "The language has been removed from your helpdesk.",
    HELPDESK_LOCALE_DONT_MATCH:
      "The language name does not match. Please enter it again.",
    HELPDESK_ARTICLES_EXPORTING: "Your helpdesk articles are being exported.",
    HELPDESK_ARTICLE_ADDED: "The article has been created in your helpdesk.",
    HELPDESK_CATEGORY_ADDED: "The category has been created in your helpdesk.",
    HELPDESK_CATEGORY_EDITED: "The category changes have been saved.",
    HELPDESK_SECTION_EDITED:
      "The section changes have been saved for the category.",
    HELPDESK_SECTION_ADDED:
      "The section has been created in your helpdesk for the category.",
    HELPDESK_ARTICLE_CATEGORY_CHANGED: "The article category has been changed.",
    HELPDESK_ARTICLE_PUBLISHED:
      "The article has been published. It is now available on your helpdesk.",
    HELPDESK_ARTICLE_REPUBLISHED:
      "The article has been republished. It is now visible again.",
    HELPDESK_ARTICLE_UNPUBLISHED:
      "The article has been unpublished. It is now hidden from your helpdesk.",
    HELPDESK_ARTICLE_SAVED: "The article changes have been saved.",
    HELPDESK_ARTICLE_ALTERNATE_LOCALES_SAVED:
      "The alternate article locales have been saved.",
    ANALYTICS_GENERATE_REPORT_GENERATED:
      "Your analytics report has been generated and will now be downloaded.",
    ANALYTICS_GENERATE_REPORT_NO_METRIC:
      "There was no metric data from which to generate the report.",
    INBOX_CONVERSATION_ASSIGNED:
      "The conversation has been assigned to user for notifications.",
    INBOX_CONVERSATION_UNASSIGNED:
      "The conversation has been unassigned. Notifications will be delivered to everyone.",
    SIMULATED_NETWORK_ERROR:
      "A simulated network error occurred, which is used for random failure resiliency testing. Check that the app handled this properly.",
    CONFIG_LOCAL_TOKENS_MISSING:
      "Cannot proceed the requested action, as you are missing a local token configuration (injected at build time). Check the app documentation.",
    INVALID_CARD_NUMBER: "Your card number looks invalid. Check for typos.",
    INVALID_EXPIRATION_DATE:
      "Please fill a valid card expiration date. Check the date is not in the past, and that the month and year are not reversed.",
    INVALID_SECURITY_CODE:
      "The security code for your card is invalid. Check for typos.",
    BILLING_EXISTS:
      "The payment method you are trying to add already exist in your account. Try removing it before adding it again?",
    DIRECTIVES_SELECT_COPIED:
      "The selected text has been copied to your clipboard.",
    DIRECTIVES_AUDIO_ERROR:
      "This audio track cannot be played at the moment. It may have expired.",
    POPUPS_ADD_CARD_UNAUTHORIZED:
      "The payment method has not been authorized to be used on Crisp. Please try again adding it.",
    SPOTLIGHT_FORGET_HISTORY_FAIL: "Failed to forget history entry. Try again.",
    CALL_ERROR:
      "An error occured setting up the call. Check that you have a microphone, and that you have good network connectivity.",
    CALL_STOPPED: "The ongoing call has been stopped.",
    CALL_REJECTED: "The call has been declined by the user.",
    ALERT_FEED_DISCARDED_ENTRY_FAILED:
      "Oops, that alert entry could not be discarded. Can you retry?",
    ALERT_FEED_DISCARDED_ALL: "All feed alerts have been discarded.",
    INITIATE_LOGIN_INVALID_CREDENTIALS:
      "Your credentials are invalid. Please retry.",
    INITIATE_LOGIN_SERVICE_UNAVAILABLE:
      "The Crisp service is unavailable. Try later.",
    INITIATE_REGISTER_ACCOUNT_EXISTS:
      "Oops. An account already exists for that email.",
    INITIATE_REGISTER_ACCOUNT_UNAVAILABLE: "Oops. A problem occurred.",
    INITIATE_REGISTER_ACCOUNT_EMAIL_NOT_VALID: "Oops. This email is not valid.",
    INITIATE_REGISTER_ACCOUNT_OVER_QUOTA:
      "Oops. It looks like you created too many accounts from this network.",
    INITIATE_REGISTER_WEBSITE_UNAVAILABLE:
      "Oops. We could not create your website.",
    INITIATE_REGISTER_WEBSITE_OVER_QUOTA:
      "Oops. It looks like you created too many websites from this network.",
    INITIATE_REGISTER_WEBSITE_DOMAIN_NOT_VALID:
      "Oops. This website domain is not valid.",
    INITIATE_RECOVER_SENT: "Recovery email sent. Check your mail inbox.",
    INITIATE_RECOVER_NOT_FOUND: "Your email could not be found. Made a typo?",
    INITIATE_OPERATORS_INVALID_PHONE:
      "This phone number are invalid. Please retry.",
    INITIATE_OPERATORS_SERVICE_UNAVAILABLE:
      "The Crisp service is unavailable. Try later.",
    INBOX_CONVERSATION_SEGMENT_DUPLICATED: "The segment already exists.",
    CONTACTS_PROFILE_ERROR: "This contact cannot be displayed. Was it removed?",
    POPUPS_EDITOR_INSERT_REPLACEMENT_FALLBACK_INVALID:
      "The fallback value entered is invalid. It likely contains forbidden special characters.",
    HELPDESK_ARTICLE_NOT_PUBLISHED:
      "You cannot add this article as it is not published yet.",
    SETTINGS_BILLING_DEFAULT_METHOD_EDITED: "The payment has been edited",
    FILTER_IS_TOO_COMPLEX:
      "Your filter is too complex to fetch in time. Try deleting elements.",
    UNITS_TOTAL_BELOW_USAGE:
      "You have requested too few units, as your current usage is well above that. Please increase the amount of units.",
    UNITS_TOTAL_TOO_LARGE:
      "You have requested too many units. Please lower the amount of units.",
    TOO_MANY_MESSAGES:
      "Too many messages have been sent in this conversation. Please create a new conversation with the user.",
    TOO_MANY_NODES:
      "You reached the limit of the amount of nodes you can monitor. You can increase this limit anytime in your website settings.",
    TOO_MANY_PEOPLE:
      "You reached the limit of the amount of profiles you can store in your CRM. You can increase this limit anytime in your website settings.",
    POPUPS_SET_REMINDER_DATE_TIME_DATE_INVALID:
      "The reminder date set is invalid.",
    WIDGET_ACTION_SUCCESS: "The widget action succeeded.",
    WIDGET_ACTION_ERROR:
      "Oops, the widget action failed. Please try again, or contact the developer of this widget.",
    POPUPS_MANAGE_LIMIT_ACTIVATED:
      "Your new limit has been successfully activated.",
    POPUPS_MANAGE_LIMIT_NO_BILLING_CARD:
      "No payment method is configured on your website. Please configure one to increase limits.",
  },
  TOOLTIPS: {
    INBOX_CONVERSATION_VIEWING: "is viewing",
    INBOX_CONVERSATION_COMPOSING: "is composing",
    NO_COUNTRY: "Unknown country",
    IS_ONLINE: "is online",
    IS_AWAY: "is away",
    REMOVE: "Remove",
    PRIMARY: "Primary",
    SECONDARY: "Secondary",
    INBOX_MESSAGE_COMPOSING_PREVIEW: "is composing this message",
    INBOX_MESSAGE_COMPOSING_STANDARD: "is composing (MagicType unavailable)",
    INBOX_CONVERSATION_MENTIONED: "You have been mentioned there",
    INBOX_CONVERSATION_LOCALE_TOGGLE: "Toggle language",
    INBOX_CONVERSATION_ADD_EMOTE: "Insert an emoji",
    INBOX_CONVERSATION_SHARE: "Share…",
    INBOX_USERINFO_DATA_ORIGIN_MAIN: "Profile data provided by Enrich™",
    INBOX_USERINFO_DATA_ORIGIN_SUB: "Guessed data (may not be accurate)",
    INBOX_USERINFO_EMAIL_VERIFIED: "User account email verified",
    INBOX_USERINFO_EMAIL_UNVERIFIED: "User account email not verified",
    INBOX_USERINFO_DEVICE_SYSTEM: "Visitor browser and system",
    INBOX_USERINFO_DEVICE_IP: "Visitor IP address",
    INBOX_USERINFO_LOCATION_CITY: "Visitor city and country",
    INBOX_USERINFO_LOCATION_TIME: "Visitor local time",
    INBOX_USERINFO_PAGE_LAST_REFERRER: "Referrer of current browsed page",
    INBOX_USERINFO_PARTICIPANTS_MASTER: "Conversation owner",
    INBOX_USERINFO_PARTICIPANTS_TYPE_DEFAULT: "Additional participant",
    INBOX_USERINFO_PARTICIPANTS_TYPE_EMAIL: "Email participant",
    INBOX_WIDGET_EXPAND: "Expand",
    SETTINGS_WEBSITE_OPERATORS_EMPTY_LAST_ACTIVE_MAIN:
      "Empty the list of last active operators in chatbox",
    SETTINGS_WEBSITE_OPERATORS_EMPTY_LAST_ACTIVE_SUB:
      "A new list will be created when operators send replies",
    SETTINGS_WEBSITE_OPERATORS_ADD_OPERATOR_MAIN:
      "Invite a new operator to join this website",
    SETTINGS_WEBSITE_OPERATORS_ADD_OPERATOR_SUB:
      "An invite email will be sent to join the website",
    SETTINGS_WEBSITE_OPERATORS_HAS_TOKEN:
      "Two Factor Authentication is enabled",
    SETTINGS_WEBSITE_OPERATORS_HASNT_TOKEN:
      "Two Factor Authentication is disabled",
    SETTINGS_SHORTCUTS_DELETE: "Delete shortcut",
    INBOX_USERINFO_CALL_UNAVAILABLE: "Calls unsupported (HTTPS required).",
    INBOX_USERINFO_CALL_VIDEO: "Start a video call",
    INBOX_USERINFO_CALL_AUDIO: "Start an audio-only call",
    PLUGINS_ITEM_INSTALLED: "Plugin is installed",
    SETTINGS_WEBSITE_HOOKS_STATUS_INACTIVE: "This hook was not called yet",
    SETTINGS_WEBSITE_HOOKS_STATUS_ACTIVE:
      "The last call to your hook server succeeded",
    SETTINGS_WEBSITE_HOOKS_STATUS_FAILED:
      "The last call to your hook server failed",
    SETTINGS_WEBSITE_HOOKS_DELETE: "Delete hook",
    SETTINGS_STATUS_SERVICES_NODE: "Delete node",
    CONTACTS_DEFAULT_COMPANY: "Where this contact currently works",
    CONTACTS_DEFAULT_SEGMENTS:
      "Contact segments, useful to group contacts in categories",
    CONTACTS_DEFAULT_ACTIVE: "When this contact was last seen online",
    CONTACTS_DEFAULT_SCORE:
      "Contact score, based on contact ratings of support quality",
    CONTACTS_PROFILE_CONTACT: "Contact information for the contact",
    CONTACTS_PROFILE_CONVERSATIONS:
      "All past and ongoing conversations with the contact",
    CONTACTS_PROFILE_COMPANY: "Company the contact works for",
    CONTACTS_PROFILE_DEVICES: "All the devices the contact used",
    CONTACTS_PROFILE_DEVICES_IP: "Device IP address",
    CONTACTS_PROFILE_LOCATION:
      "The last reported location from the contact device",
    CONTACTS_PROFILE_NOTEPAD: "Private notepad on which notes can be left",
    CONTACTS_PROFILE_DATA: "The contact data that has been set",
    CONTACTS_PROFILE_RATINGS: "The ratings the contact has submitted",
    CONTACTS_PROFILE_SEGMENTS: "The contact segments that have been set",
    CONTACTS_PROFILE_PAGES: "All the pages that the contact browsed",
    CONTACTS_PROFILE_EVENTS: "All the events pushed for the contact",
    CONTACTS_PROFILE_CAMPAIGNS: "All campaigns received by the contact",
    ANALYTICS_HELPDESK_SEARCH_WORD_COUNT: "Searches:",
    PAGE_HISTORY_LAST: "Last page visited",
    PAGE_HISTORY_OPEN: "Open page (new tab)",
    PROFILE_CAMPAIGN_OPEN: "Go to campaign",
    FIELD_LABEL_REQUIRED: "This field is required",
    FIELD_IMAGE_UPLOAD: "Upload new",
    FIELD_IMAGE_CLEAR: "Clear existing",
    SEGMENTATION_FILTERS_REMOVE: "Remove all criterion",
    SEGMENTATION_FILTER_REMOVE: "Remove criterium",
    SIDEBAR_WEBSITE_OFFLINE: "Website offline",
    SPOTLIGHT_FORM_ACTIONS_CLOSE: "Close search",
    EDITOR_BOLD: "Bold",
    EDITOR_ITALIC: "Italic",
    EDITOR_UNDERLINE: "Underline",
    EDITOR_INSERT_COLOR: "Colored text",
    EDITOR_TITLE_1: "Title (large)",
    EDITOR_TITLE_2: "Title (medium)",
    EDITOR_TITLE_3: "Title (small)",
    EDITOR_ALIGN_LEFT: "Align to left",
    EDITOR_ALIGN_CENTER: "Align to center",
    EDITOR_ALIGN_RIGHT: "Align to right",
    EDITOR_ALIGN_JUSTIFY: "Justify content",
    EDITOR_LIST: "List",
    EDITOR_INSERT_CODE: "Insert code",
    EDITOR_INSERT_LINK: "Insert a link",
    EDITOR_INSERT_IMAGE: "Insert an image",
    EDITOR_INSERT_GIF: "Insert a GIF",
    EDITOR_INSERT_FILE: "Insert a file",
    EDITOR_INSERT_REPLACEMENT: "Insert a replacement tag",
    EDITOR_BLOCKQUOTE: "Quote",
    EDITOR_LINE: "Line separator",
    EDITOR_TABLE: "Table",
    EDITOR_EMPHASIS_TIP: "Tip box",
    EDITOR_EMPHASIS_INFO: "Information box",
    EDITOR_EMPHASIS_WARNING: "Warning box",
    EDITOR_INSERT_VIDEO: "Insert a video",
    INBOX_CONVERSATION_ACTIVATE_EDITOR: "Activate editor",
    INBOX_CONVERSATION_DEACTIVATE_EDITOR: "Deactivate editor",
    INBOX_USERINFO_COPY: "Copy",
    INBOX_USERINFO_COPIED: "Copied!",
    INBOX_USERINFO_EDIT: "Edit",
    INBOX_USERINFO_REMOVE: "Remove",
    INBOX_USERINFO_REFRESH: "Refresh",
    INBOX_USERINFO_PHONE: "Visitor phone number",
    INBOX_USERINFO_CONTACT_DATA: "Data from profile",
    CONTACTS_PROFILE_CARD_MAP_OPEN: "Open this item",
    CONTACTS_PROFILE_DEVICES_BROWSING_OFFLINE:
      "MagicBrowse unavailable (device offline)",
    CONTACTS_PROFILE_DATA_REMOVE: "Remove data",
    SETTINGS_WEBSITE_OPERATOR_EDIT: "Edit operator",
    SETTINGS_WEBSITE_ROUTING_MOVE_UP: "Increase rule priority",
    SETTINGS_WEBSITE_ROUTING_MOVE_DOWN: "Decrease rule priority",
    SETTINGS_WEBSITE_ROUTING_REMOVE: "Remove rule",
    SETTINGS_BILLING_INVOICES_UNAVAILABLE: "Invoice is not available",
    SETTINGS_PLANS_COUPON_REDEEMED: "A coupon has already been applied",
    SETTINGS_PLANS_COUPON_UPGRADE: "A coupon cannot be applied on this plan",
    CAMPAIGNS_TEMPLATE_UPDATE: "Update with current content",
  },
  VALIDATION: {
    FIELDS: {
      BANG: "bang",
      CONTENT: "content",
      COUPON: "coupon",
      CUSTOM_KEY: "custom key",
      DESCRIPTION: "description",
      DOMAIN: "domain",
      EMAIL: "email",
      EXPIRE: "expire",
      FIRST_NAME: "first name",
      HEIGHT: "height",
      LABEL: "label",
      LAST_NAME: "last name",
      NAME: "name",
      NICKNAME: "nickname",
      PASSWORD: "password",
      PHONE: "phone",
      TARGET: "target",
      TITLE: "title",
      TOKEN: "token",
      URL: "url",
      WIDTH: "width",
      ADDRESS: "address",
      CARD_EXPIRE: "card expire",
    },
    RULES: {
      DOMAIN: "This is not a valid domain",
      URL: "This is not a valid URL",
      YOUTUBE: "This is not a valid YouTube video link",
    },
  },
  INITIATE: {
    COMMON: {
      NEED_HELP: "Need help?",
      TWO_FACTOR_TITLE: "Two Factor Authentication",
      TWO_FACTOR_SUBTITLE: "Please enter your token to continue.",
      CONFIRM_TITLE: "This login needs to be confirmed",
      CONFIRM_SUBTITLE:
        "You are logging from an unusual location. We sent an email to your mailbox so you can confirm this login attempt.",
      BACK: "Back",
    },
    SETUP: {
      GUIDE_RESOURCE:
        "Following this guide, you\\'ll connect Crisp to {name} store and benefit from multiple features.",
    },
    LOGIN: {
      TITLE_DEFAULT: "Login",
      SUBTITLE_DEFAULT:
        "Login to your Crisp account to access to your Crisp Inbox.",
      EMAIL_LABEL: "Sign in with your email address",
      EMAIL_PLACEHOLDER: "Enter your email…",
      PASSWORD_LABEL: "Password",
      PASSWORD_PLACEHOLDER: "Enter your password…",
      REMEMBER_LABEL: "Remember me",
      BUTTON_CREDENTIALS: "Sign in to Dashboard",
      TOKEN_LABEL: "Login token (Two Factor Authentication)",
      BUTTON_TOKEN: "Authenticate with Token",
      BUTTON_CONFIRM: "Login again (once confirmed)",
      LOST_PASSWORD: "Forgot Password?",
      LOST_TOKEN_SEND: "Lost token?",
      LOST_TOKEN_RESEND: "Sent via SMS. Resend?",
      CANCEL_TOKEN: "Cancel token login.",
      REGISTER: "Don\\'t have an account yet? Get started.",
      FEATURES: {
        LEARN_MORE: "Learn More",
        CALLS: {
          TITLE: "Video and audio calls right from your Crisp Inbox",
          SUBTITLE:
            "Video and audio calls out of the box, in real time. No plugin or software to install, just a joyful and human experience.",
        },
        TRIGGERS: {
          TITLE: "Get 2x More Sales Using Triggers",
          SUBTITLE:
            "Send automated messages that create a proactive customer service which converts visitors into opportunities.",
        },
        FAQ: {
          TITLE:
            "Optimize your customer experience with the Crisp Knowledge Base",
          SUBTITLE:
            "Make your customers more autonomous with a knowledge base. Forget about repetitive answers, hidden or unindexable help contents, slow answers or non autonomous customers.",
        },
        CHATBOT: {
          TITLE:
            "Offer the best conversational experience with the Crisp Chatbot",
          SUBTITLE:
            "Build automated behaviors on each channel you connected to your inbox without any coding skills.",
        },
        EMAIL: {
          TITLE: "Manage all your emails right from your Crisp Inbox",
          SUBTITLE:
            "Supercharge your email support at scale by integrating assignments, canned responses and get all the benefits of the Crisp features.",
        },
      },
    },
    RECOVER: {
      TITLE: "Recover your Crisp password",
      SUBTITLE:
        "We will send a recovery link to your inbox so that you can reset your password and access your account.",
      EMAIL_LABEL: "Enter your email address",
      EMAIL_PLACEHOLDER: "Enter your email…",
      BUTTON: "Recover Password",
      LOGIN: "Return to Sign in to Dashboard",
    },
    REGISTER: {
      DEFAULT_TITLE: "Get Started",
      DEFAULT_SUBTITLE: "Create your free Crisp account to continue.",
      EMAIL_LABEL: "Type your email address",
      EMAIL_PLACEHOLDER: "firstname{\\'@\\'}company.com",
      PASSWORD_LABEL: "Enter a password",
      PASSWORD_PLACEHOLDER: "Enter a password",
      FIRSTNAME_LABEL: "Your first name",
      FIRSTNAME_PLACEHOLDER: "First name",
      LASTNAME_LABEL: "Your last name",
      LASTNAME_PLACEHOLDER: "Last name",
      WEBSITE_TITLE: "Some details about your company",
      WEBSITE_SUBTITLE:
        "Fill in your company information to continue (you can edit it later). If your website is not live yet, you can fill a temporary domain.",
      NAME_LABEL: "What is the name of your company?",
      NAME_PLACEHOLDER: "Company name",
      DOMAIN_LABEL: "What is your company website?",
      DOMAIN_PLACEHOLDER: "www.acme.com",
      CHATBOX_TITLE: "Your Chatbox",
      CHATBOX_SUBTITLE:
        "Customize your chatbox with an avatar and a color scheme.",
      CHATBOX_COLOR: "Chatbox Color",
      SETUP_TITLE: "Success!",
      SETUP_SUBTITLE_DEFAULT:
        "Your Crisp website has been created! Add the Crisp chatbox to your website by pasting this code in the HTML head section, or by using the methods bellow.",
      SETUP_SUBTITLE_DETECTED:
        "Your Crisp website has been created! It seems your website is using a CMS. Please use the guide below to add Crisp to your website.",
      DONE_TITLE: "Welcome on Crisp",
      DONE_SUBTITLE: "Go chat with your customers now!",
      NEXT: "Continue",
      DONE: "Discover My Dashboard",
      TERMS_LABEL: "I have read and I accept Crisp terms of use:",
      TERMS_READ: "read terms",
      LOGIN: "Already have an account? Sign in.",
    },
    OPERATOR: {
      LOGIN_SUBTITLE: "Login to your Crisp account to join the website.",
      BUTTON_LOGIN: "Login & Join",
      FAILED: {
        TITLE: "Invalid Invite",
        SUBTITLE: "The invite looks invalid. Did you already redeem it?",
      },
      ABORTED: {
        TITLE: "Could Not Join Website",
        SUBTITLE:
          "The website plan does not allow so many operators. Please upgrade it.",
      },
      WELCOME: {
        TITLE: "You have been invited to join {website}",
        SUBTITLE: "Crisp is a customer service software used by {website}.",
        SUBTITLE_EXISTING:
          "As you already have a Crisp account, click \\'Continue\\' to join {website}\\'s Inbox",
        SUBTITLE_NEW:
          "We will create a new Crisp account so you can join {website}\\'s Inbox, click \\'Continue\\' to create your new account.",
        BUTTON: "Continue",
      },
      REGISTER: {
        TITLE: "Your Account",
        SUBTITLE:
          "Create your account and choose a password to join {website}\\'s Inbox",
        ACCOUNT: "Account",
      },
      SUCCESS: {
        TITLE: "We are done!",
        SUBTITLE:
          "You successfully joined {website} Inbox. You can now collaborate with your teammates and communicate with customers.",
        BUTTON: "Go to {website} Inbox",
      },
      AVAILABILITY_SELECT: {
        TITLE: "Availability",
        SUBTITLE:
          "Please pick the what availability mode sounds the best for you, so we don\\'t notify you when you are away.",
        AVAILABLE_APP: {
          TITLE: "Available when using the app",
          SUBTITLE:
            "You\\'ll be seen as available only when the Crisp app is opened on your desktop or on your phone.",
        },
        SCHEDULE: {
          TITLE: "Use working hours instead",
          SUBTITLE:
            "You\\'ll be seen as available only during your configured working hours.",
        },
      },
      AVAILABILITY_SCHEDULE: {
        TITLE: "Availability",
        SUBTITLE:
          "Set yourself available on schedule, by configuring days and times (in your timezone). Your team and customers will see you as away off scheduled hours.",
        BUTTON: "Continue",
      },
      NOTIFICATIONS: {
        TITLE: "Notifications",
        SUBTITLE:
          "Choose how you want to manage your notifications. It is not recommended to disable them, as you won\\'t get notified when you receive new messages.",
        ENABLE: {
          TITLE: "Enable notifications",
          SUBTITLE: "You\\'ll be notified new messages (push notifications)",
        },
        SKIP: "Skip for now.",
        BUTTON: "Continue",
      },
      UPDATE_AVATAR: {
        TITLE: "Profile",
        SUBTITLE:
          "It\\'s time to choose your profile picture! We recommend using a real photo, as visitors will be more keen to engage conversation with you.",
        SKIP: "Skip for now.",
        BUTTON: "Continue",
      },
      TWO_FACTOR_PHONE: {
        TITLE: "Two Factor Authentication Enforced",
        SUBTITLE:
          "The website you\\'re joining enforces 2FA, for security purposes. Prior to setting up 2FA, you need to enter a valid phone number.",
        PHONE_LABEL: "What is your mobile phone number?",
        PHONE_PLACEHOLDER: "+1646424545",
        BUTTON: "Continue",
      },
      TWO_FACTOR_QRCODE: {
        TITLE: "Two Factor Authentication Enforced",
        SUBTITLE: "Please scan this QR code with your authentication app.",
        BUTTON: "I Scanned the Code",
      },
      TWO_FACTOR_VERIFY: {
        TITLE: "Verify the Scanned Code",
        SUBTITLE:
          "To ensure your private key is correctly installed, please enter the 6 digit code that your authentication app generates below.",
        BUTTON: "Verify",
      },
    },
    LOGOUT: {
      TITLE: "Logging out…",
    },
    OFFLINE: {
      TITLE: "Huh. Crisp is offline.",
      SUBTITLE: "Make sure you are connected to a network.",
      PROBLEM: "If you cannot reconnect, email us at",
      COUNT: "Will reconnect in {count} seconds.",
      CHECKING: "Crisp is reconnecting…",
      RELOAD: "Reconnect now",
      RELOADING: "Reconnecting…",
    },
    PLUGIN: {
      TITLE: "Link Crisp with {plugin_name}",
      NOTICE: "{plugin_name} needs to access to your Crisp.",
      PAID_TITLE: "This plugin is paid.",
      PAID_NOTICE: "To continue, subscribe to the {plugin_name} Plugin",
      SELECT_WEBSITE: "Select a website",
      CONTINUE: "Continue",
      ERROR_OWNER_REQUIRED: "You need owner access to do this operation.",
    },
  },
  INBOX: {
    COMMON: {
      FILTER_OPERATORS: "Filter operators…",
      NO_OPERATOR_FOUND: "No operator found.",
    },
    DEFAULT: {
      ONBOARDING_HEAD_TITLE: "Let\\'s get you setup.",
      ONBOARDING_HEAD_LABEL_1:
        "Get the most out of your Crisp account by following those setup steps.",
      ONBOARDING_HEAD_LABEL_2:
        "You get extra days of trial for each completed step.",
      ONBOARDING_STEPS: {
        APPS: {
          TITLE: "Get Crisp Mobile apps",
          LABEL: "Use Crisp on the go.",
        },
        CHATBOX: {
          TITLE: "Install the chatbox on your website",
          LABEL: "Receive messages from your users.",
        },
        MESSENGER: {
          TITLE: "Connect to Facebook Messenger",
          LABEL: "Respond to messages from Messenger users.",
        },
        EMAIL: {
          TITLE: "Receive your emails in Crisp",
          LABEL: "Manage your inbound support emails in Crisp.",
        },
        SLACK: {
          TITLE: "Connect to your Slack Team",
          LABEL: "Let your Slack team answer from Slack.",
        },
        TRIGGERS: {
          TITLE: "Send automated messages",
          LABEL: "Automate your marketing by triggering chatbox actions.",
        },
        HELPDESK: {
          TITLE: "Build your Helpdesk",
          LABEL: "Write articles to help your users.",
        },
        BOT: {
          TITLE: "Build your first bot",
          LABEL: "Build chatbot flows and automate your answers.",
        },
      },
      ONBOARDING_APPS: {
        DOWNLOAD_IOS: "iOS",
        DOWNLOAD_ANDROID: "Android",
      },
      ONBOARDING_TRIAL_DAY: "day of trial",
      ONBOARDING_TRIAL_DAYS: "days of trial",
      ONBOARDING_SETUP: "Setup now",
      ONBOARDING_ALREADY_CONFIGURED: "Already configured!",
      ONBOARDING_HELP: "Need help setting up Crisp?",
      ONBOARDING_HIDE: "Hide this",
      POPUPS_HIDE_SETUP_QUESTION: "Hide setup instructions?",
      POPUPS_HIDE_SETUP_LABEL:
        "Setup instructions will be hidden forever. You can still setup your Crisp without this screen, but you will not be guided with recommended steps.",
      POPUPS_HIDE_SETUP_PROCEED: "Hide Instructions",
    },
    CONVERSATION: {
      MARK_RESOLVED: "Mark this conversation as resolved.",
      MARK_UNRESOLVED: "This conversation has been resolved.",
      PENDING: "This conversation is pending. You may send the first reply.",
      COMPOSE_SEND: "Send your message",
      COMPOSE_SEND_TO: "to",
      COMPOSE_SEND_CHAT: "in chat",
      COMPOSE_SEND_EMAIL: "in email",
      COMPOSE_BLOCKED: "is blocked. Unblock to send or receive messages.",
      COMPOSE_NOTE: "Send a private note to your team",
      SHARE_FILE: "Share a file with",
      SHARE_SHORTCUT_INSERT: "Pick a message shortcut",
      SHARE_SHORTCUT_ADD: "Add message to shortcuts",
      SEND: "Send message",
      DROP_FILE: "Send a file",
      GIF_SEARCH: "Find GIFs…",
      SHORTCUT_TITLE: "Shortcuts",
      SHORTCUT_NONE: "No shortcut was found. You may add more shortcuts.",
      ALERT_UNREAD: "There are new messages below. Click to see them.",
      ALERT_EMPTY:
        "This conversation has not yet been started. Send a message to begin.",
      POPUPS_NEW_SHORTCUT_BANG_LABEL: "!bang of the shortcut",
      POPUPS_NEW_SHORTCUT_BANG_PLACEHOLDER:
        "Enter the !bang of the shortcut (eg. !hello)…",
      POPUPS_NEW_SHORTCUT_PROCEED: "Add Shortcut",
      POPUPS_PASTE_FILE_QUESTION: "Are you sure to upload this pasted file?",
      POPUPS_PASTE_FILE_LABEL: "The file will be sent to the conversation.",
      POPUPS_PASTE_FILE_PROCEED: "Upload File",
      POPUPS_SET_REMINDER_DATE_TIME_LABEL: "Reminder date",
      POPUPS_SET_REMINDER_DATE_TIME_PROCEED: "Set Date",
      MENTION_TITLE: "Mention an operator",
      SHARE_AUDIO_RECORD: "Hold to record audio",
      MODE_TOGGLE_REPLY: "Reply",
      MODE_TOGGLE_NOTE: "Note",
      MODE_TOGGLE_EDIT: "Edit",
      MODE_TOGGLE_REMINDER: "Reminder",
      POPUPS_SEND_CONFIRM_QUESTION:
        "Are you sure you want to send this message?",
      POPUPS_SEND_CONFIRM_LABEL:
        "The user is now offline, meaning that this message will be sent via **email** once you confirm. It won\\'t be editable.",
      POPUPS_SEND_CONFIRM_REMEMBER: "Do not show this popup again.",
      POPUPS_SEND_CONFIRM_PROCEED: "Send Message",
      POPUPS_SEND_SANDBOX_REMIND_QUESTION: "Send this message in sandbox mode?",
      POPUPS_SEND_SANDBOX_REMIND_LABEL:
        "You may not be answering to a real human user, as your website is currently in sandbox mode. You should only use it for development purpose. If you are using the wrong website, you may switch to your production website.",
      POPUPS_SEND_SANDBOX_REMIND_PROCEED: "Send Message Anyway",
      POPUPS_SEND_SANDBOX_REMIND_WAIT: "Hold on a bit…",
      POPUPS_CHATBOX_CLEAR_SESSION_QUESTION: "Clear chatbox session now?",
      POPUPS_CHATBOX_CLEAR_SESSION_LABEL:
        "The chatbox session will be remotely purged. This will disconnect the user browser from this conversation.",
      POPUPS_CHATBOX_CLEAR_SESSION_PROCEED: "Clear Chatbox Session",
      TRANSLATE_INITIATE_STATE_1: "speaks",
      TRANSLATE_INITIATE_STATE_2: "See last messages in",
      TRANSLATE_INITIATE_STATE_3: "?",
      TRANSLATE_INITIATE_STATE_4: "Nothing to translate there.",
      TRANSLATE_INITIATE_ACTION: "Start LiveTranslate",
      TRANSLATE_ONGOING_STATE_1:
        "LiveTranslate currently active on messages with",
      TRANSLATE_ONGOING_ACTION: "Stop",
      TRANSLATE_INITIATE_LOCALE_MORE_LIKELY: "(more likely)",
      TRANSLATE_INITIATE_LOCALE_LESS_LIKELY: "(less likely)",
      TRANSLATE_INITIATE_LOCALE_OTHERS: "See more…",
      TRANSLATE_TRANSLATING: "Translating message…",
      SHARE_HELPDESK_INSERT: "Pick a helpdesk article",
      HELPDESK_TITLE: "Helpdesk",
      HELPDESK_NONE: "No helpdesk article was found. Refine your search.",
      HELPDESK_CATEGORY_NONE: "No category",
      HELPDESK_PREVIEW: "Preview",
      REMINDER_TITLE: "Schedule a Reminder",
      REMINDER_LABEL: "Remind me:",
      REMINDER_PICKER: {
        PREDEFINED_TITLE: "On pre-defined date…",
        PREDEFINED_1_HOUR: "In 1 hour",
        PREDEFINED_2_HOURS: "In 2 hours",
        PREDEFINED_1_DAY: "Tomorrow, same time",
        PREDEFINED_2_DAYS: "In 2 days, same time",
        PREDEFINED_1_WEEK: "In a week, same time",
        CUSTOM_TITLE: "On custom date…",
        CUSTOM_PROVIDED: "At provided date and time",
      },
      MENTION_NO_MATCH: "No operator found.",
      MODE_TOGGLE_SHORTCUTS: "Shortcuts",
      MODE_TOGGLE_HELPDESK: "Helpdesk",
      GO_TO_FULL: "Open full conversation",
    },
    USER_INFO: {
      EMAIL_TRANSCRIPT_EMAIL: "Email",
      EMAIL_TRANSCRIPT_BASE: "a chat transcript",
      EMAIL_TRANSCRIPT_OPERATOR: "me",
      EMAIL_TRANSCRIPT_EXTERNAL: "someone else",
      CHATBOX_CLEAR_SESSION: "Clear chatbox session",
      BLOCK_USER: "Block user",
      UNBLOCK_USER: "Unblock user",
      EMAIL_DEFINE: "set email",
      PROFILE_VIEW: "View {name} Profile",
      WIDGET_DEVICE_ON: "on",
      WIDGET_DEVICE_TITLE: "Visitor device",
      WIDGET_CALL_AVAILABLE: "Available for call now",
      WIDGET_CALL_BUSY: "Another call is ongoing",
      WIDGET_CALL_ONGOING: "Call ongoing",
      WIDGET_CALL_PROCEED: "Call with Crisp",
      WIDGET_CALL_STOP: "Stop ongoing call",
      WIDGET_DATA_TITLE: "Visitor data",
      WIDGET_DATA_KEY: "Data key…",
      WIDGET_DATA_VALUE: "Data value…",
      WIDGET_EVENTS_TITLE: "Last profile events",
      WIDGET_NOTEPAD_TITLE: "Private Notepad",
      WIDGET_PAGE_HISTORY_TITLE: "Last browsed pages",
      WIDGET_ROUTING_TITLE: "Assigned operator",
      WIDGET_ROUTING_OPERATOR_NONE: "None assigned",
      WIDGET_ROUTING_OPERATOR_REASSIGN: "Reassign",
      WIDGET_ROUTING_OPERATOR_ASSIGN: "Assign",
      WIDGET_RATING_TITLE: "Rating score",
      WIDGET_RATING_COMMENT: "Comment",
      WIDGET_RATING_COMMENT_EMPTY: "No comment submitted.",
      WIDGET_RATING_SEE_MORE: "See more",
      WIDGET_RATING_SEE_LESS: "See less",
      WIDGET_PARTICIPANTS_TITLE: "Conversation participants",
      WIDGET_PARTICIPANTS_ADD: "Add",
      WIDGET_CUSTOM_ACTIONS_TITLE: "Custom actions",
      WIDGET_MAIN_TITLE: "Main information",
      WIDGET_SEGMENTS_TITLE: "Segments for conversation",
      WIDGET_SEGMENTS_EXPLAIN: "Segment conversation…",
      WIDGET_GENERIC_EMPTY: "No section to display.",
      WIDGET_GENERIC_SECTION_EMPTY: "No item to display.",
    },
    FLOW: {
      DOWNLOAD_FILE: "Download file",
      PREVIEW_VIDEO: "Play video",
      EVENT_STATE_RESOLVED: "Resolved conversation",
      EVENT_USER_BLOCKED: "Blocked user",
      EVENT_REMINDER_SCHEDULED: "Left a reminder",
      EVENT_THREAD_STARTED: "Started thread",
      EVENT_THREAD_ENDED: "Ended thread",
      EVENT_PARTICIPANT_ADDED: "Added participant",
      EVENT_PARTICIPANT_REMOVED: "Removed participant",
      EVENT_CALL_STARTED: "Started a call",
      EVENT_CALL_ENDED: "Ended the call",
      EVENT_DEFAULT: "An unknown event occured",
      NOT_SENT: "Not sent. Retry?",
      TOOLTIP_PARTICIPANT: "Participant",
      TOOLTIP_AUTOMATED: "Automated",
      TOOLTIP_DATE_RECEIVED: "Received",
      TOOLTIP_DATE_SENT: "Sent",
      TOOLTIP_DATE_EDITED: "Edited",
      TOOLTIP_DATE_TRANSLATED: "Translated",
      TOOLTIP_ORIGIN_SENT_FROM: "Sent from",
      TOOLTIP_ORIGIN_ORIGINAL: "View original",
      TOOLTIP_ORIGIN_FULL: "Expand full",
      LEFT_PRIVATE_NOTE: "left this private note:",
      READ_IN: "Read in",
      DELIVERED_TO: "Delivered to",
    },
    LIST: {
      CONTROLS_MORE_TOOLTIP: "Actions",
      CONTROLS_MORE_NEW_CONVERSATION: "Create a new conversation",
      CONTROLS_MORE_REAL_ALL: "Mark all messages as read",
      CONTROLS_MORE_RESOLVE_ALL: "Resolve all conversations",
      CONTROLS_MORE_KEYBOARD_SHORTCUTS: "See keyboard shortcuts…",
      EMPTY_NO_CONVERSATION_DEFAULT_TITLE: "You have no conversations.",
      EMPTY_NO_CONVERSATION_DEFAULT_LABEL: "Messages from users all come here.",
      POPUPS_NEW_CONVERSATION_NAME_LABEL: "Name of the User",
      POPUPS_NEW_CONVERSATION_NAME_PLACEHOLDER:
        "Enter the full name of the user…",
      POPUPS_NEW_CONVERSATION_EMAIL_LABEL: "Email of the User",
      POPUPS_NEW_CONVERSATION_EMAIL_PLACEHOLDER: "Enter the email of the user…",
      POPUPS_NEW_CONVERSATION_SUBJECT_LABEL: "Subject of the email (if any)",
      POPUPS_NEW_CONVERSATION_SUBJECT_PLACEHOLDER:
        "Enter the subject of the email…",
      POPUPS_NEW_CONVERSATION_PROCEED: "Create Conversation",
      POPUPS_REMOVE_CONVERSATION_QUESTION:
        "Are you sure to remove this conversation?",
      POPUPS_REMOVE_CONVERSATION_LABEL: "Conversation messages will be lost.",
      POPUPS_REMOVE_CONVERSATION_PROCEED: "Remove Conversation",
      POPUPS_READ_ALL_CONVERSATIONS_QUESTION:
        "Are you sure to mark all messages as read?",
      POPUPS_READ_ALL_CONVERSATIONS_LABEL:
        "All messages will be marked as read.",
      POPUPS_READ_ALL_CONVERSATIONS_PROCEED: "Read All Messages",
      POPUPS_RESOLVE_ALL_CONVERSATIONS_QUESTION:
        "Are you sure to resolve all conversations?",
      POPUPS_RESOLVE_ALL_CONVERSATIONS_LABEL:
        "All conversations will be marked as resolved.",
      POPUPS_RESOLVE_ALL_CONVERSATIONS_PROCEED: "Resolve All Conversations",
      POPUPS_EDIT_CUSTOM_FILTER_LABEL_LABEL: "Label for the custom filter",
      POPUPS_EDIT_CUSTOM_FILTER_LABEL_PLACEHOLDER:
        "Enter a label… (eg. \\'Chats with segment: sales\\')",
      POPUPS_EDIT_CUSTOM_FILTER_PROCEED: "Save Custom Filter",
      FILTERS_ALL: "All",
      FILTERS_UNREAD: "Unread",
      FILTERS_UNRESOLVED: "Unresolved",
      EMPTY_NO_CONVERSATION_FILTER_UNREAD_TITLE: "No unread message.",
      EMPTY_NO_CONVERSATION_FILTER_UNREAD_LABEL:
        "Only unread messages appear here.",
      EMPTY_NO_CONVERSATION_FILTER_NOT_RESOLVED_TITLE:
        "No unresolved conversation.",
      EMPTY_NO_CONVERSATION_FILTER_NOT_RESOLVED_LABEL:
        "Only unresolved conversations appear here.",
      FILTERS_MORE: "Filters",
      FILTERS_MORE_MENTIONS: "Mentions",
      FILTERS_MORE_CUSTOM: "New custom filter…",
      EMPTY_NO_CONVERSATION_FILTER_MENTION_TITLE: "No mentioned conversation.",
      EMPTY_NO_CONVERSATION_FILTER_MENTION_LABEL:
        "Only conversations you were mentioned in appear here.",
      EMPTY_NO_CONVERSATION_FILTER_CUSTOM_TITLE: "No filtered conversation.",
      EMPTY_NO_CONVERSATION_FILTER_CUSTOM_LABEL:
        "Only conversations that match your filter appear here.",
      POPUPS_NEW_CONVERSATION_PHONE_LABEL: "Phone of the User",
      POPUPS_NEW_CONVERSATION_PHONE_PLACEHOLDER: "Enter the phone of the user…",
      SEARCH_ROUTING_ASSIGNED: "Assigned to me",
      SEARCH_ROUTING_ALL: "All conversations",
      SEARCH_ROUTING_UNASSIGNED: "Unassigned",
      SEARCH_ROUTING_OPERATORS: "Assigned to…",
      FILTERS_MORE_MOST_RECENT: "Most Recent",
      EMPTY_NO_CONVERSATION_FILTER_RECENT_TITLE: "No recent conversation.",
      EMPTY_NO_CONVERSATION_FILTER_RECENT_LABEL:
        "Most recent conversations appear here.",
      FILTERS_MORE_RESOLVED: "Resolved",
      EMPTY_NO_CONVERSATION_FILTER_RESOLVED_TITLE: "No resolved conversation.",
      EMPTY_NO_CONVERSATION_FILTER_RESOLVED_LABEL:
        "Only resolved conversations appear here.",
      POPUPS_NEW_CONVERSATION_KEYS_EMAIL: "Create an email conversation",
      POPUPS_NEW_CONVERSATION_KEYS_PHONE: "Create an SMS conversation",
    },
  },
  VISITORS: {
    COMMON: {
      USERS_ONLINE_ONE: "online user",
      USERS_ONLINE_MULTIPLE: "online users",
      USERS_ACTIVE_ONE: "active user now",
      USERS_ACTIVE_MULTIPLE: "active users now",
      USERS_POWERED_BY: "Live view from MagicMap",
      USERS_PEOPLE_COUNT_SINGULAR: "user at this location",
      USERS_PEOPLE_COUNT_PLURAL: "users at this location",
      SEARCH_FILTER: "Filter by name, city…",
      REALTIME_USERS: "Visitors",
      ALERTS_SOCKET_TITLE: "Visitors may not all appear",
      ALERTS_SOCKET_LINE: "Your website has a lot of traffic.",
      ALERTS_SOCKET_LINE_WHY: "Why?",
      EMPTY_TITLE: "There are no visitors.",
      EMPTY_LABEL:
        "Nobody is currently browsing your website. Your visitors will appear there.",
      PAID_FEATURE:
        "Map zoom is only available from paid plans. Unlock it from Crisp Pro.",
      SHOW_POINT_DETAILS: "Show people there",
      REALTIME_USERS_LIVE: "Live",
    },
    BROWSING: {
      WAITING_FOR_TABS: "Looking for opened pages…",
      LOADING_CONNECTING: "Connecting to your user…",
      LOADING_CONNECTION_LOST: "Oops, connection to the user chatbox was lost.",
      LOADING_DO_NOT_TRACK_1: "This user has enabled",
      LOADING_DO_NOT_TRACK_2: "Do Not Track",
      LOADING_DO_NOT_TRACK_3: "in their browser.",
      TAB_AVAILABLE_SOON: "Crisp might be unable to connect if the user left…",
      LOADING_READY_SOON: "It should be ready in {remaining} seconds…",
      LOADING_LONGER: "It may be a bit longer…",
      LOADING_GONE_OFFLINE:
        "The user may have gone offline, or closed their browser.",
      LOADING_REFUSED:
        "Crisp honors user privacy choices, but you can bypass it in website settings.",
      LOADING_TRY_RECONNECT: "Try to reconnect",
      ACTIONS_LIVE_ASSIST_DEFAULT: "Start LiveAssist",
      ACTIONS_LIVE_ASSIST_ACTIVE: "Stop LiveAssist",
      ACTIONS_LIVE_ASSIST_COMMENT:
        "Move your mouse and scroll to assist on this page.",
      POPUPS_BROWSING_LOCALHOST_TITLE:
        "It seems that your website is running over localhost",
      POPUPS_BROWSING_LOCALHOST_DESCRIPTION:
        "For technical reasons, MagicBrowse requires access to your website from the Internet. Please try MagicBrowse on your production website to make it work. You can contact our support if you need help.",
    },
  },
  ANALYTICS: {
    TITLE: "Analytics",
    TIMEZONE: "Timezone",
    DOWNLOAD: "Download Report",
    CONTROLS: {
      DATE_NAVIGATE_PREVIOUS: "Previous",
      DATE_NAVIGATE_NEXT: "Next",
      DATE_RANGE_HOURLY: "Hourly",
      DATE_RANGE_HOUR: "Hour",
      DATE_RANGE_DAILY: "Daily",
      DATE_RANGE_DAY: "Day",
      DATE_RANGE_WEEKLY: "Weekly",
      DATE_RANGE_WEEK: "Week",
      DATE_RANGE_MONTHLY: "Monthly",
      DATE_RANGE_MONTH: "Month",
      DATE_RANGE_YEAR: "Year",
    },
    POPUPS: {
      GENERATE_REPORT_TITLE: "Generate & download report?",
      GENERATE_REPORT_NOTICE:
        "CSV reports of current screen view will be generated. They will be saved to your computer and made ready for you to use.",
      GENERATE_REPORT_LABEL: "Select a metric",
      GENERATE_REPORT_NAMESPACES: {
        CONVERSATION_CREATED: "Conversation activity",
        CONVERSATION_RESPONSIVENESS: "Support responsiveness",
        CONVERSATION_SEGMENT: "Segments saved over time",
        CONVERSATION_SHORTCUT: "Shortcut usage statistics",
        CONVERSATION_ASSIGNED: "Assigned conversations per operator",
        VISITOR_TRIGGER: "Fired trigger",
        VISITOR_HEATMAP: "Visitor location heatmap",
        VISITOR_CALENDAR: "Website visit calendar",
        PEOPLE_CREATED: "Contacts created over time",
        PEOPLE_RATING: "Rating change over time",
        PEOPLE_RATING_COMMENT: "User ratings & comments",
        CAMPAIGN_SENT: "Sent campaign emails",
        CAMPAIGN_ACTIVITY: "Campaign email activity",
        HELPDESK_FEEDBACK: "Helpdesk feedback for articles",
        HELPDESK_READ: "Helpdesk articles read statistics",
        HELPDESK_SEARCH: "Helpdesk article searches",
        STATUS_DOWNTIME: "Node downtime statistics",
        STATUS_LATENCY: "Pull nodes latency",
      },
      GENERATE_REPORT_FILE_NAME: "Crisp Analytics Report",
      GENERATE_REPORT_PROCEED: "Download Report",
    },
    GRAPHS: {
      CHART_NONE:
        "Oops! Looks like we don\\'t have any data there at the moment.",
      INACTIVE_HELPDESK_ALERT_TITLE:
        "You have no active Helpdesk. Analytics on this page will not be available.",
      INACTIVE_STATUS_ALERT_TITLE:
        "You have no active Status Page. Analytics on this page will not be available.",
      AVERAGE: "Average",
    },
    TOOLTIPS: {
      METAS: {
        CONVERSATION_CREATED: {
          NEW: "New",
          EXISTING: "Existing",
        },
        CAMPAIGN_SENT: {
          "ONE-SHOT": "One-Shot",
          AUTOMATED: "Automated",
        },
        CAMPAIGN_ACTIVITY: {
          CLICKED: "Clicked",
          OPENED: "Opened",
          UNSUBSCRIBED: "Unsubscribed",
          BOUNCED: "Bounced",
          DELIVERED: "Delivered",
        },
        ACTIVITY_GRAPH: {
          BAD: "Bad",
          AVERAGE: "Average",
          GREAT: "Great",
        },
      },
    },
    BOXES: {
      MESSAGING: {
        CONVERSATION_ACTIVITY: {
          HEADER_TITLE: "Activity Last Week Compared to The Week Before",
          ACTIVITY_WEEK_BEFORE: "the week before",
          ACTIVITY_CONVERSATIONS_TITLE: "Conversations",
          ACTIVITY_MEAN_RESPONSE_TIME_TITLE: "Mean response time",
          ACTIVITY_WEBSITE_VISITORS_TITLE: "Website visitors",
        },
        CONVERSATION_CREATED: {
          HEADER_TITLE: "Conversation Activity",
          COUNTERS_NEW_LABEL: "New Conversations",
          COUNTERS_EXISTING_LABEL: "Existing Conversations",
        },
        CONVERSATION_LEADERBOARD: {
          HEADER_TITLE: "Leaderboard",
          LIST_CONVERSATIONS: "conversations",
        },
        CONVERSATION_RESPONSIVENESS: {
          HEADER_TITLE: "Support Responsiveness",
          CALENDAR_LEGEND_MOST: "Most",
          CALENDAR_LEGEND_LEAST: "Least",
        },
        CONVERSATION_SEGMENT: {
          HEADER_TITLE: "Segments Saved Over Time",
          PANE_FILTER_TITLE: "Filter by segment",
        },
        CONVERSATION_SHORTCUT: {
          HEADER_TITLE: "Shortcut Usage Statistics",
          PANE_FILTER_TITLE: "Filter in top bangs",
        },
        CONVERSATION_ASSIGNED: {
          HEADER_TITLE: "Assigned Conversations Per Operator",
          PANE_FILTER_ALL: "All operators",
        },
        VISITOR_TRIGGER: {
          HEADER_TITLE: "Fired Triggers",
          PANE_FILTER_TITLE: "Filter in triggers",
        },
      },
      CONTACTS: {
        VISITOR_HEATMAP: {
          HEADER_TITLE: "Visitor Location Heatmap",
          PANE_TOP_TITLE: "Top countries",
          PANE_TOP_VISITORS: "visitors",
        },
        VISITOR_CALENDAR: {
          HEADER_TITLE: "Website Visit Calendar (Per Day And Hour)",
        },
        PEOPLE_CREATED: {
          HEADER_TITLE: "Contacts Created Over Time",
        },
      },
      RATING: {
        PEOPLE_RATING_ACTIVITY: {
          HEADER_TITLE: "Ratings Compared to Last Week",
          ACTIVITY_LAST_WEEK: "last week",
          ACTIVITY_NO_COMMENT: "some ratings have no comment",
          ACTIVITY_NO_RATINGS: "no ratings yet",
          ACTIVITY_MEAN_SCORE_TITLE: "Mean score",
          ACTIVITY_COMMENTS_TITLE: "Ratings",
        },
        PEOPLE_RATING: {
          HEADER_TITLE: "General Rating Change Over Time",
        },
        PEOPLE_RATING_COMMENT: {
          HEADER_TITLE: "Latest Ratings & Comments",
          MEAN_LABEL: "Mean Score",
          DATATABLE_CONTROLS_SEARCH: "Search",
          DATATABLE_HEAD_FULL_NAME: "Full Name",
          DATATABLE_HEAD_SCORE: "Score",
          DATATABLE_HEAD_COMMENT: "Comment",
          DATATABLE_HEAD_ASSIGNED_OPERATOR: "Assigned",
          DATATABLE_HEAD_REPORTED_ON: "Reported On",
          DATATABLE_ROW_NO_IDENTITY: "Unknown user",
          DATATABLE_ROW_NO_COMMENT: "No comment left",
          DATATABLE_ROW_NO_ASSIGNED: "No operator",
          DATATABLE_ROW_PREVIEW_CONVERSATION: "Preview",
        },
      },
      CAMPAIGNS: {
        CAMPAIGN_SENT: {
          HEADER_TITLE: "Sent Campaign Emails",
          PANE_FILTER_TITLE: "Filter campaign types",
          CHOICE_LABELS: {
            "ONE-SHOT": "One-Shot Campaigns",
            AUTOMATED: "Automated Campaigns",
          },
        },
        CAMPAIGN_ACTIVITY: {
          HEADER_TITLE: "Campaign Email Activity",
          PANE_FILTER_TITLE: "Filter by activity",
          CHOICE_LABELS: {
            CLICKED: "Link Clicked",
            OPENED: "Email Opened",
            UNSUBSCRIBED: "Unsubscribed From Emails",
            BOUNCED: "Email Bounced",
            DELIVERED: "Email Delivered",
          },
        },
      },
      HELPDESK: {
        HELPDESK_FEEDBACK: {
          HEADER_TITLE: "Helpdesk Feedback For Articles",
          SMILES_LABEL: "Feedback",
          SMILES_AGGREGATE_HELPFUL: "Helpful",
          SMILES_AGGREGATE_UNHELPFUL: "Unhelpful",
          DATATABLE_HEAD_ARTICLE: "Article",
          DATATABLE_HEAD_RATING: "Rating",
          DATATABLE_HEAD_COMMENT: "Comment",
          DATATABLE_HEAD_SUBMITTED_BY: "Submitted By",
          DATATABLE_ROW_NO_IDENTITY: "Unknown user",
          DATATABLE_ROW_NO_COMMENT: "No comment left",
          DATATABLE_ROW_PREVIEW_CONVERSATION: "Preview",
        },
        HELPDESK_READ: {
          HEADER_TITLE: "Helpdesk Articles Read Statistics",
        },
        HELPDESK_SEARCH: {
          HEADER_TITLE: "Helpdesk Article Searches",
        },
      },
      STATUS: {
        STATUS_DOWNTIME: {
          HEADER_TITLE: "Node Downtime Statistics",
          PANE_FILTER_ALL: "All nodes",
        },
        STATUS_LATENCY: {
          HEADER_TITLE: "Pull Nodes Latency",
          PANE_FILTER_ALL: "All nodes",
        },
      },
    },
  },
  SETTINGS: {
    COMMON: {
      SETUP: {
        RECORDS_TITLE: "Custom domain setup instructions:",
        RECORDS_STEP_FIRST: "Login to your DNS manager for",
        RECORDS_STEP_RECORD_1: "Add",
        RECORDS_STEP_RECORD_2: "DNS entry for",
        RECORDS_STEP_RECORD_3: "with value",
        RECORDS_STEP_LAST_1:
          "Wait for DNS to propagate (this may take a few hours). Use the",
        RECORDS_STEP_LAST_2: "button below.",
        NONE_TITLE:
          "This domain does not require any setup (default domain provided by Crisp).",
        CONTROLS_STATUS_PENDING:
          "This domain is not yet configured. Check setup instructions.",
        CONTROLS_STATUS_LIVE:
          "This domain is currently in use on your website.",
        CONTROLS_STATUS_UPDATE:
          "You changed the domain. Click on use to see setup instructions.",
        CONTROLS_ACTION_VERIFY: "Verify domain setup",
        CONTROLS_ACTION_VIEW: "View online",
        CONTROLS_ACTION_AUTOCONFIGURE: "Auto-configure",
        CONTROLS_ACTION_USE: "Use this domain",
        RECORDS_STEP_RECORD_MX_PRIORITY: "with priority",
        RECORDS_STEP_RECORD_MX_VALUE: "and value",
      },
      CREDIT_CARD: {
        VALID: "Valid to",
        EXPIRED: "Expired on",
        INVALID: "Invalid card",
        UNLINK: "Unlink card",
      },
    },
    NAVIGATION: {
      SECTIONS: {
        ACCOUNT_TITLE: "Account",
        ACCOUNT_LABEL: "Avatar, name, email, password",
        NOTIFICATIONS_TITLE: "Notifications",
        NOTIFICATIONS_LABEL: "Email, desktop, mobile",
        AVAILABILITY_TITLE: "Availability",
        AVAILABILITY_LABEL: "When visitors see you online",
        BILLING_TITLE: "Cards & Invoices",
        BILLING_LABEL: "Payment methods",
        PLANS_TITLE: "Plans & Subscriptions",
        PLANS_LABEL: "Get more Crisp features",
        WEBSITES_TITLE: "Website Settings",
        WEBSITES_LABEL: "Manage your website",
        SHORTCUTS_TITLE: "Message Shortcuts",
        SHORTCUTS_LABEL: "Manage saved shortcuts",
        HELPDESK_TITLE: "Helpdesk",
        HELPDESK_LABEL: "Manage your helpdesk",
        STATUS_TITLE: "Status Page",
        STATUS_LABEL: "Manage your status page",
      },
      EXTRAS: {
        HELP_TRANSLATE_CLIENT: "Help translate the chatbox",
        GET_HELP: "Get help using Crisp",
        STATUS_PAGE: "Service status",
      },
    },
    CONTENT: {
      HEAD: {
        SAVE: "Automatically Saved",
        SAVING: "Saving changes…",
      },
      BOX: {
        FOOTER_HELP: "Help",
      },
    },
    ACCOUNT: {
      TITLE: "Account Settings",
      DESCRIPTION_1_1:
        "Only your first name is visible to users. Your last name is kept private anytime.",
      DESCRIPTION_1_2:
        "Your email is used to send you notifications. Your visitors will not see it.",
      DESCRIPTION_1_3:
        "A phone number can be used for account recovery purposes. It is required for 2-Step.",
      FIRST_NAME_LABEL: "First Name",
      FIRST_NAME_PLACEHOLDER: "Enter your first name",
      LAST_NAME_LABEL: "Last Name",
      LAST_NAME_PLACEHOLDER: "Enter your last name",
      EMAIL_LABEL: "Email",
      EMAIL_PLACEHOLDER: "Enter your email address",
      PHONE_LABEL: "Phone",
      PHONE_PLACEHOLDER: "Enter a number (eg. +16281234567)",
      AVATAR_LABEL: "Avatar",
      AVATAR_INSTRUCTIONS: "Image, max. 5MB",
      PASSWORD_LABEL: "Password",
      PASSWORD_PLACEHOLDER: "Enter your new password",
      CHANGE_PASSWORD: "Change password",
      TOKEN_LABEL: "2-Step",
      ENABLE_TOKEN: "Enable Two Factor Authentication",
      DISABLE_TOKEN: "Disable Two Factor Authentication",
      PHONE_REQUIRED_LABEL: "A phone number is required for 2-Step.",
      DELETE_1: "Looking to remove your Crisp account?",
      DELETE_2: "Delete your account",
      DELETE_3: "there.",
      HELP_1:
        "# Do my users see my full name?\\nYour users **only see your first name**, whether they receive chat responses from you, or emails. Your last name is **kept private** and is only seen by other operators in your team.\\n-----\\n# What is my email used for?\\nYour email address is used to send you **email notifications of unread messages** from visitors. You may disable email notifications anytime in the _Notifications_ settings.\\n-----\\n# What is my avatar used for?\\nYour avatar photo is **seen by your visitors**, as well as other operators. It is used to identify yourself visually and put an human face in your chatbox and emails.\\n-----\\n# Why do I still see an avatar after I removed it?\\nIf no avatar is defined, **Crisp uses your Gravatar**, based on your email. In case you already use Gravatar and linked an image to your email on Gravatar, Crisp will default to this one.\\n-----\\n# Notes on password security\\nWe advise that you **use a strong password**. Note that Crisp takes security very seriously, and our systems are resilient against external password attacks. Passwords are stored encrypted.",
      SUBTITLE_2: "Account security",
      DESCRIPTION_2:
        "Manage apps connected to your Crisp account. Review session history.",
      SECURITY_COMMON_EMPTY: "No session found.",
      SECURITY_COMMON_FROM: ", from",
      SECURITY_COMMON_LOCATION_UNKNOWN: "Unknown location",
      SECURITY_APPS_TITLE: "Authorized apps",
      SECURITY_APPS_CLOSE: "Close session",
      SECURITY_HISTORY_TITLE: "Recent login history",
      SECURITY_HISTORY_STATE_AUTHORIZED: "Authorized",
      SECURITY_HISTORY_STATE_CLOSED: "Closed",
      HELP_2:
        "# What should I do if I see an app I don\\'t recognize?\\n**If you see an app you don\\'t remember to have logged with, you should immediately close the session**. Then, change your Crisp account password; as your account may have been compromised (ie. someone knew your password). Also, make sure to configure Two Factor Authentication for more security.",
      POPUPS_CHANGE_EMAIL_QUESTION: "Change your email now?",
      POPUPS_CHANGE_EMAIL_LABEL: "Your email will be changed to:",
      POPUPS_CHANGE_EMAIL_PASSWORD_LABEL: "Please identify yourself",
      POPUPS_CHANGE_EMAIL_PASSWORD_PLACEHOLDER:
        "Enter your password to change your email address…",
      POPUPS_CHANGE_EMAIL_PROCEED: "Change Email",
      POPUPS_CHANGE_PASSWORD_QUESTION: "Change your password now?",
      POPUPS_CHANGE_PASSWORD_PASSWORD_LABEL: "Please identify yourself",
      POPUPS_CHANGE_PASSWORD_PASSWORD_PLACEHOLDER:
        "Enter your old password to change your password…",
      POPUPS_CHANGE_PASSWORD_LABEL:
        "You will be logged out to login with the new password.",
      POPUPS_CHANGE_PASSWORD_PROCEED: "Change Password",
      POPUPS_DISABLE_TOKEN_QUESTION: "Disable Two Factor Authentication?",
      POPUPS_DISABLE_TOKEN_LABEL:
        "No 2-Step code will be asked at login anymore. Be warned: your account will be less secure against password theft.",
      POPUPS_DISABLE_TOKEN_PASSWORD_LABEL: "Please identify yourself",
      POPUPS_DISABLE_TOKEN_PASSWORD_PLACEHOLDER:
        "Enter your password to disable your token…",
      POPUPS_DISABLE_TOKEN_PROCEED: "Disable Token",
      POPUPS_DELETE_ACCOUNT_CONFIRM_LABEL: "Confirm Account Deletion",
      POPUPS_DELETE_ACCOUNT_CONFIRM_PLACEHOLDER: "Type your account email…",
      POPUPS_DELETE_ACCOUNT_EMPHASIS_1:
        "All account data will be permanently deleted and non-recoverable.",
      POPUPS_DELETE_ACCOUNT_EMPHASIS_2:
        "There is no coming back after you press delete.",
      POPUPS_DELETE_ACCOUNT_PASSWORD_LABEL: "Please identify yourself",
      POPUPS_DELETE_ACCOUNT_PASSWORD_PLACEHOLDER:
        "Enter your password to delete your account…",
      POPUPS_DELETE_ACCOUNT_PROCEED: "Delete My Crisp Account",
      POPUPS_CLOSE_SESSION_QUESTION: "Close this session now?",
      POPUPS_CLOSE_SESSION_LABEL:
        "The app connected to this session will no longer have access to your Crisp account, and will need to login again to use Crisp.",
      POPUPS_CLOSE_SESSION_PROCEED: "Close Session",
      LANGUAGE_LABEL: "Language",
      LANGUAGE_DEFAULT: "Auto-Detect",
    },
    NOTIFICATIONS: {
      TITLE: "Notification Settings",
      DESCRIPTION_1: "Choose how you want to manage your notifications.",
      DESCRIPTION_2:
        "It is not recommended to disable them, as you won\\'t get notified when you receive new messages.",
      DISABLED: "Disable all notifications",
      MESSAGES_ONLINE:
        "Notify me messages when I am online (push notifications)",
      MESSAGES_OFFLINE:
        "Notify me messages when I am offline (push notifications)",
      MESSAGES_TRANSCRIPT: "Email me transcripts of conversations",
      MESSAGES_RATING: "Email me user ratings",
      MESSAGES_SOUNDS: "Play notification sounds",
      HELP: "# What are transcripts?\\nTranscripts of conversations help you archive **full records of chats in your mailbox**. This can be useful if you need to forward the chat history to someone who is not allowed to use your Crisp dashboard.\\n-----\\n# To which email address notifications are sent?\\nCrisp sends email to the email address that is configured for your account.",
      BILLING_INVOICE: "Email me paid invoices (only if you use a paid plan)",
      VISITOR_BROWSING:
        "Let me know when a visitor is browsing my website (push notifications)",
      MESSAGES_UNREAD: "Email me unread messages",
      ALERT_DENIED_MESSAGE:
        "It seems that notifications are disabled in your browser settings.",
      ALERT_DENIED_ACTION: "How to enable notifications?",
    },
    AVAILABILITY: {
      TITLE: "Availability Settings",
      DESCRIPTION_1:
        "Set yourself available on schedule, by configuring days and times (in your timezone). Visitors will see you as away off scheduled hours, but they can still send you messages.",
      DESCRIPTION_2:
        "If you are member of a website with multiple operators, the chatbox will be seen as online if at least one operator is available, and away if all operators are unavailable.",
      CURRENTLY_SEEN: "You are currently seen as:",
      ONLINE: "Online",
      OFFLINE: "Offline",
      STEALTH: "Force offline (invisible mode)",
      PRESENCE: "Set me available when using the app",
      ENABLED: "Enable availability schedule",
      DAYS: "Days",
      DAYS_SHORT: {
        MONDAY: "Mon",
        TUESDAY: "Tue",
        WEDNESDAY: "Wed",
        THURSDAY: "Thu",
        FRIDAY: "Fri",
        SATURDAY: "Sat",
        SUNDAY: "Sun",
      },
      HOURS: "Hours",
      ADD_INTERVAL: "Add an interval",
      HELP: "# What is ‘invisible mode’?\\nInvisible mode lets you **force an offline availability** indefinitely, until you disable it. It is useful if you go on an extended holiday and you do not want to change your schedule settings.\\n\\nYou can quickly toggle invisible mode from the sidebar, by clicking on your website icon and selecting the invisible mode option. Crisp will show a reminder that you are offline so that you do not forget.\\n-----\\n# What is ‘set me available when using the app’?\\nThis option lets you **appear online when you are actively using a Crisp app**, even if you are off-schedule.\\n-----\\n# How does the schedule work?\\nThe schedule lets you define **automatic online and offline days and times**. It is useful if you have fixed working hours and not actively using Crisp apps all day long. For instance, if you want to appear online and reply from your phone. The configuration is saved and applied relative to your **local timezone**.\\n-----\\n# When my websites will appear as online?\\nThe websites you are a member of will appear as online if **at least** one member operator is online.\\nIn other words, if you are online as per your availability settings, the websites you are member of will appear online. If you do not want to appear online temporarily, you can enable invisible mode.",
      TIMEZONE: "Timezone",
    },
    BILLING: {
      TITLE: "Cards & Invoices",
      DEFAULT: {
        ALERT_CARD_INVALID:
          "You have an invalid or expired payment method. Please update it to keep subscriptions active.",
        ALERT_CARD_NONE:
          "No payment method. Add a payment method to start adding subscriptions to your websites!",
        PAID_TITLE: "This is what you pay monthly.",
        PAID_DESCRIPTION:
          "Payments are done monthly or yearly based on your plan preferences.",
        DESCRIPTION_1: "You can cancel or subscribe at anytime. No commitment.",
        DESCRIPTION_2:
          "A payment method can be linked to multiple website (eg: if you have websites for multiple businesses)",
        CARD_PAYMENT_METHOD: "Card",
        CARD_IDENTITY_VALUE_OWNER: "Owner:",
        CARD_IDENTITY_VALUE_ADDRESS: "Address:",
        CARD_IDENTITY_VALUE_VAT: "VAT ID:",
        CARD_IDENTITY_VALUE_EMPTY: "None set",
        CARD_ACTIONS_SEE_INVOICES: "See invoices",
        CARD_ACTIONS_REMOVE_CARDS: "Remove card",
        CARD_LINKS_LINKED_TO_NONE: "This card is not linked to any website",
        CARD_LINKS_LINKED_TO_1: "This card is linked to",
        CARD_LINKS_LINKED_TO_2: "websites",
        CARD_LINKS_SEE: "See linked websites",
        CARD_ADD_NEW_METHOD: "Add new payment method",
        POPUPS_REMOVE_CARD_QUESTION: "Remove this payment method?",
        POPUPS_REMOVE_CARD_LABEL:
          "Make sure it is not linked to any website before you proceed.",
        POPUPS_REMOVE_CARD_PROCEED: "Remove Payment Method",
        HELP: "# How often are payment made?\\nPayments are made at the **beginning of each month** for the plans you are subscribed to.\\nPayments in due time guarantee uninterrupted service.\\n-----\\n# Can I cancel a trial and not be billed?\\nYes! You simply need to **switch your plan to the free tier** for your websites and you will not be billed when your trial expires.\\n-----\\n# What if a payment fails?\\nIf a payment fails, you will be **notified by email**. We will then **retry multiple times over a week** and we will send further emails if those also fail. If all retries fail, the subscriptions paid with the failing payment method are deactivated after a week.\\n\\nOf course, you can **reactive the plans later on** when you update your payment information.",
        CARD_ACTIONS_EDIT: "Edit",
        SEE_ALL_INVOICES: "See all invoices",
      },
      INVOICES: {
        TITLE: "Invoices",
        DESCRIPTION_1: "Those are your invoices for the payment method",
        DESCRIPTION_2:
          "You may download those invoices regularly, to keep a copy of them.",
        NO_INVOICES_TITLE: "There are no invoices for this payment method.",
        NO_INVOICES_HINT:
          "Looking for website invoices? Go to the website settings and check the Billing section. The billing owner email address will be shown there. You will need to login to this Crisp account in order to retrieve all your invoices.",
        INVOICE_REFUNDED: "Refund",
        INVOICE_PAID: "Paid",
        INVOICE_UNPAID: "Unpaid",
        INVOICE_STATISTICS_EMPTY: "Empty invoice",
        INVOICE_STATISTICS_WEBSITE_SINGULAR: "website",
        INVOICE_STATISTICS_WEBSITE_PLURAL: "websites",
        INVOICE_STATISTICS_PLUGIN_SINGULAR: "plugin",
        INVOICE_STATISTICS_PLUGIN_PLURAL: "plugins",
        INVOICE_STATISTICS_LIMIT_SINGULAR: "limit",
        INVOICE_STATISTICS_LIMIT_PLURAL: "limits",
        INVOICE_TOTAL: "Total:",
        INVOICE_DOWNLOAD: "Download",
        INVOICE_OLDER: "Load older invoices…",
        HELP: "# When are the invoices generated?\\nInvoices are generated **before a payment is made**. Once the payment succeeds, **a downloadable invoice is created**.\\n-----\\n# For how long are invoices available?\\n**Invoices are kept forever**, you will still be able to retrieve them later on.",
      },
    },
    PLANS: {
      TITLE: "Plans & Subscriptions",
      ALERT_PAYMENT_GO_TO_BILLING: "Go to billing settings",
      ALERT_PAYMENT_NO_CARD:
        "You do not have any payment method. Add one to subscribe to paid features.",
      SUBTITLE_1: "Manage all website plans",
      DESCRIPTION_1: "Manage all your website plan subscriptions.",
      HELP_1:
        "# Can I upgrade an ongoing subscription?\\nYes! **You may upgrade your ongoing subscription anytime**. You will only be charged at the beginning of the next month.\\n-----\\n# What happens when my trial expires?\\nWhen your trial expires, **the plan is automatically converted to a regular plan**. Your payment method gets charged at the beginning of each month.\\n\\nIf you do not want to keep that plan, you may unsubscribe before the end of the trial (you will not be charged).\\n-----\\n# I have a coupon. How do I apply it?\\nYou may **apply your coupon anytime to the website of your choice**, by clicking on the ‘Coupon’ button. If it does not appear, it means you already have applied a coupon for the website (coupons cannot be cumulated on a website).",
      SUBTITLE_2: "Subscriptions summary",
      DESCRIPTION_2:
        "View the summary of all your subscriptions, and the breakdown of how much you pay.",
      HELP_2:
        "# What are subscriptions?\\nSubscriptions provide you with **extra features to make the most out of Crisp**. They are paid monthly or yearly. They can either be plans, plugins, or increased limits.",
      REDEEM_COUPON: "Coupon",
      FREE: "Free",
      PER_MONTH: "/mth",
      TRIAL_TO: "Trial to",
      ACTIVE: "Active",
      INACTIVE: "Inactive",
      TRIAL: "Trial",
      UPDATE_PAYMENT: "Update Payment",
      POPUPS_REDEEM_COUPON_CODE_LABEL: "Coupon code",
      POPUPS_REDEEM_COUPON_CODE_PLACEHOLDER: "Enter the coupon code…",
      POPUPS_REDEEM_COUPON_PROCEED: "Apply Coupon",
      RENEWS_ON: "Renews on",
      SANDBOX_MODE: "Sandbox mode",
      PAID_MONTHLY: "paid monthly",
      PAID_YEARLY: "paid yearly",
      PERIOD_MONTHLY: "Monthly",
      PERIOD_YEARLY: "Yearly",
      PER_YEAR: "/yr",
      DETAILS_PLANS: "Plans",
      DETAILS_PLUGINS: "Plugins",
      DETAILS_LIMITS: "Limits",
      MANAGE_PLANS: "Change plan",
      MANAGE_PLUGINS: "Manage plugins",
      MANAGE_LIMITS: "Manage limits",
      MANAGES_PLAN: "manages this plan",
      CHANGE_BILLING_OWNER: "Change billing owner",
      POPUPS_BILL_PERIOD_QUESTION: "The billing period will be changed to:",
      POPUPS_BILL_PERIOD_CONFIRM:
        "Please enter the following word to confirm you want to switch the billing period:",
      POPUPS_BILL_PERIOD_PROCEED: "Change Billing Period To",
      CHANGE_PLAN: "Change Plan",
    },
    WEBSITES: {
      TITLE: "Website Settings",
      SUBTITLE: "Website memberships",
      DESCRIPTION_PART_1: "Those are the websites you are member of.",
      DESCRIPTION_PART_2: "Select a website to access its settings.",
      INTEGRATIONS: "Integrations",
      ADD_NEW_WEBSITE: "Add a new website…",
      POPUPS_NEW_WEBSITE_NAME_LABEL: "Website name",
      POPUPS_NEW_WEBSITE_NAME_PLACEHOLDER: "Enter website name (eg. Acme)",
      POPUPS_NEW_WEBSITE_DOMAIN_LABEL: "Website domain",
      POPUPS_NEW_WEBSITE_DOMAIN_PLACEHOLDER:
        "Enter website domain (eg. acme.com)",
      POPUPS_NEW_WEBSITE_PROCEED: "Create Website",
      POPUPS_NEW_WEBSITE_CONFIRM_QUESTION:
        "This website domain already exists on Crisp",
      POPUPS_NEW_WEBSITE_CONFIRM_NOTICE:
        "You may be duplicating a website that already exists on Crisp, which may create confusion into which website is the correct one. The website may exist either on your own Crisp account on or someone else Crisp account.",
      POPUPS_NEW_WEBSITE_CONFIRM_PROCEED: "Yes, Create Website",
      HELP: "# What is a website?\\nA website is a **Crisp group for a team of operators**. Each website has its specific integrations (eg. chatbox code). You can manage multiple websites in the same Crisp account.\\n-----\\n# How many websites can I create?\\nThere is **no limit** on the amount of websites you can create and manage on a single Crisp account.\\n-----\\n# How do plans work with websites?\\n**A plan subscription is tied to a Crisp website**. It means you can own websites with a paid plan, as well as free websites, on the same Crisp account.",
      SETTINGS: "Settings",
    },
    WEBSITE: {
      TITLE: "Website Settings",
      ALERT_NOT_OWNER:
        "You are not an owner of this website. You will not be able to change its settings.",
      ALERT_CARD_INVALID:
        "The payment method linked to this website is invalid or expired. Please review it.",
      ALERT_CARD_NONE:
        "This website is not linked to any payment method. Link one to keep your subscriptions.",
      ALERT_EMAIL_DOMAIN_INVALID:
        "The last check of your custom email domain failed. Ensure you did not change domain DNS records. Next check will be done tomorrow. Once re-validated, this will disappear.",
      ALERT_HIDE_VACATION:
        "Your chatbox is currently hidden as your support is on vacation.",
      SUBTITLE_1: "Website information",
      DESCRIPTION_1:
        "Configure your website information. This defines how your website appears to your users.",
      INFORMATION_WEBSITE_DOMAIN_LABEL: "Website domain",
      INFORMATION_WEBSITE_DOMAIN_PLACEHOLDER: "acme.com",
      INFORMATION_WEBSITE_NAME_LABEL: "Website name",
      INFORMATION_WEBSITE_NAME_PLACEHOLDER: "Acme Inc.",
      INFORMATION_WEBSITE_LOGO_LABEL: "Website icon",
      INFORMATION_WEBSITE_LOGO_INSTRUCTIONS: "Image, max. 5MB",
      HELP_1:
        "# What is the website name used for?\\nThe website name is **visible on the chatbox**, as well as **in emails** sent to your users. You may use your full website name (eg. Acme).\\n-----\\n# What is the website domain used for?\\nThe website domain is used in **links sent to your users** in emails, so that they can access your website and resume their chat session. It is also used to **get your website logo**.\\n\\nThe domain is not used to restrict the chatbox to your website by default, but you may enable the option in the preferences below. Once enabled, the website domain set here will be used to restrict the chatbox to your website, for security purposes. Crisp matches all sub-domains of your domain, so ensure you set the base domain here (eg. acme.com, not www.acme.com).\\n-----\\n# Why do I still see a logo when I removed the previous one?\\nIf no logo is defined, **Crisp uses the website Favicon**.",
      SUBTITLE_2: "Setup instructions",
      DESCRIPTION_2:
        "Find instructions on how to setup the chatbox or email redirect.",
      WEBSITE_ID_LABEL: "Website ID",
      WEBSITE_ID_COPY_BUTTON: "Copy",
      SETUP_CHATBOX_LABEL: "Chatbox",
      SETUP_CHATBOX_BUTTON: "Chatbox setup instructions",
      HELP_2:
        "# Which CMS is the Crisp chatbox compatible with?\\n**Crisp is CMS-agnostic**; that means you can install the Crisp chatbox anywhere and it will work, provided you can insert our JavaScript in your templates.\\n\\nAlso, we have plugins for popular CMS (eg. WordPress), to make your life easier.",
      SUBTITLE_3: "Operators in the team",
      DESCRIPTION_3:
        "Add people that will be able to handle support for the website.",
      OPERATORS_AVAILABILITY_SEEN_1: "Support is",
      OPERATORS_AVAILABILITY_SEEN_2_ONLINE:
        "as at least one operator is online.",
      OPERATORS_AVAILABILITY_SEEN_2_OFFLINE: "as all operators are offline.",
      OPERATORS_AVAILABILITY_ONLINE: "Online",
      OPERATORS_AVAILABILITY_OFFLINE: "Offline",
      OPERATORS_AVAILABILITY_EMPTY_LAST_ACTIVE: "Empty Last Active",
      OPERATORS_AVAILABILITY_ADD_OPERATOR: "Add Operator",
      OPERATORS_PENDING_INVITE: "Pending invite",
      OPERATORS_SANDBOX_DEVELOPER: "Plugin developer",
      OPERATORS_INVITED: "Invited",
      OPERATORS_MARKETPLACE: "Marketplace",
      OPERATORS_OWNER: "Owner",
      OPERATORS_MEMBER: "Member",
      POPUPS_EMPTY_LAST_ACTIVE_QUESTION:
        "Are you sure to empty last active operators?",
      POPUPS_EMPTY_LAST_ACTIVE_LABEL:
        "The list of last operators in the chatbox will be cleaned.",
      POPUPS_EMPTY_LAST_ACTIVE_PROCEED: "Empty Last Active",
      POPUPS_ADD_OPERATOR_ROLE_LABEL: "Operator role",
      POPUPS_ADD_OPERATOR_ROLE_OWNER: "Owner",
      POPUPS_ADD_OPERATOR_ROLE_MEMBER: "Member",
      POPUPS_ADD_OPERATOR_EMAIL_LABEL: "Operator email",
      POPUPS_ADD_OPERATOR_EMAIL_PLACEHOLDER: "Enter email to send invite to…",
      POPUPS_ADD_OPERATOR_PASSWORD_LABEL: "Please identify yourself",
      POPUPS_ADD_OPERATOR_PASSWORD_PLACEHOLDER:
        "Enter your account password to continue…",
      POPUPS_ADD_OPERATOR_PROCEED: "Invite Operator",
      POPUPS_ADD_OPERATOR_UPGRADE_PLAN_TITLE: "Please upgrade your plan",
      POPUPS_ADD_OPERATOR_UPGRADE_PLAN_DESCRIPTION:
        "You have reached your current plan operators limit.\\n\\nPlease follow our instructions to upgrade to a higher plan.",
      POPUPS_ADD_OPERATOR_UPGRADE_PLAN_PROCEED: "See Plan Options",
      POPUPS_ADD_OPERATOR_UPGRADE_LIMIT_DESCRIPTION:
        "You have reached your operators limit, included for free in your Unlimited plan.\\n**You can add more operators anytime by increasing your limits**.\\n\\nPlease follow our instructions to subscribe to higher limits.",
      POPUPS_ADD_OPERATOR_UPGRADE_LIMIT_PROCEED: "Increase Operator Limits",
      POPUPS_EDIT_OPERATOR_TITLE_LABEL: "Operator job title (can be blank)",
      POPUPS_EDIT_OPERATOR_TITLE_PLACEHOLDER:
        "Enter a job title… (eg. \\'Customer Success\\')",
      POPUPS_EDIT_OPERATOR_PROCEED: "Save Operator",
      POPUPS_REMOVE_OPERATOR_QUESTION:
        "Are you sure you want to remove this operator?",
      POPUPS_REMOVE_OPERATOR_LABEL:
        "This operator will not be able to access the website anymore.",
      POPUPS_REMOVE_OPERATOR_PROCEED: "Remove Operator",
      HELP_3:
        "# What does ‘Empty Last Active’ mean?\\nThe empty last active operators button allows you to **clear the list of last active operators** your users can see in the **Crisp chatbox**. In some cases (eg. an operator you do not want to see in this list), you may want to reset it. Use this button to empty the list.\\n\\nThe list will get populated with active operators once someone in the team sends a message to an user.\\n-----\\n# How many operators can I add per-website?\\nCrisp restricts the number of operators you can add per-plan. **Upgrade to Crisp Pro to get more operator slots**, and get an unlimited number of slots with Crisp Unlimited.\\n-----\\n# How can I invite new operators?\\nYou can invite new operators **using their email**. Crisp will then use this email to send an invite link, which the operator can use with either a new Crisp account, or his existing Crisp account.",
      SUBTITLE_4: "Contact information",
      DESCRIPTION_4:
        "Set your public contact information so that visitors have other ways to directly contact you.",
      CONTACT_WEBSITE_EMAIL_LABEL: "Website email",
      CONTACT_WEBSITE_EMAIL_PLACEHOLDER: "support{\\'@\\'}acme.com",
      CONTACT_WEBSITE_PHONE_LABEL: "Website phone",
      CONTACT_WEBSITE_PHONE_PLACEHOLDER: "+1 (628) 123-4567",
      HELP_4:
        "# Where can users see the contact information?\\nThe contact information can be seen in **chat transcript emails** sent to your users, once a chat is finished. This lets your user reach you more directly.\\n\\nThose information are not mandatory. If it is not set, it will not be shown in emails.",
      SUBTITLE_5: "Chatbox & Email settings",
      DESCRIPTION_5:
        "Configure the behavior and appearance of your chatbox and emails.",
      OPEN_OPTIONS: "Open options",
      HIDE_OPTIONS: "Hide options",
      CHATBOX_BEHAVIOR: "Chatbox Behavior",
      CHATBOX_BEHAVIOR_LAST_OPERATOR_FACE:
        "Show last active operator face in chatbox button when no chat is ongoing",
      CHATBOX_BEHAVIOR_ONGOING_OPERATOR_FACE:
        "Show operator face in chatbox button when a chat is ongoing",
      CHATBOX_BEHAVIOR_ACTIVITY_METRICS:
        "Show activity metrics in the chatbox (eg: mean time to reply)",
      CHATBOX_BEHAVIOR_AVAILABILITY_TOOLTIP:
        "Show the availability tooltip when chatbox is closed",
      CHATBOX_BEHAVIOR_HIDE_ON_AWAY:
        "Hide the chatbox if all operators are away",
      CHATBOX_BEHAVIOR_HIDE_ON_MOBILE: "Hide the chatbox on mobile devices",
      CHATBOX_BEHAVIOR_EMAIL_VISITORS: "Ask visitors for their email address",
      CHATBOX_APPEARANCE: "Chatbox Appearance",
      CHATBOX_APPEARANCE_POSITION_REVERSE:
        "Reverse the position of the chatbox (show on the left)",
      CHATBOX_SECURITY: "Chatbox Security",
      CHATBOX_APPEARANCE_COLOR_THEME: "Color theme (chatbox color)",
      CHATBOX_APPEARANCE_COLOR_THEME_CHOICES: {
        DEFAULT: "Default (Blue)",
        AMBER: "Amber",
        BLACK: "Black",
        BLUE: "Blue",
        BLUE_GREY: "Blue (Grey)",
        LIGHT_BLUE: "Blue (Light)",
        BROWN: "Brown",
        CYAN: "Cyan",
        GREEN: "Green",
        LIGHT_GREEN: "Green (Light)",
        GREY: "Grey",
        INDIGO: "Indigo",
        ORANGE: "Orange",
        DEEP_ORANGE: "Orange (Deep)",
        PINK: "Pink",
        PURPLE: "Purple",
        DEEP_PURPLE: "Purple (Deep)",
        RED: "Red",
        TEAL: "Teal",
      },
      CHATBOX_APPEARANCE_TEXT_THEME:
        "Text theme (text visible on chatbox header)",
      CHATBOX_APPEARANCE_TEXT_THEME_CHOICES: {
        1: "Questions? Chat with me!",
        2: "Ask us your questions",
        3: "Ask me your questions",
        4: "Chat with support",
        DEFAULT: "Questions? Chat with us!",
      },
      CHATBOX_APPEARANCE_WELCOME_MESSAGE:
        "Welcome message (first message seen by users)",
      CHATBOX_APPEARANCE_WELCOME_MESSAGE_CHOICES: {
        1: "Hey, want to chat with us?",
        2: "Anything you want to ask?",
        3: "Hello, ask us any question about our website.",
        4: "Hello! How can I help you? :)",
        5: "Any question about our website?",
        DEFAULT: "How can we help you with our website?",
      },
      CHATBOX_APPEARANCE_TILE: "Chatbox background (messages texture)",
      CHATBOX_APPEARANCE_TILE_CHOICES: {
        DEFAULT: "Default (No background)",
      },
      CHATBOX_APPEARANCE_LOCALE:
        "Chatbox language (autodetect, or force a language)",
      CHATBOX_APPEARANCE_LOCALE_AUTODETECT: "Detect from user country",
      CHATBOX_APPEARANCE_CUSTOMIZATION: "Advanced chatbox customization",
      CHATBOX_APPEARANCE_CUSTOMIZATION_GO: "Go to customization plugin",
      CHATBOX_SECURITY_CHECK_DOMAIN:
        "Lock the chatbox to website domain (and subdomains)",
      CHATBOX_RESTRICTIONS: "Chatbox Restrictions",
      CHATBOX_RESTRICTIONS_HIDE_VACATION:
        "Place support in vacation (hide chatbox)",
      CHATBOX_RESTRICTIONS_ALLOWED_PAGES: "Show chatbox only on pages",
      CHATBOX_RESTRICTIONS_ALLOWED_PAGES_ADD: "Add an allowed page",
      CHATBOX_RESTRICTIONS_BLOCKED_PAGES: "Hide chatbox on pages",
      CHATBOX_RESTRICTIONS_BLOCKED_PAGES_ADD: "Add a blocked page",
      CHATBOX_RESTRICTIONS_BLOCKED_COUNTRIES: "Hide chatbox for countries",
      CHATBOX_RESTRICTIONS_BLOCKED_COUNTRIES_ADD: "Add a blocked country",
      CHATBOX_RESTRICTIONS_BLOCKED_LOCALES: "Hide chatbox for locales",
      CHATBOX_RESTRICTIONS_BLOCKED_LOCALES_ADD: "Add a blocked locale",
      CHATBOX_RESTRICTIONS_BLOCKED_IPS: "Hide chatbox for IPs",
      CHATBOX_RESTRICTIONS_BLOCKED_IPS_ADD: "Add a blocked IP",
      CHATBOX_RESTRICTIONS_VISITORS_BLOCKED: "Blocked visitors",
      CHATBOX_RESTRICTIONS_VISITORS_BLOCKED_CLEAR:
        "Clear all block rules (IP & Email)",
      EMAILS_GENERAL: "Email Settings",
      EMAILS_GENERAL_TRANSCRIPT: "Email users transcripts of conversations",
      EMAILS_GENERAL_RATING: "Ask users to rate website in transcript emails",
      EMAILS_GENERAL_ENRICH:
        "Enrich contact profiles using their email (eg. name & avatar)",
      EMAILS_GENERAL_JUNK_FILTER: "Filter junk emails (block them)",
      INBOX_GENERAL: "Inbox Settings",
      INBOX_GENERAL_LOCK_REMOVAL:
        "Prevent non-owner operators to remove data (conversations & contacts)",
      INBOX_GENERAL_FORCE_OPERATOR_TOKEN:
        "Force operators to have Two Factor Authentication enabled",
      INBOX_GENERAL_HIDE_SEND_CONFIRM_POPUP: 'Hide the "Send confirm" popup',
      POPUPS_ADD_ALLOWED_PAGE_URL_LABEL:
        "Allow a page by URL (use * for any sub-page, ** for anything)",
      POPUPS_ADD_ALLOWED_PAGE_URL_PLACEHOLDER: "acme.com/contact/*",
      POPUPS_ADD_ALLOWED_PAGE_PROCEED: "Add Allowed Page",
      POPUPS_ADD_BLOCKED_PAGE_URL_LABEL:
        "Block a page by URL (use * for any sub-page, ** for anything)",
      POPUPS_ADD_BLOCKED_PAGE_URL_PLACEHOLDER: "acme.com/blog/*/category/**",
      POPUPS_ADD_BLOCKED_PAGE_PROCEED: "Add Blocked Page",
      POPUPS_ADD_BLOCKED_COUNTRY_CODE_LABEL: "Block users from country",
      POPUPS_ADD_BLOCKED_COUNTRY_PROCEED: "Add Blocked Country",
      POPUPS_ADD_BLOCKED_LOCALE_CODE_LABEL: "Block users with locale",
      POPUPS_ADD_BLOCKED_LOCALE_PROCEED: "Add Blocked Locale",
      POPUPS_ADD_BLOCKED_IP_LABEL:
        "Block the chatbox for the following IPs (CIDR notation accepted)",
      POPUPS_ADD_BLOCKED_IP_PLACEHOLDER: "168.1.2.5",
      POPUPS_ADD_BLOCKED_IP_PROCEED: "Add Blocked IP",
      HELP_5:
        "# How do I test those settings?\\nOnce you update those settings, **open a page to your website**, and you will see your chatbox with updated settings.\\n-----\\n# Why am I getting an invalid chatbox code error?\\nMost often, this error happens when you enabled the **Lock chatbox to website domain** option in **Chatbox Security** settings, and there is a mismatch between your configured website domain in Crisp, and the website domain you are using in your browser.\\n\\nYou may either **disable this option** or adjust your website domain in Crisp.\\n-----\\n# Which format do the URL blocker recognizes?\\nIf you use the feature to hide the chatbox on certain pages, you may wonder which URL format is recognized.\\n\\nSee below some recognized formats:\\n\\n* __acme.com__\\n* __www.acme.com__\\n* __/blog/article/__\\n* __acme.com/blog/article/__\\n\\nNotice that you can also **use wildcards to fuzzy match URLs**.",
      SUBTITLE_6: "Billing settings",
      DESCRIPTION_6:
        "Select the payment method to be used for this website, used for subscriptions payment.",
      BILLING_CARD_ADD: "No card available, please add one.",
      BILLING_ACTIONS_WANT_TO: "Want to add or remove payment cards?",
      BILLING_ACTIONS_GO: "Go to Cards & Invoices…",
      BILLING_ACTIONS_OWNER_INVOICES: "Looking for website invoices?",
      BILLING_ACTIONS_OWNER_EMAIL: "Use account:",
      POPUPS_UNLINK_BILLING_QUESTION:
        "Are you sure you want to unlink this card?",
      POPUPS_UNLINK_BILLING_LABEL:
        "You will be able to replace this card to keep subscriptions active.",
      POPUPS_UNLINK_BILLING_PROCEED: "Unlink Card",
      HELP_6:
        "# Why should I assign a payment card per-website?\\nCrisp allows you to own multiple websites, and pay subscriptions (if a paid plan is subscribed) using different payment methods, or the same payment method (eg. you only have a single payment method).\\n\\nThat is why you should select the payment method to use for the website here.",
      SUBTITLE_7: "Message routing rules",
      DESCRIPTION_7:
        "Enable or disable routing and configure operators to assign based on rules.",
      DANGER_WEBSITE_REMOVAL_LABEL: "Website removal",
      DANGER_WEBSITE_REMOVAL_CONFIRM: "Confirm website removal",
      POPUPS_DELETE_WEBSITE_DOMAIN_LABEL: "Confirm Website Removal",
      POPUPS_DELETE_WEBSITE_DOMAIN_PLACEHOLDER:
        "Type website domain (eg. acme.com)",
      POPUPS_DELETE_WEBSITE_EMPHASIS_1:
        "All website data (conversations, users) will be permanently deleted and non-recoverable.",
      POPUPS_DELETE_WEBSITE_EMPHASIS_2:
        "There is no coming back after you press remove.",
      POPUPS_DELETE_WEBSITE_PASSWORD_LABEL: "Please identify yourself",
      POPUPS_DELETE_WEBSITE_PASSWORD_PLACEHOLDER:
        "Enter your password to delete website…",
      POPUPS_DELETE_WEBSITE_PROCEED: "Remove Website",
      HELP_7:
        "# How does message routing work?\\nMessage routing can be used to automatically assign conversations to specified operators, based on conditions. For instance, you may want to route all chats segmented as \\'technical\\' to your technical agents. Routing is useful for large teams receiving a lot of chats. With routing, only the assigned operator gets notified.",
      CHATBOX_SECURITY_IGNORE_PRIVACY:
        "Ignore user privacy choices (MagicType, MagicBrowse)",
      POPUPS_CONFIRM_IGNORE_PRIVACY_LABEL:
        "Are you sure you want to ignore user privacy choices?",
      POPUPS_CONFIRM_IGNORE_PRIVACY_EMPHASIS_1:
        "In doing this, you agree to adjust your own terms of use to reflect your choice. This will let MagicType and MagicBrowse work for users with Do Not Track enabled.",
      POPUPS_CONFIRM_IGNORE_PRIVACY_EMPHASIS_2:
        "Crisp cannot be held responsible for any user complaint, or any privacy infrigement.",
      POPUPS_CONFIRM_IGNORE_PRIVACY_PROCEED: "Let\\'s Be Sneaky",
      POPUPS_CONFIRM_IGNORE_PRIVACY_CANCEL: "I Respect My Users",
      POPUPS_CONFIRM_TOGGLE_ROLE_QUESTION:
        "Are you sure you want to toggle this operator\\'s role?",
      POPUPS_CONFIRM_TOGGLE_ROLE_LABEL:
        "This operator will gain / loose access to certain features.",
      POPUPS_CONFIRM_TOGGLE_ROLE_PROCEED: "Toggle Role",
      POPUPS_CONFIRM_TOGGLE_ROLE_CANCEL: "Cancel",
      POPUPS_CLEAR_VISITORS_BLOCKED_LABEL:
        "Clear all blocked visitors? (IP & Email)",
      POPUPS_CLEAR_VISITORS_BLOCKED_EMPHASIS_1:
        "This will reset your block rules (ie. when you press a block user button in chat).",
      POPUPS_CLEAR_VISITORS_BLOCKED_EMPHASIS_2: "Number of blocked visitors:",
      POPUPS_CLEAR_VISITORS_BLOCKED_PROCEED: "Clear Blocked Visitors",
      CONTACT_WEBSITE_MESSENGER_LABEL: "Website Messenger",
      CONTACT_WEBSITE_MESSENGER_PLACEHOLDER: "Messenger username",
      CONTACT_WEBSITE_TELEGRAM_LABEL: "Website Telegram",
      CONTACT_WEBSITE_TELEGRAM_PLACEHOLDER: "Telegram username",
      CONTACT_WEBSITE_TWITTER_LABEL: "Website Twitter",
      CONTACT_WEBSITE_TWITTER_PLACEHOLDER: "Twitter username",
      CHATBOX_BEHAVIOR_WAIT_GAME:
        "Suggest to play a wait game when operators are slow to reply",
      CHATBOX_BEHAVIOR_PHONE_VISITORS: "Ask visitors for their phone number",
      CHATBOX_BEHAVIOR_FORCE_IDENTIFY:
        "Force visitors to identify themselves (email or phone)",
      CHATBOX_BEHAVIOR_FILE_TRANSFER:
        "Allow files to be uploaded from the chatbox",
      CHATBOX_BEHAVIOR_HELPDESK_LINK:
        "Show a link to helpdesk in the chatbox (if Crisp Helpdesk is configured)",
      CHATBOX_BEHAVIOR_STATUS_HEALTH_DEAD:
        "Show an alert when status reports dead (if Crisp Status is configured)",
      CUSTOM_EMAIL_DOMAIN: "Custom Email Domain",
      CUSTOM_EMAIL_DOMAIN_BASIC_DOMAIN_LABEL: "Basic domain",
      CUSTOM_EMAIL_DOMAIN_BASIC_DOMAIN_PLACEHOLDER: "acme",
      CUSTOM_EMAIL_DOMAIN_CUSTOM_DOMAIN_LABEL: "Custom domain",
      CUSTOM_EMAIL_DOMAIN_CUSTOM_DOMAIN_PLACEHOLDER: "emails.acme.com",
      CUSTOM_EMAIL_SMTP: "Custom Email SMTP",
      CUSTOM_EMAIL_SMTP_BADGE_ACTIVE: "active",
      CUSTOM_EMAIL_SMTP_BADGE_FAILED: "failed",
      CUSTOM_EMAIL_SMTP_BADGE_INACTIVE: "inactive",
      CUSTOM_EMAIL_SMTP_ENABLED:
        "Send all emails using my custom outbound SMTP server",
      CUSTOM_EMAIL_SMTP_DOMAIN_LABEL: "Email domain",
      CUSTOM_EMAIL_SMTP_DOMAIN_PLACEHOLDER: "emails.acme.com",
      CUSTOM_EMAIL_SMTP_USERNAME_LABEL: "SMTP username",
      CUSTOM_EMAIL_SMTP_USERNAME_PLACEHOLDER: "Username (if any)",
      CUSTOM_EMAIL_SMTP_PASSWORD_LABEL: "SMTP password",
      CUSTOM_EMAIL_SMTP_PASSWORD_PLACEHOLDER: "Password (if any)",
      CUSTOM_EMAIL_SMTP_SERVER_LABEL: "SMTP server",
      CUSTOM_EMAIL_SMTP_SERVER_PLACEHOLDER: "smtp.acme.com",
      CUSTOM_EMAIL_SMTP_CONTROLS_STATUS_PENDING:
        "Custom SMTP not yet configured. Verify to continue.",
      CUSTOM_EMAIL_SMTP_CONTROLS_STATUS_LIVE:
        "This custom SMTP is currently in use.",
      CUSTOM_EMAIL_SMTP_CONTROLS_ACTION_VERIFY: "Verify SMTP credentials",
      CUSTOM_EMAIL_SIGNATURE: "Custom Email Signature",
      CUSTOM_EMAIL_SIGNATURE_BADGE_ENABLED: "enabled",
      CUSTOM_EMAIL_SIGNATURE_BADGE_DISABLED: "disabled",
      CUSTOM_EMAIL_SIGNATURE_ENABLED:
        "Append a text signature at the bottom of all sent emails",
      CUSTOM_EMAIL_SIGNATURE_FIELD_LABEL: "Email Signature",
      CUSTOM_EMAIL_SIGNATURE_FIELD_PLACEHOLDER:
        "Enter a custom email signature…",
      CUSTOM_EMAIL_SIGNATURE_TAG_OPERATOR_TITLE_LABEL:
        "Sending operator job title",
      CUSTOM_EMAIL_SIGNATURE_TAG_OPERATOR_NAME_FULL:
        "Sending operator full name",
      CUSTOM_EMAIL_SIGNATURE_TAG_OPERATOR_NAME_FIRST:
        "Sending operator first name",
      CUSTOM_EMAIL_SIGNATURE_TAG_OPERATOR_NAME_LAST:
        "Sending operator last name",
      CUSTOM_EMAIL_SIGNATURE_TAG_OPERATOR_EMAIL:
        "Sending operator email address",
      OUTBOUND_EMAIL_IPS: "Outbound Email IPs",
      OUTBOUND_EMAIL_IPS_NOTICE_TITLE: "What are outbound email IPs?",
      OUTBOUND_EMAIL_IPS_NOTICE_LINE_1:
        "When emails are sent to your users for your Crisp messages and campaigns, they are sent using an IP address that has a reputation attached to it. The likelihood that your emails go to spam is based on the IP reputation.",
      OUTBOUND_EMAIL_IPS_NOTICE_LINE_2:
        "You can either use a default shared IP pool, or a dedicated IP address to improve email deliverability.",
      OUTBOUND_EMAIL_IPS_REPUTATION_TITLE: "Current email IP reputation",
      OUTBOUND_EMAIL_IPS_REPUTATION_TEXT_TITLE_BAD:
        "Beware! Most emails you send may go to spam.",
      OUTBOUND_EMAIL_IPS_REPUTATION_TEXT_TITLE_OKAY:
        "Your current email delivery reputation could be better.",
      OUTBOUND_EMAIL_IPS_REPUTATION_TEXT_TITLE_GOOD:
        "Your current email delivery reputation looks good.",
      OUTBOUND_EMAIL_IPS_REPUTATION_DESCRIPTION_1: "We got",
      OUTBOUND_EMAIL_IPS_REPUTATION_DESCRIPTION_2: "unsubscribes",
      OUTBOUND_EMAIL_IPS_REPUTATION_DESCRIPTION_3: "over the",
      OUTBOUND_EMAIL_IPS_REPUTATION_DESCRIPTION_4: "emails",
      OUTBOUND_EMAIL_IPS_REPUTATION_DESCRIPTION_5: "you sent from",
      OUTBOUND_EMAIL_IPS_REPUTATION_DESCRIPTION_6: "to",
      OUTBOUND_EMAIL_IPS_REPUTATION_STATUS_TITLE:
        "Your outbound email IP status",
      OUTBOUND_EMAIL_IPS_REPUTATION_STATUS_ACTIVE_SUBSCRIBED_LABEL:
        "You are subscribed to a dedicated email IP",
      OUTBOUND_EMAIL_IPS_REPUTATION_STATUS_ACTIVE_SUBSCRIBED_CANCEL:
        "Cancel dedicated email IPs subscription",
      OUTBOUND_EMAIL_IPS_REPUTATION_STATUS_ACTIVE_NOT_SUBSCRIBED_LABEL:
        "You are using the shared email IP pool",
      OUTBOUND_EMAIL_IPS_REPUTATION_STATUS_ACTIVE_NOT_SUBSCRIBED_UPGRADE:
        "Upgrade to the Dedicated Email IP plugin",
      OUTBOUND_EMAIL_IPS_REPUTATION_STATUS_ACTIVE_NOT_SUBSCRIBED_ASSIGN:
        "Request a dedicated IP assignment (plugin is installed)",
      OUTBOUND_EMAIL_IPS_REPUTATION_STATUS_POOL_TYPE_HIGH: "High",
      OUTBOUND_EMAIL_IPS_REPUTATION_STATUS_POOL_TYPE_MEDIUM: "Medium",
      OUTBOUND_EMAIL_IPS_REPUTATION_STATUS_POOL_TYPE_LOW: "Low",
      OUTBOUND_EMAIL_IPS_REPUTATION_STATUS_POOL_VALUE_HIGH:
        "Using high-reputation shared pool",
      OUTBOUND_EMAIL_IPS_REPUTATION_STATUS_POOL_VALUE_MEDIUM:
        "Using medium-reputation shared pool",
      OUTBOUND_EMAIL_IPS_REPUTATION_STATUS_POOL_VALUE_LOW:
        "Using low-reputation shared pool",
      OUTBOUND_EMAIL_IPS_REPUTATION_STATUS_POOL_VALUE_WHY: "Why?",
      OUTBOUND_EMAIL_IPS_REPUTATION_LOGS_TITLE:
        "Latest email delivery errors (SMTP logs)",
      OUTBOUND_EMAIL_IPS_REPUTATION_LOGS_EMPTY:
        "Lucky you! You don\\'t have any recent SMTP errors.",
      WEB_HOOKS: "Web Hooks",
      WEB_HOOKS_BADGE_HOOK: "hook",
      WEB_HOOKS_BADGE_HOOKS: "hooks",
      WEB_HOOKS_BADGE_FAILED: "failed",
      WEB_HOOKS_ADD_LABEL: "Listen to incoming website events",
      WEB_HOOKS_ADD_ACTION: "Add a Web Hook",
      IDENTITY_VERIFICATION: "Identity Verification",
      IDENTITY_VERIFICATION_BADGE_ENABLED: "enabled",
      IDENTITY_VERIFICATION_BADGE_DISABLED: "disabled",
      IDENTITY_VERIFICATION_ENABLED_LABEL:
        "Verify user emails with cryptographic signatures",
      IDENTITY_VERIFICATION_SECRET_LABEL: "Secret key",
      IDENTITY_VERIFICATION_SECRET_PLACEHOLDER: "Generating secret key…",
      IDENTITY_VERIFICATION_SECRET_ROLL: "Roll secret",
      CONTRACT_AGREEMENTS: "Contract Agreements",
      CONTRACT_AGREEMENTS_BUTTON_CANCEL: "Cancel",
      CONTRACT_AGREEMENTS_BUTTON_DOWNLOAD_AGREEMENT: "See agreement",
      CONTRACT_AGREEMENTS_BUTTON_UPLOAD: "Upload signed",
      CONTRACT_AGREEMENTS_BUTTON_DOWNLOAD_CONTRACT: "See contract",
      USAGE: "Limits & Quotas Usage",
      USAGE_PROGRESS_INFINITE: "(no limit)",
      USAGE_PROGRESS_TIME: "Every {time}",
      USAGE_PROGRESS_DATA: "(in total)",
      USAGE_PROGRESS_INCREASE: "Increase",
      POPUPS_EMAIL_IPS_ASSIGN_QUESTION:
        "Assign this website a dedicated email IP?",
      POPUPS_EMAIL_IPS_ASSIGN_LABEL:
        "Your emails will be delivered using an IP address allocated for your website only. You will not share a pooled IP with other websites anymore, which helps get a perfect email deliverability.",
      POPUPS_EMAIL_IPS_ASSIGN_PROCEED: "Assign Dedicated Email IP",
      POPUPS_EMAIL_IPS_UPGRADE_QUESTION:
        "Upgrade to the Dedicated Email IP plugin?",
      POPUPS_EMAIL_IPS_UPGRADE_LABEL:
        "You will be redirected to install the plugin. Once installed, come back here and activate your dedicated IPs in a click.",
      POPUPS_EMAIL_IPS_UPGRADE_PROCEED: "Upgrade To Plugin",
      POPUPS_EMAIL_IPS_DOWNGRADE_QUESTION:
        "Unassign my private email IP addresses?",
      POPUPS_EMAIL_IPS_DOWNGRADE_LABEL:
        "You will be downgraded to pooled IP addresses. The Dedicated Email IP plugin will be uninstalled (you will stop paying for it).",
      POPUPS_EMAIL_IPS_DOWNGRADE_PROCEED: "Unassign IPs & Uninstall Plugin",
      POPUPS_ADD_HOOK_LABEL_LABEL: "Name For Hook",
      POPUPS_ADD_HOOK_LABEL_PLACEHOLDER:
        "Enter a name for your hook (eg. Sent messages)",
      POPUPS_ADD_HOOK_TARGET_LABEL: "URL To Be Called",
      POPUPS_ADD_HOOK_TARGET_PLACEHOLDER:
        "Enter the target hook URL (eg. https://incoming.acme.com/crisp/messages)",
      POPUPS_ADD_HOOK_EVENTS_LABEL: "Filter Events",
      POPUPS_ADD_HOOK_PROCEED: "Add Hook Target",
      POPUPS_VERIFY_KEY_ROLL_QUESTION: "Roll secret key?",
      POPUPS_VERIFY_KEY_ROLL_LABEL:
        "Please confirm you want to roll your secret key.",
      POPUPS_VERIFY_KEY_ROLL_EMPHASIS:
        "Beware! Your previous secret key will be invalidated. You will need to update the key in your backend with the new one.",
      POPUPS_VERIFY_KEY_ROLL_PROCEED: "Roll Secret Key",
      SUBTITLE_8: "Advanced configuration",
      DESCRIPTION_8:
        "Configure Web Hooks, customize your email domain, and more.",
      HELP_8:
        "# How do custom email domains work?\\nBy default, all emails to your users are sent and received via Crisp domain name. Your users can see it in the email addresses used.\\n\\nIn the event you\\'d like to use your own domain name, you can follow the custom email domain setup guide, and get emails sent and received via your own private domain.\\n\\n**Documentation on Custom Email Domain is available [here](https://help.crisp.chat/en/article/ndklh7/).**\\n-----\\n# What are Web Hooks useful for?\\nWeb Hooks are used to send real-time events from your website on Crisp to an HTTP endpoint. You can specify an HTTP URL that will receive a list of allowed real-time events.\\n\\nWeb Hooks are useful when you need an internal system of yours to be notified of events on your Crisp website.\\n\\n**Documentation on Web Hooks is available [here](https://docs.crisp.chat/guides/web-hooks/quickstart/).**\\n-----\\n# How to configure identity verification?\\nIdentity verification is used to show user emails either as verified or unverified in conversations. This lets you recognize spoofing and validate account ownership easily. Configuring it requires you to add some code to your backend.\\n\\n**Documentation on Identity Verification is available [here](https://docs.crisp.chat/guides/chatbox-sdks/web-sdk/identity-verification/).**",
      CHATBOX_BEHAVIOR_OPERATOR_PRIVACY:
        "Operator privacy mode (eg. disable read markers in chatbox)",
      CHATBOX_BEHAVIOR_VISITOR_COMPOSE:
        "Operators can see what visitors write in real-time (MagicType)",
      ROUTING_GENERAL: "General Options",
      ROUTING_GENERAL_RESPONDER: "Assign operator who responded upon response",
      ROUTING_GENERAL_ASSIGN:
        "Auto-assign conversations according to configured rules",
      ROUTING_RULES: "Routing Rules",
      ROUTING_RULES_COUNT: "rules",
      ROUTING_RULES_ADD_LABEL: "Assign an agent for conversations",
      ROUTING_RULES_ADD_ACTION: "Add a routing rule",
      SUBTITLE_9: "Limits Usage & Legal",
      DESCRIPTION_9:
        "Review your data usage, manage your legal compliance settings.",
      HELP_9:
        "# Do I need to sign all contracts?\\nThe contracts available in this section can be signed if you need them for your business (eg. for legal compliance purposes). You are not required to sign them to use Crisp.\\n-----\\n# How do I increase my limits?\\nLimits are either time-based (we call those \\'quotas\\'), or storage-based. **Default limits are set based on your Crisp plan**, and should be enough in most cases. **Large teams might need higher limits**.\\n\\nIf you hit one of the quotas or limits, you can upgrade your subscription to higher limits from there. **Simply click on \\'Increase\\'**, and follow the steps. You can change those limits anytime, eg. reduce them.",
      SUBTITLE_10: "Danger Zone™",
      DESCRIPTION_10:
        "Perform dangerous operations on this website. This section is only available to owners.",
      HELP_10:
        "# If I remove this website, will I lose all message and user data?\\nYes, upon website removal, we also **remove permanently all messages and user data**.\\n-----\\n# Can I recover a deleted website?\\nWe do have backups over a short timespan of 1 month. However, **we do not recover website data on-demand**.\\nMake sure it is your last word before you confirm the removal of a website.\\n-----\\n# Are member users allowed to remove this website?\\nNo, **only owners** are allowed to do so, and therefore members do not even see this section.",
      ROUTING_GENERAL_REPROCESS_RULES:
        "Re-assign conversations a day after last operator message",
      ROUTING_GENERAL_REPROCESS_OFFLINE:
        "Re-assign conversations if assigned operator is offline",
      CHATBOX_SECURITY_IGNORE_PRIVACY_ENABLE: "Privacy choices honored",
      CHATBOX_SECURITY_IGNORE_PRIVACY_DISABLE: "Privacy choices ignored",
    },
    SHORTCUTS: {
      TITLE: "Message Shortcuts",
      SUBTITLE_1: "Add a new shortcut",
      DESCRIPTION_1:
        "Create a new shortcut. Give it a !bang, a group tag, and write a message for this shortcut. Then, use it in conversations to quickly answer to visitors using the shortcut !bang.",
      SHORTCUT_LABEL: "Shortcut",
      SHORTCUT_PLACEHOLDER: "!bang",
      IN_TAG: "In tag",
      ADD_TAG: "Add a tag…",
      MESSAGE_LABEL: "Message",
      MESSAGE_PLACEHOLDER: "Enter a message for this shortcut !bang…",
      MESSAGE_TAG_EMAIL_LABEL: "Visitor email",
      MESSAGE_TAG_NAME_FULL_LABEL: "Visitor full name",
      MESSAGE_TAG_NAME_FIRST_LABEL: "Visitor first name",
      MESSAGE_TAG_NAME_LAST_LABEL: "Visitor last name",
      MESSAGE_TAG_COUNTRY_LABEL: "Visitor country",
      MESSAGE_TAG_CITY_LABEL: "Visitor city",
      MESSAGE_TAG_WEBSITE_LABEL: "Visitor website URL",
      MESSAGE_TAG_COMPANY_NAME_LABEL: "Visitor company name",
      MESSAGE_TAG_DATA_LABEL: "Conversation data (on sub-key to configure)",
      ADD_SHORTCUT: "Add Shortcut",
      HELP_1:
        "# What is a shortcut !bang?\\nThe shortcut !bang is what you enter in a conversation to quickly find a saved shortcut message and **expand the !bang to a full message**.\\n-----\\n# What is a shortcut tag?\\nShortcut **tags are categories** in which you want to classify your shortcuts.\\nFor instance, you may tag !hello and !hi as Greetings; !product and !new as Product. Make it yours!\\n-----\\n# Can I use macros to replace text?\\nYes! Macros can be used **to insert dynamic text in your shortcuts**.\\nFor instance, the shortcut \\'Hello, {\\'{{\\'} name.first {\\'}}\\'}.\\' can be set to be expanded to anything like \\'Hello, John.\\'.",
      SUBTITLE_2: "Manage all shortcuts",
      DESCRIPTION_2:
        "Manage your shortcuts. They are grouped by tag to help you organize them.",
      BADGE_SHORTCUTS: "shortcuts",
      POPUPS_NEW_TAG_NAME_LABEL: "Value for the tag",
      POPUPS_NEW_TAG_NAME_PLACEHOLDER: "Enter the value for the tag…",
      POPUPS_NEW_TAG_PROCEED: "Use This Tag",
      POPUPS_EDIT_SHORTCUT_BANG_LABEL: "Shortcut !bang",
      POPUPS_EDIT_SHORTCUT_BANG_PLACEHOLDER: "Enter a !bang…",
      POPUPS_EDIT_SHORTCUT_TAG_LABEL: "Shortcut tag",
      POPUPS_EDIT_SHORTCUT_TEXT_LABEL: "Shortcut message",
      POPUPS_EDIT_SHORTCUT_TEXT_PLACEHOLDER: "Enter a message…",
      POPUPS_EDIT_SHORTCUT_PROCEED: "Save Shortcut",
      POPUPS_REMOVE_SHORTCUT_TITLE: "The selected shortcut will be removed",
      POPUPS_REMOVE_SHORTCUT_NOTICE:
        "This shortcut will be permanently deleted.",
      POPUPS_REMOVE_SHORTCUT_PROCEED: "Remove Shortcut",
      HELP_2:
        "# How do I use a shortcut?\\nShortcuts can be simply used in a conversation by **typing a shortcut !bang in the message input**.\\nOnce you start typing the !bang, shortcut suggestions will appear to quickly find your !bang.\\n-----\\n# Is there a limit on the quantity of shortcuts?\\nNo, there is absolutely no limit. You can create and use an **unlimited amount** of shortcuts.",
    },
    HELPDESK: {
      TITLE: "Helpdesk",
      ALERT_NOT_OWNER:
        "You are not an owner of the helpdesk website. You will not be able to change settings.",
      ALERT_NOT_SUBSCRIBED:
        "You haven\\'t subscribed to the Helpdesk plugin. Upgrade your plan to use it.",
      ALERT_NOT_INITIALIZED:
        "The helpdesk needs to be initialized before you can use it.",
      ALERT_NOT_INITIALIZED_GO_INITIALIZE: "Initialize my helpdesk",
      ALERT_DOMAIN_INVALID:
        "The last check of your helpdesk domain failed. Ensure you did not change domain DNS records. Next check will be done tomorrow. Once re-validated, this will disappear.",
      ALERT_INDEXING_FORBIDDEN:
        "Beware. Indexing of your helpdesk by search engines is configured as forbidden.",
      ALERT_PASSWORD_DEFINED:
        "Beware. Access to your helpdesk is configured to be password-protected.",
      POPUPS_INITIALIZE_QUESTION: "Initialize your helpdesk",
      POPUPS_INITIALIZE_LABEL: "Name your helpdesk and give it a sub-domain:",
      POPUPS_INITIALIZE_NAME_LABEL: "Helpdesk name",
      POPUPS_INITIALIZE_NAME_PLACEHOLDER: "Acme Helpdesk",
      POPUPS_INITIALIZE_DOMAIN_BASIC_LABEL: "Helpdesk sub-domain",
      POPUPS_INITIALIZE_DOMAIN_BASIC_PLACEHOLDER: "acme",
      POPUPS_INITIALIZE_DOMAIN_BASIC_ENTICE:
        "(You will be able to configure a domain name such as help.mycompany.com later)",
      POPUPS_INITIALIZE_PROCEED: "Initialize Helpdesk",
      SUBTITLE_1: "Setup your Help Desk",
      DESCRIPTION_1: "Get your Help Desk domain or configure a custom domain.",
      SETTINGS_NAME_LABEL: "Help Desk name",
      SETTINGS_NAME_PLACEHOLDER: "Acme Helpdesk",
      SETTINGS_INSTRUCTIONS: "Image, max. 5MB",
      SETTINGS_APPEARANCE_LOGOS_HEADER_LABEL: "Header logo",
      SETTINGS_APPEARANCE_LOGOS_FOOTER_LABEL: "Footer logo",
      SETTINGS_APPEARANCE_BANNER_LABEL: "Banner",
      SETTINGS_BEHAVIOR_FREQUENTLY_READ_LABEL:
        "Show a list of most frequently read articles on homepage",
      SETTINGS_BEHAVIOR_SHOW_CHATBOX_LABEL:
        "Display the Crisp chatbox on the Help Desk",
      SETTINGS_BEHAVIOR_ASK_FEEDBACK_LABEL:
        "Ask users for feedback at the end of the articles",
      SETTINGS_BEHAVIOR_LOCALE_PICKER_LABEL:
        "Show a locale selector in the header of the Help Desk",
      SETTINGS_BEHAVIOR_REFER_LINK_LABEL:
        "Show a link to Crisp in the footer of the Help Desk",
      SETTINGS_BEHAVIOR_FORBID_INDEXING_LABEL:
        "Forbid search engine indexing of all pages",
      SETTINGS_BEHAVIOR_STATUS_HEALTH_DEAD_LABEL:
        "Show an alert when status reports dead (if Crisp Status is configured)",
      DOMAIN_BASIC_LABEL: "Basic domain",
      DOMAIN_BASIC_PLACEHOLDER: "acme",
      DOMAIN_CUSTOM_LABEL: "Custom domain",
      DOMAIN_CUSTOM_PLACEHOLDER: "help.acme.com",
      HELP_1:
        "# How do the basic domain work?\\nBy default, Crisp provides an hosted domain for your helpdesk. Any helpdesk needs to have a basic domain configured. You need to pick a subdomain of crisp.help, that Crisp provides.\\n\\nFor instance, given a company _Acme, Inc._, an helpdesk sub-domain that can be chosen is _acme_. The helpdesk will then be available at acme.crisp.help.\\n-----\\n# How do the custom domain work?\\nIn case you need your helpdesk to be available from your own domain name, eg. help.acme.com instead of acme.crisp.help, you can configure a custom domain. Once a custom domain is configured, all requests to the basic helpdesk domain Crisp provides get redirected to your new custom domain.\\n\\n**To setup your custom helpdesk domain, fill your domain and then follow the setup guide.** Please note that your helpdesk will be served over secure HTTPS. Crisp manages your helpdesk SSL certificate for you.",
      SUBTITLE_2: "Customize your Help Desk",
      DESCRIPTION_2: "Change how your Help Desk looks and behaves.",
      HELP_2:
        "# Where can I see the logos?\\nThe logos can be seen in the header (white logo), and in the footer (dark logo). It\\'s recommended to upload your company logo to replace the default Crisp logos.\\n-----\\n# Where can I see the banner?\\nThe banner is the large image that\\'s visible in the helpdesk header. By default, the color you picked for your chatbox, as well as the pattern, are used. However, you may want to upload a custom image (eg. a picture).",
      SETTINGS_BEHAVIOR_SHOW_CATEGORY_IMAGES_LABEL:
        "Show category images on homepage",
      POPUPS_SETTINGS_INCLUDE_HTML_NOTICE_TITLE: "How can you use HTML code?",
      POPUPS_SETTINGS_INCLUDE_HTML_NOTICE_TEXT:
        "HTML code can be used to alter your Helpdesk style with custom CSS, or inject scripts on the pages, eg. Google Analytics.",
      POPUPS_SETTINGS_INCLUDE_HTML_INVALID_TITLE:
        "Oops! It seems that it\\'s not a valid HTML code.",
      POPUPS_SETTINGS_INCLUDE_HTML_INVALID_TEXT:
        " Did you miss <style></style> or <script></script> tags?",
      POPUPS_SETTINGS_INCLUDE_HTML_VALUE_LABEL: "HTML code to include",
      POPUPS_SETTINGS_INCLUDE_HTML_VALUE_PLACEHOLDER:
        "Enter HTML code to include in helpdesk…",
      POPUPS_SETTINGS_INCLUDE_HTML_PROCEED: "Save HTML Code",
      POPUPS_SETTINGS_ACCESS_PASSWORD_ENABLE_PASSWORD_LABEL:
        "Password to access Helpdesk (make it private)",
      POPUPS_SETTINGS_ACCESS_PASSWORD_ENABLE_PASSWORD_PLACEHOLDER:
        "Enter a password…",
      POPUPS_SETTINGS_ACCESS_PASSWORD_ENABLE_PROCEED:
        "Enable Helpdesk Password",
      POPUPS_SETTINGS_ACCESS_PASSWORD_DISABLE_QUESTION:
        "Disable the Helpdesk password?",
      POPUPS_SETTINGS_ACCESS_PASSWORD_DISABLE_NOTICE:
        "Your Helpdesk pages will be publicly visible to everyone.",
      POPUPS_SETTINGS_ACCESS_PASSWORD_DISABLE_PROCEED:
        "Disable Helpdesk Password",
      SETTINGS_INCLUDE_HTML_LABEL: "Include custom HTML code",
      SETTINGS_INCLUDE_HTML_ACTION: "Edit included HTML code",
      SETTINGS_ACCESS_PASSWORD_LABEL: "Restrict access to Helpdesk",
      SETTINGS_ACCESS_PASSWORD_ACTION_CONFIGURE:
        "Configure the Helpdesk password",
      SETTINGS_ACCESS_PASSWORD_ACTION_DISABLE: "Disable the Helpdesk password",
    },
    STATUS: {
      TITLE: "Status Page",
      ALERT_NOT_OWNER:
        "You are not an owner of the status page website. You will not be able to change settings.",
      ALERT_NOT_SUBSCRIBED:
        "You haven\\'t subscribed to the Status Page plugin. Upgrade your plan to use it.",
      ALERT_NOT_INITIALIZED:
        "The status page needs to be initialized before you can use it.",
      ALERT_NOT_INITIALIZED_GO_INITIALIZE: "Initialize my status page",
      ALERT_DOMAIN_INVALID:
        "The last check of your status page domain failed. Ensure you did not change domain DNS records. Next check will be done tomorrow. Once re-validated, this will disappear.",
      ALERT_NO_NOTIFY:
        "No notification channel is configured. Configure at least one to be notified when your status page reports as dead.",
      ALERT_HAS_ANNOUNCEMENT:
        "An announcement is active on your Status Page. Make sure to remove it once it is over.",
      POPUPS_INITIALIZE_QUESTION: "Initialize your status page",
      POPUPS_INITIALIZE_LABEL:
        "Name your status page and give it a sub-domain:",
      POPUPS_INITIALIZE_NAME_LABEL: "Status Page name",
      POPUPS_INITIALIZE_NAME_PLACEHOLDER: "Acme Status",
      POPUPS_INITIALIZE_DOMAIN_BASIC_LABEL: "Status Page sub-domain",
      POPUPS_INITIALIZE_DOMAIN_BASIC_PLACEHOLDER: "acme",
      POPUPS_INITIALIZE_PROCEED: "Initialize Status Page",
      SUBTITLE_1: "Setup your Status Page",
      DESCRIPTION_1:
        "Get your Status Page domain or configure a custom domain.",
      SETTINGS_NAME_LABEL: "Status Page name",
      SETTINGS_NAME_PLACEHOLDER: "Acme Status",
      SETTINGS_INSTRUCTIONS: "Image, max. 5MB",
      SETTINGS_APPEARANCE_LOGOS_HEADER_LABEL: "Header logo",
      SETTINGS_APPEARANCE_LOGOS_FOOTER_LABEL: "Footer logo",
      SETTINGS_APPEARANCE_BANNER_LABEL: "Banner",
      SETTINGS_BEHAVIOR_SHOW_CHATBOX_LABEL:
        "Display the Crisp chatbox on the Status Page",
      SETTINGS_BEHAVIOR_LOCALE_PICKER_LABEL:
        "Show a locale selector in the header of the Status Page",
      SETTINGS_BEHAVIOR_REFER_LINK_LABEL:
        "Show a link to Crisp in the footer of the Status Page",
      SETTINGS_BEHAVIOR_FORBID_INDEXING_LABEL:
        "Forbid search engine indexing of pages",
      SETTINGS_METRICS_POLL_RETRY_LABEL:
        "Poll: number of times to retry a failed check",
      SETTINGS_METRICS_POLL_DELAY_DEAD_LABEL:
        "Poll: seconds after which node is dead",
      SETTINGS_METRICS_POLL_DELAY_SICK_LABEL:
        "Poll: seconds after which node is sick",
      SETTINGS_METRICS_PUSH_DELAY_DEAD_LABEL:
        "Push: seconds after which node is dead",
      SETTINGS_METRICS_PUSH_SYSTEM_CPU_SICK_ABOVE_LABEL:
        "Push: load ratio above which node CPU is sick",
      SETTINGS_METRICS_PUSH_SYSTEM_RAM_SICK_ABOVE_LABEL:
        "Push: load ratio above which node RAM is sick",
      SETTINGS_METRICS_LOCAL_RETRY_LABEL:
        "Local: number of times to retry a failed check",
      SETTINGS_METRICS_LOCAL_DELAY_DEAD_LABEL:
        "Local: seconds after which node is dead",
      SETTINGS_METRICS_LOCAL_DELAY_SICK_LABEL:
        "Local: seconds after which node is sick",
      DOMAIN_BASIC_LABEL: "Basic domain",
      DOMAIN_BASIC_PLACEHOLDER: "acme",
      DOMAIN_CUSTOM_LABEL: "Custom domain",
      DOMAIN_CUSTOM_PLACEHOLDER: "status.acme.com",
      HELP_1:
        "# How do the basic domain work?\\nBy default, Crisp provides an hosted domain for your status page. Any status page needs to have a basic domain configured. You need to pick a subdomain of crisp.watch, that Crisp provides.\\n\\nFor instance, given a company _Acme, Inc._, an status page sub-domain that can be chosen is _acme_. The status page will then be available at acme.crisp.watch.\\n-----\\n# How do the custom domain work?\\nIn case you need your status page to be available from your own domain name, eg. status.acme.com instead of acme.crisp.watch, you can configure a custom domain. Once a custom domain is configured, all requests to the basic status page domain Crisp provides get redirected to your new custom domain.\\n\\n**To setup your custom status page domain, fill your domain and then follow the setup guide.** Please note that your status page will be served over secure HTTPS. Crisp manages your status page SSL certificate for you.",
      SUBTITLE_2: "Manage monitored services",
      DESCRIPTION_2: "Add and remove services & nodes to be monitored.",
      SERVICES_OPEN_NODES: "Open nodes",
      SERVICES_HIDE_NODES: "Hide nodes",
      SERVICES_BADGE_NODES: "nodes",
      SERVICES_ACTION_EDIT: "Edit",
      SERVICES_ACTION_DELETE: "Delete service",
      SERVICES_ADD_LABEL: "Group nodes in a service",
      SERVICES_ADD_ACTION: "Add a new Service",
      NODES_BADGE_MODE_PUSH: "Push node",
      NODES_BADGE_MODE_POLL: "Poll node",
      NODES_BADGE_MODE_LOCAL: "Local node",
      NODES_ACTION_SHOW_DETAILS: "Show details",
      NODES_ACTION_PURGE_REPLICAS: "Purge replicas",
      NODES_ADD_LABEL: "Monitor a node",
      NODES_ADD_ACTION: "Add a new Node",
      HELP_2:
        "# What is a service?\\nA service is a group of nodes. Think of it as a category.\\n-----\\n# What is a node?\\nA node can be a website, an application.\\n-----\\n# What is a replica?\\nA replica is a shard of a node, in case you have a load-balanced node. Most often, you\\'ll have a single replica.",
      SUBTITLE_3: "Manage notifications channels",
      DESCRIPTION_3:
        "Configure the way you want to be notified (Email, Slack, Push).",
      NOTIFY_ACTIVE: "Active",
      NOTIFY_EMAIL_TITLE: "Email (to address)",
      NOTIFY_EMAIL_CONTROL: "Send downtime alerts to my email address",
      NOTIFY_EMAIL_TO_LABEL: "Email to",
      NOTIFY_EMAIL_TO_PLACEHOLDER: "Enter email address…",
      NOTIFY_SLACK_CONTROL: "Send downtime alerts to my Slack channel",
      NOTIFY_SLACK_HOOK_URL_LABEL: "Slack Hook URL",
      NOTIFY_SLACK_HOOK_URL_PLACEHOLDER: "Enter Slack hook URL…",
      NOTIFY_SLACK_MENTION_CHANNEL_LABEL: "Mention channel",
      NOTIFY_SLACK_TITLE: "Slack (to channel)",
      NOTIFY_APP_TITLE: "Push (to operators)",
      NOTIFY_APP_CONTROL: "Send downtime alerts to my Crisp apps (push)",
      NOTIFY_APP_PICKER_LABEL: "Notified operators",
      NOTIFY_APP_PICKER_ADD: "Edit operators",
      NOTIFY_PUSHOVER_TITLE: "Pushover (to application)",
      NOTIFY_PUSHOVER_CONTROL:
        "Send downtime alerts to my Pushover application",
      NOTIFY_PUSHOVER_TOKEN_LABEL: "Pushover API token",
      NOTIFY_PUSHOVER_TOKEN_PLACEHOLDER: "Enter Pushover API token…",
      NOTIFY_PUSHOVER_USERS_LABEL: "Pushover users keys",
      NOTIFY_PUSHOVER_USERS_PLACEHOLDER:
        "Enter Pushover users keys… (comma-separated if multiple)",
      HELP_3:
        "# When do I receive notifications?\\nYou are notified when your Status Page general status change from Healthy to Dead (DOWN), or from Dead to Healthy (UP).",
      SUBTITLE_4: "Customize your Status Page",
      DESCRIPTION_4: "Change how your Status Page looks and behaves.",
      HELP_4:
        "# Where can I see the logos?\\nThe logos can be seen in the header (white logo), and in the footer (dark logo). It\\'s recommended to upload your company logo to replace the default Crisp logos.\\n-----\\n# Where can I see the banner?\\nThe banner is the large image that\\'s visible in the status page header. By default, the color you picked for your chatbox, as well as the pattern, are used. However, you may want to upload a custom image (eg. a picture).",
      SUBTITLE_5: "Configure service announcement",
      DESCRIPTION_5:
        "Configure a new service announcement, or remove active one.",
      ANNOUNCEMENT_LABEL: "Announcement",
      ANNOUNCEMENT_ADD: "Add an announcement",
      ANNOUNCEMENT_DATE_PUBLISHED: "Published on:",
      ANNOUNCEMENT_DATE_EXPIRE: "Expires on:",
      ANNOUNCEMENT_ACTION_REMOVE: "Remove",
      ANNOUNCEMENT_ACTION_EDIT: "Edit text",
      HELP_5:
        "# What are announcements used for?\\nAnnouncements are messages shown to your users on your Status Page. It is useful to warn your users about a maintenance, or downtime mitigation progress.",
      SUBTITLE_6: "Configure the monitoring bot",
      DESCRIPTION_6:
        "Fine-tune how the monitoring bot behaves with your services.",
      HELP_6:
        "# What is a \\'poll\\' monitoring?\\nA \\'poll\\' monitoring sends a request to the configured replicas (HTTP, TCP or ICMP) to check if each report as UP or DOWN.\\n-----\\n# What is a \\'push\\' monitoring?\\nA \\'push\\' monitoring waits for an application using a Crisp Status Reporter library to send its status to.\\n-----\\n# What is a \\'local\\' monitoring?\\nA \\'local\\' monitoring is similar to a \\'poll\\' monitoring, but it uses the Crisp Status Local utility to monitor your LAN endpoints.",
      SUBTITLE_7: "Configure your Status Reporter",
      DESCRIPTION_7: "Retrieve your reporter token, or generate a new one.",
      REPORTER_TOKEN_LABEL: "Secret token",
      REPORTER_TOKEN_PLACEHOLDER: "Generating reporter token…",
      REPORTER_TOKEN_ROLL: "Roll token",
      REPORTER_DESCRIPTION_1: "The token is used in",
      REPORTER_DESCRIPTION_2: "reporter libraries",
      REPORTER_DESCRIPTION_3: "and",
      REPORTER_DESCRIPTION_4: "reporter daemons",
      REPORTER_DESCRIPTION_5: ". It is private to your website.",
      HELP_7:
        "# What is the token used for?\\nThe token is used in Crisp Status Reporter libraries in your applications.",
      POPUPS_SETTINGS_INCLUDE_HTML_NOTICE_TITLE: "How can you use HTML code?",
      POPUPS_SETTINGS_INCLUDE_HTML_NOTICE_TEXT:
        "HTML code can be used to alter your Status Page style with custom CSS, or inject scripts on the status page, eg. Google Analytics.",
      POPUPS_SETTINGS_INCLUDE_HTML_INVALID_TITLE:
        "Oops! It seems that it\\'s not a valid HTML code.",
      POPUPS_SETTINGS_INCLUDE_HTML_INVALID_TEXT:
        "Did you miss <style> or <script> tags?",
      POPUPS_SETTINGS_INCLUDE_HTML_VALUE_LABEL: "HTML code to include",
      POPUPS_SETTINGS_INCLUDE_HTML_VALUE_PLACEHOLDER:
        "Enter HTML code to include in status page…",
      POPUPS_SETTINGS_INCLUDE_HTML_PROCEED: "Save HTML Code",
      POPUPS_NOTIFY_NOTIFY_APP_OPERATOR_LABEL: "Notified operators",
      POPUPS_NOTIFY_NOTIFY_APP_OPERATOR_PROCEED: "Save Operators",
      POPUPS_SERVICES_ADD_SERVICE_NOTICE_TITLE: "What is a service?",
      POPUPS_SERVICES_ADD_SERVICE_NOTICE_TEXT:
        "A service is a group of nodes. For instance, you may create a service \\'Websites\\', with a node for each website you run.",
      POPUPS_SERVICES_ADD_SERVICE_NAME_LABEL: "Service name",
      POPUPS_SERVICES_ADD_SERVICE_NAME_PLACEHOLDER:
        "Service name (eg. \\'Websites\\')",
      POPUPS_SERVICES_ADD_SERVICE_PROCEED: "Add Service",
      POPUPS_SERVICES_EDIT_SERVICE_NAME_LABEL: "Service name",
      POPUPS_SERVICES_EDIT_SERVICE_NAME_PLACEHOLDER:
        "Service name (eg. \\'Websites\\')",
      POPUPS_SERVICES_EDIT_SERVICE_ORDER_LABEL: "Service order index",
      POPUPS_SERVICES_EDIT_SERVICE_ORDER_PLACEHOLDER: "Index (eg. 1, 2, etc.)",
      POPUPS_SERVICES_EDIT_SERVICE_PROCEED: "Save Service",
      POPUPS_SERVICES_REMOVE_SERVICE_QUESTION: "Remove this service?",
      POPUPS_SERVICES_REMOVE_SERVICE_LABEL:
        "The service and all its nodes will be removed. All service child nodes will stop being monitored and will be lost.",
      POPUPS_SERVICES_REMOVE_SERVICE_PROCEED: "Remove Service",
      POPUPS_SERVICES_ADD_SERVICE_NODE_NOTICE_TITLE: "What is a node?",
      POPUPS_SERVICES_ADD_SERVICE_NODE_NOTICE_TEXT:
        "A node can be a website, an HTTP API, a database server, etc.",
      POPUPS_SERVICES_ADD_SERVICE_NODE_NOTICE_POLL_TITLE:
        "How can I monitor this poll node?",
      POPUPS_SERVICES_ADD_SERVICE_NODE_NOTICE_POLL_TEXT:
        "In poll mode, Crisp checks provided URLs (eg. HTTP-check a website). Configure URLs with ICMP, TCP or HTTP URLs.",
      POPUPS_SERVICES_ADD_SERVICE_NODE_NOTICE_PUSH_TITLE:
        "How can I monitor this push node?",
      POPUPS_SERVICES_ADD_SERVICE_NODE_NOTICE_PUSH_TEXT:
        "In push mode, you need to install a Crisp Status Reporter library in your code. Get the library for your platform:",
      POPUPS_SERVICES_ADD_SERVICE_NODE_NOTICE_LOCAL_TITLE:
        "How can I setup this local node?",
      POPUPS_SERVICES_ADD_SERVICE_NODE_NOTICE_LOCAL_TEXT:
        "In local mode, you configure monitored URLs as in poll mode. You need to install the Crisp Status Local daemon on your LAN:",
      POPUPS_SERVICES_ADD_SERVICE_NODE_LABEL_LABEL: "Node label",
      POPUPS_SERVICES_ADD_SERVICE_NODE_LABEL_PLACEHOLDER:
        "Node label (eg. \\'Homepage\\')",
      POPUPS_SERVICES_ADD_SERVICE_NODE_MODE_LABEL: "Node monitoring mode",
      POPUPS_SERVICES_ADD_SERVICE_NODE_PROCEED: "Add Node",
      POPUPS_SERVICES_ADD_SERVICE_NODE_UPGRADE_DESCRIPTION:
        "You have reached your monitored nodes limit, included for free in your Unlimited plan.\\n**You can add more nodes anytime by increasing your limits**.\\n\\nPlease follow our instructions to subscribe to higher limits.",
      POPUPS_SERVICES_ADD_SERVICE_NODE_UPGRADE_PROCEED:
        "Increase Monitoring Limits",
      POPUPS_SERVICES_EDIT_SERVICE_NODE_LABEL_LABEL: "Node label",
      POPUPS_SERVICES_EDIT_SERVICE_NODE_LABEL_PLACEHOLDER:
        "Node label (eg. \\'Homepage\\')",
      POPUPS_SERVICES_EDIT_SERVICE_NODE_ORDER_LABEL: "Node order index",
      POPUPS_SERVICES_EDIT_SERVICE_NODE_ORDER_PLACEHOLDER:
        "Index (eg. 1, 2, etc.)",
      POPUPS_SERVICES_EDIT_SERVICE_NODE_REPLICAS_LABEL:
        "Monitored URLs (if any)",
      POPUPS_SERVICES_EDIT_SERVICE_NODE_REPLICAS_PICKER_PLACEHOLDER:
        "URL (eg. \\'https://acme.com/\\', \\'tcp://acme.com:443\\')",
      POPUPS_SERVICES_EDIT_SERVICE_NODE_REPLICAS_PICKER_ADD:
        "Add a monitored URL (HTTP / TCP / ICMP)",
      POPUPS_SERVICES_EDIT_SERVICE_NODE_HTTP_STATUS_HEALTHY_ABOVE_LABEL:
        "Healthy: HTTP status above",
      POPUPS_SERVICES_EDIT_SERVICE_NODE_HTTP_STATUS_HEALTHY_ABOVE_PLACEHOLDER:
        "Status code (eg. 200)",
      POPUPS_SERVICES_EDIT_SERVICE_NODE_HTTP_STATUS_HEALTHY_BELOW_LABEL:
        "Healthy: HTTP status below",
      POPUPS_SERVICES_EDIT_SERVICE_NODE_HTTP_STATUS_HEALTHY_BELOW_PLACEHOLDER:
        "Status code (eg. 299)",
      POPUPS_SERVICES_EDIT_SERVICE_NODE_HTTP_BODY_HEALTHY_MATCH_LABEL:
        "Healthy: HTTP body contains",
      POPUPS_SERVICES_EDIT_SERVICE_NODE_HTTP_BODY_HEALTHY_MATCH_PLACEHOLDER:
        "Enter body content to match (eg. \\'OK\\')",
      POPUPS_SERVICES_EDIT_SERVICE_NODE_PROCEED: "Save Node",
      POPUPS_SERVICES_REMOVE_SERVICE_NODE_QUESTION: "Remove this node?",
      POPUPS_SERVICES_REMOVE_SERVICE_NODE_LABEL:
        "The node will be removed. All its replicas will stop being monitored.",
      POPUPS_SERVICES_REMOVE_SERVICE_NODE_PROCEED: "Remove Node",
      POPUPS_SERVICES_SHOW_DETAILS_NODE_QUESTION: "Get node details",
      POPUPS_SERVICES_SHOW_DETAILS_NODE_LABEL:
        "Those identifiers can be used in a Crisp Status Reporter library for your push node. They are unique to this node.",
      POPUPS_SERVICES_SHOW_DETAILS_NODE_SERVICE_ID_LABEL: "Service identifier",
      POPUPS_SERVICES_SHOW_DETAILS_NODE_NODE_ID_LABEL: "Node identifier",
      POPUPS_SERVICES_SHOW_DETAILS_NODE_PROCEED: "Close Details",
      POPUPS_SERVICES_PURGE_SERVICE_NODE_REPLICAS_QUESTION:
        "Purge node replicas?",
      POPUPS_SERVICES_PURGE_SERVICE_NODE_REPLICAS_LABEL:
        "This will reset the saved status for all node replicas. You may purge node replicas when you put offline a replica (eg. app in \\'push\\' mode monitored with a Crisp Status Reporter library).",
      POPUPS_SERVICES_PURGE_SERVICE_NODE_REPLICAS_PROCEED: "Flush Replicas",
      POPUPS_ADD_ANNOUNCEMENT_NOTICE_TITLE: "How do announcements work?",
      POPUPS_ADD_ANNOUNCEMENT_NOTICE_TEXT:
        "Announcements show a text box on your status page, and inform your users on any ongoing or planned issue (eg. a scheduled maintenance).",
      POPUPS_ADD_ANNOUNCEMENT_TITLE_LABEL: "Announcement title",
      POPUPS_ADD_ANNOUNCEMENT_TITLE_PLACEHOLDER: "Enter announcement title…",
      POPUPS_ADD_ANNOUNCEMENT_MESSAGE_LABEL: "Announcement message",
      POPUPS_ADD_ANNOUNCEMENT_MESSAGE_PLACEHOLDER:
        "Write a message for your announcement…",
      POPUPS_ADD_ANNOUNCEMENT_EXPIRE_LABEL:
        "Announcement expiration date (can be blank)",
      POPUPS_ADD_ANNOUNCEMENT_PROCEED: "Save Announcement",
      POPUPS_REMOVE_ANNOUNCEMENT_TITLE: "Remove announcement?",
      POPUPS_REMOVE_ANNOUNCEMENT_NOTICE:
        "The announcement will not be shown anymore on your status page.",
      POPUPS_REMOVE_ANNOUNCEMENT_PROCEED: "Remove Announcement",
      POPUPS_REPORTER_TOKEN_ROLL_LABEL:
        "Please confirm you want to roll your reporter token.",
      POPUPS_REPORTER_TOKEN_ROLL_EMPHASIS:
        "Beware! Your previous reporter token will be invalidated. You will need to update the token in your libraries with the new one.",
      POPUPS_REPORTER_TOKEN_ROLL_PROCEED: "Roll Reporter Token",
      SETTINGS_INCLUDE_HTML_LABEL: "Include custom HTML code",
      SETTINGS_INCLUDE_HTML_ACTION: "Edit included HTML code",
      POPUPS_SERVICES_ADD_SERVICE_NODE_MODE_KEY_POLL:
        "HTTP / TCP / ICMP service (poll mode)",
      POPUPS_SERVICES_ADD_SERVICE_NODE_MODE_KEY_PUSH:
        "Application using reporter library (push mode)",
      POPUPS_SERVICES_ADD_SERVICE_NODE_MODE_KEY_LOCAL:
        "Service behind a firewall (local mode)",
    },
  },
  POPUPS: {
    UPGRADE: {
      TITLE: "{plugin_name} is available in Crisp {plan_name}",
      QUICKTOUR: "Quicktour",
      STEP_PLUGIN_TOUR: "Plugin Tour",
      TOUR_TITLE_1: "The {plugin_name} plugin is available in the",
      TOUR_TITLE_2: "plan",
      GO_FROM_TOUR: "Upgrade to {plan_name}",
      TOUR_LABEL_DEFAULT: "Upgrade now to get this feature.",
      TOUR_LABEL_VIDEO: "Want to see what it does? Watch the video below.",
      FEATURE: "Feature",
    },
    HELP: {
      TITLE: "How can we help?",
      HELPDESK: {
        TITLE: "Visit our Help Center",
        DESCRIPTION: "More than 200 articles to learn how to use Crisp",
      },
      UNLEASH: {
        TITLE: "Find Video Courses",
        DESCRIPTION: "Videos to learn best practices for using Crisp",
      },
      DOCS: {
        TITLE: "Browse our Developer Hub",
        DESCRIPTION:
          "Build on the top of the Crisp Platform in a matter of minutes.",
      },
      CHAT: {
        TITLE: "Chat With Us",
        DESCRIPTION: "More complicated than expected? Chat with our team!",
      },
    },
    LIMIT_REACHED: {
      UPGRADE_TITLE: "Please upgrade your limits",
    },
    ADD_CARD: {
      TITLE: "Add a New Payment Method",
      STEP_METHOD: "Payment method",
      STEP_DETAILS: "Details",
      GO: "Add This Card",
      NAME_ON_CARD: "Name on card",
      ENTER_NAME_ON_CARD: "Enter your name on card…",
      ADDRESS: "Address",
      ENTER_ADDRESS: "42 Mission St, San Francisco, CA",
      CARD_NUMBER: "Card number",
      EXPIRATION_DATE: "Expiration date",
      SECURITY_CODE: "Security code",
      CVV: "CVV",
      INVOICE_EMAIL: "Accountant email to send invoices to (optional)",
      ENTER_INVOICE_EMAIL: "accountant{\\'@\\'}acme.com",
      INVOICE_PHONE: "Phone number (optional)",
      ENTER_INVOICE_PHONE: "+15634534534",
      INFORMATION: "Information for invoices (optional)",
      ENTER_INFORMATION: "Company Name: Crisp IM, Inc.",
      COUNTRY: "Country",
      TAX_IDENTIFIER: "VAT number (if any)",
      ENTER_TAX_IDENTIFIER: "VAT XXXX XXXX XXXX",
      DISCLAIMER_HEAD:
        "Once your payment method is added, it will be automatically linked to Crisp websites without a valid payment method.",
      DISCLAIMER_FOOT:
        "If you have any active paid plan trial expiring, this payment method will be used to automatically renew your paid subscription.",
      CARD_TITLE: "Add a credit card",
      CARD_ENTICE: "All credit card providers are supported.",
      PAYPAL_TITLE: "Add PayPal",
      PAYPAL_ENTICE: "Support credit cards and bank accounts.",
      SAVE_FINISH: "Save & Finish",
      WEBSITES_BILLING_OWNER_WARNING:
        "Some websites you are using are linked to other billing owners",
      WEBSITES_MANAGES_SUBSCRIPTION: "manages this subscription",
      USE_NEW_PAYMENT_METHOD: "Use my new payment method",
      SKIP: "Check",
    },
    ONBOARDING: {
      HEADER_TITLE: "Setup your Crisp",
      TOP_CHANNELS: "Top Channels",
      INTEGRATE:
        "Integrate Crisp on one of these channels to get in touch with your customers.",
      SEE_ALL: "See all integrations",
      VIDEO_TUTORIAL: "Video Tutorial",
      HTML: "# 1. Copy the following code\\n```{javascript_code}```\\n\\n# 2. Add the code in your page\\n**Paste the Crisp chatbox code above in your page.** You can add it in the HTML head section.\\n\\nIf the chatbox is not showing, try to reset your cache. You can also contact our support team.\\n\\n# 3. Extra\\n**Get more of your chatbox** by pushing your existing users email, and many more. Documentation is available [here](https://docs.crisp.chat/guides/chatbox-sdks/web-sdk/dollar-crisp/).",
      WORDPRESS:
        "# 1. Get the plugin \\nGo to your Wordpress dashboard. Click on **Plugins / Add New** and search **Crisp**. Install it and activate it.\\n\\nAlternatively, you can get the plugin from [this link](https://downloads.wordpress.org/plugin/crisp.zip) and upload it in **/wp-content/plugins**\\n\\n# 2. Connect with Crisp \\n Go to the **Crisp Settings** section, in the left menu. Click on **Connect with Crisp**.\\n\\n# 3. Play with Crisp \\nGo to your [{website_name} site](http://{website_domain}) and your chatbox should be be working. If you have any issues, first reset your cache and then contact the support.",
      SHOPIFY:
        "# 1. Get the plugin\\nGo to the [Crisp Shopify Plugin](/website/{website_id}/plugins/filter/all/?query=urn%3Acrisp.im%3Ashopify%3A0) and click on **Get it**.\\n\\n# 2. Go to your Shopify Apps\\nIn the left menu, go to the **Apps** page in your Shopify. Go to the *Crisp* app, and click on **Connect with Crisp**.\\n\\n# 3. Play with Crisp \\nGo to the [{website_name} site](http://{website_domain}) and your chatbox should be be working. If you have any issues, first reset your cache and then contact the support.",
      EMAIL:
        "# 1. Emails in Crisp\\nAs *Crisp* is a team inbox you can receive emails in your *Crisp*. Emails are working as redirections, forwarding for instance **contact{\\'@\\'}{website_domain}** to your *Crisp*. You will love it.\\n\\n# 2. Copy the following email\\n`{email_address}`\\n\\n# 3. Setup email forwarding\\nSetup an email forwarding in your email provider dashboard, to the provided *Crisp* email.",
      PRESTASHOP:
        "# 1. Get the plugin\\nGet the plugin from [this link](https://github.com/crisp-im/crisp-plugin-prestashop/raw/master/dist/crisp.zip).\\n\\n# 2. Upload the plugin\\nUpload the *Crisp* plugin to the **modules** directory. Ensure that the *Crisp* module is named **crisp**.\\n\\n# 3. Activate Crisp\\nGo to the **modules and services** page. Search *Crisp* and click on **configure**. Then click on **Connect with Crisp**.\\n\\nFinally, go to the [{website_name} site](http://{website_domain}) and your chatbox should be be working. If you have any issues, first reset your cache and then contact the support.",
      WHMCS:
        "# 1. Get the plugin\\nGet the plugin from [this link](https://github.com/crisp-im/crisp-plugin-whmcs/raw/master/dist/crisp.zip).\\n\\n# 2. Upload the plugin\\nUpload the *Crisp* plugin to the **modules/addon** directory. Ensure that the *Crisp* module is named **crisp**.\\n\\n# 3. Activate Crisp\\nGo to the addon page to activate *Crisp*. **Here is your licence key:**\\n\\n`{website_id}`\\n\\nFinally, go to the [{website_name} site](http://{website_domain}) and your chatbox should be be working. If you have any issues, first reset your cache and then contact the support.",
      SMS: "# 1. Get the plugin\\nGet the plugin from [this link](/website/{website_id}/plugins/filter/all/?query=urn%3Acrisp.im%3Atwilio%3A0).\\n\\n# 2. Install the plugin\\n**Click on install and go to the plugin configuration.**\\n\\n# 3. Link it with Twilio\\n**You need to paste your Twilio credentials (Twilio ID and Twilio Token).** Then, continue and select your Twilio SMS phone number. Your Crisp should be linked with Twilio then. If you have any issues, contact our support.",
      MESSENGER:
        '# 1. Get the plugin\\nGet the plugin from [this link](/website/{website_id}/plugins/filter/all/?query=urn%3Acrisp.im%3Amessenger%3A0).\\n\\n# 2. Install the plugin\\n**Click on install and go to the plugin configuration.** Finally click on "Connect with Messenger"\\n\\n# 3. Link it with Facebook\\n**You will be redirected on Facebook.** Just follow the steps and finally, select your Facebook page. If you have any issues, contact our support.',
      IOS: "# 1. Install the SDK\\nInstall the SDK as explained in our [GitHub page](https://github.com/crisp-im/crisp-sdk-ios).\\n\\n# 2. Install Crisp in AppDelegate\\nAfter importing Crisp, you can include it in the **AppDelegate**:\\n```{ios_delegate_code}```\\n\\n\\n# 3. Launch Crisp\\nYou can now launch Crisp from your **View Controller**:\\n```{ios_view_code}```\\n",
      ANDROID:
        "# 1. Install the SDK\\nInstall the SDK as explained in our [GitHub page](https://github.com/crisp-im/crisp-sdk-android).\\n\\n# 2. Initiate Application class\\nAfter importing Crisp, you can include it in the **Application subclass**:\\n```{android_application_code}```\\n\\n\\n# 3. Launch Crisp\\nYou can now launch Crisp from your **Activity**:\\n```{android_activity_code}```\\n",
    },
    FILTER: {
      TITLE: "Advanced Filter",
      CONTROLS_DONE: "Done",
      FORM_AND: "And",
      FORM_FIELD_PLACEHOLDER_STRING: "Enter a value (and hit enter)",
      FORM_FIELD_PLACEHOLDER_NUMBER: "Enter a number value",
      FORM_FIELD_PLACEHOLDER_NUMBER_HOURS: "Enter number of hours",
      FORM_FIELD_PLACEHOLDER_NUMBER_SUFFIX: "Hours",
      FORM_FIELD_PLACEHOLDER_BOOLEAN: "Check or uncheck",
      FIELDS: {
        CRITERIA: {
          PEOPLE_EMAIL: "Email address",
          PEOPLE_PERSON_NAME: "Contact full name",
          PEOPLE_PERSON_GENDER: "Contact gender",
          PEOPLE_PERSON_GENDER_MALE: "Male",
          PEOPLE_PERSON_GENDER_FEMALE: "Female",
          PEOPLE_PERSON_PHONE: "Contact phone number",
          PEOPLE_PERSON_TIMEZONE: "Contact timezone",
          PEOPLE_PERSON_LOCALES: "Contact language",
          PEOPLE_PERSON_LOCATION_COUNTRY: "Contact country",
          PEOPLE_PERSON_LOCATION_REGION: "Contact region",
          PEOPLE_PERSON_LOCATION_CITY: "Contact city",
          PEOPLE_PERSON_JOB_NAME: "Job name",
          PEOPLE_PERSON_JOB_TITLE: "Job title",
          PEOPLE_PERSON_JOB_SENIORITY_EXECUTIVE: "Executive",
          PEOPLE_PERSON_JOB_SENIORITY_DIRECTOR: "Director",
          PEOPLE_PERSON_JOB_SENIORITY_MANAGER: "Manager",
          PEOPLE_COMPANY_NAME: "Company name",
          PEOPLE_COMPANY_DOMAIN: "Company domain name",
          PEOPLE_COMPANY_TIMEZONE: "Company timezone",
          PEOPLE_COMPANY_LOCATION_COUNTRY: "Company country",
          PEOPLE_COMPANY_LOCATION_REGION: "Company region",
          PEOPLE_COMPANY_LOCATION_CITY: "Company city",
          PEOPLE_ACTIVE: "Contact last active date",
          PEOPLE_SCORE: "Contact-provided rating",
          PEOPLE_SEGMENTS: "Contact segments",
          PEOPLE_DATA: "Contact custom data",
          PEOPLE_CREATED: "Contact creation date",
          CONVERSATION_STATE: "Chat state",
          CONVERSATION_STATE_PENDING: "Pending",
          CONVERSATION_STATE_UNRESOLVED: "Unresolved",
          CONVERSATION_STATE_RESOLVED: "Resolved",
          CONVERSATION_AVAILABILITY: "User availability",
          CONVERSATION_AVAILABILITY_ONLINE: "Online",
          CONVERSATION_AVAILABILITY_AWAY: "Away",
          CONVERSATION_AVAILABILITY_OFFLINE: "Offline",
          CONVERSATION_IS_BLOCKED: "User blocked",
          CONVERSATION_META_EMAIL: "User email",
          CONVERSATION_META_PHONE: "User phone",
          CONVERSATION_META_IP: "User IP address",
          CONVERSATION_META_DEVICE_GEOLOCATION_COUNTRY: "Country",
          CONVERSATION_META_DEVICE_GEOLOCATION_CITY: "City",
          CONVERSATION_META_DEVICE_LOCALES: "User language",
          CONVERSATION_META_DEVICE_TIMEZONE: "User timezone",
          CONVERSATION_META_DATA: "Chat data",
          CONVERSATION_META_SEGMENTS: "Chat segments",
          CONVERSATION_CREATED: "Creation date",
          CONVERSATION_UPDATED: "Update date",
        },
        OPERATORS: {
          EQUALS_TO: "Equals to",
          DIFFERS_FROM: "Differs from",
          PATTERN: "Has pattern",
          GREATER_THAN: "Greater than",
          LESS_THAN: "Less than",
          GREATER_OR_EQUAL: "Greater or equal",
          LESS_OR_EQUAL: "Less or equal",
          EXISTS: "Exists",
          NOT_EXISTS: "Doesn\\'t exist",
          STARTS_WITH: "Starts with",
          ENDS_WITH: "Ends with",
          NOT_PATTERN: "No pattern",
        },
        SCORE: {
          BAD: "Bad",
          NORMAL: "Normal",
          GREAT: "Great",
        },
      },
      CONTROLS_CREATE: "Save This Filter",
      CONTROLS_UPDATE: "Update This Filter",
      CONTROLS_DELETE: "Delete This Filter",
      CONTROLS_CANCEL: "Cancel",
      CONTROLS_NEW: "New Filter",
      CRITERIA: {
        SEARCH_PLACEHOLDER: "Filter criteria",
        SEARCHED: "Searched criteria",
        MOST_USED: "Most used criteria",
        ALL: "All criteria",
      },
      REMOVE_FILTER_TITLE: "Are you sure you want to remove this filter?",
      REMOVE_FILTER_DESCRIPTION:
        "You will not be able to use this filter anymore.",
      REMOVE_FILTER_PROCEED: "Remove Filter",
    },
    NEW_CAMPAIGN: {
      TITLE: "Create a New Campaign",
      STEP_TYPE: "Campaign Type",
      STEP_NAME: "Campaign Name",
      TYPE_TITLE: "Select the type of campaign to create",
      TYPE_LABEL:
        "You can create either a one-shot, one-use campaign; or an automated, continuous campaign that gets sent per-contact when an event triggers.",
      TYPE_PICKER_ONE_SHOT_TITLE: "One-Shot Campaign",
      TYPE_PICKER_ONE_SHOT_DESCRIPTION:
        "Sent once to all targeted contacts, eg. a product newsletter.",
      TYPE_PICKER_AUTOMATED_TITLE: "Automated Campaign",
      TYPE_PICKER_AUTOMATED_DESCRIPTION:
        "Sent automatically based on events, eg. remind inactive contacts.",
      TYPE_PROCEED: "Continue With This Campaign Type",
      NAME_TITLE: "Pick a name for your campaign",
      NAME_LABEL:
        "The campaign name is visible to all targeted contacts. It is used as the subject of the email your contacts will receive.",
      NAME_FIELD_NAME_LABEL: "Name of the Campaign",
      NAME_FIELD_NAME_PLACEHOLDER: "Enter the name of the campaign…",
      NAME_PROCEED: "Start Configuring My Campaign",
    },
    EDIT_CAMPAIGN_NAME: {
      LABEL: "Campaign Name",
      PLACEHOLDER: "Enter name (eg. \\'Product Update\\')",
      ACTION: "Edit Name",
    },
    EDIT_CAMPAIGN_TAG: {
      NEW_LABEL: "New Campaign Tag",
      NEW_PLACEHOLDER: "Enter new tag (eg. \\'Newsletters\\')",
      ADD_TAG: "Add another tag…",
      NO_TAG: "No tag",
      ACTION_CLEAR: "Edit With No Tag",
      ACTION_ADD: "Edit With New Tag",
      ACTION_USE: "Edit With Existing Tag",
    },
    NEW_TEMPLATE: {
      NAME_LABEL: "Name",
      NAME_PLACEHOLDER: "Enter the name of the template…",
      FORMAT_LABEL: "Format (can be changed afterwards)",
      PROCEED: "Start Editing My Template",
    },
    IMPORT_CONTACTS: {
      TITLE: "Import Contact Profiles",
      STEP_FILE: "Select File",
      STEP_CONFIGURE: "Configure Import",
      STEP_IMPORT: "Proceed Import",
      UPLOAD_TITLE: "Upload a CSV file to import contact profiles",
      UPLOAD_LABEL:
        "You can upload a standard CSV file containing the full name and email of your contacts, to add them to your contact database on Crisp.",
      UPLOAD_FORM_LABEL: "Upload CSV file (UTF-8 encoded)",
      UPLOAD_PROCEED: "Start CSV Import",
      CONFIGURE_TITLE: "Configure CSV import",
      CONFIGURE_LABEL:
        "Map the custom contact fields from your CSV to fields Crisp recognizes. Once configured, Crisp will be ready to perform the import.",
      CONFIGURE_OPTIONS_TITLE: "Parsing options",
      CONFIGURE_OPTIONS_COLUMN_SEPARATOR: "Column separator",
      CONFIGURE_OPTIONS_SKIP_HEADER: "Skip header",
      CONFIGURE_POPUP_CUSTOM_DATA_LABEL: "Custom Data key",
      CONFIGURE_POPUP_CUSTOM_DATA_PLACEHOLDER:
        "Enter a custom data key (eg. user_id)",
      CONFIGURE_POPUP_CUSTOM_DATA_PROCEED: "Create Custom Key",
      CONFIGURE_MAPPING_COLUMN: "Column",
      CONFIGURE_MAPPING_LABEL: "Maps to…",
      CONFIGURE_MAPPING_SKIP: "Skip this field",
      CONFIGURE_MAPPING_CHOICES: {
        EMAIL: "Email address",
        PERSON_FIRSTNAME: "First name",
        PERSON_LASTNAME: "Last name",
        PERSON_NICKNAME: "Full name",
        PERSON_PHONE: "Phone number",
        PERSON_ADDRESS: "Postal address",
        PERSON_WEBSITE: "Personal website",
        PERSON_GEOLOCATION_COUNTRY: "Country",
        PERSON_GEOLOCATION_CITY: "City",
        COMPANY_NAME: "Company",
        SEGMENTS: "Segments",
        NOTEPAD: "Notepad",
        CREATED_AT: "Creation date",
        ACTIVE: "Last Active Date",
        IS_UNSUBSCRIBED: "Is Unsubscribed",
      },
      CONFIGURE_PROCEED: "Proceed Import With This Configuration",
      PROCEED_TEXT_TITLE_PENDING: "Importing contact profiles…",
      PROCEED_TEXT_TITLE_DONE: "Done importing contact profiles!",
      PROCEED_TEXT_LABEL_IMPORTED: "contacts imported",
      PROCEED_TEXT_LABEL_SKIPPED: "skipped",
      PROCEED_TEXT_LABEL_REMAINING: "remaining.",
      PROCEED_TEXT_LABEL_ESTIMATED: "(Should be ended in {minutes} minutes)",
      PROCEED_CLOSE: "Close Import",
      PROCEED_HELP:
        "You can close the popup. Your import will still be executed under the hood.",
    },
    IMPORT_HELPDESK: {
      TITLE: "Import Helpdesk Articles",
      STEP_CONFIGURE: "Configure Import",
      STEP_IMPORT: "Proceed Import",
      CONFIGURE_TITLE: "Import your previous Helpdesk in one click",
      CONFIGURE_LABEL:
        "Crisp will automatically configure and adjust articles from your previous helpdesk. Submit previous helpdesk URL and start the import.",
      FORM_FIELD_HELPDESK_URL_LABEL: "Previous Helpdesk URL",
      FORM_FIELD_HELPDESK_URL_PLACEHOLDER: "docs.acme.help",
      PROCEED_TITLE: "Your helpdesk is being imported",
      PROCEED_LABEL:
        "Our bot just started converting your helpdesk. This can take up to 10 minutes. We will notify you when your helpdesk is ready to use.",
      CONFIGURE_PROCEED: "Import My Helpdesk",
      PROCEED_CLOSE: "Okay, Close This",
    },
    ROUTING_RULE: {
      TITLE: "Routing Rule",
      STEP_NAME: "Name",
      STEP_CONDITIONS: "Conditions",
      STEP_ASSIGN: "Assign to",
      NAME_TITLE: "Pick a name for your rule",
      NAME_LABEL:
        "The rule name helps you identify this specific rule among all your rules. Don\\'t forget to adjust the rule priority once you add it.",
      NAME_FIELD_NAME_LABEL: "Name of the Rule",
      NAME_FIELD_NAME_PLACEHOLDER: "Enter the name of the rule…",
      NAME_PROCEED: "Continue To Rule Conditions",
      CONDITIONS_PICKER_TITLE: "Select routing condition",
      CONDITIONS_PICKER_LABEL:
        "When a new conversation starts, optional routing conditions can be checked before assigning the conversation to someone.",
      TYPE_PICKER_SIMPLE_TITLE: "Simple Condition",
      TYPE_PICKER_SIMPLE_DESCRIPTION:
        "Assign to the specified operators in a direct way.",
      TYPE_PICKER_ADVANCED_TITLE: "Advanced Condition",
      TYPE_PICKER_ADVANCED_DESCRIPTION:
        "Assign based on segments, user data, user locale, and more.",
      CONDITIONS_PICKER_PROCEED: "Continue With This Condition",
      CONDITIONS_ADVANCED_PROCEED: "Use Those Advanced Conditions",
      ASSIGN_TITLE: "Choose operators to be assigned",
      ASSIGN_LABEL:
        "When all routing conditions match, a target operator, or someone from target group gets assigned to the conversation.",
      ASSIGN_NOTICE_TITLE: "You selected more than 1 operator.",
      ASSIGN_NOTICE_LABEL:
        "If a new conversation matches routing rule, it will get assigned to the most active operator (ie. online operator, or last online operator if all operators are offline). This way, the assigned operator has more chances to reply quickly.",
      ASSIGN_WARNING_TITLE: "Ghost operators selected.",
      ASSIGN_WARNING_LABEL:
        "Ghost operators are operators who were previously added to this rule, but are no longer members of your website. You can safely remove them from the rule.",
      ASSIGN_PROCEED: "Save Routing Rule",
    },
    MANAGE_LIMIT: {
      TITLE: "Upgrade Your {name} Limits",
      STEP_CONFIGURE: "Configure Limit",
      INTRODUCTION_TITLE_LIMIT: "What is the {name}?",
      INTRODUCTION_TITLE_QUOTA: "What is the {name} quota?",
      INTRODUCTION_DESCRIPTION_DEFAULT:
        "Your current Crisp plan allows you a certain level of use of {name} limits, which is sufficient for most team. If you need more, you can upgrade to a higher limit.",
      INTRODUCTION_DESCRIPTION_TRANSLATE_REQUESTS:
        "Your current Crisp plan allows you to translate a certain amounts of messages per week. If you need to translate more, you may upgrade to a higher limit.",
      INTRODUCTION_DESCRIPTION_TRANSLATE_CHARACTERS:
        "Your current Crisp plan allows you to translate a certain amounts of characters per week. If you need to translate more, you may upgrade to a higher limit.",
      INTRODUCTION_DESCRIPTION_BUCKET_URL:
        "Your current Crisp plan allows you to upload a certain amount of files per day. If you need to upload more, you may upgrade to a higher limit.",
      INTRODUCTION_DESCRIPTION_OPERATOR_INVITES:
        "Your current Crisp plan allows an unlimited amount of agents, but you might have reached your free agents limit. If you need to invite more agents, please upgrade your limit.",
      INTRODUCTION_DESCRIPTION_PEOPLE_PROFILES:
        "Your current Crisp plan allows you to store a certain number of contacts in your Crisp CRM, which is sufficient for most team. If you need to store more contacts, you can upgrade to a higher limit.",
      INTRODUCTION_DESCRIPTION_STATUS_PROBES:
        "Your current Crisp plan allows you to monitor a maximum amount of hosts with Crisp Status. To monitor more, please upgrade your limits.",
      INTRODUCTION_DESCRIPTION_CAMPAIGN_RECIPIENT:
        "Your current Crisp plan lets you send campaigns to a set maximum amount of recipients. Do you need to send campaigns to more people? You may upgrade your limits there!",
      INTRODUCTION_MORE: "Read more about limits",
      POINT_INCLUDED_TITLE: "Included in your plan:",
      POINT_INCLUDED_UNITS_IN_PLAN: "(in plan)",
      POINT_ADDITIONAL_TITLE: "Additional limits:",
      POINT_ADDITIONAL_UNITS_PER_UNIT: "(per unit)",
      POINT_TOTAL_TITLE: "Total limit:",
      POINT_TOTAL_UNITS_TOTAL: "total",
      POINT_TOTAL_PER_TIME: "Every {time}",
      POINT_TOTAL_NOTICE_ENTRIES_QUOTA: "You have used",
      POINT_TOTAL_NOTICE_ENTRIES_LIMIT: "You currently have",
      POINT_TOTAL_NOTICE_INSUFFICIENT: "(limit too low)",
      POINT_TYPE_DEFAULT: "items",
      POINT_TYPE_TRANSLATE_REQUESTS: "translated messages",
      POINT_TYPE_TRANSLATE_CHARACTERS: "translated characters",
      POINT_TYPE_BUCKET_URL: "file uploads",
      POINT_TYPE_OPERATOR_INVITES: "operators",
      POINT_TYPE_PEOPLE_PROFILES: "contacts",
      POINT_TYPE_STATUS_PROBES: "monitored hosts",
      POINT_TYPE_CAMPAIGN_RECIPIENT: "campaign recipients",
      NAMESPACE_FILTER_CONVERSATION: "Conversation filters executed",
      NAMESPACE_FILTER_PEOPLE: "Contact filters executed",
      NAMESPACE_TRANSLATE_REQUESTS: "LiveTranslate messages translated",
      NAMESPACE_TRANSLATE_CHARACTERS: "LiveTranslate characters translated",
      NAMESPACE_BUCKET_URL: "Chatbox files uploaded",
      NAMESPACE_OPERATOR_INVITES: "Inbox operators limit",
      NAMESPACE_PEOPLE_PROFILES: "CRM contacts limit",
      NAMESPACE_STATUS_PROBES: "Status page nodes limit",
      NAMESPACE_CAMPAIGN_RECIPIENT: "Campaign recipients limit",
      DISCLAIMER: "Limits are charged monthly, at the same time as your plan.",
      PROCEED: "Activate Those Limits",
    },
    CONFIGURE_TOKEN: {
      TITLE: "Enable Two Factor Authentication",
      STEP_PREPARE: "Prepare",
      STEP_SCAN: "Scan code",
      STEP_VERIFY: "Verify code",
      PREPARE_WARNING_TITLE:
        "If you lose your phone, you will be locked out of your account.",
      PREPARE_WARNING_LABEL:
        "Thus, you should back up your TOTP key. We advise that your write the private key we will provide next step on a piece of paper.",
      PREPARE_PROCEED: "Continue Setup",
      SCAN_TITLE: "Scan this with your authentication app",
      SCAN_LABEL:
        "If you are unable to scan the code, you can also install the following private key in your authentication app. You may also backup it on paper.",
      SCAN_CODE_COPY: "Copy private key",
      GET_HELP: "Need help?",
      SCAN_PROCEED: "I Scanned The Code",
      VERIFY_TITLE: "Verify the scanned code",
      VERIFY_LABEL:
        "To ensure your private key is correctly installed, please enter the 6 digit code that your authentication app generates below.",
      VERIFY_FIELD_TOKEN_LABEL:
        "Confirm your token (Two Factor Authentication)",
      VERIFY_FIELD_TOKEN_PLACEHOLDER: "Enter your 6 digit token…",
      VERIFY_PROCEED: "Verify Code & Finalize",
      PREPARE_LABEL:
        "Two Factor Authentication makes your Crisp account safer, by asking for a temporary code upon login. The code is generated by a TOTP app on your mobile phone. You may use either [Google Authenticator](https://support.google.com/accounts/answer/1066447), [OnePassword](https://1password.com/).",
    },
    MOBILE: {
      TITLE: "Download Crisp apps",
      LABEL: "To use Crisp on your mobile device, get the app.",
      DOWNLOAD_IOS: "Crisp for iOS",
      DOWNLOAD_ANDROID: "Crisp for Android",
      GO_SETTINGS: "Go to Settings",
      OR: "Or",
    },
    CLOSE_WARNING: {
      SAVE_TITLE: "Do you want to save your {type} before leaving?",
      SAVE_DESCRIPTION: "Any unsaved change will be lost forever.",
      CLOSE_BACK: "Back",
      CLOSE_WARNING: "Close Without Saving",
      TYPES: {
        CAMPAIGN: "campaign",
        TEMPLATE: "template",
        ARTICLE: "article",
      },
    },
    SWITCH_PLAN: {
      BANNER: {
        STEP_CHOICE: "Choice",
        STEP_BILLING: "Details",
      },
      HEADER: {
        STEP_CHOICE: {
          TITLE: "Build incredible experiences for your customers",
          SUBTITLE: "Your current plan is ",
        },
        STEP_CHANGES: {
          TITLE: "Preview your plan changes",
          SUBTITLE_PART_1: "Thanks for your time on the",
          SUBTITLE_PART_2:
            " plan. Here are the benefits you are about to lose.",
        },
        STEP_BILLING: {
          TITLE: "You\\'re almost on {selected_plan_name}!",
          SUBTITLE: "Upgrade to Unlock more features",
        },
        BENEFITS: {
          TRIAL: "14 days trial",
          FAIR: "Flat pricing",
          CANCEL: "Cancel anytime",
        },
      },
      WARNING: {
        NOT_OWNER:
          "It is not possible to edit your subscription because you are not owner of your team",
      },
      PLANS: {
        BASIC: {
          ENTICE: "For basic conversational experiences.",
          FEATURES: [
            "Live Chat software",
            "Mobile Apps",
            "30 days history",
            "Notifications",
          ],
        },
        PRO: {
          ENTICE:
            "Build a shared inbox to centralize your messages from multiple channels and increase leadgen.",
          FEATURES: [
            "Unlimited chat history",
            "Canned Responses",
            "Email Inbox",
            "Messenger Integration",
            "Live chat trigger",
          ],
        },
        UNLIMITED: {
          ENTICE:
            "Enjoy a full-featured customer support platform to communicate with leads and customers on every channel.",
          FEATURES: [
            "Knowledge base",
            "Chatbot",
            "Campaigns",
            "CRM",
            "Routing / Assignment",
            "MagicBrowse & Video Calls",
            "No Crisp logo",
          ],
        },
        PER_MONTH: "/ mth",
        AGENTS: "agents",
        UNLIMITED_AGENTS: "No limit on agents",
        UNLIMITED_AGENTS_EXPLAIN_MAIN: "20 agents included for free",
        UNLIMITED_AGENTS_EXPLAIN_SUB:
          "Add more agents above that for a small fee",
        UPGRADE_TO: "Upgrade to {plan}",
        DOWNGRADE_TO: "Downgrade to {plan}",
        CURRENT_PLAN: "Your current plan",
        CONTINUE_WITH_PLAN: "Continue with this plan",
      },
      COMPARE_OFFERS: "Compare our offers",
      CONTACT_US: "Questions? Chat with us!",
      DOWNGRADE: {
        TITLE:
          "Downgrading will impact your customer support. Please review the changes.",
        CONFIRM: {
          TITLE: "Do you still want to downgrade?",
          SUBTITLE:
            "By downgrading today, you will lose all the features of your current plan. We\\'ll also notify impacted agents that their account was disabled.",
          LABEL: "I understand the changes and how they will affect my team",
          BUTTON_DOWNGRADE: "Downgrade",
          BUTTON_KEEP: "Keep my current plan",
        },
      },
      PAYMENT: {
        SELECT_BILLING: {
          TITLE: "Select your billing method",
          ADD_BILLING_METHOD: "Add a billing method",
        },
        ADD_BILLING_METHOD: {
          TITLE: "Select your billing method",
          ADD_CREDIT_CARD: "Pay using Credit Card",
          ADD_PAYPAL: "Pay using PayPal",
        },
        BILLING_DETAILS: {
          TITLE: "Your billing details",
        },
        PAYMENT_DETAILS: {
          TITLE: "Payment details",
        },
        SUMMARY: {
          TITLE: "Order Summary",
          TODAYS_CHARGE: "Today\\'s charge",
          STATEMENT:
            "We\\'ll charge you now, to the prorated of the current billing period. Your subscription is **billed monthly** and will automatically renew for {currency}{price} on **{date}**. You can cancel any time.",
          ITEM_1: "This is a secured and encrypted payment with Stripe",
          ITEM_2: "No engagement",
          ITEM_3: "Flat pricing",
          BUTTON_UPGRADE: "Upgrade to Crisp  {plan_name}",
          BUTTON_SOON_DONE: "Soon Done, Please Wait",
        },
      },
      BENEFITS: {
        AGENTS:
          "Your team currently have **{operators} agents**. Your new new plan only includes **{max} agents**. Some agents will lose access.",
        CRM: "The Crisp contacts feature is only included in Crisp Unlimited.\\n\\nYou will lose access to your **{contacts} contacts**.",
        TRIGGERS:
          "Right now, you are using **{triggers} triggers** on your website. \\n\\n As the free plan is not including Triggers, you might have **2x less leads** in the future.",
        HISTORY:
          "The free plan has a **30 days message history**. After this period, messages previously sent are hidden. **This will include existing conversations**. \\n\\nYour current plan has an **unlimited** message rentention.",
        FILES:
          "**You won\\'t be able to share files anymore** using the free plan. This includes regular files, GIFs, images as well as audio messages.",
        BOT: "The Crisp Bot plugin is only offered in Crisp Unlimited. The bot will **stop replying** to your customers automatically",
        HELPDESK:
          "Crisp Helpdesk is only offered in Crisp Unlimited. The domain **help.{domain}** will be **disabled** as well as **all you articles**.",
        EMAIL:
          "Emails sent to contact{\\'@\\'}{domain} **won\\'t be redirected to your Crisp Inbox anymore**.",
        CAMPAIGNS:
          "You are currently sending marketing campaigns using Crisp. **This feature will be disabled**. Crisp Campaigns are only offered from Crisp Unlimited.",
        MESSENGER:
          "It won\\'t be possible to chat with customers reaching you from Messenger. This feature is not included in the free plan.",
        TELEGRAM:
          "Crisp is currently linked with your Telegram. This feature is not included in the free plan.",
        ZAPIER:
          "Crisp is currently linked with your Zapier account. This feature is only available from Crisp Unlimited.",
        SEARCH:
          "You **won\\'t be able to search** previous conversations & contacts. The search feature is not included in the free plan.",
        MAGICBROWSE:
          "The MagicBrowse feature is only available from Crisp Unlimited. You won\\'t be able to do cobrowsing with your customers.",
        SLACK:
          "Your Slack team won\\'t be able to receive and reply to Crisp messages from Slack.",
        CUSTOMIZATION:
          "It won\\'t be possible to **customize your chatbox** using color codes and custom texts.",
        SHORTCUTS:
          "**All your canned responses will be disabled** and won\\'t be usable anymore.",
      },
      FEATURES: {
        MESSAGING: "Messaging",
        "UNLIMITED-HISTORY": "Unlimited History",
        AGENTS: "Agents",
        HISTORY: "History",
        TRIGGERS: "Triggers",
        SHORTCUTS: "Shortcuts",
        NOTES: "Notes",
        CUSTOMIZATION: "Customization",
        AUDIO: "Audio",
        FILES: "File sharing",
        SLACK: "Slack",
        FACEBOOK: "Facebook",
        TWITTER: "Twitter",
        EMAIL: "Email",
        TELEGRAM: "Telegram",
        MAGICMAP: "MagicMap",
        SEARCH: "Search",
        BLOCK: "Block",
        HELPDESK: "Knowledge Base",
        STATUS: "Status Page",
        REMINDERS: "Chat Reminders",
        ROUTING: "Routing / Assign",
        MAGICBROWSE: "MagicBrowse",
        LIVETRANSLATE: "LiveTranslate",
        "AUTO-RESPONDER": "Auto Responder",
        BOT: "Bot",
        "AUTOMATED-CAMPAIGNS": "Automated Campaigns",
        CAMPAIGNS: "Marketing Campaigns",
        CRM: "CRM",
        "VIDEO-CALLS": "Video Calls",
        ANALYTICS: "Analytics",
        SMS: "SMS",
        MESSENGER: "Messenger",
        LINE: "Line",
        ZAPIER: "Zapier",
        SALESFORCE: "Salesforce",
        PIPEDRIVE: "Pipedrive",
        ZOHO: "Zoho",
        HUBSPOT: "Hubspot",
        "USER-RATINGS": "User Ratings",
        DEBRANDING: "Debranding",
      },
    },
    PLAN_UPDATED: {
      HEADER: "Your plan has been updated",
      TITLE: "Start enjoying your Crisp Plan",
      SUBTITLE_BASIC:
        "Thanks for using Crisp! We just updated your plan. You can contact us anytime if you have any questions about your subscription.",
      SUBTITLE_PAID:
        "Thank you for using Crisp! We just updated your plan. Here are some new features you can enjoy with your new plan.",
      SLIDESHOW: {
        PRO: {
          TRIGGERS: "Send automated messages using Triggers",
          SHORTCUTS: "Increase productivity by using shortcut responses",
          EMAIL: "Manage emails right from your Crisp Inbox",
          CUSTOMIZATION: "Better Messaging Experience and Customization",
          NOTES:
            "Collaborate with team-mates by using private notes and mentions",
        },
        UNLIMITED: {
          KNOWLEDGE: "Educate customers with an Helpdesk",
          BOT: "Create your own chatbot and automate answers",
          ROUTING: "Configure routing rules and automatically assign agents",
          ANALYTICS: "Use Analytics and make datadriven decisions",
          MESSAGING: "Build multichannel messaging experiences",
        },
        LEARN_MORE: "Learn More",
      },
      CONFIRM: "Return To My Dashboard",
    },
    AUTHENTICATION: {
      CONFIRM_LOGOUT_TITLE: "Are you sure you want to logout from Crisp?",
      CONFIRM_LOGOUT_DESCRIPTION:
        "You will need to login again to a Crisp account to access your dashboard.",
      CONFIRM_LOGOUT_PROCEED: "Logout From Crisp",
    },
    SET_NICKNAME: {
      LABEL: "Set a new nickname",
      PLACEHOLDER: "Enter a nickname… (eg. John Doe)",
      ACTION: "Set Nickname",
    },
    SET_EMAIL: {
      LABEL: "Set a new email address",
      PLACEHOLDER:
        "Enter a new email address… (eg. john.doe{\\'@\\'}gmail.com)",
      ACTION: "Set Email",
    },
    SET_PHONE: {
      LABEL: "Set a new phone number",
      PLACEHOLDER: "Enter a new phone number… (eg. +16281234567)",
      ACTION: "Set Phone",
    },
    SET_WIDGET_ITEM_VALUE: {
      LABEL: "Set a new value",
      PLACEHOLDER: "Enter a value…",
      ACTION: "Set Value",
    },
    ADD_PARTICIPANT: {
      EMAIL_LABEL: "Participant email address",
      EMAIL_PLACEHOLDER: "Enter participant email address…",
      ACTION: "Add Participant",
    },
    EXTERNAL_TRANSCRIPT: {
      EMAIL_LABEL: "External operator email address",
      EMAIL_PLACEHOLDER: "Enter external email address…",
      NOTICE:
        "Note that this external operator will be able to send messages in the conversation.",
      ACTION: "Send Transcript",
    },
    EDIT_CARD: {
      GO: "Edit This Card",
    },
    CHANGELOG: {
      TITLE: "What\\'s New?",
      EMPTY_NO_UPDATES_TITLE: "No update",
      EMPTY_NO_UPDATES_DESCRIPTION: "There is no update yet.",
    },
  },
  PLUGINS: {
    DEFAULT: {
      CONTROLS_MENU_PLUGINS: "Plugins",
      ROW_PLAN_UPGRADE_CHECKOUT: "Separate checkout",
      ROW_ACTIONS_VIEW: "View Details",
      EMPTY_NO_PLUGIN_DEFAULT_TITLE: "There is no plugin.",
      EMPTY_NO_PLUGIN_DEFAULT_LABEL:
        "No plugin is available at the moment. Come back later!",
      EMPTY_NO_PLUGIN_SEARCH_TITLE: "No plugin was found for your search.",
      EMPTY_NO_PLUGIN_SEARCH_LABEL:
        "You can find plugins by name. Refine your search!",
      POPUPS_UNINSTALL_QUESTION: "Uninstall this plugin from your website?",
      POPUPS_UNINSTALL_LABEL: "This plugin will be disabled from your website.",
      POPUPS_UNINSTALL_PROCEED: "Uninstall Plugin",
      ROW_ACTIONS_VIEW_TOOLTIP: "View plugin details",
      ROW_ACTIONS_SETTINGS_TOOLTIP: "Configure the plugin",
      ROW_ACTIONS_UNINSTALL_TOOLTIP: "Uninstall the plugin",
    },
    SETTINGS: {
      IDENTITY_BY: "By",
      PLUGIN_CONTACT: "Plugin Support",
      PLUGIN_DOCUMENTATION: "Documentation",
      DISCLAIMER_UNOFFICIAL_WARNING: "Heads up: this is a third-party plugin.",
      DISCLAIMER_UNOFFICIAL_NOTICE:
        "It was not made by Crisp, contact the developer for support. Data is not hosted by Crisp.",
    },
    COMMON: {
      TITLE: {
        PLUGINS: "Plugins",
        CATEGORIES: "Categories",
      },
      PLUGINS: {
        ALL: "All Plugins",
        INSTALLED: "Installed Plugins",
      },
      CATEGORIES: {
        FEATURE: "Crisp Features",
        AUTOMATION: "Automation",
        CMS: "CMS",
        CRM: "CRM",
        MARKETING: "Marketing",
        MESSAGING: "Messaging",
        TEAMWORK: "Teamwork",
        OTHER: "Others",
      },
    },
    PREVIEW: {
      INSTALL: "Install",
      INSTALL_CONFIRM: "{price}, Confirm?",
      INSTALLED: "Installed",
      SETTINGS: "Settings",
      DOCUMENTATION: "Get Help",
      VIDEO: "See Video",
      WEBSITE: "Author website",
      CONTACT: "Contact author",
      TERMS: "Terms of use",
      PRIVACY: "Privacy policy",
      VIEW_MARKETPLACE: "View all details",
      INSTALL_PAID: "Paid plugin:",
      PER_MONTH: "/mth",
      SEPARATE_CHECKOUT: "Paid separately",
      AUTHOR_DEVELOPER: "Developer",
      PLAN_FREE: "Free",
      SCOPES_PERMISSIONS: "Permissions",
      SCOPES_HELP: "What are permissions?",
      SCOPES_ACCESS_READ: "Read",
      SCOPES_ACCESS_WRITE: "Write",
      SCOPES_ACCESS_READ_WRITE: "Read & write",
      SCOPE_GROUPS: {
        WEBSITE: "Website",
        MESSAGING: "Messaging",
        CONTACTS: "Contacts",
      },
      SCOPE_NAMESPACES: {
        BUCKET_URL: "files",
        WEBSITE_AVAILABILITY: "availability",
        WEBSITE_OPERATORS: "operators",
        WEBSITE_SETTINGS: "settings",
        WEBSITE_VERIFY: "verify settings",
        WEBSITE_VISITORS: "visitors",
        WEBSITE_CONVERSATION_INITIATE: "on chat creation",
        WEBSITE_CONVERSATION_SESSIONS: "sessions",
        WEBSITE_CONVERSATION_SUGGEST: "suggestions",
        WEBSITE_CONVERSATION_MESSAGES: "messages",
        WEBSITE_CONVERSATION_STATES: "states",
        WEBSITE_CONVERSATION_PARTICIPANTS: "participants",
        WEBSITE_CONVERSATION_PAGES: "pages",
        WEBSITE_CONVERSATION_EVENTS: "events",
        WEBSITE_CONVERSATION_ACTIONS: "actions",
        WEBSITE_CONVERSATION_BROWSING: "MagicBrowse",
        WEBSITE_CONVERSATION_CALLS: "calls",
        WEBSITE_CONVERSATION_REMINDERS: "reminders",
        WEBSITE_CONVERSATION_ROUTING: "routing",
        WEBSITE_PEOPLE_STATISTICS: "statistics",
        WEBSITE_PEOPLE_SUGGEST: "suggestions",
        WEBSITE_PEOPLE_PROFILES: "profiles",
        WEBSITE_PEOPLE_CONVERSATIONS: "conversations",
        WEBSITE_PEOPLE_EVENTS: "events",
        WEBSITE_PEOPLE_DATA: "data",
        WEBSITE_PEOPLE_SUBSCRIPTIONS: "email subscriptions",
      },
    },
  },
  CALL: {
    STATE_CALLING: "Calling…",
    STATE_ONGOING: "Ongoing",
    LABEL_AUDIO_MUTED: "Audio muted",
    LABEL_VIDEO_MUTED: "Video muted",
  },
  CONTACTS: {
    COMMON: {
      NEW_CONTACT: "New Contact",
      DOCUMENTATION: "Documentation",
    },
    GENERIC: {
      POPUPS_NEW_CONTACT_EMAIL_LABEL: "Email of the Contact",
      POPUPS_NEW_CONTACT_EMAIL_PLACEHOLDER: "Enter the email of the contact…",
      POPUPS_NEW_CONTACT_NAME_LABEL: "Name of the Contact",
      POPUPS_NEW_CONTACT_NAME_PLACEHOLDER:
        "Enter the full name of the contact…",
      POPUPS_NEW_CONTACT_PROCEED: "Add Contact",
      POPUPS_NEW_CONTACT_UPGRADE_DESCRIPTION:
        "You have reached your CRM contacts limit, included for free in your plan.\\n**You can add more contacts by increasing your limits**.\\n\\nPlease follow our instructions to subscribe to higher limits.",
      POPUPS_NEW_CONTACT_UPGRADE_PROCEED: "Increase CRM Limits",
    },
    DEFAULT: {
      CONTROLS_ACTIONS: "Actions",
      CONTROLS_ACTIONS_REMOVE_SELECTED: "Remove selected profiles",
      CONTROLS_ACTIONS_REMOVE_FILTERED: "Remove searched profiles",
      CONTROLS_ACTIONS_EXPORT: "Export contact profiles",
      CONTROLS_ACTIONS_IMPORT: "Import contact profiles",
      HEAD_FIRST_NAME: "Full Name",
      HEAD_LOCATION: "Location",
      HEAD_COMPANY: "Company",
      HEAD_SEGMENTS: "Segments",
      HEAD_LAST_ACTIVE: "Last Active",
      HEAD_SCORE: "Score",
      ROW_UNKNOWN: "Unknown",
      ROW_NO_SEGMENTS: "No segments",
      ROW_ACTIVE_ONLINE: "Active now",
      ROW_ACTIVE_SINCE: "Since",
      EMPTY_NO_CONTACT_DEFAULT_TITLE: "You have no contact.",
      EMPTY_NO_CONTACT_DEFAULT_LABEL:
        "Contacts are stored from conversations, or can be created and even imported.",
      EMPTY_NO_CONTACT_SEARCH_TITLE: "No contact was found for your search.",
      EMPTY_NO_CONTACT_SEARCH_LABEL:
        "You can find contacts by name, email, country, company and more.",
      BANNER_LIMIT_ALMOST_REACHED_TITLE:
        "You have almost reached the CRM contacts limit for your plan. Please consider increasing it to keep adding new contacts.",
      BANNER_LIMIT_ALMOST_REACHED_ACTION: "Increase Limits",
      BANNER_LIMIT_REACHED_TITLE:
        "You have hit CRM contacts limits for your plan. No new contact will be created until you increase them.",
      BANNER_LIMIT_REACHED_ACTION: "Increase Limits",
      BANNER_IMPORT_TITLE:
        "Already have contacts in a different CRM? Import all your contacts in Crisp",
      BANNER_IMPORT_ACTION: "Import Contacts",
      POPUPS_EXPORT_PROFILES_TITLE:
        "You are going to export your contact database",
      POPUPS_EXPORT_PROFILES_NOTICE:
        "It will take a few minutes. You will get an email when the export is over.",
      POPUPS_EXPORT_PROFILES_PROCEED: "Export Contacts",
      POPUPS_REMOVE_CONTACTS_TITLE: "The selected contacts will be removed",
      POPUPS_REMOVE_CONTACTS_NOTICE:
        "Data for those contacts will be permanently deleted.",
      POPUPS_REMOVE_CONTACTS_PROCEED: "Remove Selected Contacts",
      POPUPS_DESTROY_CONTACTS_TITLE:
        "All the searched contacts will be removed",
      POPUPS_DESTROY_CONTACTS_NOTICE:
        "Data for those contacts will be permanently deleted. Make sure your search filter is correct.",
      POPUPS_DESTROY_CONTACTS_PROCEED: "Remove All Searched Contacts",
      TITLE: "Contacts",
      HEAD_EMAIL: "Email",
      ROW_MORE_SEGMENTS: "View all segments",
      ROW_PREVIEW: "Preview",
      POPUPS_UPDATE_FILTER_TITLE: "Do you want to update this filter?",
      POPUPS_UPDATE_FILTER_LABEL:
        "The filter will be updated for everyone in your team.",
      POPUPS_UPDATE_FILTER_PROCEED: "Update Filter",
    },
    PROFILE: {
      ACTIVE_NOW: "Active now",
      ACTIVE_LAST: "Last active:",
      ALERT_EMAIL_NOT_SUBSCRIBED_LABEL:
        "unsubscribed from all emails sent via Crisp (using the unsubscribe link in emails).",
      ALERT_EMAIL_NOT_SUBSCRIBED_ACTION: "Re-subscribe this contact now?",
      BOXES: {
        CONTACT: {
          NAME: "Contact Information for",
          ENTER_VALUE: "Enter",
          FIELD_NAME: "Name",
          FIELD_EMAIL: "Email",
          FIELD_PHONE: "Phone",
          FIELD_ADDRESS: "Address",
          FIELD_WEBSITE: "Website",
          FIELD_GENDER: "Gender",
          FIELD_GENDER_PLACEHOLDER: "Select a gender",
          FIELD_GENDER_MALE: "Male",
          FIELD_GENDER_FEMALE: "Female",
          FIELD_SUBSCRIPTION: "Notifications",
          FIELD_SUBSCRIPTION_ENABLED: "Enabled",
          FIELD_SUBSCRIPTION_DISABLED: "Disabled",
          FIELD_CREATED_AT: "Creation date",
          FIELD_UPDATED_AT: "Last update",
        },
        CONVERSATIONS: {
          NAME: "Conversations with",
          EMPTY: "No conversations found.",
          ACTION: "New Conversation",
        },
        COMPANY: {
          NAME_1: "Company",
          NAME_2: "Works For",
          ENTER_VALUE: "Enter",
          FIELD_COMPANY: "Company",
          FIELD_JOB_TITLE: "Job Title",
          FIELD_JOB_ROLE: "Job Role",
          FIELD_WEBSITE: "Website",
          FIELD_CITY: "City",
          FIELD_COUNTRY: "Country",
          FIELD_EMPLOYEES: "Employees",
        },
        DEVICES: {
          NAME: "Devices for",
          EMPTY: "No devices available.",
          ON: "on",
        },
        SEGMENTS: {
          NAME: "Segments for",
          FORM_PLACEHOLDER: "Segment contact…",
        },
        LOCATION: {
          NAME: "Last Reported Location",
          LEGEND_CITY: "City, Country",
          LEGEND_TIME: "Local Time",
          LEGEND_LANGUAGES: "Spoken Languages",
          EMPTY: "No locations available.",
        },
        NOTEPAD: {
          NAME: "Private Notepad on",
          ACTION_CANCEL: "Cancel changes",
          ACTION_SAVE: "Save changes",
          TEXT_PLACEHOLDER: "Write your private notes…",
        },
        DATA: {
          NAME: "Data for",
          ACTION: "Add Data",
          EMPTY: "No data added.",
          FORM_KEY_PLACEHOLDER: "Enter key…",
          FORM_VALUE_PLACEHOLDER: "Enter value…",
          HELP_LABEL: "Visitor data can be set there and from chatbox.",
          HELP_LEARN: "Learn how",
        },
        RATINGS: {
          NAME: "Rating Scores from",
          LEGEND_SCORE: "Score",
          LEGEND_COMMENT: "Comment",
          VIEW_CONVERSATION: "View conversation",
          EMPTY: "No ratings submitted.",
          COMMENT_EMPTY: "No comment submitted.",
        },
        PAGES: {
          NAME_1: "Pages",
          NAME_2: "visited recently",
          PAGES_VISITED: "Pages visited",
          TIME_ON_WEBSITE: "Time on website",
          EMPTY: "No pages browsed.",
          MORE: "Display all pages",
        },
        EVENTS: {
          NAME: "Recent events for",
          EMPTY: "No events added.",
          DATA_OPEN: "view data",
          DATA_CLOSE: "hide data",
          HELP_LABEL: "Visitor events are sent from chatbox.",
          HELP_LEARN: "Learn how",
        },
        CAMPAIGNS: {
          NAME: "Campaigns for",
          EMPTY: "No campaigns found.",
          STATE_OPENED: "opened",
          STATE_CLICKED: "clicked",
          STATE_UNSUBSCRIBED: "unsubscribed",
        },
      },
      GO_TO_FULL: "Open full profile",
      MESSAGE: "Send a message",
      STATUS: {
        ONLINE: "Active",
        AWAY: "Inactive",
        OFFLINE: "Offline",
        BLOCKED: "Blocked",
      },
    },
  },
  CAMPAIGNS: {
    COMMON: {
      NEW_CAMPAIGN: "New Campaign",
      NEW_TEMPLATE: "New Template",
      SAVE_CAMPAIGN_DRAFT: "Save Draft",
      SAVE_CAMPAIGN: "Save",
      TEST_CAMPAIGN: "Receive Test",
      CONFIGURE_CAMPAIGN: "Configure",
      SEND_GO_CAMPAIGN: "Finish",
      SEND_RESUME_CAMPAIGN: "Resume",
      SEND_ACTIVATE_CAMPAIGN: "Activate",
      SEND_PAUSE_CAMPAIGN: "Pause",
      SHOW_ANALYTICS: "Show Analytics",
      BREADCRUMB_RECIPIENTS: "Recipients",
      BREADCRUMB_CONDITIONS: "Conditions",
      BREADCRUMB_TEMPLATES: "Templates",
      BREADCRUMB_EDITOR: "Editor",
      BREADCRUMB_ANALYTICS: "Analytics",
      BACK: "Back",
      NEXT: "Save & Next",
      DOCUMENTATION: "Documentation",
    },
    DEFAULT: {
      CONTROLS_NAME: "Campaigns",
      CONTROLS_ACTIONS: "Actions",
      CONTROLS_ACTIONS_CAMPAIGNS_REMOVE: "Remove selected campaigns",
      CONTROLS_ACTIONS_TEMPLATES_REMOVE: "Remove selected templates",
      CONTROLS_ACTIONS_CAMPAIGNS_DUPLICATE: "Duplicate selected campaigns",
      CONTROLS_ACTIONS_TEMPLATES_DUPLICATE: "Duplicate selected templates",
      FILTERS_TYPES_TITLE: "Types",
      FILTERS_TYPES_ALL: "All Types",
      FILTERS_TYPES_ONE_SHOT: "One-Shot",
      FILTERS_TYPES_AUTOMATED: "Automated",
      FILTERS_TAGS_TITLE: "Tags",
      FILTERS_TAGS_ALL: "All Tags",
      FILTERS_TEMPLATES_TITLE: "Templates",
      FILTERS_TEMPLATES_ALL: "All Templates",
      FILTERS_TEMPLATES_STATIC: "Crisp Templates",
      FILTERS_TEMPLATES_CUSTOM: "Your Templates",
      HEAD_CAMPAIGN_NAME: "Campaign Name",
      HEAD_STATUS: "Status",
      HEAD_CONTACTS: "Contacts",
      HEAD_LAST_UPDATE: "Last Update",
      ROW_SECTION_AUTOMATED: "Automated Campaigns",
      ROW_SECTION_ONE_SHOT: "One-Shot Campaigns",
      ROW_NAME_EDIT: "Edit name",
      ROW_NAME_TAG: "Edit tag",
      ROW_STATUS_NOT_CONFIGURED: "Not configured",
      ROW_STATUS_READY_SEND: "Ready to send",
      ROW_STATUS_READY_ACTIVATE: "Ready to activate",
      ROW_STATUS_SENDING: "Sending…",
      ROW_STATUS_ACTIVE: "Active",
      ROW_STATUS_PREPARING: "Preparing…",
      ROW_STATUS_INACTIVE: "Inactive",
      ROW_STATUS_PAUSED: "Paused",
      ROW_STATUS_DONE: "Done sending",
      ROW_RECIPIENTS_LABEL: "recipients",
      ROW_REACHED_LABEL: "reached",
      EMPTY_NO_CAMPAIGN_DEFAULT_TITLE: "You have no campaign.",
      EMPTY_NO_CAMPAIGN_DEFAULT_LABEL:
        "Campaigns are used to send emails to targeted contacts. Create your first one!",
      EMPTY_NO_CAMPAIGN_SEARCH_TITLE: "No campaign was found for your search.",
      EMPTY_NO_CAMPAIGN_SEARCH_LABEL: "You can find campaigns by name.",
      EMPTY_NO_TEMPLATE_DEFAULT_TITLE: "There are no templates.",
      EMPTY_NO_TEMPLATE_DEFAULT_LABEL:
        "Templates are used to create campaign models that can be used later on.",
      EMPTY_NO_TEMPLATE_SEARCH_TITLE: "No template was found for your search.",
      EMPTY_NO_TEMPLATE_SEARCH_LABEL: "You can find templates by name.",
      POPUPS_REMOVE_CAMPAIGNS_TITLE: "The selected campaigns will be removed",
      POPUPS_REMOVE_CAMPAIGNS_NOTICE:
        "Those campaigns will be permanently deleted.",
      POPUPS_REMOVE_CAMPAIGNS_PROCEED: "Remove Selected Campaigns",
      POPUPS_REMOVE_TEMPLATES_TITLE: "The selected templates will be removed",
      POPUPS_REMOVE_TEMPLATES_NOTICE:
        "Those templates will be permanently deleted.",
      POPUPS_REMOVE_TEMPLATES_PROCEED: "Remove Selected Templates",
      POPUPS_DUPLICATE_CAMPAIGNS_TITLE:
        "The selected campaigns will be duplicated",
      POPUPS_DUPLICATE_CAMPAIGNS_NOTICE:
        "A copy of each selected campaign will be created. If you selected many campaigns, this could take some time.",
      POPUPS_DUPLICATE_CAMPAIGNS_TOO_MANY:
        "Too many campaigns were selected. You can duplicate a maximum of {maximum} campaigns at a time.",
      POPUPS_DUPLICATE_CAMPAIGNS_PROCEED: "Duplicate Selected Campaigns",
      POPUPS_DUPLICATE_TEMPLATES_TITLE:
        "The selected templates will be duplicated",
      POPUPS_DUPLICATE_TEMPLATES_NOTICE:
        "A copy of each selected campaign will be created. If you selected many templates, this could take some time.",
      POPUPS_DUPLICATE_TEMPLATES_TOO_MANY:
        "Too many templates were selected. You can duplicate a maximum of {maximum} templates at a time.",
      POPUPS_DUPLICATE_TEMPLATES_PROCEED: "Duplicate Selected Templates",
      POPUPS_CUSTOM_DOMAIN_TITLE: "Initialize campaigns",
      POPUPS_CUSTOM_DOMAIN_NOTICE:
        "To be able to send campaigns, you need to configure a custom email domain like emails.acme.com in your website settings. Using a custom subdomain will provide better deliverability to your emails.",
      POPUPS_CUSTOM_DOMAIN_NOTICE_1:
        "Configuring a Custom Email Domain is a bit technical, but we made some documentation to help you on this. We recommend checking our documentation before going to the next step.",
      POPUPS_CUSTOM_DOMAIN_NOTICE_2: "read our help article",
      POPUPS_CUSTOM_DOMAIN_PROCEED: "Configure Email Domain",
      POPUPS_VERIFY_WEBSITE_TITLE: "Verify your account",
      POPUPS_VERIFY_WEBSITE_NOTICE:
        "To be able to send email campaigns, we need to verify your company documents. Process is made to protect our users against spam and preserve your email reputation.",
      POPUPS_VERIFY_WEBSITE_NOTICE_1:
        "No worries! This process is fast. Contact our customer support using the following button. Our team will need your company documents and will ask a question questions.",
      POPUPS_VERIFY_WEBSITE_PROCEED: "Verify my account",
      POPUPS_REPLACE_TEMPLATE_TITLE: "The campaign already has a message",
      POPUPS_REPLACE_TEMPLATE_NOTICE:
        "Selecting a new template will permanently overwrite the existing campaign message.",
      POPUPS_REPLACE_TEMPLATE_PROCEED: "Replace Template and Message",
      HEAD_ACTIONS: "Actions",
    },
    EDIT: {
      PICKER_ALL: "Send to all contacts",
      PICKER_PEOPLE: "Send to selected contacts",
      PICKER_SEGMENT: "Send to a segment of contacts",
      PICKER_FILTER: "Send to a contact filter…",
      SEARCH_PLACEHOLDER_PEOPLE: "Search in all contacts…",
      STATISTICS_BACK: "Back",
      STATISTICS_UNKNOWN: "Unknown",
      STATISTICS_VIEW_PROFILE: "View Profile",
      STATISTICS_EMPTY_TITLE: "There is no statistics (yet).",
      STATISTICS_EMPTY_LABEL:
        "Users who opened or clicked a link in the campaign will appear there (applies to email campaigns only).",
      STATISTICS_HEAD_FIRST_NAME: "Full Name",
      STATISTICS_HEAD_LOCATION: "Location",
      STATISTICS_HEAD_DATE: "Date",
      EDITOR_SUBJECT_PLACEHOLDER: "Enter a subject for the campaign…",
      EDITOR_SUBJECT_HELP: "Campaign subject (visible to recipients)",
      EDITOR_TAG_NAME_FULL_LABEL: "Profile full name",
      EDITOR_TAG_NAME_FIRST_LABEL: "Profile first name",
      EDITOR_TAG_NAME_LAST_LABEL: "Profile last name",
      EDITOR_TAG_EMAIL_LABEL: "Profile email",
      EDITOR_TAG_COUNTRY_LABEL: "Profile country",
      EDITOR_TAG_CITY_LABEL: "Profile city",
      EDITOR_TAG_WEBSITE_LABEL: "Profile website URL",
      EDITOR_TAG_COMPANY_NAME_LABEL: "Profile company name",
      EDITOR_TAG_DATA_LABEL: "Profile data (on sub-key to configure)",
      EDITOR_TARGETS_NONE: "This message targets nobody",
      EDITOR_TARGETS_ALL: "This message targets all your contacts",
      EDITOR_TARGETS_CONTACTS_1: "This message targets",
      EDITOR_TARGETS_CONTACTS_2: "contacts",
      EDITOR_OPTION_DELIVER_TO: "Send in:",
      EDITOR_OPTION_DELIVER_TO_CHATBOX: "Chat",
      EDITOR_OPTION_DELIVER_TO_EMAIL: "Email",
      EDITOR_OPTION_MANAGE_TEMPLATES: "Templates",
      EDITOR_OPTION_EDIT_OPTIONS: "Options",
      EDITOR_SEND_AS: "Send as:",
      EDITOR_TARGETS: "Recipients:",
      EDITOR_TARGETS_TOO_COMPLEX:
        "The count of recipients is too complex to fetch in due time.",
      EDITOR_HELP_MAIN_MARKDOWN:
        "Campaign message is formatted with Markdown. Markdown is easy to use.",
      EDITOR_HELP_MAIN_HTML:
        "Campaign message is HTML-formatted. You may use the code editor for advanced editing.",
      EDITOR_HELP_DOCS: "find the docs here",
      POPUPS_SEND_CAMPAIGN_QUESTION: "Send this campaign now?",
      POPUPS_SEND_CAMPAIGN_LABEL:
        "**{recipients} recipients** will get the campaign message delivered to their {destination}.\\n\\nNote that your campaign **will start sending slowly**, and **then speed up**. Some campaigns might get reviewed by our moderation team, which ensures deliverability is maximized for all our users.",
      POPUPS_SEND_CAMPAIGN_LABEL_CHATBOX: "chatbox",
      POPUPS_SEND_CAMPAIGN_LABEL_EMAIL: "inbox",
      POPUPS_SEND_CAMPAIGN_TOO_MANY_RECIPIENTS_1:
        "Your campaign is currently rejected because the amount of recipients is above the limits of your plan:",
      POPUPS_SEND_CAMPAIGN_TOO_MANY_RECIPIENTS_2: "read our help article",
      POPUPS_SEND_CAMPAIGN_PROCEED: "Send Campaign",
      POPUPS_SEND_CAMPAIGN_PROCEEDING: "Sending Campaign",
      POPUPS_SEND_CAMPAIGN_PREPARING: "Sending in {seconds}s",
      POPUPS_ACTIVATE_CAMPAIGN_QUESTION: "Activate this campaign now?",
      POPUPS_ACTIVATE_CAMPAIGN_LABEL:
        "This campaign will start listening for user events now.",
      POPUPS_ACTIVATE_CAMPAIGN_PROCEED: "Activate Campaign",
      POPUPS_TOGGLE_FORMAT_QUESTION: "Toggle campaign content format?",
      POPUPS_TOGGLE_FORMAT_LABEL:
        "The format will be toggled between Markdown and HTML. You will lose the current content.",
      POPUPS_TOGGLE_FORMAT_PROCEED: "Toggle Format",
      POPUPS_MANAGE_TEMPLATES_QUESTION: "Manage your campaign templates",
      POPUPS_MANAGE_TEMPLATES_LABEL:
        "You can save the current content as a template to reuse later on, or insert a saved template there.",
      POPUPS_MANAGE_TEMPLATES_SAVE_TITLE: "Save content as template",
      POPUPS_MANAGE_TEMPLATES_SAVE_PLACEHOLDER: "Give a name to the template…",
      POPUPS_MANAGE_TEMPLATES_SAVE_CREATE: "Save",
      POPUPS_MANAGE_TEMPLATES_LIST_TITLE: "Saved templates",
      POPUPS_MANAGE_TEMPLATES_EMPTY:
        "No saved templates. They might still be loading…",
      POPUPS_MANAGE_TEMPLATES_PROCEED: "Finish",
      POPUPS_EDIT_OPTIONS_TRACKING:
        "Track when campaign is open or a link is clicked",
      POPUPS_EDIT_OPTIONS_SENDER_NAME_WEBSITE:
        "Append website name to sender name",
      POPUPS_EDIT_OPTIONS_SENDER_EMAIL_REPLY_LABEL: "Reply-to email (if any)",
      POPUPS_EDIT_OPTIONS_SENDER_EMAIL_REPLY_PLACEHOLDER:
        "Enter reply-to email (can be blank)",
      POPUPS_EDIT_OPTIONS_PROCEED: "Edit Options",
      POPUPS_PREVIEW_RECIPIENTS_QUESTION: "Preview campaign recipients",
      POPUPS_PREVIEW_RECIPIENTS_LABEL:
        "Those are the recipients the campaign will be sent to. Some may have unsubscribed from emails, and may not receive it.",
      POPUPS_PREVIEW_RECIPIENTS_ERROR:
        "Recipients could not be previewed. Most likely your campaign filter took too much time to compute. Try again later.",
      POPUPS_PREVIEW_RECIPIENTS_EMPTY:
        "This campaign does not target any recipient.",
      POPUPS_PREVIEW_RECIPIENTS_MORE: "Show more recipients",
      POPUPS_PREVIEW_RECIPIENTS_SUBSCRIBED: "Subscribed: will receive campaign",
      POPUPS_PREVIEW_RECIPIENTS_UNSUBSCRIBED:
        "Unsubscribed: will not receive campaign",
      EDITOR_ERROR_LABEL:
        "This message is too long. You will be able to send it but it will be truncated.",
      EDITOR_ERROR_MARKED: "characters max.",
      EDITOR_NOTICE_ONE_SHOT_NOT_READY:
        "This campaign is not yet ready to be sent. Add a message, pick a sender and add recipients.",
      EDITOR_NOTICE_ONE_SHOT_DISPATCHED:
        "This campaign has been dispatched. You cannot edit it anymore.",
      EDITOR_NOTICE_AUTOMATED_NOT_READY:
        "This campaign is not yet ready to be activated. Add a message, and configure the delivery flow.",
      EDITOR_NOTICE_AUTOMATED_DEACTIVATED:
        "This campaign is deactivated. It will not be triggering anymore until you activate it.",
      TITLE: "Compose and send your campaign",
    },
    RECIPIENTS: {
      TITLE: "Select your campaign recipients",
    },
    FLOW: {
      TITLE: "Setup how your automated campaign triggers",
      FORM_NOTICE_DOCS: "read docs",
      PART_1_TITLE: "Launch On User Event",
      PART_1_FORM_FIELD_EVENT_LABEL: "Event",
      PART_1_FORM_FIELD_EVENT_PLACEHOLDER: "Event name…",
      PART_1_FORM_NOTICE_1: "Learn what user events are",
      PART_1_FORM_NOTICE_2: ", and how to use them here.",
      PART_2_TITLE: "Assert Filter Conditions",
      PART_2_FORM_FIELD_DELAY_LABEL: "Filter after minutes",
      PART_2_FORM_NOTICE_1: "Filter conditions are used to assert",
      PART_2_FORM_NOTICE_2: "whether to send campaign",
      PART_2_FORM_NOTICE_3: "based on user data filters.",
      PART_3_TITLE: "Delivery Settings",
      PART_3_FORM_FIELD_ONCE_LABEL: "Send once per contact",
      PART_3_FORM_FIELD_DELAY_LABEL: "Send after minutes",
      PART_4_TITLE: "Message Gets Sent To Contact",
    },
    TEMPLATES: {
      TITLE: "Choose an HTML template",
      BOX_TITLE: "Your available templates",
      BOX_DESCRIPTION:
        "We listed here all the HTML templates you saved for a later use. To create a new HTML template or use Markdown instead, you can skip this step.",
      TEMPLATE_ACTION: "Use this template",
      BASIS_UNSUBSCRIBE: "Unsubscribe",
      PREVIEW_OPTIONS_TO_LABEL: "To:",
      PREVIEW_OPTIONS_TO_VALUE: "john.doe{\\'@\\'}gmail.com",
      PREVIEW_OPTIONS_REPLY_TO_LABEL: "Reply-To:",
      USE_MARKDOWN: "Ignore this step",
    },
    TEMPLATE: {
      NAME_PLACEHOLDER: "Enter a name for the template…",
      EDITOR_NOTICE_DEFAULT:
        "This is a default template provided by Crisp. You cannot edit it.",
      EDITOR_HELP_MAIN_MARKDOWN:
        "Template is formatted with Markdown. Markdown is easy to use.",
      EDITOR_HELP_MAIN_HTML:
        "Template is HTML-formatted. You may use the code editor for advanced editing.",
      ACTION_SAVE: "Save Template",
    },
    ANALYTICS: {
      TITLE: "Monitor the statistics of your campaign",
      OPENED: "Email Opened",
      CLICKED: "Link Clicked",
      UNSUBSCRIBED: "Unsubscribed",
      ROW_PROFILE_CONTACT: "Profile",
      ROW_PROFILE_ACTIONS_LINK_URL: "Open clicked link: {link}",
      TIPS_TITLE: "Tips for better stats:",
      TIPS_IP_REPUTATION_WEAK:
        "Sending IP reputation is poor, are a lot of people unsubscribing?",
      TIPS_ASSIGNED_IP_UPGRADE:
        "Upgrade to an assigned IP to improve opening rates",
      TIPS_LINK_NOT_CLICKED:
        "Few people who opened the campaign clicked on a link",
      TIPS_EMAIL_JUST_SENT:
        "You just sent this campaign, statistics may still be empty",
      TIPS_EMAIL_STILL_SENDING:
        "This campaign is still being sent, statistics may still be empty",
    },
  },
  HELPDESK: {
    COMMON: {
      NEW_ARTICLE: "New Article",
      NEW_CATEGORY: "New Category",
      DOCUMENTATION: "Documentation",
    },
    DEFAULT: {
      CONTROLS_NAME: "Helpdesk",
      CONTROLS_ACTIONS_ARTICLE_REMOVE: "Remove selected articles",
      CONTROLS_ACTIONS_CATEGORY_SECTION_REMOVE: "Remove selected sections",
      CONTROLS_ACTIONS_ADD_LOCALE: "Add a new language",
      CONTROLS_ACTIONS_EXPORT_ARTICLES: "Export articles",
      CONTROLS_ACTIONS_IMPORT_ARTICLES: "Import articles",
      CONTROLS_ACTIONS_DESTROY_LOCALE: "Destroy current language",
      CONTROLS_ACTIONS_VIEW_HELPDESK: "View my Helpdesk",
      CONTROLS_ACTIONS: "Actions",
      FILTERS_CATEGORY_ARTICLES_TITLE: "Articles",
      FILTERS_CATEGORY_CATEGORIES_TITLE: "Categories",
      FILTERS_STATUS_TITLE: "Status",
      FILTERS_STATUS_PUBLISHED: "Published",
      FILTERS_STATUS_DRAFT: "Draft",
      FILTERS_VISIBILITY_VISIBLE: "Visible",
      FILTERS_VISIBILITY_HIDDEN: "Hidden",
      HEAD_ARTICLES_TITLE: "Article Title",
      HEAD_ARTICLES_STATUS: "Status",
      HEAD_ARTICLES_STATISTICS: "Statistics",
      HEAD_CATEGORIES_NAME: "Name",
      HEAD_CATEGORIES_STATISTICS: "Statistics",
      HEAD_LAST_UPDATE: "Last Update",
      ROW_SECTION_ARTICLES_NO_CATEGORY: "No category",
      ROW_SECTION_ARTICLES_ARTICLES: "articles",
      ROW_SECTION_CATEGORIES_NO_DESCRIPTION: "No description.",
      ROW_SECTION_CATEGORIES_ADD: "Add Section",
      ROW_ARTICLES_STATUS_ONLINE: "Online",
      ROW_ARTICLES_STATUS_HIDDEN: "Hidden",
      ROW_ARTICLES_STATUS_DRAFT: "Draft",
      ROW_ARTICLES_VISITS_LABEL: "visits",
      ROW_ARTICLES_EDIT: "Edit Article",
      ROW_CATEGORIES_SECTION_LABEL: "Section",
      ROW_CATEGORIES_ARTICLES_LABEL: "articles",
      ROW_CATEGORIES_EDIT: "Edit Section",
      ROW_DATE_CREATED: "Created:",
      ROW_VIEW: "View",
      EMPTY_NO_ARTICLE_DEFAULT_TITLE: "There are no articles.",
      EMPTY_NO_ARTICLE_DEFAULT_LABEL:
        "Create your first article. Write a first draft, preview it, and publish it.",
      EMPTY_NO_ARTICLE_SEARCH_TITLE: "No article was found for your search.",
      EMPTY_NO_ARTICLE_SEARCH_LABEL: "You can find articles by title.",
      EMPTY_NO_CATEGORY_DEFAULT_TITLE: "There are no categories.",
      EMPTY_NO_CATEGORY_DEFAULT_LABEL:
        "Create your first category. Give it a color and an image, and test it live!",
      EMPTY_NO_CATEGORY_SEARCH_TITLE: "No category was found for your search.",
      EMPTY_NO_CATEGORY_SEARCH_LABEL: "You can find categories by name.",
      POPUPS_INITIALIZE_TITLE: "Getting Started with your Helpdesk",
      POPUPS_INITIALIZE_STEP_INTRO: "Intro",
      POPUPS_INITIALIZE_STEP_CONFIGURE: "Configure",
      POPUPS_INITIALIZE_STEP_LOCALE: "Locale",
      POPUPS_INITIALIZE_STEP_IMPORT: "Import",
      POPUPS_INITIALIZE_SLIDESHOW_STEP_EDUCATE_TITLE:
        "Educate your Customers with Crisp",
      POPUPS_INITIALIZE_SLIDESHOW_STEP_EDUCATE_DESCRIPTION:
        "Create your own Help Center with Crisp and reduce your amount of customer support requests",
      POPUPS_INITIALIZE_SLIDESHOW_STEP_BROWSE_TITLE:
        "Browse all your FAQ articles right from your Crisp Inbox and send the right anwser in an instant",
      POPUPS_INITIALIZE_CONFIGURE_TITLE:
        "Let\\'s configure your Knowledge Base",
      POPUPS_INITIALIZE_CONFIGURE_DESCRIPTION:
        "You are few steps away from using your Knowledge Base.",
      POPUPS_INITIALIZE_LOCALE_TITLE: "Configure your helpdesk locale",
      POPUPS_INITIALIZE_LOCALE_DESCRIPTION:
        "Crisp Knowledge Base is fully multilingual. Get started by configuring your default helpdesk locale.",
      POPUPS_INITIALIZE_DONE_TITLE: "You are almost done!",
      POPUPS_INITIALIZE_DONE_DESCRIPTION:
        "Using another helpdesk provider? No problem! You can import all articles from your previous provider in a single click.",
      POPUPS_INITIALIZE_ACTION_CONFIGURE: "Configure my Knowledge Base",
      POPUPS_INITIALIZE_ACTION_CREATE: "Create my Knowledge Base",
      POPUPS_INITIALIZE_ACTION_IMPORT: "Import from a Previous provider",
      POPUPS_INITIALIZE_ACTION_CREATE_ARTICLE: "Create my First Article",
      POPUPS_INITIALIZE_OR: "Or",
      POPUPS_INITIALIZE_NOTICE:
        "Your helpdesk needs to be initialized before it can be used.",
      POPUPS_INITIALIZE_PROCEED: "Go To Helpdesk Settings",
      POPUPS_ADD_LOCALE_TITLE: "Add a language to your helpdesk",
      POPUPS_ADD_LOCALE_NOTICE:
        "Your helpdesk can be multilingual, and requires at least one locale.",
      POPUPS_ADD_LOCALE_LOCALE_LABEL: "Select a language",
      POPUPS_ADD_LOCALE_LOCALE_ENTICE:
        "(You will be able to configure other locales later)",
      POPUPS_ADD_LOCALE_PROCEED: "Add Language",
      POPUPS_DESTROY_LOCALE_TITLE: "Destroy current language?",
      POPUPS_DESTROY_LOCALE_NOTICE:
        "Please read the following and then confirm to destroy: **{locale}**",
      POPUPS_DESTROY_LOCALE_LOCALE_LABEL: "Confirm language removal",
      POPUPS_DESTROY_LOCALE_LOCALE_PLACEHOLDER: "Type language name:",
      POPUPS_DESTROY_LOCALE_EMPHASIS_1:
        "All helpdesk data (articles, categories) for this language will be lost and non-recoverable.",
      POPUPS_DESTROY_LOCALE_EMPHASIS_2:
        "There is no coming back after you press destroy.",
      POPUPS_DESTROY_LOCALE_CONFIRM:
        "Are you sure to destroy the current language?",
      POPUPS_DESTROY_LOCALE_PROCEED: "Destroy Language",
      POPUPS_EXPORT_HELPDESK_TITLE:
        "You are going to export your helpdesk articles",
      POPUPS_EXPORT_HELPDESK_NOTICE:
        "It will take a few minutes. You will get an email when the export is over.",
      POPUPS_EXPORT_HELPDESK_PROCEED: "Export Articles",
      POPUPS_NEW_ARTICLE_TITLE: "Add an article to your helpdesk",
      POPUPS_NEW_ARTICLE_NOTICE:
        "The article will be added to the helpdesk language:",
      POPUPS_NEW_ARTICLE_TITLE_LABEL: "Title of the article",
      POPUPS_NEW_ARTICLE_TITLE_PLACEHOLDER: "Enter a title for your article…",
      POPUPS_NEW_ARTICLE_PROCEED: "Create Article",
      POPUPS_NEW_CATEGORY_TITLE: "Add a category to your helpdesk",
      POPUPS_NEW_CATEGORY_NOTICE:
        "The category will be added to the helpdesk language:",
      POPUPS_NEW_CATEGORY_NAME_LABEL: "Name of the category",
      POPUPS_NEW_CATEGORY_NAME_PLACEHOLDER: "Enter a name for your category…",
      POPUPS_NEW_CATEGORY_PROCEED: "Create Category",
      POPUPS_NEW_SECTION_TITLE: "Add a section to the category",
      POPUPS_NEW_SECTION_NOTICE:
        "The section will be added to the category: {category}",
      POPUPS_NEW_SECTION_NAME_LABEL: "Name of the section",
      POPUPS_NEW_SECTION_NAME_PLACEHOLDER: "Enter a name for your section…",
      POPUPS_NEW_SECTION_PROCEED: "Create Section",
      POPUPS_REMOVE_ARTICLES_TITLE: "Remove selected articles?",
      POPUPS_REMOVE_ARTICLES_NOTICE:
        "Please read the following and then confirm to destroy: **{selected} articles**",
      POPUPS_REMOVE_ARTICLES_EMPHASIS_1:
        "All selected articles will be lost and non-recoverable.",
      POPUPS_REMOVE_ARTICLES_PROCEED: "Remove Selected Articles",
      POPUPS_REMOVE_CATEGORIES_TITLE: "Remove selected categories?",
      POPUPS_REMOVE_CATEGORIES_NOTICE:
        "Please read the following and then confirm to destroy: **{selected} categories**",
      POPUPS_REMOVE_CATEGORIES_EMPHASIS_1:
        "All selected categories will be lost and non-recoverable. Note that it also removes contained sections, but it does not remove contained articles.",
      POPUPS_REMOVE_CATEGORIES_PROCEED: "Remove Selected Categories",
      POPUPS_REMOVE_SECTIONS_TITLE: "Remove selected sections?",
      POPUPS_REMOVE_SECTIONS_NOTICE:
        "Please read the following and then confirm to destroy: **{selected} sections**",
      POPUPS_REMOVE_SECTIONS_EMPHASIS_1:
        "All selected sections will be lost and non-recoverable. Note that it does not remove contained articles.",
      POPUPS_REMOVE_SECTIONS_PROCEED: "Remove Selected Sections",
      POPUPS_EDIT_CATEGORY_NAME_LABEL: "Category name",
      POPUPS_EDIT_CATEGORY_NAME_PLACEHOLDER: "Enter a category name…",
      POPUPS_EDIT_CATEGORY_DESCRIPTION_LABEL: "Category description",
      POPUPS_EDIT_CATEGORY_DESCRIPTION_PLACEHOLDER:
        "Enter a category description…",
      POPUPS_EDIT_CATEGORY_COLOR_LABEL: "Category color",
      POPUPS_EDIT_CATEGORY_COLOR_PLACEHOLDER: "Color code (eg. #000000)",
      POPUPS_EDIT_CATEGORY_IMAGE_LABEL: "Category image",
      POPUPS_EDIT_CATEGORY_PROCEED: "Save Category",
      POPUPS_EDIT_CATEGORY_ORDER_LABEL: "Category order index",
      POPUPS_EDIT_CATEGORY_ORDER_PLACEHOLDER: "Index (eg. 1, 2, etc.)",
      POPUPS_EDIT_SECTION_NAME_LABEL: "Section name",
      POPUPS_EDIT_SECTION_NAME_PLACEHOLDER: "Enter a section name…",
      POPUPS_EDIT_SECTION_ORDER_LABEL: "Section order index",
      POPUPS_EDIT_SECTION_ORDER_PLACEHOLDER: "Index (eg. 1, 2, etc.)",
      POPUPS_EDIT_SECTION_PROCEED: "Save Section",
    },
    ARTICLE: {
      EDITOR_TITLE_PLACEHOLDER: "Enter a title for the article…",
      EDITOR_NOTICE_DRAFT:
        "This article is still a draft. It is not yet visible on your helpdesk, until you publish it.",
      EDITOR_NOTICE_HIDDEN:
        "This article is unpublished. It is hidden from your helpdesk, until you republish it.",
      EDITOR_HELP_MAIN:
        "Articles are formatted with Markdown. Markdown is easy to use.",
      EDITOR_HELP_DOCS: "find the docs here",
      EDITOR_CATEGORY: "Category:",
      EDITOR_CATEGORY_NONE: "No category",
      EDITOR_SETTINGS: "Options",
      EDITOR_VIEW: "View",
      EDITOR_ACTION_PUBLISH: "Publish Article",
      EDITOR_ACTION_UNPUBLISH: "Unpublish Article",
      EDITOR_ACTION_REPUBLISH: "Republish Article",
      EDITOR_ACTION_SAVE_DRAFT: "Save Draft",
      EDITOR_ACTION_SAVE: "Save",
      POPUPS_ALTERNATE_LOCALES_TITLE: "Link article to locales",
      POPUPS_ALTERNATE_LOCALES_NOTICE:
        "Select and manage translated articles to be linked to this article.",
      POPUPS_ALTERNATE_LOCALES_LOCALE_LABEL: "Language",
      POPUPS_ALTERNATE_LOCALES_NO_LOCALE: "Select a locale first",
      POPUPS_ALTERNATE_LOCALES_ARTICLE_LABEL: "Article",
      POPUPS_ALTERNATE_LOCALES_ARTICLE_SELECT_EMPTY: "(no article linked)",
      POPUPS_ALTERNATE_LOCALES_CONSOLIDATE_QUESTION:
        "This article is linked to {alternates_count} other article(s). Do you want to add them as well?",
      POPUPS_ALTERNATE_LOCALES_CONSOLIDATE_ANSWER_YES: "Yes",
      POPUPS_ALTERNATE_LOCALES_CONSOLIDATE_ANSWER_NO: "Don\\'t add them",
      POPUPS_ALTERNATE_LOCALES_PROCEED: "Save Linked Locales",
      POPUPS_EDIT_ARTICLE_CATEGORY_CATEGORIES_LABEL: "Category for article",
      POPUPS_EDIT_ARTICLE_CATEGORY_SECTIONS_LABEL: "Section in category",
      POPUPS_EDIT_ARTICLE_CATEGORY_FIELD_CATEGORY_NONE:
        "No category (unclassified)",
      POPUPS_EDIT_ARTICLE_CATEGORY_FIELD_SECTION_NONE:
        "No section (unclassified)",
      POPUPS_EDIT_ARTICLE_CATEGORY_PROCEED: "Edit Category",
      POPUPS_EDIT_ARTICLE_SETTINGS_FEATURED_LABEL:
        "Feature this article (in listings and search)",
      POPUPS_EDIT_ARTICLE_SETTINGS_ORDER_LABEL: "Article order index",
      POPUPS_EDIT_ARTICLE_SETTINGS_ORDER_PLACEHOLDER: "Index (eg. 1, 2, etc.)",
      POPUPS_EDIT_ARTICLE_SETTINGS_DESCRIPTION_LABEL:
        "Article description (can be used for SEO)",
      POPUPS_EDIT_ARTICLE_SETTINGS_DESCRIPTION_PLACEHOLDER:
        "Description text… (can be left empty)",
      POPUPS_EDIT_ARTICLE_SETTINGS_PROCEED: "Edit Settings",
    },
  },
  FILTERS: {
    DATE: {
      MONTHS: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      DAYS: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ],
      POINTS: {
        NOW: "Now",
        SECOND: "Second",
        MINUTE: "Minute",
        HOUR: "Hour",
        DAY: "Day",
        YESTERDAY: "Yesterday",
      },
    },
  },
  BASES: {
    COMMANDS: {
      SELECTOR_KEYS_NAVIGATE: "to navigate",
      SELECTOR_KEYS_SELECT: "to select",
      SELECTOR_KEYS_EXIT: "to exit",
    },
    HEADER: {
      CONTROLS_SEARCH: "Search",
    },
    DROPDOWN_ITEM: {
      SHARE_AUDIO_RECORDING_MAIN: "Recording…",
      SHARE_AUDIO_RECORDING_SECONDS: "seconds",
    },
    UPGRADE: {
      LABEL: "Upgrade",
      GO_FROM_TOUR: "Upgrade to {plan_name}",
    },
    PRICE: {
      MONTH: "mth",
      YEAR: "yr",
    },
  },
  FORMS: {
    FIELD_SELECT: {
      FILTER: "Filter choices…",
      LOADING: "Loading choices…",
    },
    FIELD_FILE: {
      ACTION: "Select or drag file",
      INVALID: "File is invalid",
      ACTION_REMOVE: "Remove this file",
    },
    FIELD_TIME: {
      TO: "to",
      ADD: "Add",
    },
    FIELD_DURATION: {
      MONTHS: "Months",
      WEEKS: "Weeks",
      DAYS: "Days",
      HOURS: "Hours",
    },
    FIELD_KEY_VALUE: {
      FIELD_PLACEHOLDER_OBJECT_KEY: "Data key",
      FIELD_PLACEHOLDER_OBJECT_VALUES: "Data values (comma-separated)",
    },
    FIELD_COLOR_PICKER: {
      SEE_MORE: "See more",
    },
  },
  BUTTONS: {
    CANCEL: "Cancel",
    CLOSE: "Close",
  },
  RESTRICTS: {
    FILE: {
      TITLE: "Send any file to this chat",
      LABEL: "Send images, documents, etc.",
    },
    SHORTCUTS: {
      TITLE: "Configure message shortcuts",
      LABEL: "Save time with quick replies",
    },
    NOTES: {
      TITLE: "Share private note messages",
      LABEL: "Pin information for your team",
    },
    BROWSING: {
      TITLE: "See your visitor screen",
      LABEL: "With MagicBrowse",
    },
    CALL: {
      TITLE: "Call your visitors",
      LABEL: "Video & audio calls",
    },
    BLOCK: {
      TITLE: "Block spammers",
      LABEL: "Blocking based on IP",
    },
    PROFILE: {
      TITLE: "See visitor social profiles",
      LABEL: "Facebook, Twitter, etc.",
    },
    IP: {
      TITLE: "See visitor IP",
      LABEL: "Useful for legal & debug",
    },
    HISTORY: {
      TITLE: "Get unlimited message history",
      LABEL: "Recover this old message",
    },
    AUDIO: {
      TITLE: "Send audio messages",
      LABEL: "Avoid writing long messages",
    },
    HELPDESK: {
      TITLE: "Setup your helpdesk",
      LABEL: "Publish an helpdesk website",
    },
    REMINDERS: {
      TITLE: "Schedule reminders",
      LABEL: "Remind you about things",
    },
  },
  NOTIFICATIONS: {
    MENTIONED: "You have been mentioned",
    ASSIGNED: "You have been assigned",
    NOTE: "A note has been left",
  },
  BANNERS: {
    OFFLINE_NOTICE: "Shh! You are currently seen as offline by your visitors.",
    TIMEZONE_NOTICE:
      "Oops! It seems your configured timezone is different than your current location\\'s timezone ({timezone}).",
    CHANGE_AVAILABILITY: "Configure my availability",
    CHANGE_TIMEZONE: "Configure my timezone",
    RECONNECT_NOTICE_MAIN: "Oops, Crisp is disconnected.",
    RECONNECT_NOTICE_WILL_PROCEED: "No worries, I\\'m attempting to reconnect…",
    RECONNECT_NOTICE_PROCEEDING: "Reconnecting…",
    NOTIFICATION_NOTICE:
      "Notifications are disabled in your browser. Please enable them in order to be notified of new messages.",
    ENABLE_NOTIFICATION: "Enable notifications",
    RELOAD_CRISP: "Reload Crisp",
    SAFE_MODE_NOTICE:
      "For technical reasons your dashboard is limited to Messaging.",
    NEW_VERSION_NOTICE:
      "We just updated Crisp to bring fixes and features. Reload it to use the latest version.",
    TRIAL_EXPIRE_SOON_NOTICE:
      "Your Crisp trial expires soon. You may upgrade today to keep all features.",
    TRIAL_EXPIRE_SOON_SELECT: "Choose my Crisp plan",
    PLAN_RECENTLY_EXPIRED_NOTICE:
      "Your Crisp subscription has expired. Upgrade your plan to restore all features.",
    PLAN_RECENTLY_EXPIRED_SELECT: "Update my Crisp plan",
    PLAN_SANDBOX_NOTICE:
      "Some Crisp features may be limited, as your website is currently in sandbox mode. You should only use it for development purpose.",
    SEE_HELP: "Why am I seeing this?",
    SEE_CHANGELOG: "See new changes",
    USAGE_QUOTA_ALMOST_REACHED_NOTICE:
      "Your {name} quota is almost reached. You may need to upgrade your limit to continue using this feature.",
    USAGE_QUOTA_REACHED_NOTICE:
      "You reached your {name} quota. You need to upgrade your quota to continue using this feature.",
    VIEW_LIMIT_QUOTA: "View quota",
  },
  SPOTLIGHT: {
    FORM_FIELD_PLACEHOLDER: "Search (messages, contacts, helpdesk, plugins)",
    FORM_ACTIONS_CLEAR: "Clear",
    HISTORY_TITLE: "Latest searches",
    HISTORY_ITEM_FORGET: "Forget",
    RESULTS_SECTIONS_ALL: "All",
    RESULTS_SECTIONS_MESSAGES: "Messages",
    RESULTS_SECTIONS_CONTACTS: "Contacts",
    RESULTS_SECTIONS_HELPDESK: "Helpdesk",
    RESULTS_SECTIONS_PLUGINS: "Plugins",
    RESULTS_CONTENTS_ITEMS_TITLE_SINGULAR: "result",
    RESULTS_CONTENTS_ITEMS_TITLE_PLURAL: "results",
    RESULTS_CONTENTS_ITEMS_TITLE_TOTAL_SINGULAR: "total result",
    RESULTS_CONTENTS_ITEMS_TITLE_TOTAL_PLURAL: "total results",
    RESULTS_CONTENTS_ITEMS_ACTIONS_NEXT: "See next results",
    RESULTS_CONTENTS_FILTERS_TITLE: "Filter by",
    RESULTS_CONTENTS_FILTERS_DATE_NAME: "Date",
    RESULTS_CONTENTS_FILTERS_DATE_CLEAR: "Clear date filter",
    RESULTS_CONTENTS_FILTERS_DATE_START: "From",
    RESULTS_CONTENTS_FILTERS_DATE_END: "To",
    RESULTS_CONTENTS_EMPTY:
      "Sorry, no search results were found. Try with another keyword.",
  },
  ALERT: {
    ACTION_GO: "Open action link",
    DISCARD_ONE: "Discard",
    DISCARD_ALL: "Discard all alerts",
    EMPTY: {
      TITLE: "No alert",
      DESCRIPTION: "All alerts have been read. New alerts will appear there.",
    },
    TABS: {
      FEED: "Feed",
      UPDATES: "Updates",
    },
    FEED: {
      WEBSITE_AGENT_LIMIT_REACHED: {
        TITLE: "Agent limit reached",
        DESCRIPTION:
          "{website_name} has reached its agent limit. You need to upgrade to add more agents.",
      },
      BILLING_PAYMENT_METHOD_EXPIRE: {
        TITLE: "Payment method expiring",
        DESCRIPTION:
          "Your payment card is expiring. To keep your active subscriptions, please update your payment method.",
      },
      BILLING_PAYMENT_ACTION: {
        TITLE: "Action to renew subscriptions",
        DESCRIPTION:
          "Your bank is asking that you validate the payment to renew your Crisp subscriptions, due to 3-D Secure. Please click on this link.",
      },
      BILLING_PAYMENT_ERROR: {
        TITLE: "Failed renewing subscriptions",
        DESCRIPTION:
          "Your plan could not be renewed because the payment failed. Please check your payment information.",
      },
      MESSAGE_REMINDER: {
        TITLE: "Reminder fired",
        DESCRIPTION: "A scheduled reminder has fired ({reminder_note}).",
      },
      WEBSITE_EXPORT_COMPLETE: {
        TITLE: "Export complete",
        DESCRIPTION:
          "Your export is now complete. You may download it now. The download link will expire in a day.",
      },
      HELPDESK_IMPORT_COMPLETE: {
        TITLE: "Helpdesk import complete",
        DESCRIPTION: "Your helpdesk is now ready to use.",
      },
      WEBSITE_SUBSCRIPTIONS_DEACTIVATED: {
        TITLE: "Subscriptions deactivated",
        DESCRIPTION:
          "Your Crisp subscriptions have been deactivated because all payment trials failed, or your website has no payment method.",
      },
      WEBSITE_PLAN_TRIAL_ENDED: {
        TITLE: "Crisp trial ended",
        DESCRIPTION:
          "You are now using Crisp Basic, because your Crisp Unlimited trial has just ended, and your website has no payment method.",
      },
      WEBSITE_AGENT_PURGED: {
        TITLE: "Access to website revoked",
        DESCRIPTION:
          "{website_name} had too many agents. You have been removed from {website_name} to match the website plan agent limits.",
      },
      WEBSITE_CERTIFICATE_GENERATION_FAILURE: {
        TITLE: "Certificate generation failed",
        DESCRIPTION:
          "Certificate for **{domain}** could not be generated, which will result in security errors on this domain. Please contact our support to fix this issue.",
      },
      WEBSITE_CERTIFICATE_RENEWAL_FAILURE: {
        TITLE: "Certificate renewal failed",
        DESCRIPTION:
          "Certificate for **{domain}** could not be renewed, which means this domain might not be reachable anymore soon. Please contact our support to fix this issue.",
      },
    },
  },
  EDITOR: {
    MODE_CODE: "Content",
    MODE_PREVIEW: "Preview",
    POPUPS_INSERT_COLOR_FIELD_LABEL_COLOR: "Text color",
    POPUPS_INSERT_COLOR_FIELD_COLOR_PLACEHOLDER: "Color code (eg. #000000)",
    POPUPS_INSERT_COLOR_FIELD_LABEL_LABEL: "Text content",
    POPUPS_INSERT_COLOR_FIELD_LABEL_PLACEHOLDER: "Enter text to color…",
    POPUPS_INSERT_COLOR_PROCEED: "Insert Colored Text",
    POPUPS_INSERT_CODE_PROCEED: "Insert Code",
    POPUPS_INSERT_CODE_FIELD_VARIANT_LABEL: "Type of code",
    POPUPS_INSERT_CODE_FIELD_VARIANT_OPTION_INLINE: "Inline code (single line)",
    POPUPS_INSERT_CODE_FIELD_VARIANT_OPTION_BLOCK: "Code block (multiline)",
    POPUPS_INSERT_CODE_FIELD_LANGUAGE_LABEL: "Programming language",
    POPUPS_INSERT_LINK_FIELD_TYPE_MODE_URL: "External URL",
    POPUPS_INSERT_LINK_FIELD_TYPE_MODE_HELPDESK: "Helpdesk article",
    POPUPS_INSERT_LINK_FIELD_LABEL_LABEL: "Link label",
    POPUPS_INSERT_LINK_FIELD_LABEL_PLACEHOLDER: "Enter the link label…",
    POPUPS_INSERT_LINK_FIELD_URL_LABEL: "Link URL",
    POPUPS_INSERT_LINK_FIELD_URL_PLACEHOLDER: "Enter the link URL…",
    POPUPS_INSERT_LINK_FIELD_LOCALE_LABEL: "Language",
    POPUPS_INSERT_LINK_FIELD_ARTICLE_LABEL: "Article",
    POPUPS_INSERT_LINK_PROCEED: "Insert Link",
    POPUPS_INSERT_IMAGE_FIELD_LABEL_LABEL: "Image label",
    POPUPS_INSERT_IMAGE_FIELD_LABEL_PLACEHOLDER: "Enter the image label…",
    POPUPS_INSERT_IMAGE_PROCEED: "Insert Image",
    POPUPS_EDIT_IMAGE_FIELD_URL_LABEL: "Image URL",
    POPUPS_EDIT_IMAGE_FIELD_URL_PLACEHOLDER: "Enter the image URL…",
    POPUPS_EDIT_IMAGE_FIELD_WIDTH_LABEL: "Width (in pixels)",
    POPUPS_EDIT_IMAGE_FIELD_HEIGHT_LABEL: "Height (in pixels)",
    POPUPS_EDIT_IMAGE_FIELD_ALIGN_LABEL: "Align",
    POPUPS_EDIT_IMAGE_FIELD_RATIO_LABEL: "Preserve ratio",
    POPUPS_EDIT_IMAGE_FIELD_ALIGN_OPTION_LEFT: "To the left",
    POPUPS_EDIT_IMAGE_FIELD_ALIGN_OPTION_CENTER: "To the center",
    POPUPS_EDIT_IMAGE_FIELD_ALIGN_OPTION_RIGHT: "To the right",
    POPUPS_EDIT_IMAGE_PROCEED: "Edit Image",
    POPUPS_INSERT_GIF_FIELD_SEARCH_PLACEHOLDER: "Search for GIFs…",
    POPUPS_INSERT_GIF_PROCEED: "Insert GIF",
    POPUPS_INSERT_VIDEO_FIELD_LABEL_LABEL: "Video label",
    POPUPS_INSERT_VIDEO_FIELD_LABEL_PLACEHOLDER: "Enter the video label…",
    POPUPS_INSERT_VIDEO_FIELD_URL_LABEL: "YouTube video URL",
    POPUPS_INSERT_VIDEO_FIELD_URL_PLACEHOLDER: "Enter the YouTube URL…",
    POPUPS_INSERT_VIDEO_PROCEED: "Insert Video",
    POPUPS_INSERT_FILE_FIELD_TEXT_LABEL: "File link text",
    POPUPS_INSERT_FILE_FIELD_TEXT_PLACEHOLDER:
      "Enter the text for the file link…",
    POPUPS_INSERT_FILE_PROCEED: "Insert File",
    POPUPS_INSERT_REPLACEMENT_FIELD_TAG_LABEL: "Replacement tag",
    POPUPS_INSERT_REPLACEMENT_FIELD_FALLBACK_LABEL: "Fallback value (optional)",
    POPUPS_INSERT_REPLACEMENT_FIELD_FALLBACK_PLACEHOLDER:
      "Enter a fallback value…",
    POPUPS_INSERT_REPLACEMENT_PROCEED: "Insert Replacement Tag",
    WIDGETS_LINK_EDIT: "Edit",
  },
  CHATBOX_SETUP: {
    SLIDESHOW: {
      WORDPRESS: {
        "1_TITLE": "Go to your Wordpress Admin Panel",
        "1_DESCRIPTION": "And then go to Plugins > Add New.",
        "2_TITLE": "Search & Install Crisp",
        "2_DESCRIPTION":
          "Type Crisp in the search console and then install the plugin.",
        "3_TITLE": "Activate Crisp",
        "3_DESCRIPTION": "Once Crisp is installed, click activated.",
        "4_TITLE": "Link Crisp",
        "4_DESCRIPTION": "Install Crisp on your WordPress site.",
      },
      WEBFLOW: {
        "1_TITLE": "Go to your Webflow Admin Panel",
        "1_DESCRIPTION": "And then expend the settings menu.",
        "2_TITLE": "Go to Settings",
        "2_DESCRIPTION": "You will be redirected to the settings section.",
        "3_TITLE": "Click custom code",
        "3_DESCRIPTION": "And then paste the Crisp widget code.",
        "4_TITLE": "Publish Changes",
        "4_DESCRIPTION": "Publish changes to your Webflow domains.",
      },
      WIX: {
        "1_TITLE": "Select your Wix site",
        "1_DESCRIPTION": "And then click Select & Edit site.",
        "2_TITLE": "Go to Settings",
        "2_DESCRIPTION": "You will be redirected to the settings section.",
        "3_TITLE": "Click custom code",
        "3_DESCRIPTION": "You will be redirected to the custom code section.",
        "4_TITLE": "Click Add Code",
        "4_DESCRIPTION": "Add your code in the HEAD section.",
        "5_TITLE": "Paste the Crisp Widget code",
        "5_DESCRIPTION": "And don\\'t forget saving!",
      },
      SQUARESPACE: {
        "1_TITLE": "Go to your Squarespace Admin Panel",
        "1_DESCRIPTION": "And then go to Settings.",
        "2_TITLE": "Go to Settings",
        "2_DESCRIPTION": "You will be redirected to the advanced section.",
        "3_TITLE": "Click code injection",
        "3_DESCRIPTION":
          "This available is only available from Squarespace paid plans.",
        "4_TITLE": "Paste the Crisp widget code",
        "4_DESCRIPTION": "And don\\'t forget saving!",
      },
      JOOMLA: {
        "1_TITLE": "Go to your Joomla Admin Panel",
        "1_DESCRIPTION": "And then go to System.",
        "2_TITLE": "Go to Extensions",
        "2_DESCRIPTION": "Then to Install from Web.",
        "3_TITLE": "Search for Crisp",
        "3_DESCRIPTION": "From the Install from Web tab.",
        "4_TITLE": "Install Crisp",
        "4_DESCRIPTION": "From the Install from Web tab.",
        "5_TITLE": "Install Crisp",
        "5_DESCRIPTION": "Click on the green Install button.",
        "6_TITLE": "Go to the Crisp module",
        "6_DESCRIPTION": "Click System and then Crisp.",
        "7_TITLE": "Link Crisp with your Joomla",
        "7_DESCRIPTION": "Click Connect with Crisp.",
      },
    },
  },
};
