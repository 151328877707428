<template>
  <div
    :class="[
      'c-base-illustration-picker',
      {
        'c-base-illustration-picker--selected': selected,
      },
    ]"
    v-on:click="(...e) => onClick(...e)"
  >
    <BaseIllustration
      v-if="illustration"
      :name="illustration"
      :class="[
        'c-base-illustration-picker__illustration',
        {
          [illustrationClass]: illustrationClass,
        },
      ]"
    ></BaseIllustration>
    <div class="c-base-illustration-picker__content">
      <span class="c-base-illustration-picker__title u-bold">{{ title }}</span>
      <span class="c-base-illustration-picker__description">
        {{ description }}
      </span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    description: {
      type: String,
      required: !0,
    },
    id: {
      type: String,
      required: !0,
    },
    illustration: {
      type: String,
      default: null,
    },
    illustrationClass: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      required: !0,
    },
    selected: {
      type: Boolean,
      default: !1,
    },
  },
  emits: ["click"],
  methods: {
    onClick() {
      this.$emit("click", this.id);
    },
  },
};
</script>
<style lang="scss">
.c-base-illustration-picker {
  width: 100%;
  height: 196px;
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: 8px;
  border: 2px solid #e3e8ef;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  -webkit-transition-property:
    -webkit-transform,
    -webkit-box-shadow;
  transition-property:
    -webkit-transform,
    -webkit-box-shadow;
  transition-property: transform, box-shadow;
  transition-property:
    transform,
    box-shadow,
    -webkit-transform,
    -webkit-box-shadow;
  margin-right: 24px;
  .c-base-illustration-picker__illustration {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
  }
  .c-base-illustration-picker__content {
    padding: 0 22px 0;
    .c-base-illustration-picker__description {
      color: #1c293b;
      display: inline-block;
      font-size: 13.5px;
      line-height: 17px;
      margin-top: 10px;
    }
    .c-base-illustration-picker__title {
      color: #1c293b;
      display: inline-block;
      font-size: 16px;
      margin-top: 100px;
    }
  }
  &:last-of-type {
    margin-right: 0;
  }
  &:hover {
    -webkit-transform: scale(1.03);
    transform: scale(1.03);
  }
}
.c-base-illustration-picker--selected {
  border-color: #0093ee;
  -webkit-box-shadow: 0 3px 6px 0 rgba(52, 74, 97, 0.1);
  box-shadow: 0 3px 6px 0 rgba(52, 74, 97, 0.1);
  -webkit-transform: scale(1.03);
  transform: scale(1.03);
}
</style>
