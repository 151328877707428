<template>
  <div
    v-on:click="(...e) => onClick && onClick(...e)"
    :class="[
      'c-base-card',
      'c-base-card--' + alignment,
      {
        ['c-base-card--' + illustration]: illustration,
        'c-base-card--active': active && clickable,
        'c-base-card--clickable': clickable,
        'c-base-card--disabled': disabled,
      },
    ]"
  >
    <base-illustration
      v-if="illustration"
      class="c-base-card__illustration"
      :name="illustration"
    ></base-illustration>
    <div class="c-base-card__title">{{ title }}</div>
    <div v-if="$slots.default" class="c-base-card__slot">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: !1,
    },
    alignment: {
      type: String,
      default: "center",
    },
    clickable: {
      type: Boolean,
      default: !0,
    },
    disabled: {
      type: Boolean,
      default: !1,
    },
    id: {
      type: String,
      required: !0,
    },
    illustration: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      required: !0,
    },
  },
  emits: ["click"],
  methods: {
    onClick(e) {
      this.clickable && !this.disabled && this.$emit("click", this.id, e);
    },
  },
};
</script>
<style lang="scss">
.c-base-card {
  padding: 20px 18px;
  border-radius: 2px;
  background-color: #fff;
  border: 1px solid #e3e8ef;
  text-align: center;
  -webkit-transition: all 0.1s linear;
  transition: all 0.1s linear;
  -webkit-transition-property: border-color, background-color;
  transition-property: border-color, background-color;
  .c-base-card__illustration {
    margin-bottom: 10px;
    max-height: 60px;
    width: 105px;
  }
  .c-base-card__title {
    color: #000;
    font-weight: 700;
    font-size: 15px;
    line-height: 17px;
  }
  .c-base-card__slot {
    margin-top: 10px;
  }
  &:not(.c-base-card--active) {
    &:hover {
      border-color: #c9d3e0;
    }
    &:active {
      border-color: #afbdd1;
    }
  }
}
.c-base-card--users_all {
  .c-base-card__illustration {
    height: 48px;
    margin-bottom: 12px;
    margin-top: 9px;
  }
}
.c-base-card--users_filter {
  .c-base-card__illustration {
    height: 59px;
  }
}
.c-base-card--users_selected {
  .c-base-card__illustration {
    height: 59px;
  }
}
.c-base-card--users_segment {
  .c-base-card__illustration {
    height: 48px;
    margin-bottom: 12px;
    margin-top: 9px;
  }
}
.c-base-card--left {
  text-align: left;
}
.c-base-card--center {
  text-align: center;
}
.c-base-card--right {
  text-align: right;
}
.c-base-card--active {
  background-color: #fafbfc;
  border-color: #0093ee;
}
.c-base-card--clickable {
  cursor: pointer;
}
.c-base-card--disabled {
  opacity: 0.75;
  cursor: not-allowed;
}
</style>
