import tenant from "./tenant";

class Analytics {
  analyticsBase(tenantID) {
    return tenant.tenantOne(tenantID).one("analytics");
  }

  consult({ tenantID, namespace }) {
    return this.analyticsBase(tenantID).one(namespace);
  }
}

export default new Analytics();
