<template>
  <div
    :class="[
      'c-base-banner',
      'c-base-banner--' + color,
      'c-base-banner--' + size,
    ]"
    :style="{
      textAlign: textAlign,
    }"
  >
    <BaseIcon
      v-if="hasClose && !loading"
      class="c-base-banner__close"
      v-on:click="onClose"
      color="white"
      cursor="pointer"
      name="close"
      size="18px"
    />
    <BaseSpinner
      v-if="loading"
      class="c-base-banner__spinner"
      color="white"
      size="14px"
      border-width="2px"
      speed="1500ms"
    />
    <div
      :class="[
        'c-base-banner__content',
        {
          'u-ellipsis': singleLine,
        },
      ]"
    >
      {{ content }}
    </div>
    <BaseButton
      v-if="action"
      class="c-base-banner__action"
      :onClick="onActionClick"
      :loading="isActionPending"
      size="small"
      color="white"
      reverse=""
    >
      {{ action }}
    </BaseButton>
  </div>
</template>
<script>
export default {
  props: {
    action: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: "blue",
      validator(e) {
        return (
          -1 !==
          [
            "blue",
            "green",
            "grey",
            "black",
            "orange",
            "purple",
            "red",
            "turquoise",
            "yellow",
          ].indexOf(e)
        );
      },
    },
    content: {
      type: String,
      required: !0,
    },
    singleLine: {
      type: Boolean,
      default: !0,
    },
    hasClose: {
      type: Boolean,
      default: !0,
    },
    loading: {
      type: Boolean,
      default: !1,
    },
    isActionPending: {
      type: Boolean,
      default: !1,
    },
    size: {
      type: String,
      default: "normal",
      validator(e) {
        return -1 !== ["small", "normal", "medium", "large"].indexOf(e);
      },
    },
    textAlign: {
      type: String,
      default: "left",
    },
  },
  emits: ["actionClick", "close"],
  methods: {
    onActionClick() {
      this.$emit("actionClick");
    },
    onClose() {
      this.hasClose && this.$emit("close");
    },
  },
};
</script>
<style lang="scss">
.c-base-banner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 20px;
  .c-base-banner__close {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    margin-top: -2px;
    margin-right: 15px;
    opacity: 0.75;
    -webkit-transition: opacity 0.1s linear;
    transition: opacity 0.1s linear;
    &:hover {
      opacity: 1;
    }
  }
  .c-base-banner__spinner {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    margin-top: -2px;
    margin-right: 15px;
  }
  .c-base-banner__content {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    margin-top: -2px;
    color: #fff;
    font-weight: 700;
  }
  .c-base-banner__action {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    margin-left: 14px;
  }
}
.c-base-banner--blue {
  background-color: #0093ee;
}
.c-base-banner--green {
  background-color: #40b630;
}
.c-base-banner--grey {
  background-color: #75869c;
}
.c-base-banner--black {
  background-color: #3f536e;
}
.c-base-banner--orange {
  background-color: #fd7b1f;
}
.c-base-banner--purple {
  background-color: #ab7df6;
}
.c-base-banner--red {
  background-color: #e0102b;
}
.c-base-banner--turquoise {
  background-color: #26c1c9;
}
.c-base-banner--yellow {
  background-color: #faca00;
}
.c-base-banner--small {
  height: 52px;
  .c-base-banner__content {
    font-size: 14px;
  }
}
.c-base-banner--normal {
  height: 56px;
  .c-base-banner__content {
    font-size: 15px;
  }
}
.c-base-banner--medium {
  height: 60px;
  .c-base-banner__content {
    font-size: 16px;
  }
}
.c-base-banner--large {
  height: 64px;
  .c-base-banner__content {
    font-size: 17px;
  }
}
</style>
