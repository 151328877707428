<template>
  <div class="c-field-date-time-picker">
    <div v-if="label" class="c-field-date-time-picker__header">
      <FieldLabel class="c-field-date-time-picker__label">{{
        label
      }}</FieldLabel>
    </div>
    <div class="c-field-date-time-picker__wrapper">
      <FieldDatePicker
        class="u-mb20"
        :modelValue="date"
        v-on:update:modelValue="(e) => (date = e)"
        v-on:change="onDateChange"
        size="medium"
      ></FieldDatePicker>
      <FieldTimePicker
        class="u-mb20"
        :modelValue="time"
        v-on:update:modelValue="(e) => (time = e)"
        v-on:change="onTimeChange"
      ></FieldTimePicker>
    </div>
  </div>
</template>
<script>
const a = 0;
const o = 0;
export default {
  props: {
    label: {
      type: String,
      default: null,
    },
    modelValue: {
      type: Date,
      default: null,
    },
  },
  emits: ["update:modelValue", "change"],
  data() {
    return {
      formattedDate: null,
      date: null,
      time: {
        hours: null,
        minutes: null,
      },
    };
  },
  watch: {
    modelValue: {
      deep: !0,
      immediate: !0,
      handler(e) {
        e &&
          ((this.date = this.convertToDate(e)),
          (this.time = this.convertToTime(e)));
      },
    },
  },
  methods: {
    convertToDate(e) {
      return new Date(e.toDateString());
    },
    convertToTime(e) {
      const t = e.getHours(),
        i = e.getMinutes();
      return {
        hours: 0 === t ? a : t,
        minutes: 0 === i ? o : i,
      };
    },
    getFormattedDate() {
      let e = null;
      return (
        this.date &&
          ((e = this.date),
          this.time &&
            (e.setHours(this.time.hours),
            e.setMinutes(this.time.minutes),
            e.setSeconds(0))),
        e
      );
    },
    onDateChange(e) {
      (this.date = this.convertToDate(e)),
        (this.formattedDate = this.getFormattedDate()),
        this.$emit("update:modelValue", this.formattedDate),
        this.$emit("change", this.formattedDate);
    },
    onTimeChange(e) {
      (this.time = e),
        (this.formattedDate = this.getFormattedDate()),
        this.$emit("update:modelValue", this.formattedDate),
        this.$emit("change", this.formattedDate);
    },
  },
};
</script>
<style lang="scss">
.c-field-date-time-picker {
  color: #2c405a;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  .c-field-date-time-picker__header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    .c-field-date-time-picker__label {
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
    }
  }
  .c-field-date-time-picker__wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    .c-field-date-picker {
      -webkit-box-flex: 2;
      -ms-flex: 2;
      flex: 2;
      z-index: 30;
    }
    .c-field-time-picker {
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
      margin-left: 20px;
    }
  }
}
</style>
