import $env from "@/enviroment.js";
import * as o from "./platform.js";
import * as n from "@amplitude/analytics-browser";

class _analytics {
  constructor() {
    (this.__uniqueEvents = {}),
      $env.CRISP_TOKEN_AMPLITUDE && (0, n.init)($env.CRISP_TOKEN_AMPLITUDE);
  }
  loadHotjar() {
    $env.CRISP_TOKEN_HOTJAR && window.LOAD_HOTJAR && window.LOAD_HOTJAR();
  }
  logEvent(e, t, i = !1) {
    if ($env.CRISP_TOKEN_AMPLITUDE) {
      if (i && this.__uniqueEvents[e]) return;
      (t && "object" === typeof t) || (t = {}),
        (t.platform = $env.CRISP_INTERNAL_PLATFORM_NAME),
        (t.version = $env.CRISP_APP_VERSION),
        (t.is_platform = o.default.isPlatform()),
        i && !this.__uniqueEvents[e] && (this.__uniqueEvents[e] = !0),
        (0, n.logEvent)(e, t);
    }
  }
  setUserId(e) {
    $env.CRISP_TOKEN_AMPLITUDE && (0, n.setUserId)(e);
  }
  setUserProperties(e = {}) {
    if ($env.CRISP_TOKEN_AMPLITUDE) {
      let t = new n.Identify();
      for (let i in e) t.set(i, e[i]);
      (0, n.identify)(t);
    }
  }
  setGroup(e = "", t = "") {
    $env.CRISP_TOKEN_AMPLITUDE && (0, n.setGroup)(e, t);
  }
  sendGAEvent(e, t) {
    "undefined" !== typeof window.ga && window.ga("send", "event", e, t);
  }
}

export default new _analytics();
