<template>
  <div
    :class="[
      'c-base-color',
      'c-base-color--' + size,
      {
        'c-base-color--bordered': bordered,
        'c-base-color--rounded': rounded,
      },
    ]"
    :style="{
      backgroundColor: color,
    }"
  >
    <BaseIcon
      v-if="icon"
      class="c-base-color__icon"
      :name="icon"
      size="18px"
    ></BaseIcon>
  </div>
</template>
<script>
export default {
  props: {
    bordered: {
      type: Boolean,
      default: !1,
    },
    color: {
      type: String,
      required: !0,
    },
    icon: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: "default",
      validator(e) {
        return -1 !== ["default", "medium", "large"].indexOf(e);
      },
    },
    rounded: {
      type: Boolean,
      default: !1,
    },
  },
};
</script>
<style lang="scss">
.c-base-color {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  .c-base-color__icon {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    fill: #fff;
  }
}
.c-base-color--default {
  width: 12px;
  height: 12px;
  border-radius: 2px;
}
.c-base-color--medium {
  width: 28px;
  height: 28px;
  border-radius: 3px;
}
.c-base-color--large {
  width: 32px;
  height: 32px;
  border-radius: 4px;
}
.c-base-color--bordered {
  border: 1px solid #e3e8ef;
}
.c-base-color--rounded {
  border-radius: 100px;
}
</style>
