const isProduction = process.env.NODE_ENV === "production";
const LOG_LEVELS = ["log", "info", "warn", "error"];
import $env from "@/enviroment";

class Loggy {
  constructor(options) {
    if (localStorage) {
      let __defaultLogLevel =
        localStorage.getItem($env.TALQUI_LOGGY_KEY) !== null
          ? localStorage.getItem($env.TALQUI_LOGGY_KEY)
          : isProduction
          ? "error"
          : "log";
      localStorage.setItem($env.TALQUI_LOGGY_KEY, "" + __defaultLogLevel);
    }

    this.$options = {
      isEnabled: true,
      logLevel:
        (localStorage && localStorage.getItem($env.TALQUI_LOGGY_KEY)) ||
        "error",
      ...options,
    };

    this.allowedMethods = LOG_LEVELS;
    let _idx = LOG_LEVELS.findIndex((item) => item === this.$options.logLevel);
    if (_idx > 0) {
      this.allowedMethods = LOG_LEVELS.slice(_idx);
    }
  }

  log(...params) {
    this.allowedMethods.includes("log") && console.log(...params);
  }

  info(...params) {
    this.allowedMethods.includes("info") && console.info(...params);
  }

  warn(...params) {
    this.allowedMethods.includes("warn") && console.warn(...params);
  }

  error(...params) {
    this.allowedMethods.includes("error") && console.error(...params);
  }
}

class Logger {
  constructor() {
    this.loggy = new Loggy();
  }

  log(...params) {
    this.loggy.log(...params);
  }

  info(...params) {
    this.loggy.info(...params);
  }

  warn(...params) {
    this.loggy.warn(...params);
  }

  error(...params) {
    this.loggy.error(...params);
  }

  init(app) {
    app.config.globalProperties.$log = this.loggy;
  }
}

export default new Logger();
