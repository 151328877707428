<template>
  <div
    :class="[
      'c-field-date-picker',
      'c-field-date-picker--' + size,
      {
        'c-field-date-picker--block': block,
      },
    ]"
  >
    <BaseIcon
      class="c-field-date-picker__icon"
      name="clock"
      size="15px"
    ></BaseIcon>
    <DatePicker
      class="c-field-date-picker__wrapper"
      v-model="currentValue"
      :disabled-dates="disabledDates"
      :is-inline="isInline"
      :is-required="isRequired"
      :max-date="maxDate"
      :min-date="minDate"
      :mode="mode"
      :popover="{
        visibility: popoverVisibility,
      }"
    >
      <template v-slot="{ inputValue, inputEvents }">
        <input
          class="c-field-date-picker__field js-field-date-picker-field"
          :value="inputValue"
          v-on="inputEvents"
          placeholder="DD/MM/YYYY"
        />
      </template>
    </DatePicker>
  </div>
</template>
<script>
import "v-calendar/dist/style.css";
import { DatePicker } from "v-calendar";
export default {
  components: {
    DatePicker,
  },
  props: {
    block: {
      type: Boolean,
      default: !0,
    },
    disabledDates: {
      type: [Object, Array],
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
    isInline: {
      type: Boolean,
      default: !1,
    },
    isRequired: {
      type: Boolean,
      default: !1,
    },
    maxDate: {
      type: Date,
      default: null,
    },
    minDate: {
      type: Date,
      default: null,
    },
    mode: {
      type: String,
      default: "single",
      validator(e) {
        return -1 !== ["multiple", "range", "single"].indexOf(e);
      },
    },
    popoverVisibility: {
      type: String,
      default: "click",
    },
    size: {
      type: String,
      default: "default",
      validator(e) {
        return (
          -1 !== ["mini", "small", "default", "medium", "large"].indexOf(e)
        );
      },
    },
    modelValue: {
      type: Date,
      default: null,
    },
  },
  data() {
    return {
      currentValue: "",
    };
  },
  mounted() {
    this.currentValue = JSON.parse(JSON.stringify(this.modelValue));
  },
  watch: {
    currentValue: {
      immediate: false,
      handler(e) {
        this.$emit("update:modelValue", new Date(e));
      },
    },
  },
  emits: ["update:modelValue", "change"],
  methods: {
    onInput(e) {
      this.$emit("update:modelValue", e, this.id);
      this.$emit("change", e, this.id);
    },
  },
};
</script>
<style lang="scss">
.c-field-date-picker {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #a8c6df;
  border-radius: 4px;
  background-color: #fff;
  -webkit-transition: border-color 75ms linear;
  transition: border-color 75ms linear;
  cursor: pointer;
  .c-field-date-picker__icon {
    position: absolute;
    left: 10px;
    fill: #75869c;
  }
  .c-field-date-picker__wrapper {
    width: 100%;
    .c-field-date-picker__field {
      width: 100%;
      padding: 8px 12px 8px 32px;
      outline: none;
      border: none;
      border-radius: 0;
      background-color: transparent;
      color: #2c405a;
      cursor: pointer;
      &::-webkit-input-placeholder {
        color: #75869c;
      }
      &::-moz-placeholder {
        color: #75869c;
      }
      &::-ms-input-placeholder {
        color: #75869c;
      }
      &::placeholder {
        color: #75869c;
      }
    }
  }
  .c-field-date-picker__wrapper--mini {
    input[type="text"] {
      height: 32px;
      border-radius: 2px;
      font-size: 12px;
    }
  }
  .c-field-date-picker__wrapper--small {
    input[type="text"] {
      height: 36px;
      border-radius: 3px;
      font-size: 13px;
    }
  }
  .c-field-date-picker__wrapper--default {
    input[type="text"] {
      height: 40px;
      border-radius: 4px;
      font-size: 14px;
    }
  }
  .c-field-date-picker__wrapper--medium {
    input[type="text"] {
      height: 44px;
      border-radius: 5px;
      font-size: 15px;
    }
  }
  .c-field-date-picker__wrapper--large {
    input[type="text"] {
      height: 48px;
      border-radius: 6px;
      font-size: 16px;
    }
  }
  &:hover {
    border-color: #75869c;
  }
  .vc-popover-content-wrapper {
    margin-bottom: 14px;
    margin-left: -1px;
    .vc-popover-content {
      border: 1px solid #a8c6df;
      border-radius: 2px;
      background-color: #fff;
      -webkit-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.04);
      box-shadow: 0 2px 3px rgba(0, 0, 0, 0.04);
      .vc-header {
        padding-bottom: 8px !important;
      }
      .vc-title {
        color: #000 !important;
        font-weight: 800 !important;
        font-size: 13px !important;
        margin-top: -5px !important;
      }
      .vc-nav-item {
        color: #000 !important;
        &:hover {
          color: #fff !important;
          background-color: #0079c4 !important;
        }
      }
      .vc-nav-title {
        color: #000 !important;
        &:hover {
          color: #fff !important;
          background-color: #0079c4 !important;
        }
      }
      .vc-arrow {
        &:hover {
          background-color: #f4f6f8 !important;
        }
        .vc-svg-icon {
          width: 18px !important;
          height: 18px !important;
          path {
            fill: #75869c !important;
          }
        }
      }
      .vc-nav-arrow {
        &:hover {
          background-color: #f4f6f8 !important;
        }
        .vc-svg-icon {
          width: 18px !important;
          height: 18px !important;
          path {
            fill: #75869c !important;
          }
        }
      }
      .vc-weekday {
        font-weight: 400 !important;
        font-size: 12px !important;
        opacity: 0.6 !important;
      }
      .vc-day-content {
        font-weight: 400 !important;
        font-size: 12.5px !important;
        &:hover {
          color: #fff !important;
          background-color: #0079c4 !important;
        }
      }
      .vc-highlight {
        color: #fff !important;
        background-color: #0079c4 !important;
        &:hover {
          color: #fff !important;
          background-color: #0079c4 !important;
        }
      }
      .vc-nav-item.is-active {
        color: #fff !important;
        background-color: #0079c4 !important;
      }
    }
  }
}
.c-field-date-picker--block {
  input[type="text"] {
    width: 100%;
  }
}
</style>
