import $env from "@/enviroment";
import store from "@/store/";

/**
 * Classes HTTP
 */
import operator from "@/classes/talqui/operator.js";
import tenant from "@/classes/talqui/tenant";

/**
 * Store
 */
export default {
  namespaced: !0,
  state: {
    desiredAvailability: "online",
    token: null,
    me: {},
    tenants: {},
    notificationToken: null,
  },
  getters: {
    getToken: (state) => state.token,
    getNotificationToken: (state) => state.notificationToken,
    getMe: (state) => state.me,
    getNotification: (state) =>
      state.me?.operatorSettings?.notificationSettings || {},
    getDesiredAvailability: (state) => state.desiredAvailability,
    getOperators: (state) => (tenantID) =>
      (state.tenants[tenantID] || {}).operators,
    getOperator: (state) => (tenantID, operatorID) =>
      (state.tenants[tenantID] || {}).operators.find(
        (item) => item.operatorID === operatorID,
      ),
  },
  actions: {
    logout({ commit }) {
      if (localStorage) {
        localStorage.removeItem($env.TALQUI_LOGGY_KEY);
        localStorage.removeItem($env.TALQUI_HEARTBEAT_KEY);
      }
      commit("widgetsTalqui/reset", null, { root: true });
      commit("inboxTalqui/reset", null, { root: true });
      commit("reset");
    },
    refreshToken({ commit }, { token }) {
      commit("setToken", token);
    },
    operatorLogin(
      { commit },
      { operatorEmail, operatorPassword, operatorInviteKey = null },
    ) {
      commit("setToken", null);
      return operator
        .operatorLogin()
        .post({
          operatorEmail,
          operatorPassword,
          ...(operatorInviteKey && {
            operatorInviteKey: operatorInviteKey,
          }),
        })
        .then((res) => {
          commit("setToken", res.token);
          commit("setMe", res.operator);
          return Promise.resolve(res.operator);
        });
    },
    operatorRecover({ commit }, { operatorEmail }) {
      commit("setToken", null);
      return operator
        .operatorRecover()
        .post({
          operatorEmail,
        })
        .then(() => {
          return Promise.resolve();
        });
    },
    operatorRecoverConfirm(
      { commit },
      { operatorPassword, operatorID, recoveryKey },
    ) {
      commit("setToken", null);
      return operator
        .operatorRecover()
        .patch({
          operatorPassword,
          operatorID,
          recoveryKey,
        })
        .then(() => {
          return Promise.resolve();
        });
    },
    acquireOperators({ commit }, { tenantID }) {
      commit("assertOperators", {
        tenantID: tenantID,
      });
      return tenant
        .tenantOne(tenantID)
        .one("operators")
        .get()
        .then((res) => {
          const $operators = res.operators;
          commit("setOperators", {
            tenantID: tenantID,
            operators: $operators,
          });
          return Promise.resolve($operators);
        });
    },
    acquireMe({ commit }) {
      return operator
        .operatorMe()
        .get()
        .then((res) => {
          commit("setMe", res.operator);
        });
    },
    operatorRegister(
      { commit },
      {
        operatorEmail,
        operatorPassword,
        operatorFirstname,
        operatorLastname,
        operatorPhone,
        operatorInviteKey = null,
      },
    ) {
      return operator
        .operatorBase()
        .post({
          operatorEmail,
          operatorPassword,
          operatorFirstname,
          operatorLastname,
          operatorPhone,
          ...(operatorInviteKey && {
            operatorInviteKey: operatorInviteKey,
          }),
        })
        .then((res) => {
          commit("setToken", res.token);
          commit("setMe", res.operator);
          return res.operator;
        });
    },
    operatorUpdate(
      { commit },
      {
        operatorFirstname = null,
        operatorLastname = null,
        operatorPhoto = null,
      },
    ) {
      return operator
        .operatorMe()
        .put({
          ...(operatorFirstname ? { operatorFirstname } : {}),
          ...(operatorLastname ? { operatorLastname } : {}),
          ...(operatorPhoto ? { operatorPhoto } : {}),
        })
        .then((res) => {
          commit("setMe", res.data.operator);
          return res.data.operator;
        });
    },
    operatorAvailability({ getters }, { status, time }) {
      const operatorID = getters["getMe"].operatorID;
      const tenantID = store.getters["tenantsTalqui/getTenantID"];
      const notificationToken = getters["getNotificationToken"] || null;
      return operator
        .operatorAvailability({
          tenantID,
          operatorID,
        })
        .patch({
          status,
          time,
          ...(notificationToken !== null ? { notificationToken } : {}),
        })
        .then(() => {
          return Promise.resolve();
        });
    },
    operatorNotificationUpsert(
      { getters, commit },
      { allDisabled = false, onSessionQueued = true, allowSounds = true },
    ) {
      const operatorID = getters["getMe"].operatorID;
      const tenantID = store.getters["tenantsTalqui/getTenantID"];
      return operator
        .operatorNotifications({
          tenantID,
          operatorID,
        })
        .patch({
          allDisabled,
          onSessionQueued,
          allowSounds,
        })
        .then((res) => {
          commit("setNotificationPreferences", {
            notificationSettings: res.data.notificationSettings,
          });
          return Promise.resolve();
        });
    },
  },
  mutations: {
    reset(state) {
      state.desiredAvailability = "online";
      state.token = null;
      state.me = {};
      state.tenants = {};
    },
    assertOperators(state, { tenantID }) {
      state.tenants[tenantID] ||
        (state.tenants[tenantID] = {
          operatorsAndOthers: [],
          operators: {},
          onlineOperators: {},
          onlineOperatorsLoaded: !1,
        });
    },
    setOperators(state, { tenantID, operators }) {
      state.tenants[tenantID].operators = operators;
    },
    setMe(state, value) {
      state.me = value;
    },
    setToken(state, value) {
      state.token = value;
    },
    setNotificationToken(state, value) {
      state.notificationToken = value;
    },
    setNotificationPreferences(state, { notificationSettings }) {
      let me = state.me;
      me["operatorSettings"] = me?.operatorSettings || {};
      me["operatorSettings"]["notificationSettings"] = notificationSettings;
    },
  },
};
