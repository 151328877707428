import { createApp, h } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import directivesDefault from "@/directives/default.js";
import directivesInternal from "@/directives/internal.js";
import BaseIcons from "@/initializers/base-icons.js";
import $i18n from "@/classes/i18n.js";

import initializerBases from "@/initializers/bases.js";
import vueLogHandler from "@/initializers/vueLogHandler.js";
import vueErrorHandler from "@/initializers/vueErrorHandler.js";
import $filters from "@/initializers/filters.js";
import $validator from "@/initializers/validator.js";
import env from "@/enviroment";

import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import { createGtm } from "@gtm-support/vue-gtm";
import analyticsProduct from "./classes/talqui/analyticsProduct";
analyticsProduct.init();

// import { H } from "highlight.run";

//simple-initialization
import "@/classes/interceptors.js";

//CSS
import "vue-swipe-actions/dist/vue-swipe-actions.css";
import "vue3-emoji-picker/css";
// import "leaflet/dist/leaflet.css";

const app = createApp({
  render: () => h(App),
});

/**
 * Initialize Sentry SDK
 * IMPORTANT: Using glitchtip on top of integration
 * https://failures.talqui.chat/ instance of https://glitchtip.com/
 */
if (process.env.NODE_ENV === "production") {
  Sentry.init({
    app,
    dsn: "https://670ec11c0a9c4dd5838e3efb5e15ce1f@failures.talqui.chat/1",
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: ["localhost", "talqui.chat", /^\//],
      }),
    ],
  });
}

/**
 * Define common Global Properties
 */
app.config.globalProperties.$CONFIG = Object.freeze(env);

vueErrorHandler.init(app);
vueLogHandler.init(app);
directivesDefault.init(app);
directivesInternal.init(app);
BaseIcons.init(app);
$i18n.init(app);
initializerBases.init(app);
$filters.init(app);
$validator.init(app);
app.use(router);
app.use(store);

app.use(
  createGtm({
    id: "GTM-K28DQGQG", // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
    defer: true, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
    compatibility: true, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
    enabled: process.env.NODE_ENV === "production", // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
    loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
    vueRouter: router, // Pass the router instance to automatically sync with router (optional)
    // source: 'https://customurl.com/gtm.js', // Add your own serverside GTM script
    // nonce: '2726c7f26c', // Will add `nonce` to the script tag
    // debug: true, // Whether or not display console logs debugs (optional)
    // ignoredViews: ['homepage'], // Don't trigger events for specified router names (optional)
    // trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
  }),
);

// H.init("1jdkoe52", {
//   backendUrl: "https://localhost:3000",
//   environment: "production",
//   enableStrictPrivacy: false,
// });

app.mount("#app");
