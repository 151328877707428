import http from "./http";

class _tenant {
  tenantList() {
    return http.one("tenants");
  }

  tenantOne(tenantID) {
    return http.one("tenants", tenantID);
  }

  tenantPluginList({ tenantID }) {
    return this.tenantOne(tenantID).one("plugins");
  }
}
export default new _tenant();
