<template>
  <div class="c-field-time-picker">
    <div class="c-field-time-picker__selector">
      <FieldSelect
        class="c-field-time-picker__select"
        :modelValue="modelValue.hours"
        v-on:update:modelValue="(e) => (modelValue.hours = e)"
        v-on:change="onHoursChange"
        :options="hoursOptions"
        name="hour"
        position="top"
      />

      <div class="c-field-time-picker__separator">
        <span class="c-field-time-picker__to">:</span>
      </div>

      <FieldSelect
        class="c-field-time-picker__select"
        :modelValue="modelValue.minutes"
        v-on:update:modelValue="(e) => (modelValue.minutes = e)"
        v-on:change="onMinutesChange"
        :options="minutesOptions"
        name="minute"
        position="top"
      />
    </div>
  </div>
</template>
<script>
const a = 24;
const o = 60;
export default {
  props: {
    modelValue: {
      type: Object,
      required: !0,
    },
  },
  emits: ["update:modelValue", "change"],
  data() {
    return {
      hoursOptions: [],
      minutesOptions: [],
    };
  },
  created() {
    for (let e = 0; e < a; e++) {
      this.hoursOptions.push({
        label: e < 10 ? "0" + e : e,
        value: e,
      });
    }
    for (let e = 0; e < o; e++) {
      this.minutesOptions.push({
        label: e < 10 ? "0" + e : e,
        value: e,
      });
    }
  },
  methods: {
    onHoursChange(e) {
      const t = {
        hours: e,
        minutes: this.modelValue.minutes,
      };
      this.$emit("change", t);
      this.$emit("update:modelValue", t);
    },
    onMinutesChange(e) {
      const t = {
        hours: this.modelValue.hours,
        minutes: e,
      };
      this.$emit("change", t);
      this.$emit("update:modelValue", t);
    },
  },
};
</script>
<style lang="scss">
.c-field-time-picker {
  color: #a8c6df;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  .c-field-time-picker__selector {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    .c-field-time-picker__select {
      width: 80px;
    }
    .c-field-time-picker__separator {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 0 8px;
      .c-field-time-picker__to {
        font-weight: 700;
        font-size: 15px;
        line-height: 15px;
        margin-top: -2px;
      }
    }
  }
}
</style>
