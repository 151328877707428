import startsWith from "lodash.startswith";

const n = /\r?\n|\r/g;
const r = /[\u0300-\u036f]/g;
const s = 36;

class _stringHelper {
  string(e) {
    return (
      "number" === typeof e || "boolean" === typeof e
        ? (e = e.toString())
        : "object" === typeof e && (e = JSON.stringify(e)),
      e
    );
  }
  splitAt(e, t, i) {
    return (e || "").split(t || " ")[i || 0];
  }
  startsWith(e, t) {
    return startsWith(e, t);
  }
  toHashCode(e) {
    let t = 0;
    if (e.length > 0)
      for (let i = 0; i < e.length; i++)
        (t = (t << 5) - t + e.charCodeAt(i)), (t &= t);
    return t.toString(s);
  }
  uppercase(e) {
    return e.toUpperCase();
  }
  uppercaseFirst(e) {
    return e.charAt(0).toUpperCase() + e.slice(1).toLowerCase();
  }
  lowercase(e) {
    return e.toLowerCase();
  }
  capFallback(e, t, i = 10) {
    return e && t && e.length > i ? t : e;
  }
  textToSingleLine(e) {
    return (e || "").replace(n, " ").trim();
  }
  normalize(e) {
    try {
      return e.toLowerCase().normalize("NFD").replace(r, "");
    } catch (t) {
      return e;
    }
  }
}

export default new _stringHelper();
