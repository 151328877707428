<template>
  <ValidationProvider
    v-slot="{ field, meta, errorMessage }"
    as="div"
    :modelValue="modelValue"
    v-on:update:modelValue="(e) => (modelValue = e)"
    :name="name || uuid"
    :label="validationLabel"
    :rules="rules"
    :validate-on-input="!0"
    :value="currentValue"
    :class="[
      'c-field-card',
      'c-field-card--' + direction,
      'c-field-card--' + size,
      'c-field-card--' + status,
      {
        'c-field-card--block': block,
        'c-field-card--bordered': hasBorders,
        'c-field-card--error': hasError,
        'c-field-card--focused': focused,
        'c-field-card--disabled': disabled,
        'c-field-card--loading': loading,
        'c-field-card--padding': padding,
      },
    ]"
  >
    <div class="c-field-card__header">
      <FieldLabel
        v-if="label"
        class="c-field-card__label"
        :direction="direction"
        :for-field="uuid"
        :required="rules && -1 !== rules.indexOf('required')"
        :size="size"
      >
        {{ label }}
      </FieldLabel>
    </div>

    <div class="c-field-card__wrapper">
      <div
        :class="[
          'c-field-card__container',
          {
            'c-field-card__container--error':
              errorMessage && meta.dirty && !focused,
          },
        ]"
        v-on:click="(...e) => onContainerClick(...e)"
      >
        <BaseCardProvider
          class="c-field-card__provider"
          :provider="cardType"
          size="tiny"
        ></BaseCardProvider>
        <input
          v-bind="field"
          v-on:blur="(...e) => onInputBlur(...e)"
          v-on:focus="(...e) => onInputFocus(...e)"
          v-on:input="(...e) => onInput(...e)"
          v-on:keyup="(...e) => onInputKeyup(...e)"
          :autocomplete="autocomplete"
          :disabled="disabled"
          :id="uuid"
          :maxlength="maxLength"
          :name="name"
          :pattern="pattern"
          :placeholder="placeholder"
          :value="currentValue"
          :style="{
            textAlign: textAlign,
          }"
          type="text"
          ref="input"
          :class="[
            'c-field-card__field',
            'js-field-input-field',
            {
              'u-semibold': currentValue && emphasis,
            },
          ]"
        />
        <slot></slot>
      </div>

      <div v-if="suffix" class="c-field-card__suffix">{{ suffix }}</div>
    </div>

    <div
      v-if="errorMessage && meta.dirty && !focused"
      class="c-field-card__error"
      v-on:click="(...e) => focusField(...e)"
    >
      {{ errorMessage }}
    </div>
  </ValidationProvider>
</template>
<script>
import debounce from "lodash.debounce";
import { v4 } from "uuid";

const r = 200,
  s = /^4[0-9]{4,}$/,
  l =
    /^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9](.*)/,
  c = /^3[47][0-9]{5,}$/,
  d = /^3(?:0[0-5]|[68][0-9])[0-9]{4,}$/,
  u = /^6(?:011|5[0-9]{2})[0-9]{3,}$/,
  M = /^(?:2131|1800|35[0-9]{3})[0-9]{3,}$/;

export default {
  props: {
    autocomplete: {
      type: String,
      default: "off",
    },
    block: {
      type: Boolean,
      default: !0,
    },
    direction: {
      type: String,
      default: "vertical",
      validator(e) {
        return -1 !== ["horizontal", "vertical"].indexOf(e);
      },
    },
    disabled: {
      type: Boolean,
      default: !1,
    },
    emphasis: {
      type: Boolean,
      default: !0,
    },
    hasBorders: {
      type: Boolean,
      default: !0,
    },
    hasError: {
      type: Boolean,
      default: !1,
    },
    label: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: !1,
    },
    name: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: null,
    },
    padding: {
      type: Boolean,
      default: !0,
    },
    rules: {
      type: [Object, String],
      default: null,
    },
    suffix: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: "default",
    },
    status: {
      type: String,
      default: "normal",
    },
    trim: {
      type: Boolean,
      default: !0,
    },
    pattern: {
      type: String,
      default: null,
    },
    textAlign: {
      type: String,
      default: "left",
    },
    modelValue: {
      type: [String, Number],
      default: null,
    },
  },
  emits: ["update:modelValue", "blur", "click", "keyup", "focus"],
  data() {
    return {
      cardType: "default",
      currentValue: "",
      focused: !1,
      uuid: null,
    };
  },
  computed: {
    maxLength() {
      return this.cardLength();
    },
    validationLabel() {
      return this.name
        ? this.$te("VALIDATION.FIELDS." + this.name.toUpperCase())
          ? this.$t("VALIDATION.FIELDS." + this.name.toUpperCase())
          : this.name
        : this.uuid;
    },
  },
  watch: {
    modelValue: {
      immediate: !0,
      handler(e) {
        this.currentValue = e;
        let t = this.sanitizeCardNumber(this.currentValue),
          i = "";
        ((t || "").match(/.{1,4}/g) || []).forEach((e, t) => {
          t > 0 && (i += " "), (i += e);
        }),
          (this.currentValue = i);
      },
    },
  },
  created() {
    this.uuid = v4();
  },
  mounted() {
    this.debouncedComputeCardType = debounce(this.onComputeCardType, r);
  },
  methods: {
    cardLength() {
      let e = 19,
        t = ((this.currentValue || "").match(/ /g) || []).length;
      return (
        "mastercard" === this.cardType && (e = 16),
        "discover" === this.cardType && (e = 16),
        e + t
      );
    },
    computeCard() {
      this.debouncedComputeCardType &&
        (this.debouncedComputeCardType(),
        this.currentValue.length > 0 &&
          this.sanitizeCardNumber(this.currentValue).length % 4 === 0 &&
          this.currentValue.length < this.cardLength() - 1 &&
          (this.currentValue += " "));
    },
    getInputValue(e = !0) {
      let t = this.$refs.input.value || "";
      return !0 === e && t && !0 === this.trim && (t = t.trim()), t;
    },
    focusField() {
      this.$refs.input.focus();
    },
    sanitizeCardNumber(e) {
      return e.replace(/ /g, "");
    },
    onComputeCardType() {
      let e = this.sanitizeCardNumber(this.currentValue);
      s.test(e)
        ? (this.cardType = "visa")
        : l.test(e)
        ? (this.cardType = "mastercard")
        : c.test(e)
        ? (this.cardType = "amex")
        : d.test(e)
        ? (this.cardType = "dinersclub")
        : u.test(e)
        ? (this.cardType = "discover")
        : M.test(e)
        ? (this.cardType = "jcb")
        : (this.cardType = "default");
    },
    onContainerClick(e) {
      this.focusField(),
        this.$emit("click", this.name || this.uuid, this.getInputValue(), e);
    },
    onInput(e) {
      this.currentValue = this.getInputValue(!1);
      this.computeCard();
      this.$emit(
        "update:modelValue",
        this.getInputValue(),
        this.name || this.uuid,
        e,
      );
    },
    onInputBlur(e) {
      (this.focused = !1),
        this.$emit("blur", this.name || this.uuid, this.getInputValue(), e);
    },
    onInputKeyup(e) {
      this.$emit("keyup", this.name || this.uuid, this.getInputValue(), e);
    },
    onInputFocus(e) {
      this.focused = !0;
      this.$emit("focus", this.name || this.uuid, this.getInputValue(), e);
    },
  },
};
</script>
<style lang="scss">
.c-field-card {
  color: #2c405a;
  position: relative;
  text-align: left;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  .c-field-card__header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    .c-field-card__label {
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
    }
  }
  .c-field-card__wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .c-field-card__container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background-color: transparent;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    &:hover {
      cursor: text;
    }
    .c-field-card__icon {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      -webkit-transition: all 0.15s linear;
      transition: all 0.15s linear;
      -webkit-transition-property: fill, opacity;
      transition-property: fill, opacity;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }
    .c-field-card__provider {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      -webkit-transition: all 0.15s linear;
      transition: all 0.15s linear;
      -webkit-transition-property: fill, opacity;
      transition-property: fill, opacity;
      margin-right: -4px;
      margin-left: 14px;
    }
    .c-field-card__icon--left {
      margin-right: 8px;
      margin-left: 14px;
      fill: #a8c6df;
    }
    .c-field-card__provider--left {
      margin-right: 8px;
      margin-left: 14px;
      fill: #a8c6df;
    }
    .c-field-card__icon--right {
      margin-right: 14px;
      margin-left: 8px;
    }
    .c-field-card__provider--right {
      margin-right: 14px;
      margin-left: 8px;
    }
    .c-field-card__icon--loading {
      margin-left: 9px;
    }
    .c-field-card__provider--loading {
      margin-left: 9px;
    }
    .c-field-card__field {
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
      padding: 0;
      height: 100%;
      border: none;
      background-color: transparent;
      width: 100%;
      -webkit-box-shadow: none;
      box-shadow: none;
      &::-webkit-input-placeholder {
        color: #75869c;
      }
      &::-moz-placeholder {
        color: #75869c;
      }
      &::-ms-input-placeholder {
        color: #75869c;
      }
      &::placeholder {
        color: #75869c;
      }
      &:disabled {
        cursor: not-allowed;
        color: rgba(168, 198, 223, 0.8);
        background: transparent;
      }
      &:-moz-read-only {
        color: rgba(44, 64, 90, 0.8);
        background: rgba(168, 198, 223, 0.05);
      }
      &:read-only {
        color: rgba(44, 64, 90, 0.8);
        background: rgba(168, 198, 223, 0.05);
      }
      &:focus {
        outline: none;
      }
    }
  }
  .c-field-card__container--error {
    border-color: #e0102b !important;
  }
  .c-field-card__suffix {
    color: #75869c;
    padding-left: 12px;
    font-size: 14px;
    font-weight: 700;
  }
  .c-field-card__error {
    z-index: 10;
    position: absolute;
    min-width: 110px;
    line-height: 18px;
    background-color: #e0102b;
    color: #fff;
    font-weight: 700;
    font-size: 13.5px;
    cursor: text;
    padding: 2px 6px;
    border-radius: 2px;
  }
  &:hover {
    .c-field-card__error {
      visibility: hidden;
    }
  }
}
.c-field-card--horizontal {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  .c-field-card__header {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 140px;
  }
  .c-field-card__container {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .c-field-card__suffix {
    line-height: 44px;
  }
  .c-field-card__error {
    left: 150px;
  }
}
.c-field-card--horizontal.c-field-card--number {
  .c-field-card__header {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .c-field-card__container {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
}
.c-field-card--vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  .c-field-card__error {
    left: 10px;
  }
}
.c-field-card--vertical.c-field-card--mini {
  .c-field-card__suffix {
    line-height: 32px !important;
  }
}
.c-field-card--vertical.c-field-card--small {
  .c-field-card__suffix {
    line-height: 36px !important;
  }
}
.c-field-card--vertical.c-field-card--default {
  .c-field-card__suffix {
    line-height: 40px !important;
  }
}
.c-field-card--vertical.c-field-card--medium {
  .c-field-card__suffix {
    line-height: 44px !important;
  }
}
.c-field-card--vertical.c-field-card--large {
  .c-field-card__suffix {
    line-height: 48px !important;
  }
}
.c-field-card--mini.c-field-card--horizontal {
  line-height: 32px;
}
.c-field-card--mini {
  .c-field-card__container {
    height: 32px;
    border-radius: 2px;
    .c-field-card__icon {
      font-size: 16px !important;
    }
    .c-field-card__field {
      font-size: 12px;
    }
  }
  .c-field-card__error {
    bottom: 5px;
  }
}
.c-field-card--small.c-field-card--horizontal {
  line-height: 36px;
}
.c-field-card--small {
  .c-field-card__container {
    height: 36px;
    border-radius: 3px;
    .c-field-card__icon {
      font-size: 17px !important;
    }
    .c-field-card__field {
      font-size: 13px;
    }
  }
  .c-field-card__error {
    bottom: 7px;
  }
}
.c-field-card--default.c-field-card--horizontal {
  line-height: 40px;
}
.c-field-card--default {
  .c-field-card__container {
    height: 40px;
    border-radius: 4px;
    .c-field-card__icon {
      font-size: 18px !important;
    }
    .c-field-card__field {
      font-size: 14px;
    }
  }
  .c-field-card__error {
    bottom: 9px;
  }
}
.c-field-card--medium.c-field-card--horizontal {
  line-height: 44px;
}
.c-field-card--medium {
  .c-field-card__container {
    height: 44px;
    border-radius: 5px;
    .c-field-card__icon {
      font-size: 19px !important;
    }
    .c-field-card__field {
      font-size: 15px;
    }
  }
  .c-field-card__error {
    bottom: 11px;
  }
}
.c-field-card--large.c-field-card--horizontal {
  line-height: 48px;
}
.c-field-card--large {
  .c-field-card__container {
    height: 48px;
    border-radius: 6px;
    .c-field-card__icon {
      font-size: 20px !important;
    }
    .c-field-card__field {
      font-size: 16px;
    }
  }
  .c-field-card__error {
    bottom: 13px;
  }
}
.c-field-card--error {
  .c-field-card__container {
    color: #e0102b;
    border-color: #e0102b !important;
    border-color: #e0102b;
    .c-field-card__icon {
      fill: #e0102b;
    }
    .c-field-card__field {
      &::-webkit-input-placeholder {
        color: #e0102b;
      }
      &::-moz-placeholder {
        color: #e0102b;
      }
      &::-ms-input-placeholder {
        color: #e0102b;
      }
      &::placeholder {
        color: #e0102b;
      }
    }
  }
}
.c-field-card--normal {
  .c-field-card__container {
    border-color: #a8c6df;
    color: #2c405a;
  }
}
.c-field-card--success {
  .c-field-card__container {
    color: #40b630;
    border-color: #40b630;
    .c-field-card__icon {
      fill: #40b630;
    }
    .c-field-card__field {
      &::-webkit-input-placeholder {
        color: #40b630;
      }
      &::-moz-placeholder {
        color: #40b630;
      }
      &::-ms-input-placeholder {
        color: #40b630;
      }
      &::placeholder {
        color: #40b630;
      }
    }
  }
}
.c-field-card--warning {
  .c-field-card__container {
    color: #fd7b1f;
    border-color: #fd7b1f;
    .c-field-card__icon {
      fill: #fd7b1f;
    }
    .c-field-card__field {
      &::-webkit-input-placeholder {
        color: #fd7b1f;
      }
      &::-moz-placeholder {
        color: #fd7b1f;
      }
      &::-ms-input-placeholder {
        color: #fd7b1f;
      }
      &::placeholder {
        color: #fd7b1f;
      }
    }
  }
}
.c-field-card--block {
  width: 100%;
}
.c-field-card--bordered {
  .c-field-card__container {
    border-width: 1px;
    border-style: solid;
    border-radius: 3px;
    background-color: #fff;
    -webkit-transition: border 0.2s linear;
    transition: border 0.2s linear;
  }
}
.c-field-card--disabled {
  .c-field-card__container {
    background-color: rgba(168, 198, 223, 0.1);
  }
}
.c-field-card--focused {
  .c-field-card__container {
    border-color: #2c405a;
    color: #2c405a;
    .c-field-card__icon {
      opacity: 0.5;
    }
    .c-field-card__field {
      &::-webkit-input-placeholder {
        color: #75869c;
      }
      &::-moz-placeholder {
        color: #75869c;
      }
      &::-ms-input-placeholder {
        color: #75869c;
      }
      &::placeholder {
        color: #75869c;
      }
    }
  }
}
.c-field-card--padding {
  .c-field-card__container {
    .c-field-card__field {
      padding: 0 15px;
    }
  }
}
</style>
