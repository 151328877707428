var _baseComponents = {
  "./BaseAvailability.vue": require("../components/Base/BaseAvailability.vue"),
  "./BaseAvatar.vue": require("../components/Base/BaseAvatar.vue"),
  "./BaseBadge.vue": require("../components/Base/BaseBadge.vue"),
  "./BaseBanner.vue": require("../components/Base/BaseBanner.vue"),
  "./BaseBreadcrumb.vue": require("../components/Base/BaseBreadcrumb.vue"),
  "./BaseBrowser.vue": require("../components/Base/BaseBrowser.vue"),
  "./BaseButton.vue": require("../components/Base/BaseButton.vue"),
  "./BaseCard.vue": require("../components/Base/BaseCard.vue"),
  "./BaseCardProvider.vue": require("../components/Base/BaseCardProvider.vue"),
  "./BaseCollapsible.vue": require("../components/Base/BaseCollapsible.vue"),
  "./BaseColor.vue": require("../components/Base/BaseColor.vue"),
  "./BaseCommands.vue": require("../components/Base/BaseCommands.vue"),
  "./BaseChannelIcon.vue": require("../components/Base/BaseChannelIcon.vue"),
  "./BaseControl.vue": require("../components/Base/BaseControl.vue"),
  "./BaseDivider.vue": require("../components/Base/BaseDivider.vue"),
  "./BaseDot.vue": require("../components/Base/BaseDot.vue"),
  "./BaseDropdown.vue": require("../components/Base/BaseDropdown.vue"),
  "./BaseDropdownItem.vue": require("../components/Base/BaseDropdownItem.vue"),
  "./BaseEmptySpot.vue": require("../components/Base/BaseEmptySpot.vue"),
  "./BaseFlag.vue": require("../components/Base/BaseFlag.vue"),
  "./BaseFrame.vue": require("../components/Base/BaseFrame.vue"),
  "./BaseHeader.vue": require("../components/Base/BaseHeader.vue"),
  "./BaseHeading.vue": require("../components/Base/BaseHeading.vue"),
  "./BaseHeadlines.vue": require("../components/Base/BaseHeadlines.vue"),
  // "./BaseIcon.vue": require("../components/Base/BaseIcon.vue"),
  "./BaseIllustration.vue": require("../components/Base/BaseIllustration.vue"),
  "./BaseIllustrationPicker.vue": require("../components/Base/BaseIllustrationPicker.vue"),
  "./BaseIndicator.vue": require("../components/Base/BaseIndicator.vue"),
  "./BaseInformation.vue": require("../components/Base/BaseInformation.vue"),
  "./BaseLanguage.vue": require("../components/Base/BaseLanguage.vue"),
  "./BaseLink.vue": require("../components/Base/BaseLink.vue"),
  "./BaseLogo.vue": require("../components/Base/BaseLogo.vue"),
  "./BaseNavigation.vue": require("../components/Base/BaseNavigation.vue"),
  "./BaseNotice.vue": require("../components/Base/BaseNotice.vue"),
  "./BaseNotification.vue": require("../components/Base/BaseNotification.vue"),
  "./BaseOverlay.vue": require("../components/Base/BaseOverlay.vue"),
  "./BasePages.vue": require("../components/Base/BasePages.vue"),
  "./BasePopup.vue": require("../components/Base/BasePopup.vue"),
  "./BasePrice.vue": require("../components/Base/BasePrice.vue"),
  "./BaseProgress.vue": require("../components/Base/BaseProgress.vue"),
  "./BaseRating.vue": require("../components/Base/BaseRating.vue"),
  "./BaseSlideshow.vue": require("../components/Base/BaseSlideshow.vue"),
  "./BaseSmiley.vue": require("../components/Base/BaseSmiley.vue"),
  "./BaseSpace.vue": require("../components/Base/BaseSpace.vue"),
  "./BaseSpinner.vue": require("../components/Base/BaseSpinner.vue"),
  "./BaseTabs.vue": require("../components/Base/BaseTabs.vue"),
  "./BaseTag.vue": require("../components/Base/BaseTag.vue"),
  "./BaseTeleprompter.vue": require("../components/Base/BaseTeleprompter.vue"),
  "./BaseToast.vue": require("../components/Base/BaseToast.vue"),
  "./BaseTooltip.vue": require("../components/Base/BaseTooltip.vue"),
  "./BaseUpgrade.vue": require("../components/Base/BaseUpgrade.vue"),
  "./BaseVideo.vue": require("../components/Base/BaseVideo.vue"),
  "./BaseViewer.vue": require("../components/Base/BaseViewer.vue"),
};

function o(_name) {
  let _load = readComponent(_name);
  return _load;
}

function readComponent(__name) {
  if (!_baseComponents[__name]) {
    var t = new Error("Cannot find module '" + __name + "'");
    throw ((t.code = "MODULE_NOT_FOUND"), t);
  }
  return _baseComponents[__name];
}

o.keys = function () {
  return Object.keys(_baseComponents);
};

module.exports = o;
