// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI3NTAiIGhlaWdodD0iNDcxIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxyZWN0IHdpZHRoPSI3NTAiIGhlaWdodD0iNDcxIiBmaWxsPSIjMDAwYzlkIiBmaWxsLXJ1bGU9Im5vbnplcm8iIHJ4PSI0MCIvPjxyZWN0IHdpZHRoPSIxMTAuMzI0IiBoZWlnaHQ9Ijk5LjY3OSIgeD0iNDguNzEiIHk9IjE1My41NzYiIGZpbGw9IiM5ZDk0MDAiIGZpbGwtcnVsZT0ibm9uemVybyIgcng9IjIwLjMyMyIvPjxnIGZvbnQtZmFtaWx5PSJDb3VyaWVyIj48dGV4dCBmaWxsPSIjZmZmIiBmb250LXNpemU9IjYxIiBsZXR0ZXItc3BhY2luZz0iLTEuNSI+PHRzcGFuIHg9IjU0IiB5PSIzMzMiPjQzMjEgNDMyMSA0MzIxIDQzMjE8L3RzcGFuPjwvdGV4dD48dGV4dCBmaWxsPSIjYzhjYWU5IiBmb250LXNpemU9IjQ2IiBsZXR0ZXItc3BhY2luZz0iLTEuMTMxIj48dHNwYW4geD0iNTUiIHk9IjQxOSI+MTEyMzU4IDEzMjEgMzQ1NTwvdHNwYW4+PC90ZXh0PjwvZz48L2c+PC9zdmc+", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("@/assets/images/components/base/BaseCardProvider/amex.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("@/assets/images/components/base/BaseCardProvider/dinersclub.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("@/assets/images/components/base/BaseCardProvider/discover.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("@/assets/images/components/base/BaseCardProvider/jcb.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("@/assets/images/components/base/BaseCardProvider/maestro.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("@/assets/images/components/base/BaseCardProvider/mastercard.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_7___ = new URL("@/assets/images/components/base/BaseCardProvider/paypal.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_8___ = new URL("@/assets/images/components/base/BaseCardProvider/unionpay.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_9___ = new URL("@/assets/images/components/base/BaseCardProvider/visa.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_10___ = new URL("@/assets/images/components/base/BaseCardProvider/wire.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
var ___CSS_LOADER_URL_REPLACEMENT_9___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_9___);
var ___CSS_LOADER_URL_REPLACEMENT_10___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_10___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".c-base-card-provider {\n  display: flex;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: contain;\n  background-repeat: no-repeat;\n  background-position: 50%;\n}\n.c-base-card-provider--amex {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n.c-base-card-provider--dinersclub {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n}\n.c-base-card-provider--discover {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");\n}\n.c-base-card-provider--jcb {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ");\n}\n.c-base-card-provider--maestro {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ");\n}\n.c-base-card-provider--mastercard {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + ");\n}\n.c-base-card-provider--paypal {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_7___ + ");\n}\n.c-base-card-provider--unionpay {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_8___ + ");\n}\n.c-base-card-provider--visa {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_9___ + ");\n}\n.c-base-card-provider--wire {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_10___ + ");\n}\n.c-base-card-provider--tiny {\n  width: 27px;\n  height: 17px;\n  border-radius: 2px;\n}\n.c-base-card-provider--small {\n  width: 43px;\n  height: 27px;\n  border-radius: 4px;\n}\n.c-base-card-provider--medium {\n  width: 72px;\n  height: 46px;\n  border-radius: 6px;\n}\n.c-base-card-provider--big {\n  width: 88px;\n  height: 56px;\n  border-radius: 6px;\n}\n.c-base-card-provider--huge {\n  width: 146px;\n  height: 94px;\n  border-radius: 8px;\n}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
