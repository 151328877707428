<template>
  <span v-if="isVisible"></span>
</template>
<script>
import mitt from "mitt";
// import * as QuickTour from "./QuickTour.vue";
// import * as PlanBadge from "./PlanBadge.vue";
import * as r from "../classes/plugins.js";
import * as s from "../classes/helpers.js";
import * as l from "../classes/analytics.js";
import * as c from "./SwitchPlan.vue";

const emitter = mitt();

export default {
  components: {
    // QuickTour,
    // PlanBadge,
  },
  data() {
    return {
      isVisible: !1,
      pluginDetailsLoading: !1,
      step: "plugin_tour",
      closable: !0,
      plan: "",
      pluginUrn: null,
      pluginName: "",
      videoId: null,
    };
  },
  computed: {
    mainTitle() {
      return this.$t("POPUPS.UPGRADE.TITLE", {
        plan_name: this.planName,
        plugin_name: this.pluginName || this.$t("POPUPS.UPGRADE.FEATURE"),
      });
    },
    planName() {
      return this.$filters.format.titlecase(this.plan);
    },
    websiteId() {
      return (
        this.$store.getters["websites/getURLWebsiteId"] ||
        this.$store.getters["websites/getWebsiteId"]
      );
    },
  },
  show(e) {
    emitter.emit("show", e);
  },
  watch: {
    $route: {
      handler() {
        this.onClose();
      },
    },
  },
  mounted() {
    emitter.on("show", this.onShow);
  },
  beforeUnmount() {
    emitter.off("show", this.onShow);
  },
  methods: {
    loadPluginDetails(e) {
      if (!0 !== this.pluginDetailsLoading)
        return (
          (this.pluginDetailsLoading = !0),
          (this.videoId = null),
          r.default
            .getPlugin(e)
            .catch(() => Promise.resolve(null))
            .then((e) => {
              e &&
                e.video_url &&
                (this.videoId = s.default.extractYouTubeVideoId(e.video_url)),
                this.videoId || this.onUpgradeCheck(),
                (this.pluginDetailsLoading = !1);
            })
        );
    },
    restoreDataDefaults() {
      (this.closable = !0),
        (this.plan = ""),
        (this.pluginUrn = null),
        (this.pluginName = ""),
        (this.videoId = null);
    },
    onClose() {
      c.default.hide(), (this.isVisible = !1);
    },
    onShow(e) {
      this.restoreDataDefaults();
      for (let t in e) this[t] = e[t];
      this.pluginUrn && this.loadPluginDetails(this.pluginUrn),
        (this.isVisible = !0),
        l.default.logEvent("Upgrade clicked", {
          pluginName: this.pluginName,
        });
    },
    onUpgradeCheck() {
      this.onClose(),
        c.default.show(this.websiteId, this.planName, this.closable);
    },
  },
};
</script>
