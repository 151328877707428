import socketioClient from "socket.io-client";
const NUMBER_OF_SOCKET_NODES = 2;

class _socket {
  constructor() {
    this.established = false;
    this.__client = null;
    this.__socketAddress = process.env.VUE_APP_SOCKET_ADDRESS;
    this.__listeners = {};
    this.__listen();
  }

  __cleanup() {
    this.rooms = [];
    this.events = [];
  }

  on(e, t) {
    this.__listeners[e] = this.__listeners[e] || [];
    this.__listeners[e].push(t);
    if (null !== this.__client) {
      this.__client.on(e, t);
    }
  }

  off(e) {
    if (this.__listeners[e]) {
      delete this.__listeners[e];
    }
  }

  emit(eventName, eventData, eventCallback = null) {
    if (null === this.__client) {
      throw new Error(`Cannot emit [${eventName}]: stream is not yet bound`);
    }
    this.__client.emit(eventName, eventData, eventCallback);
  }

  __connect({ tenantID, token }) {
    /**
     * Generate Affinity key based on tenantID
     *
     * This is responsible to split work load and garantee
     * resiliency of a large number of connected clients
     */
    const affinityKey =
      ((parseInt(tenantID.charAt(0) || "", 16) || 0) % NUMBER_OF_SOCKET_NODES) +
      1;

    return Promise.resolve()
      .then(() => {
        this.__client = new socketioClient(this.__socketAddress, {
          path: `/w/${affinityKey}/`,
          allowUpgrades: true,
          transports: ["websocket"],
          auth: {
            Authorization: `Bearer ${token}`,
            tenant: tenantID,
          },
          reconnection: true,
          autoConnect: false,
        });

        for (let t in this.__listeners) {
          let e = this.__listeners[t] || [];
          for (let i = 0; i < e.length; i++) {
            this.__client.on(t, e[i]);
          }
        }
      })
      .then(() => {
        this.__client.open();
        return Promise.resolve(this.__client);
      });
  }

  __listen() {
    this.on("connect_error", (err) => {
      console.log("@socket:socnnection:error", err);
    });
    this.on("connect", () => {
      this.established = true;
      // this.__triggerConnected();
    });
    this.on("disconnect", () => {
      this.established = false;
      // this.__triggerDisconnected();
    });
  }
}

export default new _socket();
