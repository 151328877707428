// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjQwIiB3aWR0aD0iNDAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxIDEpIj48Y2lyY2xlIGN4PSIxOSIgY3k9IjE5IiBmaWxsPSIjMDAwIiBmaWxsLW9wYWNpdHk9Ii4xNDYiIHI9IjE5IiBzdHJva2U9IiNmZmYiIHN0cm9rZS13aWR0aD0iMiIvPjxwYXRoIGQ9Ik0yOCAxOWwtMTQgOVYxMHoiIGZpbGw9IiNmZmYiLz48L2c+PC9zdmc+", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".c-base-video {\n  background-color: #161616;\n  background-size: cover;\n  background-position: 50%;\n  background-repeat: no-repeat;\n  cursor: pointer;\n  position: relative;\n  border-radius: 1px;\n  overflow: hidden;\n}\n.c-base-video:hover .c-base-video__play {\n  transform: scale(1.1);\n}\n.c-base-video:active .c-base-video__play {\n  transform: scale(0.975);\n}\n.c-base-video .c-base-video__play {\n  width: 52px;\n  height: 52px;\n  margin-top: -26px;\n  margin-left: -26px;\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  z-index: 1;\n  transition: transform 0.15s ease-in-out;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: contain;\n  background-repeat: no-repeat;\n  background-position: 50%;\n  display: inline-block;\n}\n.c-base-video .c-base-video__overlay {\n  background: rgba(0, 0, 0, 0.15);\n  width: 100%;\n  height: 100%;\n  display: block;\n  margin-bottom: 20px;\n}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
