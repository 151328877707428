<template>
  <div
    :data-direction="direction"
    :data-align="align"
    :data-color="color"
    :data-size="size"
    :class="[
      'c-base-tooltip',
      {
        'c-base-tooltip--hidden': hidden,
        'c-base-tooltip--multiline': multiline,
      },
    ]"
  >
    <span class="c-base-tooltip__value">
      <div v-if="activated && '' !== activatedText">
        {{ activatedText }}
      </div>
      <slot v-else name="default"></slot>

      <span v-if="$slots.main" class="c-base-tooltip__main u-semibold">
        <slot name="main"></slot>
      </span>

      <span v-if="$slots.sub" class="c-base-tooltip__sub u-regular">
        <slot name="sub"></slot>
      </span>

      <span v-if="$slots.details" class="c-base-tooltip__details u-regular">
        <slot name="details"></slot>
      </span>
    </span>
  </div>
</template>
<script>
import mitt from "mitt";
const n = 1500;
const r = 250;
const s = mitt();
export default {
  props: {
    activatedText: {
      type: String,
      default: "",
    },
    activatedDuration: {
      type: Number,
      default: null,
    },
    align: {
      type: String,
      default: "center",
      validator(e) {
        return -1 !== ["left", "center", "right"].indexOf(e);
      },
    },
    color: {
      type: String,
      default: "default",
      validator(e) {
        return -1 !== ["default", "white"].indexOf(e);
      },
    },
    direction: {
      type: String,
      default: "top",
      validator(e) {
        return -1 !== ["bottom", "middle", "top"].indexOf(e);
      },
    },
    multiline: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "default",
      validator(e) {
        return -1 !== ["default", "short"].indexOf(e);
      },
    },
  },
  data() {
    return {
      hidden: false,
      activated: false,
      autoCloseTimeout: null,
    };
  },
  activate() {
    s.emit("activate");
  },
  deactivate() {
    s.emit("deactivate");
  },
  created() {
    s.on("activate", this.activate);
    s.on("deactivate", this.deactivate);
  },
  beforeUnmount() {
    s.off("activate", this.activate);
    s.off("deactivate", this.deactivate);
  },
  methods: {
    activate() {
      this.deactivate(!0);
      this.activated = !0;
      this.scheduleClose();
    },
    deactivate(e = false) {
      this.activated &&
        (this.unscheduleClose(),
        e
          ? ((this.activated = false), (this.hidden = false))
          : ((this.hidden = !0),
            setTimeout(() => {
              (this.activated = false), (this.hidden = false);
            }, r)));
    },
    scheduleClose() {
      this.unscheduleClose();
      this.autoCloseTimeout = setTimeout(() => {
        (this.autoCloseTimeout = null), this.activated && (this.hidden = !0);
      }, this.activatedDuration || n);
    },
    unscheduleClose() {
      null !== this.autoCloseTimeout &&
        (clearTimeout(this.autoCloseTimeout), (this.autoCloseTimeout = null));
    },
  },
};
</script>
<style lang="scss">
.c-base-tooltip {
  position: absolute;
  z-index: 999;
  display: block;
  visibility: hidden;
  width: 1000px;
  font-weight: 400;
  line-height: 19px;
  opacity: 0;
  -webkit-transition: all 0.15s ease 0.1s;
  transition: all 0.15s ease 0.1s;
  pointer-events: none !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
  .c-base-tooltip__value {
    left: 50%;
    display: inline-block;
    padding: 4px 11px;
    border-radius: 2px;
    background-color: rgba(34, 44, 60, 0.96);
    -webkit-box-shadow: 0 3px 6px 0 rgba(52, 74, 97, 0.125);
    box-shadow: 0 3px 6px 0 rgba(52, 74, 97, 0.125);
    color: #fff;
    text-align: center;
    text-transform: none;
    text-shadow: none;
    letter-spacing: 0.3px;
    font-size: 12px;
    pointer-events: auto;
    .c-base-tooltip__details {
      // margin-left: 20px;
      color: #75869c;
    }
  }
  &:after {
    position: absolute;
    width: 0;
    height: 0;
    content: "";
  }
}
.c-base-tooltip[data-size="short"] {
  width: 125px;
}
.c-base-tooltip[data-color="white"][data-direction="bottom"] {
  &:after {
    border-bottom-color: hsla(0, 0%, 100%, 0.98);
  }
}
.c-base-tooltip[data-color="white"][data-direction="top"] {
  &:after {
    border-top-color: hsla(0, 0%, 100%, 0.98);
  }
}
.c-base-tooltip[data-color="white"][data-direction="middle"][data-align="left"] {
  &:after {
    border-left-color: hsla(0, 0%, 100%, 0.98);
  }
}
.c-base-tooltip[data-color="white"][data-direction="middle"][data-align="right"] {
  &:after {
    border-right-color: hsla(0, 0%, 100%, 0.98);
  }
}
.c-base-tooltip[data-color="white"] {
  .c-base-tooltip__value {
    background-color: hsla(0, 0%, 100%, 0.98);
    color: #000;
    font-weight: 700;
  }
}
.c-base-tooltip[data-direction="bottom"][data-align="left"] {
  left: 0;
  text-align: left;
  &:after {
    left: 0;
  }
}
.c-base-tooltip[data-direction="top"][data-align="left"] {
  left: 0;
  text-align: left;
  &:after {
    left: 0;
  }
}
.c-base-tooltip[data-direction="bottom"][data-align="right"] {
  right: 0;
  text-align: right;
  &:after {
    right: 0;
  }
}
.c-base-tooltip[data-direction="top"][data-align="right"] {
  right: 0;
  text-align: right;
  &:after {
    right: 0;
  }
}
.c-base-tooltip[data-direction="bottom"][data-align="center"] {
  left: 50%;
  margin-left: -500px;
  text-align: center;
  &:after {
    left: 50%;
    margin-left: -4px;
  }
}
.c-base-tooltip[data-direction="top"][data-align="center"] {
  left: 50%;
  margin-left: -500px;
  text-align: center;
  &:after {
    left: 50%;
    margin-left: -4px;
  }
}
.c-base-tooltip[data-direction="bottom"] {
  &:after {
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
    top: -4px;
    border-bottom: 4px solid rgba(34, 44, 60, 0.96);
  }
  top: 100%;
  margin-top: 5px;
  -webkit-transform: translateY(4px);
  transform: translateY(4px);
}
.c-base-tooltip[data-direction="top"] {
  &:after {
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
    bottom: -4px;
    border-top: 4px solid rgba(34, 44, 60, 0.96);
  }
  bottom: 100%;
  margin-bottom: 5px;
  -webkit-transform: translateY(-4px);
  transform: translateY(-4px);
}
.c-base-tooltip[data-direction="middle"] {
  top: 50%;
  margin-top: -13.5px;
  &:after {
    top: 50%;
    margin-top: -4px;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
  }
}
.c-base-tooltip[data-direction="middle"][data-align="left"] {
  right: 100%;
  margin-right: 6px;
  text-align: right;
  -webkit-transform: translateX(4px);
  transform: translateX(4px);
  &:after {
    right: -4px;
    border-left: 4px solid rgba(34, 44, 60, 0.96);
  }
}
.c-base-tooltip[data-direction="middle"][data-align="right"] {
  left: 100%;
  margin-left: 6px;
  text-align: left;
  -webkit-transform: translateX(-4px);
  transform: translateX(-4px);
  &:after {
    left: -4px;
    border-right: 4px solid rgba(34, 44, 60, 0.96);
  }
}
.c-base-tooltip--multiline {
  .c-base-tooltip__value {
    padding: 3px 14px;
    .c-base-tooltip__main {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
    }
    .c-base-tooltip__sub {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      margin-top: -2px;
      font-style: italic;
    }
  }
}
.c-base-tooltip--hidden {
  visibility: hidden !important;
  opacity: 0 !important;
}
.u-has-tooltip {
  &:hover {
    .c-base-tooltip {
      visibility: visible;
      opacity: 1;
      -webkit-transition: all 0.15s ease;
      transition: all 0.15s ease;
      pointer-events: auto;
    }
    .c-base-tooltip[data-direction="bottom"] {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    .c-base-tooltip[data-direction="top"] {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    .c-base-tooltip[data-direction="middle"][data-align="left"] {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
    .c-base-tooltip[data-direction="middle"][data-align="right"] {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
  }
}
</style>
