import a from "@/components/Base/BaseToast.vue";
import o from "@/classes/date";

const r = [
  ["play", "loading"],
  ["pause", "paused"],
  ["playing", "playing"],
  ["canplay", "none"],
  ["none", "none"],
];

class _crispAudio {
  init(vue) {
    let $that = this;
    vue.directive("crispAudio", {
      beforeMount(e, i, a) {
        return $that.bind(e, i, a);
      },
    });
  }
  getProgress(e, t) {
    let i = e.closest(".c-conversation-box-content-message-bubble"),
      a = i.getBoundingClientRect().left,
      o = i.getBoundingClientRect().width,
      n = t - a;
    return n / o;
  }
  pause(e, t, i) {
    i.pause(),
      t.instance.$nextTick(() => {
        t.instance.audio.state = "pausing";
      });
  }
  play(e, t, i, a = null) {
    let o = () => {
      i.play(),
        a && (i.currentTime = a * t.instance.duration),
        t.instance.$nextTick(() => {
          t.instance.audio.state = "playing";
        });
    };
    !0 === t.instance.__crispAudioError &&
      (i.load(), (t.instance.__crispAudioError = !1)),
      t.instance.duration
        ? o()
        : (i.load(),
          i.addEventListener("loadeddata", () => {
            i.duration !== 1 / 0
              ? (t.instance.duration = i.duration)
              : (t.instance.duration = parseInt(t.value)),
              o();
          }));
  }
  bindEvents(e, t, i, a) {
    r.forEach((i) => {
      a.addEventListener(i[0], () => {
        t.instance.audio.state = i[1];
        e.setAttribute("data-state", i[1]);
      });
    });
  }
  bindJump(e, t, i, a, o) {
    e.addEventListener("mousemove", (t) => {
      let i = parseInt(100 * this.getProgress(e, t.clientX));
      a.setAttribute("style", `width: ${i}%`);
    }),
      e.addEventListener("mouseleave", () => {
        a.setAttribute("style", "width: 0%");
      }),
      e.addEventListener("click", (i) => {
        let a = this.getProgress(e, i.clientX);
        this.play(e, t, o, a);
      });
  }
  bindProgress(e, t, i, a, n, r) {
    r.addEventListener("timeupdate", (e) => {
      if (e.target) {
        let i = Math.ceil((e.target.currentTime / e.target.duration) * 100),
          r = i < 100 ? i : 0,
          s = 0 === r ? t.value || 0 : Math.floor(e.target.currentTime);
        (n.innerText = o.formatTime(s)),
          a.setAttribute("style", `width: ${r}%`);
      }
    });
  }
  bindError(e, t, i, o, n) {
    n.addEventListener("error", () => {
      o.paused || o.pause(),
        (t.instance.__crispAudioError = !0),
        a.warning("directives_audio_error");
    });
  }
  bindAction(e, t, i, a, o) {
    a.addEventListener(
      "click",
      (i) => (
        i.preventDefault(),
        i.stopPropagation(),
        o.paused ? this.play(e, t, o) : this.pause(e, t, o),
        !1
      ),
    );
  }
  bind(e, t, i) {
    let mediaElement = e.querySelector(".js-audio-media");
    let o = e.querySelector(".js-audio-source");
    let n = e.querySelector(".js-audio-progress");
    let r = e.querySelector(".js-audio-jump");
    let s = e.querySelector(".js-audio-duration");
    let actionElement = e.querySelector(".js-audio-action");
    if (mediaElement) {
      if (actionElement) {
        this.bindEvents(e, t, i, mediaElement);
        this.bindJump(e, t, i, r, mediaElement);
        this.bindProgress(e, t, i, n, s, mediaElement);
        this.bindError(e, t, i, mediaElement, o);
        this.bindAction(e, t, i, actionElement, mediaElement);
      }
    }
  }
}
export default new _crispAudio();
