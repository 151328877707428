const a = 31;
class _plans {
  getPlanColor(e) {
    switch (e) {
      case "unlimited":
        return "black";
      case "pro":
        return "green";
      default:
        return "blue";
    }
  }
  getProratedPrice(e) {
    let t = e * (1 - new Date().getDate() / a);
    return t.toFixed(2);
  }
}

export default new _plans();
