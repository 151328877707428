import tenant from "@/classes/talqui/tenant";

export default {
  namespaced: true,
  state: {
    tenants: {},
  },
  getters: {
    getNotification: (state) => (tenantID, notificationType) =>
      state.tenants[tenantID][notificationType] || 0,
  },
  actions: {
    acquireNotifications({ commit }, { tenantID }) {
      commit("assertNotifications", {
        tenantID: tenantID,
      });
      return tenant
        .tenantOne(tenantID)
        .one("notifications")
        .get()
        .then((res) => {
          for (const [_notificationKey, _notificationValue] of Object.entries(
            res,
          )) {
            if (!_notificationKey.startsWith("__")) {
              commit("setNotification", {
                tenantID: tenantID,
                notificationType: _notificationKey,
                value: _notificationValue,
              });
            }
          }
          Promise.resolve(res);
        });
    },
    incrementNotification({ commit }, { tenantID, notificationType }) {
      commit("assertNotifications", {
        tenantID: tenantID,
      });
      let __actualCount =
        this.getters["notificationsTalqui/getNotification"](
          tenantID,
          notificationType,
        ) || 0;
      commit("setNotification", {
        tenantID: tenantID,
        notificationType: notificationType,
        value: __actualCount + 1,
      });
    },
    decrementNotification({ commit }, { tenantID, notificationType }) {
      commit("assertNotifications", {
        tenantID: tenantID,
      });
      let __actualCount =
        this.getters["notificationsTalqui/getNotification"](
          tenantID,
          notificationType,
        ) || 0;

      commit("setNotification", {
        tenantID: tenantID,
        notificationType: notificationType,
        value: __actualCount > 0 ? __actualCount - 1 : 0,
      });
    },
  },
  mutations: {
    assertNotifications(state, { tenantID }) {
      state.tenants[tenantID] ||
        (state.tenants[tenantID] = {
          manualPendingCount: 0,
          queuedCount: 0,
        });
    },
    setNotification(state, { tenantID, notificationType, value }) {
      state.tenants[tenantID][notificationType] = value;
    },
  },
};
