<template>
  <div
    :class="[
      'c-conversation-box-field-file',
      'c-conversation-box-field-file--' + fileType,
    ]"
  >
    <span
      v-if="'image' === fileType"
      class="c-conversation-box-field-file__image"
      v-on:click="(...e) => magnify && magnify(...e)"
      :style="{
        backgroundImage: 'url(' + dataUrl + ')',
      }"
    ></span>
    <div v-else class="c-conversation-box-field-file__file">
      <div class="c-conversation-box-field-file__icon">
        <BaseIcon name="attach_file" size="20px" color="white"></BaseIcon>
      </div>
      <div class="c-conversation-box-field-file__details">
        <span class="c-conversation-box-field-file__name u-semibold">
          {{ name }}
        </span>
        <span class="c-conversation-box-field-file__extension">
          {{ extension }}
        </span>
      </div>
    </div>
    <span
      class="c-conversation-box-field-file__delete"
      v-on:click="onDeleteFile"
    >
      <BaseIcon cursor="pointer" name="close" size="14px" color="white" />
    </span>
  </div>
</template>
<script>
export default {
  props: {
    file: {
      type: Object,
      required: !0,
    },
  },
  emits: ["delete"],
  data: () => ({
    loading: !1,
  }),
  computed: {
    dataUrl() {
      return URL.createObjectURL(this.file);
    },
    fileType() {
      return -1 !==
        ["image/jpg", "image/jpeg", "image/png", "image/gif"].indexOf(
          this.file.type,
        )
        ? "image"
        : "file";
    },
    name() {
      return this.file.name;
    },
    extension() {
      let e = this.file.name.match(/\.([0-9a-z]+)$/);
      return e && e[1] ? e[1] : "binary";
    },
  },
  methods: {
    magnify() {
      // i.default.preview(this.dataUrl, !1);
    },
    onDeleteFile() {
      this.$emit("delete", this.file);
    },
  },
};
</script>
<style lang="scss" scoped>
.c-conversation-box-field-file {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 64px;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #a8c6df;
  margin-right: 14px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 14px;
  .c-conversation-box-field-file__image {
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 7px;
    &:hover {
      .c-conversation-box-field-file__overlay {
        display: block;
      }
    }
  }
  .c-conversation-box-field-file__overlay {
    display: none;
    background: #000;
    opacity: 0.25;
    -webkit-transition: display 0.25s ease;
    transition: display 0.25s ease;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 7px;
  }
  .c-conversation-box-field-file__file {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-left: 16px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    .c-conversation-box-field-file__icon {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      height: 38px;
      width: 38px;
      background: #0079c4;
      border-radius: 8px;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
    }
    .c-conversation-box-field-file__details {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      margin-left: 14px;
      .c-conversation-box-field-file__name {
        display: inline;
        margin-top: -2px;
        color: #1c293b;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 116px;
      }
      .c-conversation-box-field-file__extension {
        margin-top: 1px;
        color: rgba(28, 41, 59, 0.6);
        text-transform: uppercase;
        font-size: 14px;
        line-height: 15px;
      }
    }
  }
  .c-conversation-box-field-file__delete {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: absolute;
    top: -12px;
    right: -12px;
    height: 26px;
    width: 26px;
    border-radius: 100%;
    background: #75869c;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border: 2px solid #fff;
    -webkit-transition: background-color 0.15s ease;
    transition: background-color 0.15s ease;
    cursor: pointer;
    &:hover {
      background: #25374f;
    }
  }
}
.c-conversation-box-field-file--image {
  width: 64px;
  // cursor: -webkit-zoom-in;
  // cursor: zoom-in;
}
.c-conversation-box-field-file--file {
  width: 200px;
}
</style>
