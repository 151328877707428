import a from "@/classes/billing.js";

export default {
  namespaced: !0,
  state: {
    feed: {
      list: [],
      count: 0,
    },
    updates: {
      list: [],
      listWithContent: [],
      count: 0,
      latest: {},
    },
  },
  getters: {
    hasAlerts: (e) => e.feed.count > 0 || e.updates.count > 0,
    hasFeedAlerts: (e) => e.feed.count > 0,
    hasNewUpdate: (e) => !1 === e.updates.latest.is_read,
    getCount: (e) => ({
      feed: e.feed.count,
      updates: e.updates.count,
    }),
    getListFeed: (e) => e.feed.list,
    getListUpdates: (e) => e.updates.list,
    getListWithContentUpdates: (e) => e.updates.listWithContent,
    getLatestAlertsUpdate: (e) => e.updates.latest,
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    acquireCount({ commit: e }) {
      // return a.getAlertsCount().then(
      //   (t) => (
      //     e("setCount", {
      //       feed: t.feed,
      //       updates: t.updates,
      //     }),
      //     Promise.resolve()
      //   )
      // );
    },
    acquireFeed({ commit: e }, { page: t }) {
      let i;
      return a
        .getAlertsFeedList(t)
        .catch(() => Promise.resolve([]))
        .then(
          (a) => (
            (i = a),
            i.map((e) => {
              e.expanded = !1;
            }),
            1 === t
              ? (e("resetFeed", {
                  value: i,
                }),
                Promise.resolve())
              : (e("addFeedAlerts", {
                  value: i,
                }),
                Promise.resolve())
          ),
        )
        .then(() =>
          206 === i.__status ? Promise.resolve(!1) : Promise.resolve(!0),
        );
    },
    acquireUpdates(
      { commit: e, dispatch: t },
      { page: i, withContent: o = !1 },
    ) {
      let n;
      return a
        .getAlertsUpdatesList(i)
        .catch(() => Promise.resolve([]))
        .then(
          (e) => (
            (n = e.__status),
            e.forEach((e) => {
              e.expanded = !1;
            }),
            !0 === o
              ? t("acquireUpdatesContent", {
                  updates: e,
                })
              : Promise.resolve(e)
          ),
        )
        .then((t) =>
          1 === i
            ? (e("resetUpdates", {
                value: t,
                withContent: o,
              }),
              Promise.resolve())
            : (e("addUpdates", {
                value: t,
                withContent: o,
              }),
              Promise.resolve()),
        )
        .then(() => (206 === n ? Promise.resolve(!1) : Promise.resolve(!0)));
    },
    acquireLatestUpdate({ commit: e }) {
      return a
        .getLatestAlertsUpdate()
        .catch(() => Promise.resolve({}))
        .then(
          (t) => (
            e("setLatestUpdate", {
              value: t,
            }),
            Promise.resolve(!0)
          ),
        );
    },
    acquireUpdatesContent(e, { updates: t }) {
      return Promise.all(
        t.map((e) =>
          a.default
            .getAlertsUpdateContent(e.id)
            .then((t) => ((e.content = t.content), Promise.resolve(e))),
        ),
      );
    },
    removeAllFeedAlerts({ commit: e }) {
      return a.deleteAllAlerts().then(
        () => (
          e("resetFeed"),
          e("resetCountGroup", {
            group: "feed",
          }),
          Promise.resolve()
        ),
      );
    },
    removeFeedAlert({ commit: e }, { feedAlertId: t }) {
      return a.deleteAlertsFeed(t).then(
        () => (
          e("removeFeedAlert", {
            feedAlertId: t,
          }),
          e("decrementCountGroup", {
            group: "feed",
          }),
          Promise.resolve()
        ),
      );
    },
    readAllUpdates({ commit: e }) {
      return a.markAlertsUpdatesRead().then(
        () => (
          e("resetCountGroup", {
            group: "updates",
          }),
          e("resetLatestUpdate"),
          Promise.resolve()
        ),
      );
    },
  },
  mutations: {
    setCount(e, { feed: t, updates: i }) {
      (e.feed.count = t), (e.updates.count = i);
    },
    decrementCountGroup(e, { group: t }) {
      e[t].count > 0 && e[t].count--;
    },
    resetCountGroup(e, { group: t }) {
      e[t].count = 0;
    },
    resetAlerts(e) {
      (e.feed.list = []),
        (e.updates.list = []),
        (e.updates.listWithContent = []);
    },
    addFeedAlerts(e, { value: t }) {
      t.forEach((t) => {
        e.feed.list.push(t);
      });
    },
    resetFeed(e, { value: t = [] } = {}) {
      e.feed.list = t;
    },
    removeFeedAlert(e, { feedAlertId: t }) {
      let i = e.feed.list.findIndex((e) => e.id === t);
      -1 !== i && e.feed.list.splice(i, 1);
    },
    setCountFeed(e, { value: t }) {
      e.feed.count = t;
    },
    addUpdates(e, { value: t, withContent: i = !1 }) {
      !0 === i
        ? t.forEach((t) => {
            e.updates.listWithContent.push(t);
          })
        : t.forEach((t) => {
            e.updates.list.push(t);
          });
    },
    resetUpdates(e, { value: t = [], withContent: i = !1 } = {}) {
      !0 === i ? (e.updates.listWithContent = t) : (e.updates.list = t);
    },
    setLatestUpdate(e, { value: t }) {
      e.updates.latest = t;
    },
    resetLatestUpdate(e) {
      e.updates.latest = {};
    },
    setAlertExpanded(e, { group: t, id: i }) {
      let a;
      "feed" === t
        ? (a = e.feed.list.find((e) => e.id === i))
        : "update" === t && (a = e.updates.list.find((e) => e.id === i)),
        a && (a.expanded = !0);
    },
  },
};
