<template>
  <button
    :class="[
      'c-base-button',
      'c-base-button--' + color,
      'c-base-button--' + size,
      'u-semibold',
      {
        'c-base-button--block': block,
        'c-base-button--bordered': bordered,
        'c-base-button--disabled': disabled,
        'c-base-button--hovered': hovered,
        'c-base-button--inliner': inliner,
        'c-base-button--items': items && items.length > 0,
        'c-base-button--loading': loading,
        'c-base-button--reverse': reverse,
        'c-base-button--rounded': rounded,
        'c-base-button--padded': padded,
        'u-has-tooltip': tooltip,
      },
    ]"
    :disabled="disabled"
    :loading="loading"
    type="button"
    v-hotkey="hotkeys"
    v-on:click.stop="onButtonClick"
    v-on:mouseenter="hovered = true"
    v-on:mouseleave="hovered = false"
    v-bind="attrs"
  >
    <BaseTooltip
      v-if="tooltip"
      class="u-regular"
      :align="tooltipAlign"
      :direction="tooltipDirection"
    >
      {{ tooltip }}
    </BaseTooltip>
    <span class="c-base-button__inner">
      <BaseIcon
        v-if="hovered && leftIconHover && !loading"
        class="c-base-button__icon c-base-button__icon--left"
        :size="iconSize"
        :name="leftIconHover"
      ></BaseIcon>
      <BaseIcon
        v-else-if="leftIcon && !loading"
        class="c-base-button__icon c-base-button__icon--left"
        :size="iconSize"
        :name="leftIcon"
      ></BaseIcon>
      <BaseIcon
        v-else-if="loading"
        class="c-base-button__icon c-base-button__icon--loading"
        :size="iconSize"
        name="refresh"
      ></BaseIcon>
      <span class="c-base-button__label" v-if="this.$slots.default">
        <slot></slot>
      </span>
      <BaseIcon
        v-if="hovered && rightIconHover"
        class="c-base-button__icon c-base-button__icon--right"
        :size="iconSize"
        :name="rightIconHover"
      ></BaseIcon>
      <BaseIcon
        v-else-if="computedRightIcon"
        :class="[
          'c-base-button__icon',
          'c-base-button__icon--right',
          { 'c-base-button__icon--deployed': deployed },
        ]"
        :size="iconSize"
        :name="computedRightIcon"
      />
    </span>
    <span
      v-if="items && deployed"
      class="c-base-button__items"
      v-on:click="(...e) => onItemsClick && onItemsClick(...e)"
    >
      <span
        v-for="item in items"
        :key="item.id"
        v-on:click.stop="(t) => onItemClick(item)"
        :class="[
          'c-base-button__item',
          {
            'c-base-button__item--disabled': item.disabled,
            'c-base-button__item--red': item.red,
            'c-base-button__item--blue': item.blue,
          },
        ]"
      >
        <BaseIcon
          v-if="item.icon"
          class="c-base-button__icon"
          :name="item.icon"
          size="18px"
        />
        <div class="c-base-button__label">
          {{ item.label }}
        </div>
      </span>
    </span>
  </button>
</template>
<script>
export default {
  inheritAttrs: false,
  data() {
    return {
      active: false,
      deployed: false,
      hovered: false,
    };
  },
  props: {
    block: { type: Boolean, default: false },
    bordered: { type: Boolean, default: true },
    color: {
      type: String,
      default: "blue",
      validator(e) {
        return (
          -1 !==
          ["black", "blue", "green", "grey", "orange", "red", "white"].indexOf(
            e,
          )
        );
      },
    },
    disabled: { type: Boolean, default: false },
    id: { type: String, default: "" },
    inliner: { type: Boolean, default: false },
    items: {
      type: Array,
      default: null,
      validator(e) {
        return e.length > 0;
      },
    },
    leftIcon: { type: String, default: "" },
    leftIconHover: { type: String, default: "" },
    loading: { type: Boolean, default: false },
    reverse: { type: Boolean, default: false },
    rightIcon: { type: String, default: "" },
    rightIconHover: { type: String, default: "" },
    rounded: { type: Boolean, default: false },
    padded: { type: Boolean, default: false },
    size: { type: String, default: "default" },
    type: { type: String, default: "button" },
    tooltip: { type: String, default: "" },
    tooltipAlign: {
      type: String,
      default: "center",
      validator(e) {
        return -1 !== ["left", "center", "right"].indexOf(e);
      },
    },
    tooltipDirection: {
      type: String,
      default: "top",
      validator(e) {
        return -1 !== ["bottom", "middle", "top"].indexOf(e);
      },
    },
  },
  computed: {
    hotkeys() {
      return { esc: this.onCloseDeploy };
    },
    iconSize() {
      switch (this.size) {
        case "mini":
          return "14px";
        case "small":
          return "16px";
        case "default":
          return "18px";
        case "medium":
          return "20px";
        default:
          return "22px";
      }
    },
    computedRightIcon() {
      return this.items ? "chevron_down" : this.rightIcon;
    },
    attrs() {
      const attrs = { ...this.$attrs };
      /**
       * Workaround to avoid duplicated onClick
       */
      if (attrs["onClick"]) {
        delete attrs.onClick;
      }
      return attrs;
    },
  },
  methods: {
    onButtonClick(e) {
      if (this.items) {
        this.deployed = !this.deployed;
      } else {
        this.loading;
      }
      this.$emit("click", this.id, e);
    },
    onCloseDeploy() {
      this.items && this.deployed && (this.deployed = !1);
    },
    onItemsClick() {
      this.deployed = !1;
    },
    onItemsClickaway() {
      this.deployed = !1;
    },
    onItemClick(e) {
      e.disabled || ((this.deployed = !1), this.$emit("itemClick", e.id));
    },
  },
};
</script>
<style lang="scss">
.c-base-button {
  position: relative;
  z-index: 20;
  display: inline-block;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  background-color: transparent;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transition-duration: 0.1s;
  transition-duration: 0.1s;
  -webkit-transition-property:
    background-color,
    border-color,
    color,
    -webkit-transform;
  transition-property:
    background-color,
    border-color,
    color,
    -webkit-transform;
  transition-property: background-color, border-color, color, transform;
  transition-property:
    background-color,
    border-color,
    color,
    transform,
    -webkit-transform;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  .c-base-button__inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    .c-base-button__icon {
      -webkit-transition: fill 0.1s linear;
      transition: fill 0.1s linear;
    }
    .c-base-button__icon--left + .c-base-button__label {
      margin-left: 8px;
    }
    .c-base-button__icon--loading + .c-base-button__label {
      margin-left: 8px;
    }
    .c-base-button__icon--loading {
      -webkit-animation: spinner 1.1s linear 0.15s infinite;
      animation: spinner 1.1s linear 0.15s infinite;
    }
    .c-base-button__icon--right {
      margin-left: 4px;
      -webkit-transition: -webkit-transform 0.2s linear;
      transition: -webkit-transform 0.2s linear;
      transition: transform 0.2s linear;
      transition:
        transform 0.2s linear,
        -webkit-transform 0.2s linear;
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    .c-base-button__icon--deployed {
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
    }
  }
  .c-base-button__items {
    position: absolute;
    top: 40px;
    right: 0;
    padding: 4px 0;
    width: 290px;
    border: 1px solid #e3e8ef;
    border-radius: 2px;
    background-color: #fff;
    -webkit-box-shadow: 0 2px 3px 0 rgba(63, 83, 110, 0.15);
    box-shadow: 0 2px 3px 0 rgba(63, 83, 110, 0.15);
    text-align: left;
    -webkit-animation-name: translateBottomToTop;
    animation-name: translateBottomToTop;
    -webkit-animation-duration: 0.25s;
    animation-duration: 0.25s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    &:after {
      position: absolute;
      bottom: 100%;
      left: 267px;
      width: 0;
      height: 0;
      border: solid transparent;
      content: " ";
      pointer-events: none;
      margin-left: -5px;
      border-width: 5px;
      border-color: none;
      border-bottom-color: #fff;
    }
    &:before {
      position: absolute;
      bottom: 100%;
      left: 267px;
      width: 0;
      height: 0;
      border: solid transparent;
      content: " ";
      pointer-events: none;
      margin-left: -7px;
      border-width: 7px;
      border-color: none;
      border-bottom-color: #e3e8ef;
    }
    .c-base-button__item {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 8px 16px;
      background-color: #fff;
      color: #1c293b;
      .c-base-button__icon {
        margin-right: 10px;
        fill: #1c293b;
      }
      &:hover {
        &:not([disabled]) {
          &:not(.c-base-button--loading) {
            &:not(.c-base-button__item--disabled) {
              background-color: #0093ee;
              color: #fff;
              .c-base-button__icon {
                fill: #fff !important;
              }
            }
          }
        }
      }
      &:active {
        &:not([disabled]) {
          &:not(.c-base-button--loading) {
            &:not(.c-base-button__item--disabled) {
              background-color: #008adf;
              color: #fff;
              -webkit-transition: background-color 0.1s linear;
              transition: background-color 0.1s linear;
              .c-base-button__icon {
                fill: #fff !important;
              }
            }
          }
        }
      }
    }
    .c-base-button__item--disabled {
      background-color: #fafbfc;
      opacity: 0.5;
      cursor: not-allowed;
    }
    .c-base-button__item--red {
      color: #e0102b;
      .c-base-button__icon {
        fill: #e0102b !important;
      }
    }
    .c-base-button__item--blue {
      color: #0093ee;
      .c-base-button__icon {
        fill: #0093ee !important;
      }
    }
  }
  &:not(.c-base-button--bordered) {
    border: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
  .c-base-tooltip[data-align="left"] {
    margin-left: -4px;
    &:after {
      margin-left: 10px;
    }
  }
  .c-base-tooltip[data-align="right"] {
    margin-right: -4px;
    &:after {
      margin-right: 10px;
    }
  }
  &:active {
    &:not(.c-base-button--items) {
      &:not([disabled]) {
        &:not(.c-base-button--loading) {
          -webkit-box-shadow: 0 1px 2px 0 rgba(63, 83, 110, 0.15);
          box-shadow: 0 1px 2px 0 rgba(63, 83, 110, 0.15);
          -webkit-transform: translateY(1px);
          transform: translateY(1px);
        }
      }
    }
  }
  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
    .c-base-button__inner {
      pointer-events: none;
    }
  }
  &:disabled.u-has-tooltip {
    &:hover {
      opacity: 1;
      .c-base-button__inner {
        opacity: 0.4;
      }
    }
  }
}
.c-base-button--black {
  background-color: #25374f;
  color: #fff;
  .c-base-button__icon {
    fill: #fff;
  }
  &:hover {
    &:not([disabled]) {
      &:not(.c-base-button--loading) {
        background-color: #223248;
      }
    }
  }
}
.c-base-button--black.c-base-button--disabled {
  background-color: rgba(37, 55, 79, 0.4);
}
.c-base-button--black.c-base-button--loading {
  background-color: rgba(37, 55, 79, 0.65);
}
.c-base-button--black.c-base-button--reverse {
  border-color: #25374f;
  background-color: initial;
  color: #25374f;
  .c-base-button__icon {
    fill: #25374f;
  }
  &:hover {
    &:not([disabled]) {
      &:not(.c-base-button--loading) {
        background-color: #25374f;
      }
    }
  }
}
.c-base-button--black.c-base-button--reverse.c-base-button--loading {
  color: rgba(37, 55, 79, 0.65);
}
.c-base-button--grey {
  background-color: #d1d5db;
  color: #fff;
  .c-base-button__icon {
    fill: #fff;
  }
  &:hover {
    &:not([disabled]) {
      &:not(.c-base-button--loading) {
        background-color: #cbd0d7;
      }
    }
  }
}
.c-base-button--grey.c-base-button--disabled {
  background-color: rgba(209, 213, 219, 0.4);
}
.c-base-button--grey.c-base-button--loading {
  background-color: rgba(209, 213, 219, 0.65);
}
.c-base-button--grey.c-base-button--reverse {
  border-color: #d1d5db;
  background-color: initial;
  color: #6b7280;
  .c-base-button__icon {
    fill: #6b7280;
  }
  &:hover {
    &:not([disabled]) {
      &:not(.c-base-button--loading) {
        background-color: rgba(0, 0, 0, 0.05);
        color: #6b7280;
        .c-base-button__icon {
          fill: #6b7280;
        }
      }
    }
  }
}
.c-base-button--grey.c-base-button--reverse.c-base-button--loading {
  color: rgba(107, 114, 128, 0.65);
}
.c-base-button--blue {
  background-color: #0093ee;
  color: #fff;
  .c-base-button__icon {
    fill: #fff;
  }
  &:hover {
    &:not([disabled]) {
      &:not(.c-base-button--loading) {
        background-color: #008de4;
      }
    }
  }
}
.c-base-button--blue.c-base-button--disabled {
  background-color: rgba(0, 147, 238, 0.4);
}
.c-base-button--blue.c-base-button--loading {
  &:not(.c-base-button--reverse) {
    background-color: rgba(0, 147, 238, 0.65);
  }
}
.c-base-button--blue.c-base-button--reverse {
  border-color: #0093ee;
  background-color: initial;
  color: #0093ee;
  .c-base-button__icon {
    fill: #0093ee;
  }
  &:hover {
    &:not([disabled]) {
      &:not(.c-base-button--loading) {
        background-color: #0093ee;
      }
    }
  }
}
.c-base-button--blue.c-base-button--reverse.c-base-button--loading {
  color: rgba(0, 147, 238, 0.65);
}
.c-base-button--green {
  background-color: #40b630;
  color: #fff;
  .c-base-button__icon {
    fill: #fff;
  }
  &:hover {
    &:not([disabled]) {
      &:not(.c-base-button--loading) {
        background-color: #3dae2e;
      }
    }
  }
}
.c-base-button--green.c-base-button--disabled {
  background-color: rgba(64, 182, 48, 0.4);
}
.c-base-button--green.c-base-button--loading {
  background-color: rgba(64, 182, 48, 0.65);
}
.c-base-button--green.c-base-button--reverse {
  border-color: #40b630;
  background-color: initial;
  color: #40b630;
  .c-base-button__icon {
    fill: #40b630;
  }
  &:hover {
    &:not([disabled]) {
      &:not(.c-base-button--loading) {
        background-color: #40b630;
      }
    }
  }
}
.c-base-button--green.c-base-button--reverse.c-base-button--loading {
  color: rgba(64, 182, 48, 0.65);
}
.c-base-button--red {
  background-color: #e0102b;
  color: #fff;
  .c-base-button__icon {
    fill: #fff;
  }
  &:hover {
    &:not([disabled]) {
      &:not(.c-base-button--loading) {
        background-color: #d60f29;
      }
    }
  }
}
.c-base-button--red.c-base-button--disabled {
  background-color: rgba(224, 16, 43, 0.4);
}
.c-base-button--red.c-base-button--loading {
  background-color: rgba(224, 16, 43, 0.65);
}
.c-base-button--red.c-base-button--reverse {
  border-color: #e0102b;
  background-color: initial;
  color: #e0102b;
  .c-base-button__icon {
    fill: #e0102b;
  }
  &:hover {
    &:not([disabled]) {
      &:not(.c-base-button--loading) {
        background-color: #e0102b;
      }
    }
  }
}
.c-base-button--red.c-base-button--reverse.c-base-button--loading {
  color: rgba(224, 16, 43, 0.65);
}
.c-base-button--orange {
  background-color: #fd7b1f;
  color: #fff;
  .c-base-button__icon {
    fill: #fff;
  }
  &:hover {
    &:not([disabled]) {
      &:not(.c-base-button--loading) {
        background-color: #fd7515;
      }
    }
  }
}
.c-base-button--orange.c-base-button--disabled {
  background-color: rgba(253, 123, 31, 0.4);
}
.c-base-button--orange.c-base-button--loading {
  background-color: rgba(253, 123, 31, 0.65);
}
.c-base-button--orange.c-base-button--reverse {
  border-color: #fd7b1f;
  background-color: initial;
  color: #fd7b1f;
  .c-base-button__icon {
    fill: #fd7b1f;
  }
  &:hover {
    &:not([disabled]) {
      &:not(.c-base-button--loading) {
        background-color: #fd7b1f;
      }
    }
  }
}
.c-base-button--orange.c-base-button--reverse.c-base-button--loading {
  color: rgba(253, 123, 31, 0.65);
}
.c-base-button--white {
  background-color: #fff;
  color: rgba(0, 147, 238, 0.8);
  .c-base-button__icon {
    fill: rgba(0, 147, 238, 0.8);
  }
  &:hover {
    &:not([disabled]) {
      &:not(.c-base-button--loading) {
        background-color: #fafafa;
        color: #1c293b;
        .c-base-button__icon {
          fill: #1c293b;
        }
      }
    }
  }
}
.c-base-button--white.c-base-button--disabled {
  background-color: hsla(0, 0%, 100%, 0.4);
}
.c-base-button--white.c-base-button--loading {
  color: rgba(0, 147, 238, 0.5);
}
.c-base-button--white.c-base-button--reverse {
  border-color: #fff;
  background-color: initial;
  color: #fff;
  &:hover {
    &:not([disabled]) {
      &:not(.c-base-button--loading) {
        background-color: #fff;
        color: #1c293b;
        .c-base-button__icon {
          fill: #1c293b;
        }
      }
    }
  }
  .c-base-button__icon {
    fill: #fff;
  }
}
.c-base-button--mini {
  -webkit-box-shadow: 0 1px 1px 0 rgba(63, 83, 110, 0.05);
  box-shadow: 0 1px 1px 0 rgba(63, 83, 110, 0.05);
  padding: 6px 12px;
  font-size: 12px;
  line-height: 14px;
}
.c-base-button--mini.c-base-button--padded {
  padding-right: 30px;
  padding-left: 30px;
}
.c-base-button--small {
  padding: 8px 14px;
  font-size: 13px;
  line-height: 16px;
}
.c-base-button--small.c-base-button--padded {
  padding-right: 32px;
  padding-left: 32px;
}
.c-base-button--default {
  padding: 10px 16px;
  font-size: 14px;
  line-height: 18px;
}
.c-base-button--default.c-base-button--padded {
  padding-right: 34px;
  padding-left: 34px;
}
.c-base-button--medium {
  padding: 12px 18px;
  font-size: 15px;
  line-height: 20px;
}
.c-base-button--medium.c-base-button--padded {
  padding-right: 36px;
  padding-left: 36px;
}
.c-base-button--large {
  padding: 14px 20px;
  font-size: 16px;
  line-height: 22px;
}
.c-base-button--large.c-base-button--padded {
  padding-right: 38px;
  padding-left: 38px;
}
.c-base-button--block {
  display: block;
  width: 100%;
}
.c-base-button--disabled {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  -webkit-transform: none !important;
  transform: none !important;
}
.c-base-button--inliner {
  position: relative;
  white-space: nowrap;
  .c-base-button__inner {
    overflow: hidden;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    &:after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 22px;
      content: "";
    }
    .c-base-button__icon {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
    }
    .c-base-button__label {
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
    }
  }
}
// .c-base-button--inliner.c-base-button--black {
//   .c-base-button__inner {
//     &:after {
//       background-image: -webkit-gradient(
//         linear,
//         left top,
//         right top,
//         color-stop(0, transparent),
//         to(#000)
//       );
//       background-image: linear-gradient(90deg, transparent, #000);
//     }
//   }
// }
.c-base-button--inliner.c-base-button--blue {
  .c-base-button__inner {
    &:after {
      background-image: -webkit-gradient(
        linear,
        left top,
        right top,
        color-stop(0, rgba(0, 147, 238, 0)),
        to(#0093ee)
      );
      background-image: linear-gradient(90deg, rgba(0, 147, 238, 0), #0093ee);
    }
  }
}
.c-base-button--inliner.c-base-button--green {
  .c-base-button__inner {
    &:after {
      background-image: -webkit-gradient(
        linear,
        left top,
        right top,
        color-stop(0, rgba(64, 182, 48, 0)),
        to(#40b630)
      );
      background-image: linear-gradient(90deg, rgba(64, 182, 48, 0), #40b630);
    }
  }
}
.c-base-button--inliner.c-base-button--red {
  .c-base-button__inner {
    &:after {
      background-image: -webkit-gradient(
        linear,
        left top,
        right top,
        color-stop(0, rgba(224, 16, 43, 0)),
        to(#e0102b)
      );
      background-image: linear-gradient(90deg, rgba(224, 16, 43, 0), #e0102b);
    }
  }
}
.c-base-button--inliner.c-base-button--white {
  .c-base-button__inner {
    &:after {
      background-image: -webkit-gradient(
        linear,
        left top,
        right top,
        color-stop(0, hsla(0, 0%, 100%, 0)),
        to(#fff)
      );
      background-image: linear-gradient(90deg, hsla(0, 0%, 100%, 0), #fff);
    }
  }
}
.c-base-button--items {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.c-base-button--reverse {
  background-color: transparent;
  &:hover {
    &:not([disabled]) {
      &:not(.c-base-button--loading) {
        color: #fff;
        .c-base-button__icon {
          fill: #fff;
        }
      }
    }
  }
}
.c-base-button--rounded {
  border-radius: 60px;
}
.c-base-button--loading {
  cursor: not-allowed;
}
</style>
