<template>
  <div
    :class="[
      'o-full-screen',
      'o-full-screen--global',
      'c-base-popup',
      'c-base-popup--' + color,
      'c-base-popup--' + size,
      {
        'c-base-popup--has-previous': hasPrevious,
      },
    ]"
  >
    <div
      v-if="this.$slots.background"
      class="c-base-popup__background o-full-screen"
    >
      <slot></slot>
    </div>
    <div
      :style="{
        overflow: overflow,
        width: width,
      }"
      :class="[
        'c-base-popup__container',
        {
          [containerClass]: containerClass,
        },
      ]"
    >
      <div v-if="title" class="c-base-popup__header">
        <span
          v-if="hasPrevious"
          class="c-base-popup__previous"
          v-on:click="(...e) => onPrevious(...e)"
        >
          <BaseIcon
            class="c-base-popup__icon"
            cursor="pointer"
            name="chevron_left"
            size="20px"
          ></BaseIcon>
        </span>
        <div
          class="c-base-popup__title"
          v-on:click="(...e) => onPrevious(...e)"
        >
          {{ title }}
        </div>
        <a
          v-if="hasClose"
          v-on:click="(...e) => onClose(...e)"
          class="c-base-popup__close"
        >
          <p
            class="c-base-popup__icon"
            cursor="pointer"
            name="close"
            size="20px"
          ></p>
        </a>
      </div>

      <div v-if="this.$slots.quickTour" class="c-base-popup__quick-tour">
        <slot name="quickTour"></slot>
      </div>

      <div
        v-if="this.$slots.subheaderLeft && this.$slots.subheaderRight"
        class="c-base-popup__subheader"
      >
        <div v-if="this.$slots.subheaderLeft" class="c-base-popup__left">
          <slot name="subheaderLeft"></slot>
        </div>
        <div v-if="this.$slots.subheaderRight" class="c-base-popup__right">
          <slot name="subheaderRight"></slot>
        </div>
      </div>

      <div
        :style="{
          minHeight: minHeight,
          maxHeight: maxHeight,
          padding: padding,
          overflowY: 'hidden' === overflow ? 'auto' : null,
          textAlign: alignment,
        }"
        :class="[
          'c-base-popup__content',
          {
            [contentClass]: contentClass,
          },
        ]"
      >
        <slot></slot>
        <footer
          v-if="this.$slots.actions || this.$slots.description"
          :class="[
            'c-base-popup__footer',
            {
              [footerClass]: footerClass,
            },
          ]"
        >
          <div
            v-if="this.$slots.actions"
            :class="[
              'c-base-popup__actions',
              {
                [actionsClass]: actionsClass,
              },
            ]"
          >
            <slot name="actions"></slot>
          </div>

          <p v-if="this.$slots.description" class="c-base-popup__description">
            <slot name="description"></slot>
          </p>
        </footer>
      </div>
    </div>

    <BaseOverlay
      v-on:click="onOverlayClick"
      :has-close="hasOverlayClose"
    ></BaseOverlay>
  </div>
</template>
<script>
export default {
  props: {
    alignment: {
      type: String,
      default: "left",
    },
    color: {
      type: String,
      default: "white",
      validator(e) {
        return -1 !== ["blue", "white"].indexOf(e);
      },
    },
    containerClass: {
      type: String,
      default: null,
    },
    contentClass: {
      type: String,
      default: null,
    },
    footerClass: {
      type: String,
      default: null,
    },
    actionsClass: {
      type: String,
      default: null,
    },
    hasClose: {
      type: Boolean,
      default: !0,
    },
    hasOverlayClose: {
      type: Boolean,
      default: !1,
    },
    hasPrevious: {
      type: Boolean,
      default: !1,
    },
    minHeight: {
      type: String,
      default: null,
    },
    maxHeight: {
      type: String,
      default: null,
    },
    pauseHotkeys: {
      type: Boolean,
      default: !0,
    },
    overflow: {
      type: String,
      default: "hidden",
      validator(e) {
        return (
          -1 !==
          ["visible", "hidden", "scroll", "auto", "initial", "inherit"].indexOf(
            e,
          )
        );
      },
    },
    size: {
      type: String,
      default: "normal",
      validator(e) {
        return -1 !== ["normal", "medium", "wide", "large"].indexOf(e);
      },
    },
    title: {
      type: String,
      default: null,
    },
    width: {
      type: String,
      default: null,
    },
    padding: {
      type: String,
      default: "22px 30px",
    },
    parent: {
      type: String,
      default: null,
    },
  },
  emits: ["click", "back", "enter", "previous", "close"],
  computed: {
    hotkeys() {
      return {
        enter: this.onEnter,
        esc: this.onClose,
      };
    },
  },
  beforeMount() {
    if (document.activeElement) {
      document.activeElement.blur();
    }
    if (this.pauseHotkeys) {
      if (this.parent) {
        // this.$store.commit("hotkeys/unbindHotkeys", {
        //   parent: this.parent,
        // });
      }
    }
  },
  beforeUnmount() {
    if (this.pauseHotkeys) {
      if (this.parent) {
        // this.$store.commit("hotkeys/bindHotkeys", {
        //   parent: this.parent,
        // });
      }
    }
  },
  methods: {
    onBack() {
      this.$emit("back");
    },
    onClose() {
      this.hasClose &&
        (event.stopPropagation(), event.preventDefault(), this.$emit("close"));
    },
    onEnter(e) {
      e.stopPropagation(), e.preventDefault(), this.$emit("enter", e);
    },
    onPrevious() {
      this.hasPrevious && this.$emit("previous");
    },
    onOverlayClick() {
      ((!0 === this.hasClose && this.title) || this.hasOverlayClose) &&
        this.$emit("close");
    },
  },
};
</script>
<style lang="css">
.c-base-popup {
  z-index: 1000;
  -webkit-animation: fadeIn 0.15s linear;
  animation: fadeIn 0.15s linear;
}

.c-base-popup .c-base-popup__background {
  z-index: 1001;
}

.c-base-popup .c-base-popup__container {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1002;
  border-radius: 4px;
  -webkit-box-shadow: 0 3px 14px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 0 3px 14px 0 rgba(0, 0, 0, 0.25);
  opacity: 0;
  -webkit-animation: c-base-popup-move-to-top 0.15s ease-out 75ms;
  animation: c-base-popup-move-to-top 0.15s ease-out 75ms;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

.c-base-popup .c-base-popup__container .c-base-popup__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 16px 26px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: #008adf;
  color: #fff;
  font-weight: 700;
}

.c-base-popup
  .c-base-popup__container
  .c-base-popup__header
  .c-base-popup__close,
.c-base-popup
  .c-base-popup__container
  .c-base-popup__header
  .c-base-popup__previous {
  margin-top: -4px;
  margin-bottom: -4px;
  padding: 3px;
  border-radius: 2px;
  line-height: 0;
  -webkit-transition: background-color 0.1s linear;
  transition: background-color 0.1s linear;
  cursor: pointer;
}

.c-base-popup
  .c-base-popup__container
  .c-base-popup__header
  .c-base-popup__close
  .c-base-popup__icon,
.c-base-popup
  .c-base-popup__container
  .c-base-popup__header
  .c-base-popup__previous
  .c-base-popup__icon {
  fill: hsla(0, 0%, 100%, 0.75);
  -webkit-transition: fill 0.1s linear;
  transition: fill 0.1s linear;
}

.c-base-popup
  .c-base-popup__container
  .c-base-popup__header
  .c-base-popup__close:active
  .c-base-popup__icon,
.c-base-popup
  .c-base-popup__container
  .c-base-popup__header
  .c-base-popup__close:hover
  .c-base-popup__icon,
.c-base-popup
  .c-base-popup__container
  .c-base-popup__header
  .c-base-popup__previous:active
  .c-base-popup__icon,
.c-base-popup
  .c-base-popup__container
  .c-base-popup__header
  .c-base-popup__previous:hover
  .c-base-popup__icon {
  fill: #fff;
}

.c-base-popup
  .c-base-popup__container
  .c-base-popup__header
  .c-base-popup__close:hover,
.c-base-popup
  .c-base-popup__container
  .c-base-popup__header
  .c-base-popup__previous:hover {
  background-color: hsla(0, 0%, 100%, 0.1);
}

.c-base-popup
  .c-base-popup__container
  .c-base-popup__header
  .c-base-popup__close:active,
.c-base-popup
  .c-base-popup__container
  .c-base-popup__header
  .c-base-popup__previous:active {
  background-color: hsla(0, 0%, 100%, 0.2);
}

.c-base-popup
  .c-base-popup__container
  .c-base-popup__header
  .c-base-popup__previous {
  margin-right: 12px;
  margin-left: -4px;
}

.c-base-popup
  .c-base-popup__container
  .c-base-popup__header
  .c-base-popup__title {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-size: 15.5px;
}

.c-base-popup
  .c-base-popup__container
  .c-base-popup__header
  .c-base-popup__close {
  margin-right: -8px;
}

.c-base-popup .c-base-popup__container .c-base-popup__subheader {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 12px 40px;
  border-bottom: 1px solid #e3e8ef;
  background-color: #fafbfc;
}

.c-base-popup
  .c-base-popup__container
  .c-base-popup__subheader
  .c-base-popup__left,
.c-base-popup
  .c-base-popup__container
  .c-base-popup__subheader
  .c-base-popup__right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.c-base-popup
  .c-base-popup__container
  .c-base-popup__subheader
  .c-base-popup__right {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.c-base-popup
  .c-base-popup__container
  .c-base-popup__content
  .c-base-popup__footer
  .c-base-popup__actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin-top: 24px;
}

.c-base-popup
  .c-base-popup__container
  .c-base-popup__content
  .c-base-popup__footer
  .c-base-popup__actions
  > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  margin-right: 10px;
}

.c-base-popup
  .c-base-popup__container
  .c-base-popup__content
  .c-base-popup__footer
  .c-base-popup__actions
  > :last-of-type {
  margin-right: 0;
}

.c-base-popup
  .c-base-popup__container
  .c-base-popup__content
  .c-base-popup__footer
  .c-base-popup__description {
  margin: 20px 0 0;
  color: #75869c;
  font-size: 14px;
}

.c-base-popup--blue .c-base-popup__container {
  background-color: #0093ee;
}

.c-base-popup--white .c-base-popup__container {
  background-color: #fff;
}

.c-base-popup--normal .c-base-popup__container {
  min-width: 460px;
}

.c-base-popup--medium .c-base-popup__container {
  width: 600px;
}

.c-base-popup--wide .c-base-popup__container {
  width: 700px;
}

.c-base-popup--large .c-base-popup__container {
  width: 850px;
}

.c-base-popup--has-previous
  .c-base-popup__container
  .c-base-popup__header
  .c-base-popup__title {
  cursor: pointer;
}

@-webkit-keyframes c-base-popup-move-to-top {
  0% {
    opacity: 0;
    -webkit-transform: translate(-50%, -45%);
    transform: translate(-50%, -45%);
  }

  to {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}

@keyframes c-base-popup-move-to-top {
  0% {
    opacity: 0;
    -webkit-transform: translate(-50%, -45%);
    transform: translate(-50%, -45%);
  }

  to {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}

@media (max-width: 56.25em) {
  .c-base-popup--large .c-base-popup__container {
    width: calc(100% - 18px) !important;
  }
}

@media (max-width: 43.125em) {
  .c-base-popup .c-base-popup__container {
    width: 80% !important;
    min-width: 0 !important;
  }

  .c-base-popup .c-base-popup__container .c-base-popup__content {
    width: 100% !important;
  }

  .c-base-popup--large .c-base-popup__container {
    width: calc(100% - 12px) !important;
  }
}

@media (max-width: 34.3125em) {
  .c-base-popup .c-base-popup__container {
    width: 90% !important;
  }

  .c-base-popup--large .c-base-popup__container {
    width: calc(100% - 8px) !important;
  }
}

@media (max-width: 28.125em) {
  .c-base-popup__actions {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  .c-base-popup__actions .c-base-button {
    margin-right: 0 !important;
    margin-top: 10px !important;
  }
}
</style>
