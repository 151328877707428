<template>
  <span
    :class="[
      'c-base-badge',
      'c-base-badge--' + color,
      'c-base-badge--' + size,
      {
        'c-base-badge--filled': filled,
        'c-base-badge--uppercase': uppercase,
      },
    ]"
  >
    <slot></slot>
  </span>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: "blue",
      validator(e) {
        return (
          -1 !==
          [
            "black",
            "blue",
            "green",
            "grey",
            "orange",
            "purple",
            "red",
            "yellow".indexOf(e),
          ]
        );
      },
    },
    filled: {
      type: Boolean,
      default: !1,
    },
    size: {
      type: String,
      default: "default",
    },
    uppercase: {
      type: Boolean,
      default: !0,
    },
  },
};
</script>
<style lang="scss">
.c-base-badge {
  display: inline-block;
  border-width: 1px;
  border-style: solid;
  border-radius: 100px;
  font-weight: 700;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.c-base-badge--black {
  border-color: #2c405a;
  color: #2c405a;
}
.c-base-badge--black.c-base-badge--filled {
  background-color: #2c405a;
}
.c-base-badge--blue {
  border-color: #0093ee;
  color: #0093ee;
}
.c-base-badge--blue.c-base-badge--filled {
  background-color: #0093ee;
}
.c-base-badge--green {
  border-color: #40b630;
  color: #40b630;
}
.c-base-badge--green.c-base-badge--filled {
  background-color: #40b630;
}
.c-base-badge--grey {
  border-color: #75869c;
  color: #75869c;
}
.c-base-badge--grey.c-base-badge--filled {
  background-color: #75869c;
}
.c-base-badge--orange {
  border-color: #fd7b1f;
  color: #fd7b1f;
}
.c-base-badge--orange.c-base-badge--filled {
  background-color: #fd7b1f;
}
.c-base-badge--purple {
  border-color: #ab7df6;
  color: #ab7df6;
}
.c-base-badge--purple.c-base-badge--filled {
  background-color: #ab7df6;
}
.c-base-badge--red {
  border-color: #e0102b;
  color: #e0102b;
}
.c-base-badge--red.c-base-badge--filled {
  background-color: #e0102b;
}
.c-base-badge--yellow {
  border-color: #faca00;
  color: #faca00;
}
.c-base-badge--yellow.c-base-badge--filled {
  background-color: #faca00;
}
.c-base-badge--mini {
  padding: 0 10px;
  font-size: 12px;
  line-height: 21px;
}
.c-base-badge--small {
  padding: 0 11px;
  font-size: 13px;
  line-height: 23px;
}
.c-base-badge--default {
  padding: 0 12px;
  font-size: 14px;
  line-height: 25px;
}
.c-base-badge--medium {
  padding: 0 13px;
  font-size: 15px;
  line-height: 27px;
}
.c-base-badge--large {
  padding: 0 14px;
  font-size: 16px;
  line-height: 29px;
}
.c-base-badge--filled {
  color: #fff;
}
.c-base-badge--uppercase {
  text-transform: uppercase;
}
</style>
