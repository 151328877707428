<template>
  <span
    :class="[
      'c-base-availability',
      'u-has-tooltip',
      'c-base-availability--' + color,
      'c-base-availability--' + size,
    ]"
  >
    <BaseTooltip
      v-if="tooltip && 'green' === color"
      direction="bottom"
      align="left"
    >
      {{ tooltip }} {{ this.$t("TOOLTIPS.IS_ONLINE") }}
    </BaseTooltip>

    <BaseTooltip
      v-if="tooltip && 'orange' === color"
      direction="bottom"
      align="left"
    >
      {{ tooltip }} {{ this.$t("TOOLTIPS.IS_AWAY") }}
    </BaseTooltip>
  </span>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: "green",
      validator(e) {
        return -1 !== ["blue", "green", "grey", "orange", "red"].indexOf(e);
      },
    },
    size: {
      type: String,
      default: "normal",
      validator(e) {
        return (
          -1 !==
          ["mini", "small", "normal", "medium", "big", "large", "huge"].indexOf(
            e,
          )
        );
      },
    },
    tooltip: {
      type: String,
      default: null,
    },
  },
};
</script>
<style lang="scss">
.c-base-availability {
  display: inline-block;
  border-radius: 100%;
  .c-base-tooltip[data-align="left"] {
    margin-left: -4px;
    &:after {
      margin-left: 5px;
    }
  }
}
.c-base-availability--blue {
  background-color: #0093ee;
}
.c-base-availability--green {
  background-color: #4ece3d;
}
.c-base-availability--grey {
  background-color: #75869c;
}
.c-base-availability--orange {
  background-color: #fd7b1f;
}
.c-base-availability--red {
  background-color: #e0102b;
}
.c-base-availability--mini {
  width: 6px;
  height: 6px;
}
.c-base-availability--normal {
  width: 8px;
  height: 8px;
}
.c-base-availability--small {
  width: 8px;
  height: 8px;
}
.c-base-availability--medium {
  width: 10px;
  height: 10px;
}
.c-base-availability--big {
  width: 12px;
  height: 12px;
}
.c-base-availability--huge {
  width: 14px;
  height: 14px;
}
</style>
