<template>
  <div
    v-if="preview.show"
    class="c-file-preview o-full-screen"
    v-on:click="(...e) => onClose && onClose(...e)"
  >
    <BaseOverlay class="c-file-preview__overlay" v-on:close="onClose" />
    <BaseSpinner
      class="c-file-preview__wait"
      :loading="preview.loading"
      color="#ffffff"
      speed="1500ms"
    />
    <div class="c-file-preview__inner">
      <template v-if="'file' === preview.type">
        <video
          v-if="isVideo"
          v-on:loadeddata="(...e) => onLoad && onLoad(...e)"
          :src="preview.contentUrl"
          :class="[
            'c-file-preview__resource',
            'c-file-preview__resource--video',
          ]"
          autoplay
          controls
        ></video>
        <img
          v-else
          v-on:click="(...e) => onClose && onClose(...e)"
          @load="onLoad"
          :class="[
            'c-file-preview__resource',
            'c-file-preview__resource--image',
          ]"
          :src="preview.contentUrl"
        />
      </template>
      <iframe
        v-else-if="'embed' === preview.type"
        v-on:loadeddata="(...e) => onLoad && onLoad(...e)"
        v-on:click="(...e) => onClose && onClose(...e)"
        :src="preview.contentUrl"
        :class="[
          'c-file-preview__resource',
          'c-file-preview__resource--' + preview.type,
        ]"
        type="text/html"
        frameborder="0"
        allow="autoplay; fullscreen"
        sandbox="allow-popups allow-same-origin allow-scripts"
      ></iframe>
      <iframe
        v-else-if="'document' === preview.type"
        v-on:click="(...e) => onClose && onClose(...e)"
        :srcdoc="preview.contentUrl"
        :class="[
          'c-file-preview__resource',
          'c-file-preview__resource--' + preview.type,
        ]"
        type="text/html"
        frameborder="0"
        sandbox="allow-popups allow-popups-to-escape-sandbox"
      ></iframe>
      <div v-if="!preview.loading" class="c-file-preview__controls">
        <BaseControl
          v-for="(control, idx) in getControls()"
          :key="idx"
          v-on:click="onControlClick"
          :buttons="control.buttons"
          :color="control.color"
          :data-id="control.id"
          :class="[
            'c-file-preview__control',
            {
              'c-file-preview__control--invisible': false === control.visible,
            },
          ]"
          shadowy=""
        ></BaseControl>
      </div>
    </div>
  </div>
</template>
<script>
import $env from "@/enviroment";
import FilePreview from "@/components/FilePreview.vue";
import linkHelper from "@/classes/linkHelper";

import mitt from "mitt";
const d = mitt();
const c = 100;

export default {
  data() {
    return {
      preview: {
        show: false,
        loading: false,
        download: !0,
        type: "file",
        contentUrl: "",
        contentType: "",
        fingerprint: null,
        fingerprints: [],
        messages: [],
        websiteId: "",
        sessionId: "",
      },
      previousControl: [
        {
          id: "previous",
          buttons: [
            {
              id: "navigate_previous",
              icon: "chevron_left",
              iconSize: "34px",
              color: "grey",
            },
          ],
        },
      ],
      nextControl: [
        {
          id: "next",
          buttons: [
            {
              id: "navigate_next",
              icon: "chevron_right",
              iconSize: "34px",
              color: "grey",
            },
          ],
        },
      ],
      downloadControl: [
        {
          id: "download",
          buttons: [
            {
              id: "download",
              icon: "cloud_download",
              iconSize: "28px",
              color: "grey",
            },
          ],
        },
      ],
      controls: [
        {
          id: "close",
          buttons: [
            {
              id: "close",
              icon: "close",
              iconSize: "28px",
              color: "grey",
            },
          ],
        },
      ],
    };
  },
  computed: {
    isVideo() {
      return (
        -1 !==
        this.$CONFIG.CRISP_FILE_PREVIEW_VIDEOS.indexOf(this.preview.contentType)
      );
    },
  },
  created() {
    document.addEventListener("keyup", this.onKeyup);
    d.on("showPreview", (e) => {
      this.preview.websiteId = e.websiteId;
      this.preview.sessionId = e.sessionId;
      this.preview.type = e.type;
      this.preview.contentUrl = e.contentUrl;
      this.preview.contentType = e.contentType;
      this.preview.messages = e.messages;
      this.preview.fingerprint = e.fingerprint;
      this.preview.fingerprints = e.fingerprints || {};
      this.preview.download = false !== e.download;
      "file" === e.type
        ? (this.preview.loading = !0)
        : (this.preview.loading = false);
      this.preview.show = !0;
    });
    d.on("closePreview", () => {
      this.preview.show = false;
    });
    window.playVideo = (e, t) => {
      this.onPlayVideo(e, t);
    };
    window.playVideoFromElement = (e) => {
      let t = e.target;
      if (t) {
        let e = t.getAttribute("data-play-type"),
          i = t.getAttribute("data-play-id");
        e && i && window.playVideo(e, i);
      }
    };
    window.magnifyImage = (e, t = !0) => {
      this.onMagnifyImage(e, t);
    };
  },
  beforeUnmount() {
    document.removeEventListener("keyup", this.onKeyup);
    d.off("showPreview");
    d.off("closePreview");
  },
  show(e, t, i, a, o, n, r = null, disableDownload = false) {
    let s = [];
    if ("file" === o) {
      s = this.getMagnifyFileFingerprints(a, i);
    }

    d.emit("showPreview", {
      websiteId: e,
      sessionId: t,
      messages: i,
      fingerprint: a,
      fingerprints: s,
      type: o,
      contentUrl: n,
      contentType: r,
      download: !disableDownload,
    });
  },
  canMessageBePreviewed(message) {
    return (
      ("file" === message.messageKey &&
        -1 !==
          $env.CRISP_FILE_PREVIEW_IMAGES.indexOf(
            message.messageValue.mimeType,
          )) ||
      -1 !==
        $env.CRISP_FILE_PREVIEW_VIDEOS.indexOf(message.messageValue.mimeType)
    );
  },
  getMagnifyFileFingerprints(currentFingerprint, messages = []) {
    let i = {
      previous: null,
      next: null,
    };
    if (null !== currentFingerprint) {
      let _currentIdx = messages.findIndex((message) => {
        return message.messageFingerprint === currentFingerprint;
      });
      let _prevMessage = messages[_currentIdx - 1];
      if (_prevMessage) {
        if (this.canMessageBePreviewed(_prevMessage)) {
          i.previous = _prevMessage.messageFingerprint;
        }
      }
      let _nextMessage = messages[_currentIdx + 1];
      if (_nextMessage) {
        if (this.canMessageBePreviewed(_nextMessage)) {
          i.next = _nextMessage.messageFingerprint;
        }
      }
    }
    return i;
  },
  methods: {
    getControls() {
      let e = [],
        t = [Object.assign({}, this.previousControl[0])];
      this.preview.fingerprints.previous || (t[0].visible = false),
        (e = e.concat(t)),
        "file" === this.preview.type &&
          false !== this.preview.download &&
          (e = e.concat(this.downloadControl)),
        (e = e.concat(this.controls));
      let i = [Object.assign({}, this.nextControl[0])];
      return (
        this.preview.fingerprints.next || (i[0].visible = false),
        (e = e.concat(i)),
        e
      );
    },
    showWithFingerprint(fingerprint = null) {
      if (null !== fingerprint) {
        let message = this.preview.messages.find((message) => {
          return message.messageFingerprint === fingerprint;
        });
        if (message) {
          this.onClose();
          setTimeout(() => {
            FilePreview.show(
              this.preview.websiteId,
              this.preview.sessionId,
              this.preview.messages,
              message.messageFingerprint,
              this.preview.type,
              message.messageValue.address,
              message.messageValue.tymimeTypepe,
            );
          }, c);
        }
      }
    },
    onKeyup(e) {
      if (this.preview.show) {
        e.preventDefault();
        if (27 === e.keyCode) {
          this.onClose();
        } else if (37 !== e.keyCode && 38 !== e.keyCode) {
          this.onNext();
        } else if (39 !== e.keyCode && 40 !== e.keyCode) {
          this.onPrevious();
        }
      }
    },
    onClose() {
      d.emit("closePreview");
    },
    onLoad() {
      this.preview.loading = false;
    },
    onPlayVideo(e, t) {
      let i = null;
      switch (e) {
        case "youtube":
          i = `https://www.youtube.com/embed/${encodeURIComponent(
            t,
          )}?autoplay=1&rel=0`;
          break;
        case "dailymotion":
          i =
            "https://www.dailymotion.com/embed/video/" + encodeURIComponent(t);
          break;
      }
      d.emit("showPreview", {
        type: "embed",
        contentUrl: i,
      });
    },
    onMagnifyImage(e, t = !0) {
      d.emit("showPreview", {
        type: "file",
        contentUrl: e,
        download: t,
      });
    },
    onPrevious() {
      this.preview.fingerprints.previous &&
        this.showWithFingerprint(this.preview.fingerprints.previous);
    },
    onNext() {
      this.preview.fingerprints.next &&
        this.showWithFingerprint(this.preview.fingerprints.next);
    },
    onDownload() {
      linkHelper.openExternal(this.preview.contentUrl);
    },
    onControlClick(e) {
      switch (e) {
        case "close":
          this.onClose();
          break;
        case "download":
          this.onDownload();
          break;
        case "navigate_previous":
          this.onPrevious();
          break;
        case "navigate_next":
          this.onNext();
          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.c-file-preview {
  z-index: 1001;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 0.15s;
  animation-duration: 0.15s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
  .c-file-preview__inner {
    position: fixed;
    z-index: 10001;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 60px 24px 98px;
    .c-file-preview__resource {
      display: block;
      margin-right: auto;
      margin-left: auto;
      border-radius: 2px;
      background-color: #fff;
      -webkit-animation-name: fadeIn;
      animation-name: fadeIn;
      -webkit-animation-duration: 0.4s;
      animation-duration: 0.4s;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
    }
    .c-file-preview__resource--image {
      max-width: 100%;
      max-height: 100%;
    }
    .c-file-preview__resource--video {
      display: block;
      width: 100%;
      height: 100%;
      background-color: #000;
      &:hover {
        cursor: auto !important;
      }
    }
    .c-file-preview__resource--default {
      display: block;
      width: 100%;
      height: 100%;
      background-color: #fff;
      &:hover {
        cursor: auto !important;
      }
    }
    .c-file-preview__resource--embed {
      display: block;
      width: 100%;
      height: 100%;
      background-color: #000;
    }
    .c-file-preview__resource--document {
      display: block;
      width: 100%;
      height: 100%;
      background-color: #fff;
    }
    .c-file-preview__controls {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      margin-top: 20px;
      padding-bottom: 20px;
      height: 52px;
      .c-file-preview__control {
        margin-right: 14px;
        &:last-of-type {
          margin-right: 0;
        }
      }
      .c-file-preview__control[data-id="close"] {
        margin-right: 0;
      }
      .c-file-preview__control[data-id="previous"] {
        margin-right: 46px;
      }
      .c-file-preview__control[data-id="next"] {
        margin-left: 46px;
      }
      .c-file-preview__control--invisible {
        visibility: hidden;
        pointer-events: none;
      }
    }
  }
  .c-file-preview__wait {
    position: fixed;
    z-index: 10001;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}
</style>
