import a from "@/enviroment.js";

class _colorPalette {
  palette(e, t = "default") {
    let i = 0,
      o = t.toUpperCase();
    for (let a = 0; a < e.length; a++) i += e.charCodeAt(a);
    return a.CRISP_COLOR_PALETTES[o][i % a.CRISP_COLOR_PALETTES[o].length];
  }
}

export default new _colorPalette();
