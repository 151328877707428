class _crispRipple {
  init(e) {
    let t = this;
    e.directive("crispRipple", {
      beforeMount(e, i) {
        return t.bind(e, i);
      },
    });
  }
  bind(e, t) {
    e.style.position = "relative";
    e.style.overflow = "hidden";
    e.style.userSelect = "none";
    e.style.msUserSelect = "none";
    e.style.mozUserSelect = "none";
    e.style.webkitUserSelect = "none";
    t.instance.createRipple = (t) => {
      let i = document.createElement("span"),
        a = e.getBoundingClientRect(),
        o = Math.max(a.height, a.width),
        n = t.pageX - a.left - o / 2 - document.body.scrollLeft,
        r = t.pageY - a.top - o / 2 - document.body.scrollTop;
      (i.className = "ripple-effect"),
        (i.style.width = i.style.height = o + "px"),
        (i.style.left = n + "px"),
        (i.style.top = r + "px"),
        i.addEventListener("animationend", () => {
          e.removeChild(i);
        }),
        e.appendChild(i);
    };
    e.addEventListener("click", t.instance.createRipple);
  }
}
export default new _crispRipple();
