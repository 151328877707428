<template>
  <div
    v-on:click="(...e) => onClick(...e)"
    :class="[
      'c-base-language',
      {
        'c-base-language--disabled': disabled,
      },
    ]"
  >
    <BaseFlag
      class="c-conversation-box-content-message-bubble__flag"
      :code="flag"
      height="18px"
      width="26px"
    >
    </BaseFlag>
    <span class="c-base-language__name">{{ name }}</span>
  </div>
</template>
<script>
import chatapiCountryLanguages from "talqui-countries-languages";
export default {
  props: {
    disabled: {
      type: Boolean,
      default: !1,
    },
    locale: {
      type: String,
      required: !0,
    },
  },
  emits: ["click"],
  computed: {
    name() {
      return this.$filters.locale.localeRead(this.locale);
    },
    flag() {
      let e = this.$filters.locale.parseLocale(this.locale);
      return (
        e.country || chatapiCountryLanguages.getLanguageCountry(this.locale)
      );
    },
  },
  methods: {
    onClick() {
      this.disabled || this.$emit("click");
    },
  },
};
</script>
<style lang="scss">
.c-base-language {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  .c-base-language__flag {
    border: 1px solid #e3e8ef;
  }
  .c-base-language__name {
    margin-left: 8px;
    color: #0093ee;
    text-decoration: underline;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    -webkit-transition: color 0.2s linear;
    transition: color 0.2s linear;
  }
  &:hover {
    &:not(.c-base-language--disabled) {
      cursor: pointer;
      .c-base-language__name {
        color: #3f536e;
      }
    }
  }
}
.c-base-language--disabled {
  .c-base-language__name {
    color: #1c293b;
    text-decoration: none;
  }
}
</style>
