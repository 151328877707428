<template>
  <div
    :class="[
      'c-base-overlay',
      'o-full-screen',
      {
        'c-base-overlay--light': light,
      },
    ]"
    v-on:click="(...e) => onClick(...e)"
  >
    <BaseIcon
      v-if="hasClose"
      v-on:click="onClose"
      :class="[
        'c-base-overlay__close',
        {
          [closeClass]: closeClass,
        },
      ]"
      cursor="pointer"
      name="close"
      size="30px"
    ></BaseIcon>
    <div v-if="this.$slots.default" class="c-base-overlay__slot">
      <slot></slot>
    </div>
    <div
      class="c-base-overlay__screen"
      v-on:click="(...e) => onClose(...e)"
    ></div>
  </div>
</template>
<script>
export default {
  props: {
    light: {
      type: Boolean,
      default: !1,
    },
    hasClose: {
      type: Boolean,
      default: !1,
    },
    closeClass: {
      type: String,
      default: null,
    },
  },
  emits: ["click", "close"],
  methods: {
    onClick() {
      this.$emit("click");
    },
    onClose() {
      !0 === this.hasClose && this.$emit("close");
    },
  },
};
</script>
<style lang="scss">
.c-base-overlay {
  z-index: 1000;
  .c-base-overlay__close {
    position: absolute;
    top: 14px;
    right: 14px;
    z-index: 101;
    fill: hsla(0, 0%, 100%, 0.75);
    -webkit-transition: fill 0.1s linear;
    transition: fill 0.1s linear;
    &:hover {
      fill: #fff;
    }
  }
  .c-base-overlay__screen {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    background-color: #131820;
    opacity: 0.8;
  }
}
.c-base-overlay--light {
  .c-base-overlay__screen {
    opacity: 0.4;
  }
}
</style>
