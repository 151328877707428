import a from "talqui-countries-languages";
import o from "@/enviroment";
import n from "@/classes/i18n.js";
import r from "@/classes/filters.js";

let l;
let d = {
  getLocalizedCountryName: {},
  getLocalizedLanguageName: {},
};
var localeParser = null;

class _locale {
  localeRead(e, t = !0, i = !1, a = !1) {
    let o = "",
      n = this.parseLocale(e);
    if (((o += this.getLocalizedLanguageName(n.language)), t && n.country))
      if (!0 === i) {
        let e = this.getLocalizedCountryName(n.country);
        e && (o += ` (${e})`);
      } else o += ` (${n.country.toUpperCase()})`;
    return a && n.alphabet && (o += ` (${n.alphabet.toUpperCase()})`), o;
  }

  countryForLocale(e) {
    const t = this.parseLocale(e);
    return (
      t.country ||
        (t.country = (
          o.CRISP_LOCALES_TO_COUNTRIES[t.language] || ""
        ).toLowerCase()),
      t.country || t.language
    );
  }

  getLocalizedCountryName(e) {
    let t = d.getLocalizedCountryName[e];
    if (!t) {
      if (!localeParser) {
        localeParser = new Intl.DisplayNames([n.getInstance().locale], {
          type: "region",
        });
      }

      try {
        const locale = localeParser?.of(e.toUpperCase()) || null;
        if (locale) {
          return r.titlecase(locale);
        }
        return e;
      } catch (err) {
        console.log("getLocalizedCountryName:error", err);
        return null;
      }
    }

    return t;
  }

  getLocalizedLanguageName(e) {
    let langName = d.getLocalizedLanguageName[e];
    if (!langName) {
      l ||
        (l = new Intl.DisplayNames([n.getInstance().locale], {
          type: "language",
          languageDisplay: "standard",
        }));
      try {
        langName = l.of(e);
        langName && langName !== e && (langName = r.titlecase(langName));
        //eslint-disable-next-line
      } catch {}
      (langName && langName !== e) || (langName = (0, a.getLanguageName)(e)),
        langName && (d.getLocalizedLanguageName[e] = langName);
    }
    return langName;
  }

  parseLocale(e) {
    let t = e.split("-"),
      i = t[0],
      a = null,
      o = null;
    return (
      t[2] ? ((a = t[1]), (o = t[2])) : t[1] && (o = t[1]),
      {
        language: i,
        country: o,
        alphabet: a,
      }
    );
  }

  parseLocales(e) {
    let t = e
      .map((e) => ({
        ...this.parseLocale(e),
        originalCode: e,
      }))
      .filter((e, t, i) => {
        let a = i.findIndex((t) => t.language === e.language);
        return a === t;
      })
      .map((e) => e.originalCode.toLowerCase());
    return t;
  }

  isUNM49Code(e) {
    return e && !isNaN(e) && !isNaN(parseFloat(e));
  }
}

export default new _locale();
