// import a from "@/classes/helpers.js";
import o from "@/classes/websites.js";

// const r = 10;

export default {
  namespaced: !0,
  state: {
    filters: {},
  },
  getters: {
    getFilters: (e) => (tenantID, type) => e.filters[tenantID][type],
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    acquireFilters({ commit: e }, { websiteId: t, type: i }) {
      // return (
      //   e("assertFilters", {
      //     websiteId: t,
      //     type: i,
      //   }),
      //   a.default
      //     .pageResolverToEnd(
      //       (e) =>
      //         o.filters(t, e).get({
      //           type: i,
      //         }),
      //       r
      //     )
      //     .catch(() => Promise.resolve([]))
      //     .then(
      //       (a) => (
      //         e("setFilters", {
      //           websiteId: t,
      //           type: i,
      //           value: a,
      //         }),
      //         Promise.resolve(a)
      //       )
      //     )
      // );
      return Promise.resolve({});
    },
    createFilter({ commit: e }, { websiteId: t, type: i, value: a }) {
      return o
        .filter(t)
        .post(a)
        .then(
          (o) => (
            (a.filter_id = o.filter_id),
            e("addFilter", {
              websiteId: t,
              type: i,
              value: a,
            }),
            Promise.resolve(o)
          ),
        );
    },
    removeFilter({ commit: e }, { websiteId: t, type: i, filterId: a }) {
      return o
        .filterOne(t, a)
        .delete()
        .then(
          () => (
            e("removeFilter", {
              websiteId: t,
              type: i,
              filterId: a,
            }),
            Promise.resolve()
          ),
        );
    },
    updateFilter({ commit: e }, { websiteId: t, type: i, value: a }) {
      return o
        .filterOne(t, a.filter_id)
        .put(a)
        .then(
          () => (
            e("updateFilter", {
              websiteId: t,
              type: i,
              value: a,
            }),
            Promise.resolve()
          ),
        );
    },
  },
  mutations: {
    assertFilters(e, { websiteId: t, type: i }) {
      e.filters[t] || (e.filters[t] = {}),
        e.filters[t][i] || (e.filters[t][i] = []);
    },
    removeFilter(e, { websiteId: t, type: i, filterId: a }) {
      const o = e.filters[t][i].findIndex((e) => e.filter_id === a);
      -1 !== o && e.filters[t][i].splice(o, 1);
    },
    addFilter(e, { websiteId: t, type: i, value: a }) {
      e.filters[t][i].push(a);
    },
    setFilters(e, { websiteId: t, type: i, value: a }) {
      e.filters[t][i] = a;
    },
    updateFilter(e, { websiteId: t, type: i, value: a }) {
      const o = e.filters[t][i].findIndex((e) => e.filter_id === a.filter_id);
      -1 !== o && (e.filters[t][i][o] = a);
    },
  },
};
