import $env from "@/enviroment.js";

class _platform {
  isPlatform() {
    var e = !1;
    return "true" === $env.CRISP_IS_PACKAGE && (e = !0), e;
  }
  getOs() {
    return this.isPlatform() ? window.CommonPlatformBridge.getOS() : "none";
  }
  isEnvironmentProduction() {
    return "production" === $env.CRISP_ENVIRONEMENT && !0;
  }
  isUpdateCapable() {
    return !!(
      $env.CRISP_PLATFORM_UPDATE_FEED_URL &&
      this.isEnvironmentProduction() &&
      this.isPlatform()
    );
  }
  isFullScreen() {
    return !!this.isPlatform() && window.CommonPlatformBridge.isFullScreen();
  }
}

export default new _platform();
