<template>
  <div
    :class="[
      'c-base-progress',
      'c-base-progress--' + color,
      'c-base-progress--' + direction,
      {
        'c-base-progress--colored-labels': coloredLabels,
      },
    ]"
  >
    <span
      v-if="labelMain || labelSecondary"
      class="c-base-progress__information"
    >
      <span v-if="labelMain" class="c-base-progress__label-main">{{
        labelMain
      }}</span>
      <span v-if="labelSecondary" class="c-base-progress__label-secondary">{{
        labelSecondary
      }}</span>
    </span>

    <div class="c-base-progress__container">
      <div class="c-base-progress__bar"></div>
      <div
        :style="{
          width: computedProgress + '%',
        }"
        :class="[
          'c-base-progress__progress',
          {
            'c-base-progress__progress--animate': animate,
          },
        ]"
      ></div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: "blue",
      validator(e) {
        return (
          -1 !==
          [
            "black",
            "blue",
            "green",
            "grey",
            "orange",
            "purple",
            "red",
            "turquoise",
            "white",
            "yellow",
          ].indexOf(e)
        );
      },
    },
    direction: {
      type: String,
      default: "vertical",
      validator(e) {
        return -1 !== ["horizontal", "vertical"].indexOf(e);
      },
    },
    labelSecondary: {
      type: [String, Number],
      default: null,
    },
    labelMain: {
      type: [String, Number],
      default: null,
    },
    progress: {
      type: Number,
      default: 0,
      validator(e) {
        return e >= 0 && e <= 100;
      },
    },
    coloredLabels: {
      type: Boolean,
      default: !1,
    },
    animate: {
      type: Boolean,
      default: !1,
    },
  },
  computed: {
    computedProgress() {
      return this.progress < 0 ? 0 : this.progress <= 100 ? this.progress : 100;
    },
  },
};
</script>
<style lang="scss">
.c-base-progress {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 14px;
  .c-base-progress__information {
    color: #75869c;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    .c-base-progress__label-main {
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
      min-width: 35px;
      font-weight: 700;
    }
    .c-base-progress__label-secondary {
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
      text-align: right;
    }
  }
  .c-base-progress__container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    .c-base-progress__bar {
      height: 6px;
      border-radius: 10px;
      margin-bottom: -6px;
      opacity: 0.15;
    }
    .c-base-progress__progress {
      height: 6px;
      border-radius: 10px;
      width: 0;
    }
    .c-base-progress__progress--animate {
      background-image: linear-gradient(
        -45deg,
        hsla(0, 0%, 100%, 0.2) 25%,
        transparent 0,
        transparent 50%,
        hsla(0, 0%, 100%, 0.2) 0,
        hsla(0, 0%, 100%, 0.2) 75%,
        transparent 0,
        transparent
      );
      -webkit-transition: width 0.25s ease;
      transition: width 0.25s ease;
      background-size: 12px 12px;
      -webkit-animation: progress 2s linear infinite;
      animation: progress 2s linear infinite;
    }
  }
}
.c-base-progress--black {
  .c-base-progress__container {
    .c-base-progress__bar {
      background-color: #25374f;
    }
    .c-base-progress__progress {
      background-color: #25374f;
    }
  }
}
.c-base-progress--blue {
  .c-base-progress__container {
    .c-base-progress__bar {
      background-color: #0093ee;
    }
    .c-base-progress__progress {
      background-color: #0093ee;
    }
  }
}
.c-base-progress--green {
  .c-base-progress__container {
    .c-base-progress__bar {
      background-color: #40b630;
    }
    .c-base-progress__progress {
      background-color: #40b630;
    }
  }
}
.c-base-progress--grey {
  .c-base-progress__container {
    .c-base-progress__bar {
      background-color: #75869c;
    }
    .c-base-progress__progress {
      background-color: #75869c;
    }
  }
}
.c-base-progress--orange {
  .c-base-progress__container {
    .c-base-progress__bar {
      background-color: #fd7b1f;
    }
    .c-base-progress__progress {
      background-color: #fd7b1f;
    }
  }
}
.c-base-progress--purple {
  .c-base-progress__container {
    .c-base-progress__bar {
      background-color: #ab7df6;
    }
    .c-base-progress__progress {
      background-color: #ab7df6;
    }
  }
}
.c-base-progress--red {
  .c-base-progress__container {
    .c-base-progress__bar {
      background-color: #e0102b;
    }
    .c-base-progress__progress {
      background-color: #e0102b;
    }
  }
}
.c-base-progress--turquoise {
  .c-base-progress__container {
    .c-base-progress__bar {
      background-color: #26c1c9;
    }
    .c-base-progress__progress {
      background-color: #26c1c9;
    }
  }
}
.c-base-progress--white {
  .c-base-progress__container {
    .c-base-progress__bar {
      background-color: #fff;
    }
    .c-base-progress__progress {
      background-color: #fff;
    }
  }
}
.c-base-progress--yellow {
  .c-base-progress__container {
    .c-base-progress__bar {
      background-color: #faca00;
    }
    .c-base-progress__progress {
      background-color: #faca00;
    }
  }
}
.c-base-progress--horizontal {
  .c-base-progress__information {
    .c-base-progress__label-main {
      text-align: right;
    }
  }
  .c-base-progress__container {
    margin-left: 10px;
  }
}
.c-base-progress--vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  .c-base-progress__information {
    width: 100%;
    margin-bottom: 4px;
    .c-base-progress__label-main {
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
    }
    .c-base-progress__label-secondary {
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
      text-align: right;
    }
  }
}
.c-base-progress--colored-labels.c-base-progress--black {
  .c-base-progress__information {
    color: #25374f;
  }
}
.c-base-progress--colored-labels.c-base-progress--blue {
  .c-base-progress__information {
    color: #0093ee;
  }
}
.c-base-progress--colored-labels.c-base-progress--green {
  .c-base-progress__information {
    color: #40b630;
  }
}
.c-base-progress--colored-labels.c-base-progress--grey {
  .c-base-progress__information {
    color: #75869c;
  }
}
.c-base-progress--colored-labels.c-base-progress--orange {
  .c-base-progress__information {
    color: #fd7b1f;
  }
}
.c-base-progress--colored-labels.c-base-progress--purple {
  .c-base-progress__information {
    color: #ab7df6;
  }
}
.c-base-progress--colored-labels.c-base-progress--red {
  .c-base-progress__information {
    color: #e0102b;
  }
}
.c-base-progress--colored-labels.c-base-progress--turquoise {
  .c-base-progress__information {
    color: #26c1c9;
  }
}
.c-base-progress--colored-labels.c-base-progress--white {
  .c-base-progress__information {
    color: #fff;
  }
}
.c-base-progress--colored-labels.c-base-progress--yellow {
  .c-base-progress__information {
    color: #faca00;
  }
}
</style>
