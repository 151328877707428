import plugins from "@/classes/talqui/plugins";
// import store from "@/store/index";

export default {
  namespaced: true,
  state: {
    plugins: [],
    pluginDetails: {},
    pluginConnections: {},
    pluginsConnectionsURNs: [],
  },
  getters: {
    getPlugins: (state) => state.plugins,
    getPluginDetails: (state) => (pluginID) =>
      state.pluginDetails[pluginID] || {},
    getPluginConnection: (state) => (pluginURN) =>
      state.pluginConnections[pluginURN] || {},
    getPluginConnectionURNs: (state) => state.pluginsConnectionsURNs || [],
    isInstalled: (state) => (pluginURN) =>
      state.pluginConnections[pluginURN] || false,
  },
  actions: {
    acquirePlugins({ commit }, { page, category }) {
      return plugins
        .pluginList()
        .get({ page, category })
        .then((res) => {
          let $plugins = res.plugins;
          let hasMore = res.__status === 206;
          if (1 === page) {
            commit("resetPlugins", {
              value: $plugins,
            });
          }
          commit("addPlugins", {
            value: $plugins,
          });
          return hasMore;
        });
    },
    acquirePluginConnections({ commit }, { tenantID }) {
      return plugins
        .pluginConnectionList({ tenantID: tenantID })
        .get()
        .then((res) => {
          if (res?.pluginConnections.length > 0) {
            for (const connection of res.pluginConnections) {
              commit("setPluginConnection", {
                pluginURN: connection.pluginURN,
                value: connection,
              });
            }
          }
          return Promise.resolve(res.pluginConnections);
        });
    },
    acquirePluginDetails({ commit }, { pluginID }) {
      return plugins
        .pluginRead({ pluginID: pluginID })
        .get()
        .then((res) => {
          commit("setPluginDetails", {
            pluginID: pluginID,
            value: res.plugin,
          });
          return Promise.resolve(res.plugin);
        });
    },
    upsertPluginConnection({ commit }, { tenantID, pluginID, pluginSettings }) {
      return plugins
        .pluginConnectionUpsert({ tenantID: tenantID, pluginID: pluginID })
        .put({
          pluginSettings: pluginSettings,
        })
        .then((res) => {
          commit("setPluginConnection", {
            pluginURN: res.pluginConnection.pluginURN,
            value: res.pluginConnection,
          });
          return Promise.resolve(res.pluginConnection);
        });
    },
    pluginPreInstall({ commit }, { tenantID, pluginID, pluginSettings }) {
      return plugins
        .pluginPreInstall({ tenantID: tenantID, pluginID: pluginID })
        .put({
          pluginSettings: pluginSettings,
        })
        .then((res) => {
          commit("setPluginConnection", {
            pluginURN: res.pluginConnection.pluginURN,
            value: res.pluginConnection,
          });
          return Promise.resolve(res.pluginConnection);
        });
    },
  },
  mutations: {
    resetPlugins(state) {
      state.plugins = [];
    },
    addPlugins(state, { value }) {
      value.forEach((pluginItem) => {
        state.plugins.push(pluginItem);
      });
    },
    setPluginDetails(state, { pluginID, value }) {
      state.pluginDetails[pluginID] = value;
    },
    setPluginConnection(state, { pluginURN, value }) {
      state.pluginConnections[pluginURN] = value;
      state.pluginsConnectionsURNs.push(pluginURN);
    },
  },
};
