<template>
  <div
    :class="[
      'c-field-select',
      'c-field-select--' + direction,
      'c-field-select--' + position,
      'c-field-select--' + size,
      'c-field-select--' + status,
      {
        'c-field-select--dark': dark,
        'c-field-select--deployed': deployed,
        'c-field-select--disabled': disabled,
        'c-field-select--focused': focused && !noFocusStyle,
        'c-field-select--loading': loading,
        'c-field-select--error': hasError,
        'c-field-select--full-width': fullWidth,
        'c-field-select--with-color': withColor,
        'c-field-select--with-flag': withFlag,
        'c-field-select--with-left-icon': computedLeftIcon,
        'c-field-select--with-width': width,
      },
    ]"
    :style="{
      textAlign: alignment,
    }"
    ref="root"
  >
    <FieldLabel
      v-if="label"
      class="c-field-select__label"
      :dark="dark"
      :direction="direction"
      :for-field="uuid"
      :size="size"
    >
      {{ label }}
    </FieldLabel>
    <div
      class="c-field-select__container"
      :style="{
        width: width,
      }"
    >
      <div
        v-on:click="(...e) => onSelectClick(...e)"
        v-on:keyDown="(...e) => onSelectKeydown(...e)"
        tabindex="0"
        ref="select"
        class="c-field-select__field js-field-select__field"
      >
        <BaseAvatar
          v-if="computedLeftIcon && selectedOption.avatarId"
          class="c-field-select__icon c-field-select__icon--left"
          :avatar-id="selectedOption.avatarId"
          size="mini"
          type="operator"
        ></BaseAvatar>
        <BaseColor
          v-if="computedLeftIcon && selectedOption.color"
          class="c-field-select__icon c-field-select__icon--left"
          :color="selectedOption.color"
        ></BaseColor>
        <BaseIcon
          v-if="computedLeftIcon && selectedOption.icon"
          class="c-field-select__icon c-field-select__icon--left"
          :height="selectedOption.iconHeight"
          :name="computedLeftIcon"
          :size="computedLeftIconSize"
          :width="selectedOption.iconWidth"
        ></BaseIcon>
        <BaseFlag
          v-else-if="
            computedLeftIcon &&
            (selectedOption.flagCountry || selectedOption.flagLocale)
          "
          class="c-field-select__icon c-field-select__icon--left c-field-select__icon--flag"
          :code="selectedOption.flagCountry"
          :locale="selectedOption.flagLocale"
          :height="computedLeftFlagHeight"
          :width="computedLeftFlagWidth"
        ></BaseFlag>
        <BaseCardProvider
          v-else-if="computedLeftIcon && selectedOption.cardProvider"
          class="c-field-select__icon c-field-select__icon--left c-field-select__icon--provider"
          :provider="selectedOption.cardProvider"
          size="tiny"
        ></BaseCardProvider>
        <div
          v-if="loading"
          class="c-field-select__option c-field-select__option--loading"
        >
          <BaseSpinner
            class="c-field-select__spinner"
            size="10px"
            border-width="1px"
            color="#1c293b"
          >
            {{ this.$t("FORMS.FIELD_SELECT.LOADING") }}
          </BaseSpinner>
        </div>
        <div
          v-else-if="selectedOption && selectedOption.label"
          class="c-field-select__option u-semibold"
        >
          {{ selectedOption.label }}
        </div>
        <div
          v-else-if="placeholder"
          class="c-field-select__option c-field-select__option--placeholder"
        >
          {{ placeholder }}
        </div>
        <BaseIcon
          v-if="!disabled"
          class="c-field-select__icon c-field-select__icon--right c-field-select__icon--opacify"
          name="arrow_drop_down"
        ></BaseIcon>
      </div>
      <div
        v-show="deployed"
        :class="[
          'c-field-select__options',
          {
            'c-field-select__options--empty':
              0 === optionsFiltered.length &&
              0 === actions.length &&
              !searchable,
          },
        ]"
      >
        <div
          v-if="searchable"
          :class="[
            'c-field-select__searchable',
            {
              'c-field-select__searchable--separated':
                optionsFiltered.length > 0 || actions.length > 0,
              'c-field-select__searchable--filtered': searchFilter,
            },
          ]"
        >
          <FieldInput
            class="c-field-select__search-input"
            :modelValue="searchFilter"
            v-on:update:modelValue="(e) => (searchFilter = e)"
            :has-borders="!1"
            :placeholder="this.$t('FORMS.FIELD_SELECT.FILTER')"
            :dark="dark"
            left-icon="search"
            size="small"
          ></FieldInput>
        </div>

        <div
          v-for="item in actions"
          :key="item.value"
          v-on:click="(t) => onActionClick(item)"
          v-on:mouseenter="(t) => setHoveredOption(item)"
          v-on:mouseleave="(t) => setHoveredOption(null)"
          :class="[
            'c-field-select__option',
            'c-field-select__option--action',
            {
              'c-field-select__option--hovered':
                item.value === (hoveredOption || {}).value,
            },
          ]"
          tabindex="0"
        >
          <span class="c-field-select__label">{{ item.label }}</span>
        </div>

        <div
          v-for="item in optionsFiltered"
          :key="item.value"
          v-on:keydown="(t) => onOptionKeydown(item, t)"
          v-on:keypress="(t) => onOptionKeypress(item, t)"
          v-on:mouseenter="(t) => setHoveredOption(item)"
          v-on:mouseleave="(t) => setHoveredOption(null)"
          v-on:mouseup="(t) => onOptionMouseup(item, t)"
          :data-option-value="item.value"
          :class="[
            'c-field-select__option',
            'js-field-select__option',
            {
              'c-field-select__option--default': item.default,
              'c-field-select__option--hovered':
                item.value === (hoveredOption || {}).value,
              'c-field-select__option--selected':
                item.value === (selectedOption || {}).value,
            },
          ]"
          tabindex="0"
        >
          <BaseAvatar
            v-if="item.avatarId"
            class="c-field-select__icon"
            :avatar-id="item.avatarId"
            size="mini"
            type="operator"
          ></BaseAvatar>
          <BaseColor
            v-if="item.color"
            class="c-field-select__icon"
            :color="item.color"
          ></BaseColor>
          <BaseIcon
            v-if="item.icon"
            class="c-field-select__icon"
            :height="item.iconHeight"
            :name="
              item.value === (hoveredOption || {}).value && item.iconHovered
                ? item.iconHovered
                : item.icon
            "
            :size="computedLeftIconSize"
            :width="item.iconWidth"
          ></BaseIcon>
          <BaseFlag
            v-if="item.flagCountry || item.flagLocale"
            class="c-field-select__icon c-field-select__icon--flag"
            :code="item.flagCountry"
            :locale="item.flagLocale"
            :height="computedLeftFlagHeight"
            :width="computedLeftFlagWidth"
          ></BaseFlag>
          <BaseCardProvider
            v-if="item.cardProvider"
            class="c-field-select__icon c-field-select__icon--provider"
            :provider="item.cardProvider"
            size="tiny"
          ></BaseCardProvider>
          <span
            v-if="item.pattern"
            class="c-field-select__pattern"
            :style="{
              backgroundImage: 'url(' + item.pattern + ')',
            }"
          ></span>
          <span class="c-field-select__label">
            {{ item.label }}
          </span>
        </div>
      </div>
    </div>
    <FieldDescription
      v-if="description"
      :description="description"
      :size="size"
    ></FieldDescription>
  </div>
</template>
<script>
// import * as a from "core-js";
import * as $uuid from "uuid";
export default {
  props: {
    actions: {
      type: Array,
      default() {
        return [];
      },
    },
    alignment: {
      type: String,
      default: "left",
      validator(e) {
        return -1 !== ["left", "center", "right"].indexOf(e);
      },
    },
    dark: {
      type: Boolean,
      default: !1,
    },
    description: {
      type: String,
      default: null,
    },
    direction: {
      type: String,
      default: "vertical",
      validator(e) {
        return -1 !== ["horizontal", "vertical"].indexOf(e);
      },
    },
    defaultOption: {
      type: Object,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: !1,
    },
    loading: {
      type: Boolean,
      default: !1,
    },
    hasError: {
      type: Boolean,
      default: !1,
    },
    fullWidth: {
      type: Boolean,
      default: !0,
    },
    label: {
      type: String,
      default: null,
    },
    leftIcon: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: "",
    },
    noFocusStyle: {
      type: Boolean,
      default: !1,
    },
    options: {
      type: Array,
      required: !0,
    },
    placeholder: {
      type: String,
      default: "—",
    },
    position: {
      type: String,
      default: "bottom",
      validator(e) {
        return -1 !== ["bottom", "top"].indexOf(e);
      },
    },
    searchable: {
      type: Boolean,
      default: !1,
    },
    size: {
      type: String,
      default: "default",
      validator(e) {
        return (
          -1 !== ["mini", "small", "default", "medium", "large"].indexOf(e)
        );
      },
    },
    status: {
      type: String,
      default: "normal",
      validator(e) {
        return -1 !== ["error", "normal", "success", "warning"].indexOf(e);
      },
    },
    modelValue: {
      type: [Number, String, Boolean],
      default: null,
    },
    width: {
      type: String,
      default: null,
    },
  },
  emits: ["update:modelValue", "change", "click", "actionClick", "keydown"],
  data() {
    return {
      uuid: "",
      deployed: !1,
      focused: !1,
      hoveredOption: null,
      selectedOption: null,
      lastSearchIndex: -1,
      lastSearchKey: "",
      searchFilter: "",
    };
  },
  computed: {
    hotkeys() {
      return {
        esc: this.onClose,
      };
    },
    computedLeftIcon() {
      if (this.selectedOption) {
        if (this.selectedOption.avatarId) return this.selectedOption.avatarId;
        if (this.selectedOption.color) return this.selectedOption.color;
        if (this.selectedOption.flagCountry)
          return this.selectedOption.flagCountry;
        if (this.selectedOption.cardProvider)
          return this.selectedOption.cardProvider;
        if (this.selectedOption.flagLocale)
          return this.selectedOption.flagLocale;
        if (this.selectedOption.icon) return this.selectedOption.icon;
      }
      switch (this.status) {
        case "error":
          return "close";
        case "success":
          return "check";
        case "warning":
          return "warning";
      }
      return this.leftIcon;
    },
    computedLeftIconSize() {
      switch (this.size) {
        case "mini":
          return "14px";
        case "small":
          return "16px";
        case "default":
          return "18px";
        case "medium":
          return "20px";
        default:
          return "22px";
      }
    },
    computedLeftFlagHeight() {
      switch (this.size) {
        case "mini":
          return "12px";
        case "small":
          return "14px";
        case "default":
          return "16px";
        case "medium":
          return "18px";
        default:
          return "20px";
      }
    },
    computedLeftFlagWidth() {
      switch (this.size) {
        case "mini":
          return "19px";
        case "small":
          return "21px";
        case "default":
          return "23px";
        case "medium":
          return "25px";
        default:
          return "27px";
      }
    },
    optionsWithDefault() {
      let e = [...this.options];
      if (this.defaultOption) {
        const t = this.defaultOption;
        (t.default = !0), (e = [t, ...e]);
      }
      return e;
    },
    optionsWithoutSelected() {
      return this.optionsWithDefault.filter(
        (e) => !this.selectedOption || e.value !== this.selectedOption.value,
      );
    },
    optionsFiltered() {
      return this.searchable && this.searchFilter
        ? this.optionsWithoutSelected.filter((e) => {
            const t = e.label.toLowerCase(),
              i = this.searchFilter.toLowerCase();
            return t.includes(i);
          })
        : this.optionsWithoutSelected;
    },
    withColor() {
      if (this.selectedOption) {
        const e = this.optionsWithDefault.find(
          (e) => e.value === this.selectedOption.value,
        );
        return !(!e || !e.color);
      }
      return !1;
    },
    withFlag() {
      if (this.selectedOption) {
        const e = this.optionsWithDefault.find(
          (e) => e.value === this.selectedOption.value,
        );
        return !(!e || (!e.flagLocale && !e.flagCountry));
      }
      return !1;
    },
  },
  watch: {
    options: {
      deep: !0,
      immediate: !0,
      handler() {
        let e = void 0 === this.modelValue ? "" : this.modelValue;
        const t = this.optionsWithDefault.find((t) => t.value === e);
        this.$nextTick(() => {
          this.setHoveredOption(t), this.setSelectedOption(t);
        });
      },
    },
    modelValue: {
      immediate: !0,
      handler(e) {
        let t = void 0 === e ? "" : e;
        const i = this.optionsWithDefault.find((e) => e.value === t);
        this.$nextTick(() => {
          this.setHoveredOption(i), this.setSelectedOption(i);
        });
      },
    },
  },
  mounted() {
    this.uuid = $uuid.v4();
  },
  methods: {
    close() {
      this.deployed = !1;
      this.searchFilter = "";
    },
    setHoveredOption(e) {
      if (e && null !== e.value) {
        this.hoveredOption = e;
        const t = this.$refs.root.querySelector(
          `.js-field-select__option[data-option-value="${e.value}"]`,
        );
        t &&
          this.$nextTick(() => {
            t.focus();
          });
      } else this.hoveredOption = null;
    },
    selectOption(e) {
      this.setSelectedOption(e);
      this.$emit("update:modelValue", e.value);
      this.$emit("change", e.value, this.name || this.uuid, event);
    },
    setSelectedOption(e) {
      this.selectedOption = e || null;
    },
    searchOptionForKeyCode(e) {
      let t = null;
      e = e.toLowerCase();
      for (let i = 0; i < this.optionsFiltered.length; i++) {
        let a = this.optionsFiltered[i];
        if (
          a.label.charAt(0).toLowerCase() === e &&
          this.lastSearchIndex !== i &&
          ((this.lastSearchKey === e && i > this.lastSearchIndex) ||
            this.lastSearchKey !== e)
        ) {
          (this.lastSearchIndex = i), (this.lastSearchKey = e), (t = a);
          break;
        }
      }
      t && this.setHoveredOption(t);
    },
    focusField() {
      (this.focused = !0), this.$refs.select.focus();
    },
    onActionClick(e) {
      this.close(), this.$emit("actionClick", e.value);
    },
    onClose() {
      (this.focused = !1), this.close();
    },
    onSelectClick(e) {
      this.disabled ||
        this.loading ||
        (this.deployed
          ? this.close()
          : (this.focusField(), (this.deployed = !0)),
        this.setHoveredOption(this.optionsFiltered[0]),
        this.$emit(
          "click",
          (this.selectedOption || {}).value,
          this.name || this.uuid,
          e,
        ));
    },
    onSelectKeydown(e) {
      "Enter" === e.code
        ? e.target.click()
        : this.$emit("keydown", this.name || this.uuid, e);
    },
    onOptionKeydown(e, t) {
      if (
        (t.preventDefault(),
        t.stopPropagation(),
        -1 !== ["ArrowDown", "ArrowUp"].indexOf(t.key))
      ) {
        const i = this.optionsFiltered.length,
          a = this.optionsFiltered.findIndex((t) => e.value === t.value);
        "ArrowDown" === t.key &&
          a + 1 < i &&
          this.setHoveredOption(this.optionsFiltered[a + 1]),
          "ArrowUp" === t.key &&
            a > 0 &&
            this.setHoveredOption(this.optionsFiltered[a - 1]);
      } else
        "Enter" === t.code
          ? (this.selectOption(e, t),
            this.deployed ? this.close() : (this.deployed = !0),
            this.focusField())
          : "Escape" === t.key && (this.close(), this.focusField());
    },
    onOptionKeypress(e, t) {
      this.searchable && this.searchOptionForKeyCode(t.key);
    },
    onOptionMouseup(e, t) {
      this.close(),
        (this.selectedOption || {}).value !== e.value &&
          this.selectOption(e, t);
    },
  },
};
</script>
<style lang="scss">
.c-field-select {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-align: left;
  .c-field-select__container {
    position: relative;
    .c-field-select__field {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      overflow: hidden;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      border-width: 1px;
      border-style: solid;
      cursor: pointer;
      position: relative;
      z-index: 21;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      background-color: #fff;
      -webkit-transition:
        background-color 0.1s linear,
        border 0.2s linear;
      transition:
        background-color 0.1s linear,
        border 0.2s linear;
      &:focus {
        outline: 0;
        border-color: #2c405a;
        color: #2c405a;
        .c-field-select__icon--opacify {
          opacity: 0.5;
        }
      }
      .c-field-select__icon {
        position: absolute;
        pointer-events: none;
      }
      .c-field-select__icon--left {
        left: 10px;
      }
      .c-field-select__icon--right {
        right: 9px;
        -webkit-transition: all 0.1s linear;
        transition: all 0.1s linear;
      }
      .c-field-select__icon--provider {
        margin-top: -1px;
      }
      .c-field-select__icon--provider ~ .c-field-select__option {
        padding-left: 50px;
      }
      .c-field-select__option {
        overflow: hidden;
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        color: #1c293b;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .c-field-select__option--placeholder {
        color: #75869c;
      }
      .c-field-select__option--loading {
        color: rgba(44, 64, 90, 0.8);
        .c-field-select__spinner {
          float: left;
          margin-top: 2px;
          margin-right: 8px;
          opacity: 0.5;
        }
      }
    }
    .c-field-select__options {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      overflow: hidden;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      border-width: 1px;
      border-style: solid;
      cursor: pointer;
      position: absolute;
      right: 0;
      left: 0;
      z-index: 22;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      overflow-x: hidden;
      overflow-y: auto;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      max-height: 200px;
      background-color: #fff;
      .c-field-select__searchable {
        .c-field-select__search-input {
          background-color: #fafbfc;
        }
      }
      .c-field-select__searchable--separated {
        border-bottom: 1px solid #e3e8ef;
      }
      .c-field-select__searchable--filtered {
        .c-field-select__search-input {
          background-color: #fefce7;
        }
      }
      .c-field-select__option {
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        border-bottom: 1px solid #eff3f6;
        color: #1c293b;
        .c-field-select__icon {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 auto;
          flex: 0 0 auto;
          margin-right: 9px;
          fill: #2c405a;
        }
        .c-field-select__icon--provider {
          margin-top: -1px;
        }
        .c-field-select__icon--flag {
          margin-top: -2px;
        }
        .c-field-select__pattern {
          position: absolute;
          top: -12%;
          right: 14px;
          z-index: 12;
          display: none;
          width: 100px;
          height: 100px;
          border-width: 2px;
          border-style: solid;
          border-color: #0093ee;
          border-radius: 4px;
          background-color: #fff;
          background-position: 50%;
          background-size: 72px;
          background-repeat: repeat;
          content: "";
          -webkit-transform: scale(0.5) translateX(48px) translateY(-48px);
          transform: scale(0.5) translateX(48px) translateY(-48px);
        }
        .c-field-select__label {
          overflow: hidden;
          -webkit-box-flex: 1;
          -ms-flex: 1;
          flex: 1;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        &:active {
          outline: 0;
          background-color: #008adf;
          color: #fff;
          -webkit-transition: background-color 50ms linear;
          transition: background-color 50ms linear;
          .c-field-select__icon {
            fill: #fff;
          }
        }
        &:focus {
          outline: 0;
          background-color: #008adf;
          color: #fff;
          -webkit-transition: background-color 50ms linear;
          transition: background-color 50ms linear;
          .c-field-select__icon {
            fill: #fff;
          }
        }
        &:last-of-type {
          border-bottom: 0 none;
        }
      }
      .c-field-select__option--action {
        color: #75869c;
      }
      .c-field-select__option--default {
        color: #75869c;
      }
      .c-field-select__option--hovered {
        background-color: #0093ee;
        color: #fff;
        .c-field-select__icon {
          fill: #fff;
        }
        .c-field-select__pattern {
          display: inline-block;
        }
      }
      .c-field-select__option--selected {
        font-weight: 900;
      }
    }
    .c-field-select__options--empty {
      padding-bottom: 12px;
    }
  }
}
.c-field-select--horizontal {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  .c-field-select__label {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 140px;
  }
  .c-field-select__container {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
}
.c-field-select--horizontal.c-field-select--with-width {
  .c-field-select__label {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .c-field-select__container {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
}
.c-field-select--vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.c-field-select--bottom {
  .c-field-select__container {
    .c-field-select__field {
      .c-field-select__icon--right {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
    }
    .c-field-select__options {
      top: 100%;
      margin-bottom: 10px;
      .c-field-select__option {
        &:last-of-type {
          border-bottom: none;
        }
      }
    }
  }
}
.c-field-select--bottom.c-field-select--deployed {
  .c-field-select__container {
    .c-field-select__field {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      .c-field-select__icon--right {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }
    .c-field-select__options {
      border-top: none;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
}
.c-field-select--top {
  .c-field-select__container {
    .c-field-select__field {
      .c-field-select__icon--right {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }
    .c-field-select__options {
      bottom: 100%;
      margin-top: 10px;
    }
  }
}
.c-field-select--top.c-field-select--deployed {
  .c-field-select__container {
    .c-field-select__field {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      .c-field-select__icon--right {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
    }
    .c-field-select__options {
      border-bottom: none;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}
.c-field-select--mini {
  .c-field-select__container {
    .c-field-select__field {
      border-radius: 3px;
      height: 32px;
      .c-field-select__option {
        font-size: 11.5px;
        line-height: 12px;
        padding: 0 30px 0 10px;
        .c-field-select__icon {
          font-size: 15.5px !important;
        }
      }
    }
    .c-field-select__options {
      border-radius: 3px;
      .c-field-select__option {
        font-size: 11.5px;
        line-height: 12px;
        padding: 0 10px;
        height: 34px;
      }
    }
  }
}
.c-field-select--small {
  .c-field-select__container {
    .c-field-select__field {
      border-radius: 3px;
      height: 36px;
      .c-field-select__option {
        font-size: 12.5px;
        line-height: 13px;
        padding: 0 31px 0 11px;
        .c-field-select__icon {
          font-size: 16.5px !important;
        }
      }
    }
    .c-field-select__options {
      border-radius: 3px;
      .c-field-select__option {
        font-size: 12.5px;
        line-height: 13px;
        padding: 0 11px;
        height: 38px;
      }
    }
  }
}
.c-field-select--default {
  .c-field-select__container {
    .c-field-select__field {
      border-radius: 3px;
      height: 40px;
      .c-field-select__option {
        font-size: 13.5px;
        line-height: 14px;
        padding: 0 32px 0 12px;
        .c-field-select__icon {
          font-size: 17.5px !important;
        }
      }
    }
    .c-field-select__options {
      border-radius: 3px;
      .c-field-select__option {
        font-size: 13.5px;
        line-height: 14px;
        padding: 0 12px;
        height: 42px;
      }
    }
  }
}
.c-field-select--medium {
  .c-field-select__container {
    .c-field-select__field {
      border-radius: 3px;
      height: 44px;
      .c-field-select__option {
        font-size: 14.5px;
        line-height: 15px;
        padding: 0 33px 0 13px;
        .c-field-select__icon {
          font-size: 18.5px !important;
        }
      }
    }
    .c-field-select__options {
      border-radius: 3px;
      .c-field-select__option {
        font-size: 14.5px;
        line-height: 15px;
        padding: 0 13px;
        height: 46px;
      }
    }
  }
}
.c-field-select--large {
  .c-field-select__container {
    .c-field-select__field {
      border-radius: 3px;
      height: 48px;
      .c-field-select__option {
        font-size: 15.5px;
        line-height: 16px;
        padding: 0 34px 0 14px;
        .c-field-select__icon {
          font-size: 19.5px !important;
        }
      }
    }
    .c-field-select__options {
      border-radius: 3px;
      .c-field-select__option {
        font-size: 15.5px;
        line-height: 16px;
        padding: 0 14px;
        height: 50px;
      }
    }
  }
}
.c-field-select--error {
  .c-field-select__container {
    .c-field-select__field {
      border-color: #e0102b;
      color: #e0102b;
      border-color: #e0102b !important;
      .c-field-select__icon {
        fill: #e0102b;
      }
    }
  }
}
.c-field-select--normal {
  .c-field-select__container {
    .c-field-select__field {
      border-color: #a8c6df;
      color: #2c405a;
      .c-field-select__icon {
        fill: #a8c6df;
      }
    }
  }
}
.c-field-select--success {
  .c-field-select__container {
    .c-field-select__field {
      border-color: #40b630;
      color: #40b630;
      .c-field-select__icon {
        fill: #40b630;
      }
    }
  }
}
.c-field-select--warning {
  .c-field-select__container {
    .c-field-select__field {
      border-color: #fd7b1f;
      color: #fd7b1f;
      .c-field-select__icon {
        fill: #fd7b1f;
      }
    }
  }
}
.c-field-select--dark {
  .c-field-select__container {
    .c-field-select__field {
      border-color: #3f536e;
      background-color: #2c405a;
      color: #fff;
      .c-field-select__option {
        color: #fff;
      }
      .c-field-select__icon--right {
        fill: #75869c;
      }
      &:hover {
        border-color: #526b8e;
      }
    }
    .c-field-select__options {
      background-color: #2c405a;
      .c-field-select__option {
        border-bottom-color: #3f536e;
        color: #fff;
        .c-field-select__icon {
          fill: #fff;
        }
      }
      .c-field-select__searchable--filtered {
        .c-field-select__search-input {
          background-color: hsla(0, 0%, 100%, 0.05);
        }
      }
      .c-field-select__searchable {
        .c-field-select__search-input {
          background-color: hsla(0, 0%, 100%, 0.05);
        }
      }
      .c-field-select__searchable--separated {
        border-bottom-color: #3f536e;
      }
    }
  }
}
.c-field-select--deployed {
  .c-field-select__container {
    .c-field-select__field {
      border-color: #a8c6df;
      .c-field-select__icon {
        fill: #a8c6df;
      }
    }
    .c-field-select__options {
      border-color: #a8c6df;
    }
  }
}
.c-field-select--deployed.c-field-select--dark {
  .c-field-select__container {
    .c-field-select__field {
      border-color: #526b8e;
    }
    .c-field-select__options {
      border-color: #526b8e;
    }
  }
}
.c-field-select--focused {
  .c-field-select__container {
    .c-field-select__field {
      border-color: #2c405a;
      color: #2c405a;
      .c-field-select__icon--opacify {
        opacity: 0.5;
      }
    }
    .c-field-select__options {
      border-color: #2c405a;
      color: #2c405a;
    }
  }
}
.c-field-select--focused.c-field-select--dark {
  .c-field-select__container {
    .c-field-select__field {
      border-color: #526b8e;
    }
    .c-field-select__options {
      border-color: #526b8e;
    }
  }
}
.c-field-select--disabled {
  .c-field-select__container {
    .c-field-select__field {
      cursor: not-allowed;
    }
    .c-field-select__options {
      cursor: not-allowed;
    }
    opacity: 0.5;
  }
  .c-field-select__label {
    cursor: not-allowed;
    opacity: 0.35;
  }
}
.c-field-select--loading {
  .c-field-select__container {
    .c-field-select__field {
      cursor: not-allowed;
    }
    .c-field-select__options {
      cursor: not-allowed;
    }
    opacity: 0.75;
  }
  .c-field-select__label {
    cursor: not-allowed;
  }
}
.c-field-select--full-width {
  width: 100%;
}
.c-field-select--with-left-icon {
  .c-field-select__container {
    .c-field-select__field {
      .c-field-select__option {
        padding-left: 36px;
      }
    }
  }
}
.c-field-select--with-color {
  .c-field-select__container {
    .c-field-select__field {
      .c-field-select__option {
        padding-left: 30px;
      }
    }
  }
}
.c-field-select--with-flag {
  .c-field-select__container {
    .c-field-select__field {
      .c-field-select__option {
        padding-left: 42px;
      }
    }
  }
}
</style>
