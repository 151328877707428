import CrispLibraryWeb from "@/classes/library";
import _env from "@/enviroment";

var s = /^(?:\s*)(?:(?:javascript|vbscript):)/i;

class __renders {
  constructor() {
    CrispLibraryWeb.init({
      template: {
        render: (e, t) => {
          var i = this["__render_".concat(e)];
          if ("function" === typeof i) {
            return i.bind(this)(t);
          }

          throw new Error("Render service is missing for: ".concat(e));
        },
      },
      config: { url: { crisp_image: _env.CRISP_URL_IMAGE }, runtime: {} },
    });
  }

  parse() {
    return CrispLibraryWeb.Parse;
  }
  smileys() {
    return CrispLibraryWeb.Smileys;
  }
  html(e) {
    return this.parse().html(e);
  }
  attribute(e) {
    return this.parse().attribute(e);
  }
  lower(e) {
    return (e || "").toLowerCase();
  }
  __filter_link_safe(e) {
    return e && !1 === s.test(e) ? e : "#";
  }
  __render_new_line() {
    return '<br class="o-new-line" />';
  }
  __render_link_email(e) {
    return (
      '<a class="o-link o-link-email"' +
      ' href="mailto:'.concat(this.attribute(e.email), '">') +
      "".concat(e.email) +
      "</a>"
    );
  }
  __render_link_phone(e) {
    return (
      '<a class="o-link o-link-phone"' +
      ' href="tel:'.concat(this.attribute(e.phone), '">') +
      "".concat(e.phone) +
      "</a>"
    );
  }
  __render_link_uri(e) {
    return (
      '<a class="o-link o-link-uri"' +
      ' href="'.concat(this.attribute(e.uri_value), '"') +
      ' rel="noopener noreferrer" target="_blank">' +
      "".concat(e.uri_name) +
      "</a>"
    );
  }
  __render_link_domain(e) {
    return (
      '<a class="o-link o-link-domain"' +
      ' href="http://'.concat(this.attribute(e.domain_value), '/"') +
      ' rel="noopener noreferrer" target="_blank">' +
      "".concat(e.domain_name) +
      "</a>"
    );
  }
  __render_markdown_blockquote(e) {
    return (
      '<span class="o-markdown o-markdown-blockquote u-select">' +
      "".concat(e.value) +
      "</span>"
    );
  }
  __render_markdown_interpolate(e) {
    return (
      '<span class="o-markdown o-markdown-interpolate u-select"' +
      ' data-type="'.concat(this.attribute(this.lower(e.type)), '">') +
      "{{" +
      " ".concat(e.type) +
      " ".concat(e.value ? '| "' + e.value + '" ' : "") +
      "}}</span>"
    );
  }
  __render_markdown_color(e) {
    return (
      '<span class="o-markdown o-markdown-color"' +
      ' style="color: '.concat(e.type, ';">') +
      "".concat(e.value) +
      "</span>"
    );
  }
  __render_markdown_bold(e) {
    return (
      '<span class="o-markdown o-markdown-bold u-bold">' +
      "".concat(e.value) +
      "</span>"
    );
  }
  __render_markdown_code_inline(e) {
    return (
      '<span class="o-markdown o-markdown-code o-markdown-code-inline u-select u-code">' +
      "".concat(e.value) +
      "</span>"
    );
  }
  __render_markdown_code_block(e) {
    return (
      '<span class="o-markdown o-markdown-code o-markdown-code-block u-select u-code">' +
      "".concat(e.value) +
      "</span>"
    );
  }
  __render_markdown_delete(e) {
    return (
      '<span class="o-markdown o-markdown-delete">' +
      "".concat(e.value) +
      "</span>"
    );
  }
  __render_markdown_highlight(e) {
    return (
      '<span class="o-markdown o-markdown-highlight">' +
      "".concat(e.value) +
      "</span>"
    );
  }
  __render_markdown_image(e) {
    return (
      '<span class="o-markdown o-markdown-image"' +
      (e.type ? ' data-label="'.concat(this.attribute(e.type), '"') : "") +
      ">" +
      '<img src="'.concat(e.url_crisp_image, "/process/original/") +
      "?url=".concat(this.attribute(encodeURIComponent(e.value)), '"') +
      ' alt="'.concat(this.attribute(e.type), '" />') +
      "</span>"
    );
  }
  __render_markdown_italic(e) {
    return (
      '<span class="o-markdown o-markdown-italic">' +
      "".concat(e.value) +
      "</span>"
    );
  }
  __render_markdown_underline(e) {
    return (
      '<span class="o-markdown o-markdown-underline">' +
      "".concat(e.value) +
      "</span>"
    );
  }
  __render_markdown_line() {
    return '<span class="o-markdown o-markdown-line"></span>';
  }
  __render_markdown_link(e) {
    return (
      '<a class="o-markdown o-markdown-link"' +
      ' href="'.concat(this.attribute(this.__filter_link_safe(e.value)), '"') +
      ' rel="noopener noreferrer" target="_blank">' +
      "".concat(e.type) +
      "</a>"
    );
  }
  __render_markdown_list(e) {
    return (
      '<span class="o-markdown o-markdown-list"' +
      ' data-type="'.concat(this.attribute(e.type), '">') +
      "".concat(e.value) +
      "</span>"
    );
  }
  __render_markdown_title(e) {
    return (
      '<span class="o-markdown o-markdown-title u-semibold"' +
      ' data-type="'.concat(this.attribute(e.type), '">') +
      "".concat(e.value) +
      "</span>"
    );
  }
  __render_markdown_emphasis(e) {
    return (
      '<span class="o-markdown o-markdown-emphasis u-semibold"' +
      ' data-type="'.concat(this.attribute(e.type), '">') +
      "".concat(e.value) +
      "</span>"
    );
  }
  __render_markdown_youtube(e) {
    var t = "https://img.youtube.com/vi/".concat(e.value, "/hqdefault.jpg"),
      i = "playVideo('youtube', '".concat(this.attribute(e.value), "')");
    return (
      '<a class="o-markdown o-markdown-video o-markdown-youtube"' +
      ' onclick="return '.concat(i, '"') +
      (e.type ? ' data-label="'.concat(this.attribute(e.type), '"') : "") +
      '><span class="o-markdown-video-preview o-markdown-youtube-preview">' +
      '<img src="'.concat(e.url_crisp_image, "/process/resize/") +
      "?url=".concat(this.attribute(encodeURIComponent(t))) +
      '&width=960&height=960"' +
      ' alt="'.concat(this.attribute(e.type), '" />') +
      "</span></a>"
    );
  }
  __render_markdown_vimeo(e) {
    var t = "https://vimeo.com/".concat(encodeURIComponent(e.value));
    return (
      '<a class="o-link o-link-uri"' +
      ' href="'.concat(this.attribute(t), '"') +
      ' rel="noopener noreferrer" target="_blank">' +
      "".concat(e.type || t) +
      "</a>"
    );
  }
  __render_markdown_dailymotion(e) {
    var t = "https://www.dailymotion.com/thumbnail/video/".concat(e.value),
      i = "playVideo('dailymotion', '".concat(this.attribute(e.value), "')");
    return (
      '<a class="o-markdown o-markdown-video o-markdown-dailymotion"' +
      ' onclick="return '.concat(i, '"') +
      (e.type ? ' data-label="'.concat(this.attribute(e.type), '"') : "") +
      '><span class="o-markdown-video-preview o-markdown-dailymotion-preview">' +
      '<img src="'.concat(e.url_crisp_image, "/process/resize/") +
      "?url=".concat(this.attribute(encodeURIComponent(t))) +
      '&width=320&height=240"' +
      ' alt="'.concat(this.attribute(e.type), '" />') +
      "</span></a>"
    );
  }
  __render_markdown_frame(e) {
    return (
      '<a class="o-link o-link-uri"' +
      ' href="'.concat(this.attribute(this.__filter_link_safe(e.value)), '"') +
      ' rel="noopener noreferrer" target="_blank">' +
      "".concat(e.type || e.value) +
      "</a>"
    );
  }
  __render_smiley(e) {
    return (
      '<span class="o-smiley"' +
      ' data-size="'.concat(this.attribute(e.size), '"') +
      ' data-name="'.concat(this.attribute(e.name), '">') +
      "</span>"
    );
  }
}
export default new __renders();
