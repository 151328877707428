class _notations {
  percent(e, t, i = 0) {
    let a = (e / t) * 100;
    return (
      (a = 0 === i ? Math.floor(a) : a.toFixed(i)),
      Math.max(0, Math.min(100, a))
    );
  }
  reduceFromList(e) {
    return e.length > 0 ? e.reduce((e, t) => e + t, 0) : 0;
  }
  toDecileNotation(e, t = 1) {
    let i = (e || 0).toString(),
      a = Math.pow(10, i.length - (-1 === t ? 1 : 0));
    return a;
  }
  toFixed(e = 0, t = 1) {
    return e.toFixed(t);
  }
  asCommaNotation(e) {
    let t = (e + "").split(""),
      i = -3;
    while (t.length + i > 0) t.splice(i, 0, ","), (i -= 4);
    return t.join("");
  }
}

export default new _notations();
