<template>
  <div
    :class="[
      'c-base-collapsible',
      'c-base-collapsible--' + color,
      {
        'c-base-collapsible--expanded': isExpanded,
      },
    ]"
  >
    <!-- v-on:click="
        t[0] || (t[0] = (...e) => c.onHeaderClick && c.onHeaderClick(...e))
      " -->
    <div class="c-base-collapsible__header">
      <div class="c-base-collapsible__header-primary u-semibold">
        <slot name="header-primary"></slot>
      </div>
      <div
        v-if="$slots['header-secondary']"
        class="c-base-collapsible__header-secondary u-regular"
      >
        <slot name="header-secondary"></slot>
      </div>
    </div>
    <div class="c-base-collapsible__body">
      <slot name="body"></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: "white",
      validator(e) {
        return -1 !== ["white"].indexOf(e);
      },
    },
    expanded: {
      type: Boolean,
      default: !1,
    },
  },
  data() {
    return {
      isExpanded: !!this.expanded,
    };
  },
  methods: {
    onHeaderClick() {
      this.isExpanded = !this.isExpanded;
    },
  },
};
</script>
<style lang="scss">
.c-base-collapsible {
  border: 1px solid #e0e5e8;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  overflow: hidden;
  &:last-child {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  .c-base-collapsible__header {
    border-color: #fff;
    border-width: 1px 0 0 1px;
    border-style: solid;
    font-size: 13.5px;
    padding: 8px 14px;
    cursor: pointer;
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transition: all 0.1s linear;
    transition: all 0.1s linear;
    -webkit-transition-property: background-color, border-color;
    transition-property: background-color, border-color;
    &:before {
      content: "";
      width: 9px;
      height: 5px;
      margin-right: 16px;
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      opacity: 0.4;
      -webkit-transform: rotate(-90deg);
      transform: rotate(-90deg);
      -webkit-transition: all 0.15s linear;
      transition: all 0.15s linear;
      -webkit-transition-property:
        opacity,
        -webkit-transform;
      transition-property:
        opacity,
        -webkit-transform;
      transition-property: transform, opacity;
      transition-property:
        transform,
        opacity,
        -webkit-transform;
      background-image: url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjQiIHdpZHRoPSI3IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik0uNS40NzRsMyAzbTMtM2wtMyAzIiBmaWxsPSJub25lIiBzdHJva2U9IiM3NTg2OWMiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiLz48L3N2Zz4=");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 50%;
      display: inline-block;
    }
    .c-base-collapsible__header-primary {
      color: #1c293b;
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
    }
    .c-base-collapsible__header-secondary {
      color: #75869c;
      -webkit-box-flex: 0;
      -ms-flex: 0 1 auto;
      flex: 0 1 auto;
      overflow: hidden;
    }
  }
  .c-base-collapsible__body {
    font-size: 14px;
    padding: 12px 14px;
    padding-left: 39px;
    display: none;
  }
}
.c-base-collapsible + .c-base-collapsible {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  &:last-child {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }
}
.c-base-collapsible--expanded {
  .c-base-collapsible__header {
    &:before {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  }
  .c-base-collapsible__body {
    border-top: 1px solid #e0e5e8;
    display: block;
  }
}
.c-base-collapsible--white {
  .c-base-collapsible__header {
    &:hover {
      background-color: #fafbfc;
      &:before {
        opacity: 0.9;
      }
    }
    &:active {
      background-color: #f8f9fb;
      border-color: #fafbfc;
      &:before {
        opacity: 1;
      }
    }
  }
}
</style>
