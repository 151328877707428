<template>
  <span
    :class="[
      'c-base-notification',
      'c-base-notification--' + color,
      'c-base-notification--' + size,
      {
        'c-base-notification--grow': null !== growWith && growWith >= 10,
      },
    ]"
  >
    <span
      :class="[
        'c-base-notification__slot',
        {
          [slotClass]: slotClass,
        },
      ]"
    >
      <slot></slot>
    </span>
  </span>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: "red",
    },
    size: {
      type: String,
      default: "normal",
      validator(e) {
        return -1 !== ["none", "micro", "mini", "small", "normal"].indexOf(e);
      },
    },
    growWith: {
      type: Number,
      default: null,
    },
    slotClass: {
      type: String,
      default: null,
    },
  },
};
</script>
<style lang="scss">
.c-base-notification {
  display: inline-block;
  border-radius: 100%;
  color: #fff;
  text-align: center;
  font-weight: 700;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  .c-base-notification__slot {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}
.c-base-notification--blue {
  background-color: #0093ee;
}
.c-base-notification--green {
  background-color: #40b630;
}
.c-base-notification--grey {
  background-color: #75869c;
}
.c-base-notification--red {
  background-color: #e0102b;
}
.c-base-notification--white {
  background-color: #fff;
}
.c-base-notification--state-pending {
  background-color: #2494e8;
}
.c-base-notification--state-unresolved {
  background-color: #e8422d;
}
.c-base-notification--state-resolved {
  background-color: #34bb1f;
}
.c-base-notification--none {
  font-size: 12px;
}
.c-base-notification--micro {
  width: 10px;
  height: 10px;
  font-size: 11px;
  line-height: 10px;
}
.c-base-notification--micro.c-base-notification--grow {
  padding: 0 2px;
  border-radius: 4px;
}
.c-base-notification--mini {
  width: 17px;
  height: 17px;
  font-weight: 900;
  font-size: 11.5px;
  line-height: 17px;
}
.c-base-notification--mini.c-base-notification--grow {
  padding: 0 3px;
  border-radius: 8px;
}
.c-base-notification--small {
  width: 20px;
  height: 20px;
  font-size: 13px;
  line-height: 20px;
}
.c-base-notification--small.c-base-notification--grow {
  padding: 0 5px;
  border-radius: 10px;
}
.c-base-notification--normal {
  width: 24px;
  height: 24px;
  font-size: 14px;
}
.c-base-notification--normal.c-base-notification--grow {
  padding: 0 5px;
  border-radius: 12px;
}
.c-base-notification--grow {
  width: auto !important;
}
</style>
