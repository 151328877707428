import http from "./http";

class _organization {
  constructor() {}

  organizationBase() {
    return http.one("organization");
  }
  organizationOne() {
    return http.one("organization");
  }
}
export default new _organization();
