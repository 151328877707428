<template>
  <div class="c-base-editor--variables-list">
    <template v-if="items.length">
      <button
        class="c-base-editor--variable"
        :class="{ 'is-selected': index === selectedIndex }"
        v-for="(item, index) in items"
        :key="index"
        v-on:click="selectItem(index)"
        v-on:mouseover="onMouseEnterItem(index)"
      >
        {{ item.label }}
      </button>
    </template>

    <div class="item" v-else>Sem resultados.</div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },

    command: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      selectedIndex: 0,
    };
  },

  watch: {
    items() {
      this.selectedIndex = 0;
    },
  },

  methods: {
    onKeyDown({ event }) {
      if (event.key === "ArrowUp") {
        this.upHandler();
        return true;
      }

      if (event.key === "ArrowDown") {
        this.downHandler();
        return true;
      }

      if (event.key === "Enter") {
        this.enterHandler();
        return true;
      }

      return false;
    },

    upHandler() {
      this.selectedIndex =
        (this.selectedIndex + this.items.length - 1) % this.items.length;
    },

    downHandler() {
      this.selectedIndex = (this.selectedIndex + 1) % this.items.length;
    },

    onMouseEnterItem(index) {
      this.selectedIndex = index;
    },

    enterHandler() {
      this.selectItem(this.selectedIndex);
    },

    selectItem(index) {
      const item = this.items[index];
      if (item) {
        this.command({ id: item.value, label: item.label, item });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.c-base-editor--variables-list {
  z-index: 99999999999999;
  box-shadow:
    0 0 #0000,
    0 0 #0000,
    0 4px 6px -1px rgb(0 0 0 / 0.1),
    0 2px 4px -2px rgb(0 0 0 / 0.1);
  color: hsl(240 10% 3.9%);
  background-color: #fff;
  padding: 0.25rem;
  border-width: 1px;
  border-radius: 0.5rem;
  overflow: hidden;
  min-width: 12rem;
  position: relative;
  border-color: #f2f2f2;
  .c-base-editor--variable {
    border: none;
    width: 100%;
    outline: 2px solid transparent;
    outline-offset: 2px;
    font-size: 0.875rem;
    line-height: 1.25rem;
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border-radius: 0.25rem;
    align-items: center;
    user-select: none;
    cursor: default;
    display: flex;
    position: relative;
    background-color: #fff;
    &.is-selected {
      color: hsl(240 5.9% 10%);
      background-color: #f4f4f5;
      outline: 2px solid transparent;
      outline-offset: 2px;
    }
  }
}
</style>
