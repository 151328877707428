// import a from "thenby";
// import o from "core-js";
// import n from "@/router/index.js";
// import r from "@/classes/operation.js";
// import s from "@/classes/notification.js";
// import l from "@/classes/websites.js";
// import c from "@/classes/helpers.js";
// import d from "@/classes/people.js";
// import u from "@/classes/stringHelper.js";
// import M from "@/classes/queryBuilder.js";

// import classOperation from "@/classes/talqui/operation";
// import session from "@/classes/talqui/session";

// const b = 2e3;
// const m = 10;
// const N = 200;
// const I = 12e4;

// function p() {
//   let p = null;
//   return (
//     (p = Object.assign
//       ? Object.assign.bind()
//       : function (e) {
//           for (var t = 1; t < arguments.length; t++) {
//             var i = arguments[t];
//             for (var a in i)
//               Object.prototype.hasOwnProperty.call(i, a) && (e[a] = i[a]);
//           }
//           return e;
//         }),
//     p.apply(this, arguments)
//   );
// }

// export default {
//   namespaced: !0,
//   state: {
//     conversationsMap: {},
//     conversationsList: {},
//     currentSessionId: null,
//     cacheTimeout: {},
//   },
//   getters: {
//     getList: (e) => (t) => e.conversationsList[t],
//     getMap: (e) => (t) => e.conversationsMap[t],
//     getTimeouts: (e) => (t) => e.cacheTimeout[t],
//     getSessionIds: (e) => (t) => Object.keys(e.conversationsMap[t] || {}) || [],
//     getCurrentSessionId: (e) => e.currentSessionId,
//     getConversationReference: (e) => (_tenantID, _sessionID) =>
//       e.conversationsMap[_tenantID][_sessionID],
//     getDeferReference: (e) => (t) => e.cacheTimeout[t],
//     getConversationIndex: (e) => (t, i) =>
//       (e.conversationsList[t] || []).findIndex((e) => e.session_id === i),
//     getMessageFingerprints: () => (e) =>
//       (e.messages || []).map((e) => e.fingerprint),
//     getLatestMessage:
//       () =>
//       (e = null, t = null) => {
//         let i = null;
//         if (!e || !e.messages) return "";
//         for (let a = e.messages.length - 1; a >= 0; a--)
//           if (null === t || e.messages[a].from === t) {
//             i = e.messages[a];
//             break;
//           }
//         return i;
//       },
//     getMessageIndex: (e) => (t, i) => {
//       let a = (t.fingerprints || []).indexOf(i);
//       if (-1 !== a) {
//         let o = e.conversationsMap[t.website_id][t.session_id].messages[a];
//         if (o.fingerprint === i) return a;
//       }
//       return null;
//     },
//   },
//   actions: {
//     acquireConversations(
//       { getters: e, commit: t },
//       {
//         tenantID: _tenantID,
//         page: a = 1,
//         perPage: _perPage = 100,
//         filterTab: _tab = "auto",
//         filterAssigned: M = false,
//         filterUnassigned: g = false,
//         searchFilters: b = null,
//       }
//     ) {
//       let m = [];
//       let _query = {
//         page: a,
//         perPage: _perPage,
//       };
//       if (_tab != "auto") {
//         _query.tab = _tab;
//       }
//       if (M) {
//         _query.filter_assigned = M;
//       }
//       if (true === g) {
//         _query.filter_unassigned = "1";
//       }
//       if (null !== b) {
//         _query.search_type = "filter";
//         _query.search_query = JSON.stringify(b.filter);
//         _query.search_operator = b.operator || "and";
//       }

//       return Promise.resolve()
//         .then(() => session.sessionList(_tenantID).get(_query))
//         .then((_sessions) => {
//           m = _sessions || [];
//           console.log(_sessions);
//           t("assertConversations", {
//             tenantID: _tenantID,
//           });
//           Promise.resolve();
//         })
//         .then(() => {
//           if (m.length > 0) {
//             m.forEach((_session) => {
//               console.log(_session);
//               t("storeConversation", {
//                 tenantID: _tenantID,
//                 sessionID: _session.sessionID,
//                 session: _session,
//                 fullLoad: false,
//                 proceedOrder: false,
//               });
//               t("orderConversations", {
//                 tenantID: _tenantID,
//               });
//             });
//           }
//         })
//         .then(() => {
//           let _sessionList = e.getList(_tenantID);
//           return Promise.resolve({
//             hasNext: m.length === _perPage,
//             list: _sessionList || [],
//           });
//         });

//       // Promise.resolve()
//       //   .then(() => r.default.conversations(i, a).get(N))
//       //   .then(
//       //     (e) => (
//       //       (m = e || []),
//       //       t("assertConversations", {
//       //         websiteId: i,
//       //       }),
//       //       Promise.resolve()
//       //     )
//       //   )
//       // .then(() => {
//       //   m.length > 0 &&
//       //     (m.forEach((e) => {
//       //       (e.new = true === o),
//       //         t("storeConversation", {
//       //           websiteId: i,
//       //           sessionId: e.session_id,
//       //           conversation: e,
//       //           fullLoad: false,
//       //           proceedOrder: false,
//       //         });
//       //     }),
//       //     t("orderConversations", {
//       //       websiteId: i,
//       //     }));
//       //   let n = e.getList(i);
//       //   return (
//       //     1 !== a ||
//       //       s ||
//       //       l ||
//       //       c ||
//       //       u ||
//       //       M ||
//       //       g ||
//       //       null !== b ||
//       //       t("fixUnreadCount", {
//       //         websiteId: i,
//       //         conversations: m,
//       //       }),
//       //     Promise.resolve({
//       //       hasNext: 206 === m.__status && !0,
//       //       list: n || [],
//       //     })
//       //   );
//       // })
//     },
//     acquireMessages(
//       { getters: e, commit: t, state },
//       { websiteId: i, sessionId: a, firstMessageTimestamp: o = -1 }
//     ) {
//       let n = e.getConversationReference(i, a);
//       console.log("REFERENCE", JSON.stringify(state.conversationsMap[i]));
//       return classOperation
//         .getMessages(i, a, o)
//         .then((e) =>
//           e && 0 !== e.length ? Promise.resolve(e) : Promise.reject()
//         )
//         .then(
//           (e) => (
//             t("setMessagesPaging", {
//               conversation: n,
//               messages: e,
//             }),
//             Promise.resolve(e)
//           )
//         );
//     },
//     // acquireConversation(
//     //   { getters: e, commit: t, dispatch: i },
//     //   {
//     //     websiteId: a,
//     //     sessionId: o,
//     //     loadDependencies: n = false,
//     //     loadOthers: s = false,
//     //   }
//     // ) {
//     //   let l, c;
//     //   return Promise.resolve()
//     //     .then(() => {
//     //       let e = [r.default.conversationOne(a, o).get()];
//     //       return (
//     //         true === n &&
//     //           (e.push(r.default.getMessages(a, o)),
//     //           true === s &&
//     //             e.push(
//     //               r.default.getPages(a, o).catch(() => Promise.resolve([]))
//     //             )),
//     //         Promise.all(e)
//     //       );
//     //     })
//     //     .then(
//     //       (e) => (
//     //         (l = e[0]),
//     //         false === n && l.unread && delete l.unread,
//     //         n && e[1] && (l.messages = e[1]),
//     //         s && e[2] && (l.pages = e[2]),
//     //         Promise.resolve()
//     //       )
//     //     )
//     //     .then(() => {
//     //       let e = [];
//     //       if (true === n && true === s) {
//     //         if (
//     //           (l["contactID"] &&
//     //             (e.push(
//     //               i(
//     //                 "contacts/acquireProfile",
//     //                 {
//     //                   websiteId: a,
//     //                   peopleId: l["contactID"],
//     //                 },
//     //                 {
//     //                   root: !0,
//     //                 }
//     //               )
//     //                 .catch(() => Promise.resolve({}))
//     //                 .then((e) => ((l.identity = e), Promise.resolve()))
//     //             ),
//     //             e.push(
//     //               i("acquireContactData", {
//     //                 websiteId: a,
//     //                 conversation: l,
//     //               })
//     //             )),
//     //           l.meta.email)
//     //         ) {
//     //           let t = this.getters["websites/getCurrentWebsite"] || {};
//     //           true === (t.emails || {}).rating &&
//     //             e.push(
//     //               i("acquireRating", {
//     //                 websiteId: a,
//     //                 conversation: l,
//     //                 doCommit: false,
//     //               })
//     //             );
//     //         }
//     //         "boolean" !== typeof l.in_call &&
//     //           ("online" === l.availability &&
//     //           this.getters["plugins/hasPlugin"]("urn:crisp.im:video-calls:0")
//     //             ? e.push(
//     //                 r.default
//     //                   .getCall(a, o)
//     //                   .catch(() => Promise.resolve({}))
//     //                   .then(
//     //                     (e) => ((l.in_call = !!e.call_id), Promise.resolve())
//     //                   )
//     //               )
//     //             : (l.in_call = false));
//     //       }
//     //       return Promise.all(e);
//     //     })
//     //     .then(
//     //       () => (
//     //         t("storeConversation", {
//     //           websiteId: a,
//     //           sessionId: o,
//     //           conversation: l,
//     //         }),
//     //         Promise.resolve()
//     //       )
//     //     )
//     //     .then(
//     //       () => (
//     //         (c = e.getConversationReference(a, o)),
//     //         l.fingerprints ||
//     //           t("updateConversationPath", {
//     //             path: c,
//     //             update: {
//     //               fingerprints: e.getMessageFingerprints(l),
//     //             },
//     //           }),
//     //         c &&
//     //           l.messages &&
//     //           t("setMessagesPaging", {
//     //             conversation: c,
//     //             messages: l.messages,
//     //           }),
//     //         true === n &&
//     //           t("updateConversationPath", {
//     //             path: c._$,
//     //             update: {
//     //               isFullLoad: !0,
//     //             },
//     //           }),
//     //         Promise.resolve(c)
//     //       )
//     //     )
//     //     .then((e) => Promise.resolve(e));
//     // },
//     // acquireRating(
//     //   { commit: e },
//     //   { websiteId: t, conversation: i, doCommit: a = false }
//     // ) {
//     //   return r.default
//     //     .conversationRating(t, i["session_id"])
//     //     .get()
//     //     .catch(() => Promise.resolve({}))
//     //     .then(
//     //       (t) => (
//     //         false === a
//     //           ? (i.rating = t || {})
//     //           : e("updateConversationPath", {
//     //               path: i,
//     //               update: {
//     //                 rating: t || {},
//     //               },
//     //             }),
//     //         Promise.resolve(t)
//     //       )
//     //     );
//     // },
//     // acquireContactData(
//     //   { commit: e, rootState: t },
//     //   { websiteId: i, conversation: a, doCommit: o = false }
//     // ) {
//     //   const n = true === t.plugins.pluginUrnsReady ? 0 : N;
//     //   return new Promise((t) => {
//     //     setTimeout(
//     //       () =>
//     //         this.getters["plugins/hasPlugin"]("urn:crisp.im:people:0")
//     //           ? d.default
//     //               .profileData(i, a["contactID"])
//     //               .get()
//     //               .catch(() => t({}))
//     //               .then((i) =>
//     //                 false !== o
//     //                   ? (e("updateConversationPath", {
//     //                       path: a,
//     //                       update: {
//     //                         contact_data: (i || {}).data || {},
//     //                       },
//     //                     }),
//     //                     t())
//     //                   : ((a.contact_data = (i || {}).data || {}), t())
//     //               )
//     //           : t(),
//     //       n
//     //     );
//     //   });
//     // },
//     // updateConversationData(
//     //   { getters: e, commit: t },
//     //   { websiteId: i, sessionId: a, key: n, value: s = null }
//     // ) {
//     //   let l = e.getConversationReference(i, a),
//     //     c = {
//     //       ...l.meta.data,
//     //     },
//     //     d = (0, o.default)(c);
//     //   return (
//     //     s ? (c[n] = s) : delete c[n],
//     //     r.default
//     //       .setMeta(i, a, {
//     //         data: c,
//     //       })
//     //       .catch(
//     //         () => (
//     //           t("updateConversationPath", {
//     //             path: l.meta,
//     //             update: {
//     //               data: d,
//     //             },
//     //           }),
//     //           Promise.reject()
//     //         )
//     //       )
//     //   );
//     // },
//     // updateContactData(
//     //   { getters: e, commit: t },
//     //   { websiteId: i, sessionId: a, key: n, value: r = null }
//     // ) {
//     //   let s = e.getConversationReference(i, a),
//     //     l = {
//     //       ...(s.contact_data || {}),
//     //     },
//     //     c = (0, o.default)(l);
//     //   return (
//     //     r ? (l[n] = r) : delete l[n],
//     //     d.default
//     //       .profileData(i, s["contactID"])
//     //       .put({
//     //         data: l,
//     //       })
//     //       .catch(
//     //         () => (
//     //           t("updateConversationPath", {
//     //             path: s,
//     //             update: {
//     //               contact_data: c,
//     //             },
//     //           }),
//     //           Promise.reject()
//     //         )
//     //       )
//     //   );
//     // },
//     // createConversation({ commit: e }, { websiteId: t, meta: i }) {
//     //   let a = "";
//     //   return r.default
//     //     .createConversation(t)
//     //     .then(
//     //       (i) => (
//     //         (a = i.session_id),
//     //         e("assertConversation", {
//     //           websiteId: t,
//     //           sessionId: a,
//     //         }),
//     //         e("createConversation", {
//     //           websiteId: t,
//     //           sessionId: a,
//     //         }),
//     //         Promise.resolve()
//     //       )
//     //     )
//     //     .then(() => r.default.setMeta(t, a, i))
//     //     .then(() => Promise.resolve(a));
//     // },
//     // batchReadAll({ getters: e, commit: t }, { websiteId: i }) {
//     //   return l.default
//     //     .batchRead(i)
//     //     .patch()
//     //     .then(() => {
//     //       let a = e.getSessionIds(i);
//     //       return (
//     //         a.forEach((e) => {
//     //           t("markAllMessagesAsRead", {
//     //             websiteId: i,
//     //             sessionId: e,
//     //           });
//     //         }),
//     //         Promise.resolve()
//     //       );
//     //     });
//     // },
//     // batchResolveAll({ getters: e, commit: t }, { websiteId: i }) {
//     //   return l.default
//     //     .batchResolve(i)
//     //     .patch()
//     //     .then(() => {
//     //       let a = e.getSessionIds(i);
//     //       return (
//     //         a.forEach((e) => {
//     //           t("setStateLocal", {
//     //             websiteId: i,
//     //             sessionId: e,
//     //             value: "resolved",
//     //           });
//     //         }),
//     //         Promise.resolve()
//     //       );
//     //     });
//     // },
//     // assertCache(
//     //   { getters: e, commit: t, dispatch: i },
//     //   { websiteId: a, sessionId: o, event: n = {}, options: r = {} }
//     // ) {
//     //   return new Promise((s, l) => {
//     //     const c = a || n.website_id,
//     //       d = o || n.session_id;
//     //     t("assertConversations", {
//     //       websiteId: c,
//     //     });
//     //     let u = e.getConversationReference(c, d),
//     //       M = e.getDeferReference(c);
//     //     true === r.reloadCache &&
//     //       u &&
//     //       u._$ &&
//     //       true === u._$.isFullLoad &&
//     //       (u._$.isFullLoad = false);
//     //     let g = r.requireFullLoad || false;
//     //     return u || false === r.ignoreUnknown
//     //       ? u &&
//     //         (true !== r.requireFullLoad || (u._$ && true === u._$.isFullLoad))
//     //         ? s(u)
//     //         : !M[d] || (false === M[d].isFullLoad && true === r.requireFullLoad)
//     //         ? (i("deferAssert", {
//     //             websiteId: c,
//     //             sessionId: d,
//     //             isFullLoad: g,
//     //             fnResolve: s,
//     //             fnReject: l,
//     //           }),
//     //           i("acquireConversation", {
//     //             websiteId: c,
//     //             sessionId: d,
//     //             loadDependencies: g,
//     //             loadOthers: r.loadOthers,
//     //           })
//     //             .then(
//     //               (e) => (
//     //                 true === r.considerNew &&
//     //                   (t("updateConversationPath", {
//     //                     path: e,
//     //                     update: {
//     //                       new: !0,
//     //                     },
//     //                   }),
//     //                   setTimeout(() => {
//     //                     t("updateConversationPath", {
//     //                       path: e,
//     //                       update: {
//     //                         new: false,
//     //                       },
//     //                     });
//     //                   }, b)),
//     //                 i("triggerDeferredAsserts", {
//     //                   websiteId: c,
//     //                   sessionId: d,
//     //                   mode: "resolve",
//     //                 })
//     //               )
//     //             )
//     //             .catch(() =>
//     //               i("triggerDeferredAsserts", {
//     //                 websiteId: c,
//     //                 sessionId: d,
//     //                 mode: "reject",
//     //               })
//     //             ))
//     //         : M[d]
//     //         ? i("deferAssert", {
//     //             websiteId: c,
//     //             sessionId: d,
//     //             isFullLoad: g,
//     //             fnResolve: s,
//     //             fnReject: l,
//     //           })
//     //         : l("assertCache: an error occured.")
//     //       : l("assertCache: unknown conversation.");
//     //   });
//     // },
//     // deferAssert(
//     //   { getters: e, commit: t },
//     //   { websiteId: i, sessionId: a, isFullLoad: o, fnResolve: n, fnReject: r }
//     // ) {
//     //   t("assertConversations", {
//     //     websiteId: i,
//     //   });
//     //   let s = e.getDeferReference(i);
//     //   return (
//     //     s[a] ||
//     //       t("deferCache", {
//     //         websiteId: i,
//     //         sessionId: a,
//     //         isFullLoad: o,
//     //       }),
//     //     false === s[a].isFullLoad &&
//     //       t("deferCacheLoaded", {
//     //         websiteId: i,
//     //         sessionId: a,
//     //         isFullLoad: o,
//     //       }),
//     //     t("deferCacheTriggers", {
//     //       websiteId: i,
//     //       sessionId: a,
//     //       resolve: n,
//     //       reject: r,
//     //     }),
//     //     Promise.resolve()
//     //   );
//     // },
//     // triggerDeferredAsserts(
//     //   { getters: e, commit: t },
//     //   { websiteId: i, sessionId: a, mode: o }
//     // ) {
//     //   let n = e.getDeferReference(i);
//     //   return (
//     //     n &&
//     //       n[a] &&
//     //       (n[a].triggers.forEach((n) => {
//     //         t("assertConversations", {
//     //           websiteId: i,
//     //         });
//     //         let r = e.getConversationReference(i, a);
//     //         n[o](r);
//     //       }),
//     //       t("deferCacheDelete", {
//     //         websiteId: i,
//     //         sessionId: a,
//     //       })),
//     //     Promise.resolve()
//     //   );
//     // },
//   },
//   mutations: {
//     storeConversation(
//       e,
//       {
//         tenantID: _tenantID,
//         sessionID: _sessionID,
//         session: _session,
//         proceedOrder: o = !0,
//         fullLoad: n = !0,
//       }
//     ) {
//       this.commit("conversationsTalqui/assertConversation", {
//         tenantID: _tenantID,
//         sessionID: _sessionID,
//       });
//       let r = this.getters["conversationsTalqui/getConversationReference"](
//         _tenantID,
//         _sessionID
//       );
//       r._$ = r._$ || {};
//       r._$.operators = r._$.operators || {};
//       r.contact = r.contact || {};
//       r.unread = {};
//       if (false === n) {
//         r._$.isFullLoad = false;
//       }
//       this.commit("conversationsTalqui/cleanConversationMessages", {
//         conversationDestination: r,
//         conversationSource: _session,
//       });
//       this.commit("conversationsTalqui/mergeConversations", {
//         conversationDestination: r,
//         conversationSource: _session,
//       });
//       this.commit("conversationsTalqui/updateConversationIndex", {
//         tenantID: _tenantID,
//         sessionID: _sessionID,
//         session: r,
//       });
//       // this.commit("conversationsTalqui/countLocalUnreadMessages", {
//       //   conversation: r,
//       //   proceedOrder: o,
//       // });
//       // this.commit("conversationsTalqui/updateComposeVisitor", {
//       //   conversation: r,
//       // });
//       // this.commit("conversationsTalqui/updateComposeOperator", {
//       //   conversation: r,
//       // });
//     },
//     assertConversations(e, { websiteId: t }) {
//       if ("object" !== typeof e.conversationsMap[t]) {
//         e.conversationsMap[t] = {};
//       }
//       if ("object" !== typeof e.conversationsList[t]) {
//         e.conversationsList[t] = [];
//       }
//       if ("object" !== typeof e.cacheTimeout[t]) {
//         e.cacheTimeout[t] = {};
//       }
//     },
//     assertConversation(e, { tenantID: _tenantID, sessionID: _sessionID }) {
//       if (
//         e.conversationsMap[_tenantID] &&
//         e.cacheTimeout[_tenantID] &&
//         e.conversationsList[_tenantID]
//       ) {
//         this.commit("conversationsTalqui/assertConversations", {
//           websiteId: _tenantID,
//         });
//       }
//       if ("object" !== typeof e.conversationsMap[_tenantID]) {
//         e.conversationsMap[_tenantID] = {};
//       }
//       if ("object" !== typeof e.conversationsMap[_tenantID][_sessionID]) {
//         e.conversationsMap[_tenantID][_sessionID] = {
//           active: {},
//           assigned: {
//             user_id: "",
//           },
//           availability: "",
//           createdAt: 0,
//           updatedAt: 0,
//           // is_blocked: false,
//           // in_call: false,
//           // is_verified: null,
//           has_unread_local: false,
//           unread_local: 0,
//           rating: {
//             stars: 0,
//             comment: "",
//             created_at: 0,
//           },
//           unread: {
//             visitor: 0,
//             operator: 0,
//           },
//           compose: {
//             visitor: "",
//             operator: {},
//           },
//           state: "pending",
//           status: 0,
//           lastMessage: "",
//           meta: {
//             nickname: "",
//             email: "",
//             device: {},
//             data: {},
//             phone: "",
//             avatar: "",
//             ip: "",
//             segments: [],
//           },
//           messages: [],
//           pages: [],
//           mentions: [],
//           contactID: "",
//           sessionID: _sessionID,
//           tenantID: _tenantID,
//         };
//       }
//     },
//     clearAll(e) {
//       this.commit("conversationsTalqui/clearConversationsMap", {
//         websiteId: null,
//         purgeRestoredStorage: !0,
//       });
//       for (let t in e.conversationsList)
//         c.default.emptyList(e.conversationsList[t]);
//     },
//     clearWebsite(e, { websiteId: t, flushCompletely: i = false }) {
//       this.commit("conversationsTalqui/assertConversations", {
//         websiteId: t,
//       }),
//         c.default.emptyList(e.conversationsList[t]),
//         i &&
//           this.commit("conversationsTalqui/clearConversationsMap", {
//             websiteId: t,
//             purgeRestoredStorage: !0,
//           });
//     },
//     clearConversationsMap(
//       e,
//       { websiteId: t, purgeRestoredStorage: i = false }
//     ) {
//       let a = e.conversationsMap;
//       for (let o in a)
//         if (null === t || o === t) {
//           let t = a[o],
//             n = e.currentSessionId,
//             r = null;
//           n && (r = t[n]),
//             c.default.emptyMap(t, n),
//             r && true === i && ((r._$ = {}), (r._$.replaceMessages = !0));
//         }
//     },
//     // createConversation(e, { websiteId: t, sessionId: i }) {
//     //   this.commit("conversationsTalqui/assertConversations", {
//     //     websiteId: t,
//     //   });
//     //   let a = this.getters["conversationsTalqui/getConversationReference"](t, i);
//     //   (a.session_id = i), e.conversationsList[t].push(a);
//     // },
//     mergeConversations(
//       e,
//       { conversationDestination: t, conversationSource: i }
//     ) {
//       if (t.messages && i.messages) {
//         let e = this.getters["conversationsTalqui/getMessageFingerprints"](i);
//         t.messages.forEach((t) => {
//           -1 === e.indexOf(t.fingerprint) &&
//             (e.push(t.fingerprint), i.messages.push(t));
//         });
//       }
//       if (i.messages) {
//         t.messages = [];
//       }
//       Object.assign(t, i);
//       // this.commit("conversationsTalqui/orderMessages", {
//       //   conversation: t,
//       // });
//     },
//     // removeConversation(e, { websiteId: t, sessionId: i }) {
//     //   this.commit("conversationsTalqui/removeConversationFromMap", {
//     //     websiteId: t,
//     //     sessionId: i,
//     //   }),
//     //     this.commit("conversationsTalqui/removeConversationFromList", {
//     //       websiteId: t,
//     //       sessionId: i,
//     //     }),
//     //     this.commit("conversationsTalqui/removeConversationFromSession", {
//     //       websiteId: t,
//     //       sessionId: i,
//     //     }),
//     //     r.default.triggerEvent("remove", {
//     //       websiteId: t,
//     //       sessionId: i,
//     //     });
//     // },
//     // removeConversationFromMap(e, { websiteId: t, sessionId: i }) {
//     //   let a = e.conversationsMap[t];
//     //   a && a[i] && delete a[i];
//     // },
//     // removeConversationFromList(e, { websiteId: t, sessionId: i }) {
//     //   let a = this.getters["conversationsTalqui/getList"](t),
//     //     o = this.getters["conversationsTalqui/getConversationIndex"](t, i);
//     //   a && -1 !== o && a.splice(o, 1);
//     // },
//     // removeConversationFromSession(e, { websiteId: t, sessionId: i }) {
//     //   let a = this.getters["conversationsTalqui/getSessionIds"](t),
//     //     o = a.indexOf(i);
//     //   -1 !== o && a.splice(o, 1);
//     // },
//     updateConversationIndex(
//       e,
//       { tenantID: _tenantID, sessionID: _sessionID, session: _session }
//     ) {
//       if ("undefined" === typeof e.conversationsList[_tenantID]) {
//         e.conversationsList[_tenantID] = [];
//         e.conversationsList[_tenantID] = [];
//       }
//       let o = this.getters["conversationsTalqui/getConversationIndex"](
//         _tenantID,
//         _sessionID
//       );
//       if (o < 0) {
//         e.conversationsList[_tenantID].push(_session);
//       }
//     },
//     // updateConversationPath(e, { path: t, update: i }) {
//     //   p(t, i);
//     // },
//     // updateConversationKey(e, { path: t, key: i, update: a }) {
//     //   t[i] = a;
//     // },
//     orderConversations(e, { tenantID: t }) {
//       if (e.conversationsList[t])
//         switch (this.getters["inboxTalqui/getActiveFilter"]) {
//           case "recent":
//             e.conversationsList[t].sort((0, a.firstBy)("updatedAt", -1));
//             break;
//           default:
//             e.conversationsList[t].sort(
//               (0, a.firstBy)("status")
//                 .thenBy("has_unread_local", -1)
//                 .thenBy("updated_at", -1)
//             );
//         }
//     },
//     orderMessages(e, { conversation: t }) {
//       t.messages && t.messages.sort((0, a.firstBy)("timestamp"));
//     },
//     // deferCache(e, { websiteId: t, sessionId: i, isFullLoad: a }) {
//     //   let o = this.getters["conversationsTalqui/getDeferReference"](t);
//     //   o[i] = {
//     //     isFullLoad: a,
//     //     triggers: [],
//     //   };
//     // },
//     // deferCacheLoaded(e, { websiteId: t, sessionId: i, isFullLoad: a }) {
//     //   let o = this.getters["conversationsTalqui/getDeferReference"](t);
//     //   o[i].isFullLoad = a;
//     // },
//     // deferCacheDelete(e, { websiteId: t, sessionId: i }) {
//     //   let a = this.getters["conversationsTalqui/getDeferReference"](t);
//     //   delete a[i];
//     // },
//     // deferCacheTriggers(
//     //   e,
//     //   { websiteId: t, sessionId: i, resolve: a, reject: o }
//     // ) {
//     //   let n = this.getters["conversationsTalqui/getDeferReference"](t);
//     //   n[i].triggers.push({
//     //     resolve: a,
//     //     reject: o,
//     //   });
//     // },
//     // setCurrentSessionId(e, { value: t }) {
//     //   e.currentSessionId = t;
//     // },
//     // setStateLocal(
//     //   e,
//     //   { websiteId: t, sessionId: i, conversation: a, value: o }
//     // ) {
//     //   let n = a;
//     //   switch (
//     //     (n ||
//     //       (this.commit("conversationsTalqui/assertConversations", {
//     //         websiteId: t,
//     //       }),
//     //       (n = this.getters["conversationsTalqui/getConversationReference"](t, i))),
//     //     (n.state = o),
//     //     o)
//     //   ) {
//     //     case "resolved":
//     //       (n.status = 2),
//     //         this.commit("conversationsTalqui/markAllMessagesAsRead", {
//     //           websiteId: t,
//     //           sessionId: i,
//     //         }),
//     //         n.unread && n.unread.operator && (n.unread.operator = 0);
//     //       break;
//     //     case "unresolved":
//     //       n.status = 1;
//     //       break;
//     //     default:
//     //       n.status = 0;
//     //   }
//     //   this.commit("conversationsTalqui/orderConversations", {
//     //     websiteId: t,
//     //   }),
//     //     this.commit("conversationsTalqui/updateConversationIndex", {
//     //       websiteId: t,
//     //       sessionId: i,
//     //       conversation: n,
//     //     });
//     // },
//     // setMeta(e, { websiteId: t, sessionId: i, value: a }) {
//     //   let o = e.conversationsMap[t][i].meta,
//     //     n = {
//     //       ...o,
//     //       ...a,
//     //     };
//     //   e.conversationsMap[t][i].meta = n;
//     // },
//     // setAssignedOperator(e, { websiteId: t, sessionId: i, value: a }) {
//     //   e.conversationsMap[t][i].assigned = a;
//     // },
//     // setBlocked(e, { websiteId: t, sessionId: i, value: a }) {
//     //   e.conversationsMap[t][i].blocked = a;
//     // },
//     // setParticipants(e, { websiteId: t, sessionId: i, value: a }) {
//     //   e.conversationsMap[t][i].participants = a || [];
//     // },
//     storeMessage(
//       e,
//       {
//         websiteId: t,
//         sessionId: i,
//         conversation: a,
//         value: o,
//         returnValue: n = null,
//         considerNew: r = false,
//         storeReverse: s = false,
//       }
//     ) {
//       let l = t || a.website_id;
//       let c = i || a.session_id;
//       let d = false;
//       console.log("L", l);
//       console.log("C", c);

//       a.messages || (a.messages = []),
//         a.fingerprints ||
//           (a.fingerprints =
//             this.getters["conversationsTalqui/getMessageFingerprints"](a)),
//         this.commit("conversationsTalqui/assertConversations", {
//           websiteId: l,
//         });
//       let u = a.fingerprints.indexOf(o.fingerprint);
//       if (-1 === u) {
//         (d = !0), (o.new = r);
//         let e = true === s ? "unshift" : "push";
//         a.fingerprints[e](o.fingerprint),
//           a.messages[e](o),
//           setTimeout(() => {
//             this.commit("conversationsTalqui/updateMessage", {
//               value: {
//                 websiteId: l,
//                 sessionId: c,
//                 fingerprint: o.fingerprint,
//                 new: false,
//               },
//             });
//           }, b);
//       } else {
//         let e = a.messages[u];
//         e &&
//           e.fingerprint === o.fingerprint &&
//           (e.translation &&
//             ((o.translation = e.translation), (o.content = e.content)),
//           p(e, o));
//       }
//       null !== n && (n.wasInserted = d);
//     },
//     // addMessage(e, { value: t }) {
//     //   const i = t.websiteId || t.website_id,
//     //     a = t.sessionId || t.session_id;
//     //   this.commit("filters/assertFilters", {
//     //     websiteId: i,
//     //     type: "conversation",
//     //   });
//     //   let o = this.getters["conversationsTalqui/getConversationReference"](i, a);
//     //   if (o) {
//     //     let e = {
//     //       conversation: o,
//     //       value: t,
//     //       considerNew: !0,
//     //       returnValue: {
//     //         wasInserted: false,
//     //       },
//     //     };
//     //     if (
//     //       (this.commit("conversationsTalqui/storeMessage", e),
//     //       true === e.returnValue.wasInserted)
//     //     ) {
//     //       let e = "event" !== t.type && !0;
//     //       true === e && (o.updated_at = Date.now()),
//     //         t.routing_ids || (t.routing_ids = []),
//     //         ("pending" !== o.state ||
//     //           ("user" !== t.from && !(t.user || {}).type)) &&
//     //           true === e &&
//     //           ((o.state = "unresolved"), (o.status = 1));
//     //       let r = "";
//     //       if (t.translation) r = t.translation.original || "";
//     //       else
//     //         switch (t.type) {
//     //           case "text":
//     //             r = "string" === typeof t.content ? t.content : "";
//     //             break;
//     //           case "animation":
//     //             r = "🎉";
//     //             break;
//     //           case "file":
//     //             r = t.content.name || "File";
//     //             break;
//     //         }
//     //       r && (o.last_message = u.default.textToSingleLine(r)),
//     //         this.commit("conversationsTalqui/updateComposeVisitor", {
//     //           conversation: o,
//     //         });
//     //       let l =
//     //           (((n.default.currentRoute || {}).value || {}).params || {})
//     //             .sessionId !== a || !document.hasFocus(),
//     //         c = (n.default.currentRoute || {}).value.name,
//     //         d = this.getters["inbox/getInboxCustomFilter"](i),
//     //         g = !0;
//     //       if (
//     //         (this.getters["inbox/isCommonFilter"]
//     //           ? (g = M.default.filterConversationItem(
//     //               o,
//     //               this.getters["inbox/getActiveFilter"],
//     //               this.state.user.me.user_id
//     //             ))
//     //           : d && (g = M.default.filterGenericItem(o, d)),
//     //         g &&
//     //           this.commit("conversationsTalqui/countLocalUnreadMessages", {
//     //             conversation: o,
//     //           }),
//     //         "user" === t.from && g)
//     //       ) {
//     //         const e = this.state.user.me.user_id,
//     //           n = t.routing_ids.some((t) => t === e);
//     //         this.commit("websites/incrementWebsiteUnreadCount", {
//     //           websiteId: i,
//     //           assigned: n,
//     //         }),
//     //           c.startsWith("app.") &&
//     //             l &&
//     //             (0 === t.routing_ids.length || n) &&
//     //             s.default.push({
//     //               body: r,
//     //               title: (o.meta || {}).nickname || "Message",
//     //               websiteId: i,
//     //               sessionId: a,
//     //               sound: !0,
//     //             });
//     //       } else
//     //         "note" === t.type &&
//     //           l &&
//     //           (t.user || {}).user_id === this.getters["user/getMe"].user_id &&
//     //           s.default.push({
//     //             body: s.default.translate("NOTE"),
//     //             websiteId: i,
//     //             sessionId: a,
//     //             sound: !0,
//     //           });
//     //     }
//     //   }
//     // },
//     // updateMessage(e, { value: t }) {
//     //   const i = t.websiteId || t.website_id,
//     //     a = t.sessionId || t.session_id;
//     //   let o = this.getters["conversationsTalqui/getConversationReference"](i, a);
//     //   if (o) {
//     //     let e = this.getters["conversationsTalqui/getMessageIndex"](o, t.fingerprint);
//     //     if (null !== e) {
//     //       let i = o.messages[e],
//     //         a = p({}, i, t);
//     //       a.translation &&
//     //         !t.translation &&
//     //         t.content &&
//     //         (i.translation &&
//     //         "text" === i.type &&
//     //         i.translation.original === t.content
//     //           ? (a.content = i.content)
//     //           : delete a.translation),
//     //         (o.messages[e] = a);
//     //     }
//     //   }
//     // },
//     // removeMessage(e, { websiteId: t, sessionId: i, fingerprint: a = 0 }) {
//     //   let o = this.getters["conversationsTalqui/getConversationReference"](t, i),
//     //     n = o.fingerprints.indexOf(a);
//     //   -1 !== n && o.fingerprints.splice(n, 1);
//     //   let r = o.messages.findIndex((e) => e.fingerprint === a);
//     //   -1 !== r && o.messages.splice(r, 1);
//     // },
//     // translateMessage(
//     //   e,
//     //   { websiteId: t, sessionId: i, fingerprint: a, locale: o, content: n }
//     // ) {
//     //   let r = this.getters["conversationsTalqui/getConversationReference"](t, i);
//     //   if (r) {
//     //     let e = this.getters["conversationsTalqui/getMessageIndex"](r, a);
//     //     if (null !== e && r.messages && r.messages[e]) {
//     //       let s = r.messages[e];
//     //       this.commit("conversationsTalqui/updateMessage", {
//     //         value: {
//     //           websiteId: t,
//     //           sessionId: i,
//     //           fingerprint: a,
//     //           content: n,
//     //           translation: {
//     //             locale: o,
//     //             original: s.content,
//     //           },
//     //         },
//     //       });
//     //     }
//     //   }
//     // },
//     // resetTranslateMessages(e, { websiteId: t, sessionId: i }) {
//     //   let a = this.getters["conversationsTalqui/getConversationReference"](t, i);
//     //   a &&
//     //     (a.messages || []).forEach((e) => {
//     //       e.translation &&
//     //         ((e.content = e.translation.original), delete e.translation);
//     //     });
//     // },
//     cleanConversationMessages(
//       e,
//       { conversationDestination: t, conversationSource: i }
//     ) {
//       if (
//         (true === t._$.replaceMessages &&
//           (delete t._$.replaceMessages, delete t.messages),
//         i && i.messages && i.messages.length > 1)
//       ) {
//         let e = {};
//         let t = [];
//         i.messages.forEach((i) => {
//           (e[i.fingerprint] = (e[i.fingerprint] || 0) + 1),
//             e[i.fingerprint] <= 1 && t.push(i);
//         });
//         if (i.messages.__status) {
//           t.__status = i.messages.__status;
//         }
//         i.messages = t;
//       }
//     },
//     // countLocalUnreadMessages(
//     //   e,
//     //   {
//     //     conversation: t,
//     //     returnValue: i = null,
//     //     proceedOrder: a = !0,
//     //     forceZeroNotLoaded: o = false,
//     //   }
//     // ) {
//     //   let n = a,
//     //     r = 0;
//     //   if ("object" === typeof t.messages) {
//     //     let e;
//     //     for (let i = t.messages.length - 1; i >= 0; i--)
//     //       if (((e = t.messages[i]), "user" === e.from)) {
//     //         if (e.read) break;
//     //         r++;
//     //       }
//     //   }
//     //   true !== (t._$ || {}).isFullLoad &&
//     //     t.unread &&
//     //     true !== o &&
//     //     t.unread.operator !== r &&
//     //     (r += t.unread.operator || 0);
//     //   let s = r > 0 && !0;
//     //   n = n || t.has_unread_local !== s;
//     //   let l = 0;
//     //   t.unread_local > r && (l = t.unread_local - r),
//     //     (t.has_unread_local = s),
//     //     (t.unread_local = r),
//     //     true === n &&
//     //       (this.commit("conversationsTalqui/orderConversations", {
//     //         websiteId: t.website_id,
//     //       }),
//     //       this.commit("conversationsTalqui/updateConversationIndex", {
//     //         websiteId: t.website_id,
//     //         sessionId: t.session_id,
//     //         conversation: t,
//     //       })),
//     //     null !== i && (i.decrementCount = l);
//     // },
//     // markAllMessagesAsRead(
//     //   e,
//     //   { websiteId: t, sessionId: i, returnValue: a = null }
//     // ) {
//     //   let o = [],
//     //     n = false,
//     //     r = this.getters["conversationsTalqui/getConversationReference"](t, i);
//     //   if (
//     //     (r &&
//     //       r.unread &&
//     //       r.unread.operator > 0 &&
//     //       ((r.unread.operator = 0), (n = !0)),
//     //     r && r.messages)
//     //   )
//     //     for (let s = 0; s < r.messages.length; s++)
//     //       r.messages[s].read ||
//     //         "user" !== r.messages[s].from ||
//     //         ((r.messages[s].read = "chat"),
//     //         r.messages[s].fingerprint &&
//     //           ((n = !0), o.push(r.messages[s].fingerprint)));
//     //   if (true === n) {
//     //     let e = {
//     //       conversation: r,
//     //       returnValue: {
//     //         decrementCount: 0,
//     //       },
//     //     };
//     //     this.commit("conversationsTalqui/countLocalUnreadMessages", e),
//     //       this.commit("websites/decrementWebsiteUnreadCount", {
//     //         websiteId: t,
//     //         decrement: e.returnValue.decrementCount,
//     //       });
//     //   }
//     //   null !== a && (a.fingerprints = o);
//     // },
//     // fixUnreadCount(e, { websiteId: t, conversations: i = [] }) {
//     //   let a = false,
//     //     o = 0;
//     //   for (o = 0; o < i.length; o++)
//     //     if (i[o].unread && 0 === i[o].unread.operator) break;
//     //   if (((a = o < i.length), a)) {
//     //     let e = i
//     //       .map((e) => (e.unread && e.unread.operator ? e.unread.operator : 0))
//     //       .reduce((e, t) => e + t);
//     //     e < m &&
//     //       this.commit("websites/updateWebsiteUnreadCount", {
//     //         websiteId: t,
//     //         count: e,
//     //         updateAssigned: false,
//     //       });
//     //   }
//     // },
//     setMessagesPaging(e, { conversation: t = {}, messages: i = [] }) {
//       // this.commit("conversationsTalqui/markMessagesPaging", {
//       //   conversation: t,
//       //   messages: i,
//       // });
//       for (let a = i.length - 1; a >= 0; a--) {
//         console.log(t, i[a]);
//         this.commit("conversationsTalqui/storeMessage", {
//           conversation: t,
//           value: i[a],
//           storeReverse: !0,
//         });
//       }

//       this.commit("conversationsTalqui/orderMessages", {
//         conversation: t,
//       });
//     },
//     // updateComposeVisitor(e, { conversation: t }) {
//     //   let i = (t.compose || {}).visitor;
//     //   i &&
//     //     this.commit("conversationsTalqui/updateCompose", {
//     //       conversation: t,
//     //       identifier: t.session_id,
//     //       type: "visitor",
//     //       compose: i,
//     //     }),
//     //     t.compose || (t.compose = {}),
//     //     t.compose.visitor || (t.compose.visitor = {});
//     // },
//     // updateComposeOperator(e, { conversation: t }) {
//     //   let i = (t.compose || {}).operator || {};
//     //   for (let a in i)
//     //     this.commit("conversationsTalqui/updateCompose", {
//     //       conversation: t,
//     //       identifier: a,
//     //       type: "operator",
//     //       compose: i[a],
//     //     });
//     //   t.compose || (t.compose = {}),
//     //     t.compose.operator || (t.compose.operator = {});
//     // },
//     // updateCompose(e, { conversation: t, identifier: i, type: a, compose: o }) {
//     //   let n = {
//     //     ongoing: !0,
//     //     excerpt: "",
//     //     user: null,
//     //   };
//     //   if (o && "start" === o.type) {
//     //     let e;
//     //     "visitor" === a &&
//     //       (e = this.getters["conversationsTalqui/getLatestMessage"](
//     //         t || null,
//     //         "user"
//     //       )),
//     //       e && e.timestamp > o.timestamp
//     //         ? (n.ongoing = false)
//     //         : ((n.ongoing = Date.now() - o.timestamp < I && !0),
//     //           true === n.ongoing &&
//     //             (o.excerpt
//     //               ? (n.excerpt = o.excerpt
//     //                   .trim()
//     //                   .replace(/(?:\r\n|\r|\n)/g, ""))
//     //               : (n.excerpt = ""),
//     //             o.user && (n.user = o.user)));
//     //   } else n.ongoing = false;
//     //   let r = "compose_" + a;
//     //   (t._$[r] = t._$[r] || {}), (t._$[r][i] = n);
//     //   let s = [];
//     //   ["visitor", "operator"].forEach((e) => {
//     //     let i = Object.values(t._$["compose_" + e] || {});
//     //     s = s.concat(
//     //       i.map((t) => ({
//     //         type: e,
//     //         compose: t,
//     //       }))
//     //     );
//     //   }),
//     //     (t._$.composeAll = s);
//     // },
//     // updateOperatorStatus(
//     //   e,
//     //   { websiteId: t, sessionId: i, event: a, isOpened: o = false }
//     // ) {
//     //   let n = e.conversationsMap[t];
//     //   for (let r in n) {
//     //     let e = n[r];
//     //     e._$ &&
//     //       e._$.operators &&
//     //       e.session_id !== i &&
//     //       e._$.operators[a.operator.user_id] &&
//     //       delete e._$.operators[a.operator.user_id];
//     //   }
//     //   if (n[i]) {
//     //     let e = n[i];
//     //     e._$ || (e._$ = {}),
//     //       e._$.operators || (e._$.operators = {}),
//     //       true === o
//     //         ? (e._$.operators[a.operator.user_id] = a.operator)
//     //         : delete e._$.operators[a.operator.user_id];
//     //   }
//     // },
//     markMessagesPaging(e, { conversation: t, messages: i }) {
//       206 === i.__status && i.length > 0
//         ? (t._$.firstMessageTimestamp = i[0].timestamp || -1)
//         : (t._$.firstMessageTimestamp = -1);
//     },
//     // setReadMessage(e, { websiteId: t, sessionId: i, event: a, direction: o }) {
//     //   let n = this.getters["conversationsTalqui/getConversationReference"](t, i),
//     //     r = e.conversationsMap[t];
//     //   if (n.messages) {
//     //     let e = "send" === o ? "user" : "operator";
//     //     n.messages.forEach((t, i) => {
//     //       t.from === e &&
//     //         r &&
//     //         (0 === a.fingerprints.length ||
//     //           (t.fingerprint &&
//     //             -1 !== a.fingerprints.indexOf(t.fingerprint))) &&
//     //         ((t.read = a.origin), (n.messages[i] = t));
//     //     });
//     //   }
//     //   if ("send" === o) {
//     //     let e = {
//     //       conversation: n,
//     //       proceedOrder: !0,
//     //       forceZeroNotLoaded: !0,
//     //       returnValue: {
//     //         decrementCount: 0,
//     //       },
//     //     };
//     //     this.commit("conversationsTalqui/countLocalUnreadMessages", e),
//     //       this.commit("websites/decrementWebsiteUnreadCount", {
//     //         websiteId: t,
//     //         decrement: e.returnValue.decrementCount,
//     //       });
//     //   }
//     // },
//     // setDeliveredMessage(e, { websiteId: t, sessionId: i, event: a }) {
//     //   let o = this.getters["conversationsTalqui/getConversationReference"](t, i),
//     //     n = e.conversationsMap[t];
//     //   o.messages &&
//     //     o.messages.forEach((e, t) => {
//     //       e.fingerprint &&
//     //         n &&
//     //         -1 !== a.fingerprints.indexOf(e.fingerprint) &&
//     //         ((e.delivered = a.origin), (o.messages[t] = e));
//     //     });
//     // },
//     // addMentions(e, { conversation: t, value: i = [] }) {
//     //   (i || []).forEach((e) => {
//     //     -1 === t.mentions.indexOf(e) && t.mentions.push(e);
//     //   }, !0),
//     //     -1 !== i.indexOf(this.getters["user/getMe"].user_id) &&
//     //       s.default.push({
//     //         body: s.default.translate("MENTIONED"),
//     //         websiteId: t.website_id,
//     //         sessionId: t.session_id,
//     //         sound: !0,
//     //       });
//     // },
//     // addPages(e, { conversation: t, value: i }) {
//     //   t.pages || (t.pages = []),
//     //     i.forEach((e) => {
//     //       (e.new = !0), t.pages.unshift(e);
//     //     });
//     // },
//   },
// };
