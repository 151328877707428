<template>
  <span
    :class="[
      'c-base-rating',
      {
        'c-base-rating--empty': empty,
      },
      'u-has-tooltip',
    ]"
  >
    <BaseIcon
      v-for="(item, index) in 5"
      :key="index"
      :size="computedSize"
      name="star"
      :class="[
        'c-base-rating__star',
        {
          'c-base-rating__star--inactive': index + 1 > rating,
        },
      ]"
    ></BaseIcon>
    <BaseTooltip
      v-if="tooltip"
      :direction="tooltipDirection"
      :align="tooltipAlign"
    >
      {{ tooltip }}
    </BaseTooltip>
  </span>
</template>
<script>
export default {
  props: {
    empty: {
      type: Boolean,
      default: !1,
    },
    rating: {
      type: Number,
      required: !0,
      validator(e) {
        return e >= 0 && e <= 5;
      },
    },
    tooltip: {
      type: String,
      default: "",
    },
    tooltipDirection: {
      type: String,
      default: "",
    },
    tooltipAlign: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "default",
      validator(e) {
        return -1 !== ["small", "default"].indexOf(e);
      },
    },
  },
  computed: {
    computedSize() {
      switch (this.size) {
        case "small":
          return "24px";
        case "default":
          return "28px";
        default:
          return;
      }
    },
  },
};
</script>
<style lang="scss">
.c-base-rating {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  .c-base-rating__star {
    margin-right: 0;
    fill: #faca00;
    &:last-of-type {
      margin-right: 0;
    }
  }
  .c-base-rating__star--inactive {
    fill: #a8c6df;
  }
}
.c-base-rating--empty {
  opacity: 0.3;
}
.c-base-rating.u-has-tooltip {
  .c-base-tooltip[data-align="right"] {
    &:after {
      margin-right: 5px;
    }
  }
  .c-base-tooltip[data-align="left"] {
    &:after {
      margin-left: 5px;
    }
  }
}
</style>
