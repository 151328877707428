import tenant from "./tenant";

class Campaigns {
  campaignBase({ tenantID }) {
    return tenant.tenantOne(tenantID).one("campaigns");
  }

  campaignOne({ tenantID, campaignID }) {
    return this.campaignBase({ tenantID }).one(campaignID);
  }

  campaignModelsBase({ tenantID }) {
    return tenant.tenantOne(tenantID).one("campaigns-models");
  }

  campaignModelsOne({ tenantID, campaignModelID }) {
    return this.campaignModelsBase({ tenantID }).one(campaignModelID);
  }
}
export default new Campaigns();
