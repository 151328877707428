class MessagesFilter {
  messageToSessionLastMessage(message) {
    return {
      messageDirection: message.messageDirection,
      messageID: message.messageID,
      messageKey: message.messageKey,
      messageStatus: message.messageStatus,
      messageValue: message.messageValue,
    };
  }
}

export default new MessagesFilter();
