export default {
  namespaced: !0,
  state: {
    spotlight: !1,
    call: {
      sessionId: null,
      mode: "",
      state: "",
      duration: 0,
    },
    resolver: {
      cachedKeys: {},
    },
    connected: !1,
    isSafeModeEnabled: !1,
    isUpdateAvailable: !1,
    reconnecting: !1,
    routing: {
      userId: null,
    },
    routeState: null,
    pendingRouteState: null,
    localeLoaded: !1,
    pendingRoute: null,
    previousRoute: null,
  },
  getters: {
    getSpotlight: (e) => e.spotlight,
    getCall: (e) => e.call,
    isConnected: (e) => e.connected,
    isSafeModeEnabled: (e) => e.isSafeModeEnabled,
    isUpdateAvailable: (e) => e.isUpdateAvailable,
    isReconnecting: (e) => e.reconnecting,
    getRouteState: (e) => e.routeState || "",
    getPendingRouteState: (e) => e.pendingRouteState || "",
    getPendingRoute: (e) => e.pendingRoute,
    getPreviousRoute: (e) => e.previousRoute,
    getLocaleLoaded: (e) => e.localeLoaded,
    getResolverCacheKey: (e) => (t) => e.resolver.cachedKeys[t],
  },
  mutations: {
    setSpotlight(e, { value: t }) {
      e.spotlight = t;
    },
    setCallValue(e, { key: t, value: i }) {
      e.call[t] = i;
    },
    setConnected(e, { value: t }) {
      e.connected = t;
    },
    setReconnecting(e, { value: t }) {
      e.reconnecting = t;
    },
    setUpdateAvailable(e, { value: t }) {
      e.isUpdateAvailable = t;
    },
    setRouteState(e, { value: t }) {
      e.routeState = t;
    },
    setPendingRouteState(e, { value: t }) {
      e.pendingRouteState = t;
    },
    setPendingRoute(e, { value: t }) {
      null === e.pendingRoute &&
        t.name &&
        !t.name.startsWith("initiate") &&
        "app" !== t.name &&
        (e.pendingRoute = {
          name: t.name,
          params: t.params,
          query: t.query,
        });
    },
    setPreviousRoute(e, { value: t }) {
      t.name &&
        "app" !== t.name &&
        (e.previousRoute = {
          name: t.name,
          params: t.params,
          query: t.query,
        });
    },
    resetPendingRoute(e) {
      e.pendingRoute = null;
    },
    resetPreviousRoute(e) {
      e.previousRoute = null;
    },
    setLocaleLoaded(e, { value: t }) {
      e.localeLoaded = t;
    },
    setSafeModeEnabled(e, { value: t }) {
      e.isSafeModeEnabled = t;
    },
    setResolverCacheKey(e, { value: t, key: i }) {
      e.resolver.cachedKeys[i] = t;
    },
  },
};
