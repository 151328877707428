<template>
  <div :class="['c-base-pages', 'c-base-pages--' + i.size]">
    <BaseLink v-for="(item, index) in displayedPages" :key="index">
      <BaseAvatar
        class="c-base-pages__favicon"
        :avatar-id="websiteId"
        :circular="!1"
        size="mini"
        type="website"
      ></BaseAvatar>

      <span class="c-base-pages__wrapper u-ellipsis">
        <span class="c-base-pages__title u-ellipsis">{{
          item.page_title
        }}</span>
        <span class="c-base-pages__path u-ellipsis">{{
          this.$filters.format.extractPathFromURI(item.page_url || "")
        }}</span>
      </span>

      <span class="c-base-pages__timestamp">{{
        this.$filters.date.monthYearFromTimestamp(item.timestamp, "full", !0)
      }}</span>

      <span class="c-base-pages__tooltip u-has-tooltip">
        <BaseIcon
          class="c-base-pages__icon"
          cursor="pointer"
          name="open_in_browser"
          size="20px"
        ></BaseIcon>
        <BaseTooltip v-if="0 !== index" align="right">{{
          this.$t("TOOLTIPS.PAGE_HISTORY_OPEN")
        }}</BaseTooltip>
      </span>

      <BaseTooltip v-if="0 === index && pages.length > 1" direction="bottom">{{
        this.$t("TOOLTIPS.PAGE_HISTORY_LAST")
      }}</BaseTooltip>
    </BaseLink>
  </div>
</template>
<script>
export default {
  props: {
    maxPages: {
      type: Number,
      default: 1 / 0,
    },
    pages: {
      type: Array,
      required: !0,
      validator(e) {
        return e.length > 0;
      },
    },
    size: {
      type: String,
      default: "default",
      validator(e) {
        return -1 !== ["small", "default"].indexOf(e);
      },
    },
    websiteId: {
      type: String,
      required: !0,
    },
  },
  computed: {
    displayedPages() {
      return this.maxPages === 1 / 0
        ? this.pages
        : this.pages.slice(0, this.maxPages);
    },
  },
};
</script>
<style lang="scss">
.c-base-pages {
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  .c-base-pages__page {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    outline: 0;
    border-bottom: 1px solid #e3e8ef;
    background-color: #fff;
    cursor: pointer;
    &:last-of-type {
      border-bottom: none;
    }
    &:hover {
      .c-base-pages__path {
        display: initial;
      }
      background-color: #fafbfc;
      .c-base-pages__timestamp {
        display: none;
      }
      .c-base-pages__tooltip {
        display: block;
        .c-base-pages__icon {
          fill: #0093ee;
        }
      }
    }
    .c-base-pages__favicon {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      margin-right: 9px;
    }
    .c-base-pages__wrapper {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
      margin-right: 10px;
      padding: 1px 0;
      color: #75869c;
    }
    .c-base-pages__title {
      color: #2c405a;
      font-weight: 700;
    }
    .c-base-pages__path {
      margin-left: 10px;
      display: none;
    }
    .c-base-pages__timestamp {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      color: #75869c;
      font-size: 12px;
    }
    .c-base-pages__tooltip {
      display: none;
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 20px;
      height: 20px;
      .c-base-pages__icon {
        fill: #75869c;
      }
      .c-base-tooltip[data-align="right"] {
        margin-right: -4px;
        &:after {
          margin-right: 10px;
        }
      }
    }
    &:active {
      background-color: #f7f8fa;
    }
  }
}
.c-base-pages--small {
  .c-base-pages__page {
    padding: 8px 20px;
    .c-base-pages__path {
      line-height: 19px;
      font-size: 11.5px;
    }
    .c-base-pages__title {
      line-height: 19px;
      font-size: 12px;
    }
  }
}
.c-base-pages--default {
  .c-base-pages__page {
    padding: 10px 15px;
    .c-base-pages__wrapper {
      width: 280px;
      margin-right: 20px;
    }
    .c-base-pages__path {
      line-height: 20px;
      font-size: 12.5px;
    }
    .c-base-pages__title {
      line-height: 20px;
      font-size: 13px;
    }
  }
}
</style>
