import a from "./http.js";
import o from "./auth.js";
import n from "./billing.js";
import r from "../store/index.js";

const l = {
  conversation: {
    session_id: "website:conversation:sessions",
    website_id: "*",
    people_id: "website:people:profiles",
    contact_data: "website:people:data",
    status: "website:conversation:states",
    state: "website:conversation:states",
    is_verified: "website:conversation:sessions",
    is_blocked: "website:conversation:sessions",
    availability: "website:conversation:sessions",
    active: "website:conversation:sessions",
    meta: "website:conversation:sessions",
    participants: "website:conversation:participants",
    last_message: "website:conversation:messages",
    messages: "website:conversation:messages",
    mentions: "website:conversation:messages",
    unread: "website:conversation:messages",
    in_call: "website:conversation:calls",
    assigned: "website:conversation:routing",
    created_at: "website:conversation:sessions",
    updated_at: "website:conversation:sessions",
  },
  operator: {
    user_id: "website:operators",
    avatar: "website:operators",
    email: "website:operators",
    first_name: "website:operators",
    last_name: "website:operators",
    locale: "website:operators",
    phone: "website:operators",
  },
  operators: {
    user_id: "website:operators",
    availability: "website:operators",
    avatar: "website:operators",
    email: "website:operators",
    first_name: "website:operators",
    last_name: "website:operators",
    has_token: "website:operators",
    role: "website:operators",
    title: "website:operators",
  },
  plugin_settings: {
    plugin_id: "*",
    website_id: "*",
    schema: "*",
    settings: "*",
  },
};
var c = 300;

class _plugins {
  constructor() {
    this.events();
  }
  plugins() {
    return a.one("plugins");
  }
  plugin() {
    return a.one("plugin");
  }
  getPlugins(e, t = {}) {
    return this.plugins().one("list/all", e).get(t);
  }
  getPluginsSearch(e, t) {
    return this.plugins().one("list/search", e).get(t);
  }
  getPlugin(e) {
    return this.plugin().one(e).get();
  }
  getPluginDetails(e) {
    return this.plugin().one(e).one("details").get();
  }
  getPluginFeatures(e, t) {
    return this.plugin().one(e).one("features", t).get();
  }
  getCategories(e) {
    return this.plugins().one("categories", e).get();
  }
  subscriptionAll() {
    return this.plugins().one("subscription");
  }
  subscriptionOne(e) {
    return this.subscriptionAll().one(e);
  }
  billing() {
    return this.plugins().one("billing");
  }
  billingAllPrice() {
    return this.billing().one("all", "price");
  }
  widgets() {
    return this.plugins().one("widgets");
  }
  acquireData(e, t, i, a) {
    return Promise.resolve()
      .then(() => {
        let t = (Object.values(l[a]) || []).filter(
          (e, t, i) => t === i.indexOf(e),
        );
        return t.length > 0
          ? this.ensurePermissions(e, i, t)
          : Promise.reject();
      })
      .then((o) => {
        switch (a) {
          case "conversation":
            return this.acquireWebsiteConversationData(e, t, o);
          case "operator":
            return this.acquireWebsiteOperatorData(o);
          case "operators":
            return this.acquireWebsiteOperatorsData(e, o);
          case "plugin_settings":
            return this.acquirePluginSettingsData(e, i, o);
        }
      });
  }
  ensurePermissions(e, t, i) {
    let a;
    return Promise.resolve()
      .then(
        () => (
          (a = r.getters["plugins/getPluginDetails"](t)), Promise.resolve()
        ),
      )
      .then(() =>
        0 === Object.keys(a || {}).length
          ? r.dispatch("plugins/acquirePluginDetails", {
              pluginId: t,
            })
          : Promise.resolve(),
      )
      .catch(() => Promise.resolve())
      .then(
        () => (
          (a = r.getters["plugins/getPluginDetails"](t)),
          0 === Object.keys(a || {}).length
            ? Promise.reject()
            : Promise.resolve((a || {}).scopes)
        ),
      )
      .then((e) => {
        let t = e
          .filter((e) => -1 !== e.permissions.indexOf("read"))
          .map((e) => e.namespace);
        if (-1 !== i.indexOf("*")) return Promise.resolve(t);
        for (let a = 0; a < t.length; a++) {
          let e = t[a];
          if (-1 !== i.indexOf(e)) return Promise.resolve(t);
        }
        return Promise.reject();
      })
      .catch((o) =>
        0 === Object.keys(a || {}).length
          ? new Promise((a, o) => {
              setTimeout(() => {
                this.ensurePermissions(e, t, i)
                  .then((e) => a(e))
                  .catch(() => o());
              }, c);
            })
          : Promise.reject(o),
      );
  }
  acquireWebsiteConversationData(e, t, i) {
    let a;
    return Promise.resolve()
      .then(
        () => (
          (a = r.getters["conversations/getConversationReference"](e, t)),
          Promise.resolve()
        ),
      )
      .then(() => {
        let e = this.__generateSafeData(a, i, "conversation");
        return Promise.resolve(e);
      });
  }
  acquireWebsiteOperatorData(e) {
    let t;
    return Promise.resolve()
      .then(() => ((t = r.getters["user/getMe"]), Promise.resolve()))
      .then(() =>
        0 === Object.keys(t || {}).length
          ? n.profile().get()
          : Promise.resolve(),
      )
      .catch(() => Promise.resolve())
      .then(() => ((t = r.getters["user/getMe"]), Promise.resolve()))
      .then(() => {
        let i = this.__generateSafeData(t, e, "operator");
        return Promise.resolve(i);
      });
  }
  acquireWebsiteOperatorsData(e, t) {
    let i;
    return Promise.resolve()
      .then(
        () => ((i = r.getters["operators/getOperators"](e)), Promise.resolve()),
      )
      .then(() =>
        0 === Object.keys(i || {}).length
          ? r.dispatch("operators/acquireOperators", {
              websiteId: e,
            })
          : Promise.resolve(),
      )
      .catch(() => Promise.resolve())
      .then(
        () => ((i = r.getters["operators/getOperators"](e)), Promise.resolve()),
      )
      .then(() => {
        let e = {};
        return (
          Object.keys(i).forEach((a) => {
            e[a] = this.__generateSafeData(i[a], t, "operators");
          }),
          Promise.resolve(e)
        );
      });
  }
  acquirePluginSettingsData(e, t, i) {
    let a;
    return Promise.resolve()
      .then(
        () => (
          (a = r.getters["plugins/getPluginSettings"](t)), Promise.resolve()
        ),
      )
      .then(() =>
        0 === Object.keys(a || {}).length
          ? r.dispatch("plugins/acquirePluginSettings", {
              websiteId: e,
              pluginId: t,
            })
          : Promise.resolve(),
      )
      .catch(() => Promise.resolve())
      .then(
        () => (
          (a = r.getters["plugins/getPluginSettings"](t)), Promise.resolve()
        ),
      )
      .then(() => {
        let e = this.__generateSafeData(a, i, "plugin_settings");
        return Promise.resolve(e);
      });
  }
  __generateSafeData(e, t, i) {
    const a = l[i];
    let o = {};
    for (let n in a) {
      let i = a[n];
      "*" !== i ? -1 !== t.indexOf(i) && (o[n] = e[n]) : (o[n] = e[n]);
    }
    return o;
  }
  bindPluginHandlers(e) {
    this.onPluginChannel = e;
  }
  unbindPluginHandlers() {
    this.onPluginChannel = void 0;
  }
  events() {
    o.on("plugin:channel", (e) => {
      this.onPluginChannel &&
        this.onPluginChannel(
          e.website_id,
          e.plugin_id,
          e.namespace,
          e.identifier,
          e.payload,
        );
    });
  }
}
export default new _plugins();
