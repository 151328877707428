<template>
  <div
    :class="[
      'c-base-tag',
      'c-base-tag--' + color,
      'c-base-tag--' + size,
      {
        'c-base-tag--filled': filled,
        'c-base-tag--has-remove': hasRemove,
        'c-base-tag--rounded': rounded,
        'c-base-tag--truncated': truncated,
        'c-base-tag--uppercase': uppercase,
        'u-has-tooltip': tooltip,
      },
    ]"
    :style="{
      backgroundColor: generatedColor,
    }"
  >
    <BaseTooltip v-if="tooltip" class="u-regular" :direction="tooltipDirection">
      {{ tooltip }}
    </BaseTooltip>
    <div class="c-base-tag__container">
      <div class="c-base-tag__label u-semibold u-ellipsis">{{ label }}</div>
      <span
        v-if="hasRemove"
        class="c-base-tag__remove"
        v-on:click="(...e) => onRemove(...e)"
      >
        <BaseIcon class="c-base-tag__icon" name="close" size="14px"></BaseIcon>
      </span>
    </div>
  </div>
</template>
<script>
import collorPalette from "@/classes/collorPalette.js";
export default {
  props: {
    color: {
      type: String,
      default: "",
    },
    palette: {
      type: String,
      default: null,
      validator(e) {
        return -1 !== ["segment"].indexOf(e);
      },
    },
    filled: {
      type: Boolean,
      default: !0,
    },
    hasRemove: {
      type: Boolean,
      default: !1,
    },
    id: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      required: !0,
    },
    rounded: {
      type: Boolean,
      default: !0,
    },
    size: {
      type: String,
      default: "normal",
      validator(e) {
        return -1 !== ["mini", "small", "normal", "medium", "large"].indexOf(e);
      },
    },
    truncated: {
      type: Boolean,
      default: !0,
    },
    tooltip: {
      type: String,
      default: null,
    },
    tooltipDirection: {
      type: String,
      default: "",
    },
    uppercase: {
      type: Boolean,
      default: !1,
    },
  },
  emits: ["remove"],
  computed: {
    generatedColor() {
      return this.color && "#" === this.color[0]
        ? this.color
        : !this.color && this.palette
        ? "#" + (collorPalette.palette(this.label, this.palette) || [])[0]
        : void 0;
    },
  },
  methods: {
    onRemove() {
      this.$emit("remove", this.id);
    },
  },
};
</script>
<style lang="scss">
.c-base-tag {
  display: inline-block;
  background-color: #fff;
  .c-base-tag__container {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .c-base-tooltip[data-align="left"] {
    margin-left: 0;
    &:after {
      margin-left: 10px;
    }
  }
  .c-base-tooltip[data-align="right"] {
    margin-right: 0;
    &:after {
      margin-right: 10px;
    }
  }
}
.c-base-tag--black {
  color: #25374f;
}
.c-base-tag--blue {
  color: #0093ee;
}
.c-base-tag--green {
  color: #40b630;
}
.c-base-tag--orange {
  color: #fd7b1f;
}
.c-base-tag--purple {
  color: #ab7df6;
}
.c-base-tag--red {
  color: #e0102b;
}
.c-base-tag--turquoise {
  color: #26c1c9;
}
.c-base-tag--yellow {
  color: #faca00;
}
.c-base-tag--mini {
  .c-base-tag__container {
    .c-base-tag__label {
      padding: 2px 6px;
      font-size: 10px;
      line-height: 15px;
    }
  }
}
.c-base-tag--small {
  .c-base-tag__container {
    .c-base-tag__label {
      padding: 3px 7px;
      font-size: 11px;
      line-height: 16px;
    }
  }
}
.c-base-tag--normal {
  .c-base-tag__container {
    .c-base-tag__label {
      padding: 4px 8px;
      font-size: 12px;
      line-height: 17px;
    }
  }
}
.c-base-tag--medium {
  .c-base-tag__container {
    .c-base-tag__label {
      padding: 5px 10px;
      font-size: 13px;
      line-height: 18px;
    }
  }
}
.c-base-tag--large {
  .c-base-tag__container {
    .c-base-tag__label {
      padding: 6px 12px;
      font-size: 14px;
      line-height: 19px;
    }
  }
}
.c-base-tag--filled {
  color: #fff;
}
.c-base-tag--filled.c-base-tag--black {
  background-color: #25374f;
}
.c-base-tag--filled.c-base-tag--blue {
  background-color: #0093ee;
}
.c-base-tag--filled.c-base-tag--green {
  background-color: #40b630;
}
.c-base-tag--filled.c-base-tag--orange {
  background-color: #fd7b1f;
}
.c-base-tag--filled.c-base-tag--purple {
  background-color: #ab7df6;
}
.c-base-tag--filled.c-base-tag--red {
  background-color: #e0102b;
}
.c-base-tag--filled.c-base-tag--turquoise {
  background-color: #26c1c9;
}
.c-base-tag--filled.c-base-tag--yellow {
  background-color: #faca00;
}
.c-base-tag--filled.c-base-tag--white {
  background-color: #fff;
  color: #2c405a;
}
.c-base-tag--has-remove {
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  cursor: pointer;
  .c-base-tag__container {
    .c-base-tag__remove {
      position: absolute;
      display: none;
      width: 100%;
      height: 22px;
      text-align: center;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      .c-base-tag__icon {
        margin-top: 4px;
        fill: #fff;
      }
    }
  }
  &:hover {
    .c-base-tag__container {
      .c-base-tag__label {
        opacity: 0;
      }
      .c-base-tag__remove {
        display: block;
      }
    }
  }
}
.c-base-tag--rounded {
  border-radius: 2px;
}
.c-base-tag--truncated {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  .c-base-tag__container {
    .c-base-tag__label {
      max-width: 100px;
    }
  }
}
.c-base-tag--uppercase {
  text-transform: uppercase;
}
</style>
