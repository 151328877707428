import startsWith from "lodash.startswith";

const n = "custom_";

export default {
  namespaced: true,
  state: {
    sessions: {},
    activeFilter: "queued",
    activeSessionKind: "both",
    activeUserRouting: null,
    editorMode: !1,
    layout: {},
    settings: {},
  },
  getters: {
    // getSessionInbox: (e) => (t) => e.sessions[t],
    getActiveFilter: (e) => e.activeFilter,
    getActiveSessionKind: (e) => e.activeSessionKind,
    isCommonFilter: (e) => !startsWith(e.activeFilter, n),
    // getInboxCustomFilter: (e, t, i, a) => (t) => {
    //   let i = a["inbox/getInboxCustomFilters"](t);
    //   return i.find((t) => t.filter_id === e.activeFilter.substr(n.length));
    // },
    getInboxCustomFilters: (e, t, i, a) => (e) =>
      a["filters/getFilters"](e, "conversation"),
    // getActiveUserRouting: (e) => e.activeUserRouting,
    // isInEditorMode: (e) => e.editorMode,
    getLayout: (e) => (t) => e.layout[t],
    // getSetting: (e) => (t) => e.settings[t],
  },
  mutations: {
    reset(state) {
      state.sessions = {};
      state.activeFilter = "queued";
      state.activeSessionKind = "both";
      state.activeUserRouting = null;
      state.editorMode = !1;
      state.layout = {};
      state.settings = {};
    },
    assertSessionInbox(e, { sessionId: t }) {
      e.sessions[t] ||
        (e.sessions[t] = {
          message: "",
          uploading: !1,
          bypassSuggestions: !1,
          fieldStatus: "reply",
          activeField: "default",
          activeMenu: "tabs",
          activeSwitcher: "smileys",
          translate: {
            from: "",
            to: "",
            active: !1,
            newMessageIsTranslating: !1,
          },
          lastMessages: {
            unreadBannerActive: !1,
            unreadBannerDisabled: !1,
            emptyBannerActive: !1,
            size: 0,
            isFirst: !0,
          },
          helpdesk: {
            defer: null,
            searchId: null,
            all: [],
          },
        });
    },
    updateSessionUploading(state, { sessionID, value }) {
      state.sessions[sessionID].uploading = value;
    },
    updateSessionMessage(e, { sessionId: t, value: i }) {
      e.sessions[t].message = i;
    },
    updateBypassSuggestions(e, { sessionId: t, value: i }) {
      e.sessions[t].bypassSuggestions = i;
    },
    updateSessionFieldStatus(e, { sessionId: t, value: i }) {
      e.sessions[t].fieldStatus = i;
    },
    updateSessionActiveField(e, { sessionId: t, value: i }) {
      e.sessions[t].activeField = i;
    },
    updateSessionActiveMenu(e, { sessionId: t, value: i }) {
      e.sessions[t].activeMenu = i;
    },
    updateSessionActiveSwitcher(e, { sessionId: t, value: i }) {
      e.sessions[t].activeSwitcher = i;
    },
    updateSessionTranslate(e, { sessionId: t, key: i, value: a }) {
      e.sessions[t].translate[i] = a;
    },
    updateSessionLastMessages(e, { sessionId: t, key: i, value: a }) {
      e.sessions[t].lastMessages[i] = a;
    },
    updateSessionHelpdesk(e, { sessionId: t, key: i, value: a }) {
      e.sessions[t].helpdesk[i] = a;
    },
    setActiveFilter(e, { value: t }) {
      e.activeFilter = t;
    },
    setActiveSessionKind(e, { value: t }) {
      e.activeSessionKind = t;
    },
    setActiveUserRouting(e, { value: t }) {
      e.activeUserRouting = t;
    },
    setEditorMode(e, { value: t }) {
      e.editorMode = t;
    },
    setLayout(e, { item: t, key: i, value: a }) {
      e.layout[t] || (e.layout[t] = {}), (e.layout[t][i] = a);
    },
    setSetting(e, { key: t, value: i }) {
      e.settings[t] = i;
    },
  },
};
