import Vuex from "vuex";
import createdPersistedState from "vuex-persistedstate";

import storeCommon from "@/store/common";
import storeFilters from "@/store/filters";
import storeInbox from "@/store/inbox";
import storePlugins from "@/store/plugins";
import storeWebsites from "@/store/websites";
import storeWidgets from "@/store/widgets";

import storeTalquiContacts from "@/store/talqui/contacts";
import storeInboxTalqui from "@/store/talqui/inbox";
import storeConversationsTalqui from "@/store/talqui/conversations";
import storeTenantsTalqui from "@/store/talqui/tenants";
import storeSessionsTalqui from "@/store/talqui/_sessions";
import storeOperatorsTalqui from "@/store/talqui/operators";
import storeWidgetsTalqui from "@/store/talqui/widgets";
import storeOrganizationsTalqui from "@/store/talqui/organizations";
import storePluginsTalqui from "@/store/talqui/plugins";
import storeNotificationsTalqui from "@/store/talqui/notifications";
import storeCampaignsTalqui from "@/store/talqui/campaigns";
import storeShortcutsTalqui from "@/store/talqui/shortcuts";
import storeAnalyticsTalqui from "@/store/talqui/analytics";
import storeSubscriptionsTalqui from "@/store/talqui/subscriptions";

const $modules = {
  common: storeCommon,
  conversationsTalqui: storeConversationsTalqui,
  filters: storeFilters,
  inbox: storeInbox,
  inboxTalqui: storeInboxTalqui,
  plugins: storePlugins,
  websites: storeWebsites,
  contactsTalqui: storeTalquiContacts,
  tenantsTalqui: storeTenantsTalqui,
  sessionsTalqui: storeSessionsTalqui,
  operatorsTalqui: storeOperatorsTalqui,
  widgets: storeWidgets,
  widgetsTalqui: storeWidgetsTalqui,
  organizationsTalqui: storeOrganizationsTalqui,
  pluginsTalqui: storePluginsTalqui,
  notificationsTalqui: storeNotificationsTalqui,
  campaignsTalqui: storeCampaignsTalqui,
  shortcutsTalqui: storeShortcutsTalqui,
  analyticsTalqui: storeAnalyticsTalqui,
  subscriptionsTalqui: storeSubscriptionsTalqui,
};

export default new Vuex.createStore({
  strict: false,
  plugins: [
    createdPersistedState({
      key: "_talqui",
      paths: [
        "operatorsTalqui.token",
        "operatorsTalqui.me",
        "widgetsTalqui.widgets",
        "widgetsTalqui.pluginsWidgets",
        "inboxTalqui.layout",
        "inboxTalqui.activeFilter",
        "inboxTalqui.activeUserRouting",
        "tenantsTalqui.tenantID",
        // "alerts.count",
        // "inbox.activeFilter",
        // "inbox.activeUserRouting",
        // "inbox.editorMode",
        // "inbox.cards",
        // "contacts.profileSections",
        // "contacts.import",
        // "filters.filters",
        // "user.me",
        // "user.notification",
        // "user.schedule",
        // "user.pendingCardProvider",
        // "user.pendingPlan",
        // "segments.segments",
        // "segments.lastListed",
        // "websites.websiteId",
        // "websites.websites",
        // "websites.currentWebsite",
        // "websites.unreadCount",
        // "websites.setup",
        // "data.lastListed",
        // "data.dataKeys",
      ],
    }),
  ],
  modules: $modules,
});
