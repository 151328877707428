import campaigns from "@/classes/talqui/campaigns.js";
import qs from "qs";

export default {
  namespaced: true,
  state: {
    campaigns: {},
    campaignModels: {},
    tags: [],
    recipients: [],
    statistics: [],
  },
  getters: {
    getCampaigns: (state) => (tenantID) => state.campaigns[tenantID],
    getCampaign: (state) => (tenantID, campaignID) =>
      state.campaigns[tenantID][campaignID],

    getCampaignModels: (state) => (tenantID) => state.campaignModels[tenantID],
    getCampaignModel: (state) => (tenantID, campaignModelID) =>
      state.campaignModels[tenantID][campaignModelID],

    getTags: (state) => state.tags,
    getRecipients: (state) => state.recipients,
    getStatistics: (state) => state.statistics,
  },
  actions: {
    acquireCampaigns({ commit }, { tenantID, page = 1, filters = null }) {
      return Promise.resolve()
        .then(() => {
          if (1 === page) {
            commit("resetCampaigns");
            return Promise.resolve();
          } else {
            return Promise.resolve();
          }
        })
        .then((res) => {
          commit("assertCampaignTenant", {
            tenantID: tenantID,
          });

          return res;
        })
        .then(() => {
          return campaigns.campaignBase({ tenantID }).get({
            page,
            ...(filters && { filters: qs.stringify(filters) }),
          });
        })
        .then((res) => {
          (res || []).forEach((campaign) => {
            commit("storeCampaign", {
              tenantID: tenantID,
              campaignID: campaign.campaignID,
              campaign: campaign,
            });
          });

          return 206 === res.__status
            ? Promise.resolve(false)
            : Promise.resolve(true);
        })
        .catch(() => Promise.resolve([]));
    },

    acquireCampaign({ commit }, { tenantID, campaignID }) {
      return campaigns
        .campaignOne({ tenantID, campaignID })
        .get()
        .then((res) => {
          commit("assertCampaignTenant", {
            tenantID: tenantID,
          });

          return res;
        })
        .then((res) => {
          commit("storeCampaign", {
            tenantID: tenantID,
            campaignID: campaignID,
            campaign: res.campaign,
          });

          return Promise.resolve(res.campaign);
        });
    },

    acquireModels(
      { commit },
      {
        tenantID,
        page = 1,
        sortBy = "createdAt",
        sortDesc = -1,
        filters = null,
      },
    ) {
      return campaigns
        .campaignModelsBase({
          tenantID,
        })
        .get({
          page,
          sortBy,
          sortDesc,
          ...(filters && { filters: qs.stringify(filters) }),
        })
        .then((res) => {
          commit("assertCampaignTenant", {
            tenantID: tenantID,
          });

          return res;
        })
        .then((res) => {
          (res || []).forEach((campaignModel) => {
            commit("storeModel", {
              tenantID: tenantID,
              campaignModelID: campaignModel.campaignModelID,
              campaignModel: campaignModel,
            });
          });

          return Promise.resolve();
        });
    },

    acquireModel({ commit }, { tenantID, campaignModelID }) {
      return campaigns
        .campaignModelsOne({ tenantID, campaignModelID })
        .get()
        .then((res) => {
          commit("assertCampaignTenant", {
            tenantID: tenantID,
          });

          return res;
        })
        .then((res) => {
          commit("storeModel", {
            tenantID: tenantID,
            campaignModelID: campaignModelID,
            campaignModel: res,
          });
          return Promise.resolve(res);
        });
    },

    createCampaign({ dispatch }, { tenantID, campaignName, campaignChannel }) {
      return campaigns
        .campaignBase({
          tenantID,
        })
        .post({
          campaignName,
          campaignChannel,
        })
        .then((res) => {
          return dispatch("acquireCampaign", {
            tenantID: tenantID,
            campaignID: res.campaign.campaignID,
          });
        });
    },

    updateCampaign(e, { tenantID, campaignID, updateData }) {
      return campaigns
        .campaignOne({ tenantID: tenantID, campaignID: campaignID })
        .patch(updateData);
    },

    scheduleCampaign(
      { dispatch },
      { tenantID, campaignID, campaignVariables = [] },
    ) {
      return campaigns
        .campaignOne({ tenantID: tenantID, campaignID: campaignID })
        .put({
          campaignVariables: campaignVariables,
        })
        .then((res) => {
          dispatch("acquireCampaign", {
            tenantID: tenantID,
            campaignID: campaignID,
          });

          return Promise.resolve(res);
        });
    },

    createCampaignModel(
      { dispatch },
      {
        tenantID,
        campaignModelChannel,
        campaignModelName,
        campaignModelTags,
        campaignModelSlots,
      },
    ) {
      return campaigns
        .campaignModelsBase({
          tenantID,
        })
        .post({
          campaignModelChannel,
          campaignModelName,
          campaignModelTags,
          campaignModelSlots,
        })
        .then((res) => {
          dispatch("acquireModel", {
            tenantID: tenantID,
            campaignModelID: res.campaignModel.campaignModelID,
          });
          return res.campaignModel;
        });
    },

    updateCampaignModelSlot(
      { commit },
      { campaignModelID, slotType, fieldKey, fieldValue },
    ) {
      return new Promise((resolve) => {
        commit("setCampaignModelSlot", {
          campaignModelID,
          slotType,
          fieldKey,
          fieldValue,
        });
        return resolve();
      });
    },
  },
  mutations: {
    resetCampaigns(state) {
      state.campaigns = [];
      state.campaignModels = {};
    },

    assertCampaignTenant(state, { tenantID }) {
      if ("object" !== typeof state.campaignModels[tenantID]) {
        state.campaignModels[tenantID] = {};
      }
      if ("object" !== typeof state.campaigns[tenantID]) {
        state.campaigns[tenantID] = {};
      }
    },

    assertCampaign(state, { tenantID, campaignID }) {
      if ("object" !== typeof state.campaigns[tenantID][campaignID]) {
        state.campaigns[tenantID][campaignID] = {
          tenantID: tenantID,
          campaignID: campaignID,
          campaignModel: {},
          campaignContactIDs: [],
        };
      }

      state.campaigns[tenantID][campaignID].campaignContactIDs =
        state.campaigns[tenantID][campaignID].campaignContactIDs || [];
      state.campaigns[tenantID][campaignID].__campaignMetrics = state.campaigns[
        tenantID
      ][campaignID].__campaignMetrics || {
        total: 0,
        pending: 0,
        sended: 0,
      };

      state.campaigns[tenantID][campaignID]._$ =
        state.campaigns[tenantID][campaignID]._$ || {};
      state.campaigns[tenantID][campaignID]._$.campaignType =
        state.campaigns[tenantID][campaignID]._$.campaignType || "people";
      state.campaigns[tenantID][campaignID]._$.slots ||
        (state.campaigns[tenantID][campaignID]._$.slots = {});
      state.campaigns[tenantID][campaignID]._$.variables ||
        (state.campaigns[tenantID][campaignID]._$.variables = {});
    },

    async storeCampaign(state, { tenantID, campaignID, campaign }) {
      this.commit("campaignsTalqui/assertCampaign", {
        tenantID,
        campaignID,
      });

      let $campaign = this.getters["campaignsTalqui/getCampaign"](
        tenantID,
        campaignID,
      );

      /**
       * Prepare campaign with data from campaignModel
       */
      if (Object.keys(campaign?.campaignModel || {}).length > 0) {
        /**
         * Prepare slots with fallbacks
         */
        const slotsWithFallbacks = Object.fromEntries(
          Object.entries(campaign.campaignModel.campaignModelSlots).reduce(
            (state, [slotKey, slotValue]) => {
              slotValue.variables = (slotValue.variables || []).map(
                (element) => {
                  return {
                    ...element,
                    value: element.fallback || null,
                  };
                },
              );
              return state.concat([[slotKey, slotValue]]);
            },
            [],
          ),
        );
        $campaign._$.slots = slotsWithFallbacks;

        /**
         * Refresh campaign variables based on slots
         */
        this.commit("campaignsTalqui/refreshCampaignVariables", {
          tenantID,
          campaignID,
        });
      }

      Object.assign($campaign, {
        ...campaign,
      });
    },

    assertModel(state, { tenantID, campaignModelID }) {
      if ("object" !== typeof state.campaignModels[tenantID][campaignModelID]) {
        state.campaignModels[tenantID][campaignModelID] = {
          tenantID: tenantID,
          campaignModelID: campaignModelID,
          campaignModelStatus: "created",
          campaignModelTags: [],
          campaignModelName: "",
          campaignModelSlots: {},
        };
      }

      state.campaignModels[tenantID][campaignModelID]._$ =
        state.campaignModels[tenantID][campaignModelID]._$ || {};
      state.campaignModels[tenantID][campaignModelID]._$.slots =
        state.campaignModels[tenantID][campaignModelID]._$.slots || {};
      state.campaignModels[tenantID][campaignModelID]._$.variables =
        state.campaignModels[tenantID][campaignModelID]._$.variables || {};
    },

    storeModel(state, { tenantID, campaignModelID, campaignModel }) {
      this.commit("campaignsTalqui/assertModel", {
        tenantID,
        campaignModelID,
      });

      let $model = this.getters["campaignsTalqui/getCampaignModel"](
        tenantID,
        campaignModelID,
      );

      /**
       * Prepare slots with fallbacks
       */
      const slotsWithFallbacks = Object.fromEntries(
        Object.entries(campaignModel.campaignModelSlots).reduce(
          (state, [slotKey, slotValue]) => {
            slotValue.variables = (slotValue.variables || []).map((element) => {
              return {
                ...element,
                value: element.fallback || null,
              };
            });
            return state.concat([[slotKey, slotValue]]);
          },
          [],
        ),
      );
      $model._$.slots = Object.assign({}, $model._$.slots, slotsWithFallbacks);

      /**
       * Prepare variables from slots
       */
      const variables = Object.values($model._$.slots).reduce(
        (state, slotValue) => {
          (slotValue.variables || []).forEach((element) => {
            state[element.key] = element;
          });

          return state;
        },
        {},
      );
      $model._$.variables = Object.assign({}, $model._$.variables, variables);

      Object.assign($model, {
        ...campaignModel,
      });
    },

    setCampaignVariable(
      state,
      { tenantID, campaignID, variableKey, variableValue },
    ) {
      if (state.campaigns[tenantID][campaignID]) {
        Object.fromEntries(
          Object.entries(state.campaigns[tenantID][campaignID]._$.slots).reduce(
            (state, [slotKey, slotValue]) => {
              (slotValue.variables || []).map((variable) => {
                if (variable.key === variableKey) {
                  variable.value = String(variableValue);
                }

                return variable;
              });

              return [[slotKey, slotValue]];
            },
            [],
          ),
        );

        this.commit("campaignsTalqui/refreshCampaignVariables", {
          tenantID,
          campaignID,
        });
      }
    },

    refreshCampaignVariables(state, { tenantID, campaignID }) {
      this.commit("campaignsTalqui/assertCampaign", {
        tenantID,
        campaignID,
      });

      let $campaign = this.getters["campaignsTalqui/getCampaign"](
        tenantID,
        campaignID,
      );

      /**
       * Prepare variables from campaignModelSlots
       */
      const variables = Object.values($campaign._$.slots).reduce(
        (state, slotValue) => {
          (slotValue.variables || []).forEach((element) => {
            state[element.key] = element;
          });
          return state;
        },
        {},
      );
      $campaign._$.variables = variables;
    },

    updateCampaignType(state, { tenantID, campaignID, campaignType }) {
      let $campaign = state.campaigns[tenantID][campaignID];
      if ($campaign) {
        $campaign._$.campaignType = campaignType;
      }
    },

    updateCampaignRecipientsItems(
      state,
      { tenantID, campaignID, type, items },
    ) {
      let $campaign = state.campaigns[tenantID][campaignID];

      if ($campaign) {
        if (["people", "import"].includes(type) && 1 === items.length) {
          let __itemIndex = $campaign.campaignContactIDs.indexOf(items[0]);
          -1 === __itemIndex
            ? $campaign.campaignContactIDs.push(items[0])
            : $campaign.campaignContactIDs.splice(__itemIndex, 1);
        } else if (items.length > 1) {
          $campaign.campaignContactIDs = items;
        }
      }
    },
  },
};
