<template>
  <div
    :readonly="readonly"
    :class="[
      'c-field-color',
      'c-field-color--' + size,
      {
        'c-field-color--block': block,
        'c-field-color--bordered': hasBorders,
      },
    ]"
  >
    <div v-if="label" class="c-field-color__header">
      <FieldLabel class="c-field-color__label" :for-field="uuid" :size="size">
        {{ label }}
      </FieldLabel>
    </div>

    <input
      class="c-field-color__field js-field-color-field"
      v-on:input="(...e) => onInput(...e)"
      :id="uuid"
      :name="name"
      :placeholder="placeholder"
      :value="currentValue"
      :disabled="disabled"
      type="color"
      ref="input"
    />
  </div>
</template>
<script>
import { v4 } from "uuid";
export default {
  props: {
    block: {
      type: Boolean,
      default: !0,
    },
    disabled: {
      type: Boolean,
      default: !1,
    },
    hasBorders: {
      type: Boolean,
      default: !0,
    },
    label: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: !1,
    },
    size: {
      type: String,
      default: "default",
    },
    modelValue: {
      type: String,
      default: null,
    },
  },
  emits: ["update:modelValue", "change"],
  data() {
    return {
      currentValue: "",
      uuid: null,
    };
  },
  watch: {
    modelValue: {
      immediate: !0,
      handler(e) {
        this.currentValue = e;
      },
    },
  },
  created() {
    this.uuid = v4();
  },
  methods: {
    onInput(e) {
      this.currentValue = this.$refs.input.value || "";
      this.$emit("change", this.currentValue, this.name || this.uuid, e);
      this.$emit("update:modelValue", this.currentValue);
    },
  },
};
</script>
<style lang="scss">
.c-field-color {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  color: #2c405a;
  text-align: left;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  .c-field-color__header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    .c-field-color__label {
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
    }
  }
  .c-field-color__field {
    display: block;
    overflow: hidden;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    background-color: #fafbfc;
    color: #a8c6df;
    &::-webkit-input-placeholder {
      color: #75869c;
    }
    &::-moz-placeholder {
      color: #75869c;
    }
    &::-ms-input-placeholder {
      color: #75869c;
    }
    &::placeholder {
      color: #75869c;
    }
    &:disabled {
      cursor: not-allowed;
    }
    &:hover {
      cursor: crosshair;
    }
    &:focus {
      outline: none;
      border-color: #2c405a;
      color: #2c405a;
    }
  }
}
.c-field-color--mini {
  .c-field-color__field {
    min-height: 32px;
    height: 32px;
    border-radius: 2px;
  }
}
.c-field-color--small {
  .c-field-color__field {
    min-height: 36px;
    height: 36px;
    border-radius: 3px;
  }
}
.c-field-color--default {
  .c-field-color__field {
    min-height: 40px;
    height: 40px;
    border-radius: 4px;
  }
}
.c-field-color--medium {
  .c-field-color__field {
    min-height: 44px;
    height: 44px;
    border-radius: 5px;
  }
}
.c-field-color--large {
  .c-field-color__field {
    min-height: 48px;
    height: 48px;
    border-radius: 6px;
  }
}
.c-field-color--block {
  width: 100%;
}
.c-field-color--bordered {
  .c-field-color__field {
    border-width: 1px;
    border-style: solid;
    border-color: #a8c6df;
    border-radius: 3px;
    -webkit-transition: border 0.2s linear;
    transition: border 0.2s linear;
  }
}
</style>
