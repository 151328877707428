<template>
  <div class="c-base-breadcrumb">
    <div class="c-base-breadcrumb__header">
      <h3 class="c-base-breadcrumb__title">{{ title }}</h3>
    </div>
    <div class="c-base-breadcrumb__line c-base-breadcrumb__line--full"></div>
    <div
      class="c-base-breadcrumb__line c-base-breadcrumb__line--active"
      :style="{
        width: progressWidth,
      }"
    ></div>
    <div class="c-base-breadcrumb__items">
      <div
        v-for="(item, index) in items"
        :key="item.id"
        v-on:click="(_action) => onItemClick(item, index, _action)"
        :class="[
          'c-base-breadcrumb__item',
          {
            'c-base-breadcrumb__item--active':
              index < currentItemIndex || !0 === item.forceActive,
            'c-base-breadcrumb__item--disabled': item.disabled,
            'c-base-breadcrumb__item--current': index === currentItemIndex,
            'c-base-breadcrumb__item--inactive':
              index > currentItemIndex && !0 !== item.forceActive,
            'c-base-breadcrumb__item--unclickable': !1 === item.clickable,
          },
        ]"
      >
        <span class="c-base-breadcrumb__dot"></span>
        <span class="c-base-breadcrumb__label">{{ item.label }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    items: {
      type: Array,
      required: !0,
    },
    title: {
      type: String,
      required: !0,
    },
  },
  emits: ["click"],
  computed: {
    currentItemIndex() {
      let e = 0;
      return (
        this.items.map((t, i) => {
          t.current && (e = i);
        }),
        e
      );
    },
    progressWidth() {
      let e = 0,
        t = this.currentItemIndex + 1;
      this.items.map((t, i) => {
        !0 === t.forceActive && (e = i + 1);
      }),
        t > e && (e = t);
      let i = e / this.items.length;
      return (
        e < this.items.length && (i -= 1 / (2 * this.items.length)),
        100 * (i + 0.025) + "%"
      );
    },
  },
  methods: {
    onItemClick(e, t, i) {
      if (!e.disabled && !1 !== e.clickable) {
        const a = t < this.currentItemIndex || !0 === e.forceActive;
        this.$emit("click", e.id, a, i);
      }
    },
  },
};
</script>
<style lang="scss">
.c-base-breadcrumb {
  position: relative;
  max-width: 600px;
  text-align: center;
  .c-base-breadcrumb__header {
    color: #1c293b;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 18px;
    .c-base-breadcrumb__title {
      margin-bottom: 0;
      font-weight: 700;
      font-size: 20px;
    }
  }
  .c-base-breadcrumb__line {
    position: absolute;
    bottom: 33px;
    height: 6px;
    border-radius: 3px;
    background-color: #c8e6fb;
    -webkit-transition: background-color 0.1s linear;
    transition: background-color 0.1s linear;
  }
  .c-base-breadcrumb__line--active {
    width: 0;
    background-color: #0093ee;
  }
  .c-base-breadcrumb__line--full {
    width: 100%;
  }
  .c-base-breadcrumb__items {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    .c-base-breadcrumb__item {
      position: relative;
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
      text-align: center;
      .c-base-breadcrumb__dot {
        display: block;
        -webkit-transition: all 0.2s linear;
        transition: all 0.2s linear;
        margin: 0 auto;
        width: 14px;
        height: 14px;
        border: 3px solid #c8e6fb;
        border-radius: 100%;
        background-color: #c8e6fb;
        -webkit-transform: translateY(-2px);
        transform: translateY(-2px);
      }
      .c-base-breadcrumb__label {
        display: block;
        -webkit-transition: all 0.2s linear;
        transition: all 0.2s linear;
        margin-top: 6px;
        padding: 0 30px;
        color: #75869c;
        white-space: nowrap;
        font-size: 14px;
      }
    }
    .c-base-breadcrumb__item--active {
      .c-base-breadcrumb__label {
        color: #1c293b;
        font-weight: 700;
      }
      cursor: pointer;
      .c-base-breadcrumb__dot {
        border-color: #0093ee;
        background-color: #0093ee;
        -webkit-transition-timing-function: linear;
        transition-timing-function: linear;
        -webkit-transition-duration: 0.1s;
        transition-duration: 0.1s;
        -webkit-transition-property: background-color, border-color;
        transition-property: background-color, border-color;
      }
      &:hover {
        &:not(.c-base-breadcrumb__item--disabled) {
          &:not(.c-base-breadcrumb__item--unclickable) {
            .c-base-breadcrumb__dot {
              border-color: #0074bb;
              background-color: #0074bb;
            }
          }
        }
      }
    }
    .c-base-breadcrumb__item--current {
      .c-base-breadcrumb__label {
        color: #1c293b;
        font-weight: 700;
      }
      .c-base-breadcrumb__dot {
        border-color: #0093ee;
        background-color: #fff;
      }
    }
    .c-base-breadcrumb__item--active.c-base-breadcrumb__item--disabled {
      .c-base-breadcrumb__label {
        color: rgba(28, 41, 59, 0.4);
      }
    }
    .c-base-breadcrumb__item--current.c-base-breadcrumb__item--disabled {
      .c-base-breadcrumb__label {
        color: rgba(28, 41, 59, 0.4);
      }
    }
    .c-base-breadcrumb__item--unclickable {
      cursor: default;
      &:hover {
        .c-base-breadcrumb__dot {
          border-color: #c8e6fb;
          background-color: #c8e6fb;
        }
      }
    }
    .c-base-breadcrumb__item--disabled {
      cursor: not-allowed;
    }
    .c-base-breadcrumb__item--inactive {
      cursor: not-allowed;
    }
  }
}
@media (max-width: 56.25em) {
  .c-base-breadcrumb {
    .c-base-breadcrumb__items {
      .c-base-breadcrumb__item {
        .c-base-breadcrumb__label {
          padding: 0 20px;
        }
      }
    }
  }
}
</style>
