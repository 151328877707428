<template>
  <div class="c-base-information">
    <slot></slot>
  </div>
</template>
<style lang="scss">
.c-base-information {
  padding: 13px 16px;
  color: #000;
  border: 1px solid #e7e1b6;
  border-radius: 2px;
  background-color: #fefce7;
  -webkit-box-shadow: 1 1px 3px 0 rgba(0, 0, 0, 0.03);
  box-shadow: 1 1px 3px 0 rgba(0, 0, 0, 0.03);
  font-size: 14px;
  line-height: 16px;
}
</style>
