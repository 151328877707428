<template>
  <span
    :class="[
      'c-base-indicator',
      'c-base-indicator--' + status,
      'c-base-indicator--' + size,
    ]"
  >
    <BaseIcon
      class="c-base-indicator__icon"
      :name="iconName"
      :size="iconSize"
    ></BaseIcon>
  </span>
</template>
<script>
export default {
  props: {
    status: {
      type: String,
      default: "neutral",
      validator(e) {
        return -1 !== ["bad", "neutral", "good"].indexOf(e);
      },
    },
    size: {
      type: String,
      default: "normal",
      validator(e) {
        return -1 !== ["small", "normal"].indexOf(e);
      },
    },
  },
  computed: {
    iconName() {
      switch (this.status) {
        case "bad":
          return "arrow_drop_down";
        case "good":
          return "arrow_drop_up";
        default:
          return "remove";
      }
    },
    iconSize() {
      let e = 0;
      switch (this.size) {
        case "small":
          e = 20;
          break;
        case "normal":
          e = 24;
          break;
      }
      return "remove" === this.iconName && (e = Math.round(0.7 * e)), e + "px";
    },
  },
};
</script>
<style lang="scss">
.c-base-indicator {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 4px;
  color: #fff;
  .c-base-indicator__icon {
    fill: #fff;
  }
}
.c-base-indicator--bad {
  background-color: #e0102b;
}
.c-base-indicator--neutral {
  background-color: #75869c;
}
.c-base-indicator--good {
  background-color: #40b630;
}
.c-base-indicator--small {
  width: 24px;
  height: 24px;
}
.c-base-indicator--normal {
  width: 26px;
  height: 26px;
}
</style>
