// import routerGraph from "@/classes/talqui/routesGraph";
import _routerResolver from "@/classes/talqui/_routerResolver";

class _routerBuilder {
  build({
    path: e,
    name: t,
    component: i,
    resolvers: o,
    hook: n,
    children: r,
    successFn: s,
    errorFn: l,
  }) {
    let c = {
      path: e,
      component: i,
      pathToRegexpOptions: {
        strict: !0,
      },
    };
    return (
      t && (c.name = t),
      r && (c.children = r),
      o
        ? (c.beforeEnter = (e, i, n) => {
            _routerResolver
              .resolve(
                {
                  to: e,
                  name: t,
                  next: n,
                  successFn: s,
                },
                o,
              )
              .then((t) => {
                if (s)
                  return s({
                    resolved: t,
                    to: e,
                    next: n,
                  });
              })
              .catch((e) => {
                if (l)
                  return l({
                    error: e,
                  });
              });
          })
        : n &&
          (c.beforeEnter = (e, t, i) =>
            n({
              to: e,
              from: t,
              next: i,
            })),
      c
    );
  }
}

export default new _routerBuilder();
