<template>
  <iframe
    :src="src"
    :allow="allow"
    ref="frame"
    sandbox="allow-same-origin allow-scripts allow-forms allow-popups allow-downloads allow-top-navigation-by-user-activation"
  ></iframe>
</template>
<script>
export default {
  props: {
    src: {
      type: String,
      required: !0,
    },
    allow: {
      type: String,
      default: "",
    },
  },
  emits: ["frameLoaded", "message"],
  mounted() {
    let e = this.$refs.frame;
    e.addEventListener("load", this.onFrameLoaded),
      window.addEventListener("message", this.onIframeEvent);
  },
  beforeUnmount() {
    let e = this.$refs.frame;
    e.removeEventListener("load", this.onFrameLoaded),
      window.removeEventListener("message", this.onIframeEvent);
  },
  methods: {
    onFrameLoaded() {
      this.$emit("frameLoaded");
    },
    onIframeEvent(e) {
      this.$emit("message", e.data);
    },
    onData(e) {
      let t = this.$refs.frame;
      t && t.contentWindow.postMessage(e, "*");
    },
  },
};
</script>
