import a from "@/classes/date.js";
class _crispDate {
  init(_vue) {
    let t = this;
    _vue.directive("crispDate", {
      beforeMount(e, i, a) {
        return t.bind(e, i, a);
      },
      updated(e, i, a) {
        return t.update(e, i, a);
      },
      beforeUnmount(e, i, a) {
        return t.unbind(e, i, a);
      },
    });
  }
  updateText(e, t, i) {
    let o = t.value.format;
    e.innerHTML = a[o || "timeAgo"](parseInt(t.value.date, 10));
    this.scheduleMaintain(e, t, i);
  }
  acquireMaintain(e, t) {
    const i = 60;
    const a = 1e3;
    let o = t.value.maintain || !1;
    (e.__crispDateMaintainInterval = -1),
      o &&
        (isNaN(t.crispDateMaintain)
          ? (e.__crispDateMaintainInterval = i * a)
          : (e.__crispDateMaintainInterval = parseInt(o, 10) * a));
  }
  scheduleMaintain(e, t, i) {
    e.__crispDateMaintainInterval &&
      -1 !== e.__crispDateMaintainInterval &&
      (this.unscheduleMaintain(e, t),
      (e.__crispDateMaintainTimer = setTimeout(() => {
        this.updateText(e, t, i);
      }, e.__crispDateMaintainInterval)));
  }
  unscheduleMaintain(e) {
    null !== e.__crispDateMaintainTimer &&
      (clearTimeout(e.__crispDateMaintainTimer),
      (e.__crispDateMaintainTimer = null));
  }
  update(e, t, i) {
    this.updateText(e, t, i);
  }
  bind(e, t, i) {
    this.acquireMaintain(e, t);
    this.updateText(e, t, i);
  }
  unbind(e, t, i) {
    this.unscheduleMaintain(e, t, i);
  }
}
export default new _crispDate();
