<template>
  <div
    :class="[
      'c-conversation-tabs',
      'c-conversation-tabs--' + size,
      'c-conversation-tabs--' + theme,
      {
        'c-conversation-tabs--center': center,
        'c-conversation-tabs--padded': padded,
        'c-conversation-tabs--disabled': disabled,
      },
    ]"
  >
    <div
      v-for="item in tabs"
      :key="item.id"
      v-on:click="(e) => onTabClick(item.id)"
      v-on:mouseenter="(e) => onMouseEnter(item.id)"
      v-on:mouseleave="(e) => onMouseLeave(item.id)"
      :class="[
        'c-conversation-tabs__item',
        'c-conversation-tabs__item--' + item.id,
        'u-semibold',
        {
          'c-conversation-tabs__item--active':
            active === item.id || isTabItemActive(item),
          'c-conversation-tabs__item--with-items': item.groups,
        },
      ]"
    >
      <BaseUpgrade
        v-if="item.upgrade"
        :align="item.upgrade.align"
        :label="this.$t(item.upgrade.label)"
        :plan-name="item.upgrade.planName"
        :plugin-name="item.upgrade.pluginName"
        :plugin-urn="item.upgrade.pluginUrn"
        :title="this.$t(item.upgrade.title)"
        :width="item.upgrade.width"
      >
        <div
          :class="[
            'c-conversation-tabs__header',
            'c-conversation-tabs__header--inactive',
          ]"
        >
          {{ item.name }}
        </div>
      </BaseUpgrade>
      <div v-if="!item.upgrade" class="c-conversation-tabs__header">
        <BaseIcon
          v-if="item.icon"
          class="c-conversation-tabs__icon"
          :width="item.icon.width"
          :height="item.icon.height"
          :name="item.icon.name"
          :size="item.icon.size"
        ></BaseIcon>
        <span class="c-conversation-tabs__name">
          {{ item.name }}
        </span>
        <BaseIcon
          v-if="item.groups"
          class="c-conversation-tabs__icon"
          :name="showItems ? 'arrow_drop_up' : 'arrow_drop_down'"
          size="20px"
        ></BaseIcon>
        <BaseNotification
          v-if="item.notification"
          class="c-conversation-tabs__notification u-bold"
          size="none"
        >
          {{ item.notification > 99 ? "99+" : item.notification }}
        </BaseNotification>
      </div>
      <div
        class="c-conversation-tabs__items"
        v-show="item.groups && showItems && !item.upgrade"
      >
        <div class="c-conversation-tabs__items-wrapper">
          <div
            v-for="group in item.groups"
            :key="group.id"
            class="c-conversation-tabs__group"
          >
            <div
              v-for="groupItem in group.items"
              :key="groupItem.id"
              v-on:click.stop="(i) => onTabItemClick(item.id, groupItem.id)"
              :class="[
                'c-conversation-tabs__item',
                'u-ellipsis',
                'u-semibold',
                {
                  'c-conversation-tabs__item--active': active === groupItem.id,
                  'c-conversation-tabs__item--action': groupItem.isAction,
                },
              ]"
            >
              <div class="c-conversation-tabs__label u-ellipsis">
                {{ groupItem.label }}
              </div>
              <div
                v-if="groupItem.actions"
                class="c-conversation-tabs__actions"
              >
                <span
                  v-for="groupItemAction in groupItem.actions"
                  :key="groupItemAction.id"
                  class="c-conversation-tabs__action"
                  v-on:click.stop="
                    (a) =>
                      onTabItemActionClick(
                        item.id,
                        groupItem.id,
                        groupItemAction.id,
                      )
                  "
                >
                  <BaseIcon
                    class="c-conversation-tabs__icon"
                    :name="groupItemAction.icon"
                    size="16px"
                  ></BaseIcon>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    active: {
      type: String,
      required: !0,
    },
    center: {
      type: Boolean,
      default: !1,
    },
    padded: {
      type: Boolean,
      default: !1,
    },
    size: {
      type: String,
      default: "normal",
    },
    tabs: {
      type: Array,
      required: !0,
    },
    disabled: {
      type: Boolean,
      default: !1,
    },
    theme: {
      type: String,
      default: "blue",
      validator(e) {
        return -1 !== ["black", "blue"].indexOf(e);
      },
    },
    unreadCount: {
      type: Number,
      default: 0,
    },
  },
  emits: ["clickTab", "clickTabItem", "clickTabItemAction", "showItems"],
  data() {
    return {
      showItems: !1,
    };
  },
  computed: {
    activeTabItem() {
      return !1;
    },
  },
  methods: {
    isTabItemActive(e) {
      if (e.groups)
        for (let t = 0; t < e.groups.length; t++)
          if (e.groups[t].items) {
            let i = e.groups[t].items.find((e) => e.id === this.active);
            if (i) return !0;
          }
      return !1;
    },
    onTabClick(e) {
      let t = this.tabs.find((t) => t.id === e);
      t.groups || this.$emit("clickTab", e);
    },
    onTabItemClick(e, t) {
      this.showItems = !1;
      this.$emit("clickTabItem", e, t);
    },
    onTabItemActionClick(e, t, i) {
      this.showItems = !1;
      this.$emit("clickTabItemAction", e, t, i);
    },
    onMouseEnter(e) {
      let t = this.tabs.find((t) => t.id === e);
      if (t.groups) {
        this.showItems = !0;
        this.$emit("showItems", !0);
      }
    },
    onMouseLeave(e) {
      let t = this.tabs.find((t) => t.id === e);
      if (t.groups) {
        this.showItems = !1;
        this.$emit("showItems", !1);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.c-conversation-tabs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: #fff;
  .c-conversation-tabs__item {
    position: relative;
    font-weight: 500;
    .c-conversation-tabs__header {
      position: relative;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      color: #1c293b;
      -webkit-transition: all 0.1s linear;
      transition: all 0.1s linear;
      cursor: pointer;
      .c-conversation-tabs__icon {
        margin-right: 6px;
        fill: #25374f;
        -webkit-transition: fill 0.1s linear;
        transition: fill 0.1s linear;
      }
      .c-conversation-tabs__notification {
        margin-right: -7px;
        margin-left: 7px;
        padding: 2px 5px 0;
        height: 20px;
        border-radius: 2px;
        letter-spacing: 0.5px;
        line-height: 17px;
      }
      &:after {
        position: absolute;
        right: 0;
        bottom: -1px;
        left: 0;
        height: 0;
        content: "";
        -webkit-transition: height 0.1s linear;
        transition: height 0.1s linear;
      }
    }
    .c-conversation-tabs__items {
      position: absolute;
      top: 0;
      left: -10px;
      padding-top: 54px;
      padding-bottom: 6px;
      border: 1px solid #e3e8ef;
      border-top: none;
      background-color: #fff;
      text-align: left;
      font-size: 14px;
      .c-conversation-tabs__items-wrapper {
        max-height: 400px;
        width: 220px;
        overflow-x: hidden;
        overflow-y: auto;
      }
      .c-conversation-tabs__group {
        &:not(:last-of-type) {
          margin-bottom: 6px;
          padding-bottom: 6px;
          border-bottom: 1px solid #eff3f6;
        }
      }
      .c-conversation-tabs__item {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 5px 10px 5px 15px;
        border-left: 3px solid #fff;
        background-color: #fff;
        font-size: 13.25px;
        .c-conversation-tabs__label {
          -webkit-box-flex: 1;
          -ms-flex: 1;
          flex: 1;
        }
        .c-conversation-tabs__actions {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-flex: 0;
          -ms-flex: 0 0 auto;
          flex: 0 0 auto;
          -webkit-box-pack: end;
          -ms-flex-pack: end;
          justify-content: flex-end;
          margin-top: -1px;
          padding-left: 10px;
          .c-conversation-tabs__icon {
            display: none;
          }
          .c-conversation-tabs__action {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
            flex: 0 0 auto;
            margin-left: 4px;
            width: 16px;
            height: 16px;
            &:first-of-type {
              margin-left: 0;
            }
          }
        }
        &:hover {
          border-left-color: #0093ee;
          background-color: #0093ee;
          color: #fff;
          cursor: pointer;
          .c-conversation-tabs__label {
            color: #fff;
          }
          .c-conversation-tabs__actions {
            .c-conversation-tabs__action {
              opacity: 0.6;
              .c-conversation-tabs__icon {
                display: inline-block;
                fill: #fff;
              }
              &:hover {
                opacity: 1;
              }
            }
          }
        }
        &:active {
          border-left-color: #008adf;
          background-color: #008adf;
          color: #fff;
          -webkit-transition: background-color 0.1s linear;
          transition: background-color 0.1s linear;
        }
      }
      .c-conversation-tabs__item--action {
        margin-top: 6px;
        color: #75869c;
      }
      .c-conversation-tabs__item--active {
        border-left-color: #0093ee;
        background-color: #eff3f6;
        .c-conversation-tabs__label {
          font-weight: 700;
        }
      }
    }
  }
  .c-conversation-tabs__item--active {
    .c-conversation-tabs__header {
      &:after {
        height: 3px;
      }
    }
  }
  .c-conversation-tabs__item--filters {
    &:hover {
      .c-conversation-tabs__header {
        &:after {
          display: none;
        }
      }
    }
  }
  .c-conversation-tabs__item--with-items {
    .c-conversation-tabs__header {
      z-index: 5;
    }
    .c-conversation-tabs__items {
      z-index: 4;
    }
  }
}
.c-conversation-tabs--small {
  .c-conversation-tabs__item {
    .c-conversation-tabs__header {
      margin-right: 8px;
      padding: 8px 6px;
      font-size: 12.75px;
      &:last-of-type {
        margin-left: 0;
      }
    }
    &:last-of-type {
      .c-conversation-tabs__header {
        margin-right: 0;
      }
    }
  }
}
.c-conversation-tabs--normal {
  .c-conversation-tabs__item {
    .c-conversation-tabs__header {
      margin-right: 10px;
      padding: 13px 8px 14px;
      font-size: 14px;
      &:last-of-type {
        margin-left: 0;
      }
    }
    &:last-of-type {
      .c-conversation-tabs__header {
        margin-right: 0;
      }
    }
  }
}
.c-conversation-tabs--black {
  .c-conversation-tabs__item {
    .c-conversation-tabs__header {
      &:after {
        background-color: #2c405a;
      }
    }
  }
}
.c-conversation-tabs--blue {
  .c-conversation-tabs__item {
    .c-conversation-tabs__header {
      &:hover {
        color: #0079c4;
        .c-conversation-tabs__icon {
          fill: #0079c4;
        }
      }
      &:after {
        background-color: #0079c4;
      }
    }
  }
  .c-conversation-tabs__item--active {
    .c-conversation-tabs__header {
      color: #0079c4;
      .c-conversation-tabs__icon {
        fill: #0079c4;
      }
    }
  }
}
.c-conversation-tabs--center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.c-conversation-tabs--padded {
  padding: 0 18px;
}
.c-conversation-tabs--disabled {
  pointer-events: none;
  .c-conversation-tabs__item {
    &:not(.c-conversation-tabs__item--active) {
      opacity: 0.3;
    }
  }
}
</style>
