<template>
  <div
    v-on:click="(...e) => onClick(...e)"
    :class="[
      'c-base-upgrade',
      {
        'c-base-upgrade--not-ready': !isReady,
        'c-base-upgrade--active': isActive,
        'c-base-upgrade--blur': blur && isActive,
        'c-base-upgrade--block': block,
        'u-has-tooltip': hasTooltip && isActive,
      },
    ]"
  >
    <div class="c-base-upgrade__slot">
      <slot></slot>
    </div>

    <div v-if="isReady && isActive">
      <BaseTooltip
        class="c-base-upgrade__tooltip"
        :direction="direction"
        :align="align"
      >
        <div
          class="c-base-upgrade__wrapper"
          :style="{
            width: width,
          }"
        >
          <div class="c-base-upgrade__text">
            <div v-if="title" class="c-base-upgrade__title u-semibold">
              {{ title }}
            </div>
            <div v-if="label" class="c-base-upgrade__label u-regular">
              {{ label }}
            </div>
          </div>

          <BaseButton
            class="c-base-upgrade__button"
            v-on:click="onUpgrade"
            color="white"
            size="mini"
          >
            {{ buttonLabel }}
          </BaseButton>
        </div>
      </BaseTooltip>
    </div>
  </div>
</template>
<script>
import mixingPlugins from "@/mixins/plugins.js";
import AuxiliarComponent from "../AuxiliarComponent.vue";

export default {
  mixins: [mixingPlugins],
  props: {
    active: {
      type: Boolean,
      default: !1,
    },
    block: {
      type: Boolean,
      default: !1,
    },
    align: {
      type: String,
      default: "center",
    },
    blur: {
      type: Boolean,
      default: !1,
    },
    direction: {
      type: String,
      default: "top",
    },
    hasTooltip: {
      type: Boolean,
      default: !0,
    },
    label: {
      type: String,
      required: !0,
    },
    planName: {
      type: String,
      default: null,
    },
    pluginName: {
      type: String,
      default: null,
    },
    pluginUrn: {
      type: String,
      default: null,
    },
    showPlan: {
      type: Boolean,
      default: !0,
    },
    title: {
      type: String,
      required: !0,
    },
    useActive: {
      type: Boolean,
      default: !1,
    },
    width: {
      type: String,
      default: null,
    },
  },
  computed: {
    buttonLabel() {
      return this.showPlan
        ? this.$t("BASES.UPGRADE.GO_FROM_TOUR", {
            plan_name: this.planName[0].toUpperCase() + this.planName.substr(1),
          })
        : this.$t("BASES.UPGRADE.LABEL");
    },
    hasPlugin() {
      // console.log(this.$pluginExists);
      return this.$pluginExists(this.pluginUrn);
      // return true;
    },
    isActive() {
      return !0 === this.active || (!this.useActive && !this.hasPlugin);
    },
    isReady() {
      return this.$pluginsLoaded;
    },
  },
  methods: {
    onClick(e) {
      this.hasPlugin || e.stopPropagation();
    },
    onUpgrade() {
      AuxiliarComponent.show({
        plan: this.planName,
        pluginName: this.pluginName,
        pluginUrn: this.pluginUrn,
      });
    },
  },
};
</script>
<style lang="scss">
.c-base-upgrade {
  .c-base-upgrade__wrapper {
    display: inline-block;
    margin: 0 auto;
    padding: 4px 4px;
    padding-bottom: 0;
    .c-base-upgrade__text {
      float: left;
      width: 57%;
      text-align: right;
      line-height: 17px;
      display: block;
    }
    .c-base-upgrade__label {
      display: block;
    }
    .c-base-upgrade__button {
      float: right;
      overflow: hidden;
      margin-top: 4px;
      margin-left: 4%;
      width: 39%;
      color: #2c405a;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 11.5px;
    }
  }
  .c-base-upgrade__tooltip.c-base-tooltip[data-align="left"] {
    margin-left: -8px;
    &:after {
      margin-left: 24px;
    }
  }
  .c-base-upgrade__tooltip.c-base-tooltip[data-align="right"] {
    margin-right: -8px;
    &:after {
      margin-right: 24px;
    }
  }
  .c-base-upgrade__tooltip.c-base-tooltip[data-direction="top"] {
    margin-bottom: 1px;
  }
  .c-base-upgrade__tooltip.c-base-tooltip[data-direction="bottom"] {
    margin-top: 1px;
  }
}
.c-base-upgrade--block {
  .c-base-upgrade__slot {
    width: 100%;
    display: block;
  }
}
.c-base-upgrade--not-ready {
  pointer-events: none;
}
.c-base-upgrade--active {
  cursor: not-allowed;
  .c-base-upgrade__slot {
    pointer-events: none;
    .c-base-tooltip {
      display: none;
    }
  }
}
.c-base-upgrade--blur {
  .c-base-upgrade__slot {
    -webkit-filter: blur(3px);
    filter: blur(3px);
  }
}
</style>
