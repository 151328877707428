import tenant from "./tenant";
import socket from "@/classes/talqui/socket";

class Contact {
  constructor() {
    this.events();
  }

  contacts(tenantID) {
    return tenant.tenantOne(tenantID).one("contacts");
  }

  contactOne(tenantID, contactID) {
    return tenant.tenantOne(tenantID).one("contacts", contactID);
  }

  contactsImport(tenantID) {
    return this.contacts(tenantID).one("import");
  }

  events() {
    socket.on("contact:import:progress", (e) => {
      this.onPeopleImportProgress &&
        this.onPeopleImportProgress(
          e.tenantID,
          e.importID,
          e.importError,
          e.contact,
        );
    });
  }

  bindPeopleImportHandlers(e, t) {
    this.onPeopleImportProgress = e;
    this.onPeopleImportDone = t;
  }

  unbindPeopleImportHandlers() {
    this.onPeopleImportProgress = void 0;
    this.onPeopleImportDone = void 0;
  }
}
export default new Contact();
