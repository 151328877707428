<template>
  <div
    :class="[
      'c-field-link',
      'c-field-link--' + alignment,
      'c-field-link--' + direction,
      'c-field-link--' + color,
      {
        'c-field-link--disabled': disabled,
        ['c-field-link--' + direction + '-' + directionAlign]: directionAlign,
      },
    ]"
  >
    <FieldLabel
      v-if="label"
      class="c-field-link__label"
      :direction="direction"
      :for-field="uuid"
    >
      {{ label }}
    </FieldLabel>
    <div class="c-field-link__container">
      <div
        v-if="(deployed && canDeploy) || !canDeploy"
        class="c-field-link__slot"
      >
        <slot></slot>
      </div>
      <div v-if="!(deployed && canDeploy)" class="c-field-link__actions">
        <BaseButton
          v-if="'button' === type"
          class="c-field-link__button"
          v-on:click="onClick"
          :disabled="disabled"
          :color="color"
          :left-icon="icon"
          size="small"
        >
          {{ action }}
        </BaseButton>
        <span
          v-else-if="'link' === type"
          class="c-field-link__link"
          v-on:click="(...e) => onClick(...e)"
        >
          {{ action }}
        </span>
      </div>
      <BaseHeading
        v-if="disabled && disabledLabel"
        class="u-mt5"
        color="grey"
        type="small"
      >
        {{ disabledLabel }}
      </BaseHeading>
    </div>
  </div>
</template>
<script>
import * as $uuid from "uuid";
export default {
  props: {
    action: {
      type: String,
      required: !0,
    },
    alignment: {
      type: String,
      default: "left",
    },
    color: {
      type: String,
      default: "blue",
      validator(e) {
        return -1 !== ["blue", "red", "green", "orange", "black"].indexOf(e);
      },
    },
    disabled: {
      type: Boolean,
      default: !1,
    },
    disabledLabel: {
      type: String,
      default: "",
    },
    direction: {
      type: String,
      default: "vertical",
      validator(e) {
        return -1 !== ["horizontal", "vertical"].indexOf(e);
      },
    },
    directionAlign: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: "",
    },
    canDeploy: {
      type: Boolean,
      default: !1,
    },
    opened: {
      type: Boolean,
      default: !1,
    },
    icon: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: "link",
      validator(e) {
        return -1 !== ["button", "link"].indexOf(e);
      },
    },
  },
  emits: ["click"],
  data() {
    return {
      deployed: !1,
      uuid: "",
    };
  },
  watch: {
    opened: {
      handler() {
        this.opened && this.canDeploy && (this.deployed = !0);
      },
    },
  },
  created() {
    (this.uuid = $uuid.v4()),
      this.opened && this.canDeploy && (this.deployed = !0);
  },
  methods: {
    onClick(e) {
      this.canDeploy && (this.deployed = !0),
        this.$emit("click", this.name || this.uuid, e);
    },
  },
};
</script>
<style lang="scss">
.c-field-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-align: left;
  .c-field-link__label {
    cursor: default;
  }
  .c-field-link__container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    .c-field-link__actions {
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
      .c-field-link__link {
        text-decoration: none;
        font-weight: 700;
        font-size: 14px;
        -webkit-transition: color 0.2s linear;
        transition: color 0.2s linear;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .c-field-link__slot {
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
    }
  }
}
.c-field-link--left {
  .c-field-link__container {
    text-align: left;
  }
}
.c-field-link--right {
  .c-field-link__container {
    text-align: right;
  }
}
.c-field-link--blue {
  .c-field-link__container {
    .c-field-link__actions {
      .c-field-link__link {
        color: #0093ee;
      }
    }
  }
}
.c-field-link--red {
  .c-field-link__container {
    .c-field-link__actions {
      .c-field-link__link {
        color: #e0102b;
      }
    }
  }
}
.c-field-link--green {
  .c-field-link__container {
    .c-field-link__actions {
      .c-field-link__link {
        color: #40b630;
      }
    }
  }
}
.c-field-link--orange {
  .c-field-link__container {
    .c-field-link__actions {
      .c-field-link__link {
        color: #fd7b1f;
      }
    }
  }
}
.c-field-link--horizontal {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  .c-field-link__label {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 40px;
    min-width: 140px;
    line-height: 34px;
  }
  .c-field-link__container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    .c-field-link__slot {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: end;
      -ms-flex-pack: end;
      justify-content: flex-end;
    }
  }
}
.c-field-link--vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.c-field-link--horizontal-start {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.c-field-link--disabled {
  cursor: not-allowed;
  .c-field-link__label {
    cursor: not-allowed;
    opacity: 0.35;
  }
}
</style>
