import a from "js-cookie";
import CryptoJS from "crypto-js";
import n from "../classes/filters.js";
import r from "../classes/http.js";
import s from "../classes/auth.js";
import l from "../store/alerts.js";
import c from "@/enviroment.js";

class _billing {
  constructor() {
    (this.__pending = {
      billingCardAdded: null,
      billingLinkRedirect: {},
    }),
      (this.__timeouts = {
        billingCardAdded: null,
      }),
      this.events();
  }
  account() {
    return r.default.one("user", "account");
  }
  availability() {
    return r.default.one("user", "availability");
  }
  billing() {
    return this.account().one("billing");
  }
  schedule() {
    return this.account().one("schedule");
  }
  websites() {
    return this.account().one("websites");
  }
  addBillingCard(e) {
    this.__pending.billingCardAdded = null;
    let t = {
      name_on_card: e.name_on_card,
      address: e.address,
      country: e.country,
      card_number: n.default.cardNumber(e.card_number),
      security_code: e.security_code,
      expiration_date: {
        month: "" + e.month,
        year: "" + e.year,
      },
    };
    return (
      ["tax_identifier", "invoice_email", "information"].forEach((i) => {
        e[i] && (t[i] = e[i]);
      }),
      this.billing()
        .post(t)
        .then(
          (e) => (
            (this.__pending.billingCardAdded = e.card_id),
            null === this.__timeouts.billingCardAdded &&
              (clearTimeout(this.__timeouts.billingCardAdded),
              (this.__timeouts.billingCardAdded = null)),
            (this.__timeouts.billingCardAdded = setTimeout(() => {
              (this.__timeouts.billingCardAdded = null),
                this.__handleBillingCardAdded(
                  {
                    card_id: e.card_id,
                  },
                  !1,
                );
            }, c.default.CRISP_CARD_FINALIZE_DELAY)),
            Promise.resolve(e)
          ),
        )
    );
  }
  billingLinkGenerate(e, t) {
    (this.__pending.billingLinkRedirect.service = e),
      t && (this.__pending.billingLinkRedirect.card_id = t);
    let i = {
      service: e,
    };
    return (
      t && (i.card_id = t),
      this.billing()
        .one("link")
        .post(i)
        .catch(
          (e) => ((this.__pending.billingLinkRedirect = {}), Promise.reject(e)),
        )
    );
  }
  signAccountData(e = []) {
    const t = c.default.LIVE_INTERNAL_PLATFORM_NAME;
    const i = c.default.LIVE_INTERNAL_PLATFORM_SIGNATURE_KEY;
    let a = CryptoJS.HmacSHA1(
      `[${e.join("|")}]`,
      i
        .split("/")
        .map((e) => String.fromCharCode(parseInt(e, 36)))
        .join(""),
    );
    return `${t}:${a}`;
  }
  notification() {
    return this.account().one("notification");
  }
  profile() {
    return this.account().one("profile");
  }
  accountRemove(e) {
    return this.account().delete({
      verify: e,
    });
  }
  getSharedProfile() {
    try {
      return JSON.parse(a.default.get("shared_profile") || null) || {};
    } catch (e) {
      return {};
    }
  }
  setSharedProfile(e) {
    if (e) {
      let t = {};
      (t.user_id = e.id),
        (t.email = e.email),
        (t.first_name = e.first_name),
        (t.last_name = e.last_name),
        (t.avatar = e.avatar),
        a.default.set("shared_profile", JSON.stringify(t), {
          domain: c.default.CRISP_HOST_WEB,
          sameSite: "strict",
        });
    }
  }
  token() {
    return this.account().one("token");
  }
  alerts() {
    return r.default.one("user", "alerts");
  }
  getAlertsCount() {
    return this.alerts().one("count").get();
  }
  deleteAllAlerts() {
    return this.alerts().one("feed").delete();
  }
  getAlertsFeedList(e = 1) {
    return this.alerts().one("feed/list", e).get();
  }
  deleteAlertsFeed(e) {
    return this.alerts().one("feed/entry", e).delete();
  }
  getAlertsUpdatesList(e = 1) {
    return this.alerts().one("updates/list", e).get();
  }
  getLatestAlertsUpdate() {
    return this.alerts().one("updates/latest").get();
  }
  getAlertsUpdateContent(e) {
    return this.alerts().one("updates/entry", e).one("content").get();
  }
  markAlertsUpdatesRead() {
    return this.alerts().one("updates/read").patch();
  }
  __handleBillingCardAdded(e, t = !1) {
    this.__pending.billingCardAdded === e.card_id &&
      (null !== this.__timeouts.billingCardAdded &&
        (clearTimeout(this.__timeouts.billingCardAdded),
        (this.__timeouts.billingCardAdded = null)),
      this.__onCardAdded && this.__onCardAdded(e, t));
  }
  events() {
    s.on("alert:feed:update_count", (e) => {
      l.default.commit("alerts/setCountFeed", {
        value: e.feed_count,
      });
    });
    s.on("billing:link:redirect", (e) => {
      let t = this.__pending.billingLinkRedirect;
      t.service !== e.service ||
        (t.card_id && t.card_id !== e.card_id) ||
        ((this.__pending.billingLinkRedirect = {}),
        this.__onLinkRedirect && this.__onLinkRedirect(e));
    });
    s.on("billing:card:added", (e) => {
      this.__handleBillingCardAdded(
        {
          card_id: e.card_id,
        },
        !0,
      );
    });
  }
}

export default new _billing();
