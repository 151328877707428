<template>
  <div
    class="c-base-video"
    :style="{
      backgroundImage: 'url(' + previewUrl + ')',
      height: height,
      width: width,
    }"
    v-on:click="(...e) => onPlayVideo(...e)"
  >
    <div class="c-base-video__overlay"></div>
    <div class="c-base-video__play"></div>
  </div>
</template>
<script>
const a = 600;
export default {
  props: {
    height: {
      type: String,
      default: "300px",
    },
    videoId: {
      type: String,
      required: !0,
    },
    width: {
      type: String,
      default: "400px",
    },
  },
  computed: {
    previewUrl() {
      let e = `https://img.youtube.com/vi/${this.videoId}/maxresdefault.jpg`;
      return (
        this.$CONFIG.CRISP_URL_IMAGE +
        "/process/resize/?url=" +
        encodeURIComponent(e) +
        `&width=${a}&height=${a}`
      );
    },
  },
  methods: {
    onPlayVideo() {
      window.playVideo("youtube", this.videoId);
    },
  },
};
</script>
<style lang="scss">
.c-base-video {
  background-color: #161616;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  cursor: pointer;
  position: relative;
  border-radius: 1px;
  overflow: hidden;
  &:hover {
    .c-base-video__play {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
  }
  &:active {
    .c-base-video__play {
      -webkit-transform: scale(0.975);
      transform: scale(0.975);
    }
  }
  .c-base-video__play {
    width: 52px;
    height: 52px;
    margin-top: -26px;
    margin-left: -26px;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    -webkit-transition: -webkit-transform 0.15s ease-in-out;
    transition: -webkit-transform 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out;
    transition:
      transform 0.15s ease-in-out,
      -webkit-transform 0.15s ease-in-out;
    background-image: url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjQwIiB3aWR0aD0iNDAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxIDEpIj48Y2lyY2xlIGN4PSIxOSIgY3k9IjE5IiBmaWxsPSIjMDAwIiBmaWxsLW9wYWNpdHk9Ii4xNDYiIHI9IjE5IiBzdHJva2U9IiNmZmYiIHN0cm9rZS13aWR0aD0iMiIvPjxwYXRoIGQ9Ik0yOCAxOWwtMTQgOVYxMHoiIGZpbGw9IiNmZmYiLz48L2c+PC9zdmc+");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50%;
    display: inline-block;
  }
  .c-base-video__overlay {
    background: rgba(0, 0, 0, 0.15);
    width: 100%;
    height: 100%;
    display: block;
    margin-bottom: 20px;
  }
}
</style>
