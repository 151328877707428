// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjU0MCIgd2lkdGg9IjU0MCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0wIDBoNTQwdjU0MEgweiIgZmlsbD0iI2VmZjNmNiIvPjxwYXRoIGQ9Ik0zMDAgMzQ3LjYyNXY1MS43NWMwIDIuMy0uODYzIDQuMzEzLTIuNTg3IDYuMDM3UzI5My42NzUgNDA4IDI5MS4zNzUgNDA4aC01MS43NWMtMi4zIDAtNC4zMTMtLjg2My02LjAzNy0yLjU4N1MyMzEgNDAxLjY3NSAyMzEgMzk5LjM3NXYtNTEuNzVjMC0yLjMuODYzLTQuMzEzIDIuNTg3LTYuMDM3czMuNzM4LTIuNTg4IDYuMDM4LTIuNTg4aDUxLjc1YzIuMyAwIDQuMzEzLjg2MyA2LjAzNyAyLjU4N3MyLjU4OCAzLjczOCAyLjU4OCA2LjAzOHptNjktMTMwLjAxOGMwIDcuNzk1LTEuMTE4IDE1LjA4NC0zLjM1NSAyMS44NjgtMi4yMzYgNi43ODUtNC43NjEgMTIuMzA2LTcuNTc1IDE2LjU2NC0yLjgxMyA0LjI1OC02Ljc4MSA4LjU1My0xMS45MDMgMTIuODgzLTUuMTIyIDQuMzMtOS4yNyA3LjQ3LTEyLjQ0NSA5LjQxOC0zLjE3NCAxLjk1LTcuNTc1IDQuNTExLTEzLjIwMiA3LjY4Ny01LjkxNiAzLjMyLTEwLjg1NyA4LjAxLTE0LjgyNSAxNC4wNzNzLTUuOTUyIDEwLjg5OS01Ljk1MiAxNC41MDdjMCAyLjQ1NC0uODY2IDQuOC0yLjU5NyA3LjAzNy0xLjczMSAyLjIzNy0zLjc1MSAzLjM1Ni02LjA2IDMuMzU2aC01MS45NDNjLTIuMTY0IDAtNC4wMDQtMS4zMzUtNS41MTktNC4wMDYtMS41MTUtMi42Ny0yLjI3Mi01LjM3Ni0yLjI3Mi04LjExOXYtOS43NDNjMC0xMS45ODEgNC42OS0yMy4yNzYgMTQuMDY4LTMzLjg4NXMxOS42OTUtMTguNDQgMzAuOTQ5LTIzLjQ5M2M4LjUxMy0zLjg5NyAxNC41NzMtNy45MzkgMTguMTgtMTIuMTI1IDMuNjA3LTQuMTg2IDUuNDEtOS42NyA1LjQxLTE2LjQ1NSAwLTYuMDYyLTMuMzU0LTExLjQwMy0xMC4wNjMtMTYuMDIycy0xNC40NjUtNi45MjktMjMuMjY2LTYuOTI5Yy05LjM3OSAwLTE3LjE3IDIuMDkzLTIzLjM3NSA2LjI4LTUuMDUgMy42MDgtMTIuNzY5IDExLjkwOC0yMy4xNTcgMjQuODk5LTEuODc2IDIuMzEtNC4xMTMgMy40NjQtNi43MSAzLjQ2NC0xLjczMSAwLTMuNTM1LS41NzctNS40MS0xLjczMmwtMzUuNDk1LTI3LjA2NWMtMS44NzUtMS40NDMtMi45OTMtMy4yNDgtMy4zNTQtNS40MTMtLjM2LTIuMTY1LjAzNi00LjE4NiAxLjE5LTYuMDYyQzE5My40MDUgMTUwLjE5OCAyMjYuODggMTMxIDI3MC43NDIgMTMxYzExLjU0MyAwIDIzLjE1OCAyLjIzNyAzNC44NDUgNi43MTIgMTEuNjg3IDQuNDc1IDIyLjIyIDEwLjQ2NSAzMS41OTggMTcuOTcxIDkuMzc5IDcuNTA2IDE3LjAyNiAxNi43MDggMjIuOTQxIDI3LjYwNlMzNjkgMjA1LjYyNiAzNjkgMjE3LjYwN3oiIGZpbGw9IiM0YjU4NmUiLz48L2c+PC9zdmc+", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("@/assets/images/common/browsers/android.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("@/assets/images/common/browsers/chrome.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("@/assets/images/common/browsers/chromium.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("@/assets/images/common/browsers/edge.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("@/assets/images/common/browsers/facebook.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("@/assets/images/common/browsers/firefox.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_7___ = new URL("@/assets/images/common/browsers/internet-explorer.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_8___ = new URL("@/assets/images/common/browsers/opera.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_9___ = new URL("@/assets/images/common/browsers/safari.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
var ___CSS_LOADER_URL_REPLACEMENT_9___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_9___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".c-base-browser {\n  border-radius: 100%;\n  background-color: #eff3f6;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: contain;\n  background-repeat: no-repeat;\n  background-position: 50%;\n  display: inline-block;\n}\n.c-base-browser--android {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n.c-base-browser--chrome {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n}\n.c-base-browser--chromium {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");\n}\n.c-base-browser--edge {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ");\n}\n.c-base-browser--facebook {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ");\n}\n.c-base-browser--firefox {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + ");\n}\n.c-base-browser--internet-explorer {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_7___ + ");\n}\n.c-base-browser--opera {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_8___ + ");\n}\n.c-base-browser--safari {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_9___ + ");\n}\n.c-base-browser--small {\n  width: 14px;\n  height: 14px;\n}\n.c-base-browser--default {\n  width: 20px;\n  height: 20px;\n}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
