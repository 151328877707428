<template>
  <hr
    :class="[
      'c-base-divider',
      'c-base-divider--' + size,
      'c-base-divider--' + color,
    ]"
  />
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: "grey",
      validator(e) {
        return -1 !== ["grey", "white"].indexOf(e);
      },
    },
    size: {
      type: String,
      default: "large",
      validator(e) {
        return -1 !== ["small", "large"].indexOf(e);
      },
    },
  },
};
</script>
<style lang="scss">
.c-base-divider {
  display: block;
  border: 0;
  border-top-style: solid;
  width: 100%;
}
.c-base-divider--grey {
  border-color: #e3e8ef;
}
.c-base-divider--white {
  border-color: #eff3f6;
}
.c-base-divider--small {
  margin: 15px auto;
  max-width: 60px;
  height: 4px;
  border-top-width: 4px;
}
.c-base-divider--large {
  margin: 30px auto;
  height: 1px;
  border-top-width: 1px;
}
</style>
