import a from "@/classes/billing.js";
import o from "@/classes/websites.js";
import n from "@/classes/titleController.js";

export default {
  namespaced: !0,
  state: {
    currentWebsite: {},
    websiteId: "",
    urlWebsiteId: "",
    setup: {},
    usersAvailable: !1,
    unreadCount: 0,
    settings: {},
    billing: {},
    plan: {},
    routingSettings: {},
    routingRules: [],
    hooks: [],
    channelEmailDomain: {},
    channelEmailSignature: {},
    channelEmailIp: {},
    channelEmailIpLogs: [],
    emailSmtp: {},
    verifySettings: {},
    verifyKey: {},
    contracts: [],
    usageQuotas: [],
    usageLimits: [],
    websites: [],
    subscriptions: [],
    addWebsitePopup: {
      visible: !1,
    },
    addOperatorPopup: {
      visible: !1,
      websiteId: null,
    },
  },
  getters: {
    getWebsite: (e) => (t) => e.websites.find((e) => e.id === t),
    getWebsites: (e) => e.websites,
    getSubscriptions: (e) => e.subscriptions,
    getWebsiteId: (e) => e.websiteId,
    getURLWebsiteId: (e) => e.urlWebsiteId,
    getCurrentWebsite: (e) => e.currentWebsite,
    getCurrentWebsiteSubscription: (e) => e.currentWebsite.subscription || {},
    getContracts: (e) => e.contracts,
    getUsageQuotas: (e) => e.usageQuotas,
    getUsageLimits: (e) => e.usageLimits,
    getSetup: (e) => (t) => (e.setup[t] ? e.setup[t] : null),
    hasUsersAvailable: (e) => e.usersAvailable,
    getSettings: (e) => e.settings,
    getBilling: (e) => e.billing,
    getPlan: (e) => e.plan,
    getRoutingSettings: (e) => e.routingSettings,
    getRoutingRules: (e) => e.routingRules,
    getHooks: (e) => e.hooks,
    getChannelEmailDomain: (e) => e.channelEmailDomain,
    getChannelEmailSignature: (e) => e.channelEmailSignature,
    getChannelEmailIp: (e) => e.channelEmailIp,
    getChannelEmailIpLogs: (e) => e.channelEmailIpLogs,
    getEmailSmtp: (e) => e.emailSmtp,
    getVerifySettings: (e) => e.verifySettings,
    getVerifyKey: (e) => e.verifyKey,
    getAddOperatorPopup: (e) => e.addOperatorPopup,
    getAddWebsitePopup: (e) => e.addWebsitePopup,
  },
  actions: {
    acquireWebsites({ getters: e, commit: t, state: i }) {
      console.log("READING_WEBSITES");
      return a
        .websites()
        .get()
        .then((a) => {
          t("setWebsites", {
            value: a,
          });
          let o = i.currentWebsite.website_id;
          if (o && a.length > 0) {
            let i = e.getCurrentWebsite,
              a = e.getWebsite(o);
            a &&
              ((i.name = a.name),
              (i.website_id = a.id),
              (i.logo = a.logo),
              (i.count = a.count),
              (i.assigned = a.assigned),
              t("setCurrentWebsite", {
                value: i,
              }));
          } else
            o &&
              0 === a.length &&
              t("setCurrentWebsite", {
                value: {},
              });
          return (
            a.forEach((e) => {
              t("updateWebsiteUnreadCount", {
                websiteId: e.id,
                count: e.count,
                assigned: e.assigned,
              });
            }),
            t("updateUnreadCount"),
            Promise.resolve()
          );
        });
    },
    disableSetup({ commit: e }, { websiteId: t }) {
      return o
        .setup(t)
        .patch({
          disabled: !0,
        })
        .then(
          () => (
            e("disableSetup", {
              websiteId: t,
            }),
            Promise.resolve()
          ),
        );
    },
    getSetup({ commit: e }, { websiteId: t }) {
      return o
        .setup(t)
        .get()
        .then(
          (i) => (
            e("setSetup", {
              websiteId: t,
              setup: i,
            }),
            Promise.resolve()
          ),
        );
    },
    acquireUsageQuotas({ commit: e }, { websiteId: t }) {
      return o
        .usageQuotas(t)
        .get()
        .catch(() => Promise.resolve([]))
        .then(
          (t) => (
            e("setUsageQuotas", {
              value: t,
            }),
            Promise.resolve(t)
          ),
        );
    },
    acquireUsageLimits({ commit: e }, { websiteId: t }) {
      return o
        .usageLimits(t)
        .get()
        .catch(() => Promise.resolve([]))
        .then(
          (t) => (
            e("setUsageLimits", {
              value: t,
            }),
            Promise.resolve(t)
          ),
        );
    },
  },
  mutations: {
    reset(e) {
      e.currentWebsite = {};
      e.setup = {};
      e.usersAvailable = !1;
      e.unreadCount = 0;
      e.websites = [];
      e.subscriptions = [];
    },
    disableSetup(e, { websiteId: t }) {
      e.setup[t] && (e.setup[t].disabled = !0);
    },
    setSetup(e, { websiteId: t, setup: i }) {
      e.setup[t] = i;
    },
    decrementWebsiteUnreadCount(e, { websiteId: t, decrement: i = 0 }) {
      let a = e.websites.find((e) => e.id === t);
      if (!a || a.id !== t) return;
      let o = {
        count: 0,
        assigned: 0,
      };
      for (let n in o)
        if (a[n]) {
          let t = a[n] - i;
          t < 0 && (t = 0), (a[n] = t), (e.currentWebsite[n] = t);
        }
      this.commit("websites/updateUnreadCount");
    },
    incrementWebsiteUnreadCount(e, { websiteId: t, assigned: i }) {
      let a = e.websites.find((e) => e.id === t);
      a &&
        a.id === t &&
        (this.commit("websites/updateWebsiteUnreadCount", {
          websiteId: t,
          count: (a.count || 0) + 1,
          assigned: (a.assigned || 0) + (i ? 1 : 0),
        }),
        this.commit("websites/updateUnreadCount"));
    },
    updateWebsiteUnreadCount(
      e,
      { websiteId: t, count: i = 0, assigned: a = 0, updateAssigned: o = !0 },
    ) {
      let n = e.websites.find((e) => e.id === t);
      n &&
        n.id === t &&
        ((n.count = i),
        o && (n.assigned = a),
        n.id === e.currentWebsite.website_id &&
          ((e.currentWebsite.count = i), o && (e.currentWebsite.assigned = a)),
        this.commit("websites/updateUnreadCount"));
    },
    updateUnreadCount(e) {
      (e.unreadCount = (e.websites || []).reduce(
        (e, t) => e + (t.count || 0),
        0,
      )),
        n.setUnreadCounter(e.unreadCount);
    },
    setSubscriptions(e, { value: t }) {
      (e.subscriptions = t),
        t.forEach((t) => {
          const i = e.websites.findIndex((e) => e.id === t.website.id);
          -1 !== i && (e.websites[i].subscription = t);
        });
    },
    setUsersAvailable(e, { value: t }) {
      e.usersAvailable = t;
    },
    setSettings(e, { value: t }) {
      e.settings = t;
    },
    setBilling(e, { value: t }) {
      e.billing = t;
    },
    setPlan(e, { value: t }) {
      e.plan = t;
    },
    setRoutingSettings(e, { value: t }) {
      e.routingSettings = t;
    },
    setRoutingRules(e, { value: t }) {
      e.routingRules = t;
    },
    setHooks(e, { value: t }) {
      e.hooks = t;
    },
    setHook(e, { hookId: t, hook: i }) {
      let a = e.hooks.findIndex((e) => e.hook_id === t);
      -1 === a ? e.hooks.push(i) : (e.hooks[a] = i);
    },
    removeHook(e, { hookId: t }) {
      let i = e.hooks.findIndex((e) => e.hook_id === t);
      -1 !== i && e.hooks.splice(i, 1);
    },
    setChannelEmailDomain(e, { value: t }) {
      e.channelEmailDomain = t;
    },
    setChannelEmailSignature(e, { value: t }) {
      e.channelEmailSignature = t;
    },
    setChannelEmailIp(e, { value: t }) {
      e.channelEmailIp = t;
    },
    setChannelEmailIpLogs(e, { value: t }) {
      e.channelEmailIpLogs = t;
    },
    setEmailSmtp(e, { value: t }) {
      e.emailSmtp = t;
    },
    setVerifySettings(e, { value: t }) {
      e.verifySettings = t;
    },
    setVerifyKey(e, { value: t }) {
      e.verifyKey = t;
    },
    setContracts(e, { value: t }) {
      e.contracts = t;
    },
    setUsageQuotas(e, { value: t }) {
      e.usageQuotas = t;
    },
    setUsageLimits(e, { value: t }) {
      e.usageLimits = t;
    },
    setWebsiteId(e, { value: t }) {
      e.websiteId = t;
    },
    setURLWebsiteId(e, { value: t }) {
      e.urlWebsiteId = t;
    },
    setCurrentWebsite(e, { value: t }) {
      (e.currentWebsite = t),
        e.websites.forEach((i) => {
          i.id === t.website_id &&
            ((e.currentWebsite.assigned = i.assigned || 0),
            (e.currentWebsite.count = i.count || 0),
            i.subscription && (e.currentWebsite.subscription = i.subscription));
        });
    },
    setWebsites(e, { value: t }) {
      (e.websites = t),
        t.forEach((t) => {
          t.id === e.currentWebsite.website_id &&
            ((e.currentWebsite.count = t.count),
            (e.currentWebsite.assigned = t.assigned));
        }),
        this.commit("websites/setSubscriptions", {
          value: e.subscriptions,
        }),
        this.commit("websites/updateUnreadCount");
    },
    setAddWebsitePopupVisible(e, { value: t }) {
      e.addWebsitePopup.visible = t;
    },
    setAddOperatorPopupVisible(e, { websiteId: t, value: i }) {
      (e.addOperatorPopup.websiteId = !0 === i ? t : null),
        (e.addOperatorPopup.visible = i);
    },
  },
};
