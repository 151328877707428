<template>
  <div class="c-field-duration">
    <FieldInput
      class="c-field-duration__value js-field-duration__value"
      :modelValue="currentValue"
      v-on:update:modelValue="(e) => (currentValue = e)"
      v-on:input="onChange"
      :dark="dark"
      :min="0"
      :size="size"
      type="number"
      autofocus=""
      no-focus-style=""
    ></FieldInput>
    <FieldSelect
      class="c-field-duration__unit"
      :modelValue="currentUnit"
      v-on:update:modelValue="(e) => (currentUnit = e)"
      v-on:input="onChange"
      :dark="dark"
      :options="options"
      :size="size"
    ></FieldSelect>
  </div>
</template>
<script>
export default {
  props: {
    dark: {
      type: Boolean,
      default: !1,
    },
    name: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "default",
    },
    modelValue: {
      type: Number,
      default: 0,
    },
  },
  emits: ["update:modelValue", "input"],
  data() {
    return {
      currentValue: 0,
      currentUnit: "hours",
      units: [
        {
          label: this.$t("FORMS.FIELD_DURATION.HOURS"),
          value: "hours",
          multiplicator: 36e5,
        },
        {
          label: this.$t("FORMS.FIELD_DURATION.DAYS"),
          value: "days",
          multiplicator: 864e5,
        },
        {
          label: this.$t("FORMS.FIELD_DURATION.WEEKS"),
          value: "weeks",
          multiplicator: 6048e5,
        },
        {
          label: this.$t("FORMS.FIELD_DURATION.MONTHS"),
          value: "months",
          multiplicator: 2592e6,
        },
      ],
    };
  },
  computed: {
    multiplicator() {
      const e = this.units.find((e) => e.value === this.currentUnit);
      return e.multiplicator;
    },
  },
  watch: {
    modelValue: {
      immediate: !0,
      handler(e) {
        e &&
          0 !== e &&
          this.units.forEach((t) => {
            e % t.multiplicator === 0 &&
              ((this.currentUnit = t.value),
              (this.currentValue = e / t.multiplicator));
          });
      },
    },
  },
  methods: {
    onChange(e, t, i) {
      this.$nextTick(() => {
        const e = this.currentValue * this.multiplicator;
        this.$emit("update:modelValue", e, this.name, i),
          this.$emit("input", e, this.name, i);
      });
    },
  },
};
</script>
<style lang="scss">
.c-field-duration {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  .c-field-duration__value {
    -webkit-box-flex: 5;
    -ms-flex: 5;
    flex: 5;
    .c-field-input__container {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      border-right: none;
    }
  }
  .c-field-duration__unit {
    -webkit-box-flex: 3;
    -ms-flex: 3;
    flex: 3;
    .c-field-select__field {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
  }
}
</style>
