let Library = function () {};

Library.prototype.init = function (t) {
  function e(t, e) {
    if (!(t instanceof e))
      throw new TypeError("Cannot call a class as a function");
  }

  var Smileys = (function () {
    function t(n) {
      e(this, t);
      this.parent = n;
      this.smiley_map = {
        angry: ":@",
        blushing: ":$",
        confused: "x)",
        cool: "8)",
        crying: ":'(",
        embarrased: ":/",
        heart: "<3",
        laughing: ":'D",
        sad: ":(",
        sick: ":S",
        "small-smile": ":)",
        "big-smile": ":D",
        "thumbs-up": "+1",
        surprised: ":o",
        tongue: ":P",
        winking: ";)",
      };
      this.__smiley_patterns = {
        angry: "((?:=|:)(?:-)?@)",
        blushing: "((?:=|:)(?:-)?\\$)",
        cool: "(8(?:-)?\\))",
        confused: "(x(?:-)?\\))",
        crying: "((?:=|:)'(?:-)?\\()",
        embarrased: "((?:=|:)(?:-)?\\/)",
        heart: "(&lt;3)",
        laughing: "((?:=|:)(?:-)?'D)",
        sad: "((?:=|:)(?:-)?(?:\\(|\\|))",
        sick: "((?:=|:)(?:-)?S)",
        "small-smile": "((?:=|:)(?:-)?\\))",
        "big-smile": "((?:=|:)(?:-)?D)",
        "thumbs-up": "(\\+1)",
        surprised: "((?:=|:)(?:-)?o)",
        tongue: "((?:=|:)(?:-)?P)",
        winking: "(;(?:-)?\\))",
      };
      this.__initialize_smiley_regexes();
    }

    t.prototype.code = function (name, size = "small") {
      const template = this.parent.template;
      const rendered = template.render("smiley", { name, size });
      return rendered;
    };

    t.prototype.parse = function (input) {
      let parsed = input;
      for (const pattern in this.__smiley_regexes) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.__smiley_regexes.hasOwnProperty(pattern)) {
          const regex = this.__smiley_regexes[pattern];
          let match = null;
          while ((match = parsed.match(regex)) !== null) {
            let size = "small";
            if (parsed === match[2]) {
              size = "large";
            }
            const replacement = this.code(pattern, size);
            parsed = parsed.replace(regex, `$1${replacement}$3`);
          }
        }
      }
      return parsed;
    };

    t.prototype.count = function (input) {
      let count = 0;
      let parsed = input;
      for (const pattern in this.__smiley_regexes) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.__smiley_regexes.hasOwnProperty(pattern)) {
          const regex = this.__smiley_regexes[pattern];
          while (regex.test(parsed)) {
            count++;
            parsed = parsed.replace(regex, "$1[]$3");
          }
        }
      }
      return count;
    };

    t.prototype.name = function (input) {
      let matchedName = null;
      for (const pattern in this.__smiley_regexes) {
        if (
          // eslint-disable-next-line no-prototype-builtins
          this.__smiley_regexes.hasOwnProperty(pattern) &&
          input.match(this.__smiley_regexes[pattern])
        ) {
          matchedName = pattern;
          break;
        }
      }
      return matchedName;
    };

    t.prototype.__initialize_smiley_regexes = function () {
      this.__smiley_regexes = {};
      for (const pattern in this.__smiley_patterns) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.__smiley_patterns.hasOwnProperty(pattern)) {
          this.__smiley_regexes[pattern] = new RegExp(
            "(^|\\s|\\()" + this.__smiley_patterns[pattern] + "($|\\s|\\))",
            "i",
          );
        }
      }
    };

    return t;
  })();

  var Parse = (function () {
    function t(n) {
      e(this, t);
      this.parent = n;
      this.__gtld_list = [
        "com",
        "net",
        "org",
        "info",
        "biz",
        "mobi",
        "chat",
        "email",
        "help",
        "watch",
        "name",
        "pro",
        "aero",
        "asia",
        "cat",
        "coop",
        "edu",
        "gov",
        "int",
        "jobs",
        "mil",
        "museum",
        "post",
        "tel",
        "travel",
        "xxx",
        "xyz",
        "top",
        "site",
        "online",
        "club",
        "shop",
        "work",
        "app",
        "live",
        "store",
        "space",
        "website",
        "tech",
        "life",
        "dev",
        "blog",
        "cloud",
        "page",
        "link",
        "design",
        "group",
        "company",
        "agency",
        "network",
        "media",
        "immo",
        "ac",
        "ad",
        "ae",
        "af",
        "ag",
        "ai",
        "al",
        "am",
        "an",
        "ao",
        "aq",
        "ar",
        "as",
        "at",
        "au",
        "aw",
        "ax",
        "az",
        "ba",
        "bb",
        "bd",
        "be",
        "bf",
        "bg",
        "bh",
        "bi",
        "bj",
        "bl",
        "bm",
        "bn",
        "bo",
        "bq",
        "br",
        "bs",
        "bt",
        "bv",
        "bw",
        "by",
        "bz",
        "ca",
        "cc",
        "cd",
        "cf",
        "cg",
        "ch",
        "ci",
        "ck",
        "cl",
        "cm",
        "cn",
        "co",
        "cr",
        "cu",
        "cv",
        "cw",
        "cx",
        "cy",
        "cz",
        "de",
        "dj",
        "dk",
        "dm",
        "do",
        "dz",
        "ec",
        "ee",
        "eg",
        "eh",
        "er",
        "es",
        "et",
        "eu",
        "fi",
        "fj",
        "fk",
        "fm",
        "fo",
        "fr",
        "ga",
        "gb",
        "gd",
        "ge",
        "gf",
        "gg",
        "gh",
        "gi",
        "gl",
        "gm",
        "gn",
        "gp",
        "gq",
        "gr",
        "gs",
        "gt",
        "gu",
        "gw",
        "gy",
        "hk",
        "hm",
        "hn",
        "hr",
        "ht",
        "hu",
        "id",
        "ie",
        "il",
        "im",
        "in",
        "io",
        "iq",
        "ir",
        "is",
        "it",
        "je",
        "jm",
        "jo",
        "jp",
        "ke",
        "kg",
        "kh",
        "ki",
        "km",
        "kn",
        "kp",
        "kr",
        "kw",
        "ky",
        "kz",
        "la",
        "lb",
        "lc",
        "li",
        "lk",
        "lr",
        "ls",
        "lt",
        "lu",
        "lv",
        "ly",
        "ma",
        "mc",
        "md",
        "me",
        "mf",
        "mg",
        "mh",
        "mk",
        "ml",
        "mm",
        "mn",
        "mo",
        "mp",
        "mq",
        "mr",
        "ms",
        "mt",
        "mu",
        "mv",
        "mw",
        "mx",
        "my",
        "mz",
        "na",
        "nc",
        "ne",
        "nf",
        "ng",
        "ni",
        "nl",
        "no",
        "np",
        "nr",
        "nu",
        "nz",
        "om",
        "pa",
        "pe",
        "pf",
        "pg",
        "ph",
        "pk",
        "pl",
        "pm",
        "pn",
        "pr",
        "ps",
        "pt",
        "pw",
        "py",
        "qa",
        "re",
        "ro",
        "rs",
        "ru",
        "rw",
        "sa",
        "sb",
        "sc",
        "sd",
        "se",
        "sg",
        "sh",
        "si",
        "sj",
        "sk",
        "sl",
        "sm",
        "sn",
        "so",
        "sr",
        "ss",
        "st",
        "su",
        "sv",
        "sx",
        "sy",
        "sz",
        "tc",
        "td",
        "tf",
        "tg",
        "th",
        "tj",
        "tk",
        "tl",
        "tm",
        "tn",
        "to",
        "tp",
        "tr",
        "tt",
        "tv",
        "tw",
        "tz",
        "ua",
        "ug",
        "uk",
        "um",
        "us",
        "uy",
        "uz",
        "va",
        "vc",
        "ve",
        "vg",
        "vi",
        "vn",
        "vu",
        "wf",
        "ws",
        "ye",
        "yt",
        "za",
        "zm",
        "zw",
      ];
      this.__html_unescape = {
        "&": /&amp;/g,
        "<": /&lt;/g,
        ">": /&gt;/g,
        '"': /&quot;/g,
      };
      this.__html_escape = {
        "&amp;": /&/g,
        "&lt;": /</g,
        "&gt;": />/g,
        "&quot;": /"/g,
      };
      this.__attribute_escape = {
        "\\\\": /\\/g,
        "\\t": /\t/g,
        "\\n": /\n/g,
        "\\u00A0": /\u00A0/g,
      };
      this.__protect_items_escape = { "<": /&lt;/g, ">": /&gt;/g };
      //eslint-disable-next-line
      this.__escape_regex_regex = /[-\/\\^$*+?.()|[\]{}]/g;
      //eslint-disable-next-line
      this.__protect_items_regex = /([\#$\*\_\-+![\]\(\)`~><\.|{}])/g;
      this.__regex_rules = {
        links: {
          prefix: "(\\s|<[^<>]+\\/>|<[^a][^<>]*>|\\(|^)",
          suffix: "(,|\\s|<[^<>]+\\/>|</[^a][^<>]*>|\\)|$)",
        },
        markdown_simple: { prefix: "(^)", suffix: "($)" },
        markdown_includes: {
          prefix: "(\\s|<[^<>]+\\/>|<[^<>]+>|\\(|^)",
          suffix: "(\\s|.|,|:|<[^<>]+\\/>|\\)|$)",
        },
        markdown_formatting: {
          prefix: "(\\s|<[^<>]+\\/>|\\(|^)",
          suffix: "",
        },
        markdown_list: { prefix: "(^\\s*)", suffix: "(.*?)($)" },
      };
      this.__markdown_trim_items = [
        "title",
        "list",
        "code_block",
        "code_inline",
        "blockquote",
        "emphasis",
      ];
      this.__markdown_protect_items = ["code_block", "code_inline"];
      this.__format_pipeline = [
        "escape",
        // "consecutive_lines", //DISABLE CONTROL OF MULTIPLE LINES
        "markdown",
        "links_uri",
        "links_domain",
        "emails",
        "phones",
        "smileys",
        "new_lines",
      ];
      this.__format_apply_methods = {
        escape: this.__apply_escape,
        consecutive_lines: this.__apply_consecutive_lines,
        new_lines: this.__apply_new_lines,
        emails: this.__apply_emails,
        phones: this.__apply_phones,
        markdown: this.__apply_markdown,
        links_domain: this.__apply_links_domain,
        links_uri: this.__apply_links_uri,
        smileys: this.__apply_smileys,
      };
      this.__initialize_link_regexes();
      this.__initialize_markdown_regexes();
    }

    t.prototype.format = function (t) {
      var e =
        arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
      var n =
        arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : [];
      var r = void 0;
      var i = void 0;
      var o = t;

      for (r = 0; r < this.__format_pipeline.length; r++) {
        i = this.__format_pipeline[r];
        if (-1 === n.indexOf(i)) {
          o = this.__format_apply_methods[i].bind(this)(o, e);
        }
      }

      return o;
    };
    t.prototype.replace = function (t) {
      var e =
          arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : [],
        n = void 0,
        r = void 0,
        i = t;
      for (n = 0; n < e.length; n++)
        (r = n + 1), (i = i.replace("%" + r + "s", e[n]));
      return i;
    };
    t.prototype.attribute = function (t) {
      return this.__apply_escape(t, {}, this.__attribute_escape);
    };
    t.prototype.html = function (t) {
      var e = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
      return this.__apply_escape(
        t,
        {},
        !0 === e ? this.__html_unescape : this.__html_escape,
      );
    };
    t.prototype.escape_regex = function (t) {
      return t.replace(this.__escape_regex_regex, "\\$&");
    };
    t.prototype.__apply_escape = function (t) {
      var e =
          arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : null,
        n = void 0,
        r = void 0,
        i = t,
        o = e || this.__html_escape;
      //eslint-disable-next-line
      for (r in o) o.hasOwnProperty(r) && ((n = o[r]), (i = i.replace(n, r)));
      return i;
    };
    t.prototype.__apply_consecutive_lines = function (t) {
      return t.replace(/\n(\s+)?\n(\s+)?\n/g, "\n\n");
    };
    t.prototype.__apply_new_lines = function (t) {
      var e =
        arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
      var n = this.parent.template.render("new_line", {}, e);

      return t.trim().replace(/\n/g, n);
    };
    t.prototype.__apply_emails = function (t) {
      var e = this,
        n = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
      return t.replace(this.__links_regex.email, function (t, r, i, o) {
        var a = e.parent.template.render(
          "link_email",
          {
            email: i,
            no_external_link: e.parent.config.runtime.no_external_link || !1,
          },
          n,
        );
        return "" + r + a + o;
      });
    };
    t.prototype.__apply_phones = function (t) {
      var e = this,
        n = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
      return t.replace(this.__links_regex.phone, function (t, r, i, o) {
        var a = e.parent.template.render(
          "link_phone",
          {
            phone: i,
            no_external_link: e.parent.config.runtime.no_external_link || !1,
          },
          n,
        );
        return "" + r + a + o;
      });
    };
    t.prototype.__apply_markdown = function (t) {
      var e =
        arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
      t = "\n" + t + "\n";
      for (var n = 0; n < this.__markdown_regex.length; n++)
        t = t.replace(
          this.__markdown_regex[n][1],
          this.__replace_markdown_item(this.__markdown_regex[n], e),
        );
      return (t = t.trim()), t;
    };
    t.prototype.__apply_links_domain = function (t) {
      var e = this,
        n = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
      return t.replace(this.__links_regex.domain, function (t, r, i, o) {
        var a = e.parent.template.render(
          "link_domain",
          {
            domain_value: i,
            domain_name: i,
            no_external_link: e.parent.config.runtime.no_external_link || !1,
          },
          n,
        );
        return "" + r + a + o;
      });
    };
    t.prototype.__apply_links_uri = function (t) {
      var e = this,
        n = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
      return t.replace(this.__links_regex.uri, function (t, r, i, o) {
        var a = e.parent.template.render(
          "link_uri",
          {
            uri_value: i,
            uri_name: i,
            no_external_link: e.parent.config.runtime.no_external_link || !1,
          },
          n,
        );
        return "" + r + a + o;
      });
    };
    t.prototype.__apply_smileys = function (t) {
      return this.parent.Smileys.parse(t);
    };
    t.prototype.__replace_markdown_item = function (t) {
      var e = this,
        n = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
      return function (r, i, o, a, s) {
        var c = "",
          u = "",
          l = {
            type: null,
            value: null,
            url_crisp_image: e.parent.config.url.crisp_image,
            no_external_link: e.parent.config.runtime.no_external_link || !1,
          };
        (c = ("string" === typeof i ? i : null) || ""),
          (u = ("string" === typeof s ? s : null) || ""),
          (l.type = o),
          (l.value = a),
          -1 !== e.__markdown_trim_items.indexOf(t[0]) &&
            "string" === typeof l.value &&
            (l.value = l.value.trim()),
          -1 !== e.__markdown_protect_items.indexOf(t[0]) &&
            "string" === typeof l.value &&
            (l.value = e.__protect_item(l.value));
        var f = e.parent.template.render("markdown_" + t[0], l, n);
        return "" + c + f + u;
      };
    };
    t.prototype.__protect_item = function (t) {
      var e = t;
      return (
        (e = this.__apply_escape(e, {}, this.__protect_items_escape)),
        (e = e.replace(this.__protect_items_regex, function (t) {
          return "&#" + t.charCodeAt(0) + ";";
        })),
        e
      );
    };
    t.prototype.__compile_regex = function (t) {
      var e =
          arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : "gi",
        n = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : {};
      return new RegExp("" + (n.prefix || "") + t + (n.suffix || ""), e);
    };
    t.prototype.__initialize_link_regexes = function () {
      this.__links_regex = {
        email: this.__compile_regex(
          "([\\w\\._-]+@[\\w\\.\\/_-]+)",
          "gi",
          this.__regex_rules.links,
        ),
        phone: this.__compile_regex(
          "(\\+[0-9]{1,3}[ \\(\\)\\-0-9]{7,20})",
          "gi",
          this.__regex_rules.links,
        ),
        domain: this.__compile_regex(
          [
            "((?:[^<>\\(\\)\\s,@]+)(?:\\.(?:",
            this.__gtld_list.map(this.escape_regex.bind(this)).join("|"),
            ")))",
          ].join(""),
          "gim",
          this.__regex_rules.links,
        ),
        uri: this.__compile_regex(
          "((?:https?|ftps?|file):(?:[^<>\\(\\)\\s,]+))",
          "gim",
          this.__regex_rules.links,
        ),
      };
    };
    t.prototype.__initialize_markdown_regexes = function () {
      this.__markdown_regex = [
        [
          "code_block",
          this.__compile_regex(
            "(`{2,3})((?:\\n+)?(?:(?!\\2).(?:\\n+)?)*)(?:\\2)",
            "gi",
            { prefix: "(\\s|<[^<>]+\\/>|^)", suffix: "(\\s|\\.|,|:|$)" },
          ),
        ],
        [
          "code_inline",
          this.__compile_regex("(`{1})([^`]*)(?:\\2)", "gim", {
            prefix: "(\\s|<[^<>]+\\/>|\\(|^)",
            suffix: "(\\s|\\.|,|:|\\)|<\\/[^<>]+>|$)",
          }),
        ],
        [
          "title",
          this.__compile_regex(
            "(#{1})(?:[\\s]+)([^#].*)",
            "gim",
            this.__regex_rules.markdown_simple,
          ),
        ],
        [
          "title",
          this.__compile_regex(
            "(#{2})(?:[\\s]+)([^#].*)",
            "gim",
            this.__regex_rules.markdown_simple,
          ),
        ],
        [
          "title",
          this.__compile_regex(
            "(#{3})(?:[\\s]+)([^#].*)",
            "gim",
            this.__regex_rules.markdown_simple,
          ),
        ],
        [
          "title",
          this.__compile_regex(
            "(#{4})(?:[\\s]+)([^#].*)",
            "gim",
            this.__regex_rules.markdown_simple,
          ),
        ],
        [
          "title",
          this.__compile_regex(
            "(#{5})(?:[\\s]+)([^#].*)",
            "gim",
            this.__regex_rules.markdown_simple,
          ),
        ],
        [
          "title",
          this.__compile_regex(
            "(#{6})(?:[\\s]+)([^#].*)",
            "gim",
            this.__regex_rules.markdown_simple,
          ),
        ],
        [
          "image",
          this.__compile_regex(
            "!\\[([^\\[]*)\\]\\(([^\\)]+)\\)",
            "gim",
            this.__regex_rules.markdown_includes,
          ),
        ],
        [
          "link",
          this.__compile_regex("\\[([^\\[]+)\\]\\(([^\\)]+)\\)", "gim", {
            prefix: "(\\s|<[^<>]+\\/>|<[^a][^<>]*>|\\(|^)",
            suffix: "(\\s|.|,|:|<[^<>]+\\/>|</[^a][^<>]*>|\\)|$)",
          }),
        ],
        [
          "youtube",
          this.__compile_regex(
            "\\$\\{youtube\\}\\[([^\\[]*)\\]\\(([^\\)]+)\\)",
            "gim",
            this.__regex_rules.markdown_includes,
          ),
        ],
        [
          "vimeo",
          this.__compile_regex(
            "\\$\\{vimeo\\}\\[([^\\[]*)\\]\\(([^\\)]+)\\)",
            "gim",
            this.__regex_rules.markdown_includes,
          ),
        ],
        [
          "dailymotion",
          this.__compile_regex(
            "\\$\\{dailymotion\\}\\[([^\\[]*)\\]\\(([^\\)]+)\\)",
            "gim",
            this.__regex_rules.markdown_includes,
          ),
        ],
        [
          "frame",
          this.__compile_regex(
            "\\$\\{frame\\}\\[([^\\[]*)\\]\\(([^\\)]+)\\)",
            "gim",
            this.__regex_rules.markdown_includes,
          ),
        ],
        [
          "bold",
          this.__compile_regex(
            "(\\*)(.*?)(?:\\2)",
            "gim",
            this.__regex_rules.markdown_formatting,
          ),
        ],
        [
          "underline",
          this.__compile_regex(
            "(__)(.*?)(?:\\2)",
            "gim",
            this.__regex_rules.markdown_formatting,
          ),
        ],
        [
          "italic",
          this.__compile_regex(
            "(_)(.*?)(?:\\2)",
            "gim",
            this.__regex_rules.markdown_formatting,
          ),
        ],
        [
          "delete",
          this.__compile_regex(
            "(\\~)(.*?)(?:\\2)",
            "gim",
            this.__regex_rules.markdown_formatting,
          ),
        ],
        [
          "highlight",
          this.__compile_regex(
            "(\\+\\+)(.*?)(?:\\2)",
            "gim",
            this.__regex_rules.markdown_formatting,
          ),
        ],
        [
          "list",
          this.__compile_regex(
            "(\\*)(?:[\\s]+)",
            "gim",
            this.__regex_rules.markdown_list,
          ),
        ],
        [
          "list",
          this.__compile_regex(
            "(?:([\\d]{1,2})\\.)(?:[\\s]+)",
            "gim",
            this.__regex_rules.markdown_list,
          ),
        ],
        [
          "blockquote",
          this.__compile_regex(
            "(&gt;|\\>)(?:[\\s]+)(.*)",
            "gim",
            this.__regex_rules.markdown_simple,
          ),
        ],
        [
          "emphasis",
          this.__compile_regex(
            "(\\|{1})(?:[\\s]+)([^\\|].*)",
            "gim",
            this.__regex_rules.markdown_simple,
          ),
        ],
        [
          "emphasis",
          this.__compile_regex(
            "(\\|{2})(?:[\\s]+)([^\\|].*)",
            "gim",
            this.__regex_rules.markdown_simple,
          ),
        ],
        [
          "emphasis",
          this.__compile_regex(
            "(\\|{3})(?:[\\s]+)([^\\|].*)",
            "gim",
            this.__regex_rules.markdown_simple,
          ),
        ],
        [
          "interpolate",
          this.__compile_regex(
            '(?:\\{\\{\\s*)([^\\{\\}\\|\\s]+)(?:\\s*\\|\\s*(?:(?:&quot;)|")([^&"]*)(?:(?:&quot;)|"))?(?:\\s*\\}\\})',
            "gim",
            { prefix: "(\\s|\\(|^)", suffix: "" },
          ),
        ],
        [
          "line",
          this.__compile_regex("(-{3,})", "gim", {
            prefix: "(^)(\\s+)?",
            suffix: "(\\s|$)",
          }),
        ],
        [
          "color",
          this.__compile_regex(
            "\\$\\{color\\}\\[(#[0-9A-Fa-f]{6})\\]\\(([^\\)]+)\\)",
            "gim",
            this.__regex_rules.markdown_includes,
          ),
        ],
      ];
    };

    return t;
  })();

  var Name = (function () {
    function t(n) {
      e(this, t),
        (this.parent = n),
        (this.__full_name_regex = /^(\S+)((?:\s+)(.+))?$/);
    }
    return (
      (t.prototype.parse_first_name = function (t) {
        return this.__parse(t, 1);
      }),
      (t.prototype.parse_last_name = function (t) {
        return this.__parse(t, 3);
      }),
      (t.prototype.__parse = function (t, e) {
        var n = (t || "").trim();
        if (n) {
          var r = n.match(this.__full_name_regex);
          r && r[e] && (n = r[e].trim());
        }
        return n;
      }),
      t
    );
  })();

  this.__configure(t);
  this.Smileys = new Smileys(this);
  this.Parse = new Parse(this);
  this.Name = new Name(this);
};

Library.prototype.__configure = function (t) {
  t && t.template && (this.template = t.template);
  t && t.config && (this.config = t.config);
};

export default new Library();
