import a from "@/classes/platform.js";
import o from "@/classes/platformAssistant.js";
import n from "@/router/index.js";

class _platformBirdge {
  redirectPage(e) {
    a.isPlatform()
      ? (o.setForceReload(),
        e.startsWith("/")
          ? n.default
              .push({
                path: e,
              })
              .catch(() => {})
          : window.LinksPlatformBridge.loadURL("app://./index.html"))
      : (window.location = e);
  }
  reloadPage() {
    a.isPlatform()
      ? "win32" !== window.CommonPlatformBridge.getOS()
        ? window.location.reload()
        : window.LinksPlatformBridge.loadURL("app://./index.html")
      : window.location.reload();
  }
}
export default new _platformBirdge();
