import contact from "@/classes/talqui/contact";
import qs from "qs";

export default {
  namespaced: true,
  state: {
    stats: {},
    contacts: [],
    contactsMap: {},
    segmentation: {
      type: "people",
      label: "",
      operator: "and",
      filter: [],
    },
    profileSections: {
      customData: true,
      devices: true,
      events: true,
      location: true,
      pages: true,
      ratings: true,
      sessions: true,
      campaigns: true,
    },
    import: {
      importID: null,
      total: 0,
      pending: 0,
      accepted: 0,
      refused: 0,
      progress: 0,
      loading: true,
    },
  },
  getters: {
    getContacts: (state) => state.contacts,
    getContact: (state) => (contactID) => state.contactsMap[contactID],
    getContactIndex: (state) => (contactID) =>
      (state.contacts || []).findIndex(
        (contact) => contact.contactID === contactID,
      ),
    getImport: (state) => state.import,
    getImportKey: (state) => (key) => state.import[key] || null,
    // getStats: (e) => e.stats,
    // getProfile: (e) => (t) => e.profilesMap[t],
    // getSegmentation: (e) => e.segmentation,
    // getProfileSections: (e) => e.profileSections,
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    contactUpdate({ commit }, { tenantID, contactID, attributes }) {
      return contact
        .contactOne(tenantID, contactID)
        .patch({
          ...attributes,
        })
        .then((res) => {
          return Promise.resolve(res.contact);
        });
    },

    acquireContacts(
      { commit },
      {
        tenantID,
        page,
        perPage = 10,
        sortBy,
        sortDesc,
        filters = {},
        search = "",
      },
    ) {
      return contact
        .contacts(tenantID)
        .get({
          page,
          perPage,
          sortBy,
          sortDesc,
          ...(filters && { filters: qs.stringify(filters) }),
          ...(search !== "" && {
            search: search,
          }),
        })
        .then((contacts) => {
          if (page === 1) {
            commit("resetAccounts");
          }

          if (contacts.length > 0) {
            contacts.forEach((_contact) => {
              commit("storeContact", {
                contact: _contact,
              });
            });
          }

          return Promise.resolve(contacts);
        })
        .catch((t) => {
          if (page === 1) {
            commit("resetAccounts");
          }

          return Promise.reject(t);
        });
    },

    importDeclare({ commit }, { importID }) {
      commit("setImportID", { importID });
    },

    importProgress(
      { commit, getters },
      { progressKey, progressValue, increment = false },
    ) {
      return new Promise(() => {
        if (increment) {
          progressValue =
            (getters.getImportKey(progressKey) || 0) + progressValue;
        }

        commit("importProgress", { progressKey, progressValue });
        return Promise.resolve();
      }).then(() => {
        return Promise.resolve();
      });
    },
  },
  mutations: {
    resetAccounts(state) {
      state.contacts = [];
      state.contactsMap = {};
    },

    assertAccount(state, { contactID }) {
      if ("object" !== typeof state.contactsMap[contactID]) {
        state.contactsMap[contactID] = {
          contactID: contactID,
          contactEmail: "",
          contactLastSessionID: null,
          contactMeta: {},
          contactTags: [],
          contactChannels: [],
          contactBlock: false,
        };
      }
    },

    storeContact(state, { contact }) {
      this.commit("contactsTalqui/assertAccount", {
        contactID: contact.contactID,
      });

      let $contact = this.getters["contactsTalqui/getContact"](
        contact.contactID,
      );
      Object.assign($contact, contact);

      this.commit("contactsTalqui/postStoreContact", {
        contact,
      });
    },

    postStoreContact(state, { contact }) {
      let contactIndex = this.getters["contactsTalqui/getContactIndex"](
        contact.contactID,
      );

      if (contactIndex < 0) {
        state.contacts.push(contact);
      } else {
        state.contacts[contactIndex] = contact;
      }
    },

    setImportID(state, { importID }) {
      state.importID = importID;
    },

    importProgress(state, { progressKey, progressValue }) {
      state.import[progressKey] = progressValue;
      this.commit("contactsTalqui/postImportProgress");
    },

    postImportProgress(state) {
      let $processed = state.import["accepted"] + state.import["refused"];
      let __prePending = state.import["total"] - $processed;
      state.import["pending"] = __prePending <= 0 ? __prePending : 0;
      state.import["progress"] =
        parseInt($processed / state.import["total"]) * 100;
      if (state.import["progress"] >= 95) {
        state.import["loading"] = false;
      }
    },
  },
};
