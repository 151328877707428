<template>
  <span
    :class="[
      'c-base-flag',
      'u-has-tooltip',
      {
        'c-base-flag--circular': circular,
      },
    ]"
    :style="{
      height: height,
      width: width,
    }"
  >
    <BaseTooltip v-if="tooltip" :align="tooltipAlign">{{
      tooltip
    }}</BaseTooltip>
    <slot></slot>
    <span
      class="c-base-flag__inner"
      :style="{
        borderColor: borderColor,
        borderStyle: borderColor && borderWidth ? 'solid' : null,
        borderWidth: borderWidth,
        height: height,
        width: width,
      }"
    >
      <span
        class="c-base-flag__image"
        :style="{
          backgroundImage: finalFlagCode
            ? 'url(/images/common/flags/' + finalFlagCode + '.png)'
            : 'url(/images/common/flags/default.svg)',
          height: height,
          width: width,
        }"
      ></span>
    </span>
  </span>
</template>
<script>
export default {
  props: {
    circular: {
      type: Boolean,
      default: !1,
    },
    code: {
      type: String,
      default: null,
    },
    locale: {
      type: String,
      default: null,
    },
    borderColor: {
      type: String,
      default: null,
    },
    borderWidth: {
      type: String,
      default: null,
    },
    hasTooltip: {
      type: Boolean,
      default: !1,
    },
    tooltipAlign: {
      type: String,
      default: "left",
      validator(e) {
        return -1 !== ["left", "center", "right"].indexOf(e);
      },
    },
    height: {
      type: String,
      required: !0,
    },
    width: {
      type: String,
      required: !0,
    },
  },
  computed: {
    countryFromLocale() {
      let e = null;
      return (
        this.locale &&
          (-1 === this.locale.indexOf("-")
            ? ((e =
                this.$CONFIG.CRISP_LOCALES_TO_COUNTRIES[this.locale] || null),
              e && (e = e.toLowerCase()))
            : (e = this.$filters.locale.parseLocale(this.locale).country)),
        e
      );
    },
    finalFlagCode() {
      let e = (this.code || this.countryFromLocale || "").toLowerCase();
      return e ? e : null;
    },
    tooltip() {
      if (this.hasTooltip)
        return this.finalFlagCode
          ? this.$filters.format.isoCountry(this.finalFlagCode)
          : this.$t("TOOLTIPS.NO_COUNTRY");
    },
  },
};
</script>
<style lang="scss">
.c-base-flag {
  .c-base-flag__inner {
    position: relative;
    display: block;
    .c-base-flag__image {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: inline-block;
      overflow: hidden;
      background-color: #eff3f6;
      background-position: 50%;
      background-size: cover;
    }
  }
}
.c-base-flag--circular {
  .c-base-flag__inner {
    overflow: hidden;
    border-radius: 100%;
  }
}
</style>
