import a from "@/enviroment.js";
import o from "./helpers.js";
import n from "./notations.js";
import r from "./renders.js";
import s from "talqui-countries-languages";

import { generateText, generateHTML } from "@tiptap/core";
import { Document } from "@tiptap/extension-document";
import { Paragraph } from "@tiptap/extension-paragraph";
import { Text } from "@tiptap/extension-text";
import { Bold } from "@tiptap/extension-bold";
import { Italic } from "@tiptap/extension-italic";
import { Underline } from "@tiptap/extension-underline";
import { HardBreak } from "@tiptap/extension-hard-break";
import { Variable } from "@talqui-oss/tiptap-extension-variable";

// var l = u(i("5293")), //IDK
//smart-wrarper
// var l = function (_) {
//   return _;
// };
//     c = u(i("f37d")), //IDK
//smart-wrarper
var c = function (a, b) {
  return _.contains(a, b);
};
import d from "ua-parser-js";

const M = /^(\S+)((\s+)(.+))?$/,
  g = /^(?:https?:[/]*)?([^/]+)/,
  p = /^(?:https?:[/]*)(?:([^/]+))(.*)/,
  b = /^urn:([^:]+):([^:]+):[0-9]+$/,
  m = /(^|\s)(#\w*[a-zA-Z_]+\w*)/gim,
  N = /(^|\s)@(\w*[a-zA-Z_]+\w*)/gim,
  I = /^urn:([^:]+)/,
  f = /^(["'])(.+)\1$/,
  x = /\\(["'])/g,
  h = /^([^-]+)(?:-([A-Z]{2}))?$/,
  j = {
    "crisp-app-ios": "iOS",
    "crisp-app-android": "Android",
  },
  L = {
    USD: "$",
    EUR: "€",
    GBP: "£",
    BRL: "R$ ",
  },
  y = 10,
  D = "en-GB",
  _ = 3,
  w = 500,
  T = 500,
  S = 1e3,
  A = 2e3,
  v = 19,
  E = ' target="_blank" rel="noopener noreferrer"';
let k = {
  firstName: {},
  lastName: {},
  parseText: {},
  parseHighlight: {},
  localesToCountries: {},
};
let z = {
  firstName: 0,
  lastName: 0,
  parseText: 0,
};

class _filters {
  pad(e) {
    let t = "" + e;
    if (isNaN(e)) throw ((t = "00"), new Error("Not a number: " + e));
    return (
      "number" !== typeof e && (e = parseInt(e, 10)), e < y && (t = "0" + e), t
    );
  }

  prettyNumber(e, t = 0, i = 0) {
    let a = new Intl.NumberFormat(D, {
      maximumFractionDigits: i || _,
    }).format(e);
    if (t > 0) {
      let e = a.split(".")[1] || "";
      e.length < t && (e || (a += "."), (a += "0".repeat(t - e.length)));
    }
    return a;
  }

  cutText(e, t = 10, i = "…", a = !1) {
    return e
      ? ((t = parseInt(t, 10)),
        t
          ? e.length <= t
            ? e
            : ((e =
                !0 === a
                  ? e.substr(Math.max(0, e.length - t), e.length)
                  : e.substr(0, t)),
              e.trim() + (i || ""))
          : e)
      : "";
  }

  amountMain(e, t = !1) {
    let i = 0;
    return (
      isNaN(e) ||
        ("number" !== typeof e && (e = parseInt(e, 10)), (i = Math.floor(e))),
      !0 === t ? n.asCommaNotation(i) : "" + i
    );
  }

  amountCents(e) {
    let t = 0;
    return (
      isNaN(e) ||
        ("number" !== typeof e && (e = parseInt(e, 10)),
        e < 0 && (e *= -1),
        (t = Math.round((e % 1) * 100))),
      1 === ("" + t).length ? "0" + t : t
    );
  }

  floor(e) {
    let t = 0;
    return (
      isNaN(e) ||
        ("number" !== typeof e && (e = parseInt(e, 10)), (t = Math.floor(e))),
      "" + t
    );
  }

  ucFirst(e) {
    return e.charAt(0).toUpperCase() + e.slice(1);
  }

  encodeUri(e) {
    return window.encodeURIComponent(e);
  }

  extractDomain(e) {
    let t = (e || "").toLowerCase().match(g);
    return t && t[1] ? t[1] : e;
  }

  extractDomainBase(e) {
    return e.toLowerCase().split(".").slice(1).join(".");
  }

  extractPathFromURI(e) {
    let t = (e || "").toLowerCase().match(p);
    return t && t[1] && t[2] ? t[2] : "/";
  }

  extractDomainSub(e, t) {
    return e.toLowerCase().replace("." + t.toLowerCase(), "");
  }

  extractOrigin(e, t = "name") {
    if (e.startsWith("urn:")) {
      let i = ((e || "").match(b) || [])["domain" === t ? 1 : 2];
      if (i)
        switch (t) {
          case "name":
            return i.substr(0, 1).toUpperCase() + i.substr(1);
          default:
            return i;
        }
      return e;
    }
    return e;
  }

  parseFirstName(e) {
    let t = k.firstName[e];
    if (!t) {
      if (((t = e), t)) {
        t = t.trim();
        let e = t.match(M);
        e && (t = e[1]);
      }
      z.firstName > w && ((k.firstName = {}), (z.firstName = 0)),
        (k.firstName[e] = t),
        z.firstName++;
    }
    return t;
  }
  parseLastName(e) {
    let t = k.lastName[e];
    if (!t) {
      if (((t = e), t)) {
        t = t.trim();
        let e = t.match(M);
        e && (t = e[e.length - 1]);
      }
      z.lastName > T && ((k.lastName = {}), (z.lastName = 0)),
        (k.lastName[e] = t),
        z.lastName++;
    }
    return t;
  }
  parseEmailUsername(e) {
    let t = (e || "").indexOf("@");
    return -1 !== t ? e.substr(0, t) : "";
  }

  parseText(text = "", formatType = "default", additionalOptions = []) {
    let parsedText = k.parseText[text];

    if (!parsedText) {
      let options = (formatType === "escaped" ? ["escape"] : []).concat(
        additionalOptions,
      );
      parsedText = r.parse().format(text, {}, options);

      if ((parsedText || "").length <= A) {
        if (z.parseText > S) {
          k.parseText = {};
          z.parseText = 0;
        }

        k.parseText[text] = parsedText;
        z.parseText++;
      }
    }

    return parsedText;
  }

  parseHighlight(e, t = "", i = [], a = !1) {
    let n = [];
    if (
      (t
        .toLowerCase()
        .split(" ")
        .forEach((e) => {
          e.length > 1 && n.push(o.regexEscape(e));
        }),
      n.length > 0)
    ) {
      let t = i.join(" "),
        a = n.join("|"),
        o = k.parseHighlight[a] || null;
      o || ((o = new RegExp(`(\\b)(${a})`, "gi")), (k.parseHighlight[a] = o)),
        (e = e.replace(o, `$1<span class="${t}">$2</span>`));
    }
    return !0 === a ? this.parseText(e, "escaped") : e;
  }

  parseTwitter(e) {
    if (!e) return e;
    let t = e;
    return (
      (t = t.replace(
        m,
        (e, t, i) =>
          t +
          '<a href="https://twitter.com/search' +
          `?q=${encodeURIComponent(i)}"${E}>` +
          `@${i}</a>`,
      )),
      (t = t.replace(
        N,
        (e, t, i) =>
          t +
          '<a href="https://twitter.com/' +
          encodeURIComponent(i) +
          '"' +
          `${E}>@${i}</a>`,
      )),
      t
    );
  }

  parseUrnProvider(e) {
    let t = (e || "").match(I);
    return t && t[1] ? t[1] : "";
  }

  parseQuoted(e) {
    let t = (e || "").match(f);
    return t && t[2] && (e = t[2].replace(x, "$1")), e;
  }

  parseUserAgent(e) {
    if (e) {
      let t = e.split("/")[0] || "";
      if (t in j) return j[t];
      let i = (0, d.default)(e),
        a = "";
      if (
        (i.browser.name &&
          ((a += i.browser.name), i.os.name && (a += ` (${i.os.name})`)),
        a)
      )
        return a;
    }
    return e;
  }

  toUrl(e, t) {
    let i = "";
    switch (t) {
      case "email":
        (i = e), (0, c.default)(i, "mailto:") || (i = "mailto:" + i);
        break;
      case "phone":
        (i = e), (0, c.default)(i, "tel:") || (i = "tel:" + i);
        break;
      case "website":
        (i = e),
          (0, c.default)(i, "http://") ||
            (0, c.default)(i, "https://") ||
            (i = "http://" + i);
        break;
      case "location":
        i = "https://maps.google.com/?q=" + encodeURIComponent(e);
        break;
      default:
        throw new Error("Unknown link type: " + t);
    }
    return i;
  }

  cardNumber(e) {
    return (e || "")
      .toUpperCase()
      .replace(/[^0-9A-Z]/g, "")
      .substring(0, v);
  }

  cardNumberDisplay(e) {
    return this.cardNumber(e)
      .replace(/(.{4})/g, "$1 ")
      .trim();
  }

  currencyFromCountry(e, t = "USD") {
    switch (e) {
      case "GB":
        return "GBP";
      case "AT":
      case "BE":
      case "BG":
      case "HR":
      case "CH":
      case "CY":
      case "CZ":
      case "DK":
      case "EE":
      case "FI":
      case "FR":
      case "DE":
      case "EL":
      case "HU":
      case "IE":
      case "IT":
      case "LV":
      case "LT":
      case "LU":
      case "MT":
      case "NL":
      case "NO":
      case "PL":
      case "PT":
      case "RO":
      case "SK":
      case "SI":
      case "ES":
      case "SE":
      case "IR":
        return "EUR";
      default:
        return t;
    }
  }

  currencyFromTimezone(e, t = "USD") {
    let i = (e || "None/None").toLowerCase().split("/");
    switch (i[0]) {
      case "europe":
        return "london" === i[1] ? "GBP" : "EUR";
      case "asia":
        if ("tehran" === i[1]) return "EUR";
        break;
    }
    return t;
  }

  currencyFromLocales(e = [], t = "USD") {
    let i,
      a = [];
    for (let o = 0; o < (e || []).length; o++)
      (i = (e[o] || "").match(h)), i && a.push(i);
    for (let o = 0; o < a.length; o++)
      if (((i = a[o]), i && i[2])) {
        if ("US" === i[2] || "CA" === i[2]) return "USD";
        let e = this.currencyFromCountry(i[2], "");
        if (e) return e;
      }
    for (let o = 0; o < a.length; o++)
      if (((i = a[o]), i && i[1]))
        switch (i[1]) {
          case "de":
          case "fr":
          case "bg":
          case "hr":
          case "cs":
          case "da":
          case "et":
          case "fi":
          case "el":
          case "hu":
          case "ga":
          case "it":
          case "lv":
          case "lt":
          case "mt":
          case "nl":
          case "no":
          case "pl":
          case "pt":
          case "ro":
          case "sk":
          case "sl":
          case "es":
          case "sv":
          case "fa":
            return "EUR";
        }
    return t;
  }

  currencySymbol(e, t = "$", i = null) {
    if (!e && i) {
      e = this.currencyFromTimezone(i);
    }

    if (!e) {
      e = this.currencyFromLocales(
        (window.navigator || {}).languages || [],
        "",
      );
    }

    return L[(e || "").toUpperCase()] || t;
  }

  capitalize(e) {
    return e
      ? ((e = e.toString()), e.charAt(0).toUpperCase() + e.slice(1))
      : "";
  }

  titlecase(e) {
    return e
      ? e.replace(
          /([^\s:\-'])([^\s:\-']*)/g,
          (e) => e.charAt(0).toUpperCase() + e.slice(1).toLowerCase(),
        )
      : "";
  }

  isoCountry(e) {
    return e ? (0, s.getCountryName)(e) : "";
  }

  isoCity(e, t, i) {
    let o = e;
    return (
      -1 !== a.default.CRISP_COUNTRIES_WITH_REGIONS.indexOf(i) &&
        t &&
        (o += ", " + t),
      o
    );
  }

  isoLocale(e) {
    return e ? (0, s.getLanguageName)(e) : "";
  }

  emphasizeWithQuery(e = "", t = "") {
    const i = new RegExp(o.regexEscape(t), "gi");
    return e.replace(i, function (e) {
      return "<strong>" + e + "</strong>";
    });
  }

  localesToCountries(e = [], t = -1) {
    let i = `[${e.join(",")}]:${t}`;
    if (!k.localesToCountries[i]) {
      let o,
        n,
        r,
        s = [],
        l = [];
      for (let i = 0; i < e.length; i++)
        if (
          ((o = (e[i] || "").toLowerCase().trim()),
          -1 === o.indexOf("-")
            ? -1 === s.indexOf(o) &&
              ((n = a.default.CRISP_LOCALES_TO_COUNTRIES[o] || null),
              n && (n = n.toLowerCase()),
              (r = [o, n]))
            : (r = o.split("-")),
          2 === r[0].length &&
            -1 === s.indexOf(r[0]) &&
            (l.push({
              locale: r[0],
              country: r[1],
            }),
            s.push(r[0])),
          -1 !== t && l.length >= t)
        )
          break;
      k.localesToCountries[i] = l;
    }
    return k.localesToCountries[i];
  }

  editorToText(json) {
    if (typeof json !== "object") {
      return "";
    }

    let __res = generateText(
      json,
      [Document, Paragraph, Text, Bold, Italic, Underline, HardBreak, Variable],
      {
        textSerializers: {
          text: (params) => {
            const __content = (params.node.marks || []).reduce(
              (state, mark) => {
                let __value = state;

                switch (mark.type.name) {
                  case "bold":
                    __value = `*${__value.trim()}*`;
                    break;

                  case "italic":
                    __value = `_${__value.trim()}_`;
                    break;

                  case "underline":
                    __value = `__${__value.trim()}__`;
                    break;
                }

                return __value;
              },
              params["node"]["text"],
            );

            return __content;
          },
        },
      },
    );

    return __res;
  }

  editorToHtml(json) {
    if (typeof json !== "object") {
      return "";
    }

    let __res = generateHTML(
      json,
      [Document, Paragraph, Text, Bold, Italic, Underline, HardBreak, Variable],
      {
        textSerializers: {
          text: (params) => {
            const __content = (params.node.marks || []).reduce(
              (state, mark) => {
                let __value = state;

                switch (mark.type.name) {
                  case "bold":
                    __value = `*${__value.trim()}*`;
                    break;

                  case "italic":
                    __value = `_${__value.trim()}_`;
                    break;

                  case "underline":
                    __value = `__${__value.trim()}__`;
                    break;
                }

                return __value;
              },
              params["node"]["text"],
            );

            return __content;
          },
        },
      },
    );

    return __res;
  }
}
export default new _filters();
