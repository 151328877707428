import * as o from "@vee-validate/rules";
import n from "@/classes/i18n.js";

import { defineRule, Form, Field } from "vee-validate";

const s = [
  [
    "email",
    null,
    /^(?:(?:[^<>()\][,;:\s@"]+)@([^<>()\][,;:\s@"]+\.[^<>()\][,;:\s@"]+))$/,
  ],
  ["domain_basic", "domain", /^([a-z](?:[a-z0-9\\-]*[a-z0-9])?)$/],
  [
    "domain_custom",
    "domain",
    new RegExp("^(([^\\\\/:@&_\\*]+)\\.)[^\\\\/:@&_\\*\\-\\.]{2,63}$"),
  ],
  [
    "url",
    null,
    new RegExp(
      "https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}.[a-zA-Z0-9()]{1,6}([-a-zA-Z0-9()@:%_+.~#?&//=]*)",
    ),
  ],
  [
    "youtube",
    null,
    new RegExp(
      "^(?:https?:\\/\\/)?(?:(?:(?:www\\.)?youtube\\.[^\\.]+\\/watch\\?v=)|(?:youtu\\.be\\/))([^&]+)",
      "i",
    ),
  ],
  ["shortcut_bang", null, new RegExp("^(![^!\\s]+)$")],
];

class _validator {
  init(e) {
    e.component("ValidationObserver", Form);
    e.component("ValidationProvider", Field);
    defineRule("required", o.required);
    defineRule("min", o.min);
    defineRule("max", o.max);
    defineRule("min_value", o.min_value);
    defineRule("max_value", o.max_value);
    defineRule("numeric", o.numeric);
    defineRule("regex", o.regex);

    for (let t = 0; t < s.length; t++) {
      defineRule(s[t][0], (e) => {
        if (!e) return !0;
        let i = s[t][2];
        if (Array.isArray(i)) {
          for (let t = 0; t < i.length; t++) if (e.match(i[t])) return !0;
        } else if (e.match(i)) return !0;
        return n.getInstance().messages[n.getInstance().locale].VALIDATION
          .RULES["" + (s[t][1] || s[t][0]).toUpperCase()];
      });
    }

    defineRule(
      "card_expire",
      (e) =>
        !e ||
        (10 * parseInt(e[0]) + parseInt(e[1]) > 12
          ? n.getInstance().messages[n.getInstance().locale].VALIDATION.RULES
              .CARD_EXPIRE
          : !isNaN(`${e[0]}${e[1]}${e[3]}${e[4]}`) ||
            n.getInstance().messages[n.getInstance().locale].VALIDATION.RULES
              .CARD_EXPIRE),
    );
  }
}

export default new _validator();
