<template>
  <div
    :class="[
      'c-base-editor',
      {
        'c-base-editor--transparent': isTransparent,
        'c-base-editor--disabled': disabled,
      },
    ]"
    v-if="editor"
  >
    <div v-if="label" class="c-base-editor__header">
      <FieldLabel class="c-field-input__label">
        {{ label }}
      </FieldLabel>
    </div>

    <div class="c-base-editor-stack">
      <div class="c-base-editor-stack__menu" v-if="showMenu">
        <span
          :class="[
            'stack-button-item',
            { 'stack-button-item--active': editor.isActive('bold') },
            'stack-button-item__bold',
          ]"
        >
          <button
            @click="editor.chain().focus().toggleBold().run()"
            :disabled="!editor.can().chain().focus().toggleBold().run()"
          >
            <BaseIcon name="base-editor-bold" size="1rem" />
          </button>
        </span>

        <span
          :class="[
            'stack-button-item',
            { 'stack-button-item--active': editor.isActive('italic') },
            'stack-button-item__italic',
          ]"
        >
          <button
            @click="editor.chain().focus().toggleItalic().run()"
            :disabled="!editor.can().chain().focus().toggleItalic().run()"
          >
            <BaseIcon name="base-editor-italic" size="1rem" />
          </button>
        </span>

        <span
          :class="[
            'stack-button-item',
            { 'stack-button-item--active': editor.isActive('underline') },
            'stack-button-item__underline',
          ]"
        >
          <button
            @click="editor.chain().focus().toggleUnderline().run()"
            :disabled="!editor.can().chain().focus().toggleUnderline().run()"
          >
            <BaseIcon name="base-editor-underline" size="1rem" />
          </button>
        </span>

        <span class="c-base-editor-stack__vertical-divider"></span>

        <span :class="['stack-button-item']">
          <button @click="editor.chain().focus().insertContent('@').run()">
            <BaseIcon name="at-symbol" size="1rem" />
          </button>
        </span>
      </div>
      <div class="c-base-editor-stack__editor" v-on:click="onFocusEditor">
        <editor-content :editor="editor" />
      </div>
    </div>
  </div>
</template>
<script>
import Document from "@tiptap/extension-document";
import Paragraph from "@tiptap/extension-paragraph";
import Bold from "@tiptap/extension-bold";
import Italic from "@tiptap/extension-italic";
import Underline from "@tiptap/extension-underline";
import Text from "@tiptap/extension-text";
import HardBreak from "@tiptap/extension-hard-break";
import Variable from "@talqui-oss/tiptap-extension-variable";
import { Editor, EditorContent, Extension, VueRenderer } from "@tiptap/vue-3";
import tippy from "tippy.js";
import FieldInputEditorVariables from "./FieldInputEditorVariables.vue";

const DEFAULT_VARIABLES = [
  { label: "Nome completo", value: "{{ contact.contactFullName }}" },
  { label: "Primeiro Nome", value: "{{ contact.contactFirstname }}" },
  { label: "Sobrenome", value: "{{ contact.contactLastname }}" },
  { label: "E-mail", value: "{{ contact.contactEmail }}" },
  { label: "Telefone", value: "{{ contact.contactPhone }}" },
];

export default {
  components: {
    EditorContent,
  },

  props: {
    modelValue: {
      type: [String, Object],
      default: "",
    },
    singleLine: {
      type: Boolean,
      default: false,
    },
    showMenu: {
      type: Boolean,
      default: true,
    },
    isTransparent: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  emits: ["update:modelValue"],

  data() {
    return {
      editor: null,
    };
  },

  watch: {
    modelValue(value) {
      const isSame =
        JSON.stringify(this.editor.getJSON()) === JSON.stringify(value);

      if (isSame) {
        return;
      }

      this.editor.commands.setContent(value, false);
    },
  },

  methods: {
    onFocusEditor() {
      this.editor.commands.focus();
    },
  },

  mounted() {
    const DisableEnter = Extension.create({
      addKeyboardShortcuts() {
        return {
          Enter: () => true,
        };
      },
    });
    var extensions = [
      Document,
      Paragraph,
      Text,
      Bold,
      Italic,
      Underline,
      HardBreak.extend({
        addKeyboardShortcuts() {
          return {
            Enter: () => this.editor.commands.setHardBreak(),
          };
        },
      }),
      ...(this.singleLine ? [DisableEnter] : []),
      Variable.extend({
        addOptions() {
          return {
            /**
             * Assert default options
             */
            ...this.parent?.(),

            /**
             * Custom items to suggestion of variables
             */
            customItems: ({ query }) => {
              return Object.values(DEFAULT_VARIABLES).filter((item) => {
                return item.label.toLowerCase().startsWith(query.toLowerCase());
              });
            },
          };
        },
      }).configure({
        suggestion: {
          render: () => {
            let component;
            let popup;

            return {
              onStart: (props) => {
                component = new VueRenderer(FieldInputEditorVariables, {
                  props,
                  editor: props.editor,
                });

                if (!props.clientRect) {
                  return;
                }

                popup = tippy("body", {
                  getReferenceClientRect: props.clientRect,
                  content: component.element,
                  showOnCreate: true,
                  interactive: true,
                  trigger: "manual",
                  placement: "bottom-start",
                });
              },

              onUpdate(props) {
                component.updateProps(props);

                if (!props.clientRect) {
                  return;
                }

                popup[0].setProps({
                  getReferenceClientRect: props.clientRect,
                });
              },

              onKeyDown(props) {
                if (props.event.key === "Escape") {
                  popup[0].hide();

                  return true;
                }

                return component?.ref?.onKeyDown(props);
              },

              onExit() {
                popup[0]?.destroy();
                component.destroy();
              },
            };
          },
        },
      }),
    ];
    this.editor = new Editor({
      extensions: extensions,
      content: this.modelValue,
      onUpdate: () => {
        // JSON
        this.$emit("update:modelValue", this.editor.getJSON());
      },
    });
    this.editor.commands.focus("end");
  },

  beforeUnmount() {
    this.editor.destroy();
  },
};
</script>
<style lang="scss">
.ProseMirror {
  &:focus {
    outline: none;
  }

  p {
    margin-bottom: 0px;
  }
}

.c-base-editor {
  display: flex;
  flex-direction: column;
  span {
    &.variable {
      white-space: nowrap;
      -webkit-font-smoothing: antialiased;
      user-select: none;
      -webkit-tap-highlight-color: transparent;
      box-sizing: inherit;
      display: inline-block;
      color: #fff;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
      cursor: pointer;
      border-radius: 2px;
      padding: 2px 4px;
      font-size: 12px;
      line-height: 16px;
      font-weight: 600;
      transform: translateY(-2px);
    }
  }
  .c-base-editor-stack {
    display: flex;
    flex-direction: column;
    flex: 1 1 0%;
    border-width: 1px;
    border-style: solid;
    border-color: #d6dde8;
    border-radius: 3px;
    position: relative;
    cursor: text;
    &__menu {
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      flex-direction: row;
      background-color: #ffffff;
      border-top-left-radius: 0.375rem;
      border-top-right-radius: 0.375rem;
      padding: 0.5rem;
      width: 100%;
      box-sizing: border-box;
      border: 0px;
      border-bottom-width: 1px;
      border-color: #e4e4e7;
      border-style: solid;
      .c-base-editor-stack__vertical-divider {
        height: 30px;
        width: 1px;
        background-color: #f4f4f5;
        display: block;
        margin-inline: 0.5rem 0px;
      }
      .stack-button-item {
        display: flex;
        background: #f4f4f5;
        border-radius: 0.375rem;
        transition-property: background-color, border-color, color, fill, stroke,
          opacity, box-shadow, transform;
        transition-duration: 200ms;
        &:hover {
          background: #e4e4e7;
        }
        button {
          background-color: transparent;
          display: flex;
          cursor: pointer;
          user-select: none;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: center;
          justify-content: center;
          vertical-align: middle;
          height: 32px;
          width: 32px;
          border-style: none;
          background-color: transparent;
          color: currentcolor;
          outline: transparent solid 2px;
          outline-offset: 2px;
          svg {
            display: block;
            height: 1rem;
            width: 1rem;
            line-height: 1em;
            flex-shrink: 0;
            color: currentcolor;
            fill: none;
            stroke: currentcolor;
          }
        }
        &:not(:first-of-type) {
          margin-top: 0px;
          margin-inline: 0.5rem 0px;
          margin-bottom: 0px;
        }
        &--active {
          color: #0042da !important;
        }
      }
    }
    &__editor {
      position: relative;
      outline: none;
      white-space: pre-wrap;
      overflow-wrap: break-word;
      flex: 1 1 0%;
      padding: 1rem;
      background-color: white;
      border-radius: 0.25rem;
    }
  }
  &--transparent {
    .c-base-editor-stack {
      border-color: transparent;
    }
    .c-base-editor-stack__editor {
      padding: 0rem;
    }
  }
}

.c-base-editor--disabled {
  .c-base-editor-stack,
  .c-base-editor__header {
    cursor: not-allowed;
    opacity: 0.35;
  }
}
</style>
