<template>
  <div
    :class="[
      'c-spotlight',
      'o-full-screen',
      { 'c-spotlight--no-history': history.length === 0 },
    ]"
    ref="root"
  >
    <div class="c-spotlight__container">
      <div class="c-spotlight__search">
        <BaseIcon
          class="c-spotlight__icon c-spotlight__icon--search"
          name="search"
          size="19px"
        ></BaseIcon>
        <input
          v-model.trim="search.query"
          @input="(...args) => onBaseKeydown && onBaseKeydown(...args)"
          :placeholder="this.$t('SPOTLIGHT.FORM_FIELD_PLACEHOLDER')"
          :class="['c-spotlight__field', { 'u-semibold': search.query }]"
          type="text"
          autocomplete="off"
          autocorrect="off"
          autocapitalize="off"
          maxlength="100"
          ref="input"
        />
        <a
          v-if="search.query.length > 0"
          class="c-spotlight__clear"
          @click="(...args) => onClear && onClear(...args)"
        >
          {{ this.$t("SPOTLIGHT.FORM_ACTIONS_CLEAR") }}
        </a>
        <span
          v-if="search.query.length > 0"
          class="c-spotlight__separator"
        ></span>
        <BaseIcon
          :class="[
            'c-spotlight__icon',
            'c-spotlight__icon--close',
            'u-has-tooltip',
          ]"
          @click="onClose"
          name="close"
          size="20px"
        >
          <BaseTooltip align="right" direction="bottom">
            {{ this.$t("TOOLTIPS.SPOTLIGHT_FORM_ACTIONS_CLOSE") }}
          </BaseTooltip>
        </BaseIcon>
      </div>
      <div class="c-spotlight__body">
        <div
          v-if="search.query.length === 0 && history.length > 0"
          class="c-spotlight__history"
        >
          <div class="c-spotlight__title">
            {{ this.$t("SPOTLIGHT.HISTORY_TITLE") }}
          </div>
          <SpotlightSearch
            v-for="e in history"
            :key="e.id"
            :onClick="onClickSelectedResult"
            :v-on:mouseenter="onHistoryMouseEnter(e)"
            :v-on:mouseleave="onHistoryMouseLeave(e)"
            :onForget="onForgetHistory(e)"
            :active="activeSearch"
            :id="e.id"
            :query="e.query"
          ></SpotlightSearch>
        </div>
        <div
          class="c-spotlight__inner"
          @click="(...args) => focusField && focusField(...args)"
          v-else-if="search.query.length > 0"
        >
          <ConversationTabs
            class="c-spotlight__tabs"
            :onClickTab="onTabClick"
            :active="filter.group || 'all'"
            :tabs="tabs"
          ></ConversationTabs>

          <div v-if="search.loading" class="c-spotlight__content">
            <div class="c-spotlight__loading">
              <BaseSpinner
                class="c-spotlight__spinner"
                size="18px"
                border-width="2px"
              ></BaseSpinner>
            </div>
          </div>

          <template v-else-if="results.count > 0">
            <div v-if="results.count > 0" class="c-spotlight__content">
              <div class="c-spotlight__results">
                <div class="c-spotlight__title">{{ countTitle }}</div>

                <div
                  class="c-spotlight__section"
                  v-for="group in displayedResultsGroups"
                  :key="group.name"
                >
                  <div class="c-spotlight__subtitle">
                    <BaseIcon
                      :class="[
                        'c-spotlight__icon',
                        'c-spotlight__icon--' + group.name,
                      ]"
                      :name="groupIcon[group.name]"
                    ></BaseIcon>
                    <span class="c-spotlight__label">{{
                      $t(
                        "SPOTLIGHT.RESULTS_SECTIONS_" +
                          group.name.toUpperCase(),
                      )
                    }}</span>
                  </div>
                  <router-link
                    v-for="(item, index) in group.items"
                    class="c-spotlight__result"
                    :to="generateResultPath(item, group.name)"
                    :key="item.id || index"
                    @click="onClickSearchResult"
                  >
                    <SpotlightResult
                      v-on:mouseenter="onResultMouseEnter"
                      v-on:mouseleave="onResultMouseLeave"
                      :active="activeResult"
                      :avatar="item.avatar"
                      :color="item.color"
                      :description="item.label"
                      :id="item.id || index"
                      :logo="item.logo"
                      :title="item.title"
                      :timestamp="item.timestamp"
                      :type="group.name"
                      :query="search.query"
                    ></SpotlightResult>
                  </router-link>
                  <div
                    v-if="results.pages[group.name] > -1"
                    class="c-spotlight__next"
                    @click="onLoadNextResults(group.name)"
                  >
                    <span class="c-spotlight__label">
                      {{
                        this.$t("SPOTLIGHT.RESULTS_CONTENTS_ITEMS_ACTIONS_NEXT")
                      }}
                    </span>
                    <BaseSpinner
                      v-if="results.paging[group.name]"
                      class="c-spotlight__spinner"
                      border-width="1px"
                      color="#75869c"
                      size="9px"
                    ></BaseSpinner>
                    <BaseIcon
                      v-else
                      class="c-spotlight__icon"
                      name="chevron_down"
                      size="15px"
                    ></BaseIcon>
                  </div>
                </div>
              </div>
              <!-- <div class="c-spotlight__filters">
                  <div class="c-spotlight__title">
                    {{ $t("SPOTLIGHT.RESULTS_CONTENTS_FILTERS_TITLE") }}
                  </div>
                  <div class="c-spotlight__filter">
                    <div class="c-spotlight__subtitle">
                      <span class="c-spotlight__label">{{
                        $t("SPOTLIGHT.RESULTS_CONTENTS_FILTERS_DATE_NAME")
                      }}</span>
                    </div>
                    <div class="c-spotlight__fields">
                      <div class="c-spotlight__group">
                        <div class="c-spotlight__subtitle">
                          {{
                            $t("SPOTLIGHT.RESULTS_CONTENTS_FILTERS_DATE_START")
                          }}
                        </div>
                        <field-date-picker
                          class="c-spotlight__filter"
                          v-model="filter.date.value.start"
                          @change="onDateChange"
                          size="small"
                        ></field-date-picker>
                      </div>
                      <div class="c-spotlight__group">
                        <div class="c-spotlight__subtitle">
                          {{
                            $t("SPOTLIGHT.RESULTS_CONTENTS_FILTERS_DATE_END")
                          }}
                        </div>
                        <field-date-picker
                          class="c-spotlight__filter"
                          v-model="filter.date.value.end"
                          @change="onDateChange"
                          size="small"
                        ></field-date-picker>
                      </div>
                      <div
                        v-if="filter.date.value.start || filter.date.value.end"
                        class="c-spotlight__clean"
                        @click="clearDate && clearDate()"
                      >
                        {{
                          $t("SPOTLIGHT.RESULTS_CONTENTS_FILTERS_DATE_CLEAR")
                        }}
                      </div>
                    </div>
                  </div>
                </div> -->
            </div>
            <div v-else class="c-spotlight__content">
              <div class="c-spotlight__empty">
                {{ $t("SPOTLIGHT.RESULTS_CONTENTS_EMPTY") }}
              </div>
            </div>
          </template>
        </div>
      </div>
      <div
        class="c-spotlight__footer"
        v-on:click="(...e) => focusField && focusField(...e)"
      >
        <BaseCommands />
      </div>
    </div>
    <BaseOverlay v-on:click="onClose" light="" />
  </div>
</template>
<script>
// var l = g(i("9829")); //class-operation
// var c = g(i("d682")); //class-helpdeskHelper
// var d = g(i("a13a")); //class-plugins
// var u = g(i("0315")); //class-analytics

import contact from "@/classes/talqui/contact";
import message from "@/classes/talqui/message";
import BaseToast from "@/components/Base/BaseToast.vue";
import pluginMixins from "@/mixins/plugins.js";
import SpotlightResult from "@/components/SpotlightResult.vue";
import SpotlightSearch from "@/components/SpotlightSearch.vue";
import ConversationTabs from "@/components/ConversationTabs.vue";

const b = 500;
// const m = 3e3;
const N = 125;
const f = 13;
const x = 27;
const h = 38;
const j = 40;

export default {
  components: {
    SpotlightResult,
    SpotlightSearch,
    ConversationTabs,
    // ConversationTabs: o.default,
    // SpotlightResult: n.default,
    // SpotlightSearch: r.default,
  },
  mixins: [pluginMixins],
  data() {
    return {
      isConstructed: true,
      helpdeskLocales: null,
      searchDispatchDeferTimeout: null,
      searchHistoryDeferTimeout: null,
      activeResult: null,
      activeSearch: null,
      filter: {
        group: "all",
        date: {
          value: {
            start: null,
            end: null,
          },
          valueFrozen: {
            start: null,
            end: null,
          },
          visible: {
            start: false,
            end: false,
          },
        },
      },
      search: {
        query: "",
        initializing: false,
        loading: false,
      },
      results: {
        count: 0,
        groups: {
          messages: [],
          contacts: [],
          helpdesk: [],
          plugins: [],
        },
        pages: {
          messages: -1,
          contacts: -1,
          helpdesk: -1,
          plugins: -1,
        },
        paging: {
          messages: false,
          contacts: false,
          helpdesk: false,
          plugins: false,
        },
      },
      groupIcon: {
        messages: "crispy",
        contacts: "people",
        helpdesk: "article",
        plugins: "plugin",
      },
      sections: [
        {
          id: "messages",
          title: this.$t("SPOTLIGHT.RESULTS_SECTIONS_MESSAGES"),
          results: [],
        },
        {
          id: "contacts",
          title: this.$t("SPOTLIGHT.RESULTS_SECTIONS_CONTACTS"),
          results: [],
        },
        {
          id: "helpdesk",
          title: this.$t("SPOTLIGHT.RESULTS_SECTIONS_HELPDESK"),
          results: [],
        },
        {
          id: "plugins",
          title: this.$t("SPOTLIGHT.RESULTS_SECTIONS_PLUGINS"),
          results: [],
        },
      ],
    };
  },
  computed: {
    displayedResultsGroups() {
      let e = [];
      for (let t in this.results.groups) {
        let i = this.results.groups[t];
        i.length > 0 &&
          ("all" === this.filter.group || this.filter.group === t) &&
          e.push({
            name: t,
            items: i,
          });
      }
      return e;
    },
    countTitle() {
      let e, t;
      return (
        this.filter.group && "all" !== this.filter.group
          ? ((e = this.results.groups[this.filter.group].length),
            (t =
              e > 1
                ? this.$t("SPOTLIGHT.RESULTS_CONTENTS_ITEMS_TITLE_PLURAL")
                : this.$t("SPOTLIGHT.RESULTS_CONTENTS_ITEMS_TITLE_SINGULAR")))
          : ((e = this.results.count),
            (t =
              e > 1
                ? this.$t("SPOTLIGHT.RESULTS_CONTENTS_ITEMS_TITLE_TOTAL_PLURAL")
                : this.$t(
                    "SPOTLIGHT.RESULTS_CONTENTS_ITEMS_TITLE_TOTAL_SINGULAR",
                  ))),
        `${e} ${t}`
      );
    },
    hotkeys() {
      // return {
      //   esc: this.onBaseKeydown,
      //   up: this.onBaseKeydown,
      //   down: this.onBaseKeydown,
      //   enter: this.onBaseEnter,
      // };
      return {};
    },
    tabs() {
      let e = [
        {
          id: "all",
          name: this.$t("SPOTLIGHT.RESULTS_SECTIONS_ALL"),
        },
      ];
      return (
        this.results.groups.messages.length > 0 &&
          e.push({
            id: "messages",
            name: this.$t("SPOTLIGHT.RESULTS_SECTIONS_MESSAGES"),
            icon: {
              name: this.groupIcon.messages,
              height: "12px",
              width: "16px",
            },
          }),
        this.results.groups.contacts.length > 0 &&
          e.push({
            id: "contacts",
            name: this.$t("SPOTLIGHT.RESULTS_SECTIONS_CONTACTS"),
            icon: {
              name: this.groupIcon.contacts,
              size: "20px",
            },
          }),
        this.results.groups.helpdesk.length > 0 &&
          e.push({
            id: "helpdesk",
            name: this.$t("SPOTLIGHT.RESULTS_SECTIONS_HELPDESK"),
            icon: {
              name: this.groupIcon.helpdesk,
              size: "13px",
            },
          }),
        this.results.groups.plugins.length > 0 &&
          e.push({
            id: "plugins",
            name: this.$t("SPOTLIGHT.RESULTS_SECTIONS_PLUGINS"),
            icon: {
              name: this.groupIcon.plugins,
              size: "14px",
            },
          }),
        e
      );
    },
    tenantID() {
      return this.$store.getters["tenantsTalqui/getTenantID"];
    },
    history() {
      // return this.$store.getters["spotlight/getHistory"](this.tenantID) || [];
      return [];
    },
  },
  watch: {
    "search.query": {
      handler(e, t) {
        t !== e && this.proceedSearch();
      },
    },
  },
  mounted() {
    this.initializeHistory();
    this.initializeSpotlight();
    this.focusField();
    // u.default.logEvent("Search opened");
  },
  unmounted() {
    this.isConstructed = false;
  },
  methods: {
    acquireHelpdeskLocales() {
      this.$log.log("@acquireHelpdeskLocales");
      return Promise.resolve([]);
      // return this.helpdeskLocales
      //   ? Promise.resolve(this.helpdeskLocales)
      //   : c.default
      //       .locales(this.tenantID)
      //       .get()
      //       .then((e) => Promise.resolve(e.map((e) => e.locale)))
      //       .catch(() => Promise.resolve([]))
      //       .then((e) => ((this.helpdeskLocales = e), Promise.resolve(e)));
    },

    applyCountResults() {
      this.results.count = 0;
      for (let e in this.results.groups) {
        this.results.count += this.results.groups[e].length;
      }
    },

    applyTypeResults(e, t, i = 1) {
      this.results.groups[e] = t.items || [];
      this.results.pages[e] = t.has_more ? i : -1;
      this.results.paging[e] = null;
    },

    clearDate() {
      this.filter.date.value.start = null;
      this.filter.date.value.end = null;
      this.filter.date.valueFrozen.start = null;
      this.filter.date.valueFrozen.end = null;
      this.proceedSearch(false);
    },

    clearQuery() {
      this.search.query = "";
      this.activeSearch = null;
      this.focusField();
    },

    dispatchSearch(e, t = 1) {
      if (e) {
        let i = this.generateDateFilter();
        this.activeResult = null;
        Promise.all([
          this.dispatchSearchMessages(e, t, i),
          this.dispatchSearchContacts(e, t, i),
          // this.dispatchSearchHelpdesk(e, t, i),
          // this.dispatchSearchPlugins(e, t, i),
        ]).then((e) => {
          // ["messages", "contacts", "helpdesk", "plugins"].forEach((t, i) => {
          ["messages", "contacts"].forEach((t, i) => {
            let a = e[i] || {};
            (a.items || []).length > 0 &&
              !this.activeResult &&
              this.setActiveFirstResult(t, a.items),
              this.applyTypeResults(t, a);
          });

          this.applyCountResults();
          this.search.loading = false;
        });
      }
    },

    // eslint-disable-next-line no-unused-vars
    dispatchSearchContacts(searchTerm, page = 1, filters = {}) {
      this.$log.log("@dispatchSearchContacts", searchTerm, page, filters);
      return searchTerm.length >= 1
        ? contact
            .contacts(this.tenantID)
            .get({
              page: page,
              perPage: 3,
              search: searchTerm,
              // ...(filters && { filters: qs.stringify(filters) }),
            })
            .then((res) => {
              return Promise.resolve({
                items: res.map((contact) => {
                  return {
                    id: contact.contactID,
                    title:
                      contact?.contactFirstname ||
                      contact?.contactMeta?.nickname ||
                      "",
                    label: contact?.contactPhone || contact?.contactEmail || "",
                    avatar: contact?.contactPhoto || null,
                    lastSessionID: contact?.contactLastSessionID || null,
                  };
                }),
                has_more: 206 === res.__status && true,
              });
            })
            .catch(() => Promise.resolve(null))
        : Promise.resolve(null);
    },

    dispatchSearchHelpdesk(e, t = 1, i = {}) {
      this.$log.log("@dispatchSearchHelpdesk", e, t, i);
      // return e.length >= I &&
      //   true === this.$pluginExists("urn:crisp.im:helpdesk:0")
      //   ? this.acquireHelpdeskLocales()
      //       .then((a) =>
      //         Promise.all(
      //           a.map((a) => this.dispatchSearchHelpdeskLocale(a, e, t, i))
      //         ).then((e) =>
      //           Promise.resolve({
      //             items: [].concat.apply(
      //               [],
      //               e.map((e) => (e || {}).items || [])
      //             ),
      //             has_more: !!e.find((e) => (e || {}).has_more || null),
      //           })
      //         )
      //       )
      //       .catch(() => Promise.resolve(null))
      //   : Promise.resolve(null);
    },

    dispatchSearchHelpdeskLocale(e, t, i = 1, a = {}) {
      console.log("@dispatchSearchHelpdeskLocale", e, t, i, a);
      return Promise.resolve(null);
      // return c.default
      //   .getLocaleArticles(
      //     this.tenantID,
      //     e,
      //     i,
      //     Object.assign(
      //       {
      //         search_title: t,
      //       },
      //       a
      //     )
      //   )
      //   .then((t) =>
      //     Promise.resolve({
      //       items: t.map((t) => {
      //         let i = this.$filters.locale.localeRead(e);
      //         return (
      //           (t.category || {}).name && (i = `${t.category.name} (${i})`),
      //           {
      //             id: t.article_id,
      //             title: t.title,
      //             label: i,
      //             color: (t.category || {}).color || null,
      //             locale: e,
      //           }
      //         );
      //       }),
      //       has_more: 206 === t.__status && true,
      //       locale: e,
      //     })
      //   )
      //   .catch(() => Promise.resolve(null));
    },

    dispatchSearchMessages(searchTerm, page = 1, filters = {}) {
      this.$log.log("@dispatchSearchMessages", searchTerm, page, filters);
      return searchTerm.length >= 1
        ? message
            .messageBase(this.tenantID)
            .get({
              page: page,
              perPage: 3,
              search: searchTerm,
              // ...(filters && { filters: qs.stringify(filters) }),
            })
            .then((res) => {
              return Promise.resolve({
                items: res.map((message) => ({
                  id: message.messageID,
                  title: message?.contact?.contactFirstname,
                  label: message.messageValue.replace(/\n/giu, " "),
                  avatar: message?.contact?.contactPhoto,
                  timestamp: new Date(message.createdAt).getTime(),
                  lastSessionID: message?.sessionID || null,
                })),
                has_more: 206 === res.__status && true,
              });
            })
            .catch(() => Promise.resolve(null))
        : Promise.resolve(null);

      // return Promise.resolve(null);
      // return e.length >= I
      //   ? l.default
      //       .conversations(this.tenantID, t)
      //       .get(
      //         Object.assign(
      //           {
      //             search_query: e,
      //             search_type: "text",
      //           },
      //           i
      //         )
      //       )
      //       .then((e) =>
      //         Promise.resolve({
      //           items: e.map((e) => ({
      //             id: e.session_id,
      //             title: (e.meta || {}).nickname,
      //             label: e.last_message || (e.meta || {}).email || e.session_id,
      //             avatar: (e.meta || {}).avatar || null,
      //             timestamp: e.updated_at,
      //           })),
      //           has_more: 206 === e.__status && true,
      //         })
      //       )
      //       .catch(() => Promise.resolve(null))
      //   : Promise.resolve(null);
    },

    dispatchSearchPlugins(e, t = 1, i = {}) {
      console.log("@dispatchSearchPlugins", e, t, i);
      return Promise.resolve(null);
      // return e.length >= I
      //   ? d.default
      //       .getPluginsSearch(
      //         t,
      //         Object.assign(
      //           {
      //             query: e,
      //             search_name: "1",
      //             search_description: "0",
      //             search_urn: "1",
      //           },
      //           i
      //         )
      //       )
      //       .then((e) =>
      //         Promise.resolve({
      //           items: e.map((e) => ({
      //             id: e.urn,
      //             title: e.name,
      //             label: e.summary,
      //             logo: e.icon,
      //           })),
      //           has_more: 206 === e.__status && true,
      //         })
      //       )
      //       .catch(() => Promise.resolve(null))
      //   : Promise.resolve(null);
    },
    focusField() {
      this.$refs.input.focus();
    },
    generateDateFilter() {
      let e = {};
      return (
        ["start", "end"].forEach((t) => {
          let i = this.filter.date.value[t];
          i &&
            (e["filter_date_" + t] = window.dateFns.format(
              i,
              "yyyy-MM-dd'T'HH:mm:ssxxxxx",
            ));
        }),
        e
      );
    },
    generateResultPath(e, t) {
      const conversationRouteName = this.$route.name.includes("reinvent")
        ? "app.website.inbox.conversation.reinvent"
        : "app.website.inbox.conversation";

      let i = {};
      if (e.lastSessionID) {
        switch (t) {
          case "messages":
            i = {
              name: conversationRouteName,
              params: {
                tenantID: this.tenantID,
                sessionId: e.lastSessionID,
              },
            };
            break;
          case "contacts":
            i = {
              name: conversationRouteName,
              params: {
                tenantID: this.tenantID,
                sessionId: e.lastSessionID,
              },
            };
            break;
          // case "helpdesk":
          //   i = {
          //     name: "app.website.helpdesk.article",
          //     params: {
          //       websiteId: this.tenantID,
          //       filter: "articles",
          //       locale: e.locale,
          //       articleId: e.id,
          //     },
          //   };
          //   break;
          // case "plugins":
          //   i = {
          //     name: "app.website.plugins.filter",
          //     params: {
          //       websiteId: this.tenantID,
          //       filter: "all",
          //     },
          //     query: {
          //       query: e.id,
          //     },
          //   };
          //   break;
        }
      }
      return i;
    },
    initializeHistory() {
      this.search.initializing = true;
      // this.$store
      //   .dispatch("spotlight/acquireHistory", {
      //     websiteId: this.tenantID,
      //   })
      //   .then(() => {
      //     this.search.initializing = false;
      //   });
      this.search.initializing = false;
    },
    initializeSpotlight() {
      // Promise.resolve()
      //   .then(() => Promise.resolve().then(i.bind(null, "6ff4")))
      //   .then((e) => {
      //     window.dateFns = e;
      //   });
    },
    proceedSearch(e = true) {
      this.search.loading = false;
      this.results.count = 0;

      for (let t in this.results.groups) {
        this.results.groups[t] = [];
        this.results.pages[t] = -1;
        this.results.paging[t] = null;
      }

      if (
        (true === e &&
          ((this.filter.group = "all"),
          (this.filter.date.value.start = null),
          (this.filter.date.value.end = null),
          (this.filter.date.valueFrozen.start = null),
          (this.filter.date.valueFrozen.end = null)),
        null !== this.searchDispatchDeferTimeout &&
          (clearTimeout(this.searchDispatchDeferTimeout),
          (this.searchDispatchDeferTimeout = null)),
        null !== this.searchHistoryDeferTimeout &&
          (clearTimeout(this.searchHistoryDeferTimeout),
          (this.searchHistoryDeferTimeout = null)),
        this.search.query)
      ) {
        this.search.loading = true;

        let t = this.search.query.toLowerCase();
        this.searchDispatchDeferTimeout = setTimeout(() => {
          this.dispatchSearch(t);
        }, b);

        // let e = this.search.query;
        // this.searchHistoryDeferTimeout = setTimeout(() => {
        //   this.$store.dispatch("spotlight/createHistoryEntry", {
        //     websiteId: this.tenantID,
        //     query: e,
        //   });
        // }, m);
      }
    },

    restoreHistory(e) {
      this.search.query = e.query;
      this.focusField();
    },

    selectPositionResult(e = 1) {
      let t = null;
      if (
        (this.search.query
          ? ((t = []),
            Object.values(this.results.groups).forEach((e) => {
              e.forEach((e) => {
                t.push(e);
              });
            }))
          : (t = this.history),
        null !== t)
      ) {
        let i = this.search.query ? this.activeResult : this.activeSearch,
          a = t.findIndex((e) => e.id === i),
          o = a + e;
        o < 0 ? (o = t.length - 1) : o >= t.length && (o = 0),
          o > -1 &&
            o !== a &&
            t[o] &&
            (this.search.query
              ? (this.activeResult = t[o].id)
              : (this.activeSearch = t[o].id),
            setTimeout(() => {
              let e = this.search.query
                  ? ".js-spotlight-result-active"
                  : ".js-spotlight-search-active",
                t = this.$refs.root.querySelector(e);
              t &&
                t.scrollIntoView({
                  behavior: "smooth",
                  block: "center",
                });
            }, 0));
      }
    },
    setActiveFirstResult(e, t) {
      this.activeResult = t[0].id;
    },
    onBaseKeydown(e) {
      let t = false;
      switch ((e.stopPropagation(), e.keyCode)) {
        case x:
          this.search.query ? this.clearQuery() : this.onClose(), (t = true);
          break;
        case f:
          this.onClickSelectedResult(), (t = true);
          break;
        case h:
          this.selectPositionResult(-1), (t = true);
          break;
        case j:
          this.selectPositionResult(1), (t = true);
          break;
      }
      true === t && e.preventDefault();
    },
    onClear() {
      this.clearQuery();
    },
    onClickSelectedResult() {
      if (this.search.query) {
        let e = null,
          t = null;
        for (e in this.results.groups)
          (t = this.results.groups[e].find((e) => e.id === this.activeResult)),
            e &&
              t &&
              (this.$router.push(this.generateResultPath(t, e)).catch(() => {}),
              this.onClickSearchResult());
      } else {
        let e = this.history.find((e) => e.id === this.activeSearch);
        e && this.restoreHistory(e);
      }
    },
    onClickSearchResult() {
      setTimeout(() => {
        true === this.isConstructed && this.onClose();
      }, N);
    },
    onClose() {
      this.$store.commit("common/setSpotlight", {
        value: false,
      });
    },
    onDateChange() {
      if (this.search.query) {
        let e = this.filter.date.value,
          t = this.filter.date.valueFrozen;
        ((e && e.start !== t.start) || e.end !== t.end) &&
          ((t.start = e.start), (t.end = e.end), this.proceedSearch(false));
      }
    },
    onBaseEnter(e) {
      if (
        document.activeElement &&
        document.activeElement.parentElement &&
        document.activeElement.parentElement.className &&
        document.activeElement.parentElement.className.includes(
          ".js-field-date-picker-field",
        )
      )
        return false;
      this.onBaseKeydown(e);
    },
    onForgetHistory(e) {
      this.$store
        .dispatch("spotlight/forgetHistoryEntry", {
          websiteId: this.tenantID,
          entry: e,
        })
        .then(() => {
          this.focusField();
        })
        .catch(() => {
          BaseToast.error("spotlight_forget_history_fail");
        });
    },
    onHistoryMouseEnter(e) {
      this.activeSearch = e.id;
    },
    onHistoryMouseLeave() {
      this.activeSearch = null;
    },
    onLoadNextResults(e) {
      if (true !== this.results.paging[e]) {
        let t = Date.now();
        this.results.paging[e] = t;
        let i = null;
        switch (e) {
          case "messages":
            i = this.dispatchSearchMessages;
            break;
          case "contacts":
            i = this.dispatchSearchContacts;
            break;
          case "helpdesk":
            i = this.dispatchSearchHelpdesk;
            break;
          case "plugins":
            i = this.dispatchSearchPlugins;
            break;
        }
        if (i) {
          let a = this.results.pages[e] + 1;
          i(this.search.query, a, this.generateDateFilter())
            .catch(() => Promise.resolve(null))
            .then((i) => {
              this.results.paging[e] === t &&
                ((this.results.paging[e] = null),
                i &&
                  i.items.length > 0 &&
                  (this.applyTypeResults(
                    e,
                    {
                      items: this.results.groups[e].concat(i.items),
                      has_more: i.has_more,
                    },
                    a,
                  ),
                  this.applyCountResults()));
            });
        }
      }
    },
    onResultMouseEnter(e) {
      this.activeResult = e;
    },
    onResultMouseLeave() {
      this.activeResult = null;
    },
    onTabClick(e) {
      this.filter.group = e;
    },
  },
};
</script>
<style lang="scss" scoped>
.c-spotlight {
  z-index: 1000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-top: 30px;
  -webkit-animation: fadeIn 0.1s linear;
  animation: fadeIn 0.1s linear;
  height: 100%;
  .c-spotlight__container {
    z-index: 1001;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 800px;
    height: 90%;
    border-radius: 20px;
    -webkit-box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
    color: #1c293b;
    font-size: 15px;
    -webkit-animation-name: fade-container;
    animation-name: fade-container;
    -webkit-animation-duration: 0.1s;
    animation-duration: 0.1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
    .c-spotlight__search {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      height: 57px;
      border-bottom: 1px solid #e3e8ef;
      background-color: #fff;
      .c-spotlight__icon {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        margin: 0 30px;
        fill: #2c405a;
        .c-base-tooltip[data-align="right"] {
          margin-right: 0;
          &:after {
            margin-right: 6px;
          }
        }
      }
      .c-spotlight__icon--search {
        margin-right: 16px;
      }
      .c-spotlight__icon--close {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin-left: 0;
        cursor: pointer;
      }
      .c-spotlight__field {
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        margin-right: 10px;
        height: 100%;
        outline: 0;
        border: none;
      }
      .c-spotlight__clear {
        color: #e0102b;
        text-decoration: underline;
        font-size: 13px;
      }
      .c-spotlight__separator {
        margin: 0 12px 0 14px;
        width: 1px;
        height: 15px;
        background-color: rgba(44, 64, 90, 0.1);
      }
    }
    .c-spotlight__body {
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
      background-color: #fff;
      .c-spotlight__history {
        .c-spotlight__title {
          text-transform: uppercase;
          font-weight: 700;
          font-size: 13px;
          padding: 5px 32px 10px;
        }
        overflow-x: hidden;
        overflow-y: auto;
        padding-top: 14px;
        max-height: 260px;
        &:after {
          display: block;
          height: 14px;
          content: "";
        }
      }
      .c-spotlight__inner {
        .c-spotlight__title {
          text-transform: uppercase;
          font-weight: 700;
          font-size: 13px;
        }
        .c-spotlight__tabs {
          padding: 0 33px;
          border-bottom: 1px solid #e3e8ef;
        }
        .c-spotlight__loading {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          padding: 44px 0;
          line-height: 0;
          .c-spotlight__spinner {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
            flex: 0 0 auto;
          }
        }
        .c-spotlight__empty {
          -webkit-box-flex: 1;
          -ms-flex: 1;
          flex: 1;
          padding: 38px 0;
          color: #75869c;
          text-align: center;
          font-weight: 700;
          font-style: italic;
          font-size: 14px;
        }
        .c-spotlight__content {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          overflow-x: hidden;
          overflow-y: auto;
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
          -ms-flex-direction: row;
          flex-direction: row;
          padding: 0 32px 0;
          max-height: 600px;
          background-color: #fafbfc;
          .c-spotlight__title {
            padding: 16px 0 14px;
          }
          .c-spotlight__subtitle {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            margin-bottom: 5px;
            color: #6e788b;
            font-weight: 700;
            font-size: 13px;
            .c-spotlight__icon {
              margin-right: 5px;
              fill: #6e788b;
              background-size: contain;
              background-repeat: no-repeat;
              background-position: 50%;
              display: inline-block;
            }
            .c-spotlight__icon--messages {
              margin-top: -2px;
              margin-right: 6px;
              width: 13px !important;
              height: 11px !important;
            }
            .c-spotlight__icon--contacts {
              margin-top: -2px;
              margin-right: 5px;
              width: 15px !important;
              height: 15px !important;
            }
            .c-spotlight__icon--helpdesk {
              margin-top: -2px;
              margin-right: 6px;
              width: 10px !important;
              height: 11px !important;
            }
            .c-spotlight__icon--plugins {
              margin-top: -2px;
              margin-right: 6px;
              width: 11px !important;
              height: 11px !important;
            }
          }
          .c-spotlight__loading {
            -webkit-box-flex: 1;
            -ms-flex: 1;
            flex: 1;
          }
          .c-spotlight__results {
            -webkit-box-flex: 1;
            -ms-flex: 1;
            flex: 1;
            margin-bottom: 20px;
            &:after {
              display: block;
              height: 20px;
              content: "";
            }
            .c-spotlight__section {
              margin-bottom: 20px;
              &:last-of-type {
                margin-bottom: 0;
              }
              .c-spotlight__result {
                margin-bottom: 6px;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                &:last-of-type {
                  margin-bottom: 0;
                }
              }
              .c-spotlight__next {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: end;
                -ms-flex-pack: end;
                justify-content: flex-end;
                margin-top: 8px;
                cursor: pointer;
                .c-spotlight__icon {
                  -webkit-box-flex: 0;
                  -ms-flex: 0 0 auto;
                  flex: 0 0 auto;
                  fill: rgba(117, 134, 156, 0.8);
                }
                .c-spotlight__label {
                  -webkit-box-flex: 0;
                  -ms-flex: 0 0 auto;
                  flex: 0 0 auto;
                  color: #0093ee;
                  text-decoration: underline;
                  font-weight: 700;
                  font-size: 13px;
                }
                .c-spotlight__spinner {
                  -webkit-box-flex: 0;
                  -ms-flex: 0 0 auto;
                  flex: 0 0 auto;
                  margin-top: -2px;
                  margin-bottom: -1px;
                  margin-left: 6px;
                }
              }
            }
          }
          .c-spotlight__filters {
            margin-bottom: 20px;
            -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
            flex: 0 0 auto;
            margin-left: 26px;
            width: 260px;
            .c-spotlight__fields {
              padding: 12px 16px 6px;
              border: 1px solid #e3e8ef;
              border-radius: 1px;
              background-color: #fff;
              .c-spotlight__group {
                margin-bottom: 12px;
                .c-spotlight__subtitle {
                  margin-bottom: 0;
                  font-size: 12px;
                }
              }
              .c-spotlight__clean {
                margin-top: 14px;
                margin-bottom: 4px;
                color: #e0102b;
                text-align: center;
                text-decoration: underline;
                font-size: 12.5px;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
    .c-spotlight__footer {
      z-index: 2;
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      padding: 10px 32px;
      border-top: 1px solid #e3e8ef;
      background-color: #fafbfc;
    }
  }
}
.c-spotlight--no-history {
  .c-spotlight__container {
    .c-spotlight__search {
      border-bottom: none;
    }
  }
}
@media (max-width: 52.5em) {
  .c-spotlight {
    .c-spotlight__container {
      width: 100%;
      border-radius: 0;
    }
  }
}
@media (max-width: 41.25em) {
  .c-spotlight {
    .c-spotlight__container {
      .c-spotlight__filters {
        display: none;
      }
    }
  }
}
@media (max-height: 52.5em) {
  .c-spotlight {
    padding-top: 24px;
    .c-spotlight__container {
      .c-spotlight__body {
        .c-spotlight__inner {
          .c-spotlight__content {
            max-height: 500px;
          }
        }
      }
    }
  }
}
@media (max-height: 46.25em) {
  .c-spotlight {
    padding-top: 20px;
    .c-spotlight__container {
      .c-spotlight__body {
        .c-spotlight__inner {
          .c-spotlight__content {
            max-height: 400px;
          }
        }
      }
    }
  }
}
@media (max-height: 40em) {
  .c-spotlight {
    padding-top: 0;
    .c-spotlight__container {
      .c-spotlight__search {
        padding-bottom: 2px;
      }
      .c-spotlight__body {
        overflow: hidden;
        height: 100%;
        .c-spotlight__history {
          max-height: 100%;
        }
        .c-spotlight__inner {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          height: 100%;
          .c-spotlight__content {
            max-height: none;
            height: 100%;
          }
        }
      }
    }
  }
}
</style>
