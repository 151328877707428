import a from "@/classes/stringHelper.js";
import o from "@/classes/http.js";
import n from "@/classes/auth.js";

class _uploadHelper {
  constructor() {
    (this.__pending = {
      urlAvatarGenerate: null,
      urlWebsiteGenerate: null,
      urlCampaignGenerate: null,
      urlProcessingGenerate: null,
      urlHelpdeskGenerate: {},
      urlStatusGenerate: {},
      urlUploadGenerate: {},
    }),
      (this.__listeners = {
        avatar: [],
        website: [],
        campaign: [],
        processing: [],
        helpdesk: [],
        status: [],
        upload: [],
      }),
      this.events();
  }
  urlGenerate() {
    return o.one("bucket/url/generate");
  }
  bucketUrlAvatarGenerate(e, t) {
    this.__bucketUrlSimpleGenerate("avatar", e, t).catch(() => {
      this.bucketUrlAvatarGenerated && this.bucketUrlAvatarGenerated({});
    });
  }
  bucketUrlWebsiteGenerate(e, t) {
    this.__bucketUrlSimpleGenerate("website", e, t).catch(() => {
      this.bucketUrlWebsiteGenerated && this.bucketUrlWebsiteGenerated({});
    });
  }
  bucketUrlUploadGenerate(e, t) {
    this.__bucketUrlMultipleGenerate("upload", e, t).catch(() => {
      this.bucketUrlUploadGenerated && this.bucketUrlUploadGenerated({});
    });
  }
  bucketUrlCampaignGenerate(e, t) {
    this.__bucketUrlSimpleGenerate("campaign", e, t).catch(() => {
      this.bucketUrlCampaignGenerated && this.bucketUrlCampaignGenerated({});
    });
  }
  bucketUrlHelpdeskGenerate(e, t) {
    this.__bucketUrlMultipleGenerate("helpdesk", e, t).catch(() => {
      this.bucketUrlHelpdeskGenerated && this.bucketUrlHelpdeskGenerated({});
    });
  }
  bucketUrlStatusGenerate(e, t) {
    this.__bucketUrlMultipleGenerate("status", e, t).catch(() => {
      this.bucketUrlStatusGenerated && this.bucketUrlStatusGenerated({});
    });
  }
  bucketUrlProcessingGenerate(e, t) {
    this.__bucketUrlSimpleGenerate("processing", e, t).catch(() => {
      this.bucketUrlProcessingGenerated &&
        this.bucketUrlProcessingGenerated({});
    });
  }
  addAvatarGenerateListener(e) {
    this.addGenericListener("avatar", e);
  }
  addWebsiteGenerateListener(e) {
    this.addGenericListener("website", e);
  }
  addCampaignGenerateListener(e) {
    this.addGenericListener("campaign", e);
  }
  addProcessingGenerateListener(e) {
    this.addGenericListener("processing", e);
  }
  addHelpdeskGenerateListener(e) {
    this.addGenericListener("helpdesk", e);
  }
  addStatusGenerateListener(e) {
    this.addGenericListener("status", e);
  }
  addUploadGenerateListener(e) {
    this.addGenericListener("upload", e);
  }
  removeAvatarGenerateListener(e) {
    this.removeGenericListener("avatar", e);
  }
  removeWebsiteGenerateListener(e) {
    this.removeGenericListener("website", e);
  }
  removeCampaignGenerateListener(e) {
    this.removeGenericListener("campaign", e);
  }
  removeProcessingGenerateListener(e) {
    this.removeGenericListener("processing", e);
  }
  removeHelpdeskGenerateListener(e) {
    this.removeGenericListener("helpdesk", e);
  }
  removeStatusGenerateListener(e) {
    this.removeGenericListener("status", e);
  }
  removeUploadGenerateListener(e) {
    this.removeGenericListener("upload", e);
  }
  addGenericListener(e, t) {
    this.__listeners[e].push(t);
  }
  removeGenericListener(e, t) {
    for (let i = 0; i < this.__listeners[e].length; i++)
      if (this.__listeners[e][i] === t) {
        this.__listeners[e].splice(i, 1);
        break;
      }
  }
  __triggerGenericListener(e, t) {
    for (let i = 0; i < this.__listeners[e].length; i++)
      this.__listeners[e][i](t);
  }
  events() {
    n.on("bucket:url:avatar:generated", (e) => {
      this.__pending.urlAvatarGenerate === e.id &&
        ((this.__pending.urlAvatarGenerate = null),
        this.__triggerGenericListener("avatar", e));
    }),
      n.on("bucket:url:website:generated", (e) => {
        this.__pending.urlWebsiteGenerate === e.id &&
          ((this.__pending.urlWebsiteGenerate = null),
          this.__triggerGenericListener("website", e));
      }),
      n.on("bucket:url:upload:generated", (e) => {
        !0 === this.__pending.urlUploadGenerate[e.id] &&
          (delete this.__pending.urlUploadGenerate[e.id],
          this.__triggerGenericListener("upload", e));
      }),
      n.on("bucket:url:campaign:generated", (e) => {
        this.__pending.urlCampaignGenerate === e.id &&
          ((this.__pending.urlCampaignGenerate = null),
          this.__triggerGenericListener("campaign", e));
      }),
      n.on("bucket:url:helpdesk:generated", (e) => {
        !0 === this.__pending.urlHelpdeskGenerate[e.id] &&
          (delete this.__pending.urlHelpdeskGenerate[e.id],
          this.__triggerGenericListener("helpdesk", e));
      }),
      n.on("bucket:url:status:generated", (e) => {
        !0 === this.__pending.urlStatusGenerate[e.id] &&
          (delete this.__pending.urlStatusGenerate[e.id],
          this.__triggerGenericListener("status", e));
      }),
      n.on("bucket:url:processing:generated", (e) => {
        this.__pending.urlProcessingGenerate === e.id &&
          ((this.__pending.urlProcessingGenerate = null),
          this.__triggerGenericListener("processing", e));
      });
  }
  __bucketUrlSimpleGenerate(e, t, i) {
    let o = "" + Date.now();
    return (
      (this.__pending[`url${a.uppercaseFirst(e)}Generate`] = o),
      (i.id = o),
      (i.namespace = e),
      (i.identifier = t),
      this.urlGenerate().post(i)
    );
  }
  __bucketUrlMultipleGenerate(e, t, i) {
    let o = "" + Date.now();
    return (
      (this.__pending[`url${a.uppercaseFirst(e)}Generate`][o] = !0),
      (i.id = o),
      (i.namespace = e),
      (i.identifier = t),
      this.urlGenerate().post(i)
    );
  }
}

export default new _uploadHelper();
