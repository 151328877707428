<template>
  <div :class="['c-base-notice', 'c-base-notice--' + color]">
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: "blue",
    },
  },
};
</script>
<style lang="scss">
.c-base-notice {
  position: relative;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 10px 20px;
  border: 1px solid rgba(117, 134, 156, 0.25);
  border-radius: 1px;
  color: #1c293b;
  text-align: left;
  font-size: 13.75px;
  line-height: 18px;
  p {
    margin-bottom: 0;
  }
  ul {
    margin-bottom: 0;
  }
  a {
    color: #0093ee;
    text-decoration: underline;
    font-weight: 700;
  }
  &:before {
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: -1px;
    width: 3px;
    border-top-left-radius: 1px;
    border-bottom-left-radius: 1px;
    content: " ";
  }
}
.c-base-notice--blue {
  border-color: rgba(117, 134, 156, 0.15);
  background-color: rgba(0, 147, 238, 0.06);
  &:before {
    background-color: #0079c4;
  }
}
.c-base-notice--grey {
  &:before {
    background-color: #75869c;
  }
}
.c-base-notice--red {
  border-color: rgba(117, 134, 156, 0.15);
  background-color: rgba(224, 16, 43, 0.06);
  &:before {
    background-color: #ee334b;
  }
}
</style>
