export default {
  computed: {
    session() {
      this.$store.commit("sessionsTalqui/assertSession", {
        tenantID: this.tenantID,
        sessionID: this.sessionID,
      });
      return this.$store.getters["sessionsTalqui/getSession"](
        this.tenantID,
        this.sessionID,
      );
    },
    sessionState() {
      /**
       * sessionStatus
       * 0 - Operator Pending - has unread messages by operator
       * 1 - Contact Pending - waiting response from contact
       *
       * hasUnreadMessages
       * true - Operator Pending - has unread messages by operator
       * false - Operator Pending or Contact Pending - message read by operator
       *
       * messageDirection
       * inbound - Operator Pending - waiting operator to response contact
       * outbound - Contact Pending - waiting contact to response operator
       */
      return this.session.sessionStatus === 0
        ? this.session._$.hasUnreadMessages
          ? "pending"
          : this.session.lastMessage.messageDirection === "inbound"
            ? "unresolved"
            : "resolved"
        : "resolved";
      // if (this.session.sessionActive) {
      //   if (this.session.sessionType === "auto") {
      //     //Conversa em triagem - sem nenhum marcador de coloração
      //     return "resolved";
      //   } else if (this.session.sessionType === "queued") {
      //     //Conversa aguardando atendimento humano - conversa em destaque azul (alterado para vermelho)
      //     return "pending";
      //   } else if (this.session.sessionType === "manual") {
      //     /**
      //      * Conversa em manual
      //      * 0 - Operator Pending - has unread messages by operators
      //      * 1 - Contact Pending - waiting response from contact
      //      *
      //      * Caso pendente do operador, se existirem mensagens não lidas
      //      * a conversa fica em destaque azul (alterado para vermelho), caso todas as últimas mensagens
      //      * foram lidas então somente a bolinha vermelha (alterado para amarelo) de conversa pendente
      //      * de resposta
      //      */
      //     return this.session.sessionStatus === 0
      //       ? this.session._$.hasUnreadMessages
      //         ? "pending"
      //         : "unresolved"
      //       : "resolved";
      //   }
      // } else {
      //   return "resolved";
      // }
    },
  },
};
