<template>
  <svg
    class="c-base-icon"
    :style="{
      cursor: cursor,
      fontSize: size,
      height: height || size,
      fill: color,
      color: color,
      width: width || size,
    }"
    :id="id"
    v-on:click="onClick"
    v-on:dblclick="onDoubleClick"
    v-on:mousedown="onMousedown"
    v-on:mouseenter="onMouseEnter"
    v-on:mouseleave="onMouseLeave"
    v-on:mousemove="onMouseMove"
    v-on:mouseout="onMouseOut"
    v-on:mouseover="onMouseOver"
    v-on:mouseup="onMouseup"
  >
    <use :xlink:href="href"></use>
  </svg>
</template>
<script>
export default {
  props: {
    color: { type: String, default: "" },
    cursor: {
      type: String,
      default: "inherit",
      validator(e) {
        return (
          -1 !==
          ["default", "inherit", "pointer", "wait", "not-allowed"].indexOf(e)
        );
      },
    },
    height: { type: String, default: null },
    id: { type: [String, Number], default: null },
    name: { type: String, required: true },
    size: { type: String, default: "24px" },
    width: { type: String, default: null },
  },
  computed: {
    href: {
      get() {
        return "#".concat(this.name);
      },
    },
  },
  methods: {
    onDoubleClick(e) {
      this.$emit("dblclick", this.id, e);
    },
    onMousedown(e) {
      this.$emit("mousedown", this.id, e);
    },
    onMouseEnter(e) {
      this.$emit("mouseenter", this.id, e);
    },
    onMouseLeave(e) {
      this.$emit("mouseleave", this.id, e);
    },
    onMouseMove(e) {
      this.$emit("mousemove", this.id, e);
    },
    onMouseOut(e) {
      this.$emit("mouseout", this.id, e);
    },
    onMouseOver(e) {
      this.$emit("mouseover", this.id, e);
    },
    onMouseup(e) {
      this.$emit("mouseup", this.id, e);
    },
  },
};
</script>
