<template>
  <div
    @click="(...args) => onClick && onClick(...args)"
    @mouseenter="(...args) => onMouseEnter && onMouseEnter(...args)"
    @mouseleave="(...args) => onMouseLeave && onMouseLeave(...args)"
    :class="[
      'c-spotlight-result',
      {
        'c-spotlight-result--active js-spotlight-result-active': active === id,
      },
    ]"
  >
    <BaseAvatar
      v-if="type === 'messages' || type === 'contacts'"
      :class="'c-spotlight-result__avatar'"
      :avatar-id="type === 'messages' ? id : null"
      :avatar-url="avatar"
      type="visitor"
      size="small"
    ></BaseAvatar>
    <BaseColor
      v-else-if="type === 'helpdesk'"
      :class="'c-spotlight-result__color'"
      :color="color || 'white'"
      size="large"
    ></BaseColor>
    <img
      v-else-if="type === 'plugins'"
      :class="'c-spotlight-result__logo'"
      :src="logo"
    />

    <div class="c-spotlight-result__container">
      <div class="c-spotlight-result__title-wrapper">
        <div
          class="c-spotlight-result__title u-bold"
          :innerHTML="
            $filters.format.parseHighlight(
              title,
              query,
              ['c-spotlight-result__match', 'u-bold'],
              highlightParseText,
            )
          "
        ></div>
        <div class="c-spotlight-result__date u-regular" v-if="timestamp">
          <!-- <div
            v-crisp-date="{
              date: timestamp,
              maintain: false,
              format: 'timeAgo',
            }"
          ></div> -->
        </div>
      </div>

      <div
        class="c-spotlight-result__description"
        :class="[
          'c-spotlight-result__description--secondary',
          {
            'c-spotlight-result__description--secondary':
              type === 'contacts' || type === 'helpdesk',
          },
        ]"
        v-html="
          $filters.format.parseHighlight(
            $filters.format.cutText(description, 100),
            query,
            ['c-spotlight-result__match', 'u-semibold'],
            highlightParseText,
          )
        "
        v-if="description"
      ></div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    active: {
      type: [String, Number],
      default: null,
    },
    avatar: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    id: {
      type: [String, Number],
      required: !0,
    },
    logo: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      required: !0,
    },
    type: {
      type: String,
      required: !0,
      validator(e) {
        return (
          -1 !== ["messages", "contacts", "helpdesk", "plugins"].indexOf(e)
        );
      },
    },
    timestamp: {
      type: Number,
      default: null,
    },
    query: {
      type: String,
      required: !0,
    },
  },
  emits: ["click", "mouseenter", "mouseleave"],
  onMounted() {
    console.log(this);
  },
  computed: {
    highlightParseText() {
      return "messages" === this.type;
    },
  },
  methods: {
    onClick() {
      this.$emit("click", this.id);
    },
    onMouseEnter() {
      this.$emit("mouseenter", this.id);
    },
    onMouseLeave() {
      this.$emit("mouseleave", this.id);
    },
  },
};
</script>
<style lang="scss" scoped>
.c-spotlight-result {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  padding: 10px 36px 10px 26px;
  border: 1px solid #e3e8ef;
  border-radius: 1px;
  background-color: #fff;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
  -webkit-transition-property:
    -webkit-transform,
    -webkit-box-shadow;
  transition-property:
    -webkit-transform,
    -webkit-box-shadow;
  transition-property: transform, box-shadow;
  transition-property:
    transform,
    box-shadow,
    -webkit-transform,
    -webkit-box-shadow;
  cursor: pointer;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  .c-spotlight-result__avatar {
    margin-right: 20px;
    margin-top: 3px;
  }
  .c-spotlight-result__color {
    margin-right: 20px;
    margin-top: 2px;
  }
  .c-spotlight-result__logo {
    margin-right: 20px;
    -ms-flex-item-align: start;
    align-self: flex-start;
    margin-top: 3px;
    width: 32px;
    height: 32px;
    border-radius: 4px;
  }
  .c-spotlight-result__container {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-hyphens: auto;
    hyphens: auto;
    word-break: break-word;
    .c-spotlight-result__title-wrapper {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }
    .c-spotlight-result__title {
      color: #1c293b;
      font-size: 14px;
      line-height: 18px;
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
    }
    .c-spotlight-result__date {
      font-size: 12px;
      line-height: 18px;
      padding-left: 8px;
      color: #75869c;
    }
    .c-spotlight-result__description {
      margin-top: 3px;
      color: #2c405a;
      font-size: 13px;
      line-height: 16px;
      pointer-events: none;
    }
    .c-spotlight-result__description--secondary {
      color: #75869c;
    }
    .c-spotlight-result__match {
      display: inline-block;
      background-color: #fff5cc;
    }
  }
}
.c-spotlight-result--active {
  border-color: #0093ee;
  &:active {
    -webkit-box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.02);
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.02);
    -webkit-transform: translateY(1px);
    transform: translateY(1px);
  }
}
</style>
