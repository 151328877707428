<template>
  <div
    :class="['c-base-dot', 'c-base-dot--' + color, 'c-base-dot--' + size]"
  ></div>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: "green",
    },
    size: {
      type: String,
      default: "default",
    },
  },
};
</script>
<style lang="scss">
.c-base-dot {
  display: inline-block;
  border-radius: 100%;
}
.c-base-dot--black {
  background-color: #3f536e;
}
.c-base-dot--blue {
  background-color: #0093ee;
}
.c-base-dot--green {
  background-color: #40b630;
}
.c-base-dot--red {
  background-color: #e0102b;
}
.c-base-dot--white {
  background-color: #fff;
}
.c-base-dot--yellow {
  background-color: #faca00;
}
.c-base-dot--mini {
  width: 4px;
  height: 4px;
}
.c-base-dot--small {
  width: 6px;
  height: 6px;
}
.c-base-dot--default {
  width: 8px;
  height: 8px;
}
.c-base-dot--medium {
  width: 10px;
  height: 10px;
}
.c-base-dot--large {
  width: 12px;
  height: 12px;
}
</style>
