<template>
  <a class="c-base-link" :href="href" :target="target" :rel="rel">
    <slot></slot>
  </a>
</template>
<script>
export default {
  props: {
    href: {
      type: String,
      required: true,
    },
    blank: {
      type: Boolean,
      default: true,
    },
    concealOrigin: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    target() {
      return true === this.blank ? "_blank" : "_self";
    },
    rel() {
      return true === this.concealOrigin ? "noopener noreferrer" : null;
    },
  },
};
</script>
