var a = {
  "./en": [require("./locale-en.js"), "locale-en"],
  "./en.json": [require("./locale-en.js"), "locale-en"],
  "./pt": [require("./locale-pt-br.js"), "locale-pt-br"],
  "./pt.json": [require("./locale-pt-br.js"), "locale-pt-br"],
  "./pt-br": [require("./locale-pt-br.js"), "locale-pt-br"],
  "./pt-br.json": [require("./locale-pt-br.js"), "locale-pt-br"],
};

function o(e) {
  // eslint-disable-next-line no-prototype-builtins
  if (!Object(a).hasOwnProperty(e)) {
    return Promise.resolve().then(function () {
      var t = new Error("Cannot find module '" + e + "'");
      throw ((t.code = "MODULE_NOT_FOUND"), t);
    });
  }

  var t = a[e];
  return new Promise((resolve) => {
    resolve(t[0]);
  });
}

o.keys = function () {
  return Object.keys(a);
};

module.exports = o;
