<template>
  <div
    @click="(...args) => onClick && onClick(...args)"
    @mouseenter="(...args) => onMouseEnter && onMouseEnter(...args)"
    @mouseleave="(...args) => onMouseLeave && onMouseLeave(...args)"
    :class="[
      'c-spotlight-search',
      {
        'c-spotlight-search--active js-spotlight-search-active': active === id,
      },
    ]"
  >
    <BaseIcon
      class="c-spotlight-search__icon"
      name="restore"
      size="18px"
    ></BaseIcon>
    <span class="c-spotlight-search__query u-bold u-ellipsis">{{ query }}</span>
    <span
      class="c-spotlight-search__forget"
      @click.stop.prevent="(...args) => onForget && onForget(...args)"
    >
      {{ $t("SPOTLIGHT.HISTORY_ITEM_FORGET") }}
    </span>
  </div>
</template>
<script>
export default {
  props: {
    active: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
    query: {
      type: String,
      default: null,
    },
  },
  emits: ["click", "forget", "mouseenter", "mouseleave"],
  methods: {
    onClick() {
      this.$emit("click", this.query);
    },
    onForget() {
      this.$emit("forget", this.id);
    },
    onMouseEnter() {
      this.$emit("mouseenter", this.id);
    },
    onMouseLeave() {
      this.$emit("mouseleave", this.id);
    },
  },
};
</script>
<style lang="scss" scoped>
.c-spotlight-search {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 8px 32px 9px;
  color: #2c405a;
  font-size: 13.5px;
  cursor: pointer;
  .c-spotlight-search__icon {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    margin-right: 11px;
    fill: rgba(44, 64, 90, 0.8);
  }
  .c-spotlight-search__query {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .c-spotlight-search__forget {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    margin-left: 8px;
    opacity: 0;
  }
}
.c-spotlight-search--active {
  background-color: #0093ee;
  color: #fff;
  .c-spotlight-search__icon {
    fill: hsla(0, 0%, 100%, 0.8);
  }
  .c-spotlight-search__forget {
    text-decoration: underline;
    font-weight: 700;
    font-size: 13px;
    opacity: 0.8;
    &:hover {
      opacity: 1;
    }
  }
  &:active {
    background-color: #008adf;
    -webkit-transition: background-color 50ms linear;
    transition: background-color 50ms linear;
  }
}
</style>
