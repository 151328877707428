import o from "@/classes/helpers.js";
import s from "@/classes/stringHelper.js";

import filters from "@/classes/filters";
import store from "@/store/";
import tenant from "@/classes/talqui/tenant";

const u = "Others";
const M = 3;
const g = /^(?:(\S+)(?:\s+(.+))?)?/;
const REGEX_REPLACE_IDENTIFY =
  /{{\s*([a-zA-Z.-_]*)(?:\s*\|\s*"([^"]*)")?\s*}}/gim;
const b = /[.,;:()!?]/g;

class _shortcuts {
  constructor() {
    this.__db = {};
    this.__sync = {
      tenantID: null,
      lastPage: 0,
      loading: false,
    };
    this.__pendingQuery = "";
    this.__shortcutsReplacementsPlaceholders = {
      // sessionID: [this.__fillShortcutWithSessionId],
      // email: [this.__fillShortcutWithEmail],
      // "name.full": [this.__fillShortcutWithFullName],
      // "name.first": [this.__fillShortcutWithFirstName],
      // "name.last": [this.__fillShortcutWithLastName],
      // country: [this.__fillShortcutWithCountry],
      // city: [this.__fillShortcutWithCity],
      // website: [this.__fillShortcutWithWebsite],
      // "data.": [this.__fillShortcutWithData],
      // "company.name": [this.__fillShortcutWithCompanyName],
      greeting: [this.__fillShortcutWithGreeting],
      "operator.full": [this.__fillShortcutWithOperatorFullName],
      "operator.first": [this.__fillShortcutWithOperatorFirstName],
      "operator.last": [this.__fillShortcutWithOperatorLastName],
    };
  }

  // eslint-disable-next-line no-unused-vars
  getShortcuts(e, t) {
    // return n.websiteOne(e).one("shortcutsTalqui/list", t).get();
  }

  load(tenantID, t = true, i = false) {
    return this.__ensureInitialized(tenantID, t, i);
  }

  all(tenantID, t = true, i = false) {
    return this.__ensureInitialized(tenantID, t, i).then(() => {
      this.__tickRefresh();
    });
  }

  lookup(e, t) {
    return this.__ensureInitialized(e).then(() => {
      let e;
      let i = [];
      let a = {};
      let n = this.__normalizeText((t || "").slice(1));
      let r = o.fuzzySearchRegex(t, ["!"]);
      let s = ["bang"];
      return (
        n.length >= M && s.push("text"),
        s.forEach((t) => {
          for (let o in this.__db)
            true !== a[o] &&
              ((e = this.__db[o]),
              true !== e.disabled &&
                (("bang" === t && true === r.test(e.bang)) ||
                  ("text" === t && true === e.maps.text[n])) &&
                ((a[o] = true), i.push(e)));
        }),
        store.commit("shortcutsTalqui/updateResults", {
          value: i,
        }),
        Promise.resolve()
      );
    });
  }

  updatePendingQuery(e = "") {
    let t = e.toLowerCase().trim();
    let i = t.match(g);

    this.__pendingQuery = i[1] || "";
  }

  getPendingQuery() {
    return this.__pendingQuery;
  }

  add(tenantID, bang, text, tag = null, disabled = null) {
    return this.__ensureInitialized(tenantID).then(() => {
      let payload = this.__generate(bang, text, tag, disabled);
      return tenant
        .tenantOne(tenantID)
        .one("operators")
        .one("shortcuts")
        .post(payload)
        .then((res) => {
          payload.shortcutID = res.data.shortcut.shortcutID;
          this.__store(payload);
          this.__tickRefresh();
          return Promise.resolve();
        });
    });
  }

  update(tenantID, t, i, a, o = null, r = null) {
    return this.__ensureInitialized(tenantID).then(() => {
      let s = this.__generate(i, a, o, r);
      return tenant
        .tenantOne(tenantID)
        .one("shortcut", t)
        .patch(s)
        .then(() => ((s.shortcut_id = t), this.__store(s), Promise.resolve()));
    });
  }

  delete(tenantID, shortcutID) {
    return this.__ensureInitialized(tenantID).then(() =>
      tenant
        .tenantOne(tenantID)
        .one("operators")
        .one("shortcuts", shortcutID)
        .delete()
        .then(() => {
          this.__db[shortcutID] && delete this.__db[shortcutID];
          this.__tickRefresh();
        })
        .finally(() => {
          return Promise.resolve();
        }),
    );
  }

  report(tenantID, t, i) {
    return tenant.tenantOne(tenantID).one("shortcut", t).one("report").post({
      action: i,
    });
  }

  fillWithData(tenantID, sessionID, shortcutText) {
    let $session = store.getters["sessionsTalqui/getSessionReference"](
      tenantID,
      sessionID,
    );

    var shortcutTextWithData = shortcutText.replace(
      REGEX_REPLACE_IDENTIFY,
      (e, _key, _defaultValue) => {
        let o = _key;

        if (true === o.startsWith("data.")) {
          o = "data.";
        }

        if (o) {
          let _replacementFunction =
            this.__shortcutsReplacementsPlaceholders[o];
          if (_replacementFunction) {
            let __fn = _replacementFunction[0];
            let n = __fn.bind(this)($session, _key);

            return !n && _defaultValue && (n = _defaultValue), n;
          }
        }

        return e;
      },
    );

    return shortcutTextWithData;
  }

  reset() {
    this.__db = {};
    this.__sync = {
      websiteId: null,
      lastPage: 0,
      loading: false,
    };
  }

  __clear() {
    let e = Object.keys(this.__db);
    e.forEach((e) => {
      delete this.__db[e];
    }),
      (this.__sync.last_page = 0);
  }

  __store(shortcut) {
    shortcut.shortcutTag = filters.capitalize(shortcut.shortcutTag || u);

    let t = {};
    this.__normalizeText(shortcut.shortcutText)
      .replace(b, " ")
      .split(" ")
      .forEach((e) => {
        (e || "").length >= M && (t[e] = true);
      });

    shortcut.maps = {
      text: t,
    };
    this.__db[shortcut.shortcutID] = shortcut;

    this.__tickRefresh();
  }

  __tickRefresh() {
    store.commit("shortcutsTalqui/updateResults", {
      value: this.__db,
    });
  }

  __generate(bang, text, tag = null, disabled = null) {
    let payload = {
      shortcutBang: bang,
      shortcutText: text,
    };

    null !== tag && (payload.shortcutTag = filters.capitalize(tag));
    null !== disabled && (payload.disabled = disabled);
    return payload;
  }

  __normalizeText(e = "") {
    return s.normalize(e);
  }

  __ensureInitialized(tenantID, t = true, i = false) {
    if (tenantID !== this.__sync.tenantID) {
      this.__clear();
      this.__sync.tenantID = tenantID;
      this.__sync.loading = true;
      store.commit("shortcutsTalqui/setLoading", {
        value: true,
      });

      return this.__acquireNextPage(tenantID, t)
        .then(() => {
          store.commit("shortcutsTalqui/setLoading", {
            value: false,
          });
          this.__sync.loading = false;
          return Promise.resolve();
        })
        .catch((e) => {
          store.commit("shortcutsTalqui/setLoading", {
            value: false,
          });
          this.__sync.loading = false;
          return Promise.reject(e);
        });
    } else if (true === this.__sync.loading && true === i) {
      return Promise.reject();
    } else {
      return Promise.resolve();
    }
  }

  __acquireNextPage(tenantID, loadAll = true) {
    let _page = ++this.__sync.lastPage;
    return tenant
      .tenantOne(tenantID)
      .one("operators")
      .one("shortcuts")
      .get({ page: _page, perPage: 50 })
      .then((res) => {
        if (res.length > 0) {
          res.forEach((shortcut) => {
            this.__store(shortcut);
          });

          if (206 === res.__status) {
            this.__acquireNextPage(tenantID, loadAll);
          } else {
            this.__tickRefresh();
            return Promise.resolve();
          }
        }
      });
  }

  __fillShortcutWithSessionId(e) {
    return e.session_id;
  }

  __fillShortcutWithEmail(e) {
    return e.meta.email;
  }

  __fillShortcutWithFullName(e) {
    let t = e.meta.nickname;
    return t.startsWith("visitor") ? "" : t;
  }

  __fillShortcutWithFirstName(e) {
    let t = this.__fillShortcutWithFullName(e);
    return filters.parseFirstName(t);
  }

  __fillShortcutWithLastName(e) {
    let t = this.__fillShortcutWithFullName(e);
    return filters.parseLastName(t);
  }

  __fillShortcutWithCountry(e) {
    return e.meta &&
      e.meta.device &&
      e.meta.device.geolocation &&
      e.meta.device.geolocation.country
      ? filters.isoCountry(e.meta.device.geolocation.country)
      : "";
  }

  __fillShortcutWithCity(e) {
    return e.meta &&
      e.meta.device &&
      e.meta.device.geolocation &&
      e.meta.device.geolocation.city
      ? e.meta.device.geolocation.city
      : "";
  }

  __fillShortcutWithWebsite(e) {
    return e.identity && e.identity.company && e.identity.company.domain
      ? e.identity.company.domain
      : "";
  }

  __fillShortcutWithCompanyName(e) {
    return e.identity && e.identity.company && e.identity.company.name
      ? e.identity.company.name
      : "";
  }

  __fillShortcutWithData(e, t = "") {
    let i = t.replace("data.", "");
    return e.contact_data && e.contact_data[i]
      ? e.contact_data[i]
      : e.meta && e.meta.data
      ? e.meta.data[i]
      : "";
  }

  __fillShortcutWithGreeting() {
    const currentHour = new Date().getHours();
    if (currentHour >= 4 && currentHour < 12) {
      return "Bom dia";
    } else if (currentHour >= 12 && currentHour < 18) {
      return "Boa tarde";
    } else {
      return "Boa noite";
    }
  }

  __fillShortcutWithOperatorFullName() {
    let e = store.getters["operatorsTalqui/getMe"];
    return `${e.operatorFirstname} ${e.operatorLastname}`;
  }

  __fillShortcutWithOperatorFirstName() {
    let e = store.getters["operatorsTalqui/getMe"];
    return e.operatorFirstname;
  }

  __fillShortcutWithOperatorLastName() {
    let e = store.getters["operatorsTalqui/getMe"];
    return e.operatorLastname;
  }
}

export default new _shortcuts();
