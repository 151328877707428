class _audioHelper {
  constructor() {
    this.inCall = !1;
    this.audioContext = null;
    this.lastAudio = null;
    this.htmlAudioFormat = null;
    this.sounds = {};
    this.load();
  }
  load() {
    try {
      this.htmlAudioFormat = this.detectHtmlAudioSupport();
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }
  play(e, t, inLoop = !1, gain = 50) {
    this.htmlAudioFormat &&
      this.canPlayNow(e) &&
      (this.stop(),
      this.fetch(`${t}.${this.htmlAudioFormat}`, (e) => {
        if (null !== this.audioContext) {
          "suspended" === this.audioContext.state && this.audioContext.resume();
          let t = this.audioContext.createGain();
          t.gain.setValueAtTime(gain / 100, 0),
            t.connect(this.audioContext.destination),
            (this.lastAudio = this.audioContext.createBufferSource()),
            (this.lastAudio.buffer = e),
            (this.lastAudio.loop = inLoop),
            this.lastAudio.connect(t),
            this.lastAudio.start();
        }
      }));
  }
  stop() {
    try {
      if (null !== this.lastAudio) {
        this.lastAudio.stop();
        this.lastAudio = null;
      }
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }
  fetch(e, t) {
    if (this.sounds[e]) return t(this.sounds[e]);
    let i = new XMLHttpRequest();
    i.open("GET", e, !0),
      (i.responseType = "arraybuffer"),
      (i.onload = () => {
        null === this.audioContext &&
          (this.audioContext = this.initiateAudioContext()),
          null !== this.audioContext &&
            this.audioContext.decodeAudioData(
              i.response,
              (i) => ((this.sounds[e] = i), t(i)),
            );
      }),
      i.send();
  }
  canPlayNow(e) {
    let t = true;
    try {
      let _isCallSound = "call" === e || true !== this.inCall;
      t = _isCallSound && !0;
      // eslint-disable-next-line no-empty
    } catch (i) {
    } finally {
      // eslint-disable-next-line no-unsafe-finally
      return t;
    }
  }
  initiateAudioContext() {
    try {
      return "function" === typeof AudioContext ? new AudioContext() : null;
    } catch (e) {
      return null;
    }
  }
  detectHtmlAudioSupport() {
    let e = null;
    try {
      let t = document.createElement("audio"),
        i = !(
          !t.canPlayType ||
          !t.canPlayType('audio/ogg; codecs="vorbis"').replace("no", "")
        ),
        a = !(
          !t.canPlayType ||
          !t.canPlayType('audio/mp4; codecs="mp4a.40.2"').replace("no", "")
        ),
        o = !(
          !t.canPlayType || !t.canPlayType("audio/mpeg;").replace("no", "")
        );
      !0 === i ? (e = "oga") : !0 === a ? (e = "  ") : !0 === o && (e = "mp3");
      // eslint-disable-next-line no-empty
    } catch (t) {
    } finally {
      // eslint-disable-next-line no-unsafe-finally
      return e;
    }
  }
}

export default new _audioHelper();
