import { createRouter, createWebHistory } from "vue-router";
import resolvers from "@/router/_resolvers";
import language from "@/classes/language";
import classAuth from "@/classes/talqui/auth.js";
import routerBuilder from "@/classes/talqui/routerBuilder";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  linkActiveClass: "is-active",
  linkExactActiveClass: "is-exact-active",
  routes: [
    routerBuilder.build({
      path: "/initiate",
      name: "initiate",
      component: () => import("@/views/initiate/initiate-index.vue"),
      hook: ({ next }) => {
        window.APP_LOADED_CALLBACK();
        next();
      },
      children: [
        routerBuilder.build({
          path: "/initiate/logout/",
          name: "initiate.logout",
          component: () => import("@/views/DummyView.vue"),
          resolvers: {
            logout: resolvers.logout,
          },
          successFn: () => {
            router.push({
              name: "initiate.login",
            });
          },
        }),
        routerBuilder.build({
          path: "/initiate/login/",
          name: "initiate.login",
          component: () => import("@/views/reinvent/initiate/login.vue"),
          hook: ({ next }) => {
            next();
          },
        }),
        routerBuilder.build({
          path: "/initiate/signup/",
          name: "initiate.signup",
          component: () => import("@/views/initiate/signup.vue"),
          hook: ({ next }) => {
            next();
          },
        }),
        routerBuilder.build({
          path: "/initiate/recover/",
          name: "initiate.recover",
          component: () =>
            import("@/views/initiate/recover/recover-request.vue"),
          hook: ({ next }) => {
            next();
          },
        }),
        routerBuilder.build({
          path: "/initiate/recover/:recoverID/",
          name: "initiate.recover.changepass",
          component: () =>
            import("@/views/initiate/recover/recover-change-pass.vue"),
          hook: ({ next }) => {
            next();
          },
        }),
        routerBuilder.build({
          path: "/initiate/operator/",
          name: "initiate.operator",
          component: () => import("@/views/initiate/operator.vue"),
        }),
        routerBuilder.build({
          path: "/initiate/plugin/:pluginID/",
          name: "initiate.plugin",
          resolvers: {
            me: resolvers.me,
            tenants: resolvers.tenants,
          },
          component: () => import("@/views/initiate/plugin.vue"),
        }),
        // routerBuilder.build({
        //   path: "/initiate/offline/:requestId?/",
        //   name: "initiate.offline",
        //   component: D,
        // }),
      ],
    }),
    routerBuilder.build({
      path: "/",
      name: "app",
      component: () => import("@/views/app/app-index.vue"),
      resolvers: {
        // defaultWebsite: resolverApp.defaultWebsite,
        // me: resolverApp.me,
        // schedule: resolverApp.schedule,
        // subscriptions: resolverApp.subscriptions,
        // websites: resolverApp.websites,
      },
      successFn: ({ to, next }) => {
        if ("app" !== to.name) {
          return next();
        }
        router
          .push({
            name: "app.website-redirect",
          })
          .then(() => {
            next();
          });
      },
      children: [
        routerBuilder.build({
          path: "tenant/",
          name: "app.website-redirect",
          resolvers: {
            defaultWebsiteRedirect: resolvers.defaultWebsiteRedirect,
          },
        }),
        routerBuilder.build({
          name: "app.website",
          path: "tenant/:tenantID/",
          component: () => import("@/views/app/website/index.vue"),
          resolvers: {
            tenantID: resolvers.tenantID,
            tenants: resolvers.tenants,
            me: resolvers.me,
            operators: resolvers.operators,
            pluginsConnections: resolvers.pluginsConnections,
            socket: resolvers.socket,
            notification: resolvers.notification,
            subscription: resolvers.subscription,
            tenantSessionMotives: resolvers.tenantSessionMotives,
            // currentWebsite: resolverApp.currentWebsite,
            // websiteSettings: store.websiteSettings,
            // websitePlan: store.websitePlan,
            // websiteBilling: store.websiteBilling,
            // websiteRoutingSettings: store.websiteRoutingSettings,
            // websiteRoutingRules: store.websiteRoutingRules,
            // websiteHooks: store.websiteHooks,
            // websiteChannelEmailDomain: store.websiteChannelEmailDomain,
            // websiteChannelEmailSignature:
            //   store.websiteChannelEmailSignature,
            // websiteChannelEmailIp: store.websiteChannelEmailIp,
            // websiteChannelEmailIpLogs: store.websiteChannelEmailIpLogs,
            // websiteEmailSmtp: store.websiteEmailSmtp,
            // websiteVerifySettings: store.websiteVerifySettings,
            // websiteVerifyKey: store.websiteVerifyKey,
            // websiteContracts: store.websiteContracts,
            // websiteUsageQuotas: store.websiteUsageQuotas,
            // websiteUsageLimits: store.websiteUsageLimits,
            // operators: resolverApp.operators,
          },
          successFn: ({ next }) => {
            window.APP_LOADED_CALLBACK();
            return next();
          },
          children: [
            routerBuilder.build({
              path: "inbox/",
              name: "app.website.inbox.index",
              component: () => import("@/views/app/website/inbox.vue"),
              children: [
                routerBuilder.build({
                  path: ":sessionId/",
                  name: "app.website.inbox.conversation",
                  component: () =>
                    import("@/views/app/website/conversation.vue"),
                  // resolvers: {
                  //   currentSession: resolvers.currentSession,
                  // },
                }),
              ],
            }),
            routerBuilder.build({
              path: "inbox-reinvent/",
              name: "app.website.inbox.index.reinvent",
              component: () => import("@/views/reinvent/app/website/inbox.vue"),
              children: [
                routerBuilder.build({
                  path: ":sessionId/",
                  name: "app.website.inbox.conversation.reinvent",
                  component: () =>
                    import("@/views/reinvent/app/website/conversation.vue"),
                  // resolvers: {
                  //   currentSession: resolvers.currentSession,
                  // },
                }),
              ],
            }),
            {
              path: "visitors/",
              name: "app.website.visitors.index",
              component: () => import("@/views/DummyView.vue"),
              children: [
                // {
                //   path: ":sessionId/",
                //   name: "app.website.visitors.browsing",
                //   component: z,
                //   resolvers: {
                //     sessionId: resolverConversation.conversationSessionId,
                //   },
                // },
              ],
            },
            {
              path: "contacts/",
              name: "app.website.contacts.index",
              component: () => import("@/views/DummyView.vue"),
            },
            {
              path: "contacts/profile/:peopleId/",
              name: "app.website.contacts.profile",
              component: () => import("@/views/DummyView.vue"),
            },
            routerBuilder.build({
              path: "campaigns/",
              name: "app.website.campaigns",
              component: () => import("@/views/DummyView.vue"),
              hook: ({ to, next }) => {
                return next({
                  name: "app.website.campaigns.index",
                  params: {
                    ...to.params,
                    channel: "all",
                  },
                });
              },
            }),
            routerBuilder.build({
              path: "campaigns/:channel/",
              name: "app.website.campaigns.index",
              component: () =>
                import("@/views/app/website/campaigns/index.vue"),
              children: [
                routerBuilder.build({
                  path: "campaign/:campaignID/",
                  name: "app.website.campaigns.campaign",
                  component: () =>
                    import("@/views/app/website/campaigns/campaign/index.vue"),
                  resolvers: {
                    logout: resolvers.campaign,
                  },
                  children: [
                    {
                      path: "recipients/",
                      name: "app.website.campaigns.campaign.recipients",
                      component: () =>
                        import(
                          "@/views/app/website/campaigns/campaign/receipients.vue"
                        ),
                    },
                    {
                      path: "models/",
                      name: "app.website.campaigns.campaign.models",
                      component: () =>
                        import(
                          "@/views/app/website/campaigns/campaign/models.vue"
                        ),
                    },
                    {
                      path: "model-create/",
                      name: "app.website.campaigns.campaign.models.create",
                      component: () =>
                        import(
                          "@/views/app/website/campaigns/campaign/modelCreate.vue"
                        ),
                    },
                    {
                      path: "settings/",
                      name: "app.website.campaigns.campaign.settings",
                      component: () =>
                        import(
                          "@/views/app/website/campaigns/campaign/settings.vue"
                        ),
                    },
                    //     {
                    //       path: "flow/",
                    //       name: "app.website.campaigns.flow",
                    //       component: R,
                    //     },
                    //     {
                    //       path: "editor/",
                    //       name: "app.website.campaigns.editor",
                    //       component: Z,
                    //     },
                    //     {
                    //       path: "analytics/",
                    //       name: "app.website.campaigns.analytics",
                    //       component: F,
                    //     },
                  ],
                }),
                {
                  path: "template/",
                  name: "app.website.campaigns.template",
                  component: () =>
                    import("@/views/app/website/campaigns/template/index.vue"),
                  children: [
                    {
                      path: "new/",
                      name: "app.website.campaigns.template.new",
                      component: () =>
                        import(
                          "@/views/app/website/campaigns/template/preview.vue"
                        ),
                    },
                    {
                      path: ":campaignModelID/approval/",
                      name: "app.website.campaigns.template.approval",
                      component: () =>
                        import(
                          "@/views/app/website/campaigns/template/approval.vue"
                        ),
                    },
                  ],
                },
              ],
            }),
            {
              path: "helpdesk/",
              redirect: (e) => ({
                name: "app.website.helpdesk.index",
                params: {
                  ...e.params,
                  filter: "articles",
                },
              }),
            },
            {
              path: "helpdesk/:filter/",
              name: "app.website.helpdesk.index",
              component: () => import("@/views/DummyView.vue"),
              children: [
                // {
                //   path: ":locale/:articleId/",
                //   name: "app.website.helpdesk.article",
                //   component: P,
                // },
              ],
            },
            {
              path: "analytics/",
              redirect: (e) => ({
                name: "app.website.analytics.messaging",
                params: {
                  ...e.params,
                },
              }),
            },
            {
              path: "analytics/",
              name: "app.website.analytics.index",
              component: () => import("@/views/app/analytics/index.vue"),
              children: [
                {
                  path: "messaging/",
                  name: "app.website.analytics.messaging",
                  component: () =>
                    import("@/views/app/analytics/messaging.vue"),
                },
                // {
                //   path: "contacts/",
                //   name: "app.website.analytics.contacts",
                //   component: J,
                // },
                // {
                //   path: "rating/",
                //   name: "app.website.analytics.rating",
                //   component: q,
                // },
                // {
                //   path: "campaigns/",
                //   name: "app.website.analytics.campaigns",
                //   component: H,
                // },
                // {
                //   path: "helpdesk/",
                //   name: "app.website.analytics.helpdesk",
                //   component: X,
                //   resolvers: {
                //     helpdeskStatus: I.default.helpdeskStatus,
                //   },
                // },
                // {
                //   path: "status/",
                //   name: "app.website.analytics.status",
                //   component: $,
                //   resolvers: {
                //     statusStatus: I.default.statusStatus,
                //   },
                // },
              ],
            },
            {
              path: "plugins/",
              redirect: (e) => ({
                name: "app.website.plugins.filter",
                params: {
                  ...e.params,
                  filter: "all",
                },
              }),
            },
            {
              path: "plugins/",
              name: "app.website.plugins.index",
              component: () => import("@/views/app/website/plugins/index.vue"),
              children: [
                {
                  path: "filter/:filter/",
                  name: "app.website.plugins.filter",
                  component: () =>
                    import("@/views/app/website/plugins/filter.vue"),
                },
                {
                  path: "settings/:pluginID/",
                  name: "app.website.plugins.settings",
                  component: () =>
                    import("@/views/app/website/plugins/settings.vue"),
                },
              ],
            },
            routerBuilder.build({
              path: "settings/",
              name: "app.settings.index",
              component: () => import("@/views/app/settings/index.vue"),
              resolvers: {
                // tenants: resolvers.tenants,
                // tenantID: resolvers.tenantID,
              },
              successFn: ({ next }) => {
                window.APP_LOADED_CALLBACK();
                next();
              },
              children: [
                routerBuilder.build({
                  path: "account/",
                  name: "app.settings.account",
                  component: () => import("@/views/app/settings/account.vue"),
                  resolvers: {
                    // profile: resolverProfile.profile,
                    // hasToken: resolverProfile.hasToken,
                    // userSessionAuthorized: resolverProfile.userSessionAuthorized,
                    // userSessionHistory: resolverProfile.userSessionHistory,
                  },
                }),
                {
                  path: "notifications/",
                  name: "app.settings.notifications",
                  component: () =>
                    import("@/views/app/settings/notifications.vue"),
                  resolvers: {
                    notifications: resolvers.notification,
                  },
                },
                routerBuilder.build({
                  path: "shortcuts/",
                  name: "app.settings.shortcuts",
                  component: () => import("@/views/app/settings/shortcuts.vue"),
                }),
                routerBuilder.build({
                  path: "billing/",
                  name: "app.settings.billing",
                  component: () => import("@/views/app/settings/billing.vue"),
                  // resolvers: {
                  //   cards: store.userCards,
                  // },
                }),
                // routerBuilder.build({
                //   path: "tenants",
                //   name: "app.settings.tenants.all",
                //   component: () => import("@/views/app/settings/tenants.vue"),
                //   resolvers: {
                //     tenants: resolvers.tenants,
                //   },
                // }),
                // routerBuilder.build({
                //   path: "availability/",
                //   name: "app.settings.availability",
                //   component: () => import("@/views/app/settings/availability.vue"),
                //   resolvers: {
                //     // userSchedule: store.schedule,
                //   },
                // }),
                routerBuilder.build({
                  path: "configure/",
                  name: "app.settings.tenants.configure",
                  component: () => import("@/views/app/settings/configure.vue"),
                  resolvers: {
                    // tenants: resolvers.tenants,
                    // tenantID: resolvers.tenantID,
                    // operators: resolvers.operators,
                    // websiteSettings: store.websiteSettings,
                    // websitePlan: store.websitePlan,
                    // websiteBilling: store.websiteBilling,
                    // websiteRoutingSettings: store.websiteRoutingSettings,
                    // websiteRoutingRules: store.websiteRoutingRules,
                    // websiteHooks: store.websiteHooks,
                    // websiteChannelEmailDomain: store.websiteChannelEmailDomain,
                    // websiteChannelEmailSignature:
                    //   store.websiteChannelEmailSignature,
                    // websiteChannelEmailIp: store.websiteChannelEmailIp,
                    // websiteChannelEmailIpLogs: store.websiteChannelEmailIpLogs,
                    // websiteEmailSmtp: store.websiteEmailSmtp,
                    // websiteVerifySettings: store.websiteVerifySettings,
                    // websiteVerifyKey: store.websiteVerifyKey,
                    // websiteContracts: store.websiteContracts,
                    // websiteUsageQuotas: store.websiteUsageQuotas,
                    // websiteUsageLimits: store.websiteUsageLimits,
                    // operators: resolverApp.operators,
                  },
                }),

                // {
                //   path: "billing/invoices/:billingId/",
                //   name: "app.settings.billing.invoices",
                //   component: le,
                //   resolvers: {
                //     card: store.card,
                //   },
                // },
                // {
                //   path: "billing/link/added/:billingId/",
                //   name: "app.settings.billing.link.added",
                //   hook: ({ to: e }) => {
                //     let t = store.getters["user/getPendingCardProvider"],
                //       i =
                //         "switchPlan" === t.popup
                //           ? "app.settings.plans"
                //           : "app.settings.billing";
                //     be.push({
                //       name: i,
                //       params: {
                //         action: "link",
                //         billingId: e.params.billingId,
                //       },
                //     }).catch(() => {});
                //   },
                // },
                // {
                //   path: "billing/link/removed/:billingId/",
                //   name: "app.settings.billing.link.removed",
                //   hook: ({ to: e }) => {
                //     be.push({
                //       name: "app.settings.billing",
                //       params: {
                //         action: "unlink",
                //         billingId: e.params.billingId,
                //       },
                //     }).catch(() => {});
                //   },
                // },
                // {
                //   path: "plans/",
                //   name: "app.settings.plans",
                //   component: ce,
                //   resolvers: {
                //     cards: store.userCards,
                //     planSubscriptions: store.userPlanSubscriptions,
                //     planBillingPrice: store.websitesPlanBillingPrice,
                //     pluginSubscriptions: store.userPluginSubscriptions,
                //     pluginBillingPrice: store.websitesPluginBillingPrice,
                //     limitSubscriptions: store.userLimitSubscriptions,
                //     limitBillingPrice: store.websitesLimitBillingPrice,
                //   },
                // },
                // {
                //   path: "shortcuts/",
                //   name: "app.settings.shortcuts",
                //   component: Me,
                // },
                // {
                //   path: "helpdesk/",
                //   name: "app.settings.helpdesk",
                //   component: ge,
                //   resolvers: {
                //     websiteHelpdeskStatus: store.websiteHelpdeskStatus,
                //     websiteHelpdeskSettings: store.websiteHelpdeskSettings,
                //     websiteHelpdeskDomain: store.websiteHelpdeskDomain,
                //     operators: resolverApp.operators,
                //   },
                // },
                // {
                //   path: "status/",
                //   name: "app.settings.status",
                //   component: pe,
                //   resolvers: {
                //     websiteStatusStatus: store.websiteStatusStatus,
                //     websiteStatusSettings: store.websiteStatusSettings,
                //     websiteStatusDomain: store.websiteStatusDomain,
                //     websiteStatusAnnouncement: store.websiteStatusAnnouncement,
                //     websiteStatusServices: store.websiteStatusServices,
                //     websiteStatusReporterToken: store.websiteStatusReporterToken,
                //     operators: resolverApp.operators,
                //   },
                // },
              ],
            }),
          ],
        }),
      ],
    }),
  ],
});

router.beforeEach((e, t, next) => {
  // console.log(e, t);
  // let tenantID = (e.params || {}).tenantID || "";
  // let r = websiteID || websites.getDefaultWebsiteId();
  // websiteID &&
  //   websiteID !== store.getters["websites/getURLWebsiteId"] &&
  //   store.commit("websites/setURLWebsiteId", {
  //     value: websiteID,
  //   });

  // r && r !== store.getters["websites/getWebsiteId"]
  //   ? !e ||
  //     "app" === e.name ||
  //     startsWith(e.name, "initiate.") ||
  //     (startsWith(e.name, "app.settings") &&
  //       store.getters["websites/getWebsiteId"]) ||
  //     (store.commit("websites/setWebsiteId", {
  //       value: r,
  //     }),
  //     store.commit("inbox/reset"),
  //     store.commit("operators/reset", {
  //       websiteId: r,
  //     }),
  //     store.commit("contacts/resetSegmentation"),
  //     store.commit("conversations/clearWebsite", {
  //       websiteId: r,
  //       flushCompletely: !0,
  //     }),
  //     store.commit("analytics/reset"),
  //     store.commit("shortcuts/reset"),
  //     store.dispatch("plugins/acquirePluginUrns", {
  //       websiteId: r,
  //     }),
  //     websites.join(r))
  //   : websites.subscribed || websites.join(r);
  language.loadLanguageAsync(window.LOCALE).then(() => next());
});

router.beforeEach(async (to, from, next) => {
  if (to?.name?.startsWith("app") || to?.name === "initiate.plugin") {
    classAuth.autoAuthenticateNetwork();
  }

  return next();
});

// router.afterEach((to, from) => {
//   console.log("AFTER", to, from);
//   //   //   store.commit("common/setPendingRouteState", {
//   //   //     value: null,
//   //   //   });
//   //   //   store.commit("common/setRouteState", {
//   //   //     value: to.name,
//   //   //   });
//   //   // if ("app" === to.name || "app.website-redirect" === to.name) {
//   //   //   window.APP_LOADED_CALLBACK();
//   //   // }
// });

export default router;
