<template>
  <div
    :class="[
      'c-field-time',
      {
        'c-field-time--disabled': disabled,
      },
    ]"
  >
    <div class="c-field-time__selections">
      <div
        v-for="(item, index) in modelValue"
        :key="index"
        class="c-field-time__selection"
      >
        <span class="c-field-time__time">
          {{ item.from + " - " + item.to }}
        </span>
        <BaseIcon
          class="c-field-time__remove"
          v-on:click="onRemoveSelectionItem"
          :id="index"
          cursor="pointer"
          name="clear"
          size="16px"
        ></BaseIcon>
      </div>
    </div>
    <div class="c-field-time__selectors">
      <div class="c-field-time__selector-start">
        <FieldSelect
          class="c-field-time__select c-field-time__select--hour"
          :modelValue="startHour"
          v-on:update:modelValue="(e) => (startHour = e)"
          :disabled="disabled"
          :options="availableHours"
          name="startHour"
          position="top"
          size="small"
        ></FieldSelect>
        <FieldSelect
          class="c-field-time__select"
          :modelValue="startMinute"
          v-on:update:modelValue="(e) => (startMinute = e)"
          :disabled="disabled"
          :options="availableMinutes"
          name="startMinute"
          position="top"
          size="small"
        ></FieldSelect>
      </div>
      <span class="c-field-time__to">
        {{ this.$t("FORMS.FIELD_TIME.TO") }}
      </span>
      <div class="c-field-time__selector-stop">
        <FieldSelect
          class="c-field-time__select c-field-time__select--hour"
          :modelValue="stopHour"
          v-on:update:modelValue="(e) => (stopHour = e)"
          :disabled="disabled"
          :options="availableHours"
          name="stopHour"
          position="top"
          size="small"
        ></FieldSelect>
        <FieldSelect
          class="c-field-time__select"
          :modelValue="stopMinute"
          v-on:update:modelValue="(e) => (stopMinute = e)"
          :disabled="disabled"
          :options="availableMinutes"
          name="stopMinute"
          position="top"
          size="small"
        ></FieldSelect>
      </div>
      <BaseButton
        class="c-field-time__button"
        v-on:click="onAddSelection"
        :disabled="disabled || !isCurrentSelectionValid"
        size="small"
      >
        {{ this.$t("FORMS.FIELD_TIME.ADD") }}
      </BaseButton>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      required: !1,
    },
    modelValue: {
      type: Array,
      required: !0,
    },
  },
  emits: ["update:modelValue", "change"],
  data() {
    return {
      selections: [],
      startHour: null,
      startMinute: null,
      stopHour: null,
      stopMinute: null,
      availableHours: [],
      availableMinutes: [
        {
          label: "00",
          value: "00",
        },
        {
          label: "15",
          value: "15",
        },
        {
          label: "30",
          value: "30",
        },
        {
          label: "45",
          value: "45",
        },
      ],
    };
  },
  computed: {
    isCurrentSelectionValid() {
      return Boolean(
        this.startHour && this.startMinute && this.stopHour && this.stopMinute,
      );
    },
  },
  created() {
    for (let e = 0; e < 24; e++)
      this.availableHours.push({
        label: e < 10 ? "0" + e : e,
        value: e < 10 ? "0" + e : e,
      });
  },
  methods: {
    clearSelection() {
      this.startHour = null;
      this.startMinute = null;
      this.stopHour = null;
      this.stopMinute = null;
    },
    onAddSelection() {
      const e = {
        from: this.startHour + ":" + this.startMinute,
        to: this.stopHour + ":" + this.stopMinute,
      };
      this.clearSelection();
      this.$emit("change", e, "added");
      this.$emit("update:modelValue", [...this.modelValue, e]);
    },
    onRemoveSelectionItem(e) {
      if (!this.disabled) {
        let t = [...this.modelValue];
        t.splice(e, 1);
        this.$emit("change", t[e], "removed", e);
        this.$emit("update:modelValue", t);
      }
    },
  },
};
</script>
<style lang="scss">
.c-field-time {
  color: #1c293b;
  .c-field-time__selections {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    .c-field-time__selection {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: end;
      -ms-flex-pack: end;
      justify-content: flex-end;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      margin-bottom: 6px;
      &:last-of-type {
        margin-bottom: 20px;
      }
      .c-field-time__time {
        color: #75869c;
        font-size: 15px;
      }
      .c-field-time__remove {
        margin-left: 6px;
        fill: #e0102b;
      }
    }
  }
  .c-field-time__selectors {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    .c-field-time__selector-start {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
    }
    .c-field-time__selector-stop {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
    }
    .c-field-time__select {
      width: 80px;
    }
    .c-field-time__select--hour {
      margin-right: 5px;
    }
    .c-field-time__to {
      margin: 0 16px;
      font-weight: 700;
      font-size: 15px;
      margin-top: auto;
      margin-bottom: auto;
    }
    .c-field-time__button {
      margin-left: 18px;
    }
  }
}
.c-field-time--disabled {
  .c-field-time__selections {
    .c-field-time__selection {
      opacity: 0.35;
      .c-field-time__remove {
        cursor: not-allowed !important;
      }
    }
  }
  .c-field-time__selectors {
    .c-field-time__to {
      opacity: 0.35;
    }
  }
}
</style>
