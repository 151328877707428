import BaseIcon from "@/components/Base/BaseIcon.vue"; // svg component

class _baseIcon {
  init(_vue) {
    // import all svg's
    const req = require.context("../assets/svg", false, /\.svg$/);
    const requireAll = (requireContext) =>
      requireContext.keys().map(requireContext);
    requireAll(req);

    // register globally component
    _vue.component("BaseIcon", BaseIcon);
  }
}
export default new _baseIcon();
