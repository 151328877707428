import tenants from "@/classes/talqui/tenant";

const $widgets = {
  devices: {
    deployed: true,
    order: 5,
  },
  main: {
    deployed: true,
    order: 4,
  },
  routing: {
    deployed: true,
    order: 2,
  },
  segments: {
    deployed: true,
    order: 7,
  },
  // actions: {
  //   deployed: true,
  //   order: 12,
  // },
  // call: {
  //   deployed: true,
  //   order: 1,
  // },
  // data: {
  //   deployed: true,
  //   order: 8,
  // },
  // events: {
  //   deployed: false,
  //   order: 9,
  // },
  // history: {
  //   deployed: true,
  //   order: 6,
  // },
  // notepad: {
  //   deployed: false,
  //   order: 10,
  // },
  // participants: {
  //   deployed: true,
  //   order: 11,
  // },
  // rating: {
  //   deployed: true,
  //   order: 3,
  // },
};

export default {
  namespaced: true,
  state: {
    widgets: {},
    pluginsWidgets: {},
    pluginsWidgetsLoading: {},
    pluginsWidgetsLastAcquire: {},
    expandedWidget: null,
  },
  getters: {
    getWidgets: (state) => (tenantID) => state.widgets[tenantID],
    getPluginsWidgets: (state) => (tenantID) => state.pluginsWidgets[tenantID],
    getExpandedWidget: (state) => state.expandedWidget,
  },
  actions: {
    acquirePluginsWidgets({ commit, dispatch }, { tenantID }) {
      return Promise.resolve()
        .then(() => {
          // commit("reset");
          commit("assertWidgets", { tenantID: tenantID });
          return tenants
            .tenantPluginList({ tenantID: tenantID })
            .get()
            .then((res) => {
              let widgetsConnecteds = {};
              let __plugins = res?.plugins || [];

              for (let idx = 0; idx < __plugins.length; idx++) {
                const pluginConnection = __plugins[idx];
                if (pluginConnection?.plugin?.pluginWidget) {
                  widgetsConnecteds[pluginConnection.plugin.pluginURN] = {
                    deployed: false,
                    order: 99 - idx,
                    fromPlugin: true,
                    pluginName: pluginConnection.plugin.pluginName,
                    pluginIcon: pluginConnection.plugin.pluginIcon,
                    pluginWidget: pluginConnection.plugin.pluginWidget,
                    pluginToken: pluginConnection.plugin.pluginToken,
                  };
                }
              }

              commit("setPluginsWidgets", {
                tenantID: tenantID,
                value: widgetsConnecteds,
              });
              return Promise.resolve(widgetsConnecteds);
            });
        })
        .then(() => {
          dispatch("syncWidgets", {
            tenantID: tenantID,
          });
          return Promise.resolve();
        });
    },
    syncWidgets({ commit, getters }, { tenantID }) {
      let stateWidgets = getters.getWidgets(tenantID);
      let pluginsWidgets = getters.getPluginsWidgets(tenantID);

      for (let pluginWidgetURN in pluginsWidgets) {
        if (!Object.keys(stateWidgets).includes(pluginWidgetURN)) {
          commit("setWidget", {
            tenantID: tenantID,
            widgetId: pluginWidgetURN,
            value: pluginsWidgets[pluginWidgetURN],
          });
        }
      }
    },
  },
  mutations: {
    reset(state) {
      state.widgets = {};
      state.pluginsWidgets = {};
      state.pluginsWidgetsLoading = {};
      state.pluginsWidgetsLastAcquire = {};
      state.expandedWidget = null;
    },
    assertWidgets(state, { tenantID }) {
      if (state.widgets[tenantID]) {
        for (let i in $widgets) {
          state.widgets[tenantID][i] ||
            (state.widgets[tenantID][i] = $widgets[i]);
        }
      } else {
        state.widgets[tenantID] = {};
        for (let i in $widgets) {
          state.widgets[tenantID][i] = $widgets[i];
        }
      }
    },
    setWidget(state, { tenantID, widgetId, value }) {
      state.widgets[tenantID][widgetId] = value;
    },
    setWidgetOrder(state, { tenantID, widgetId, value }) {
      state.widgets[tenantID][widgetId].order = value;
    },
    setWidgetDeployed(state, { tenantID, widgetId, value }) {
      state.widgets[tenantID][widgetId].deployed = value;
    },
    setPluginsWidgets(state, { tenantID, value }) {
      state.pluginsWidgets[tenantID] = value;
    },
  },
};
