import tenant from "./tenant";

class Session {
  sessionBase(tenantID) {
    return tenant.tenantOne(tenantID).one("sessions");
  }
  sessionList(tenantID) {
    return this.sessionBase(tenantID);
  }
  sessionOne(tenantID, sessionID) {
    return this.sessionBase(tenantID).one(sessionID);
  }
  sessionMessages(tenantID, sessionID, firstMessageTimestamp = Date.now) {
    return this.sessionOne(tenantID, sessionID)
      .one("messages")
      .get({
        infiniteScroll: true,
        ...(firstMessageTimestamp > 0
          ? { firstMessageTimestamp: firstMessageTimestamp }
          : {}),
      });
  }
}
export default new Session();
