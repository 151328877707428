<template>
  <div
    :class="[
      'c-field-file',
      'c-field-file--' + color,
      'c-field-file--' + direction,
      'c-field-file--' + size,
      {
        'c-field-file--block': block,
        'c-field-file--dragging': dragging,
        'c-field-file--invalid': invalid,
        'c-field-file--disabled': disabled,
        'c-field-file--with-file': currentFile && !loading,
      },
    ]"
    ref="root"
  >
    <span v-if="label" class="c-field-file__label">{{ label }}</span>
    <div v-if="loading" class="c-field-file__container">
      <BaseSpinner
        class="c-field-file__spinner"
        color="#1c293b"
        size="22px"
        speed="2500ms"
      ></BaseSpinner>
    </div>
    <div v-else-if="source" class="c-field-file__preview">
      <span
        class="c-field-file__image"
        :style="{
          backgroundImage: 'url(' + source + ')',
        }"
      ></span>
      <span class="c-field-file__action" v-on:click="(...e) => onRemove(...e)">
        <BaseIcon
          class="c-field-file__icon"
          name="delete"
          size="15px"
        ></BaseIcon>
        {{ this.$t("FORMS.FIELD_FILE.ACTION_REMOVE") }}
      </span>
    </div>
    <label
      v-else
      class="c-field-file__container c-field-file__container--label"
      :direction="direction"
      for="upload"
    >
      <BaseIcon
        class="c-field-file__icon"
        :name="currentFile ? 'folder' : 'add_circle'"
      ></BaseIcon>
      <span class="c-field-file__action">{{ fileAction }}</span>
      <BaseIcon
        v-if="currentFile && remove"
        class="c-field-file__remove"
        v-on:click="onClearInput"
        cursor="pointer"
        name="cancel"
        size="18px"
      ></BaseIcon>
    </label>
    <input
      class="c-field-file__field"
      v-on:change="(...e) => onFieldChange(...e)"
      :accept="accept ? accept.join(',') : null"
      :name="name"
      :disabled="disabled"
      id="upload"
      ref="file"
      type="file"
    />
  </div>
</template>
<script>
import * as a from "@/classes/uploadHelper.js";

export default {
  props: {
    accept: {
      type: Array,
      default: null,
      validator(e) {
        return e.length > 0;
      },
    },
    block: {
      type: Boolean,
      default: !1,
    },
    bucketGenerated: {
      type: Function,
      required: !0,
    },
    color: {
      type: String,
      default: "black",
      validator(e) {
        return -1 !== ["black", "white"].indexOf(e);
      },
    },
    direction: {
      type: String,
      default: "vertical",
      validator(e) {
        return -1 !== ["horizontal", "vertical"].indexOf(e);
      },
    },
    disabled: {
      type: Boolean,
      default: !1,
    },
    draggable: {
      type: Boolean,
      default: !0,
    },
    label: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: !1,
    },
    name: {
      type: String,
      required: !0,
    },
    remove: {
      type: Boolean,
      default: !0,
    },
    size: {
      type: String,
      default: "default",
      validator(e) {
        return -1 !== ["small", "default", "large"].indexOf(e);
      },
    },
    source: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      required: !0,
    },
  },
  emits: ["change", "drop", "remove"],
  data() {
    return {
      currentFile: null,
      dragging: !1,
      invalid: !1,
    };
  },
  computed: {
    fileAction() {
      return this.invalid
        ? this.$t("FORMS.FIELD_FILE.INVALID")
        : this.currentFile
        ? this.currentFile.name
        : this.$t("FORMS.FIELD_FILE.ACTION");
    },
  },
  created() {
    a.default.addGenericListener(this.type, this.bucketGenerated);
  },
  mounted() {
    this.draggable &&
      (this.$refs.root.addEventListener("dragover", this.onDragOver),
      this.$refs.root.addEventListener("dragleave", this.onDragLeave),
      this.$refs.root.addEventListener("drop", this.onDrop));
  },
  beforeUnmount() {
    a.default.removeGenericListener(this.type, this.bucketGenerated),
      this.draggable &&
        (this.$refs.root.removeEventListener("dragover", this.onDragOver),
        this.$refs.root.removeEventListener("dragleave", this.onDragLeave),
        this.$refs.root.removeEventListener("drop", this.onDrop));
  },
  methods: {
    setFiles(e, t) {
      const i = this.$refs.file;
      e && e.length > 0
        ? (this.currentFile = e[0])
        : ((this.currentFile = null), (i.value = "")),
        t && (i.files = e),
        this.$emit("change", e, this.name);
    },
    onClearInput() {
      this.setFiles(null);
    },
    onDragOver(e) {
      e.preventDefault(), e.stopPropagation(), (this.dragging = !0);
    },
    onDragLeave(e) {
      e.preventDefault(), e.stopPropagation(), (this.dragging = !1);
    },
    onDrop(e) {
      if (
        (e.preventDefault(),
        e.stopPropagation(),
        (this.dragging = !1),
        (this.invalid = !1),
        e.dataTransfer &&
          e.dataTransfer.files &&
          e.dataTransfer.files.length > 0)
      ) {
        if (this.accept.length > 0)
          for (let t = 0; t < e.dataTransfer.files.length; t++) {
            let i = e.dataTransfer.files[t],
              a = i.type.split("/")[0] + "/*";
            -1 === this.accept.indexOf(i.type) &&
              -1 === this.accept.indexOf(a) &&
              ((this.invalid = !0), this.setFiles(null));
          }
        this.invalid ||
          (this.setFiles(e.dataTransfer.files, !0),
          this.$emit("drop", e.dataTransfer.files, this.name));
      }
    },
    onFieldChange() {
      const e = this.$refs.file.files;
      e.length > 0 ? this.setFiles(e) : this.setFiles(null);
    },
    onRemove() {
      this.$emit("remove"),
        this.$nextTick(() => {
          this.setFiles(null);
        });
    },
  },
};
</script>
<style lang="scss">
.c-field-file {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-align: left;
  .c-field-file__label {
    margin-bottom: 8px;
    color: #2c405a;
    font-weight: 700;
    font-size: 14.5px;
    cursor: default;
  }
  .c-field-file__container {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 16px 12px;
    width: 200px;
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    text-align: center;
    -webkit-transition: all 0.15s linear;
    transition: all 0.15s linear;
    .c-field-file__spinner {
      line-height: 0;
    }
    .c-field-file__action {
      -webkit-transition: -webkit-transform 0.15s linear;
      transition: -webkit-transform 0.15s linear;
      transition: transform 0.15s linear;
      transition:
        transform 0.15s linear,
        -webkit-transform 0.15s linear;
      display: block;
      margin-top: 4px;
      word-break: break-all;
      font-size: 15px;
    }
    .c-field-file__icon {
      -webkit-transition: -webkit-transform 0.15s linear;
      transition: -webkit-transform 0.15s linear;
      transition: transform 0.15s linear;
      transition:
        transform 0.15s linear,
        -webkit-transform 0.15s linear;
    }
    .c-field-file__remove {
      position: absolute;
      top: 8px;
      right: 10px;
      fill: #2c405a;
      -webkit-transition: fill 0.15s linear;
      transition: fill 0.15s linear;
      &:hover {
        fill: #e0102b;
      }
    }
    &:active {
      .c-field-file__action {
        -webkit-transform: translateY(1px);
        transform: translateY(1px);
      }
      .c-field-file__icon {
        -webkit-transform: translateY(1px);
        transform: translateY(1px);
      }
    }
  }
  .c-field-file__container--label {
    cursor: pointer;
  }
  .c-field-file__preview {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    .c-field-file__image {
      display: inline-block;
      width: 200px;
      height: 120px;
      border: 1px solid #e3e8ef;
      border-radius: 3px;
      background-color: #fff;
      background-position: 50%;
      background-size: cover;
      background-repeat: no-repeat;
    }
    .c-field-file__action {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      margin-top: 8px;
      color: #e0102b;
      text-decoration: underline;
      font-weight: 700;
      font-size: 13px;
      cursor: pointer;
      .c-field-file__icon {
        margin: 0 3px;
        fill: #e0102b;
      }
    }
  }
  .c-field-file__field {
    display: none;
  }
}
.c-field-file--black {
  .c-field-file__container {
    border-color: #e3e8ef;
    color: #2c405a;
  }
  .c-field-file__icon {
    fill: #2c405a;
  }
  &:not(.c-field-file--disabled) {
    .c-field-file__container {
      &:hover {
        border-color: #a8c6df;
      }
      &:active {
        border-color: #75869c;
      }
    }
  }
}
.c-field-file--white {
  .c-field-file__container {
    border-color: #fff;
    color: #fff;
  }
  .c-field-file__icon {
    fill: #fff;
  }
}
.c-field-file--horizontal {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  .c-field-file__label {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 140px;
  }
  .c-field-file__container {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
}
.c-field-file--vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.c-field-file--small {
  .c-field-file__container {
    height: 100px;
  }
}
.c-field-file--default {
  .c-field-file__container {
    height: 120px;
  }
}
.c-field-file--large {
  .c-field-file__container {
    height: 140px;
  }
}
.c-field-file--block {
  .c-field-file__container {
    width: 100%;
  }
}
.c-field-file--dragging {
  .c-field-file__container {
    border-color: #3f536e;
    color: #3f536e;
  }
  .c-field-file__icon {
    fill: #3f536e;
  }
}
.c-field-file--invalid {
  .c-field-file__container {
    border-color: #e0102b;
    color: #e0102b;
  }
  .c-field-file__icon {
    fill: #e0102b;
  }
}
.c-field-file--disabled {
  cursor: not-allowed;
  .c-field-file__container {
    pointer-events: none;
    color: #75869c;
  }
  .c-field-file__preview {
    pointer-events: none;
  }
  .c-field-file__icon {
    fill: #75869c;
  }
}
.c-field-file--with-file {
  .c-field-file__container {
    border-color: #2c405a;
    color: #2c405a;
  }
  .c-field-file__icon {
    fill: #2c405a;
  }
}
</style>
