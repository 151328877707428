<template>
  <div
    :class="['c-base-header', 'c-base-header--padding-' + padding]"
    :style="{
      paddingLeft: padding,
      paddingRight: padding,
    }"
  >
    <div v-if="icon || title || search" class="c-base-header__headline">
      <div v-if="icon || title" class="c-base-header__content">
        <BaseIcon
          v-if="icon"
          class="c-base-header__icon"
          name="icon"
          size="20px"
        ></BaseIcon>
        <div v-if="title" class="c-base-header__title">{{ title }}</div>
      </div>
      <FieldInput
        v-if="search"
        class="c-base-header__search"
        :modelValue="initialSearchValue"
        v-on:update:modelValue="(e) => (c.initialSearchValue = e)"
        v-on:clear="onSearchClear"
        v-on:keyup="onSearchKeyup"
        :loading="searchLoading"
        :disabled="searchDisabled"
        :placeholder="this.$t('BASES.HEADER.CONTROLS_SEARCH')"
        name="search"
        left-icon="search"
        size="small"
        clear=""
      ></FieldInput>
    </div>

    <slot></slot>

    <div v-if="this.$slots.buttons" class="c-base-header__buttons">
      <slot name="buttons"></slot>
    </div>
  </div>
</template>
<script>
import debounce from "lodash.debounce";
const n = 400;
export default {
  props: {
    icon: {
      type: String,
      default: null,
    },
    padding: {
      type: String,
      default: "20px",
    },
    search: {
      type: Boolean,
      default: false,
    },
    searchLoading: {
      type: Boolean,
      default: false,
    },
    searchDisabled: {
      type: Boolean,
      default: false,
    },
    searchValue: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: null,
    },
  },
  emits: ["search"],
  data() {
    return {
      searchDone: "",
      initialSearchValue: "",
    };
  },
  created() {
    this.searchValue && (this.initialSearchValue = this.searchValue);
  },
  methods: {
    onSearchClear() {
      this.$emit("search", ""), (this.searchDone = "");
    },
    onSearchKeyup: debounce(function (e, t) {
      this.searchDone !== t && (this.$emit("search", t), (this.searchDone = t));
    }, n),
  },
};
</script>
<style lang="scss">
.c-base-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: 53px;
  border-bottom: 1px solid #e3e8ef;
  background-color: #fff;
  color: #2c405a;
  .c-base-header__buttons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    > .c-base-button {
      margin-right: 9px;
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
  .c-base-header__headline {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-right: 20px;
    .c-base-header__content {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 170px;
      .c-base-header__icon {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        margin-right: 6px;
        fill: #2c405a;
      }
      .c-base-header__title {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        color: #2c405a;
        text-transform: capitalize;
        font-weight: 700;
        font-size: 14px;
      }
    }
    .c-base-header__search {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      margin-left: 20px;
      max-width: 240px;
    }
  }
}
@media (max-width: 75em) {
  .c-base-header {
    .c-base-header__headline {
      .c-base-header__search {
        max-width: 180px;
      }
    }
  }
}
@media (max-width: 55em) {
  .c-base-header {
    .c-base-header__headline {
      .c-base-header__search {
        max-width: 160px;
      }
    }
  }
}
@media (max-width: 52.5em) {
  .c-base-header {
    .c-base-header__headline {
      .c-base-header__search {
        max-width: 150px;
      }
    }
  }
}
</style>
