<template>
  <div class="c-general-banner">
    <BaseBanner
      v-if="bannerType"
      key="0"
      class="c-general-banner__banner"
      v-on:actionClick="onBannerActionClick"
      v-on:close="onCloseBanner"
      :action="bannerAction"
      :color="bannerColor"
      :content="bannerContent"
      :loading="bannerLoading"
      :single-line="bannerSingleLine"
      :has-close="bannerHasClose"
      size="small"
    />
  </div>
</template>
<script>
// var a = c(i("b48d0"));
import classNotification from "@/classes/talqui/notification.js";
// var o = c(i("09ff"));
// var n = c(i("0315"));
// var r = c(i("50ad"));
// var s = c(i("0b1b"));
// var l = c(i("afd9"));

// const d = 864e5;
// const g = 3;
// const p = 30;
// const b = 0.9;
const u = 3e4;
// const M = 2e3;

import dayjs from "dayjs";
import * as relativeTime from "dayjs/plugin/relativeTime";
import "dayjs/locale/pt-br";
dayjs.locale("pt-br");
dayjs.extend(relativeTime);

export default {
  data() {
    return {
      notificationsPermissionState: "denied",
      askForNotificationsBannerClosed: false,
      connectedDebounced: true,
      connectedDebounceTimeout: null,
      isUpdateBannerClosed: false,
      isOfflineScheduleBannerClosed: false,
      isUsageQuotaAlmostReachedBannerClosed: false,
      isUsageQuotaReachedBannerClosed: false,
      isPlanRecentlyExpiredBannerClosed: false,
      isChangelogBannerClosed: false,
      isInvalidScheduleBannerClosed: false,
      isTrialExpireSoonBannerClosed: false,
      isUnpaidSubscriptionBannerClosed: false,
      onMissPaymentMethodBannerClosed: false,
    };
  },
  computed: {
    bannerType() {
      return this.hasTrialExpireSoon && !this.isTrialExpireSoonBannerClosed
        ? "trialExpireSoon"
        : this.hasUnpaidSubscription && !this.isUnpaidSubscriptionBannerClosed
        ? "unpaidSubscription"
        : this.missingPaymentMethod && !this.onMissPaymentMethodBannerClosed
        ? "missingPaymentMethod"
        : this.notificationsNotGranted && !this.askForNotificationsBannerClosed
        ? "askForNotifications"
        : null;
      // return this.connectedDebounced || this.isReconnecting
      //   ? this.isSafeModeEnabled
      //     ? "safeMode"
      //     : !this.connectedDebounced && this.isReconnecting
      //     ? "reconnecting"
      //     : !this.isUpdateBannerClosed && this.isUpdateAvailable
      //     ? "updateApp"
      //     : this.hasPlanRecentlyExpired &&
      //       !this.isPlanRecentlyExpiredBannerClosed
      //     ? "planRecentlyExpired"
      //     : this.hasTrialExpireSoon
      //     ? "trialExpireSoon"
      //     : this.isUsageQuotaReached && !this.isUsageQuotaReachedBannerClosed
      //     ? "usageQuotaReached"
      //     : this.isUsageQuotaAlmostReached &&
      //       !this.isUsageQuotaAlmostReachedBannerClosed
      //     ? "usageQuotaAlmostReached"
      //     : this.hasNewChangelog && !this.isChangelogBannerClosed
      //     ? "changelog"
      //     : this.isInvalidSchedule
      //     ? "invalidSchedule"
      //     : this.hasPlanSandbox
      //     ? "planSandbox"
      //     : this.isOffline
      //     ? "offlineSchedule"
      //     : null
      //   : "disconnected";
    },
    bannerAction() {
      switch (this.bannerType) {
        case "trialExpireSoon":
          return this.$t("BANNERS.TRIAL_EXPIRE_SOON_SELECT");
        case "unpaidSubscription":
          return this.$t("BANNERS.ACTION_UNPAID_SUBSCRIPTION");
        case "missingPaymentMethod":
          return this.$t("BANNERS.ACTION_MISS_PAYMENT_METHOD");
        case "askForNotifications":
          return this.$t("BANNERS.ENABLE_NOTIFICATION");
        // case "disconnected":
        // case "reconnecting":
        // case "planSandbox":
        //   return this.$t("BANNERS.SEE_HELP");
        // case "updateApp":
        //   return this.$t("BANNERS.RELOAD_CRISP");
        // case "planRecentlyExpired":
        //   return this.$t("BANNERS.PLAN_RECENTLY_EXPIRED_SELECT");
        // case "offlineSchedule":
        //   return this.$t("BANNERS.CHANGE_AVAILABILITY");
        // case "invalidSchedule":
        //   return this.$t("BANNERS.CHANGE_TIMEZONE");
        // case "changelog":
        //   return this.$t("BANNERS.SEE_CHANGELOG");
        // case "usageQuotaAlmostReached":
        // case "usageQuotaReached":
        //   return this.$t("BANNERS.VIEW_LIMIT_QUOTA");
        default:
          return;
      }
    },
    bannerColor() {
      switch (this.bannerType) {
        case "trialExpireSoon":
          return "blue";
        case "unpaidSubscription":
          return "red";
        case "missingPaymentMethod":
          return "orange";
        // case "usageQuotaAlmostReached":
        // case "disconnected":
        // case "safeMode":
        // case "reconnecting":
        //   return "orange";
        // case "askForNotifications":
        // case "updateApp":
        // case "changelog":
        // case "planSandbox":
        //   return "black";
        // case "usageQuotaReached":
        // case "planRecentlyExpired":
        // case "invalidSchedule":
        //   return "red";
        // case "offlineSchedule":
        //   return "grey";
        default:
          return;
      }
    },
    bannerContent() {
      switch (this.bannerType) {
        case "trialExpireSoon":
          return this.$t("BANNERS.TRIAL_EXPIRE_SOON_NOTICE", {
            expiration_time: dayjs(
              this?.subscription.subscriptionCurrentPeriodEndAt,
            ).fromNow(),
          });
        case "unpaidSubscription":
          return this.$t("BANNERS.CONTENT_UNPAID_SUBSCRIPTION");
        case "missingPaymentMethod":
          return this.$t("BANNERS.CONTENT_MISS_PAYMENT_METHOD");
        case "askForNotifications":
          return this.$t("BANNERS.NOTIFICATION_NOTICE");
        // case "disconnected":
        //   return `${this.$t("BANNERS.RECONNECT_NOTICE_MAIN")} ${this.$t(
        //     "BANNERS.RECONNECT_NOTICE_WILL_PROCEED",
        //   )}`;
        // case "reconnecting":
        //   return `${this.$t("BANNERS.RECONNECT_NOTICE_MAIN")} ${this.$t(
        //     "BANNERS.RECONNECT_NOTICE_PROCEEDING",
        //   )}`;
        // case "updateApp":
        //   return this.$t("BANNERS.NEW_VERSION_NOTICE");
        // case "planRecentlyExpired":
        //   return this.$t("BANNERS.PLAN_RECENTLY_EXPIRED_NOTICE");
        // case "planSandbox":
        //   return this.$t("BANNERS.PLAN_SANDBOX_NOTICE");
        // case "offlineSchedule":
        //   return this.$t("BANNERS.OFFLINE_NOTICE");
        // case "invalidSchedule":
        //   return this.$t("BANNERS.TIMEZONE_NOTICE", {
        //     timezone: this.timezone,
        //   });
        // case "safeMode":
        //   return this.$t("BANNERS.SAFE_MODE_NOTICE");
        // case "changelog":
        //   return (
        //     this.latestAlertsUpdate.title +
        //     " – " +
        //     this.latestAlertsUpdate.description
        //   );
        // case "usageQuotaReached":
        //   return this.$t("BANNERS.USAGE_QUOTA_REACHED_NOTICE", {
        //     name: this.formatLimitNamespaceToText(
        //       this.namespaceUsageQuotaReached,
        //     ),
        //   });
        // case "usageQuotaAlmostReached":
        //   return this.$t("BANNERS.USAGE_QUOTA_ALMOST_REACHED_NOTICE", {
        //     name: this.formatLimitNamespaceToText(
        //       this.namespaceUsageQuotaAlmostReached,
        //     ),
        //   });
        default:
          return;
      }
    },
    bannerHasClose() {
      switch (this.bannerType) {
        case "safeMode":
        case "planSandbox":
        case "disconnected":
        case "reconnecting":
          return false;
      }
      return true;
    },
    bannerLoading() {
      switch (this.bannerType) {
        case "reconnecting":
          return true;
      }
      return false;
    },
    bannerSingleLine() {
      switch (this.bannerType) {
        case "changelog":
          return true;
      }
      return false;
    },
    // hasPlanRecentlyExpired() {
    //   if (
    //     this.website &&
    //     this.website.subscription &&
    //     !this.website.subscription.active &&
    //     this.website.subscription.price > 0 &&
    //     this.website.subscription.bill_valid_until
    //   ) {
    //     let e = new Date(this.website.subscription.bill_valid_until),
    //       t = new Date(),
    //       i = Math.round((t.getTime() - e.getTime()) / d);
    //     return i > 0 && i < p;
    //   }
    //   return false;
    // },
    // hasPlanSandbox() {
    //   return !(
    //     !this.website ||
    //     !this.website.subscription ||
    //     true !== this.website.subscription.sandbox
    //   );
    // },
    // hasNewChangelog() {
    //   return this.$store.getters["alerts/hasNewUpdate"];
    // },
    // isOffline() {
    //   return (
    //     this.$store.commit("operators/assertOperators", {
    //       websiteId: this.websiteId,
    //     }),
    //     !(
    //       this.isOfflineScheduleBannerClosed ||
    //       !this.$store.getters["operators/areOnlineOperatorsLoaded"](
    //         this.websiteId,
    //       )
    //     ) && !this.onlineOperators[this.me.user_id]
    //   );
    // },
    // isUpdateAvailable() {
    //   return this.$store.getters["common/isUpdateAvailable"];
    // },
    // isConnected() {
    //   return this.$store.getters["common/isConnected"];
    // },
    // isReconnecting() {
    //   return this.$store.getters["common/isReconnecting"];
    // },
    // isSafeModeEnabled() {
    //   return this.$store.getters["common/isSafeModeEnabled"];
    // },
    // isInvalidSchedule() {
    //   let e = new Date().getTimezoneOffset();
    //   return (
    //     !this.isInvalidScheduleBannerClosed &&
    //     this.schedule &&
    //     false === this.schedule.stealth &&
    //     true === this.schedule.enabled &&
    //     this.schedule.offset !== e
    //   );
    // },
    // isUsageQuotaAlmostReached() {
    //   return null !== this.namespaceUsageQuotaAlmostReached;
    // },
    // isUsageQuotaReached() {
    //   return null !== this.namespaceUsageQuotaReached;
    // },
    // namespaceUsageQuotaAlmostReached() {
    //   for (let e = 0; e < this.usageQuotasWithLimit.length; e++) {
    //     let t = this.usageQuotasWithLimit[e];
    //     if (t.quota.used < t.quota.limit) {
    //       let e = t.quota.used / t.quota.limit;
    //       if (e >= b) return this.normalizeLimitNamespaceRaw(t.namespace);
    //     }
    //   }
    //   return null;
    // },
    // namespaceUsageQuotaReached() {
    //   for (let e = 0; e < this.usageQuotasWithLimit.length; e++) {
    //     let t = this.usageQuotasWithLimit[e];
    //     if (t.quota.used >= t.quota.limit)
    //       return this.normalizeLimitNamespaceRaw(t.namespace);
    //   }
    //   return null;
    // },
    // latestAlertsUpdate() {
    //   return this.$store.getters["alerts/getLatestAlertsUpdate"];
    // },
    // usageQuotasWithLimit() {
    //   let e = this.$router.currentRoute.value.name || "";
    //   return e.startsWith("app.website.")
    //     ? this.usageQuotas.filter((e) => !(!e.limit || !e.limit.id))
    //     : [];
    // },
    // onlineOperators() {
    //   return (
    //     this.$store.commit("operators/assertOperators", {
    //       websiteId: this.websiteId,
    //     }),
    //     this.$store.getters["operators/getOnlineOperators"](this.websiteId)
    //   );
    // },
    // schedule() {
    //   return this.$store.getters["user/getSchedule"];
    // },
    // timezone() {
    //   let e = new Date().getTimezoneOffset(),
    //     t = "";
    //   return (
    //     (t = -1 * e >= 0 ? "+" + -1 * e : "" + -1 * e),
    //     this.$CONFIG.CRISP_TIMEZONE_OFFSETS[t]
    //   );
    // },
    // usageQuotas() {
    //   return this.$store.getters["websites/getUsageQuotas"];
    // },
    me() {
      return this.$store.getters["operatorsTalqui/getMe"];
    },
    tenantID() {
      return this.$store.getters["tenantsTalqui/getTenantID"];
    },
    tenant() {
      return this.$store.getters["tenantsTalqui/getTenant"](this.tenantID);
    },
    subscription() {
      return this.$store.getters["subscriptionsTalqui/getSubscription"](
        this.tenantID,
      );
    },
    hasTrialExpireSoon() {
      if (this?.subscription?.subscriptionStatus === "trial") {
        return true;
      }

      return false;
    },
    hasUnpaidSubscription() {
      if (
        this?.subscription?.subscriptionStatus === "unpaid" &&
        this.$route.name !== "app.settings.billing"
      ) {
        return true;
      }

      return false;
    },
    missingPaymentMethod() {
      if (
        this?.subscription?.subscriptionStatus !== "trial" &&
        this.$route.name !== "app.settings.billing" &&
        this?.subscription?.subscriptionPaymentMethod === null
      ) {
        return true;
      }

      return false;
    },
    notificationsNotGranted() {
      if (
        this.notificationsPermissionState === "default" &&
        this.$route.name !== "app.settings.notifications"
      ) {
        return true;
      }

      return false;
    },
  },
  watch: {
    isConnected: {
      immediate: true,
      handler(e) {
        null !== this.connectedDebounceTimeout &&
          (clearTimeout(this.connectedDebounceTimeout),
          (this.connectedDebounceTimeout = null)),
          true === e
            ? (this.connectedDebounced = e)
            : (this.connectedDebounceTimeout = setTimeout(() => {
                (this.connectedDebounceTimeout = null),
                  (this.connectedDebounced = e);
              }, u));
      },
    },
  },
  mounted() {
    let timeoutPermissions = null;
    timeoutPermissions = setTimeout(() => {
      this.notificationsPermissionState = classNotification?.getPermissions();
    }, 2000);

    classNotification.onDenied = () => {
      if (timeoutPermissions) {
        clearTimeout(timeoutPermissions);
        timeoutPermissions = null;
      }
      this.notificationsPermissionState = "denied";
    };
    classNotification.onGranted = () => {
      if (timeoutPermissions) {
        clearTimeout(timeoutPermissions);
        timeoutPermissions = null;
      }
      this.notificationsPermissionState = "granted";
    };
  },
  beforeUnmount() {
    classNotification.onDenied = null;
    classNotification.onGranted = null;
  },
  methods: {
    normalizeLimitNamespaceRaw(e) {
      return e && e.type && e.classifier ? `${e.type}:${e.classifier}` : null;
    },
    formatLimitNamespaceToText(e) {
      let t =
        "POPUPS.MANAGE_LIMIT.NAMESPACE_" + e.split(":").join("_").toUpperCase();
      return this.$te(t)
        ? this.$filters.format.titlecase(this.$t(t))
        : `'${e}'`;
    },
    onTrialExpireSoonBannerClosed() {
      this.isTrialExpireSoonBannerClosed = true;
    },
    onUnpaidSubscriptionBannerClosed() {
      this.isUnpaidSubscriptionBannerClosed = true;
    },
    onSubscriptionPaymentMethodBannerClosed() {
      this.onMissPaymentMethodBannerClosed = true;
    },
    onAskForNotificationsBannerClosed() {
      this.askForNotificationsBannerClosed = false;
    },
    // onUpdateApp() {
    //   this.isUpdateBannerClosed = true;
    //   // r.default.quitAndInstall();
    // },
    // onUpdateBannerClosed() {
    //   this.isUpdateBannerClosed = true;
    // },
    // onPlanRecentlyExpiredBannerClosed() {
    //   this.isPlanRecentlyExpiredBannerClosed = true;
    // },
    // onInvalidScheduleBannerClosed() {
    //   this.isInvalidScheduleBannerClosed = true;
    // },
    // onOfflineScheduleBannerClosed() {
    //   this.isOfflineScheduleBannerClosed = true;
    // },
    // onChangelogBannerClosed() {
    //   // n.default.logEvent("Changelog dismissed");
    //   this.isChangelogBannerClosed = true;
    //   this.$store.dispatch("alerts/readAllUpdates");
    // },
    // onUsageQuotaAlmostReachedBannerClosed() {
    //   this.isUsageQuotaAlmostReachedBannerClosed = true;
    // },
    // onUsageQuotaReachedBannerClosed() {
    //   this.isUsageQuotaReachedBannerClosed = true;
    // },
    onBannerActionClick() {
      switch (this.bannerType) {
        case "trialExpireSoon":
          this.$router.push({ name: "app.settings.billing" });
          break;
        case "unpaidSubscription":
          this.$router.push({ name: "app.settings.billing" });
          break;
        case "missingPaymentMethod":
          this.$router.push({ name: "app.settings.billing" });
          break;
        case "askForNotifications":
          classNotification.loadNotifications();
          this.$router.push({ name: "app.settings.notifications" });
          break;
        // case "updateApp":
        //   this.onUpdateApp();
        //   break;
        // case "planRecentlyExpired":
        //   // s.default.show(this.websiteId);
        //   break;
        // case "planSandbox":
        //   // o.default.openExternal(
        //   //   this.$CONFIG.CRISP_URL_HELP + "/en/article/7i77eu/",
        //   // );
        //   break;
        // case "disconnected":
        // case "reconnecting":
        //   // o.default.openExternal(
        //   //   this.$CONFIG.CRISP_URL_HELP + "/en/article/pcoi37/",
        //   // );
        //   break;
        // case "offlineSchedule":
        // case "invalidSchedule": {
        //   let e = {};
        //   "invalidSchedule" === this.bannerType && (e.updateTimezone = true),
        //     "offlineSchedule" === this.bannerType && (e.updateStealth = true),
        //     this.$router
        //       .push({
        //         name: "app.settings.availability",
        //         params: e,
        //       })
        //       .catch(() => {});
        //   break;
        // }
        // case "changelog":
        //   // l.default.show();
        //   // n.default.logEvent("Changelog opened");
        //   break;
        // case "usageQuotaReached":
        // case "usageQuotaAlmostReached":
        //   this.$router
        //     .push({
        //       name: "app.settings.websites.one",
        //       params: {
        //         websiteId: this.websiteId,
        //         usageNamespace:
        //           this.namespaceUsageQuotaReached ||
        //           this.namespaceUsageQuotaAlmostReached,
        //       },
        //     })
        //     .catch(() => {});
        //   break;
      }
    },
    onCloseBanner() {
      switch (this.bannerType) {
        case "trialExpireSoon":
          this.onTrialExpireSoonBannerClosed();
          break;
        case "unpaidSubscription":
          this.onUnpaidSubscriptionBannerClosed();
          break;
        case "askForNotifications":
          this.onAskForNotificationsBannerClosed();
          break;
        // case "updateApp":
        //   this.onUpdateBannerClosed();
        //   break;
        // case "planRecentlyExpired":
        //   this.onPlanRecentlyExpiredBannerClosed();
        //   break;
        // case "invalidSchedule":
        //   this.onInvalidScheduleBannerClosed();
        //   break;
        // case "offlineSchedule":
        //   this.onOfflineScheduleBannerClosed();
        //   break;
        // case "changelog":
        //   this.onChangelogBannerClosed();
        //   break;
        // case "usageQuotaReached":
        //   this.onUsageQuotaReachedBannerClosed();
        //   break;
        // case "usageQuotaAlmostReached":
        //   this.onUsageQuotaAlmostReachedBannerClosed();
        //   break;
      }
    },
  },
};
</script>
<style lang="scss">
.c-general-banner {
  z-index: 100000000;
  .c-general-banner__banner {
    -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05);
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05);
    -webkit-animation-name: deployBanner;
    animation-name: deployBanner;
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-direction: both;
    animation-direction: both;
    .c-base-banner__content {
      margin-top: -2px;
      line-height: 16px;
    }
  }
}
</style>
