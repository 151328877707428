import a from "./i18n.js";
import o from "./filters.js";
import n from "./stringHelper.js";
import dayjs from "dayjs";

const s = 6e4;
const l = 1e3;
const c = 60;
const d = 3600;
const u = 86400;
const M = 60;
const g = 3;
const p = 12e4;
const b = 0;
const m = 0;
const N = 0;
const I = 0;
const f = 999;
const x = 59;
const h = 59;
const j = 23;
let L = [];
let y = [];
let D = "";
let _ = "";
let w = "";
let T = "";
let S = "";
let A = "";

class _date {
  updateDateInstances() {
    const e = a.getInstance().messages[a.getInstance().locale];
    (y = e.FILTERS.DATE.MONTHS),
      (D = e.FILTERS.DATE.POINTS.SECOND),
      (_ = e.FILTERS.DATE.POINTS.MINUTE),
      (w = e.FILTERS.DATE.POINTS.HOUR),
      (T = e.FILTERS.DATE.POINTS.DAY),
      (S = e.FILTERS.DATE.POINTS.YESTERDAY),
      (A = e.FILTERS.DATE.POINTS.NOW),
      this.__buildLangMonths();
  }
  toDate(e) {
    return new Date(e);
  }
  offsetToLocalTime(e = 0) {
    let t = new Date().getTimezoneOffset(),
      i = new Date(Date.now() - (e - t) * s),
      a = i.getHours() >= 12,
      n = i.getHours() > 12 ? i.getHours() - 12 : i.getHours(),
      r = o.pad(i.getMinutes()),
      l = a ? "pm" : "am";
    return `${n}:${r}${l}`;
  }
  dateRead(e) {
    return new Date(e).toLocaleDateString();
  }
  dateTimeRead(e) {
    let t = new Date(e);
    return t.toLocaleDateString() + " " + t.toLocaleTimeString();
  }
  dateTimeDayJS(e) {
    return dayjs(e).format("DD/MM/YY [às] HH:mm");
  }
  dateFormatDayJS(e, format) {
    return dayjs(e).format(format);
  }
  utcTimezone(e) {
    let t = parseInt((e || 0) / M, 10);
    return (t = t < 0 ? "UTC+" + Math.abs(t) : "UTC-" + Math.abs(t)), t;
  }
  monthYear(e, t = "full", i = !1, a = !0, o = !0, n = !1) {
    let r = "",
      s = new Date().getYear();
    !0 === i &&
      ((e.getYear() === s && !1 === o) || !0 === o) &&
      (r += e.getDate() + " ");
    let l = L[t][e.getMonth()];
    return (
      (r += l),
      ((!0 !== i && !0 === a) || e.getYear() !== s || !0 === n) &&
        (r += " " + e.getFullYear()),
      r
    );
  }
  monthYearFromTimestamp(e, t = "full", i = !1, a = !0, o = !0, n = !1) {
    return this.monthYear(new Date(e), t, i, a, o, n);
  }
  hoursMinutes(e, t = !1) {
    let i = o.pad(e.getHours()) + ":" + o.pad(e.getMinutes());
    return !0 === t && (i += ":" + o.pad(e.getSeconds())), i;
  }
  hoursMinutesFromTimestamp(e, t = !1) {
    return this.hoursMinutes(new Date(e), t);
  }
  timeAgo(e, t = !1) {
    let i,
      o,
      r = !1,
      s = new Date(e),
      M = new Date();
    if (
      ((i = Math.round((M.getTime() - s.getTime()) / l)),
      i < c
        ? (o = n.lowercase(A))
        : i < d
        ? (o = Math.floor(i / c) + "" + n.lowercase(_)[0])
        : i < u
        ? ((o = Math.floor(i / d) + "" + n.lowercase(w)[0]), (r = t && !0))
        : i < 2 * u && M.getDate() - s.getDate() === 1
        ? ((o = S), (r = t && !0))
        : ((o = this.monthYear(s, "short", !0, !0, t)), (r = t && !0)),
      !0 === r)
    )
      try {
        let e = s.toLocaleString(a.getInstance().locale, {
          hour: "numeric",
          minute: "numeric",
        });
        o += ` (${e})`;
        //eslint-disable-next-line
      } catch (g) {}
    return o;
  }
  timeAgoWithHour(e) {
    return this.timeAgo(e, !0);
  }
  dateFormat(e, t = !1) {
    let i = "0:00";
    e = Math.round(e);
    let a = Math.floor(e / l),
      n = Math.floor(a / c) % c,
      r = Math.floor(a / d);
    return (
      (a = (a % c) % d),
      (i =
        r > 0 || !0 === t
          ? `${r}:${o.pad(n)}:` + o.pad(a)
          : `${n}:${o.pad(a)}`),
      i
    );
  }
  duration(e, t = -1) {
    let i,
      a,
      o = Math.round(e / l);
    o < c
      ? ((a = 1), (i = D))
      : o < d
      ? ((a = c), (i = _))
      : o < u
      ? ((a = d), (i = w))
      : ((a = u), (i = T));
    let r = Math.floor(o / a) + "" + n.lowercase(i)[0];
    return t > -1 && e >= t && (r += "+"), r;
  }
  formatTime(e) {
    let t = "0:00";
    if (e >= 0) {
      e = Math.round(e);
      let i = Math.floor(e / c),
        a = e % c;
      t = `${i}:${o.pad(a)}`;
    }
    return t;
  }
  approximateTimeSpent(e, t) {
    let i = 0;
    if (t && e && e.length > 0) {
      let a,
        o = [],
        n = 0;
      e.forEach((e) => {
        "number" === typeof e[t] && o.push(e[t]);
      }),
        o.sort(),
        o.forEach((e) => {
          (a = e - n), (n = e), a <= p && (i += a);
        });
    }
    return i;
  }
  __buildLangMonths() {
    (L = {
      full: y,
      short: y.map((e) => "" + e.substr(0, g)),
    }),
      L["short"].forEach((e, t) => {
        let i = L["short"]
          .map((i, a) => (a !== t && i === e ? a : void 0))
          .filter((e) => e);
        i.length > 0 &&
          ((L["short"][t] = "" + L["full"][t].substr(0, g + 1)),
          i.forEach((e) => {
            L["short"][e] = "" + L["full"][e].substr(0, g + 1);
          }));
      });
  }
  rangeForSplit(e, t = "daily") {
    switch (t) {
      case "hourly":
        return this.rangeDayMonthYear(e);
      case "daily":
        return this.rangeWeekMonthYear(e);
      case "weekly":
        return this.rangeMonthYear(e);
      case "monthly":
        return this.rangeYear(e);
      default:
        return "—";
    }
  }
  rangeDayMonthYear(e) {
    let t = new Date(new Date(e).setDate(e.getDate() + 1));
    if ((t.setHours(t.getHours() - 1), e.getDate() === t.getDate()))
      return t.getDate() + " " + this.monthYear(e, "full", !1, !1);
    let i = this.monthYear(e, "short", !1, !1),
      a = this.monthYear(t, "short", !1, !1);
    return `${e.getDate()} ${i} - ${t.getDate()} ${a}`;
  }
  rangeWeekMonthYear(e, t = "full", i = !1) {
    let a,
      o,
      n,
      r = new Date(new Date(e).setDate(e.getDate() + 6));
    return (
      e.getMonth() !== r.getMonth() || e.getFullYear() !== r.getFullYear()
        ? ((o = this.monthYear(e, "short", !1, i)),
          (n = this.monthYear(r, "short", !1, i)),
          (a = `${e.getDate()} ${o} - ${r.getDate()} ${n}`))
        : ((o = this.monthYear(e, t, !1, i)),
          (n = this.monthYear(r, t, !1, i)),
          (a = `${e.getDate()} - ${r.getDate()} ` + o)),
      a
    );
  }
  rangeMonthYear(e) {
    let t = new Date(new Date(e).setMonth(e.getMonth() + 1));
    return (
      t.setDate(t.getDate() - 1),
      e.getMonth() === t.getMonth()
        ? this.monthYear(e, "full", !1, !1)
        : this.monthYear(e, "short", !1, !1) +
          " - " +
          this.monthYear(t, "short", !1, !1)
    );
  }
  rangeYear(e, t = "short") {
    let i = new Date(new Date(e).setFullYear(e.getFullYear() + 1));
    return (
      i.setDate(i.getDate() - 1),
      e.getFullYear() === i.getFullYear()
        ? "" + e.getFullYear()
        : this.monthYear(e, t, !1, !1) + " - " + this.monthYear(i, t, !1, !1)
    );
  }
  isCurrentForSplit(e, t = "daily") {
    switch (t) {
      case "hourly":
        return this.isCurrentDay(e);
      case "daily":
        return this.isCurrentWeek(e);
      case "weekly":
        return this.isCurrentMonth(e);
      case "monthly":
        return this.isCurrentYear(e);
      default:
        return !1;
    }
  }
  isTooFarInPast(e, t = "year") {
    let i = new Date();
    switch (t) {
      case "month":
        i.setMonth(i.getMonth() - 1);
        break;
      default:
        i.setFullYear(i.getFullYear() - 1);
    }
    return i.setDate(i.getDate() + 1), e <= i;
  }
  isCurrentDay(e) {
    let t = new Date();
    return (
      e.getDate() === t.getDate() &&
      e.getMonth() === t.getMonth() &&
      e.getFullYear() === t.getFullYear()
    );
  }
  isCurrentWeek(e) {
    let t = new Date(),
      i = new Date(
        new Date(t).setDate(
          t.getDate() - (0 === t.getDay() ? 6 : t.getDay() - 1),
        ),
      ),
      a = new Date(new Date(i).setDate(i.getDate() + 6));
    return (
      i.setMilliseconds(b),
      i.setSeconds(m),
      i.setMinutes(N),
      i.setHours(I),
      a.setMilliseconds(f),
      a.setSeconds(x),
      a.setMinutes(h),
      a.setHours(j),
      e >= i && e <= a
    );
  }
  isCurrentMonth(e) {
    let t = new Date();
    return e.getMonth() === t.getMonth() && e.getFullYear() === t.getFullYear();
  }
  isCurrentYear(e) {
    let t = new Date();
    return e.getFullYear() === t.getFullYear();
  }
  weekDayLocale(weekday) {
    return a.i18n.global.t(
      `SETTINGS.AVAILABILITY.DAYS_LONG.${weekday.toUpperCase()}`,
    );
  }
}

export default new _date();
