import shortcuts from "@/classes/talqui/shortcuts.js";

export default {
  namespaced: true,
  state: {
    results: {},
    resultTags: {},
    resultArray: [],
    loading: false,
  },
  getters: {
    getLoading: (state) => state.loading,
    getResults: (state) => state.results,
    getResultTags: (state) => state.resultTags,
    getResultArray: (state) => state.resultArray,
  },
  mutations: {
    reset(state) {
      state.loading = false;
      state.results = {};
      state.resultTags = {};
      state.resultArray = [];
      shortcuts.reset();
    },

    addTag(state, { value: t }) {
      state.resultTags[t] || (state.resultTags[t] = []);
    },

    updateResults(state, { value }) {
      state.results = value;
      state.resultTags = {};
      state.resultArray = [];

      for (let i in value) {
        let __shortcut = value[i];
        let __tag = __shortcut.shortcutTag;
        state.resultTags[__tag] = state.resultTags[__tag] || [];
        state.resultTags[__tag].push(__shortcut);
      }

      for (let _tag in state.resultTags) {
        state.resultTags[_tag].forEach((t) => {
          state.resultArray.push(t);
        });
      }
    },

    setLoading(state, { value: t }) {
      state.loading = t;
    },
  },
};
