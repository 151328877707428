/* eslint-disable */

// var a = require("core-js"); POSSÍVEL _.isEqual
import o from "@/classes/plugins";
import n from "@/classes/helpers";

const s = 12e5;
const l = {
  actions: {
    deployed: !0,
    order: 12,
  },
  call: {
    deployed: !0,
    order: 1,
  },
  data: {
    deployed: !0,
    order: 8,
  },
  devices: {
    deployed: !0,
    order: 5,
  },
  events: {
    deployed: !1,
    order: 9,
  },
  history: {
    deployed: !0,
    order: 6,
  },
  main: {
    deployed: !0,
    order: 4,
  },
  notepad: {
    deployed: !1,
    order: 10,
  },
  participants: {
    deployed: !0,
    order: 11,
  },
  rating: {
    deployed: !0,
    order: 3,
  },
  routing: {
    deployed: !0,
    order: 2,
  },
  segments: {
    deployed: !0,
    order: 7,
  },
};

export default {
  namespaced: !0,
  state: {
    widgets: {},
    pluginsWidgets: {},
    pluginsWidgetsLoading: {},
    pluginsWidgetsLastAcquire: {},
    expandedWidget: null,
  },
  getters: {
    getWidgets: (e) => (t) => e.widgets[t],
    getPluginsWidgets: (e) => (t) => e.pluginsWidgets[t],
    getExpandedWidget: (e) => e.expandedWidget,
  },
  actions: {
    acquirePluginsWidgets(
      { getters: e, commit: t, dispatch: i, state: a },
      { websiteId: r },
    ) {
      let l = Date.now();
      return (
        t("assertPluginsWidgets", {
          websiteId: r,
        }),
        Object.keys(e.getPluginsWidgets(r)).length > 0 &&
        a.pluginsWidgetsLastAcquire[r] &&
        l - a.pluginsWidgetsLastAcquire[r] < s
          ? (i("syncWidgets", {
              websiteId: r,
            }),
            Promise.resolve())
          : !0 === a.pluginsWidgetsLoading[r]
            ? Promise.resolve()
            : (t("setPluginsWidgetsLoading", {
                websiteId: r,
                value: !0,
              }),
              n
                .pageResolverToEnd((e) =>
                  o.widgets().one(r).one("schemas", e).get(),
                )
                .then((e) => {
                  let t = {};
                  for (let i = 0; i < e.length; i++) {
                    let a = e[i];
                    t[a.plugin_id] = a;
                  }
                  return Promise.resolve(t);
                })
                .catch(() => Promise.resolve({}))
                .then(
                  (e) => (
                    t("setPluginsWidgets", {
                      websiteId: r,
                      value: e,
                    }),
                    t("setPluginsWidgetsLoading", {
                      websiteId: r,
                      value: !1,
                    }),
                    t("setPluginsWidgetsLastAcquire", {
                      websiteId: r,
                      value: l,
                    }),
                    i("syncWidgets", {
                      websiteId: r,
                    }),
                    Promise.resolve()
                  ),
                ))
      );
    },
    syncWidgets({ commit: e, getters: t }, { websiteId: i }) {
      let a = t.getWidgets(i),
        o = t.getPluginsWidgets(i),
        n = [],
        r = [],
        s = [],
        l = 0;
      e("assertWidgets", {
        websiteId: i,
      });
      for (let c in o) {
        let e = !1;
        for (let t in a)
          if (t === c) {
            e = !0;
            break;
          }
        !1 === e ? n.push(c) : r.push(c);
      }
      for (let c in a)
        if ((a[c].order > l && (l = a[c].order), !0 === a[c].fromPlugin)) {
          let e = !1;
          for (let t in o)
            if (c === t) {
              e = !0;
              break;
            }
          !1 === e && s.push(c);
        }
      s.forEach((t) => {
        e("removeWidget", {
          websiteId: i,
          widgetId: t,
        });
      }),
        n.forEach((t) => {
          l++;
          let a = {
            deployed: !0,
            order: l,
            fromPlugin: !0,
          };
          o[t].schema.sections &&
            ((a.deployedSections = {}),
            o[t].schema.sections.forEach((e) => {
              a.deployedSections[e.id] = !0;
            })),
            e("setWidget", {
              websiteId: i,
              widgetId: t,
              value: a,
            });
        }),
        r.forEach((t) => {
          if (o[t].schema.sections) {
            let n = a[t],
              r = [];
            e("assertWidgetDeployedSections", {
              websiteId: i,
              widgetId: t,
            }),
              o[t].schema.sections.forEach((a) => {
                r.push(a.id),
                  "undefined" === typeof n.deployedSections[a.id] &&
                    e("setWidgetSectionDeployed", {
                      websiteId: i,
                      widgetId: t,
                      sectionId: a.id,
                      value: !0,
                    });
              });
            for (let a in n.deployedSections)
              -1 === r.indexOf(a) &&
                e("removeWidgetSectionDeployed", {
                  websiteId: i,
                  widgetId: t,
                  sectionId: a,
                });
          }
        });
    },
  },
  mutations: {
    reset(e) {
      e.widgets = {};
      e.pluginsWidgets = {};
      e.pluginsWidgetsLoading = {};
      e.pluginsWidgetsLastAcquire = {};
      e.expandedWidget = null;
    },
    assertWidgets(e, { websiteId: t }) {
      if (e.widgets[t]) {
        // e.widgets[t][i] || (e.widgets[t][i] = (0, a.default)(l[i]));
        for (let i in l) {
          // console.log(e.widgets[t][i], l[i]);
          e.widgets[t][i] || (e.widgets[t][i] = l[i]);
        }
      } else {
        e.widgets[t] = {};
        for (let i in l) {
          e.widgets[t][i] = l[i];
        }
      }
    },
    assertWidgetDeployedSections(e, { websiteId: t, widgetId: i }) {
      e.widgets[t][i].deployedSections ||
        (e.widgets[t][i].deployedSections = {});
    },
    setWidget(e, { websiteId: t, widgetId: i, value: a }) {
      e.widgets[t][i] = a;
    },
    removeWidget(e, { websiteId: t, widgetId: i }) {
      delete e.widgets[t][i];
    },
    setWidgetOrder(e, { websiteId: t, widgetId: i, value: a }) {
      e.widgets[t][i].order = a;
    },
    setWidgetDeployed(e, { websiteId: t, widgetId: i, value: a }) {
      e.widgets[t][i].deployed = a;
    },
    setWidgetSectionDeployed(
      e,
      { websiteId: t, widgetId: i, sectionId: a, value: o },
    ) {
      e.widgets[t][i].deployedSections[a] = o;
    },
    removeWidgetSectionDeployed(
      e,
      { websiteId: t, widgetId: i, sectionId: a },
    ) {
      delete e.widgets[t][i].deployedSections[a];
    },
    assertPluginsWidgets(e, { websiteId: t }) {
      e.pluginsWidgets[t] || (e.pluginsWidgets[t] = {}),
        e.pluginsWidgetsLoading[t] || (e.pluginsWidgetsLoading[t] = !1),
        e.pluginsWidgetsLastAcquire[t] ||
          (e.pluginsWidgetsLastAcquire[t] = null);
    },
    setPluginsWidgets(e, { websiteId: t, value: i }) {
      e.pluginsWidgets[t] = i;
    },
    setPluginsWidgetsLoading(e, { websiteId: t, value: i }) {
      e.pluginsWidgetsLoading[t] = i;
    },
    setPluginsWidgetsLastAcquire(e, { websiteId: t, value: i }) {
      e.pluginsWidgetsLastAcquire[t] = i;
    },
    setExpandedWidget(e, { value: t }) {
      e.expandedWidget = t;
    },
  },
};
