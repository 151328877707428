const a = /min:(\d*)/;
const o = /max:(\d*)/;
const n = /^\s+(.+?)$/;
const r = /^(.+?)\s+$/;

export default {
  computed: {
    minLength() {
      if ("object" === typeof this.rules) return (this.rules || {}).min || null;
      const e = (this.rules || "").match(a);
      return e && e[1] ? e[1] : null;
    },
    maxLength() {
      if ("object" === typeof this.rules) return (this.rules || {}).max || null;
      const e = (this.rules || "").match(o);
      return e && e[1] ? e[1] : null;
    },
    validationLabel() {
      return this.name
        ? this.$te("VALIDATION.FIELDS." + this.name.toUpperCase())
          ? this.$t("VALIDATION.FIELDS." + this.name.toUpperCase())
          : this.name
        : this.uuid;
    },
  },
  methods: {
    ignoreModelSync(e) {
      const t = (this.currentValue || "").toString();
      if (this.hasTags) return !0;
      if (e === t) return !0;
      if (!0 === this.trim) {
        if (" " + e === t || e + " " === t) return !0;
        let i = null,
          a = null;
        if (
          ((a = t.match(n)),
          a && a[1] ? (i = a[1]) : ((a = t.match(r)), a && a[1] && (i = a[1])),
          i && i === e)
        )
          return !0;
      }
      return !1;
    },
  },
};
