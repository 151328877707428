import o from "@/classes/platform.js";
import debounce from "lodash.debounce";

const r = 1440;
const s = 980;
const l = 900;
const c = 1140;
const d = 1380;
const u = 670;
const M = 705;
const g = 870;
const p = 500;
const b = window.screen.availWidth >= r;
const m = window.screen.availHeight >= s;
const N = {
  default: {
    width: b ? d : c,
    height: m ? g : M,
  },
  minimum: {
    width: l,
    height: u,
  },
};

class _platformAssistant {
  constructor() {
    (this.forceQuit = !1), (this.forceReload = !1);
  }
  createWindow() {
    o.isPlatform() && (this.disableZoom(), this.track(), this.restore());
  }
  track() {
    if (!o.isPlatform()) return !1;
    window.WindowPlatformBridge.trackWindow(debounce(this.saveState, p));
  }
  saveState(e) {
    localStorage.setItem("crisp_window", JSON.stringify(e));
  }
  restore() {
    if (!o.isPlatform()) return !1;
    let e = {
      height: N.height,
      width: N.width,
    };
    if (null !== localStorage.getItem("crisp_window"))
      try {
        e = JSON.parse(localStorage.getItem("crisp_window"));
        // eslint-disable-next-line no-empty
      } catch (t) {}
    e.width >= window.screen.availWidth && (e.width = N.width),
      e.height >= window.screen.availHeight && (e.height = N.height),
      window.WindowPlatformBridge.restore(
        e.width || N.width,
        e.height || N.height,
        N.minimum.width,
        N.minimum.height,
      );
  }
  disableZoom() {
    if (!o.isPlatform()) return !1;
    window.WindowPlatformBridge.disableZoom();
  }
  showWindow() {
    if (!o.isPlatform()) return !1;
    window.WindowPlatformBridge.show();
  }
  hideWindow() {
    if (!o.isPlatform()) return !1;
    window.WindowPlatformBridge.hide();
  }
  resetForces() {
    if (!o.isPlatform()) return !1;
    (this.forceQuit = !1), (this.forceReload = !1);
  }
  setForceQuit() {
    this.forceQuit = !0;
  }
  setForceReload() {
    this.forceReload = !0;
  }
}

export default new _platformAssistant();
