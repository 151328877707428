const o = 80;
const n = 50;
const r = 1500;
const s = /.*/;
const l = /[-/\\^$*+?.()|[\]{}]/g;
const c = /^https?:\/\/(?:www\.)?youtube\.com\/watch\?v=([^&]+)/;

function a() {
  return (
    //eslint-disable-next-line
    (a = Object.assign
      ? Object.assign.bind()
      : function (e) {
          for (var t = 1; t < arguments.length; t++) {
            var i = arguments[t];
            for (var a in i)
              Object.prototype.hasOwnProperty.call(i, a) && (e[a] = i[a]);
          }
          return e;
        }),
    a.apply(this, arguments)
  );
}

class _helpers {
  cleanMap(e, t = !0) {
    let i = !0 === t ? {} : void 0;
    if ("object" === typeof e) {
      if (Object.keys(e || {}).length > 0) {
        let t;
        for (let i in e) {
          let a = this.cleanMap(e[i], !1);
          void 0 !== a &&
            (void 0 === t && (t = e instanceof Array ? [] : {}), (t[i] = a));
        }
        return void 0 !== t ? t : i;
      }
      return i;
    }
    return null === e || void 0 === e || "" === e ? i : e;
  }
  emptyList(e) {
    e && e.splice(0, e.length);
  }
  emptyMap(e, t = null) {
    if (e) for (let i in e) (null !== t && i === t) || delete e[i];
  }
  replaceList(e, t = []) {
    this.emptyList(e), a(e, t);
  }
  replaceMap(e, t = {}) {
    this.emptyMap(e), a(e, t);
  }
  fuzzySearchRegex(e, t = []) {
    let i, a, o;
    return (
      (o = (e || "").toLowerCase()),
      t.forEach((e) => {
        o = o.replace(e, "");
      }),
      (a = []),
      o.split(" ").forEach((e) => {
        e && -1 === t.indexOf(e) && a.push(this.regexEscape(e));
      }),
      (i = 0 === a.length ? s : new RegExp(a.join("|"), "i")),
      i
    );
  }
  pageResolverToEnd(e, t = -1) {
    let i = (a, o, n, r) => {
      e(a)
        .then((e) => {
          e.forEach((e) => {
            o.push(e);
          }),
            (o.__status = e.__status),
            t >= 1 && a === t
              ? n(o)
              : e.length > 0 && 206 === e.__status
              ? i(a + 1, o, n, r)
              : n(o);
        })
        .catch(r);
    };
    return new Promise((e, t) => {
      let a = [];
      i(1, a, e, t);
    });
  }
  percentToHeatLabel(e, t, i, a) {
    return e >= o ? t : e >= n ? i : a;
  }
  regexEscape(e) {
    return e.replace(l, "\\$&");
  }
  extractYouTubeVideoId(e) {
    let t = e.match(c);
    return t && t[1] ? t[1] : null;
  }
  temporizeDataAccess(e, t = 0) {
    return new Promise((i) => {
      setTimeout(() => {
        i(e);
      }, t || r);
    });
  }
}

export default new _helpers();
