import a from "@/enviroment.js";
import o from "@/components/Base/BaseToast.vue";

class _uploadFactory {
  proceed(e) {
    let t = new XMLHttpRequest();
    let i = {};
    i.progress = () => {
      this.handleProgress(e);
    };
    i.fail = () => {
      this.handleFail(e, t, i);
    };
    i.load = () => {
      this.handleLoad(e, t, e.states.id, i);
    };
    e.states.files[0]
      ? e.action && e.resource
        ? e.states.files[0].size >= e.size_limit
          ? (o.warning("factory_upload_too_large"), this.handleDone(e, t, i))
          : ((t.file = e.states.files[0]),
            t.addEventListener("progress", i.progress, !1),
            t.addEventListener("error", i.fail, !1),
            t.addEventListener("abort", i.fail, !1),
            t.addEventListener("load", i.load, !1),
            t.open("PUT", e.action, !0),
            t.setRequestHeader("Content-Disposition", "attachment"),
            t.send(e.states.files[0]))
        : (o.warning("factory_upload_policy"), this.handleDone(e, t, i))
      : this.handleFail(e, t, i);
  }
  handleDone(e, t, i) {
    t &&
      (t.removeEventListener("progress", i.progress, !1),
      t.removeEventListener("error", i.fail, !1),
      t.removeEventListener("abort", i.fail, !1),
      t.removeEventListener("load", i.load, !1)),
      e.field && (e.field.value = null),
      (e.states.uploading = !1),
      e.states.files.shift(),
      e.fnClean && e.fnClean(e.states),
      e.states.files.length > 0 && e.fnNext && e.fnNext(e.states);
  }
  handleFail(e, t, i) {
    e.states.files.length > 0 && o.warning("factory_upload_error"),
      e.fnFail && e.fnFail(),
      this.handleDone(e, t, i);
  }
  handleLoad(e, t, i, a) {
    i === e.states.id && this.handleSuccess(e, t, a);
  }
  handleProgress(e) {
    e.states.uploading = !0;
  }
  handleSuccess(e, t, i) {
    Promise.resolve()
      .then(() => {
        if (e.states.files.length > 0 && e.fnDone) {
          let t = e.fnDone(
            e.states,
            e.resource,
            e.states.files[0].name || a.CRISP_FILE_NAME_DEFAULT,
            e.states.files[0].type || a.CRISP_FILE_TYPE_DEFAULT,
          );
          if (t && "function" === typeof t.then) return t;
        }
        return Promise.resolve();
      })
      .then(() => {
        this.handleDone(e, t, i);
      });
  }
}
export default new _uploadFactory();
