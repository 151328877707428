<template>
  <div
    :class="[
      'c-field-key-value',
      'c-field-key-value--' + size,
      {
        'c-field-input--block': block,
        'c-field-key-value--dark': dark,
        'c-field-key-value--no-value': void 0 === modelValue.value,
      },
    ]"
  >
    <input
      class="c-field-key-value__key js-field-key-value__key"
      v-on:focus="(...e) => onFocus(...e)"
      v-on:blur="(...e) => onBlur(...e)"
      v-on:input="(...e) => onKey(...e)"
      v-on:update:ModelValue="(t) => (currentKeyQuery = t)"
      :placeholder="
        this.$t('FORMS.FIELD_KEY_VALUE.FIELD_PLACEHOLDER_OBJECT_KEY')
      "
      type="text"
    />
    <input
      v-if="0 !== modelValue.value"
      class="c-field-key-value__value js-field-key-value__value"
      v-on:input="(...e) => onValue(...e)"
      v-on:update:ModelValue="(t) => (currentValueQuery = t)"
      :placeholder="
        this.$t('FORMS.FIELD_KEY_VALUE.FIELD_PLACEHOLDER_OBJECT_VALUES')
      "
      type="text"
    />
    <div
      v-if="autocompletion && hasFocus && computedSuggestions.length > 0"
      class="c-field-key-value__suggestions"
    >
      <div
        v-for="item in computedSuggestions"
        :key="item"
        :innerHTML="
          this.$filters.format.emphasizeWithQuery(item, currentKeyQuery)
        "
        v-on:mouseDown="(e) => onSelectSuggestion(item, e)"
        class="c-field-key-value__suggestion"
      ></div>
    </div>
  </div>
</template>
<script>
const n = 500;
const r = 100;
import debounce from "lodash.debounce";
export default {
  props: {
    block: {
      type: Boolean,
      default: !0,
    },
    dark: {
      type: Boolean,
      default: !1,
    },
    name: {
      type: String,
      required: !0,
    },
    size: {
      type: String,
      default: "default",
      validator(e) {
        return -1 !== ["small", "default"].indexOf(e);
      },
    },
    modelValue: {
      type: Object,
      default() {
        return {
          key: "",
          value: [],
        };
      },
    },
    autocompletion: {
      type: Boolean,
      default: !1,
    },
    suggestions: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  emits: ["update:modelValue", "input"],
  data: () => ({
    hasFocus: !1,
    currentKeyQuery: "",
    currentValueQuery: "",
  }),
  computed: {
    computedSuggestions() {
      let e = this.currentKeyQuery || this.modelValue.key;
      return this.suggestions
        .map((e) => e.key)
        .filter(
          (t) =>
            t.toLowerCase().startsWith(e.toLowerCase()) &&
            t.toLowerCase() !== e.toLowerCase(),
        );
    },
  },
  watch: {
    modelValue: {
      deep: !0,
      immediate: !0,
      handler(e) {
        e.key && (this.currentKeyQuery = e.key),
          (this.currentValueQuery = this.formatValue(e.value));
      },
    },
  },
  methods: {
    formatValue(e) {
      return e ? e.join(",") : e;
    },
    getKeyValue() {
      let e = {
        key: this.currentKeyQuery || "",
      };
      if (void 0 !== this.modelValue.value) {
        let t = this.currentValueQuery || "",
          i = t.split(",").map((e) => e.trim()),
          a = new Set(i);
        (a = a.values()), (e.value = Array.from(a));
      }
      return e;
    },
    onFocus() {
      setTimeout(() => {
        this.hasFocus = !0;
      }, r);
    },
    onBlur() {
      setTimeout(() => {
        this.hasFocus = !1;
      }, r);
    },
    onKey(e) {
      this.onInput("key", e);
    },
    onValue(e) {
      this.onInput("value", e);
    },
    onSelectSuggestion(e, t) {
      (this.currentKeyQuery = e), this.onInput("key", t);
    },
    onInput: debounce(function (e, t) {
      const i = this.getKeyValue();
      this.$emit("update:modelValue", i, this.name, t),
        this.$emit("input", i, this.name, t);
    }, n),
  },
};
</script>
<style lang="scss">
.c-field-key-value {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  .c-field-key-value__key {
    padding: 5px 15px;
    border: 1px solid #e3e8ef;
    background-color: #fafbfc;
    font-size: 14px;
    width: 40%;
    &::-webkit-input-placeholder {
      color: #75869c;
    }
    &::-moz-placeholder {
      color: #75869c;
    }
    &::-ms-input-placeholder {
      color: #75869c;
    }
    &::placeholder {
      color: #75869c;
    }
    &:focus {
      outline: none;
    }
  }
  .c-field-key-value__value {
    padding: 5px 15px;
    border: 1px solid #e3e8ef;
    background-color: #fafbfc;
    font-size: 14px;
    width: 60%;
    border-left: none;
    &::-webkit-input-placeholder {
      color: #75869c;
    }
    &::-moz-placeholder {
      color: #75869c;
    }
    &::-ms-input-placeholder {
      color: #75869c;
    }
    &::placeholder {
      color: #75869c;
    }
    &:focus {
      outline: none;
    }
  }
  .c-field-key-value__suggestions {
    position: absolute;
    right: 0;
    left: 0;
    top: calc(100% - 2px);
    z-index: 22;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 160px;
    margin-bottom: 10px;
    background-color: #fff;
    color: #1c293b;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
    border: 1px solid #e3e8ef;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    .c-field-key-value__suggestion {
      position: relative;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      cursor: pointer;
      padding: 6px 10px;
      font-size: 14px;
      line-height: 22px;
      border-bottom: 1px solid #e3e8ef;
      &:last-child {
        border-bottom: none;
      }
      &:hover {
        background-color: #0093ee;
        color: #fff;
      }
    }
  }
}
.c-field-key-value--small {
  height: 38px;
  .c-field-key-value__key {
    height: 38px;
    &:first-of-type {
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
    }
    &:last-of-type {
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
    }
  }
  .c-field-key-value__value {
    height: 38px;
    &:first-of-type {
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
    }
    &:last-of-type {
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
    }
  }
}
.c-field-key-value--default {
  height: 42px;
  .c-field-key-value__key {
    height: 42px;
    &:first-of-type {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }
    &:last-of-type {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }
  .c-field-key-value__value {
    height: 42px;
    &:first-of-type {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }
    &:last-of-type {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }
}
.c-field-key-value--block {
  width: 100%;
}
.c-field-key-value--no-value {
  .c-field-key-value__key {
    width: 100%;
  }
}
.c-field-key-value--dark {
  .c-field-key-value__key {
    border-color: #3f536e;
    background-color: #2c405a;
    color: #fff;
  }
  .c-field-key-value__suggestions {
    border-color: #3f536e;
    background-color: #2c405a;
    color: #fff;
    .c-field-key-value__suggestion {
      border-color: #3f536e;
      &:hover {
        border-color: #526b8e;
      }
    }
  }
  .c-field-key-value__value {
    border-color: #3f536e;
    background-color: #2c405a;
    color: #fff;
  }
}
</style>
