<template>
  <div class="c-base-viewer">
    <div
      :class="[
        'c-base-viewer__container',
        {
          [containerClass]: containerClass,
        },
      ]"
    >
      <div
        class="c-base-viewer__content"
        :style="{
          textAlign: alignment,
        }"
      >
        <div
          v-if="this.$slots.title"
          :class="[
            'c-base-viewer__title',
            {
              'u-ellipsis': titleEllipsis,
              [titleClass]: titleClass,
            },
          ]"
        >
          <div
            :class="[
              'c-base-viewer__title-text',
              'u-semibold',
              {
                'c-base-viewer__title-text--spaced': hasClose,
                'u-ellipsis': titleEllipsis,
              },
            ]"
          >
            <slot name="title"></slot>
          </div>
          <BaseIcon
            v-if="hasClose"
            class="c-base-viewer__close"
            v-on:click="onCloseClick"
            name="close"
          ></BaseIcon>
        </div>

        <div
          v-if="this.$slots.body"
          :class="[
            'c-base-viewer__body',
            'u-select',
            {
              [bodyClass]: bodyClass,
            },
          ]"
        >
          <slot name="body"></slot>
        </div>
      </div>
    </div>
    <BaseOverlay v-on:click="onOverlayClick"></BaseOverlay>
  </div>
</template>
<script>
export default {
  props: {
    alignment: {
      type: String,
      default: "left",
    },
    hasClose: {
      type: Boolean,
      default: !0,
    },
    titleClass: {
      type: String,
      default: null,
    },
    titleEllipsis: {
      type: Boolean,
      default: !1,
    },
    containerClass: {
      type: String,
      default: null,
    },
    bodyClass: {
      type: String,
      default: null,
    },
  },
  emits: ["close", "previous", "next"],
  computed: {
    hotkeys() {
      return {
        esc: this.onClose,
        left: this.onPrevious,
        right: this.onNext,
      };
    },
  },
  beforeMount() {
    document.activeElement && document.activeElement.blur();
  },
  methods: {
    onClose() {
      this.hasClose &&
        (event.stopPropagation(), event.preventDefault(), this.$emit("close"));
    },
    onPrevious() {
      this.$emit("previous");
    },
    onNext() {
      this.$emit("next");
    },
    onCloseClick() {
      this.$emit("close");
    },
    onOverlayClick() {
      !0 === this.hasClose && this.$emit("close");
    },
  },
};
</script>
<style lang="scss">
.c-base-viewer {
  z-index: 1000;
  position: fixed;
  .c-base-viewer__background {
    z-index: 1001;
  }
  .c-base-viewer__container {
    background-color: #fff;
    width: 840px;
    position: fixed;
    left: 50%;
    top: 50px;
    bottom: 0;
    z-index: 1002;
    border-radius: 2px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    -webkit-box-shadow: 0 5px 16px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 5px 16px 0 rgba(0, 0, 0, 0.16);
    opacity: 0;
    -webkit-animation: c-base-viewer-move-to-top 0.1s ease-out;
    animation: c-base-viewer-move-to-top 0.1s ease-out;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    .c-base-viewer__content {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      height: 100%;
      .c-base-viewer__body {
        padding: 0 36px;
        padding-top: 26px;
        overflow-x: hidden;
        overflow-y: auto;
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        &:after {
          content: "";
          height: 46px;
          display: block;
        }
      }
      .c-base-viewer__title {
        padding: 0 36px;
        color: #000;
        border-bottom: 1px solid #e3e8ef;
        font-size: 15.5px;
        padding-top: 18px;
        padding-bottom: 18px;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        .c-base-viewer__title-text {
          -webkit-box-flex: 1;
          -ms-flex: 1;
          flex: 1;
        }
        .c-base-viewer__title-text--spaced {
          margin-right: 12px;
        }
        .c-base-viewer__close {
          fill: #000;
          margin-right: -6px;
          opacity: 0.5;
          -webkit-transition: opacity 75ms linear;
          transition: opacity 75ms linear;
          cursor: pointer;
          * {
            cursor: pointer;
          }
          &:hover {
            opacity: 0.8;
          }
          &:active {
            opacity: 1;
          }
        }
      }
    }
  }
}

@-webkit-keyframes c-base-viewer-move-to-top {
  0% {
    opacity: 0;
    -webkit-transform: translate(-50%, 8%);
    transform: translate(-50%, 8%);
  }

  to {
    opacity: 1;
    -webkit-transform: translate(-50%);
    transform: translate(-50%);
  }
}

@keyframes c-base-viewer-move-to-top {
  0% {
    opacity: 0;
    -webkit-transform: translate(-50%, 8%);
    transform: translate(-50%, 8%);
  }

  to {
    opacity: 1;
    -webkit-transform: translate(-50%);
    transform: translate(-50%);
  }
}

@media (max-width: 56.25em) {
  .c-base-viewer .c-base-viewer__container {
    width: 100%;
    left: 0;
    right: 0;
    opacity: 1;
    border-radius: 0;
    -webkit-animation: none;
    animation: none;
  }
}
</style>
