<template>
  <div
    :class="[
      'c-base-headlines',
      'c-base-headlines--' + color,
      'c-base-headlines--' + size,
      {
        ['c-base-headlines--' + illustration]: illustration,
        'c-base-headlines--emphasis': emphasis,
        'c-base-headlines--uppercase': uppercase,
        'c-base-headlines--has-flag': flag,
      },
    ]"
    :style="{
      textAlign: alignment,
    }"
  >
    <BaseBadge
      v-if="badge"
      class="c-base-headlines__badge"
      color="blue"
      size="small"
    >
      {{ badge }}
    </BaseBadge>
    <BaseAvatar
      v-if="avatar"
      class="c-base-headlines__avatar"
      :picture="avatar.picture"
      cursor="default"
      size="huge"
    >
    </BaseAvatar>
    <BaseIllustration
      v-if="illustration"
      class="c-base-headlines__illustration"
      :name="illustration"
    ></BaseIllustration>
    <div class="c-base-headlines__title">
      <span
        v-if="isTitleHtml"
        class="c-base-headlines__title u-mb5 u-bold"
        :innerHTML="title"
      ></span>
      <span v-else class="c-base-headlines__title u-mb5 u-bold">{{
        title
      }}</span>
      <BaseFlag
        v-if="flag"
        class="c-base-headlines__flag"
        :code="flag"
        height="15px"
        width="21px"
      ></BaseFlag>
      <div v-if="description">
        <p
          v-if="isDescriptionHtml"
          class="c-base-headlines__description"
          :innerHTML="description"
        ></p>
        <p v-else class="c-base-headlines__description">{{ description }}</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    avatar: {
      type: Object,
      default: null,
    },
    badge: {
      type: String,
      default: null,
    },
    alignment: {
      type: String,
      default: "left",
    },
    color: {
      type: String,
      default: "black",
      validator(e) {
        return -1 !== ["black", "white"].indexOf(e);
      },
    },
    description: {
      type: String,
      default: null,
    },
    flag: {
      type: String,
      default: null,
    },
    illustration: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: "default",
      validator(e) {
        return -1 !== ["small", "default", "big"].indexOf(e);
      },
    },
    title: {
      type: String,
      required: !0,
    },
    emphasis: {
      type: Boolean,
      default: !1,
    },
    uppercase: {
      type: Boolean,
      default: !1,
    },
    isTitleHtml: {
      type: Boolean,
      default: !1,
    },
    isDescriptionHtml: {
      type: Boolean,
      default: !1,
    },
  },
};
</script>
<style lang="scss">
.c-base-headlines {
  .c-base-headlines__badge {
    margin-bottom: 10px;
  }
  .c-base-headlines__avatar {
    margin: 0 auto 20px;
  }
  .c-base-headlines__illustration {
    margin-bottom: 20px;
  }
  .c-base-headlines__description {
    margin: 0;
  }
  .c-base-headlines__title {
    margin: 0;
    display: block;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-weight: 700;
    .c-base-headlines__flag {
      margin-top: -4px;
      margin-left: 12px;
    }
  }
}
.c-base-headlines--users_selected {
  .c-base-headlines__illustration {
    width: 100%;
    height: 125px !important;
  }
}
.c-base-headlines--black {
  .c-base-headlines__title {
    color: #1c293b;
  }
  .c-base-headlines__description {
    color: #75869c;
  }
}
.c-base-headlines--black.c-base-headlines--emphasis {
  .c-base-headlines__description {
    color: #273950;
  }
}
.c-base-headlines--white {
  .c-base-headlines__title {
    color: #fff;
  }
  .c-base-headlines__description {
    color: #fafbfc;
  }
}
.c-base-headlines--white.c-base-headlines--emphasis {
  .c-base-headlines__description {
    color: #fff;
  }
}
.c-base-headlines--small {
  .c-base-headlines__illustration {
    height: 100px;
  }
  .c-base-headlines__title {
    font-size: 18px;
    line-height: 32px;
  }
  .c-base-headlines__description {
    font-size: 14px;
    line-height: 20px;
  }
}
.c-base-headlines--default {
  .c-base-headlines__illustration {
    height: 150px;
  }
  .c-base-headlines__title {
    font-size: 18px;
    line-height: 28px;
  }
  .c-base-headlines__description {
    font-size: 15px;
    line-height: 20px;
  }
}
.c-base-headlines--big {
  .c-base-headlines__illustration {
    height: 200px;
  }
  .c-base-headlines__title {
    font-size: 20px;
    line-height: 30px;
  }
  .c-base-headlines__description {
    margin-top: 10px;
    font-size: 15.5px;
    line-height: 20px;
  }
}
.c-base-headlines--uppercase {
  .c-base-headlines__title {
    text-transform: uppercase;
  }
}
.c-base-headlines--has-flag {
  .c-base-headlines__title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}
</style>
