import analytics from "@/classes/talqui/analytics";

export default {
  namespaced: true,
  state: {
    cards: {},
    dependencies: {},
    graphLoading: {},
    graphs: {},
    graphChoices: {},
    graphFilter: {},
    graphData: {},
  },
  getters: {
    getGraphs: (state) => state.graphs,
    getGraphChoices: (state) => state.graphChoices,
    getGraphLoading: (state) => state.graphLoading,
    getGraphFilter: (state) => state.graphFilter,
    getDependencies: (state) => state.dependencies,
    getCards: (state) => state.cards,
  },
  actions: {
    acquireGraphData(
      { commit },
      { tenantID, type, metric, dateRange, filter = [], classifier = null },
    ) {
      const _namespace = `${type}_${metric}`;
      return analytics
        .consult({ tenantID: tenantID, namespace: _namespace })
        .post({
          query: {
            from: dateRange.from,
            to: dateRange.to,
            filter,
            classifier,
          },
        })
        .then((res) => {
          commit("setGraphData", {
            namespace: _namespace,
            value: res,
          });
          return Promise.resolve(res);
        })
        .catch((e) => {
          console.error(e);
          return Promise.resolve([]);
        });
    },
  },
  mutations: {
    setCard(state, { namespace, value }) {
      state.cards[namespace] = value;
    },
    setGraphLoading(state, { namespace, value }) {
      state.graphLoading[namespace] = value;
    },
    setGraphFilter(state, { namespace, value }) {
      state.graphFilter[namespace] = value;
    },
    setGraphData(state, { namespace, value }) {
      state.graphData[namespace] = value;
    },
    setGraph(state, { namespace, value }) {
      state.graphs[namespace] = value;
    },
    setGraphChoicesNamespace(state, { namespace, value }) {
      state.graphChoices[namespace] = value;
    },
    setGraphChoicesNamespaceKey(state, { namespace, key, value }) {
      state.graphChoices[namespace] || (state.graphChoices[namespace] = {});
      state.graphChoices[namespace][key] = value;
    },
  },
};
