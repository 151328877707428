<template>
  <div
    :class="[
      'c-base-control',
      'u-has-tooltip',
      'c-base-control--' + size,
      {
        'c-base-control--rounded': rounded,
        'c-base-control--shadowy': shadowy,
      },
    ]"
  >
    <div
      v-for="(item, index) in buttons"
      :key="index"
      v-on:click="(t) => onClick(item.id)"
      :class="[
        'c-base-control__button',
        'c-base-control__button--' + (item.color || 'grey'),
        {
          'c-base-control__button--locked': item.locked,
        },
      ]"
    >
      <BaseIcon
        class="c-base-control__icon"
        :name="item.icon"
        :size="item.iconSize || iconSize"
        color="white"
      ></BaseIcon>
      <BaseTooltip v-if="item.tooltip" color="white">{{
        item.tooltip
      }}</BaseTooltip>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    buttons: {
      type: Array,
      required: !0,
    },
    rounded: {
      type: Boolean,
      default: !0,
    },
    shadowy: {
      type: Boolean,
      default: !1,
    },
    size: {
      type: String,
      default: "large",
    },
  },
  emits: ["click"],
  computed: {
    iconSize() {
      switch (this.size) {
        case "mini":
          return "16px";
        case "small":
          return "18px";
        default:
          return "28px";
      }
    },
  },
  methods: {
    onClick(e) {
      this.$emit("click", e);
    },
  },
};
</script>
<style lang="scss">
.c-base-control {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  .c-base-control__button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-right: 1px;
    cursor: pointer;
    -webkit-transition: background-color 0.1s linear;
    transition: background-color 0.1s linear;
    &:last-of-type {
      margin-right: 0;
    }
  }
  .c-base-control__button--locked {
    pointer-events: none;
    .c-base-control__icon {
      opacity: 0.2;
    }
  }
  .c-base-control__button--blue {
    background-color: rgba(0, 147, 238, 0.8);
    &:hover {
      background-color: rgba(0, 147, 238, 0.9);
    }
    &:active {
      background-color: rgba(0, 147, 238, 0.7);
    }
  }
  .c-base-control__button--black {
    background-color: rgba(28, 41, 59, 0.6);
    &:hover {
      background-color: rgba(28, 41, 59, 0.7);
    }
    &:active {
      background-color: rgba(28, 41, 59, 0.5);
    }
  }
  .c-base-control__button--grey {
    background-color: rgba(63, 83, 110, 0.6);
    &:hover {
      background-color: rgba(63, 83, 110, 0.7);
    }
    &:active {
      background-color: rgba(63, 83, 110, 0.5);
    }
  }
  .c-base-control__button--red {
    background-color: rgba(224, 16, 43, 0.6);
    &:hover {
      background-color: rgba(224, 16, 43, 0.7);
    }
    &:active {
      background-color: rgba(224, 16, 43, 0.5);
    }
  }
}
.c-base-control--mini {
  .c-base-control__button {
    width: 24px;
    height: 24px;
    &:first-of-type {
      .c-base-control__icon {
        margin-left: 3px;
      }
    }
    &:last-of-type {
      .c-base-control__icon {
        margin-right: 3px;
      }
    }
  }
}
.c-base-control--small {
  .c-base-control__button {
    width: 30px;
    height: 30px;
    &:first-of-type {
      .c-base-control__icon {
        margin-left: 4px;
      }
    }
    &:last-of-type {
      .c-base-control__icon {
        margin-right: 4px;
      }
    }
  }
}
.c-base-control--large {
  .c-base-control__button {
    width: 52px;
    height: 52px;
    &:first-of-type {
      .c-base-control__icon {
        margin-left: 6px;
      }
    }
    &:last-of-type {
      .c-base-control__icon {
        margin-right: 6px;
      }
    }
  }
}
.c-base-control--rounded {
  .c-base-control__button {
    &:first-of-type {
      border-top-left-radius: 100%;
      border-bottom-left-radius: 100%;
    }
    &:last-of-type {
      border-top-right-radius: 100%;
      border-bottom-right-radius: 100%;
    }
  }
}
.c-base-control--shadowy {
  .c-base-control__button {
    -webkit-box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.15);
    -webkit-transition: all 0.1s linear;
    transition: all 0.1s linear;
    -webkit-transition-property:
      -webkit-box-shadow,
      -webkit-transform;
    transition-property:
      -webkit-box-shadow,
      -webkit-transform;
    transition-property: box-shadow, transform;
    transition-property:
      box-shadow,
      transform,
      -webkit-box-shadow,
      -webkit-transform;
    &:hover {
      -webkit-box-shadow: 0 10px 22px 0 rgba(0, 0, 0, 0.3);
      box-shadow: 0 10px 22px 0 rgba(0, 0, 0, 0.3);
    }
    &:active {
      -webkit-transform: translateY(1px);
      transform: translateY(1px);
      -webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
    }
  }
}
</style>
