<template>
  <div class="c-base-spinner" v-show="loading">
    <div class="c-base-spinner__clip" :style="spinnerStyle"></div>
  </div>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: "#0093ee",
    },
    loading: {
      type: Boolean,
      default: !0,
    },
    radius: {
      type: String,
      default: "100%",
    },
    size: {
      type: String,
      default: "28px",
    },
    borderWidth: {
      type: String,
      default: "3px",
    },
    speed: {
      type: String,
      default: "900ms",
    },
  },
  computed: {
    spinnerStyle() {
      return {
        height: this.size,
        width: this.size,
        borderColor: `${this.color} ${this.color} transparent`,
        borderRadius: this.radius,
        borderWidth: this.borderWidth,
        animation: `clip ${this.speed} 0s infinite linear`,
      };
    },
  },
};
</script>
<style lang="scss">
.c-base-spinner {
  text-align: center;
  line-height: 0;
  .c-base-spinner__clip {
    display: inline-block;
    border-style: solid;
    background: transparent;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
}
</style>
