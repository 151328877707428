import SmoothScroll from "smooth-scroll";
import o from "./auth.js";

const r = 500;
const s = 1e3;

class _focus {
  constructor() {
    (this.pendingFocusActions = {}),
      (this.inFocus = document.hasFocus() && !0),
      (this.smooth_scroll = new SmoothScroll("a[href*='#']", {
        speed: r,
        updateURL: !1,
        popstate: !1,
        speedAsDuration: !0,
      })),
      window.addEventListener(
        "focus",
        () => {
          !0 !== this.inFocus &&
            ((this.inFocus = !0), o.onViewFocus(), this.unstackFocusActions());
        },
        !0,
      ),
      window.addEventListener(
        "blur",
        () => {
          !0 === this.inFocus && ((this.inFocus = !1), o.onViewBlur());
        },
        !0,
      );
  }
  purgeFocusActions(e) {
    this.pendingFocusActions[e] && delete this.pendingFocusActions[e];
  }
  runInFocus(e, t, i = !1) {
    !0 === this.inFocus || !0 === i
      ? t()
      : (this.pendingFocusActions[e] || (this.pendingFocusActions[e] = []),
        this.pendingFocusActions[e].push(t));
  }
  unstackFocusActions() {
    for (let e in this.pendingFocusActions)
      while ((this.pendingFocusActions[e] || []).length > 0) {
        let t = this.pendingFocusActions[e][0];
        "function" === typeof t && t(), this.pendingFocusActions[e].shift();
      }
    this.pendingFocusActions = {};
  }
  scrollTo(e, t = null) {
    e &&
      setTimeout(
        () => {
          let t = e();
          t && this.smooth_scroll.animateScroll(t);
        },
        null !== t ? t : s,
      );
  }
}

export default new _focus();
