<template>
  <div
    :class="[
      'c-field-image',
      'c-field-image--' + direction,
      'c-field-image--' + shape,
      {
        'c-field-image--border': border,
        'c-field-image--uploading': uploading,
        'c-field-image--reloading': reloading,
        'c-field-image--disabled': disabled,
      },
    ]"
  >
    <span v-if="label" class="c-field-image__label-wrapper">
      <span v-if="label" class="c-field-image__label">{{ label }}</span>
      <span v-if="instructions" class="c-field-image__instructions">{{
        instructions
      }}</span>
    </span>

    <div class="c-field-image__container">
      <BaseAvatar
        v-if="'avatar' === uploadType"
        class="c-field-image__preview"
        :avatar-id="identifier"
        :avatar-url="currentSource"
        :circular="false"
        :size="size"
        :type="'operator'"
        cursor="default"
      ></BaseAvatar>
      <span
        v-else
        class="c-field-image__preview"
        :style="{
          backgroundColor: backgroundColor,
        }"
      >
        <span
          class="c-field-image__preview-image"
          :style="{
            backgroundImage: 'url(' + source + ')',
            height: customHeight,
            width: customWidth,
            transform: customTransform,
          }"
        ></span>
      </span>
      <input
        v-if="!disabled"
        class="c-field-image__field"
        :accept="accept ? accept.join(',') : null"
        :id="uuid"
        type="file"
        v-on:change="(...e) => onFieldChange(...e)"
      />
      <div v-if="!disabled" class="c-field-image__actions">
        <label class="c-field-image__upload u-has-tooltip" :for="uuid">
          <BaseTooltip direction="middle" align="right">
            {{ this.$t("TOOLTIPS.FIELD_IMAGE_UPLOAD") }}
          </BaseTooltip>
          <BaseIcon
            class="c-field-image__icon"
            name="cloud_upload"
            size="19px"
          ></BaseIcon>
        </label>
      </div>
      <!-- <span
        v-if="currentSource"
        class="c-field-image__remove u-has-tooltip"
        v-on:click="(...e) => onRemove(...e)"
      >
        <BaseTooltip direction="middle" align="right">
          {{ this.$t("TOOLTIPS.FIELD_IMAGE_CLEAR") }}
        </BaseTooltip>
        <BaseIcon
          class="c-field-image__icon"
          name="clear"
          size="19px"
        ></BaseIcon>
      </span> -->
      <div v-if="uploading || reloading" class="c-field-image__progress">
        <BaseSpinner
          class="c-field-image__spinner"
          border-width="3px"
          color="#1c293b"
          size="20px"
          speed="1s"
        ></BaseSpinner>
      </div>
    </div>
  </div>
</template>
<script>
import * as $uuid from "uuid";
import uploadFactory from "@/classes/talqui/uploadFactory";
import uploadHelper from "@/classes/talqui/uploadHelper";

const s = 3e3;
export default {
  props: {
    accept: {
      type: Array,
      default: () => ["image/*"],
      validator(e) {
        return e.length > 0;
      },
    },
    backgroundColor: {
      type: String,
      default: "",
    },
    customHeight: {
      type: String,
      default: "",
    },
    customWidth: {
      type: String,
      default: "",
    },
    customTransform: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    border: {
      type: Boolean,
      default: true,
    },
    direction: {
      type: String,
      default: "vertical",
      validator(e) {
        return -1 !== ["horizontal", "vertical"].indexOf(e);
      },
    },
    label: {
      type: String,
      default: null,
    },
    instructions: {
      type: String,
      default: "",
    },
    identifier: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    shape: {
      type: String,
      default: "circular",
    },
    type: {
      type: String,
      default: null,
    },
    source: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "",
    },
    uploadType: {
      type: String,
      default: "",
    },
    returnId: {
      type: String,
      default: "",
    },
  },
  emits: ["upload", "remove"],
  data() {
    return {
      uploading: false,
      reloading: false,
      currentSource: "",
      pendingFile: {},
      uuid: null,
    };
  },
  watch: {
    source: {
      immediate: true,
      handler(e) {
        "" !== this.currentSource
          ? ((this.reloading = true),
            setTimeout(() => {
              (this.reloading = false), (this.currentSource = e);
            }, s))
          : (this.currentSource = e);
      },
    },
  },
  created() {
    this.uuid = $uuid.v4();
    // uploadHelper.addGenericListener(this.uploadType, this.onBucketGenerated);
  },
  beforeUnmount() {
    // uploadHelper.removeGenericListener(this.uploadType, this.onBucketGenerated);
  },
  methods: {
    onBucketGenerated(e) {
      if (this.uploading) {
        let t = this;
        uploadFactory.proceed({
          states: this.pendingFile,
          action: e.uploadUrl,
          resource: e.resourceUrl,
          size_limit: 10000000, //max 10mb
          fnClean: () => {
            this.uploading = false;
          },
          fnDone: (file, resourceUrl) => {
            this.uploading = false;
            t.$emit("upload", this.name, resourceUrl);
          },
        });
      }
    },
    onFieldChange(e) {
      let file = e.target.files[0];
      if (file && !this.uploading) {
        this.pendingFile.id = Date.now();
        this.pendingFile.files = [file];
        uploadHelper
          .urlGenerate()
          .post({
            fileKind: this.type,
            fileName: file.name,
            fileContentType: file.type,
            ...(this.type === "operator-photo" && {
              operatorID: this.identifier,
            }),
            ...(this.type === "tenant-logo" && {
              tenantID: this.identifier,
            }),
            identifier: this.identifier,
          })
          .then((res) => {
            this.uploading = true;
            this.onBucketGenerated(res);
          })
          .catch(() => {
            this.uploading = true;
          });
      }
    },
    onRemove() {
      this.$emit("remove", this.name);
    },
  },
};
</script>
<style lang="scss">
.c-field-image {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-align: left;
  .c-field-image__label-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    .c-field-image__label {
      color: #1c293b;
      font-weight: 700;
      font-size: 14px;
      cursor: default;
    }
    .c-field-image__instructions {
      margin-top: 5px;
      color: #75869c;
      font-size: 12.5px;
      cursor: default;
    }
  }
  .c-field-image__container {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 80px;
    .c-field-image__preview {
      z-index: 2;
      display: block;
      .c-field-image__preview-image {
        display: block;
        width: 100%;
        height: 100%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 50%;
        display: inline-block;
      }
    }
    .c-field-image__actions {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      margin-left: -40px;
      padding-left: 39px;
      width: 80px;
      height: 100%;
      border: 1px solid #e3e8ef;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      background-color: #fafbfc;
      cursor: pointer;
      .c-field-image__remove {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        color: #75869c;
        cursor: pointer;
        &:hover {
          color: #2c405a;
          .c-field-image__icon {
            fill: #e0102b;
          }
        }
        .c-field-image__icon {
          fill: #75869c;
        }
      }
      .c-field-image__upload {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        color: #75869c;
        cursor: pointer;
        border-bottom: 1px solid rgba(227, 232, 239, 0.5);
        &:hover {
          color: #2c405a;
          .c-field-image__icon {
            fill: #0093ee;
          }
        }
        .c-field-image__icon {
          fill: #75869c;
        }
        &:last-child {
          border-bottom: 0;
        }
      }
    }
  }
  .c-field-image__field {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    width: 80px;
    opacity: 0;
    cursor: pointer;
  }
  .c-field-image__progress {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    background: hsla(0, 0%, 100%, 0.75);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    .c-field-image__spinner {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }
  }
}
.c-field-image--border {
  .c-field-image__container {
    .c-field-image__preview {
      border: 1px solid #e3e8ef;
    }
  }
}
.c-field-image--horizontal {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  .c-field-image__label {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 140px;
  }
  .c-field-image__container {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
}
.c-field-image--vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.c-field-image--circular {
  .c-field-image__container {
    .c-field-image__preview {
      width: 80px;
      height: 100%;
      border-radius: 100%;
      img {
        border-radius: 100%;
      }
    }
    .c-field-image__progress {
      border-radius: 100%;
    }
  }
}
.c-field-image--square {
  .c-field-image__container {
    .c-field-image__preview {
      width: 80px;
      height: 100%;
      border-radius: 4px;
      img {
        border-radius: 4px;
      }
    }
    .c-field-image__progress {
      border-radius: 4px;
    }
  }
}
.c-field-image--rectangular {
  .c-field-image__container {
    border-radius: 4px;
    .c-field-image__preview {
      width: 200px;
      height: 100%;
      border-radius: 4px 0 0 4px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 50%;
      display: inline-block;
    }
    .c-field-image__progress {
      width: 200px;
      border-radius: 4px 0 0 4px;
    }
    .c-field-image__field {
      width: 200px;
    }
  }
}
.c-field-image--rectangular.c-field-image--disabled {
  .c-field-image__container {
    .c-field-image__preview {
      border-radius: 4px;
    }
    .c-field-image__progress {
      border-radius: 4px;
    }
  }
}
.c-field-image--uploading {
  .c-field-image__container {
    .c-field-image__actions {
      opacity: 0.2;
      pointer-events: none;
    }
  }
}
</style>
