<template>
  <div :class="['c-base-price', 'c-base-price--' + size]">
    <span class="u-semibold">{{
      this.$filters.format.currencySymbol(currency)
    }}</span>
    <span class="u-bold">{{ this.$filters.format.amountMain(price) }}</span>
    <span v-if="termSymbol" class="u-regular">{{ termSymbol }}</span>
  </div>
</template>
<script>
export default {
  props: {
    currency: {
      type: String,
      default: null,
    },
    price: {
      type: Number,
      required: !0,
    },
    term: {
      type: String,
      default: null,
      validator(e) {
        return -1 !== ["month", "year"].indexOf(e);
      },
    },
    size: {
      type: String,
      default: "normal",
      validator(e) {
        return -1 !== ["small", "normal", "medium", "large"].indexOf(e);
      },
    },
  },
  computed: {
    termSymbol() {
      switch (this.term) {
        case "month":
          return "/" + this.$t("BASES.PRICE.MONTH");
        case "year":
          return "/" + this.$t("BASES.PRICE.YEAR");
      }
      return "";
    },
  },
};
</script>
<style lang="scss">
.c-base-price {
  display: inline-block;
  border: 1px solid #e3e8ef;
  background-color: #fff;
  color: #1c293b;
}
.c-base-price--small {
  padding: 2px 7px;
  border-radius: 2px;
  font-size: 12px;
}
.c-base-price--normal {
  padding: 2px 9px;
  border-radius: 3px;
  font-size: 14px;
}
.c-base-price--medium {
  padding: 3px 11px;
  border-radius: 4px;
  font-size: 16px;
}
.c-base-price--large {
  padding: 3px 12px;
  border-radius: 4px;
  font-size: 18px;
}
</style>
