<template>
  <p :class="['c-field-description', 'c-field-description--' + size]">
    {{ description }}
  </p>
</template>
<script>
export default {
  props: {
    description: {
      type: String,
      required: !0,
    },
    size: {
      type: String,
      default: "default",
      validator(e) {
        return (
          -1 !== ["mini", "small", "default", "medium", "large"].indexOf(e)
        );
      },
    },
  },
};
</script>
<style lang="scss">
.c-field-description {
  margin: 10px 0 0;
  color: #75869c;
}
.c-field-description--mini {
  font-size: 12px;
  line-height: 14px;
}
.c-field-description--small {
  font-size: 13px;
  line-height: 16px;
}
.c-field-description--default {
  font-size: 14px;
  line-height: 18px;
}
.c-field-description--medium {
  font-size: 15px;
  line-height: 20px;
}
.c-field-description--large {
  font-size: 16px;
  line-height: 22px;
}
</style>
