export default {
  computed: {
    pluginUrnsReady() {
      return true;
    },
    $pluginsLoaded() {
      return true === this.pluginUrnsReady;
    },
    pluginUrns() {
      return this.$store.getters["pluginsTalqui/getPluginConnectionURNs"];
    },
  },
  methods: {
    $pluginExists(pluginURN) {
      return this.pluginUrns.includes(pluginURN);
    },
    // computeIncludedPlan(e = [], t = null, i = !1) {
    //   if (!i) {
    //     if (e.length > 0) {
    //       let i;
    //       return (
    //         t && t.id && (i = e.find((e) => e.id === t.id)),
    //         i || (i = e[0]),
    //         {
    //           color: this.$filters.plans.getPlanColor(i.name.toLowerCase()),
    //           label: i.name,
    //         }
    //       );
    //     }
    //     return {
    //       color: this.$filters.plans.getPlanColor("free"),
    //       label: this.$t("PLUGINS.PREVIEW.PLAN_FREE"),
    //     };
    //   }
    //   return null;
    // },
  },
};
