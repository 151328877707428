<template>
  <div
    :class="[
      'c-base-navigation',
      {
        'c-base-navigation--expand-left': expandLeft,
        'c-base-navigation--expand-right': expandRight,
      },
    ]"
  >
    <BaseDivider v-if="hasDivider"></BaseDivider>
    <div class="c-base-navigation__content">
      <div class="c-base-navigation__left">
        <slot name="left"></slot>
      </div>

      <div class="c-base-navigation__center">
        <slot name="center"></slot>
      </div>

      <div class="c-base-navigation__right">
        <slot name="right"></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    hasDivider: {
      type: Boolean,
      default: !0,
    },
    expandLeft: {
      type: Boolean,
      default: !1,
    },
    expandRight: {
      type: Boolean,
      default: !1,
    },
  },
};
</script>
<style lang="scss">
.c-base-navigation {
  .c-base-navigation__content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    .c-base-navigation__left {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      padding-right: 4px;
    }
    .c-base-navigation__center {
      display: flex;
      align-items: center;
      flex: 1;
      justify-content: center;
      padding: 0px 4px;
    }
    .c-base-navigation__right {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
      -webkit-box-pack: end;
      -ms-flex-pack: end;
      justify-content: flex-end;
      padding-left: 4px;
    }
  }
}
.c-base-navigation--expand-left {
  .c-base-navigation__content {
    .c-base-navigation__right {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
    }
  }
}
.c-base-navigation--expand-right {
  .c-base-navigation__content {
    .c-base-navigation__left {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
    }
  }
}
</style>
