import crispAudio from "./crispAudio";
import crispDate from "./crispDate.js";
// import crispMove from "./crispMove";
import crispRipple from "./crispRipple";
// import crispSelect from "./crispSelect";
import crispPlaceholder from "./crispPlaceholder";

class _internalDirectives {
  init(_vue) {
    crispAudio.init(_vue);
    crispDate.init(_vue);
    // crispMove.init(_vue);
    crispRipple.init(_vue);
    // crispSelect.init(_vue);
    crispPlaceholder.init(_vue);
  }
}
export default new _internalDirectives();
