import VueHotkey from "v-hotkey";
import clickAway from "vue-clickaway";
import { copy } from "v-copy";
// // import VueObserveVisibility from "vue-observe-visibility";
// // import chartist from "vue-chartist";
import InfiniteLoading from "vue-infinite-loading";
// import RouterPrefetch from "vue-router-prefetch";
import VueVirtualScroller from "vue-virtual-scroller";

import { vMaska } from "maska";

class defaultDirectives {
  init(_vue) {
    _vue.use(VueVirtualScroller);

    _vue.directive("maska", vMaska);

    // _vue.use(RouterPrefetch);
    _vue.directive("click-away", clickAway.directive);
    _vue.directive("hotkey", {
      beforeMount: VueHotkey.directive.bind,
      updated: VueHotkey.directive.componentUpdated,
      unmounted: VueHotkey.directive.unbind,
    });
    _vue.directive("copy", {
      beforeMount: copy.bind,
      updated: copy.componentUpdated,
      unmounted: copy.unbind,
    });
    //     // _vue.directive("observe-visibility", {
    //     //   beforeMount: (e, t, i) => {
    //     //     (i.context = t.instance), VueObserveVisibility.ObserveVisibility.bind(e, t, i);
    //     //   },
    //     //   updated: VueObserveVisibility.ObserveVisibility.update,
    //     //   unmounted: VueObserveVisibility.ObserveVisibility.unbind,
    //     // });
    //     // _vue.use(chartist, {
    //     //   messageNoData: "",
    //     // });
    _vue.use(require("vue-chartist"), {
      messageNoData: "",
    });
    _vue.use(InfiniteLoading, {
      props: {
        distance: 10,
      },
      slots: {
        noMore: "",
        noResults: "",
      },
      system: {
        throttleLimit: 250,
      },
    });
  }
}
export default new defaultDirectives();
