import { createI18n } from "vue-i18n";

class _i18n {
  constructor() {
    this.i18n = null;
  }
  init(_vue) {
    this.i18n = createI18n({
      locale: "pt_BR",
      fallbackLocale: "en",
      messages: {},
    });
    _vue.use(this.i18n);
  }
  getInstance() {
    return this.i18n.global;
  }
}

export default new _i18n();
