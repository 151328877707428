<template>
  <div
    :class="[
      'c-base-teleprompter',
      'u-select',
      { 'c-base-teleprompter--running': running },
    ]"
  >
    <p
      class="c-base-teleprompter__paragraph"
      v-for="(sequence, paragraphIndex) in sequences"
      :key="paragraphIndex"
    >
      <span
        v-for="(sentence, sentenceIndex) in sequence"
        :key="sentenceIndex"
        :class="[
          'c-base-teleprompter__sentence',
          {
            'c-base-teleprompter__sentence--active': isActiveSegment(
              paragraphIndex,
              sentenceIndex,
            ),
          },
        ]"
        @click="() => onSentenceClick(sentence, paragraphIndex, sentenceIndex)"
      >
        <span
          v-for="(word, wordIndex) in sentence.words"
          :key="wordIndex"
          :class="[
            'c-base-teleprompter__word',
            {
              'c-base-teleprompter__word--active':
                isActiveSegment(paragraphIndex, sentenceIndex) &&
                isActiveWord(sentence, wordIndex),
            },
          ]"
        >
          {{ word.replace(" ", "") }}
          <BaseSpace v-if="wordIndex < sentence.words.length - 1" />
        </span>
      </span>
    </p>
  </div>
</template>
<script>
export default {
  props: {
    duration: {
      type: Number,
      required: !0,
    },
    parts: {
      type: Array,
      required: !0,
    },
    running: {
      type: Boolean,
      default: !1,
    },
  },
  emits: ["seek"],
  data() {
    return {
      activeSegment: {
        paragraph: -1,
        sentence: -1,
        progress: 0,
      },
    };
  },
  computed: {
    sequences() {
      return this.parts.map((part) =>
        part.map((e) => ({
          offset: e.offset,
          words: e.text.split(" ").filter((n) => n.length > 0),
        })),
      );
    },
    locations() {
      let t = [];
      return (
        this.parts.forEach((e, n) => {
          e.forEach((i, r) => {
            t.push({
              offset: i.offset,
              segment: [n, r],
            });
          });
        }),
        t
      );
    },
  },
  methods: {
    jump(t = -1) {
      if (t >= 0) {
        let e = null;
        for (let n = this.locations.length - 1; n >= 0; n--) {
          let i = this.locations[n];
          if (t >= i.offset) {
            (this.activeSegment.paragraph = i.segment[0]),
              (this.activeSegment.sentence = i.segment[1]);
            let s = (e !== null ? e.offset : this.duration) - i.offset,
              a = Math.max(0, t - i.offset);
            this.activeSegment.progress = a / s;
            break;
          }
          e = i;
        }
      } else {
        this.activeSegment.paragraph = -1;
        this.activeSegment.sentence = -1;
        this.activeSegment.progress = 0;
      }
    },
    isActiveSegment(t = 0, e = 0) {
      return (
        this.activeSegment.paragraph === t && this.activeSegment.sentence === e
      );
    },
    isActiveWord(t, e = 0) {
      let n = Math.floor(t.words.length * this.activeSegment.progress);
      return e === n;
    },
    onSentenceClick(t, e = -1, n = -1) {
      this.$emit("seek", t.offset);
      this.activeSegment.paragraph = e;
      this.activeSegment.sentence = n;
      this.activeSegment.progress = 0;
    },
  },
};
</script>
<style lang="scss">
@-webkit-keyframes c-base-teleprompter-running-sentence-active {
  0% {
    background-color: rgba(250, 202, 0, 0.25);
  }
  33% {
    background-color: rgba(250, 202, 0, 0.4);
  }
  66% {
    background-color: rgba(250, 202, 0, 0.1);
  }
  to {
    background-color: rgba(250, 202, 0, 0.25);
  }
}

@keyframes c-base-teleprompter-running-sentence-active {
  0% {
    background-color: rgba(250, 202, 0, 0.25);
  }
  33% {
    background-color: rgba(250, 202, 0, 0.4);
  }
  66% {
    background-color: rgba(250, 202, 0, 0.1);
  }
  to {
    background-color: rgba(250, 202, 0, 0.25);
  }
}

.c-base-teleprompter {
  .c-base-teleprompter__paragraph {
    margin-bottom: 12px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .c-base-teleprompter__sentence {
    margin-right: 1px;
    padding: 0 2px;
    border-radius: 4px;
    -webkit-transition: background-color linear 75ms;
    transition: background-color linear 75ms;
    > * {
      display: inline-block;
    }
    &:hover {
      background-color: rgba(250, 202, 0, 0.1);
      cursor: pointer;
    }
    &:active {
      background-color: rgba(250, 202, 0, 0.15);
    }
    &:first-child {
      margin-left: -2px;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  .c-base-teleprompter__sentence--active {
    background-color: rgba(250, 202, 0, 0.25);
    cursor: default;
    &:hover {
      background-color: rgba(250, 202, 0, 0.25);
      cursor: default;
    }
    &:active {
      background-color: rgba(250, 202, 0, 0.25);
      cursor: default;
    }
  }
  .c-base-teleprompter__word {
    margin: 0 -2px;
    padding: 0 2px;
    border-radius: 4px;
  }
  .c-base-teleprompter__word--active {
    background-color: rgba(250, 202, 0, 0.7);
  }
}

.c-base-teleprompter--running {
  .c-base-teleprompter__sentence--active {
    -webkit-animation-name: "c-base-teleprompter-running-sentence-active";
    animation-name: "c-base-teleprompter-running-sentence-active";
    -webkit-animation-duration: 5s;
    animation-duration: 5s;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    &:hover {
      -webkit-animation-name: "c-base-teleprompter-running-sentence-active";
      animation-name: "c-base-teleprompter-running-sentence-active";
      -webkit-animation-duration: 5s;
      animation-duration: 5s;
      -webkit-animation-timing-function: ease-in-out;
      animation-timing-function: ease-in-out;
      -webkit-animation-iteration-count: infinite;
      animation-iteration-count: infinite;
    }
    &:active {
      -webkit-animation-name: "c-base-teleprompter-running-sentence-active";
      animation-name: "c-base-teleprompter-running-sentence-active";
      -webkit-animation-duration: 5s;
      animation-duration: 5s;
      -webkit-animation-timing-function: ease-in-out;
      animation-timing-function: ease-in-out;
      -webkit-animation-iteration-count: infinite;
      animation-iteration-count: infinite;
    }
  }
}
</style>
