<template>
  <div
    :data-direction="direction"
    :data-align="align"
    :class="[
      'c-base-dropdown',
      'c-base-dropdown--' + type,
      {
        'c-base-dropdown--displayed': displayed,
      },
    ]"
  >
    <span
      class="c-base-dropdown__button u-has-tooltip"
      v-on:click="(...e) => onButtonClick && onButtonClick(...e)"
    >
      <BaseTooltip v-if="tooltip" :direction="tooltip.direction">{{
        tooltip.content
      }}</BaseTooltip>
    </span>
    <ul
      v-show="displayed"
      :class="['c-base-dropdown__menu', 'c-base-dropdown__menu--' + align]"
      :style="{
        'z-index': menuIndex,
      }"
    >
      <BaseDropdownItem
        v-for="item in items"
        :key="item.id"
        v-on:click="onItemClick"
        v-on:FileChange="onFileChange"
        v-on:AudioFile="onAudioFile"
        :can-show="item.condition"
        :disabled="item.disabled"
        :icon="item.icon"
        :id="item.id"
        :file="item.file"
        :label="item.label"
        :record="item.record"
        :upgrade="item.upgrade"
      ></BaseDropdownItem>
    </ul>
  </div>
</template>
<script>
export default {
  props: {
    align: {
      type: String,
      default: "right",
      validator(t) {
        return ["left", "right"].indexOf(t) !== -1;
      },
    },
    direction: {
      type: String,
      default: "top",
      validator(t) {
        return ["bottom", "top"].indexOf(t) !== -1;
      },
    },
    items: {
      type: Array,
      required: !0,
    },
    menuIndex: {
      type: [String, Number],
      default: "auto",
    },
    tooltip: {
      type: Object,
      default: null,
    },
    type: {
      type: String,
      default: "dots",
    },
  },
  emits: ["fileChange", "audioFile", "itemClick"],
  data() {
    return {
      displayed: !1,
    };
  },
  computed: {
    hotkeys() {
      return {
        esc: this.onClose,
      };
    },
  },
  methods: {
    onButtonClick() {
      this.displayed = !this.displayed;
    },
    onClose() {
      this.displayed && (this.displayed = !1);
    },
    onFileChange(e) {
      this.$emit("fileChange", e);
    },
    onAudioFile(e, t) {
      this.$emit("audioFile", e, t);
    },
    onItemClick(e) {
      this.displayed = !1;
      this.$emit("itemClick", e);
    },
  },
};
</script>
<style lang="scss">
.c-base-dropdown {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  .c-base-dropdown__button {
    cursor: pointer;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50%;
    display: inline-block;
  }
  .c-base-dropdown__menu {
    position: absolute;
    margin-left: 0px;
    bottom: 13px;
    padding: 5px 0;
    border: 1px solid #e3e8ef;
    border-radius: 1px;
    background-color: #fff;
    -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.05);
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.05);
    z-index: 10;
    list-style: none;
    -webkit-animation-name: translateBottomToTop;
    animation-name: translateBottomToTop;
    -webkit-animation-duration: 0.1s;
    animation-duration: 0.1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    &:after {
      position: absolute;
      top: 100%;
      width: 0;
      height: 0;
      border: solid transparent;
      content: " ";
      pointer-events: none;
      border-width: 8px;
      border-color: none;
      border-top-color: #fff;
    }
    &:before {
      position: absolute;
      top: 100%;
      width: 0;
      height: 0;
      border: solid transparent;
      content: " ";
      pointer-events: none;
      border-width: 9px;
      border-color: none;
      border-top-color: #e3e8ef;
    }
  }
  .c-base-dropdown__menu--left {
    left: -13px;
    &:after {
      left: 17px;
      margin-right: -7px;
    }
    &:before {
      left: 15px;
      margin-right: -9px;
    }
  }
  .c-base-dropdown__menu--right {
    right: -13px;
    &:after {
      right: 17px;
      margin-left: -7px;
    }
    &:before {
      right: 15px;
      margin-left: -9px;
    }
  }
}
.c-base-dropdown--dots {
  .c-base-dropdown__button {
    width: 23px;
    height: 23px;
    background-image: url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjIyIiB3aWR0aD0iMjIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48Y2lyY2xlIGN4PSIxMSIgY3k9IjExIiByPSIxMCIgc3Ryb2tlPSIjMTc5MWVkIiBzdHJva2Utd2lkdGg9IjEuNSIvPjxnIGZpbGw9IiMxNzkxZWQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDQuNSA4LjUpIj48cGF0aCBkPSJNMS43NSA0LjI1YTEuNzUgMS43NSAwIDEwMC0zLjUgMS43NSAxLjc1IDAgMDAwIDMuNXoiLz48Y2lyY2xlIGN4PSI2LjUiIGN5PSIyLjUiIHI9IjEuNzUiLz48Y2lyY2xlIGN4PSIxMS4yNSIgY3k9IjIuNSIgcj0iMS43NSIvPjwvZz48L2c+PC9zdmc+");
  }
  .c-base-dropdown__menu {
    bottom: 8px;
    &:after {
      right: 14px;
    }
    &:before {
      right: 13px;
    }
  }
}
.c-base-dropdown--plus {
  .c-base-dropdown__button {
    width: 25px;
    height: 25px;
    background-image: url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjI1IiB3aWR0aD0iMjUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2U9IiMxNzkxZWQiIHN0cm9rZS13aWR0aD0iMS4wNDIiPjxjaXJjbGUgY3g9IjEyLjkwMiIgY3k9IjEyLjcxNyIgcj0iMTEuNTEiLz48ZyBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiPjxwYXRoIGQ9Ik0xMy4wMiA4LjM4NXY4LjkyM00xNy40NDggMTIuNzZIOC41NDIiLz48L2c+PC9nPjwvc3ZnPg==");
  }
}
.c-base-dropdown--bubble-dots {
  .c-base-dropdown__button {
    width: 23px;
    height: 23px;
    background-image: url(data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9JzIyJyB2aWV3Qm94PScwIDAgMjIgMjInIHdpZHRoPScyMicgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJz48ZyBmaWxsPSdub25lJyB0cmFuc2Zvcm09J3RyYW5zbGF0ZSgxIDEpJz48Y2lyY2xlIGN4PScxMCcgY3k9JzEwJyByPScxMCcvPjxnIGZpbGw9JyM3NTg2OWMnIHRyYW5zZm9ybT0nbWF0cml4KDAgMSAtMSAwIDExLjc1IDMuNSknPjxwYXRoIGQ9J20xLjc1IDMuNWMuOTY2NDk4MzEgMCAxLjc1LS43ODM1MDE2OSAxLjc1LTEuNzVzLS43ODM1MDE2OS0xLjc1LTEuNzUtMS43NS0xLjc1Ljc4MzUwMTY5LTEuNzUgMS43NS43ODM1MDE2OSAxLjc1IDEuNzUgMS43NXonLz48Y2lyY2xlIGN4PSc2LjUnIGN5PScxLjc1JyByPScxLjc1Jy8+PGNpcmNsZSBjeD0nMTEuMjUnIGN5PScxLjc1JyByPScxLjc1Jy8+PC9nPjwvZz48L3N2Zz4=);
  }
}
.c-base-dropdown--bubble-dots-horizontal {
  .c-base-dropdown__button {
    width: 20px;
    height: 20px;
    background-image: url(data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9JzIwJyB2aWV3Qm94PScwIDAgMjAgMjAnIHdpZHRoPScyMCcgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJz48ZyBmaWxsPSdub25lJyBmaWxsLXJ1bGU9J2V2ZW5vZGQnPjxjaXJjbGUgY3g9JzEwJyBjeT0nMTAnIHI9JzEwJy8+PGcgZmlsbD0nIzc1ODY5YycgZmlsbC1ydWxlPSdub256ZXJvJyB0cmFuc2Zvcm09J3RyYW5zbGF0ZSgzLjUgOC4yNSknPjxwYXRoIGQ9J20xLjc1IDMuNWMuOTY2NDk4MzEgMCAxLjc1LS43ODM1MDE2OSAxLjc1LTEuNzVzLS43ODM1MDE2OS0xLjc1LTEuNzUtMS43NS0xLjc1Ljc4MzUwMTY5LTEuNzUgMS43NS43ODM1MDE2OSAxLjc1IDEuNzUgMS43NXonLz48Y2lyY2xlIGN4PSc2LjUnIGN5PScxLjc1JyByPScxLjc1Jy8+PGNpcmNsZSBjeD0nMTEuMjUnIGN5PScxLjc1JyByPScxLjc1Jy8+PC9nPjwvZz48L3N2Zz4=);
  }
}
.c-base-dropdown--displayed.c-base-dropdown--dots {
  .c-base-dropdown__button {
    background-image: url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjIyIiB3aWR0aD0iMjIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48Y2lyY2xlIGN4PSIxMSIgY3k9IjExIiBmaWxsPSIjMTc5MWVkIiByPSIxMCIgc3Ryb2tlPSIjMTc5MWVkIiBzdHJva2Utd2lkdGg9IjEuNSIvPjxnIGZpbGw9IiNmZmYiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDQuNSA4LjUpIj48cGF0aCBkPSJNMS43NSA0LjI1YTEuNzUgMS43NSAwIDEwMC0zLjUgMS43NSAxLjc1IDAgMDAwIDMuNXoiLz48Y2lyY2xlIGN4PSI2LjUiIGN5PSIyLjUiIHI9IjEuNzUiLz48Y2lyY2xlIGN4PSIxMS4yNSIgY3k9IjIuNSIgcj0iMS43NSIvPjwvZz48L2c+PC9zdmc+");
  }
}
.c-base-dropdown--displayed.c-base-dropdown--plus {
  .c-base-dropdown__button {
    background-image: url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjI1IiB3aWR0aD0iMjUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2Utd2lkdGg9IjEuMDQyIj48Y2lyY2xlIGN4PSIxMi45MDIiIGN5PSIxMi43MTciIGZpbGw9IiMwMDkzZWUiIHI9IjExLjUxIiBzdHJva2U9IiMxNzkxZWQiLz48ZyBzdHJva2U9IiNmZmYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCI+PHBhdGggZD0iTTEzLjAyIDguMzg1djguOTIzTTE3LjQ0OCAxMi43Nkg4LjU0MiIvPjwvZz48L2c+PC9zdmc+");
  }
}
.c-base-dropdown--displayed.c-base-dropdown--bubble-dots {
  .c-base-dropdown__button {
    background-image: url(data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9JzIyJyB2aWV3Qm94PScwIDAgMjIgMjInIHdpZHRoPScyMicgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJz48ZyBmaWxsPScjNzU4NjljJyB0cmFuc2Zvcm09J3RyYW5zbGF0ZSguNSAuNSknPjxjaXJjbGUgY3g9JzEwLjUnIGN5PScxMC41JyBmaWxsLW9wYWNpdHk9Jy4xNjM2OCcgcj0nMTAuNScgc3Ryb2tlPScjNzU4NjljJyBzdHJva2Utb3BhY2l0eT0nLjA4Jy8+PGcgdHJhbnNmb3JtPSdtYXRyaXgoMCAxIC0xIDAgMTIuMjUgNCknPjxwYXRoIGQ9J20xLjc1IDMuNWMuOTY2NDk4MzEgMCAxLjc1LS43ODM1MDE2OSAxLjc1LTEuNzVzLS43ODM1MDE2OS0xLjc1LTEuNzUtMS43NS0xLjc1Ljc4MzUwMTY5LTEuNzUgMS43NS43ODM1MDE2OSAxLjc1IDEuNzUgMS43NXonLz48Y2lyY2xlIGN4PSc2LjUnIGN5PScxLjc1JyByPScxLjc1Jy8+PGNpcmNsZSBjeD0nMTEuMjUnIGN5PScxLjc1JyByPScxLjc1Jy8+PC9nPjwvZz48L3N2Zz4=);
  }
}
.c-base-dropdown--displayed.c-base-dropdown--bubble-dots-horizontal {
  .c-base-dropdown__button {
    background-image: url(data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9JzIyJyB2aWV3Qm94PScwIDAgMjIgMjInIHdpZHRoPScyMicgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJz48ZyBmaWxsPScjNzU4NjljJz48Y2lyY2xlIGN4PScxMScgY3k9JzExJyBmaWxsLW9wYWNpdHk9Jy4xNjM2OCcgcj0nMTAuNScgc3Ryb2tlPScjNzU4NjljJyBzdHJva2Utb3BhY2l0eT0nLjA4Jy8+PGcgdHJhbnNmb3JtPSd0cmFuc2xhdGUoNC41IDkuMjUpJz48cGF0aCBkPSdtMS43NSAzLjVjLjk2NjQ5ODMxIDAgMS43NS0uNzgzNTAxNjkgMS43NS0xLjc1cy0uNzgzNTAxNjktMS43NS0xLjc1LTEuNzUtMS43NS43ODM1MDE2OS0xLjc1IDEuNzUuNzgzNTAxNjkgMS43NSAxLjc1IDEuNzV6Jy8+PGNpcmNsZSBjeD0nNi41JyBjeT0nMS43NScgcj0nMS43NScvPjxjaXJjbGUgY3g9JzExLjI1JyBjeT0nMS43NScgcj0nMS43NScvPjwvZz48L2c+PC9zdmc+);
  }
}
</style>
