<template>
  <div
    :class="[
      'c-base-avatar',
      'c-base-avatar--' + size,
      {
        'c-base-avatar--circular': circular,
        'c-base-avatar--compose': compose,
      },
    ]"
    :style="{
      cursor: cursor,
      height: customSize ? customSize : null,
      width: customSize ? customSize : null,
    }"
  >
    <span v-if="compose" class="c-base-avatar__compose">
      <span class="c-base-avatar__dot"></span>
      <span class="c-base-avatar__dot"></span>
      <span class="c-base-avatar__dot"></span>
    </span>
    <img
      class="c-base-avatar__picture"
      :style="{
        backgroundColor: backgroundColor,
        backgroundImage: backgroundImage,
        height: customSize ? customSize : null,
        width: customSize ? customSize : null,
      }"
      :src="picture"
      :width="computedSize"
      :height="computedSize"
      loading="lazy"
    />

    <BaseAvailability
      v-if="
        'online' === availability ||
        (showAvailabilityAway && 'away' === availability)
      "
      class="c-base-avatar__availability"
      :style="{ borderColor: parentColor }"
      :size="size"
      :tooltip="availability && 'visitor' === type ? nickname : ''"
      :color="'online' === availability ? 'green' : 'orange'"
    />

    <slot name="default"></slot>

    <BaseFlag
      v-if="hasFlag"
      class="c-base-avatar__flag"
      :border-color="parentColor"
      :border-width="flagBorderWidth"
      :code="flag"
      :height="flagSize"
      :width="flagSize"
      tooltipAlign="left"
      has-tooltip=""
      circular=""
    />

    <BaseChannelIcon
      v-else-if="hasChannelIcon"
      class="c-base-avatar__flag"
      :border-color="parentColor"
      :border-width="flagBorderWidth"
      :height="flagSize"
      :width="flagSize"
      tooltipAlign="center"
      :channel="channel"
      has-tooltip=""
      circular=""
    />
  </div>
</template>
<script>
var imageLoc = require("@/assets/images/components/base/BaseAvatar/default_white.png");
var a = require("@/classes/collorPalette.js");
export default {
  props: {
    availability: {
      type: String,
      default: "",
    },
    avatarId: {
      type: String,
      default: null,
    },
    avatarUrl: {
      type: String,
      default: null,
    },
    circular: {
      type: Boolean,
      default: !0,
    },
    compose: {
      type: Boolean,
      default: !1,
    },
    cursor: {
      type: String,
      default: null,
    },
    customSize: {
      type: String,
      default: "",
    },
    flag: {
      type: String,
      default: "",
    },
    hasFlag: {
      type: Boolean,
      default: !1,
    },
    channel: {
      type: String,
      default: "",
    },
    hasChannelIcon: {
      type: Boolean,
      default: !1,
    },
    nickname: {
      type: String,
      default: "",
    },
    parentColor: {
      type: String,
      default: "#ffffff",
    },
    showAvailabilityAway: {
      type: Boolean,
      default: !1,
    },
    size: {
      type: String,
      default: "normal",
      validator(e) {
        return (
          -1 !== ["mini", "small", "normal", "medium", "big", "huge"].indexOf(e)
        );
      },
    },
    type: {
      type: String,
      default: "visitor",
      validator(e) {
        return -1 !== ["operator", "visitor", "website"].indexOf(e);
      },
    },
  },
  emits: ["click"],
  computed: {
    backgroundColor() {
      return "website" !== this.type ? this.colorPalette[0] : null;
    },
    // eslint-disable-next-line vue/return-in-computed-property
    backgroundImage() {
      switch (this.type) {
        case "visitor":
          return (
            "url(" +
            imageLoc +
            ")" +
            ", linear-gradient(62deg, " +
            `#${this.colorPalette[0]} 0%, #${this.colorPalette[1]} 100%)`
          );
        case "operator":
          return "url(" + imageLoc + ")";
        default:
          return;
      }
    },
    colorPalette() {
      let e = [];
      return "visitor" === this.type
        ? a.default.palette(this.avatarId || "default")
        : e;
    },
    // eslint-disable-next-line vue/return-in-computed-property
    flagBorderWidth() {
      switch (this.size) {
        case "medium":
          return "2px";
        case "big":
          return "3px";
        case "huge":
          return "3px";
        default:
          return;
      }
    },
    // eslint-disable-next-line vue/return-in-computed-property
    flagSize() {
      switch (this.size) {
        case "mini":
          return "0px";
        case "small":
          return "0px";
        case "normal":
          return "12px";
        case "medium":
          return "16px";
        case "big":
          return "20px";
        case "huge":
          return "24px";
        default:
          return;
      }
    },
    picture() {
      let $photo = require("@/assets/images/components/base/BaseAvatar/default_white.png");
      if (this.avatarUrl) {
        try {
          $photo = new URL(this.avatarUrl);
        } catch (_) {
          return $photo;
        }
      }
      return $photo;
    },
    // eslint-disable-next-line vue/return-in-computed-property
    sizePixel() {
      switch (this.size) {
        case "mini":
          return 20;
        case "small":
          return 30;
        case "normal":
          return 36;
        case "medium":
          return 50;
        case "big":
          return 60;
        case "huge":
          return 80;
        default:
          return;
      }
    },
    computedSize() {
      return this.customSize || this.sizePixel + "px";
    },
  },
  methods: {
    onClick() {
      this.$emit("click");
    },
  },
};
</script>
<style lang="scss">
.c-base-avatar {
  position: relative;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  .c-base-avatar__picture {
    display: block;
    border-radius: 1px;
    background-color: #a8c6df;
    background-position: 50%;
    background-size: 120%;
    background-repeat: none;
  }
  .c-base-avatar__availability {
    position: absolute;
    display: inline-block;
    top: -2px;
    left: -2px;
    z-index: 2;
    border-width: 2px;
    border-style: solid;
  }
  .c-base-avatar__flag {
    position: absolute;
    display: inline-block;
  }
}
.c-base-avatar--mini {
  width: 20px;
  height: 20px;
  .c-base-avatar__picture {
    width: 20px;
    height: 20px;
  }
  .c-base-avatar__availability {
    display: none;
    top: 14px;
    left: 14px;
    border-width: 1px;
  }
  .c-base-avatar__flag {
    display: none;
  }
}
.c-base-avatar--small {
  width: 30px;
  height: 30px;
  .c-base-avatar__picture {
    width: 30px;
    height: 30px;
  }
  .c-base-avatar__availability {
    top: 22px;
    left: 22px;
    border-width: 1px;
  }
}
.c-base-avatar--normal {
  width: 36px;
  height: 36px;
  .c-base-avatar__picture {
    width: 36px;
    height: 36px;
  }
  .c-base-avatar__flag {
    top: 26px;
    left: 26px;
  }
  .c-base-tooltip[data-align="left"] {
    margin-left: -3px;
    &:after {
      margin-left: 5px;
    }
  }
}
.c-base-avatar--medium {
  width: 50px;
  height: 50px;
  .c-base-avatar__picture {
    width: 50px;
    height: 50px;
  }
  .c-base-avatar__flag {
    top: 34px;
    left: 34px;
  }
  .c-base-tooltip[data-align="left"] {
    margin-left: -3px;
    &:after {
      margin-left: 9px;
    }
  }
}
.c-base-avatar--big {
  width: 60px;
  height: 60px;
  .c-base-avatar__picture {
    width: 60px;
    height: 60px;
  }
  .c-base-avatar__flag {
    top: 40px;
    left: 40px;
  }
  .c-base-tooltip[data-align="left"] {
    margin-left: -3px;
    &:after {
      margin-left: 12px;
    }
  }
}
.c-base-avatar--huge {
  width: 80px;
  height: 80px;
  .c-base-avatar__picture {
    width: 80px;
    height: 80px;
  }
  .c-base-avatar__flag {
    top: 54px;
    left: 54px;
  }
  .c-base-avatar__availability {
    top: 0;
    left: 0;
  }
  .c-base-tooltip[data-align="left"] {
    margin-left: -3px;
    &:after {
      margin-left: 14px;
    }
  }
}
.c-base-avatar--circular {
  .c-base-avatar__picture {
    border-radius: 100px;
  }
}
.c-base-avatar--compose {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  width: auto !important;
  height: auto !important;
  border: none !important;
  .c-base-avatar__compose {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-right: -10px;
    margin-left: -20px;
    padding: 4px 13px 0 8px;
    height: 20px;
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
    background-color: #e0e5e8;
    .c-base-avatar__dot {
      display: inline-block;
      margin-right: 1px;
      margin-left: 1px;
      width: 4px;
      height: 4px;
      border-radius: 4px;
      background-color: #25374f;
      -webkit-animation: compose 1.5s linear infinite;
      animation: compose 1.5s linear infinite;
      &:first-child {
        -webkit-animation-delay: 0s;
        animation-delay: 0s;
      }
      &:nth-child(2) {
        -webkit-animation-delay: 0.25s;
        animation-delay: 0.25s;
      }
      &:nth-child(3) {
        -webkit-animation-delay: 0.5s;
        animation-delay: 0.5s;
      }
    }
  }
  .c-base-avatar__picture {
    border: 2px solid #e0e5e8 !important;
  }
}
</style>
