<template>
  <div class="c-base-slideshow">
    <div
      v-for="(item, index) in displayedResources"
      :key="index"
      class="c-base-slideshow__list"
    >
      <BaseHeadlines
        v-if="item.title"
        :title="item.title"
        :description="item.description"
        alignment="center"
      ></BaseHeadlines>

      <div class="c-base-slideshow__resource_wrapper">
        <BaseIcon
          class="c-base-slideshow__resource_navigation"
          name="chevron_left"
          color="#6A6F85"
          size="28px"
          v-on:click="onSwitchActiveResource(currentIndex - 1)"
        ></BaseIcon>
        <img
          class="c-base-slideshow__resource"
          v-on:click="onSwitchActiveResource(currentIndex + 1)"
          :src="resources[currentIndex].path"
        />
        <BaseIcon
          class="c-base-slideshow__resource_navigation"
          name="chevron_right"
          color="#6A6F85"
          size="28px"
          v-on:click="onSwitchActiveResource(currentIndex + 1)"
        ></BaseIcon>
      </div>

      <div v-if="item.link" class="c-base-slideshow__resource_link">
        <a class="o-link u-semibold" :href="item.link.url" target="_blank">{{
          item.link.text
        }}</a>
      </div>
    </div>

    <div class="c-base-slideshow__spinners">
      <span
        v-for="(item, index) in resources"
        :key="index"
        :class="[
          'c-base-slideshow__spinner-one',
          {
            'c-base-slideshow__spinner-one--active': index === currentIndex,
          },
        ]"
        v-on:click="(e) => onSwitchActiveResource(index)"
      ></span>
    </div>
  </div>
</template>
<script>
const a = 1e4;
export default {
  props: {
    resources: {
      type: Array,
      required: !0,
    },
    loop: {
      type: Boolean,
      default: !0,
    },
  },
  data() {
    return {
      currentIndex: 0,
      loopScheduler: null,
    };
  },
  computed: {
    displayedResources() {
      return (this.resources || []).filter((e, t) => t === this.currentIndex);
    },
  },
  mounted() {
    this.loopFn();
  },
  beforeUnmount() {
    this.loopScheduler &&
      (clearInterval(this.loopScheduler), (this.loopScheduler = null));
  },
  methods: {
    loopFn() {
      this.loopScheduler && clearInterval(this.loopScheduler),
        (this.loopScheduler = setInterval(() => {
          this.currentIndex + 1 >= this.resources.length
            ? (this.currentIndex = 0)
            : this.currentIndex++;
        }, a));
    },
    onSwitchActiveResource(e) {
      e < 0
        ? (e = this.resources.length - 1)
        : e >= this.resources.length && (e = 0),
        (this.currentIndex = e),
        this.loopFn();
    },
  },
};
</script>
<style lang="scss">
.c-base-slideshow {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  .c-base-slideshow__list {
    -webkit-box-orient: vertical;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-animation: fade;
    animation: fade;
    -webkit-animation-duration: 0.25s;
    animation-duration: 0.25s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-direction: normal;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .c-base-slideshow__resource_wrapper {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-direction: normal;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
    height: 300px;
    margin-top: 20px;
    margin-bottom: 12px;
    -webkit-box-orient: horizontal;
    -ms-flex-direction: row;
    flex-direction: row;
    .c-base-slideshow__resource_navigation {
      cursor: pointer !important;
      -webkit-transition-timing-function: linear;
      transition-timing-function: linear;
      -webkit-transition-duration: 0.1s;
      transition-duration: 0.1s;
      -webkit-transition-property:
        fill,
        -webkit-transform;
      transition-property:
        fill,
        -webkit-transform;
      transition-property: fill, transform;
      transition-property:
        fill,
        transform,
        -webkit-transform;
      &:active {
        fill: #000;
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
      }
      &:hover {
        fill: #000;
        -webkit-transform: scale(1.25);
        transform: scale(1.25);
      }
    }
    .c-base-slideshow__resource {
      height: 100%;
      margin: 0 12px;
    }
  }
  .c-base-slideshow__resource_link {
    margin-top: 6px;
    margin-bottom: 8px;
  }
  .c-base-slideshow__spinners {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    .c-base-slideshow__spinner-one {
      height: 9px;
      width: 9px;
      background: #a8c6df;
      border-radius: 100%;
      margin-right: 5px;
      cursor: pointer;
      -webkit-transition: background 0.1s linear;
      transition: background 0.1s linear;
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        background: #95bad8;
      }
    }
    .c-base-slideshow__spinner-one--active {
      background: #0093ee;
    }
  }
}
</style>
