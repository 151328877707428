import tenant from "@/classes/talqui/tenant";

export default {
  namespaced: true,
  state: {
    subscriptions: {},
    paymentMethods: {},
    invoices: {},
  },
  getters: {
    getSubscription: (state) => (tenantID) => state.subscriptions[tenantID],
    getPaymentMethods: (state) => (tenantID) => state.paymentMethods[tenantID],
    getInvoices: (state) => (tenantID) => state.invoices[tenantID],
  },
  actions: {
    acquireSubscription({ commit }, { tenantID }) {
      commit("assertSubscription", {
        tenantID: tenantID,
      });
      return tenant
        .tenantOne(tenantID)
        .one("subscription")
        .get()
        .then((res) => {
          commit("setSubscription", {
            tenantID: tenantID,
            value: res.data.subscription,
          });
          Promise.resolve(res);
        });
    },
    createPaymentMethod(
      // eslint-disable-next-line no-empty-pattern
      {},
      { tenantID, subscriptionID, paymentMethod, paymentMethodData },
    ) {
      return tenant
        .tenantOne(tenantID)
        .one("subscription")
        .one(subscriptionID)
        .one("payment-methods")
        .post({
          paymentMethod,
          paymentMethodData,
        })
        .then((res) => {
          return Promise.resolve(res);
        });
    },
    aquirePaymentMethods({ commit }, { tenantID, subscriptionID }) {
      commit("assertPaymentMethods", {
        tenantID: tenantID,
      });
      return tenant
        .tenantOne(tenantID)
        .one("subscription")
        .one(subscriptionID)
        .one("payment-methods")
        .get()
        .then((res) => {
          commit("setPaymentMethods", {
            tenantID: tenantID,
            value: res.data.paymentMethods,
          });
          return Promise.resolve(res);
        });
    },
    aquireInvoices({ commit }, { tenantID, subscriptionID }) {
      commit("assertInvoices", {
        tenantID: tenantID,
      });
      return tenant
        .tenantOne(tenantID)
        .one("subscription")
        .one(subscriptionID)
        .one("invoices")
        .get()
        .then((res) => {
          commit("setInvoices", {
            tenantID: tenantID,
            value: res.data.invoices,
          });
          return Promise.resolve(res);
        });
    },
  },
  mutations: {
    assertSubscription(state, { tenantID }) {
      state.subscriptions[tenantID] ||
        (state.subscriptions[tenantID] = {
          subscriptionID: null,
          subscriptionStatus: "paid",
          subscriptionCurrency: "BRL",
          subscriptionPriceAmount: 100,
          subscriptionCurrentPeriodStartAt: new Date(),
          subscriptionCurrentPeriodEndAt: new Date(),
          subscriptionCreatedAt: new Date(),
        });
    },
    assertPaymentMethods(state, { tenantID }) {
      state.paymentMethods[tenantID] || (state.paymentMethods[tenantID] = []);
    },
    assertInvoices(state, { tenantID }) {
      state.invoices[tenantID] || (state.invoices[tenantID] = []);
    },
    setSubscription(state, { tenantID, value }) {
      state.subscriptions[tenantID] = value;
    },
    setPaymentMethods(state, { tenantID, value }) {
      state.paymentMethods[tenantID] = value;
    },
    setInvoices(state, { tenantID, value }) {
      state.invoices[tenantID] = value;
    },
  },
};
