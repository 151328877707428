<template>
  <div
    :class="[
      'c-base-empty-spot',
      'c-base-empty-spot--' + size,
      {
        'c-base-empty-spot--light': light,
        ['c-base-empty-spot--' + illustration]: illustration,
      },
    ]"
  >
    <BaseIllustration
      v-if="illustration"
      class="c-base-empty-spot__illustration"
      :name="illustration"
    ></BaseIllustration>
    <div class="c-base-empty-spot__title">{{ title }}</div>
    <div class="c-base-empty-spot__description">{{ description }}</div>
    <div v-if="this.$slots.buttons" class="c-base-empty-spot__buttons">
      <slot name="buttons"></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    description: {
      type: String,
      required: !0,
    },
    illustration: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: "default",
      validator(e) {
        return -1 !== ["mini", "small", "default"].indexOf(e);
      },
    },
    title: {
      type: String,
      required: !0,
    },
    light: {
      type: Boolean,
      default: !1,
    },
  },
};
</script>
<style lang="scss">
.c-base-empty-spot {
  text-align: center;
}

.c-base-empty-spot .c-base-empty-spot__illustration {
  margin-bottom: 18px;
  max-width: 200px;
  max-height: 200px;
}

.c-base-empty-spot .c-base-empty-spot__title {
  margin-bottom: 12px;
  color: #1c293b;
  font-weight: 700;
}

.c-base-empty-spot .c-base-empty-spot__description {
  color: #75869c;
}

.c-base-empty-spot .c-base-empty-spot__buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 30px;
}

.c-base-empty-spot .c-base-empty-spot__buttons > * {
  margin-right: 10px;
}

.c-base-empty-spot .c-base-empty-spot__buttons > :last-of-type {
  margin-right: 0;
}

.c-base-empty-spot--artboard .c-base-empty-spot__illustration {
  width: 138px;
  height: 132px;
}

.c-base-empty-spot--inbox .c-base-empty-spot__illustration {
  width: 120px;
  height: 111px;
}

.c-base-empty-spot--users_all .c-base-empty-spot__illustration {
  width: 180px;
  height: 105px;
}

.c-base-empty-spot--campaign .c-base-empty-spot__illustration {
  width: 138px;
  height: 132px;
}

.c-base-empty-spot--helpdesk_articles .c-base-empty-spot__illustration,
.c-base-empty-spot--helpdesk_categories .c-base-empty-spot__illustration {
  width: 240px;
  height: 180px;
  margin-left: 10px;
}

.c-base-empty-spot--mini {
  padding: 0 7px;
  line-height: 16px;
}

.c-base-empty-spot--mini .c-base-empty-spot__title {
  font-size: 15.5px;
}

.c-base-empty-spot--mini .c-base-empty-spot__description {
  font-size: 13px;
}

.c-base-empty-spot--small {
  padding: 0 8px;
  line-height: 16px;
}

.c-base-empty-spot--small .c-base-empty-spot__title {
  font-size: 16px;
}

.c-base-empty-spot--small .c-base-empty-spot__description {
  font-size: 14px;
}

.c-base-empty-spot--default {
  padding: 0 12px;
  line-height: 18px;
}

.c-base-empty-spot--default .c-base-empty-spot__title {
  font-size: 18px;
}

.c-base-empty-spot--default .c-base-empty-spot__description {
  font-size: 15px;
}

.c-base-empty-spot--light .c-base-empty-spot__title {
  color: hsla(0, 0%, 100%, 0.95);
}

.c-base-empty-spot--light .c-base-empty-spot__description {
  color: hsla(0, 0%, 100%, 0.65);
}
</style>
