import tenant from "@/classes/talqui/tenant";
// import router from "@/router";

export default {
  namespaced: !0,
  state: {
    currentTenant: {},
    tenantID: "",
    tenants: [],
    setup: {},
    routingRules: {},
    routingSettings: {},
    messagesDefault: {},
    businessHours: {},
    sessionMotives: {},

    /**
     * New version settings
     */
    sessionsSettings: {},
  },
  getters: {
    getTenant: (state) => (t) => state.tenants.find((e) => e.tenantID === t),
    getTenants: (state) => state.tenants,
    getTenantID: (state) => state.tenantID,
    getSetup: (state) => (tenantID) =>
      state.setup[tenantID] ? state.setup[tenantID] : null,
    getRoutingRules: (state) => (tenantID) =>
      state.routingRules[tenantID] ? state.routingRules[tenantID] : [],
    getRoutingSettings: (state) => (tenantID) =>
      state.routingSettings[tenantID] ? state.routingSettings[tenantID] : {},
    getMessagesDefault: (state) => (tenantID) =>
      state.messagesDefault[tenantID] ? state.messagesDefault[tenantID] : {},
    getBusinessHours: (state) => (tenantID) =>
      state.businessHours[tenantID] ? state.businessHours[tenantID] : {},
    getSessionMotives: (state) => (tenantID) =>
      state.sessionMotives[tenantID] ? state.sessionMotives[tenantID] : {},
  },
  actions: {
    logout({ commit }) {
      commit("reset");
    },
    acquireTenants({ getters, commit }) {
      return tenant
        .tenantList()
        .get()
        .then((res) => {
          commit("setTenants", res.tenants);
          if (res?.tenants?.length > 0) {
            let $currentTenant = res.tenants.find((tenant) => {
              return tenant.tenantID === getters.getTenantID;
            });
            commit("setCurrentTenant", $currentTenant);
          }
          return Promise.resolve(res.tenants);
        });
    },
    updateTenantSettings(
      { commit },
      {
        tenantID,
        tenantDescription = null,
        tenantLogo = null,
        tenantName = null,
        tenantWebsite = null,
      },
    ) {
      return tenant
        .tenantOne(tenantID)
        .one("settings")
        .patch({
          ...(tenantDescription && { tenantDescription }),
          ...(tenantLogo && { tenantLogo }),
          ...(tenantName && { tenantName }),
          ...(tenantWebsite && { tenantWebsite }),
        })
        .then((res) => {
          commit("setCurrentTenant", res.data.tenant);
          return Promise.resolve(res);
        });
    },
    acquireRoutingRules({ commit }, { tenantID }) {
      return tenant
        .tenantOne(tenantID)
        .one("settings")
        .one("routing-rules")
        .get()
        .then((res) => {
          const rules = res.data.routingRules;

          commit("setRoutingRules", {
            tenantID: tenantID,
            rules: rules,
          });

          return Promise.resolve(rules);
        });
    },
    acquireRoutingSettings({ commit }, { tenantID }) {
      return tenant
        .tenantOne(tenantID)
        .one("settings")
        .one("routing-settings")
        .get()
        .then((res) => {
          const settings = res.data.routingSettings;

          commit("setRoutingSettings", {
            tenantID: tenantID,
            settings: settings,
          });

          return Promise.resolve(settings);
        });
    },

    acquireSettings({ commit }, { tenantID }) {
      return tenant
        .tenantOne(tenantID)
        .one("settings")
        .get()
        .then((res) => {
          /**
           * Commit settings [sessions]
           */
          const sessionSettings = res.settings.sessions;
          commit("setSessionsSettings", {
            tenantID: tenantID,
            sessionSettings: sessionSettings,
          });

          return Promise.resolve(sessionSettings);
        });
    },

    acquireSettingsMessagesDefault({ commit }, { tenantID }) {
      return tenant
        .tenantOne(tenantID)
        .one("settings")
        .one("messages-default")
        .get()
        .then((res) => {
          const messages = res.data.messagesDefault;

          commit("setMessagesDefault", {
            tenantID: tenantID,
            messagesDefault: messages,
          });

          return Promise.resolve(messages);
        });
    },

    acquireSettingsBusinessHours({ commit }, { tenantID }) {
      return tenant
        .tenantOne(tenantID)
        .one("settings")
        .one("business-hours")
        .get()
        .then((res) => {
          const businessHours = res.businessHours;
          commit("setBusinessHours", {
            tenantID: tenantID,
            businessHours: businessHours,
          });
          return Promise.resolve(businessHours);
        });
    },

    acquireSettingsSessionMotives({ commit }, { tenantID }) {
      return tenant
        .tenantOne(tenantID)
        .one("settings")
        .one("session-motives")
        .get()
        .then((res) => {
          const sessionMotives = res.sessionMotives;
          commit("setSessionMotives", {
            tenantID: tenantID,
            sessionMotives: sessionMotives,
          });
          return Promise.resolve(sessionMotives);
        });
    },

    settingsSessionMotiveCreate(
      { commit },
      { tenantID, tenantSessionMotiveTitle, tenantSessionMotiveDescription },
    ) {
      return tenant
        .tenantOne(tenantID)
        .one("settings")
        .one("session-motives")
        .post({
          tenantSessionMotiveTitle,
          tenantSessionMotiveDescription,
        })
        .then((res) => {
          const sessionMotives = res.sessionMotives;
          commit("setSessionMotives", {
            tenantID: tenantID,
            sessionMotives: sessionMotives,
          });
          return Promise.resolve(sessionMotives);
        });
    },

    settingsSessionMotiveUpdate(
      { commit },
      {
        tenantID,
        tenantSessionMotiveID,
        tenantSessionMotiveTitle,
        tenantSessionMotiveDescription,
      },
    ) {
      return tenant
        .tenantOne(tenantID)
        .one("settings")
        .one("session-motives")
        .one(tenantSessionMotiveID)
        .put({
          tenantSessionMotiveTitle,
          tenantSessionMotiveDescription,
        })
        .then((res) => {
          const sessionMotives = res.sessionMotives;
          commit("setSessionMotives", {
            tenantID: tenantID,
            sessionMotives: sessionMotives,
          });
          return Promise.resolve(sessionMotives);
        });
    },

    settingsSessionMotiveDelete(
      { commit },
      { tenantID, tenantSessionMotiveID },
    ) {
      return tenant
        .tenantOne(tenantID)
        .one("settings")
        .one("session-motives")
        .one(tenantSessionMotiveID)
        .delete()
        .then((res) => {
          const sessionMotives = res.sessionMotives;
          commit("setSessionMotives", {
            tenantID: tenantID,
            sessionMotives: sessionMotives,
          });
          return Promise.resolve(sessionMotives);
        });
    },

    getSetup({ commit }, { tenantID }) {
      return tenant
        .tenantOne(tenantID)
        .one("setup")
        .get()
        .then((res) => {
          commit("setSetup", {
            tenantID: tenantID,
            setup: res,
          });
          return Promise.resolve();
        });
    },
  },
  mutations: {
    reset(state) {
      state.currentTenant = {};
      state.tenantID = "";
      state.tenants = [];
    },
    setTenants(state, tenants) {
      state.tenants = tenants;
    },
    setTenantID(state, tenantID) {
      state.tenantID = tenantID;
    },
    setCurrentTenant(state, currrentTenant) {
      state.currentTenant = currrentTenant;
      state.tenantID = currrentTenant.tenantID;

      /**
       * Update tenant in array of tenants
       *
       * Special case because store not in best shape
       */
      let __tenantIndex = state.tenants.findIndex(
        (e) => e.tenantID === currrentTenant.tenantID,
      );
      if (__tenantIndex != -1) {
        state.tenants[__tenantIndex] = currrentTenant;
      }
    },
    setSetup(state, { tenantID, setup }) {
      state.setup[tenantID] = setup;
    },
    setRoutingRules(state, { tenantID, rules }) {
      state.routingRules[tenantID] = rules;
    },
    setRoutingSettings(state, { tenantID, settings }) {
      state.routingSettings[tenantID] = settings;
    },
    setMessagesDefault(state, { tenantID, messagesDefault }) {
      state.messagesDefault[tenantID] = messagesDefault;
    },
    setSessionsSettings(state, { tenantID, sessionSettings }) {
      state.sessionsSettings[tenantID] = sessionSettings;
    },
    setBusinessHours(state, { tenantID, businessHours }) {
      state.businessHours[tenantID] = businessHours;
    },
    setSessionMotives(state, { tenantID, sessionMotives }) {
      state.sessionMotives[tenantID] = sessionMotives;
    },
  },
};
