<template>
  <span
    :class="[
      'c-base-channel-icon',
      'u-has-tooltip',
      {
        'c-base-channel-icon--circular': circular,
      },
    ]"
    :style="{
      height: height,
      width: width,
    }"
  >
    <BaseTooltip v-if="tooltip" :align="tooltipAlign">
      {{ tooltip }}
    </BaseTooltip>
    <slot></slot>
    <span
      class="c-base-channel-icon__inner"
      :style="{
        borderColor: borderColor,
        borderStyle: borderColor && borderWidth ? 'solid' : null,
        borderWidth: borderWidth,
        height: height,
        width: width,
      }"
    >
      <span
        class="c-base-channel-icon__image"
        :style="{
          backgroundImage: channel
            ? 'url(/images/common/channels/' + channelProcess + '.png)'
            : 'url(/images/common/flags/default.svg)',
          height: height,
          width: width,
        }"
      ></span>
    </span>
  </span>
</template>
<script>
export default {
  props: {
    circular: {
      type: Boolean,
      default: !1,
    },
    channel: {
      type: String,
      default: null,
    },
    locale: {
      type: String,
      default: null,
    },
    borderColor: {
      type: String,
      default: null,
    },
    borderWidth: {
      type: String,
      default: null,
    },
    hasTooltip: {
      type: Boolean,
      default: !1,
    },
    tooltipAlign: {
      type: String,
      default: "left",
      validator(e) {
        return -1 !== ["left", "center", "right"].indexOf(e);
      },
    },
    height: {
      type: String,
      required: !0,
    },
    width: {
      type: String,
      required: !0,
    },
  },
  computed: {
    channelProcess() {
      if (this?.channel?.toLowerCase().startsWith("whatsapp")) {
        return "whatsapp";
      } else {
        return this.channel;
      }
    },
    tooltip() {
      if (this.hasTooltip) {
        return this.channelProcess
          ? this.$filters.format.ucFirst(this.channelProcess)
          : this.$t("TALQUI.TOOLTIPS.NO_CHANNEL");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.c-base-channel-icon {
  .c-base-channel-icon__inner {
    position: relative;
    display: block;
    .c-base-channel-icon__image {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: inline-block;
      overflow: hidden;
      background-color: #eff3f6;
      background-position: 50%;
      background-size: cover;
    }
  }
}
.c-base-channel-icon--circular {
  .c-base-channel-icon__inner {
    overflow: hidden;
    border-radius: 100%;
  }
}
</style>
