<template>
  <div
    :class="[
      'c-field-token',
      {
        'c-field-token--error': error,
      },
    ]"
    ref="root"
  >
    <input
      v-for="item in size"
      :key="item"
      v-on:input="(t) => onInput(item, t)"
      v-on:keydown="(t) => onKeydown(item, t)"
      v-on:paste="(...e) => onPaste(...e)"
      :disabled="disabled"
      :class="[
        'c-field-token__field',
        'js-field',
        {
          'u-bold': token[item - 1],
        },
      ]"
      max="9"
      min="0"
      type="number"
      placeholder="0"
      pattern="^[0-9]{1}$"
      maxlength="1"
    />
  </div>
</template>
<script>
const a = 6;
const o = /^([0-9]{6})$/;
export default {
  props: {
    error: {
      type: Boolean,
      default: !1,
    },
    modelValue: {
      type: [String, Number],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: !1,
    },
  },
  emits: ["update:modelValue", "submit"],
  data() {
    return {
      token: Array(a).fill(""),
      size: a,
    };
  },
  watch: {
    modelValue: {
      handler(e) {
        for (let t = 0; t < a; t++) this.token[t] = void 0 !== e[t] ? e[t] : "";
      },
    },
  },
  mounted() {
    const e = this.$refs.root.querySelectorAll(".js-field")[0];
    e.focus();
  },
  methods: {
    getTokenValue() {
      let e = "";
      for (let t = 0; t < this.token.length; t++)
        e += void 0 !== this.token[t] ? this.token[t] : "";
      return e;
    },
    submitIfComplete() {
      this.getTokenValue().length === a && this.$emit("submit");
    },
    onInput(e, t) {
      if (
        (this.$emit("update:modelValue", this.getTokenValue()), e < a && t.data)
      ) {
        const t = this.$refs.root.querySelectorAll(".js-field");
        t[e].focus();
      }
      this.submitIfComplete();
    },
    onKeydown(e, t) {
      switch (t.key) {
        case "Enter":
          this.$emit("submit");
          break;
        case "Backspace": {
          const t = this.$refs.root.querySelectorAll(".js-field");
          0 === t[e - 1].value.length && e > 1 && t[e - 2].focus();
          break;
        }
      }
    },
    onPaste(e) {
      e.preventDefault();
      let t = e.clipboardData || e.originalEvent.clipboardData || {};
      if (t) {
        let i = (t.getData("text/plain") || "").trim();
        !0 === o.test(i) &&
          ((this.token = i.split("")),
          this.$emit("update:modelValue", i, e),
          this.submitIfComplete());
      }
    },
  },
};
</script>
<style lang="scss">
.c-field-token {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #2c405a;
  .c-field-token__field {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    margin: 0;
    padding: 15px 10px;
    min-width: 0;
    width: 100%;
    outline: 0;
    border: 1px solid #e3e8ef;
    border-right: none;
    background-color: #fafbfc;
    text-align: center;
    -moz-appearance: textfield;
    &:first-of-type {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    &:last-of-type {
      border-right: 1px solid #e3e8ef;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
    }
    &::-webkit-input-placeholder {
      text-align: center;
    }
    &::-moz-placeholder {
      text-align: center;
    }
    &::-ms-input-placeholder {
      text-align: center;
    }
    &::placeholder {
      text-align: center;
    }
    &:focus {
      border-bottom-color: #2c405a;
    }
  }
}
.c-field-token--error {
  .c-field-token__field {
    border-color: #e0102b !important;
    color: #e0102b;
    &::-webkit-input-placeholder {
      color: #e0102b;
    }
    &::-moz-placeholder {
      color: #e0102b;
    }
    &::-ms-input-placeholder {
      color: #e0102b;
    }
    &::placeholder {
      color: #e0102b;
    }
  }
}
</style>
