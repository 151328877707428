import a from "js-cookie";
import o from "./userAuth.js";
import n from "./analytics.js";
import r from "./http.js";
import s from "./auth.js";
import l from "../store/index.js";

const d = 4e3;

class _websites {
  constructor() {
    this.subscribed = !1;
  }
  join(e) {
    o.isLogged() && e && (n.setGroup("website_id", e), this.subscribers(e));
  }
  websiteOne(e) {
    return r.one("website", e);
  }
  setup(e) {
    return this.websiteOne(e).one("setup");
  }
  subscription(e) {
    return r.one("plans/subscription").one(e);
  }
  subscriptions() {
    return r.one("plans/subscription");
  }
  states(e) {
    return this.websiteOne(e).one("states");
  }
  billing(e) {
    return this.websiteOne(e).one("billing");
  }
  billingCard(e, t) {
    return this.billing(e).one(t);
  }
  batchRead(e) {
    return this.websiteOne(e).one("batch/read");
  }
  batchRemove(e) {
    return this.websiteOne(e).one("batch/remove");
  }
  batchResolve(e) {
    return this.websiteOne(e).one("batch/resolve");
  }
  channelOne(e, t) {
    return this.websiteOne(e).one("channel", t);
  }
  channelDomain(e, t) {
    return this.channelOne(e, t).one("domain");
  }
  channelSignature(e, t) {
    return this.channelOne(e, t).one("signature");
  }
  channelSetup(e, t) {
    return this.channelOne(e, t).one("setup");
  }
  channelIp(e, t) {
    return this.channelOne(e, t).one("ip");
  }
  filter(e) {
    return this.websiteOne(e).one("filter");
  }
  filterOne(e, t) {
    return this.filter(e).one(t);
  }
  filters(e, t) {
    return this.websiteOne(e).one("filters", t);
  }
  operatorsActive(e) {
    return this.websiteOne(e).one("operators/active");
  }
  operatorsList(e) {
    return this.websiteOne(e).one("operators/list");
  }
  operator(e) {
    return this.websiteOne(e).one("operator");
  }
  operatorOne(e, t) {
    return this.operator(e).one(t);
  }
  inviteOne(e, t, i) {
    return this.websiteOne(e).one("invite").one(t, i);
  }
  sandboxOne(e, t) {
    return this.websiteOne(e).one("sandbox").one(t);
  }
  availability(e) {
    return this.websiteOne(e).one("availability");
  }
  availabilityStatus(e) {
    return this.availability(e).one("status");
  }
  getUsersAvailable(e) {
    return this.availabilityStatus(e)
      .get()
      .then(
        (e) => (
          l.commit("websites/setUsersAvailable", {
            value: "online" === e.status,
          }),
          Promise.resolve(e)
        ),
      );
  }
  settings(e) {
    return this.websiteOne(e).one("settings");
  }
  hooks(e, t = 1) {
    return this.websiteOne(e).one("hooks", t);
  }
  hook(e) {
    return this.websiteOne(e).one("hook");
  }
  routing(e) {
    return this.websiteOne(e).one("routing");
  }
  routingSettings(e) {
    return this.routing(e).one("settings");
  }
  routingRules(e) {
    return this.routing(e).one("rules");
  }
  verifySettings(e) {
    return this.websiteOne(e).one("verify/settings");
  }
  verifyKey(e) {
    return this.websiteOne(e).one("verify/key");
  }
  ratingSessionsList(e, t) {
    return this.websiteOne(e).one("rating", "sessions").one("list", t);
  }
  ratingSessionsStars(e) {
    return this.websiteOne(e).one("rating", "sessions").one("stars");
  }
  contracts(e) {
    return this.websiteOne(e).one("contracts");
  }
  contractOne(e, t) {
    return this.websiteOne(e).one("contract", t);
  }
  usage(e) {
    return this.websiteOne(e).one("usage");
  }
  usageQuotas(e) {
    return this.usage(e).one("quotas");
  }
  usageLimits(e) {
    return this.usage(e).one("limits");
  }
  getVisitorCount(e) {
    return this.websiteOne(e)
      .one("visitors")
      .one("count")
      .get()
      .then(
        (e) => (
          l.commit("visitors/setCount", {
            key: "count",
            value: e.count,
          }),
          l.commit("visitors/setCount", {
            key: "active",
            value: e.active,
          }),
          l.commit("visitors/setCount", {
            key: "limited",
            value: e.limited,
          }),
          Promise.resolve()
        ),
      );
  }
  delete(e, t) {
    return this.websiteOne(e).delete({
      verify: t,
    });
  }
  exists(e) {
    return r.one("website").head({
      domain: e,
    });
  }
  connect(e) {
    return this.websiteOne(e).one("connect");
  }
  connectEndpoints(e) {
    return this.connect(e).one("endpoints");
  }
  create(e, t) {
    return r.one("website").post({
      name: e,
      domain: t,
    });
  }
  clearDefaultWebsiteId() {
    try {
      localStorage && localStorage.removeItem("default_website_id"),
        a.remove("default_website_id");
      //eslint-disable-next-line
    } catch (e) {}
  }
  getDefaultWebsiteId() {
    try {
      return localStorage
        ? localStorage.getItem("default_website_id")
        : a.get("default_website_id");
    } catch (e) {
      return null;
    }
  }
  hasDefaultWebsiteId(e) {
    let t = this.getDefaultWebsiteId();
    if (t) for (let i = 0; i < e.length; i++) if (t === e[i].id) return !0;
    return !1;
  }
  setDefaultWebsiteId(e) {
    try {
      if (localStorage) return localStorage.setItem("default_website_id", e), e;
      a.set("default_website_id", e, {
        sameSite: "strict",
      });
      //eslint-disable-next-line
    } catch (t) {}
  }
  subscribers(e) {
    (this.subscribed = !0),
      setTimeout(() => {
        this.getVisitorCount(e), this.getUsersAvailable(e);
      }, d),
      s.on("website:users:available", (t) => {
        t.website_id === e &&
          l.commit("websites/setUsersAvailable", {
            value: t.available,
          });
      }),
      s.on("website:update_visitors_count", (t) => {
        t.website_id === e &&
          l.commit("visitors/setCount", {
            key: "count",
            value: t.visitors_count,
          });
      }),
      s.on("website:validate:domain:invalid", (e) => {
        this.onWebsiteValidateDomainInvalid &&
          this.onWebsiteValidateDomainInvalid(
            e.website_id,
            e.domain,
            e.records,
          );
      }),
      s.on("website:hints:technology:detected", (e) => {
        this.onWebsiteHintsTechnologyDetected &&
          this.onWebsiteHintsTechnologyDetected(e.website_id, e.technology);
      });
  }
}
export default new _websites();
