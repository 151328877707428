<template>
  <div class="c-field-color-picker">
    <div v-if="label" class="c-field-color-picker__header">
      <FieldLabel
        class="c-field-color-picker__label"
        :for-field="uuid"
        :size="size"
      >
        {{ label }}
      </FieldLabel>
    </div>

    <div class="c-field-color-picker__colors">
      <div
        v-for="(item, index) in filteredColors"
        :key="index"
        class="c-field-color-picker__color"
        :data-selected="modelValue === item.name"
      >
        <div
          class="c-field-color-picker__color-background"
          v-on:click="updateColor(item)"
          :style="{
            backgroundColor: item.color,
          }"
        ></div>
      </div>
    </div>

    <a
      v-if="expended"
      class="c-field-color-picker__see-more u-semibold u-underline u-mt5"
      v-on:click="(e) => (expended = !0)"
    >
      {{ this.$t("FORMS.FIELD_COLOR_PICKER.SEE_MORE") }}
    </a>
  </div>
</template>
<script>
import { v4 } from "uuid";
export default {
  props: {
    label: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "default",
      validator(e) {
        return (
          -1 !== ["mini", "small", "default", "medium", "large"].indexOf(e)
        );
      },
    },
    modelValue: {
      type: String,
      default: null,
    },
    colors: {
      type: Array,
      required: !0,
    },
  },
  emits: ["update:modelValue", "change"],
  data() {
    return {
      currentValue: "",
      expended: !1,
      uuid: null,
    };
  },
  computed: {
    filteredColors() {
      return this.colors.filter((e) => !1 === e.hidden || this.expended);
    },
  },
  watch: {
    modelValue: {
      immediate: !0,
      handler(e) {
        this.currentValue = e;
      },
    },
  },
  created() {
    this.uuid = v4();
  },
  methods: {
    updateColor(e) {
      this.$emit("update:modelValue", e.name);
      this.$emit("change", e.name);
    },
  },
};
</script>
<style lang="scss">
.c-field-color-picker {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  color: #2c405a;
  text-align: left;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  .c-field-color-picker__header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    .c-field-color-picker__label {
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
    }
  }
  .c-field-color-picker__colors {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    max-width: 300px;
    .c-field-color-picker__color {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      margin-right: 5px;
      margin-bottom: 5px;
      cursor: pointer;
      -webkit-animation: fadeIn;
      animation: fadeIn;
      -webkit-animation-duration: 0.35s;
      animation-duration: 0.35s;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
      .c-field-color-picker__color-background {
        width: 34px;
        height: 34px;
        border-radius: 100%;
        -webkit-transition: all 0.08s ease;
        transition: all 0.08s ease;
      }
    }
    .c-field-color-picker__color[data-selected="true"] {
      .c-field-color-picker__color-background {
        -webkit-box-shadow: inset 0 0 0 2px #fff;
        box-shadow: inset 0 0 0 2px #fff;
        border: 2px solid #0074fe;
      }
    }
  }
}
</style>
