<template>
  <div
    :class="[
      'c-field-tabs',
      'c-field-tabs--' + direction,
      {
        'c-field-tabs--block': block,
        'c-field-tabs--disabled': disabled,
        'c-field-tabs--multiple': multiple,
      },
    ]"
  >
    <FieldLabel v-if="label" class="c-field-tabs__label">
      {{ label }}
    </FieldLabel>
    <div
      class="c-field-tabs__container"
      :style="{
        'text-align': alignment,
      }"
    >
      <span
        v-for="(item, index) in tabs"
        :key="index"
        :class="[
          'c-field-tabs__tab',
          {
            'c-field-tabs__tab--active': -1 !== activeTabs.indexOf(item.id),
            'c-field-tabs__tab--disabled': item.disabled,
          },
        ]"
        v-on:click="(t) => onTabClick(item.id, t)"
        v-on:keypress.prevent="(...e) => onTabKeypress(...e)"
        tabindex="0"
      >
        {{ item.name }}
      </span>
    </div>
    <FieldDescription
      v-if="description"
      :description="description"
      :size="size"
    ></FieldDescription>
  </div>
</template>
<script>
export default {
  props: {
    alignment: {
      type: String,
      default: "left",
    },
    block: {
      type: Boolean,
      default: !1,
    },
    direction: {
      type: String,
      default: "vertical",
      validator(e) {
        return -1 !== ["horizontal", "vertical"].indexOf(e);
      },
    },
    description: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: !1,
    },
    label: {
      type: String,
      default: null,
    },
    multiple: {
      type: Boolean,
      default: !1,
    },
    name: {
      type: String,
      required: !0,
    },
    tabs: {
      type: Array,
      required: !0,
    },
    value: {
      type: [Array, String],
      default: null,
    },
  },
  emits: ["change", "click"],
  data() {
    return {
      activeTabs: [],
    };
  },
  watch: {
    tabs: {
      deep: !0,
      immediate: !0,
      handler(e) {
        e && (this.activeTabs = e.filter((e) => e.active).map((e) => e.id));
      },
    },
    value: {
      deep: !0,
      immediate: !0,
      handler(e) {
        e &&
          (Array.isArray(e) ? (this.activeTabs = e) : (this.activeTabs = [e]));
      },
    },
  },
  methods: {
    checkActiveBrother(e, t) {
      if (this.multiple && this.tabs[t])
        return -1 !== this.activeTabs.indexOf(this.tabs[t].id);
    },
    onTabClick(e, t) {
      this.multiple ||
        -1 === !this.activeTabs.indexOf(e) ||
        ((this.activeTabs = [e]),
        this.$emit("change", e, "added", this.activeTabs, this.name, t)),
        this.multiple &&
          (-1 !== this.activeTabs.indexOf(e)
            ? (this.activeTabs.splice(this.activeTabs.indexOf(e), 1),
              this.$emit("change", e, "removed", this.activeTabs, this.name, t))
            : (this.activeTabs.push(e),
              this.$emit("change", e, "added", this.activeTabs, this.name, t))),
        this.$emit("click", e, this.activeTabs, this.name, t);
    },
    onTabKeypress(e) {
      "Space" === e.code && e.target.click();
    },
  },
};
</script>
<style lang="scss">
.c-field-tabs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  .c-field-tabs__label {
    cursor: default;
  }
  .c-field-tabs__container {
    display: inline-block;
    cursor: pointer;
    .c-field-tabs__tab {
      display: inline-block;
      padding: 8px 13px;
      outline: 0;
      border-width: 1px;
      border-style: solid;
      border-color: transparent;
      border-top-color: #e3e8ef;
      border-right-color: #eff3f6;
      border-bottom-color: #e3e8ef;
      border-left: none;
      background: #fff;
      color: #1c293b;
      font-weight: 700;
      font-size: 13.5px;
      &:first-of-type {
        border-left: 1px solid #e3e8ef;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        padding-left: 15px;
      }
      &:first-of-type.c-field-tabs__tab--active {
        padding-left: 15px;
        margin-left: 0;
      }
      &:last-of-type {
        padding-right: 15px;
        border-right: 1px solid #e3e8ef;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
      &:hover {
        background-color: #fafbfc;
      }
      &:active {
        background-color: #f7f8fa;
      }
    }
    .c-field-tabs__tab--active {
      z-index: 1;
      margin-left: -1px;
      padding-left: 14px;
      border-color: #0093ee !important;
      color: #fff;
      background-color: #0093ee;
      &:active {
        background-color: #0093ee;
      }
      &:hover {
        background-color: #0093ee;
      }
    }
    .c-field-tabs__tab--disabled {
      opacity: 0.6;
      pointer-events: none;
    }
  }
}
.c-field-tabs--horizontal {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  .c-field-tabs__label {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 140px;
  }
  .c-field-tabs__container {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
}
.c-field-tabs--vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.c-field-tabs--block {
  .c-field-tabs__container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    .c-field-tabs__tab {
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
      text-align: center;
    }
  }
}
.c-field-tabs--disabled {
  opacity: 0.35;
  .c-field-tabs__container {
    cursor: not-allowed;
    .c-field-tabs__tab {
      pointer-events: none;
    }
  }
  .c-field-tabs__label {
    cursor: not-allowed;
    .c-field-tabs__tab {
      pointer-events: none;
    }
  }
}
</style>
