class _routesGraph {
  constructor() {
    this.reset();
    this.routesGraph = {};
    this.resolved = {};
    this.cachedFunctions = {};
  }

  reset() {
    this.resolved = {};
    this.router = {};
  }

  merge(e, t) {
    for (let i in t) {
      e[i] = t[i];
    }
  }

  executePromiseChain(resolvers) {
    let $results = {};
    let $chain = Object.entries(resolvers).reduce((state, resolver) => {
      const [resolverName, resolverFunc] = resolver;
      if (typeof resolverFunc[0] === "string") {
        state.push(
          new Promise((cb) => {
            let resolverResponse = resolverFunc[1]();
            $results[resolverName] = resolverResponse;
            cb(resolverResponse);
          }),
        );
      } else {
        state.push(
          new Promise((cb) => {
            cb(resolverFunc[0]());
          }),
        );
      }
      return state;
    }, []);

    return Promise.all($chain).then(() => Promise.resolve($results));
    // return Promise.all($chain).then(() => Promise.resolve($results));
  }

  resolve(route, resolvers = {}) {
    this.route = route.to;
    return new Promise((resolve) => {
      // console.log("@routerResolver", route, resolvers);
      this.executePromiseChain(resolvers).then((results) => {
        this.merge(this.resolved, results);
        route.next && !route.successFn && route.next();
        resolve(results);
      });
      // .catch((error) => console.log(error));
      // route.to && (this.route = route.to),
      //   this.updateRouteGraph(route.name, resolvers),
      //   this.functionGenerator(resolvers, this.resolved),
      //   this.executePromiseChain(resolvers)
      //     .then(
      //       (t) => (
      //         this.merge(this.resolved, t),
      //         route.next && !route.successFn && route.next(),
      //         resolve(t)
      //       )
      //     )
      //     .catch((error) => console.log(error));
    });
  }
}

export default new _routesGraph();
