<template>
  <li
    v-on:click="(...e) => onItemClick && onItemClick(...e)"
    v-on:mousedown="(...e) => onItemMousedown && onItemMousedown(...e)"
    v-on:mouseleave="(...e) => onItemMouseLeave && onItemMouseLeave(...e)"
    v-on:mouseup="(...e) => onItemMouseup && onItemMouseup(...e)"
    :class="[
      'c-base-dropdown-item',
      {
        'c-base-dropdown-item--recording': record && audioRecording.active,
        'c-base-dropdown-item--disabled':
          disabled ||
          (upgrade &&
            upgrade.pluginUrn &&
            !this.$pluginExists(upgrade.pluginUrn)),
      },
    ]"
  >
    <span
      v-if="audioRecording.active"
      class="c-base-dropdown-item__progress"
      :style="{
        width: audioRecording.progress + '%',
      }"
    ></span>

    <BaseUpgrade
      v-if="upgrade"
      class="c-base-dropdown-item__upgrade"
      :blur="false"
      :label="this.$t(upgrade.label)"
      :plan-name="upgrade.planName"
      :plugin-name="upgrade.pluginName"
      :plugin-urn="upgrade.pluginUrn"
      :title="this.$t(upgrade.title)"
      direction="top"
      align="right"
      width="340px"
    >
      <div class="c-base-dropdown-item__wrapper">
        <BaseIcon
          class="c-base-dropdown-item__icon"
          v-if="icon"
          :name="icon"
          size="18px"
        ></BaseIcon>
        <span class="c-base-dropdown-item__label">{{ computedLabel }}</span>
        <input
          v-if="file"
          class="c-base-dropdown-item__file"
          v-on:change="(...e) => onFileChange && onFileChange(...e)"
          multiple="multiple"
          name="attach_file"
          type="file"
        />
      </div>
    </BaseUpgrade>

    <div v-else class="c-base-dropdown-item__wrapper">
      <BaseIcon
        class="c-base-dropdown-item__icon"
        v-if="icon"
        :name="icon"
        size="18px"
      ></BaseIcon>
      <span class="c-base-dropdown-item__label">{{ computedLabel }}</span>
      <input
        v-if="file"
        class="c-base-dropdown-item__file"
        v-on:change="(...e) => onFileChange && onFileChange(...e)"
        multiple="multiple"
        name="attach_file"
        type="file"
      />
    </div>
  </li>
</template>
<script>
import mixinsPlugins from "@/mixins/plugins";
import MicRecorder from "mic-recorder-to-mp3";

const r = 60;
export default {
  mixins: [mixinsPlugins],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    file: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: true,
    },
    record: {
      type: Boolean,
      default: false,
    },
    upgrade: {
      type: Object,
      default: null,
    },
    canShow: {
      type: Function,
      default: null,
    },
  },
  emits: ["click", "fileChange", "audioFile"],
  data() {
    return {
      audioRecording: {
        available: false,
        active: false,
        stream: null,
        recorder: null,
        scheduler: null,
        seconds: 0,
        progress: 0,
      },
    };
  },
  computed: {
    show() {
      let e = true;
      return (
        e && this.canShow && (e = this.canShow()),
        e && this.record && (e = this.audioRecording.available),
        e
      );
    },
    computedLabel() {
      let e = this.label;
      return (
        this.audioRecording.active &&
          (e = `${this.$t("BASES.DROPDOWN_ITEM.SHARE_AUDIO_RECORDING_MAIN")} (${
            this.audioRecording.seconds
          } ${this.$t("BASES.DROPDOWN_ITEM.SHARE_AUDIO_RECORDING_SECONDS")})`),
        e
      );
    },
  },
  beforeMount() {
    this.record && this.detectSupportMediaAudio();
  },
  methods: {
    detectSupportMediaAudio() {
      window.MediaRecorder &&
        navigator.mediaDevices &&
        navigator.mediaDevices.enumerateDevices &&
        navigator.mediaDevices.enumerateDevices().then((e) => {
          e.forEach((e) => {
            "audioinput" === e.kind && (this.audioRecording.available = true);
          });
        });
    },

    acquireAndRecordAudio() {
      this.$log.log("@on:acquireAndRecordAudio");

      this.audioRecording.active = true;
      this.audioRecording.recorder = new MicRecorder({
        bitRate: 128,
      });

      this.audioRecording.recorder
        .start()
        .then(() => {
          if (null === this.audioRecording.scheduler) {
            this.audioRecording.seconds = 0;

            this.audioRecording.scheduler = setInterval(() => {
              this.audioRecording.seconds++;

              this.audioRecording.progress = Math.ceil(
                (this.audioRecording.seconds / r) * 100,
              );

              if (this.audioRecording.progress >= 100) {
                this.stopAudioRecording(true);
              }
            }, 1000);
          }
        })
        .catch((e) => {
          console.error(e);
          this.stopAudioRecording();
        });
    },

    stopAudioRecording(shouldSend = false) {
      this.$log.log("@on:stopAudioRecording");

      if (this.audioRecording.active === true) {
        this.audioRecording.active = false;

        this.audioRecording.recorder
          .stop()
          .getMp3()
          .then(([buffer, blob]) => {
            this.audioRecording.active = false;
            this.audioRecording.progress = 0;

            if (null !== this.audioRecording.scheduler) {
              clearInterval(this.audioRecording.scheduler);
              this.audioRecording.scheduler = null;
            }

            const file = new File(buffer, `audio_${Date.now()}.mp3`, {
              type: blob.type,
              lastModified: Date.now(),
            });

            if (shouldSend && this.audioRecording.seconds > 0) {
              this.$emit("audioFile", [file], {
                duration: this.audioRecording.seconds,
              });
            }

            this.audioRecording.seconds = 0;
          });
      }
    },

    onFileChange(e) {
      this.$emit("fileChange", e.target.files);
    },

    onItemClick() {
      this.$emit("click", this.id);
    },

    onItemMousedown() {
      this.record && this.acquireAndRecordAudio();
    },

    onItemMouseLeave() {
      setTimeout(() => {
        if (this.record && this.audioRecording.active) {
          this.stopAudioRecording(false);
        }
      }, 500);
    },

    onItemMouseup() {
      this.record && this.stopAudioRecording(true);
    },
  },
};
</script>
<style lang="scss">
.c-base-dropdown-item {
  position: relative;
  background-color: #fff;
  color: #1c293b;
  font-weight: 700;
  font-size: 12px;
  cursor: pointer;
  .c-base-dropdown-item__progress {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #e0102b !important;
    -webkit-transition: width 1.1s linear;
    transition: width 1.1s linear;
  }
  .c-base-dropdown-item__wrapper {
    z-index: 2;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    padding: 6px 20px 6px 16px;
    white-space: nowrap;
    .c-base-dropdown-item__icon {
      margin-right: 10px;
      fill: #1c293b;
    }
    .c-base-dropdown-item__file {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      margin: 0;
      padding: 0;
      width: 100%;
      border: 0;
      border-radius: 0;
      opacity: 0;
      cursor: pointer;
    }
  }
  .c-base-dropdown-item__upgrade {
    .c-base-tooltip[data-align="right"] {
      margin-right: 10px;
      &:after {
        margin-right: 10px;
      }
    }
  }
  &:active {
    &:not(.c-base-dropdown-item--disabled) {
      color: #fff;
      background-color: #008adf;
      -webkit-transition: background-color 0.1s linear;
      transition: background-color 0.1s linear;
      .c-base-dropdown-item__icon {
        fill: #fff;
      }
    }
  }
  &:hover {
    &:not(.c-base-dropdown-item--disabled) {
      color: #fff;
      background-color: #0093ee;
      .c-base-dropdown-item__icon {
        fill: #fff;
      }
    }
  }
}
.c-base-dropdown-item--recording {
  background-color: #ee334b !important;
  color: #fff;
  .c-base-dropdown-item__icon {
    fill: #fff;
  }
}
.c-base-dropdown-item--disabled {
  cursor: not-allowed;
  .c-base-dropdown-item__wrapper {
    opacity: 0.7;
  }
}
</style>
