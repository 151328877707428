<template>
  <div
    :class="[
      'c-conversation-box-field',
      'c-conversation-box-field--' + status,
      {
        'c-conversation-box-field--blocked': blocked,
        'c-conversation-box-field--translating': translating,
      },
    ]"
  >
    <div class="c-conversation-box-field__files">
      <ConversationBoxFieldFile
        v-for="(file, index) in fieldFiles"
        :key="index"
        :file="file"
        v-on:delete="onDeleteFile"
      />
    </div>

    <div class="c-conversation-box-field__wrapper">
      <div class="c-conversation-box-field__send">
        <div>
          <BaseIcon
            name="PaperPlaneRight"
            size="18px"
            color="#fff"
            cursor="pointer"
            @click="sendTextareaMessage"
          />
        </div>
      </div>
      <div class="c-conversation-box-field__message">
        <div class="c-conversation-box-field__message-context">
          <BaseTag
            v-show="tag.enabled"
            class="c-conversation-box-field__tag"
            :color="tag.color"
            :label="tag.label"
            palette="segment"
          ></BaseTag>

          <BaseSpinner
            v-if="translating"
            border-width="2px"
            color="#7f8fa4"
            size="14px"
            speed="600ms"
            style="margin-right: 8px"
          />
        </div>

        <span
          v-if="translating"
          class="c-conversation-box-field__field-placeholder"
        >
          <span class="c-conversation-box-field__field-placeholder-text">
            {{ this.$t("INBOX.CONVERSATION.TRANSLATE_TRANSLATING") }}
          </span>
        </span>

        <span
          v-else-if="blocked"
          class="c-conversation-box-field__field-placeholder"
        >
          <span class="c-conversation-box-field__field-placeholder-text">
            {{
              this.$filters.format.parseFirstName(this.nickname) +
              " " +
              this.$t("INBOX.CONVERSATION.COMPOSE_BLOCKED")
            }}
          </span>
        </span>

        <span
          v-else
          v-crisp-placeholder
          class="c-conversation-box-field__field-placeholder"
          :placeholder="placeholder"
          ref="placeholder"
          empty=""
        >
          <span
            v-show="!blocked && !translating"
            class="c-conversation-box-field__field js-textarea"
            v-on:keydown="onFieldKeydown"
            v-on:keyup="onFieldKeyup"
            v-on:paste="(...e) => onFieldPaste && onFieldPaste(...e)"
            v-on:compositionStart="
              (...e) => onCompositionStart && onCompositionStart(...e)
            "
            v-on:compositionEnd="
              (...e) => onCompositionEnd && onCompositionEnd(...e)
            "
            :contenteditable="!blocked"
            autocapitalize="off"
            autocomplete="off"
            autocorrect="off"
            cols="1"
            dir="auto"
            rows="1"
            ref="textarea"
          ></span>
        </span>
      </div>

      <div v-if="!blocked" class="c-conversation-box-field__actions">
        <EmojiPicker
          class="c-conversation-box-field__picker"
          v-show="showEmojiPicker"
          :native="true"
          :hide-search="true"
          :hide-group-names="true"
          :disable-skin-tones="true"
          :group-names="emojiGoupNames"
          @select="onSelectEmoji"
        />

        <BaseIcon
          name="Smiley"
          size="25px"
          color="#fff"
          cursor="pointer"
          @click="onAddSmiley"
        />

        <BaseDropdown
          v-if="!uploading"
          menu-index="200"
          :align="'left'"
          :items="dropdownItems"
          @itemClick="onItemClick"
          @audioFile="onAudioFile"
          @fileChange="onFileChange"
        />

        <BaseSpinner
          v-if="uploading"
          border-width="2px"
          color="#7f8fa4"
          size="14px"
          speed="600ms"
        ></BaseSpinner>
      </div>
    </div>

    <div v-show="editorMode" class="c-conversation-box-field__editor-bar">
      <div class="c-conversation-box-field__groups"></div>

      <div class="c-conversation-box-field__buttons">
        <BaseButton
          v-if="reducedSize"
          v-on:click="onSubmitEditorMessage"
          left-icon="send"
          size="mini"
        ></BaseButton>

        <BaseButton
          v-else
          v-on:click="onSubmitEditorMessage"
          left-icon="send"
          size="mini"
        >
          {{ this.$t("INBOX.CONVERSATION.SEND") }}
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<script>
import * as autosize from "autosize";

import mitt from "mitt";
const u = mitt();

import EmojiPicker from "vue3-emoji-picker";
import mixinsSession from "@/mixins/session";
import BaseDropdown from "@/components/Reinvent/Base/BaseDropdown.vue";
import ConversationBoxFieldFile from "@/components/ConversationBoxFieldFile.vue";

const d = 250;

export default {
  components: {
    EmojiPicker,
    BaseDropdown,
    ConversationBoxFieldFile,
  },
  mixins: [mixinsSession],
  props: {
    sessionID: {
      type: String,
      required: !0,
    },
    tenantID: {
      type: String,
      required: !0,
    },
    availability: {
      type: String,
      default: null,
    },
    blocked: {
      type: Boolean,
      default: !1,
    },
    email: {
      type: String,
      default: null,
    },
    nickname: {
      type: String,
      default: null,
    },
    status: {
      type: String,
      default: "reply",
    },
    size: {
      type: String,
      default: "medium",
    },
    uploading: {
      type: Boolean,
      default: !1,
    },
    modelValue: {
      type: String,
      required: !0,
    },
  },
  emits: [
    "update:modelValue",
    "audioFile",
    "fieldInput",
    "fieldKeydown",
    "fieldPaste",
    "fileChange",
    "fileDelete",
    "loadHelpdesk",
    "loadShortcuts",
    "sendMessage",
  ],
  data() {
    return {
      fieldFiles: [],
      pasteFiles: [],
      isImeOpened: !1,
      popups: {
        newShortcut: {
          visible: !1,
          text: "",
        },
      },
      savedSelection: {
        startContainer: null,
        startOffset: 0,
        lastValidStartContainer: null,
        endContainer: null,
        endOffset: 0,
        lastValidEndContainer: null,
      },
      preventRangeSave: !1,
      editor: {
        controls: [
          {
            id: "format",
            items: ["bold", "italic", "underline", "insertColor", "insertCode"],
          },
          {
            id: "insert",
            items: ["insertLink", "insertImage", "insertVideo", "insertFile"],
          },
        ],
      },
      saveSelectionScheduler: null,
      showEmojiPicker: false,
      emojiGoupNames: {
        smileys_people: "Emojis e Pessoas",
        animals_nature: "Animais e Natureza",
        food_drink: "Comida e Bebida",
        activities: "Atividades",
        travel_places: "Viagens e Lugares",
        objects: "Objetos",
        symbols: "Símbolos",
        flags: "Bandeiras",
      },
    };
  },
  watch: {
    sessionID() {
      /**
       * When session changes, close emojiPicket
       */
      this.showEmojiPicker = false;
    },
  },
  computed: {
    dropdownItems() {
      return [
        {
          id: "share_file",
          icon: "attach_file",
          label: this.$t("TALQUI.INBOX.CONVERSATION.SHARE_FILE"),
          file: true,
        },
        {
          id: "record_audio",
          icon: "audio",
          label: this.$t("TALQUI.INBOX.CONVERSATION.SHARE_AUDIO_RECORD"),
          record: true,
        },
        // {
        //   id: "pick_message_shortcut",
        //   icon: "chat",
        //   label: this.$t("INBOX.CONVERSATION.SHARE_SHORTCUT_INSERT"),
        //   upgrade: {
        //     title: "RESTRICTS.SHORTCUTS.TITLE",
        //     label: "RESTRICTS.SHORTCUTS.LABEL",
        //     planName: "pro",
        //     pluginName: "Message Shortcuts",
        //     pluginUrn: "urn:crisp.im:message-shortcuts:0",
        //   },
        // },
        // {
        //   id: "save_message_shortcut",
        //   icon: "add",
        //   label: this.$t("INBOX.CONVERSATION.SHARE_SHORTCUT_ADD"),
        //   upgrade: {
        //     title: "RESTRICTS.SHORTCUTS.TITLE",
        //     label: "RESTRICTS.SHORTCUTS.LABEL",
        //     planName: "pro",
        //     pluginName: "Message Shortcuts",
        //     pluginUrn: "urn:crisp.im:message-shortcuts:0",
        //   },
        //   condition: () => this.inboxSession.message && !0,
        // },
        // {
        //   id: "pick_helpdesk_article",
        //   icon: "book",
        //   label: this.$t("INBOX.CONVERSATION.SHARE_HELPDESK_INSERT"),
        //   upgrade: {
        //     title: "RESTRICTS.HELPDESK.TITLE",
        //     label: "RESTRICTS.HELPDESK.LABEL",
        //     planName: "unlimited",
        //     pluginName: "Helpdesk",
        //     pluginUrn: "urn:crisp.im:helpdesk:0",
        //   },
        // },
      ];
    },
    placeholder() {
      let e;
      switch (this.status) {
        case "note":
          e = this.$t("INBOX.CONVERSATION.COMPOSE_NOTE");
          break;
        default:
          (e = this.$t("INBOX.CONVERSATION.COMPOSE_SEND")),
            this.nickname &&
              !this.reducedSize &&
              (e += ` ${this.$t("INBOX.CONVERSATION.COMPOSE_SEND_TO")} ${
                this.nickname
              }`),
            "online" === this.availability
              ? (e += " " + this.$t("INBOX.CONVERSATION.COMPOSE_SEND_CHAT"))
              : this.email &&
                (e += " " + this.$t("INBOX.CONVERSATION.COMPOSE_SEND_EMAIL"));
      }
      return (e += "..."), e;
    },
    tag() {
      if (!this.blocked) {
        if (
          this.inboxSession.message.length >
          this.$CONFIG.CRISP_LIMITS_MESSAGES_CHARACTERS
        )
          return {
            color: "red",
            label: `${this.inboxSession.message.length} / ${this.$CONFIG.CRISP_LIMITS_MESSAGES_CHARACTERS}`,
            enabled: !0,
          };
        switch (this.status) {
          case "edit":
            return {
              color: "red",
              label: "Editing",
              enabled: !0,
            };
          case "helpdesk":
            return {
              color: "turquoise",
              label: "Helpdesk",
              enabled: !0,
            };
          case "note":
            return {
              color: "yellow",
              label: "Lembrete",
              enabled: !0,
            };
          case "reminder":
            return {
              color: "black",
              label: "Reminder",
              enabled: !0,
            };
          case "shortcuts":
            return {
              color: "purple",
              label: "Atalho",
              enabled: !0,
            };
        }
      }
      return {
        color: "black",
        label: "default",
        enabled: !1,
      };
    },
    reducedSize() {
      return "small" === this.size && !0;
    },
    inboxSession: {
      get() {
        return (
          this.$store.commit("inbox/assertSessionInbox", {
            sessionId: this.sessionID,
          }),
          this.$store.getters["inbox/getSessionInbox"](this.sessionID)
        );
      },
    },
    translating: {
      get() {
        return this.inboxSession.translate.newMessageIsTranslating;
      },
    },
    editorMode() {
      return this.$store.getters["inbox/isInEditorMode"];
    },
  },
  focus(e) {
    u.emit("focus", e);
  },
  clear() {
    u.emit("clear");
  },
  clearCurrentLine() {
    u.emit("clearCurrentLine");
  },
  appendFile(file) {
    u.emit("appendFile", file);
  },
  getContent() {
    let e = document.querySelector(".js-textarea"),
      t = "",
      n = "";
    for (let i = 0; i < e.childNodes.length; i++) {
      let n = e.childNodes[i],
        o = "";
      (o = n.nodeType === Node.TEXT_NODE ? n.nodeValue : n.innerText),
        "\n" !== o && (t += o),
        (t += "\n");
    }
    return e && e.innerText
      ? ((n = e.innerText), n.length <= t.length ? n : t)
      : "";
  },
  preventRangeSave(e) {
    u.emit("preventRangeSave", e);
  },
  replaceCurrentLineContent(e, t) {
    u.emit("replaceCurrentLineContent", {
      text: e,
      unescapedText: t,
    });
  },
  setText(text, restoreRange) {
    u.emit("setText", {
      text: text,
      restoreRange: restoreRange,
    });
  },
  insertAtCursor(e) {
    u.emit("onInsertAtCursor", e);
  },
  insertImage(e, t) {
    u.emit("onInsertImage", {
      name: e,
      resourceUrl: t,
    });
  },
  insertFile(e, t) {
    u.emit("onInsertFile", {
      name: e,
      resourceUrl: t,
    });
  },
  insertGIF(e) {
    u.emit("onInsertGIF", e);
  },
  updatePlaceholder() {
    u.emit("onUpdatePlaceholder");
  },
  mounted() {
    autosize(this.$refs.textarea);
    this.onFocus();
    this.saveSelectionScheduler = setInterval(() => {
      this.onSaveSelection();
    }, d);
    u.on("appendFile", this.onAppendFile);
    u.on("focus", this.onFocus);
    u.on("clearCurrentLine", this.onClearCurrentLine);
    u.on("clear", this.onClearInput);
    u.on("preventRangeSave", this.onPreventRangeSave);
    u.on("replaceCurrentLineContent", this.onReplaceCurrentLineContent);
    u.on("setText", this.onSetText);
    u.on("onInsertAtCursor", this.onInsertAtCursor);
    u.on("onInsertImage", this.onInsertImage);
    u.on("onInsertFile", this.onInsertFile);
    u.on("onInsertGIF", this.onInsertGIF);
    u.on("onUpdatePlaceholder", this.onUpdatePlaceholder);
  },
  beforeUnmount() {
    if (null !== this.saveSelectionScheduler) {
      clearInterval(this.saveSelectionScheduler);
      this.saveSelectionScheduler = null;
    }
    u.off("appendFile", this.onAppendFile);
    u.off("focus", this.onFocus);
    u.off("clearCurrentLine", this.onClearCurrentLine);
    u.off("clear", this.onClearInput);
    u.off("preventRangeSave", this.onPreventRangeSave);
    u.off("replaceCurrentLineContent", this.onReplaceCurrentLineContent);
    u.off("setText", this.onSetText);
    u.off("onInsertAtCursor", this.onInsertAtCursor);
    u.off("onInsertImage", this.onInsertImage);
    u.off("onInsertFile", this.onInsertFile);
    u.off("onInsertGIF", this.onInsertGIF);
    u.off("onUpdatePlaceholder", this.onUpdatePlaceholder);
  },
  methods: {
    sendTextareaMessage() {
      const message = this.$refs.textarea.innerText;

      if (!this.editorMode && message) {
        this.$emit(
          "sendMessage",
          this.$refs.textarea.innerText,
          (this.$refs.textarea.ctrlKey || this.$refs.textarea.metaKey) &&
            !1 === this.editorMode,
        );
      }
    },
    onSelectEmoji(emoji) {
      this.onFocus(!0);
      this.manipulateTextarea(emoji.i);
    },
    manipulateTextarea(character) {
      const textarea = this.$refs.textarea;
      if (window.getSelection) {
        const selection = window.getSelection();

        if (selection.rangeCount > 0) {
          const range = selection.getRangeAt(0);
          range.deleteContents();

          if (range.startContainer.nodeType === Node.TEXT_NODE) {
            const offset = Math.min(
              range.startOffset,
              range.startContainer.length,
            );
            range.startContainer.splitText(offset);
            range.startContainer.insertData(offset, character);
            range.setStartAfter(range.startContainer);
          } else {
            range.insertNode(document.createTextNode(character));
          }

          range.collapse(false);
          selection.removeAllRanges();
          selection.addRange(range);
        } else {
          const cursorPos = textarea.textContent.length;
          textarea.textContent =
            textarea.textContent.substring(0, cursorPos) +
            character +
            textarea.textContent.substring(cursorPos);

          const range = document.createRange();
          range.setStart(textarea, cursorPos + 1);
          range.collapse(false);

          selection.removeAllRanges();
          selection.addRange(range);
        }
      } else {
        textarea.value += character;
      }

      textarea.focus();
      this.onTextUpdated();
      this.onUpdatePlaceholder();
    },
    getCurrentLineContent() {
      let e = this.getCurrentNode();
      if (!e || (e && e.classList && e.classList.contains("js-textarea")))
        return "";
      if (e.nodeType === Node.TEXT_NODE) {
        let t = this.textWithHTML(e.wholeText).split(/(<br \/>)/);
        return t[t.length - 1];
      }
      return e.innerText;
    },
    textWithHTML(e) {
      let t = e;
      return (
        (t = e.replace(/\n\n/g, () => "<br /><br />")),
        (t = t.replace(/\n/g, () => "<br />")),
        t
      );
    },
    insertTextViaEditor(e) {
      this.$refs.editor.replaceInContentEditable(e, !0),
        this.onTextUpdated(),
        this.onUpdatePlaceholder();
    },
    insertImageViaEditor(e, t) {
      this.$refs.editor.onInsertImageProceed(e, t), this.onTextUpdated();
    },
    insertFileViaEditor(e, t) {
      this.$refs.editor.onInsertFileProceed(e, t), this.onTextUpdated();
    },
    isUrl(e) {
      let t;
      try {
        t = new URL(e);
      } catch (n) {
        return !1;
      }
      return "http:" === t.protocol || "https:" === t.protocol;
    },
    placeCursorAt(e, t = !1, n = !1) {
      if ((e.focus(), window.getSelection && document.createRange)) {
        let i = window.getSelection(),
          o = document.createRange();
        if (t)
          if (n) {
            let e = 1;
            this.savedSelection.endContainer.nodeType === Node.TEXT_NODE &&
              (e = this.savedSelection.endContainer.nodeValue.length),
              o.setEnd(this.savedSelection.endContainer, e),
              o.collapse(!1);
          } else
            o.setStart(
              this.savedSelection.startContainer,
              this.savedSelection.startOffset,
            ),
              o.setEnd(
                this.savedSelection.endContainer,
                this.savedSelection.endOffset,
              );
        else o.selectNodeContents(e), o.collapse(!1);
        i.removeAllRanges(), i.addRange(o);
      } else if (document.body.createTextRange) {
        let t = document.body.createTextRange();
        t.moveToElementText(e), t.collapse(!1), t.select();
      }
    },
    saveSelection(e, t, n, i) {
      this.savedSelection.startContainer = e;
      this.savedSelection.startOffset = t;
      this.savedSelection.endContainer = n;
      this.savedSelection.endOffset = i;
      e &&
        (!e.classList ||
          (e.classList && !e.classList.contains("js-textarea"))) &&
        (this.lastValidStartContainer = e),
        n &&
          (!n.classList ||
            (n.classList && !n.classList.contains("js-textarea"))) &&
          (this.lastValidEndContainer = n);
    },
    getCurrentNode() {
      let e,
        t,
        n = window.getSelection();
      return (
        n &&
          n.rangeCount > 0 &&
          ((t = n.getRangeAt(0)), (e = t.endContainer || t.startContainer)),
        e &&
        (!e.classList || (e.classList && !e.classList.contains("js-textarea")))
          ? e
          : e && e.childNodes && t.endOffset && e.childNodes[t.endOffset]
            ? e.childNodes[t.endOffset]
            : (this.onSaveSelection(),
              this.savedSelection.lastValidEndContainer)
      );
    },
    onAddSmiley() {
      this.onFocus(!0);
      this.showEmojiPicker = !this.showEmojiPicker;
    },
    onInsertAtCursor(e) {
      this.onFocus(!0);
      this.$nextTick(() => {
        this.insertTextViaEditor(e);
      });
    },
    onInsertImage({ name: e, resourceUrl: t }) {
      this.insertImageViaEditor(e, t);
    },
    onInsertFile({ name: e, resourceUrl: t }) {
      this.insertFileViaEditor(e, t);
    },
    onInsertGIF(e) {
      this.$refs.editor.onInsertGIFProceed(e), this.onTextUpdated();
    },
    onAudioFile(e, t) {
      this.$emit("audioFile", e, t);
    },
    onClearCurrentLine() {
      let e = document.querySelector(".js-textarea");
      if (e.childNodes.length > 1) {
        let e,
          t = this.getCurrentNode();
        if (t) {
          if (t.nodeType === Node.TEXT_NODE)
            if ("DIV" !== t.parentNode.nodeName) {
              let n = document.createTextNode(" ");
              t.replaceWith(n), (e = n);
            } else {
              let n = document.createElement("div"),
                i = document.createElement("br");
              n.appendChild(i), t.parentNode.replaceWith(n), (e = n);
            }
          else (t.innerHTML = "<br>"), (e = t);
          this.onPreventRangeSave(!0),
            this.saveSelection(e, 0, e, 0),
            this.onTextUpdated(),
            this.onUpdatePlaceholder();
        }
        this.onFocus(!0), this.onPreventRangeSave(!1);
      } else this.onClearInput();
    },
    onClearInput() {
      if (this.$refs.textarea) {
        this.$refs.textarea.innerText = "";
      }
      this.onTextUpdated();
      this.onUpdatePlaceholder();
      autosize.update(this.$refs.textarea);
      this.fieldFiles = [];
    },
    onFieldKeydown(e) {
      if (
        ("Enter" === e.key &&
          !1 === e.shiftKey &&
          !1 === this.editorMode &&
          !this.isImeOpened) ||
        ("Enter" === e.key &&
          (e.ctrlKey || e.metaKey) &&
          !0 === this.editorMode)
      ) {
        e.preventDefault(),
          this.$emit(
            "sendMessage",
            e.target.innerText,
            (e.ctrlKey || e.metaKey) && !1 === this.editorMode,
          );
      } else if (e.ctrlKey || e.metaKey) {
        switch (e.key) {
          case "b":
            e.preventDefault();
            this.$refs.editor.onControlClick("bold");
            break;
          case "i":
            e.preventDefault();
            this.$refs.editor.onControlClick("italic");
            break;
          case "u":
            e.preventDefault();
            this.$refs.editor.onControlClick("underline");
            break;
        }
      }
      this.$emit("fieldKeydown", e.target.innerText, e);
    },
    onFileChange(e) {
      this.$emit("fileChange", e);
    },
    onFieldKeyup(e) {
      this.onTextUpdated(e);
    },
    onFieldPaste(e) {
      if (e.clipboardData.getData("text")) {
        let t = e.clipboardData.getData("text/plain");
        e.preventDefault();
        e.stopPropagation();
        setTimeout(() => {
          document.execCommand("inserttext", !1, t),
            this.onUpdatePlaceholder(),
            this.onScroll();
        }, 0);
      }

      let t = (e.clipboardData || e.originalEvent.clipboardData || {}).items;
      if (t && t.length > 0) {
        let n = [];
        for (let e = 0; e < t.length; e++) {
          if ("file" === t[e].kind) {
            let i = t[e].getAsFile();
            i && n.push(i);
          }
        }
        if (n.length > 0) {
          e.preventDefault();
          e.stopPropagation();
          this.$emit("fieldPaste", n);
        }
      }
    },
    onAppendFile(file) {
      this.fieldFiles.push(file);
    },
    onDeleteFile(file) {
      this.fieldFiles = this.fieldFiles.filter((fileItem) => {
        return fileItem.name !== file.name;
      });
      this.$emit("fileDelete", file);
    },
    onFocus(e, t) {
      const n = this.$refs.textarea;
      n && (n.innerText ? this.placeCursorAt(n, e, t) : n.focus());
    },
    onScroll() {
      let e,
        t = document.querySelector(".js-textarea"),
        n = this.getCurrentNode();
      if (n) {
        if (n && n.nodeType === Node.TEXT_NODE) {
          let i = n.parentNode;
          if ("DIV" === i.nodeName && i !== t) e = i.offsetTop;
          else {
            let t = document.createRange();
            t.selectNodeContents(n), (e = t.getBoundingClientRect().bottom);
          }
        } else e = n.offsetTop;
        t.scrollTo({
          top: e,
          left: 0,
          behavior: "smooth",
        });
      }
    },
    onItemClick(e) {
      let t = !1;
      switch (e) {
        case "pick_helpdesk_article":
          this.$store.commit("inbox/updateSessionFieldStatus", {
            sessionId: this.sessionID,
            value: "helpdesk",
          }),
            this.$emit("loadHelpdesk"),
            (t = !0);
          break;
        case "pick_message_shortcut":
          this.$store.commit("inbox/updateSessionFieldStatus", {
            sessionId: this.sessionID,
            value: "shortcuts",
          }),
            this.$emit("loadShortcuts"),
            (t = !0);
          break;
        case "save_message_shortcut":
          (this.popups.newShortcut.visible = !0),
            (this.popups.newShortcut.text = this.inboxSession.message);
          break;
      }
      !0 === t && this.onFocus(!0);
    },
    onNewShortcutClose() {
      this.popups.newShortcut.visible = !1;
      this.popups.newShortcut.text = "";
      this.onFocus();
    },
    onPreventRangeSave(e = !0) {
      this.preventRangeSave = e;
    },
    onReplaceCurrentLineContent({ text: e, unescapedText: t = "" }) {
      let n = document.querySelector(".js-textarea");
      (t = t || e),
        n.childNodes.length > 1
          ? (this.onClearCurrentLine(),
            this.$nextTick(() => {
              let i = this.getCurrentNode();
              if (
                (i || (i = n.childNodes[n.childNodes.length - 1]),
                "DIV" !== i.nodeName)
              ) {
                let e,
                  n,
                  o,
                  a = this.textWithHTML(t).split(/(<br \/>)/);
                if (a.length > 1) {
                  (e = document.createElement("div")),
                    (o = 1),
                    (e.innerHTML = this.textWithHTML(t));
                  let i = e.childNodes[e.childNodes.length - 1];
                  i &&
                    i.nodeType === Node.TEXT_NODE &&
                    ((n = i), (o = i.nodeValue.length));
                } else
                  (e = document.createTextNode(this.textWithHTML(t))),
                    (n = e),
                    (o = e.nodeValue.length);
                i.replaceWith(e),
                  this.onPreventRangeSave(!0),
                  this.saveSelection(n, o, n, o);
              } else
                (i.innerHTML = this.textWithHTML(e)),
                  this.onPreventRangeSave(!0),
                  this.saveSelection(i, 1, i, 1);
              this.onTextUpdated(),
                this.onUpdatePlaceholder(),
                this.onFocus(!0, !0),
                this.onPreventRangeSave(!1);
            }))
          : this.onSetText({
              text: e,
            });
    },
    onSaveSelection() {
      if (!this.preventRangeSave) {
        let e = null;
        if (
          window.getSelection &&
          document.activeElement.classList.contains("js-textarea")
        ) {
          let t = window.getSelection();
          t.getRangeAt && t.rangeCount && (e = t.getRangeAt(0));
        }
        e
          ? this.saveSelection(
              e.startContainer,
              e.startOffset,
              e.endContainer,
              e.endOffset,
            )
          : document.selection &&
            document.selection.createRange &&
            ((e = document.selection.createRange()),
            this.saveSelection(
              e.startContainer,
              e.startOffset,
              e.endContainer,
              e.endOffset,
            ));
      }
    },
    onSetText({ text: e, restoreRange: t }) {
      this.$refs.textarea &&
        (this.$refs.textarea.innerHTML = this.textWithHTML(e)),
        this.onTextUpdated(),
        this.onUpdatePlaceholder(),
        this.onFocus(t);
    },
    onSubmitEditorMessage() {
      const e = document.querySelector(".js-textarea");
      this.$emit("sendMessage", e.innerText);
    },
    onUpdatePlaceholder() {
      this.$refs.placeholder &&
        (this.$refs.textarea.textContent
          ? this.$refs.placeholder.removeAttribute("empty")
          : this.$refs.placeholder.setAttribute("empty", !0));
    },
    onTextUpdated(e) {
      this.$refs.textarea &&
        (this.$emit("update:modelValue", this.$refs.textarea.innerHTML),
        this.$emit("fieldInput", this.$refs.textarea.textContent, e));
    },
    onToggleFieldEditor() {
      this.$store.commit("inbox/updateSessionActiveMenu", {
        sessionId: this.sessionID,
        value: "tabs",
      });
      this.$store.commit("inbox/setEditorMode", {
        value: !this.editorMode,
      });
      this.onFocus();
    },
    onCompositionStart() {
      this.isImeOpened = !0;
    },
    onCompositionEnd() {
      this.isImeOpened = !1;
    },
  },
};
</script>

<style lang="scss" scoped>
.c-conversation-box-field {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: rgba(243, 244, 244, 0.2);
  margin: 20px;
  border-radius: 24px;
  padding: 0 4px 0 20px;
  ::selection {
    background: rgba(0, 0, 0, 0.4);
    color: #fff;
  }
  ::-moz-selection {
    background: rgba(0, 0, 0, 0.4);
    color: #fff;
  }
  .c-conversation-box-field__files {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .c-conversation-box-field__wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    width: 100%;
    flex-direction: row-reverse;
    gap: 12px;
    .c-conversation-box-field__send {
      align-self: flex-end;
      margin-bottom: 5px;
      > div {
        background-color: rgba(204, 204, 204, 0.2);
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
      }
    }
    .c-conversation-box-field__message {
      position: relative;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
      .c-conversation-box-field__message-context {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        // margin-top: 12px;
        // height: 24px;
      }
      .c-conversation-box-field__live-translate {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        margin-left: 12px;
        -webkit-animation-name: tag;
        animation-name: tag;
        -webkit-animation-duration: 0.1s;
        animation-duration: 0.1s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
      }
      .c-conversation-box-field__tag {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        margin-left: 12px;
        -webkit-animation-name: tag;
        animation-name: tag;
        -webkit-animation-duration: 0.1s;
        animation-duration: 0.1s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        margin-right: -5px;
      }
      .c-conversation-box-field__field-placeholder {
        grid-row-start: 1;
        grid-column-start: 1;
        min-height: 49px;
        font-size: 0.9rem;
        line-height: 18px;
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        min-width: 150px;
        white-space: pre-wrap;
        .c-conversation-box-field__field-placeholder-text {
          width: 100%;
          outline: 0;
          color: rgba(255, 255, 255, 0.4);
          display: flex;
          align-items: center;
        }
        .c-conversation-box-field__field {
          z-index: 1;
          display: block;
          overflow-x: hidden;
          overflow-y: auto;
          -webkit-box-flex: 1;
          -ms-flex: 1;
          flex: 1;
          padding: 16px 0;
          min-height: 24px;
          max-height: 300px;
          width: 0;
          outline: 0;
          border: none;
          color: #fff;
          text-align: initial;
          word-wrap: break-word;
          font-size: 16px;
          line-height: 18px;
          resize: none;
          -moz-user-select: text;
          -ms-user-select: text;
          user-select: text;
          cursor: text;
          -webkit-user-select: text;
          &:before {
            background: transparent;
            content: "";
          }
        }
      }
      .c-conversation-box-field__predict {
        grid-row-start: 1;
        grid-column-start: 1;
        min-height: 49px;
        font-size: 0.9rem;
        line-height: 18px;
      }
      .c-conversation-box-field__field-placeholder[empty] {
        &:before {
          position: absolute;
          top: 15px;
          left: 0;
          z-index: 1;
          color: rgba(255, 255, 255, 0.6);
          content: attr(placeholder);
        }
      }
      .c-conversation-box-field__inner {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr;
        .c-conversation-box-field__predict {
          padding: 15px 0 10px 18px;
          .c-conversation-box-field__predict-partial {
            white-space: break-spaces;
            -webkit-animation: c-conversation-box-field-predict-partial 1s
              forwards;
            animation: c-conversation-box-field-predict-partial 1s forwards;
          }
          .c-conversation-box-field__predict-prompt {
            white-space: break-spaces;
          }
        }
      }
    }
    .c-conversation-box-field__actions {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      margin-bottom: 12px;
      align-self: flex-end;
      gap: 8px;
      position: relative;
      .c-conversation-box-field__editor {
        z-index: 5;
        display: inline-block;
        width: 25px;
        height: 25px;
        cursor: pointer;
        margin-right: 5px;
        background-image: url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjI1IiB3aWR0aD0iMjUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxkZWZzPjxwYXRoIGlkPSJhIiBkPSJNMS4zNzcgMTZsMS4xMjItMi43ODhIOC41TDkuNjIyIDE2aDEuMTIyTDYuMTAzIDQuNjYxSDQuODk2TC4yNTUgMTZ6bTYuOC0zLjY3MkgyLjgyMmwyLjY2OS02LjYzeiIvPjxwYXRoIGlkPSJiIiBkPSJNMy44MDggMTYuMjA0YzEuMTM5IDAgMi4wNTctLjM3NCAyLjc4OC0xLjE3M1YxNmguODg0di01Ljc4YzAtMS44MzYtMS4zMjYtMi42MzUtMi45OTItMi42MzUtMS4zNiAwLTIuMzEyLjQ1OS0zLjE2MiAxLjM3N2wuNDkzLjYxMmMuNzQ4LS44NSAxLjU0Ny0xLjIwNyAyLjU2Ny0xLjIwNyAxLjIyNCAwIDIuMjEuNjYzIDIuMjEgMS44ODd2MS42NjZjLS43MTQtLjc5OS0xLjY0OS0xLjE3My0yLjc4OC0xLjE3My0xLjQyOCAwLTIuODczLjkzNS0yLjg3MyAyLjcyIDAgMS43NjggMS40NjIgMi43MzcgMi44NzMgMi43Mzd6bS4zMDYtLjY5N2MtMS4zNiAwLTIuMjQ0LS44NjctMi4yNDQtMi4wMjMgMC0xLjE3My44ODQtMi4wNCAyLjI0NC0yLjA0Ljk4NiAwIDEuOTA0LjM5MSAyLjQ4MiAxLjEzOXYxLjgwMmMtLjU3OC43NDgtMS40OTYgMS4xMjItMi40ODIgMS4xMjJ6Ii8+PC9kZWZzPjxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+PGNpcmNsZSBjeD0iMTIuNSIgY3k9IjEyLjUiIHI9IjEyIiBzdHJva2U9IiMxNzkxZWQiLz48ZyBmaWxsLXJ1bGU9Im5vbnplcm8iPjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKDQgMSkiPjx1c2UgZmlsbD0iIzAwMCIgeGxpbms6aHJlZj0iI2EiLz48dXNlIGZpbGw9IiMxNzkxZWQiIHhsaW5rOmhyZWY9IiNhIi8+PC9nPjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKDE0IDEpIj48dXNlIGZpbGw9IiMwMDAiIHhsaW5rOmhyZWY9IiNiIi8+PHVzZSBmaWxsPSIjMTc5MWVkIiB4bGluazpocmVmPSIjYiIvPjwvZz48L2c+PC9nPjwvc3ZnPg==");
      }
      .c-conversation-box-field__picker {
        z-index: 9999;
        position: absolute;
        bottom: calc(100% + 4px);
      }
      .c-conversation-box-field__smileys {
        z-index: 5;
        display: inline-block;
        width: 25px;
        height: 25px;
        cursor: pointer;
        margin-right: 3px;
        background-image: url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjI1IiB3aWR0aD0iMjUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48Y2lyY2xlIGN4PSIxMi4wMzEiIGN5PSIxMi41NzgiIHI9IjExLjUxIiBzdHJva2U9IiMxNzkxZWQiIHN0cm9rZS13aWR0aD0iMS4wNDIiLz48ZyBmaWxsPSIjMTc5MWVkIj48Y2lyY2xlIGN4PSI2LjU2MyIgY3k9IjkuODQ0IiByPSIxLjA5NCIvPjxjaXJjbGUgY3g9IjE3LjUiIGN5PSI5Ljg0NCIgcj0iMS4wOTQiLz48L2c+PHBhdGggZD0iTTYuNzY4IDE0LjA2aDEwLjUyN3MtLjc5NSA0LjY5My01LjI2NCA0LjY5My01LjI2My00LjY5My01LjI2My00LjY5M3oiIHN0cm9rZT0iIzE3OTFlZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjEuMDQyIi8+PC9nPjwvc3ZnPg==");
      }
      .c-conversation-box-field__editor--active {
        background-image: url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjI1IiB3aWR0aD0iMjUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxkZWZzPjxwYXRoIGlkPSJhIiBkPSJNMS4zNzcgMTZsMS4xMjItMi43ODhIOC41TDkuNjIyIDE2aDEuMTIyTDYuMTAzIDQuNjYxSDQuODk2TC4yNTUgMTZ6bTYuOC0zLjY3MkgyLjgyMmwyLjY2OS02LjYzeiIvPjxwYXRoIGlkPSJiIiBkPSJNMy44MDggMTYuMjA0YzEuMTM5IDAgMi4wNTctLjM3NCAyLjc4OC0xLjE3M1YxNmguODg0di01Ljc4YzAtMS44MzYtMS4zMjYtMi42MzUtMi45OTItMi42MzUtMS4zNiAwLTIuMzEyLjQ1OS0zLjE2MiAxLjM3N2wuNDkzLjYxMmMuNzQ4LS44NSAxLjU0Ny0xLjIwNyAyLjU2Ny0xLjIwNyAxLjIyNCAwIDIuMjEuNjYzIDIuMjEgMS44ODd2MS42NjZjLS43MTQtLjc5OS0xLjY0OS0xLjE3My0yLjc4OC0xLjE3My0xLjQyOCAwLTIuODczLjkzNS0yLjg3MyAyLjcyIDAgMS43NjggMS40NjIgMi43MzcgMi44NzMgMi43Mzd6bS4zMDYtLjY5N2MtMS4zNiAwLTIuMjQ0LS44NjctMi4yNDQtMi4wMjMgMC0xLjE3My44ODQtMi4wNCAyLjI0NC0yLjA0Ljk4NiAwIDEuOTA0LjM5MSAyLjQ4MiAxLjEzOXYxLjgwMmMtLjU3OC43NDgtMS40OTYgMS4xMjItMi40ODIgMS4xMjJ6Ii8+PC9kZWZzPjxnIGZpbGw9Im5vbmUiPjxjaXJjbGUgY3g9IjEyLjUiIGN5PSIxMi41IiBmaWxsPSIjMTc5MWVkIiByPSIxMiIgc3Ryb2tlPSIjMTc5MWVkIi8+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNCAxKSI+PHVzZSBmaWxsPSIjMDAwIiB4bGluazpocmVmPSIjYSIvPjx1c2UgZmlsbD0iI2ZmZiIgeGxpbms6aHJlZj0iI2EiLz48L2c+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTQgMSkiPjx1c2UgZmlsbD0iIzAwMCIgeGxpbms6aHJlZj0iI2IiLz48dXNlIGZpbGw9IiNmZmYiIHhsaW5rOmhyZWY9IiNiIi8+PC9nPjwvZz48L3N2Zz4=");
      }
    }
  }
}
.c-conversation-box-field--note {
  background-color: #fff5cc;
}
.c-conversation-box-field--blocked {
  pointer-events: none;
  cursor: not-allowed;
  .c-conversation-box-field__field {
    opacity: 0.55;
  }
}
@media (max-width: 68.75em) {
  .c-conversation-box-field {
    .c-conversation-box-field__controls[data-id="insert"] {
      .c-conversation-box-field__control_item[data-item-id="insertVideo"] {
        display: none;
      }
    }
  }
}
@media (max-width: 66.875em) {
  .c-conversation-box-field {
    .c-conversation-box-field__controls[data-id="insert"] {
      .c-conversation-box-field__control_item[data-item-id="insertImage"] {
        display: none;
      }
    }
  }
}
@media (max-width: 65em) {
  .c-conversation-box-field {
    .c-conversation-box-field__controls[data-id="insert"] {
      .c-conversation-box-field__control_item[data-item-id="insertLink"] {
        display: none;
      }
    }
  }
}
</style>
