import Cookies from "js-cookie";
import o from "./http.js";
import n from "./auth.js";
import r from "./billing.js";
import s from "../store/index.js";
import l from "@/enviroment.js";
import c from "./platform.js";

const u = 365;
const M = 1e4;

class _userAuth {
  constructor() {
    this.heartbeatDisabled = !1;
    this.__heartbeatScheduler = null;
    this.__authenticatedNetwork = {};
  }
  session() {
    return o.one("user", "session");
  }
  login(e, t, i = null, a = null, o = !1) {
    let n = {
      email: e,
      password: t,
    };
    return (
      i && (n.extend = i),
      a && (n.token = a),
      !0 === o && (n.ephemeral = !0),
      this.clearLocalSession(),
      this.session()
        .one("login")
        .post(n)
        .then((e) =>
          e.identifier
            ? (this.setCookie(e.identifier, e.key),
              this.autoAuthenticateNetwork(!0),
              Promise.resolve(e.user_id))
            : Promise.reject(null),
        )
        .catch((e) => Promise.reject(e))
    );
  }
  recover(e) {
    return this.session().one("recover").post({
      email: e,
    });
  }
  logout() {
    return (
      s.commit("common/resetPendingRoute"),
      this.clearLocalSession(),
      Promise.resolve()
        .then(() => this.changeAvailability("offline"))
        .then(() => this.session().one("logout").post())
        .then(() => (this.clearLocalAuthentication(), Promise.resolve()))
        .catch((e) => Promise.reject(e))
    );
  }
  setCookie(e, t) {
    localStorage &&
      localStorage.setItem(
        "user_session",
        JSON.stringify({
          identifier: e,
          key: t,
        }),
      ),
      c.isPlatform() ||
        Cookies.set(
          "user_session",
          JSON.stringify({
            identifier: e,
            key: t,
          }),
          {
            expires: u,
            sameSite: "strict",
          },
        );
  }
  check() {
    return o.one("user/session").head();
  }
  authorized() {
    return o.one("user/session/authorized");
  }
  history() {
    return o.one("user/session/history");
  }
  getCookie() {
    let e;
    try {
      e = JSON.parse(Cookies.get("user_session") || null);
      //eslint-disable-next-line
    } catch (t) {}
    if ((!e || !e.identifier) && localStorage)
      try {
        e = JSON.parse(localStorage.getItem("user_session") || null);
        //eslint-disable-next-line
      } catch (t) {}
    return e || {};
  }
  getLoginRemember() {
    return "false" !== Cookies.get("login_remember");
  }
  setLoginRemember(e = !0) {
    Cookies.set("login_remember", "" + e, {
      expires: u,
      sameSite: "strict",
    });
  }
  clearLocalSession() {
    Cookies.remove("user_session");
    Cookies.remove("default_website_id");
    Cookies.remove("notifications_skipped");
    Cookies.remove("login_remember");
    s.commit("user/reset");
    s.commit("websites/reset");
    s.commit("segments/reset");
    s.commit("plugins/reset");
    s.commit("widgets/reset");
    s.commit("shortcuts/reset");
    localStorage && localStorage.clear();
  }
  clearLocalAuthentication() {
    o.deleteHeader("Authorization");
  }
  isLogged() {
    const e = this.getCookie();
    return Object.keys(e).length > 0;
  }
  authenticateApi(e, t) {
    o.setHeader("Authorization", "Basic " + btoa(`${e}:${t}`));
  }
  autoAuthenticateApi() {
    let e = this.getCookie();
    e.identifier && e.key && this.authenticateApi(e.identifier, e.key);
  }
  autoAuthenticateNetwork(e = !1, t = !0) {
    let i = this.getCookie();
    i.identifier &&
      i.key &&
      ((this.__authenticatedNetwork.identifier === i.identifier &&
        this.__authenticatedNetwork.key === i.key) ||
        ((this.__authenticatedNetwork.identifier = i.identifier),
        (this.__authenticatedNetwork.key = i.key),
        this.authenticateApi(i.identifier, i.key),
        n.authenticate(i.identifier, i.key),
        this.startAvailabilityHeartbeat(e, t)));
  }
  startAvailabilityHeartbeat(e = !1, t = !0) {
    localStorage && !0 === t && localStorage.removeItem("heartbeat_last"),
      this.doHeartbeat(e),
      this.__heartbeatScheduler &&
        (clearInterval(this.__heartbeatScheduler),
        (this.__heartbeatScheduler = null)),
      (this.__heartbeatScheduler = setInterval(() => {
        s.getters["common/isConnected"] &&
          !s.getters["common/isSafeModeEnabled"] &&
          this.doHeartbeat();
      }, l.CRISP_INTERVAL_HEARTBEAT_HARD - l.CRISP_INTERVAL_HEARTBEAT_SOFT));
  }
  doHeartbeat(e = !1, t = !0) {
    if (!0 === e || !0 !== this.heartbeatDisabled) {
      let e = Date.now();
      this.heartbeatLast = e;
      let i = !0;
      if (!0 === t) {
        let t = parseInt(
          (localStorage && localStorage.getItem("heartbeat_last")) || "0",
          10,
        );
        e > 0 && e - t < l.CRISP_INTERVAL_HEARTBEAT_SOFT - M && (i = !1);
      }
      if (!0 === i)
        return (
          localStorage && localStorage.setItem("heartbeat_last", "" + e),
          this.changeAvailability(
            "online",
            l.CRISP_INTERVAL_HEARTBEAT_HARD / 1e3,
          )
        );
    }
    return Promise.resolve();
  }
  changeAvailability(e, t = 0) {
    let i = {
      type: e,
    };
    return (
      t &&
        t > 0 &&
        (i.time = {
          for: t,
        }),
      r.availability().patch(i)
    );
  }
}

export default new _userAuth();
