import a from "@/filters/colors.js";
import l from "@/filters/plans.js";
import c from "@/filters/locale.js";
import o from "@/classes/date.js";
import n from "@/classes/filters.js";
import r from "@/classes/stringHelper.js";
import s from "@/classes/notations.js";

class _filters {
  init(e) {
    e.config.globalProperties.$filters = {
      colors: a,
      date: o,
      format: n,
      strings: r,
      numbers: s,
      plans: l,
      locale: c,
    };
  }
}

export default new _filters();
