var a = {
  "./en": [require("./locale-veevalidate-en.js"), "locale-veevalidate-en"],
  "./en.json": [require("./locale-veevalidate-en.js"), "locale-veevalidate-en"],
  "./pt_BR": [
    require("./locale-veevalidate-pt_BR.js"),
    "locale-veevalidate-pt_BR",
  ],
  "./pt_BR.json": [
    require("./locale-veevalidate-pt_BR.js"),
    "locale-veevalidate-pt_BR",
  ],
};

function o(e) {
  // eslint-disable-next-line no-prototype-builtins
  if (!Object(a).hasOwnProperty(e)) {
    return Promise.resolve().then(function () {
      var t = new Error("Cannot find module '" + e + "'");
      throw ((t.code = "MODULE_NOT_FOUND"), t);
    });
  }

  var t = a[e];
  return new Promise((resolve) => {
    resolve(t[0]);
  });
}

o.keys = function () {
  return Object.keys(a);
};

module.exports = o;
