<template>
  <transition
    enter-active-class="animated toast-in fast"
    leave-active-class="animated toast-out speedy"
  >
    <div
      v-if="message"
      v-on:mouseover="(...e) => mouseOver && mouseOver(...e)"
      v-on:mouseleave="(...e) => mouseLeave && mouseLeave(...e)"
      :class="[
        'c-base-toast',
        'c-base-toast--' + level,
        {
          'c-base-toast--active': isActive,
        },
      ]"
    >
      <div class="c-base-toast__view">
        <div class="c-base-toast__wrapped">
          <span class="c-base-toast__icon"></span>
          <span class="c-base-toast__level">{{ level }}</span>
          <span
            class="c-base-toast__view__message"
            v-html="$filters.format.parseText(message)"
          ></span>
          <a
            class="c-base-toast__close"
            v-on:click="(...e) => close && close(...e)"
          ></a>
        </div>
        <span class="c-base-toast__progress"></span>
      </div>
    </div>
  </transition>
</template>
<script>
import mitt from "mitt";

const n = 350;
const r = 8e3;
const s = 4e3;
const l = mitt();
const c = /[_-]+/g;
let d = 0;

export default {
  data() {
    return {
      isActive: !1,
      level: "success",
      message: "",
      toastOpenTimeout: null,
      toastAutoCloseTimeout: null,
      currentInstance: 0,
    };
  },
  success(e, t = !0) {
    l.emit("open", {
      level: "success",
      message: e,
      mapMessage: t,
    });
  },
  info(e, t = !0) {
    l.emit("open", {
      level: "info",
      message: e,
      mapMessage: t,
    });
  },
  warning(e, t = !0) {
    l.emit("open", {
      level: "warning",
      message: e,
      mapMessage: t,
    });
  },
  error(e, t = !0) {
    l.emit("open", {
      level: "error",
      message: e,
      mapMessage: t,
    });
  },
  created() {
    this.currentInstance = d;
    d++;
    l.on("open", this.open);
  },
  beforeUnmount() {
    d--;
    l.off("open", this.open);
  },
  methods: {
    open({ level: e, message: t, mapMessage: i = !1 }) {
      if (this.currentInstance === d - 1) {
        if (!t) throw new Error("No message provided for toast");
        !0 === i &&
          (t = this.$te("TOASTS." + t.toUpperCase())
            ? this.$t("TOASTS." + t.toUpperCase())
            : this.formatMessage(t)),
          null !== this.toastOpenTimeout &&
            (clearTimeout(this.toastOpenTimeout),
            (this.toastOpenTimeout = null)),
          this.close(),
          (this.toastOpenTimeout = setTimeout(() => {
            (this.toastOpenTimeout = null),
              (this.isActive = !0),
              (this.level = e),
              (this.message = t),
              this.scheduleClose();
          }, n));
      }
    },
    close() {
      this.isActive &&
        (this.unscheduleClose(),
        (this.isActive = !1),
        (this.level = null),
        (this.message = null));
    },
    scheduleClose(e = !1) {
      let t = s;
      this.unscheduleClose(),
        e || (t = r),
        (this.toastAutoCloseTimeout = setTimeout(() => {
          (this.toastAutoCloseTimeout = null), this.close();
        }, t));
    },
    unscheduleClose() {
      null !== this.toastAutoCloseTimeout &&
        (clearTimeout(this.toastAutoCloseTimeout),
        (this.toastAutoCloseTimeout = null));
    },
    formatMessage(e) {
      return (
        (e = e.replace(c, " ").trim()),
        (e = this.$filters.format.capitalize(e)),
        e
      );
    },
    mouseOver() {
      this.unscheduleClose();
    },
    mouseLeave() {
      this.scheduleClose(!0);
    },
  },
};
</script>
<style lang="scss">
.c-base-toast {
  position: relative;
  z-index: 1000000;
  width: 100%;
  -webkit-box-shadow: 0 -1px 3px 0 rgba(0, 0, 0, 0.075);
  box-shadow: 0 -1px 3px 0 rgba(0, 0, 0, 0.075);
  .c-base-toast__view {
    min-height: 46px;
    .c-base-toast__wrapped {
      position: relative;
      z-index: 1;
      display: block;
      overflow: hidden;
      padding: 12px 44px 12px 52px;
      font-size: 14px;
      line-height: 22px;
      .c-base-toast__icon {
        position: absolute;
        left: 24px;
        margin-top: 1px;
        height: 20px;
        text-align: center;
        &:before {
          position: absolute;
          top: 50%;
          width: 15px;
          height: 15px;
          content: "";
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
          background-size: contain;
          background-repeat: no-repeat;
          background-position: 50%;
          display: inline-block;
        }
      }
      .c-base-toast__level {
        margin-right: 12px;
        padding-right: 12px;
        border-right: 1px solid hsla(0, 0%, 100%, 0.2);
        text-transform: capitalize;
        font-weight: 800;
      }
      .c-base-toast__close {
        position: absolute;
        top: 50%;
        right: 30px;
        margin-top: -1px;
        width: 10px;
        height: 10px;
        opacity: 0.75;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        background-image: url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjI1IiB3aWR0aD0iMjUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2U9IiNmZmYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIzIj48cGF0aCBkPSJNMS41IDIzLjVMMjIuNzEzIDIuMjg3TTIyLjcxMyAyMy41TDEuNSAyLjI4NyIvPjwvZz48L3N2Zz4=");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 50%;
        display: inline-block;
        &:hover {
          opacity: 0.9;
        }
        &:active {
          opacity: 1;
        }
      }
    }
    .c-base-toast__progress {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      background-color: hsla(0, 0%, 100%, 0.075);
    }
    .o-markdown-link-text {
      font-weight: 700;
      text-decoration: underline;
    }
  }
}
.c-base-toast--active {
  .c-base-toast__view {
    .c-base-toast__progress {
      -webkit-animation: reduceWidthLeft;
      animation: reduceWidthLeft;
      -webkit-animation-duration: 7s;
      animation-duration: 7s;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
      -webkit-animation-delay: 1s;
      animation-delay: 1s;
    }
  }
}
.c-base-toast--success {
  .c-base-toast__view {
    background: #3aaa29;
    color: #fff;
    .o-markdown-link-text {
      color: #fff;
    }
    .c-base-toast__wrapped {
      .c-base-toast__icon {
        &:before {
          background-image: url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjI0IiB3aWR0aD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTIwLjA2MyA5LjQ2OWEuOTczLjk3MyAwIDAwLS4yODItLjcxOUwxOC4zNiA3LjM0NGMtLjE5OC0uMTk4LS40MzItLjI5Ny0uNzAzLS4yOTdzLS41MDUuMDk5LS43MDMuMjk3bC02LjM3NSA2LjM2LTMuNTMxLTMuNTMyYy0uMTk4LS4xOTgtLjQzMi0uMjk3LS43MDMtLjI5N3MtLjUwNS4wOTktLjcwMy4yOTdsLTEuNDIyIDEuNDA2YS45NzMuOTczIDAgMDAtLjI4Mi43MTljMCAuMjgxLjA5NC41MTUuMjgyLjcwM2w1LjY1NiA1LjY1NmEuOTYxLjk2MSAwIDAwLjcwMy4yOTdjLjI4MSAwIC41MjEtLjA5OS43MTktLjI5N2w4LjQ4NC04LjQ4NGEuOTU0Ljk1NCAwIDAwLjI4Mi0uNzAzek0yNCAxMmMwIDIuMTc3LS41MzYgNC4xODUtMS42MSA2LjAyM3MtMi41MjggMy4yOTUtNC4zNjcgNC4zNjhTMTQuMTc3IDI0IDEyIDI0Yy0yLjE3NyAwLTQuMTg1LS41MzYtNi4wMjMtMS42MXMtMy4yOTUtMi41MjgtNC4zNjgtNC4zNjdTMCAxNC4xNzcgMCAxMmMwLTIuMTc3LjUzNi00LjE4NSAxLjYxLTYuMDIzczIuNTI4LTMuMjk1IDQuMzY3LTQuMzY4UzkuODIzIDAgMTIgMGMyLjE3NyAwIDQuMTg1LjUzNiA2LjAyMyAxLjYxczMuMjk1IDIuNTI4IDQuMzY4IDQuMzY3UzI0IDkuODIzIDI0IDEyeiIgZmlsbD0iI2ZmZiIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+");
        }
      }
    }
  }
}
.c-base-toast--info {
  .c-base-toast__view {
    background: #0d82da;
    color: #fff;
    .o-markdown-link-text {
      color: #fff;
    }
    .c-base-toast__wrapped {
      .c-base-toast__icon {
        &:before {
          background-image: url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjI0IiB3aWR0aD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE2IDE5LjVWMTdjMC0uMTQ2LS4wNDctLjI2Ni0uMTQtLjM2cy0uMjE0LS4xNC0uMzYtLjE0SDE0di04YzAtLjE0Ni0uMDQ3LS4yNjYtLjE0LS4zNlMxMy42NDUgOCAxMy41IDhoLTVjLS4xNDYgMC0uMjY2LjA0Ny0uMzYuMTRTOCA4LjM1NSA4IDguNVYxMWMwIC4xNDYuMDQ3LjI2Ni4xNC4zNnMuMjE0LjE0LjM2LjE0SDEwdjVIOC41Yy0uMTQ2IDAtLjI2Ni4wNDctLjM2LjE0UzggMTYuODU1IDggMTd2Mi41YzAgLjE0Ni4wNDcuMjY2LjE0LjM2cy4yMTQuMTQuMzYuMTRoN2MuMTQ2IDAgLjI2Ni0uMDQ3LjM2LS4xNHMuMTQtLjIxNC4xNC0uMzZ6bS0yLTE0VjNjMC0uMTQ2LS4wNDctLjI2Ni0uMTQtLjM2cy0uMjE0LS4xNC0uMzYtLjE0aC0zYy0uMTQ2IDAtLjI2Ni4wNDctLjM2LjE0UzEwIDIuODU1IDEwIDN2Mi41YzAgLjE0Ni4wNDcuMjY2LjE0LjM2cy4yMTQuMTQuMzYuMTRoM2MuMTQ2IDAgLjI2Ni0uMDQ3LjM2LS4xNHMuMTQtLjIxNC4xNC0uMzZ6TTI0IDEyYzAgMi4xNzctLjUzNiA0LjE4NS0xLjYxIDYuMDIzcy0yLjUyOCAzLjI5NS00LjM2NyA0LjM2OFMxNC4xNzcgMjQgMTIgMjRjLTIuMTc3IDAtNC4xODUtLjUzNi02LjAyMy0xLjYxcy0zLjI5NS0yLjUyOC00LjM2OC00LjM2N1MwIDE0LjE3NyAwIDEyYzAtMi4xNzcuNTM2LTQuMTg1IDEuNjEtNi4wMjNzMi41MjgtMy4yOTUgNC4zNjctNC4zNjhTOS44MjMgMCAxMiAwYzIuMTc3IDAgNC4xODUuNTM2IDYuMDIzIDEuNjFzMy4yOTUgMi41MjggNC4zNjggNC4zNjdTMjQgOS44MjMgMjQgMTJ6IiBmaWxsPSIjZmZmIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz48L3N2Zz4=");
        }
      }
    }
  }
}
.c-base-toast--warning {
  .c-base-toast__view {
    background: #ef5f0b;
    color: #fff;
    .o-markdown-link-text {
      color: #fff;
    }
    .c-base-toast__wrapped {
      .c-base-toast__icon {
        &:before {
          background-image: url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjI0IiB3aWR0aD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEyIDBjMi4xNzcgMCA0LjE4NS41MzYgNi4wMjMgMS42MXMzLjI5NSAyLjUyOCA0LjM2OCA0LjM2N1MyNCA5LjgyMyAyNCAxMmMwIDIuMTc3LS41MzYgNC4xODUtMS42MSA2LjAyM3MtMi41MjggMy4yOTUtNC4zNjcgNC4zNjhTMTQuMTc3IDI0IDEyIDI0Yy0yLjE3NyAwLTQuMTg1LS41MzYtNi4wMjMtMS42MXMtMy4yOTUtMi41MjgtNC4zNjgtNC4zNjdTMCAxNC4xNzcgMCAxMmMwLTIuMTc3LjUzNi00LjE4NSAxLjYxLTYuMDIzczIuNTI4LTMuMjk1IDQuMzY3LTQuMzY4UzkuODIzIDAgMTIgMHptMiAxOS40ODR2LTIuOTY4YzAtLjE0Ni0uMDQ3LS4yNjktLjE0LS4zNjhTMTMuNjUgMTYgMTMuNTE1IDE2aC0zYy0uMTM2IDAtLjI1Ni4wNTItLjM2LjE1NnMtLjE1Ni4yMjQtLjE1Ni4zNnYyLjk2OGMwIC4xMzYuMDUyLjI1Ni4xNTYuMzZzLjIyNC4xNTYuMzYuMTU2aDNjLjEzNSAwIC4yNS0uMDUuMzQzLS4xNDhzLjE0MS0uMjIyLjE0MS0uMzY4em0tLjAzMS01LjM3NWwuMjgxLTkuNzAzYS4zMDcuMzA3IDAgMDAtLjE1Ni0uMjgxLjU4Mi41ODIgMCAwMC0uMzc1LS4xMjVIMTAuMjhhLjU4Mi41ODIgMCAwMC0uMzc1LjEyNS4zMDcuMzA3IDAgMDAtLjE1Ni4yODFsLjI2NiA5LjcwM2MwIC4xMDUuMDUyLjE5Ni4xNTYuMjc0cy4yMjkuMTE3LjM3NS4xMTdoMi44OWMuMTQ2IDAgLjI2OS0uMDQuMzY4LS4xMTdzLjE1My0uMTcuMTY0LS4yNzR6IiBmaWxsPSIjZmZmIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz48L3N2Zz4=");
        }
      }
    }
  }
}
.c-base-toast--error {
  .c-base-toast__view {
    background: #ca280d;
    color: #fff;
    .o-markdown-link-text {
      color: #fff;
    }
    .c-base-toast__wrapped {
      .c-base-toast__icon {
        &:before {
          background-image: url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjI0IiB3aWR0aD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEyIDBjMi4xNzcgMCA0LjE4NS41MzYgNi4wMjMgMS42MXMzLjI5NSAyLjUyOCA0LjM2OCA0LjM2N1MyNCA5LjgyMyAyNCAxMmMwIDIuMTc3LS41MzYgNC4xODUtMS42MSA2LjAyM3MtMi41MjggMy4yOTUtNC4zNjcgNC4zNjhTMTQuMTc3IDI0IDEyIDI0Yy0yLjE3NyAwLTQuMTg1LS41MzYtNi4wMjMtMS42MXMtMy4yOTUtMi41MjgtNC4zNjgtNC4zNjdTMCAxNC4xNzcgMCAxMmMwLTIuMTc3LjUzNi00LjE4NSAxLjYxLTYuMDIzczIuNTI4LTMuMjk1IDQuMzY3LTQuMzY4UzkuODIzIDAgMTIgMHptMiAxOS40ODR2LTIuOTY4YzAtLjE0Ni0uMDQ3LS4yNjktLjE0LS4zNjhTMTMuNjUgMTYgMTMuNTE1IDE2aC0zYy0uMTM2IDAtLjI1Ni4wNTItLjM2LjE1NnMtLjE1Ni4yMjQtLjE1Ni4zNnYyLjk2OGMwIC4xMzYuMDUyLjI1Ni4xNTYuMzZzLjIyNC4xNTYuMzYuMTU2aDNjLjEzNSAwIC4yNS0uMDUuMzQzLS4xNDhzLjE0MS0uMjIyLjE0MS0uMzY4em0tLjAzMS01LjM3NWwuMjgxLTkuNzAzYS4zMDcuMzA3IDAgMDAtLjE1Ni0uMjgxLjU4Mi41ODIgMCAwMC0uMzc1LS4xMjVIMTAuMjhhLjU4Mi41ODIgMCAwMC0uMzc1LjEyNS4zMDcuMzA3IDAgMDAtLjE1Ni4yODFsLjI2NiA5LjcwM2MwIC4xMDUuMDUyLjE5Ni4xNTYuMjc0cy4yMjkuMTE3LjM3NS4xMTdoMi44OWMuMTQ2IDAgLjI2OS0uMDQuMzY4LS4xMTdzLjE1My0uMTcuMTY0LS4yNzR6IiBmaWxsPSIjZmZmIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz48L3N2Zz4=");
        }
      }
    }
  }
}
</style>
