import a from "@/classes/plugins";
import o from "@/classes/helpers";

export default {
  namespaced: !0,
  state: {
    subscriptionsAll: [],
    indexedSubscriptionsAll: {},
    billingPriceAll: {},
    categories: [],
    plugins: [],
    pluginUrns: {},
    pluginUrnsReady: !1,
    pluginDetails: {},
    detailsLoading: {},
    currentPlugin: {},
    pluginSettings: {},
  },
  getters: {
    getSubscriptionsAll: (e) => e.subscriptionsAll,
    getIndexedSubscriptionsAll: (e) => e.indexedSubscriptionsAll,
    getBillingPriceAll: (e) => e.billingPriceAll,
    getCategories: (e) => e.categories,
    getPluginUrns: (e) => e.pluginUrns,
    arePluginUrnsReady: (e) => e.pluginUrnsReady,
    getPluginSettings: (e) => (t) => e.pluginSettings[t],
    getPluginDetails: (e) => (t) => e.pluginDetails[t],
    getPlugins: (e) => e.plugins,
    getCurrentPlugin: (e) => e.currentPlugin,
    hasPlugin: (e) => (t) => !!e.pluginUrns[t],
  },
  actions: {
    acquirePluginUrns({ commit: e }, { websiteId: t }) {
      return Promise.resolve()
        .then(
          () => (
            e("resetPluginsUrns"),
            e("setPluginUrnsReady", {
              value: !1,
            }),
            Promise.resolve()
          ),
        )
        .then(() => a.subscriptionOne(t).get())
        .catch(() => Promise.resolve([]))
        .then((t) => {
          let i = {};
          return (
            (t || []).forEach((e) => {
              !0 === e.active && (i[e.urn] = e.id);
            }),
            e("resetPluginsUrns", {
              value: i,
            }),
            e("setPluginUrnsReady", {
              value: !0,
            }),
            Promise.resolve()
          );
        });
    },
    acquireAllCategories({ commit: e }) {
      return o
        .pageResolverToEnd((e) => a.getCategories(e))
        .catch(() => Promise.resolve([]))
        .then(
          (t) => (
            e("setCategories", {
              value: t,
            }),
            Promise.resolve(t)
          ),
        );
    },
    acquireAllSubscribedPlugins({ commit: e }, { websiteId: t }) {
      return a
        .subscriptionOne(t)
        .get()
        .catch(() => Promise.resolve([]))
        .then(
          (t) => (
            e("resetPlugins", {
              value: t,
            }),
            Promise.resolve(!0)
          ),
        );
    },
    acquirePlugins({ commit: e }, { page: t, category: i }) {
      let o;
      return a
        .getPlugins(t, {
          category: i,
        })
        .catch(() => Promise.resolve([]))
        .then(
          (i) => (
            (o = i),
            1 === t
              ? (e("resetPlugins", {
                  value: i,
                }),
                Promise.resolve())
              : (e("addPlugins", {
                  value: i,
                }),
                Promise.resolve())
          ),
        )
        .then(() =>
          206 === o.__status ? Promise.resolve(!1) : Promise.resolve(!0),
        );
    },
    acquirePlugin({ commit: e }, { pluginId: t }) {
      return a
        .getPlugin(t)
        .catch(() => Promise.resolve([]))
        .then(
          (t) => (
            e("addPlugin", {
              value: t,
            }),
            Promise.resolve()
          ),
        );
    },
    acquirePluginSettings({ commit: e }, { websiteId: t, pluginId: i }) {
      return a
        .subscriptionOne(t)
        .one(i)
        .one("settings")
        .get()
        .then(
          (t) => (
            e("setPluginSettings", {
              pluginId: i,
              value: t,
            }),
            Promise.resolve(t)
          ),
        )
        .catch(() => Promise.resolve({}));
    },
    acquirePluginDetails({ commit: e, state: t }, { pluginId: i }) {
      return !0 === t.detailsLoading[i]
        ? Promise.resolve()
        : (e("setDetailsLoading", {
            pluginId: i,
            value: !0,
          }),
          a
            .getPluginDetails(i)
            .catch(() => Promise.resolve({}))
            .then(
              (t) => (
                e("setPluginDetails", {
                  pluginId: i,
                  value: t,
                }),
                e("setDetailsLoading", {
                  pluginId: i,
                  value: !1,
                }),
                Promise.resolve()
              ),
            ));
    },
    searchPlugins({ commit: e }, { query: t, category: i, page: o }) {
      let n;
      return a
        .getPluginsSearch(o, {
          query: t,
          category: i,
        })
        .catch(() => Promise.resolve([]))
        .then(
          (t) => (
            (n = t),
            1 === o
              ? (e("resetPlugins", {
                  value: t,
                }),
                Promise.resolve())
              : (e("addPlugins", {
                  value: t,
                }),
                Promise.resolve())
          ),
        )
        .then(() =>
          206 === n.__status ? Promise.resolve(!1) : Promise.resolve(!0),
        );
    },
  },
  mutations: {
    reset(e) {
      (e.detailsLoading = {}), (e.pluginUrns = {}), (e.pluginUrnsReady = !1);
    },
    setSubscriptionsAll(e, { value: t }) {
      (e.subscriptionsAll = t),
        (e.indexedSubscriptionsAll = {}),
        e.subscriptionsAll.forEach((t) => {
          (e.indexedSubscriptionsAll[t.website_id] =
            e.indexedSubscriptionsAll[t.website_id] || {}),
            (e.indexedSubscriptionsAll[t.website_id][t.id] = t);
        });
    },
    setBillingPriceAll(e, { value: t }) {
      e.billingPriceAll = t;
    },
    resetPlugins(e, { value: t = [] } = {}) {
      e.plugins = t;
    },
    addPlugins(e, { value: t }) {
      t.forEach((t) => {
        e.plugins.push(t);
      });
    },
    setCurrentPlugin(e, { value: t }) {
      e.currentPlugin = t;
    },
    addPlugin(e, { value: t }) {
      const i = e.plugins.findIndex((e) => e.id === t.id);
      -1 !== i ? (e.plugins[i] = t) : e.plugins.push(t);
    },
    removePlugin(e, { pluginId: t, pluginUrn: i }) {
      const a = e.plugins.findIndex((e) => e.id === t);
      -1 !== a && e.plugins.splice(a, 1), delete e.pluginUrns[i];
    },
    setPluginDetails(e, { pluginId: t, value: i }) {
      e.pluginDetails[t] = i;
    },
    setDetailsLoading(e, { pluginId: t, value: i }) {
      e.detailsLoading[t] = i;
    },
    setCategories(e, { value: t = [] } = {}) {
      e.categories = t;
    },
    setPluginSettings(e, { pluginId: t, value: i }) {
      e.pluginSettings[t] = i;
    },
    addPluginUrn(e, { pluginUrn: t, pluginId: i }) {
      e.pluginUrns[t] = i;
    },
    removePluginUrn(e, { pluginUrn: t }) {
      delete e.pluginUrns[t];
    },
    resetPluginsUrns(e, { value: t = {} } = {}) {
      e.pluginUrns = t;
    },
    setPluginUrnsReady(e, { value: t = !1 } = {}) {
      e.pluginUrnsReady = t;
    },
  },
};
