class _bases {
  init(_vue) {
    this.registerGlobally(_vue, require("./base-components.js"));
    this.registerGlobally(_vue, require("./base-fields.js"));
  }
  upperFirst(e) {
    return e ? e.charAt(0).toUpperCase() + e.slice(1) : "";
  }
  registerGlobally(__vue, _sources) {
    _sources.keys().forEach((_sourceName) => {
      const o = _sources(_sourceName);
      const _componentName = this.upperFirst(
        _sourceName.replace(/^\.\/(.*)\.\w+$/, "$1"),
      );
      __vue.component(_componentName, o.default || o);
      // console.log("@registering", _componentName, o);
    });
  }
}

export default new _bases();
